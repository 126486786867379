import React, { useContext } from 'react';
import {
  UserWorkloadCreate,
  userWorkloadRunDocToDocPDF,
  userWorkloadRunPost,
  userWorkloadRunPostForTranslator,
  userWorkloadRunPostPDF,
  UserWorkloadRunPostPdfCsv,
  workloadCreate,
  workloadRunDocToDocPDF,
  workloadRunPost,
  workloadRunPostForTranslator,
  workloadRunPostPDF,
  workloadRunPostREX,
  userWorkloadRunPostForREX,
} from '../../api/workloads';
import { iCaptureCsv, iCaptureExcel } from '../../api/module1';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import { calculateSize } from '../Project/ProjectContainer/components/ProjectFolder/Files';

const deeplruntime = {
  txt: {
    value: 1,
    unit: 'MB',
  },
  html: {
    value: 5,
    unit: 'MB',
  },
  xlf: {
    value: 10,
    unit: 'MB',
  },
  xliff: {
    value: 10,
    unit: 'MB',
  },
};

const useModule2services = (props) => {
  const docOperation = [
    'Similarity Analysis',
    'Document Similarity',
    'Compare Versions',
    'Extract Topics',
    // 'Translate',
    'Find Duplicates',
  ];

  //   const [captureName, setCaptureName] = React., boolValueuseState('');
  const {
    scopeSetting,
    fileSetting,
    captureName,
    boolValue,
    serviceName,
    serviceFor,
    globalGlossary = null,
    docTranslation,
  } = props;
  const [loadingResult, setLoadingResult] = React.useState(false);
  //   const [boolValue, setBoolValue] = React.useState(false);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = state;
  const {
    simiProductLine,
    simiPlatform,
    simiSolution,
    simiProduct,
    simiProject,
    simiDocumentProfile,
    selectedSimiProductLine,
    selectedSimiPlatform,
    selectedSimiSolution,
    selectedSimiProduct,
    selectedSimiProject,
    selectedSimiDocumentProfile,
    countValue,
    threshold,
  } = similarityFilter;

  const WorkloadCreateion = async (payload) => {
    const { name, id } = serviceFor;
    if (name === 'user') {
      const newWorkload = await UserWorkloadCreate({ ...payload });
      return newWorkload;
    }

    if (name === 'project') {
      const newWorkload = await workloadCreate({
        ...payload,
        project_id: id,
      });
      return newWorkload;
    }
    return null;
  };

  const HandleValidation = () => {
    const FileterkeysSettings = Object.keys(fileSetting);
    // console.log('fileSetting', fileSetting);
    let errorCheck = { error: false, message: '' };
    for (let i = 0; i < FileterkeysSettings.length; i += 1) {
      const element = FileterkeysSettings[i];
      if (fileSetting[element]?.FileType === 'Excel') {
        if (!fileSetting[element]?.sheetName) {
          //   setSnack('Please Enter Excel Sheet Column', 'error');
          errorCheck = { error: true, message: 'Please Enter Excel Sheet Column' };
          break;
        }
        if (!fileSetting[element]?.targetColumn) {
          //   setSnack('Please Enter Excel Target Column', 'error');
          errorCheck = { error: true, message: 'Please Enter Excel Target Column' };
          break;
        }
      } else if (fileSetting[element].FileType === 'CSV') {
        if (!fileSetting[element]?.columnName) {
          //   setSnack('Please Enter CSV Column Name', 'error');
          errorCheck = { error: true, message: 'Please Enter CSV Column Name' };
          break;
        }
      }
    }
    if (!captureName) {
      //   setSnack('Please Enter Activity Name', 'error');
      setLoadingResult(false);
      return { error: true, message: 'Please Enter Activity Name' };
    }

    if (errorCheck.error) setLoadingResult(false);
    return errorCheck;
  };

  const handleAnalyzePdfiles = async (service: string) => {
    let servicesType: any = null;
    if (captureName?.trim().length === 0) {
      //   setSnack('Enter activity name', 'error');
      return { error: true, message: 'Enter activity name' };
    }
    setLoadingResult(true);
    let fileone = null;
    let filetwo = null;
    if (scopeSetting[0].FileType === 'PDF') {
      fileone = {
        output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
        TextLength: 10000,
        settingsModule: {
          FileName: scopeSetting[0].Blob,
          FilePath: scopeSetting[0].FilePath,
          Container: scopeSetting[0].Container,
          BlobName: scopeSetting[0].Blob,
          id: scopeSetting[0].Blob,
          FileType: 'pdf',
          FileIndex: 0,
          Language: scopeSetting[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
        },
      };
    }
    if (scopeSetting[1].FileType === 'PDF') {
      filetwo = {
        output: { NameOfCapture: captureName, FileName: scopeSetting[1].Blob },
        TextLength: 10000,
        settingsModule: {
          FileName: scopeSetting[1].Blob,
          FilePath: scopeSetting[1].FilePath,
          Container: scopeSetting[1].Container,
          BlobName: scopeSetting[1].Blob,
          id: scopeSetting[1].Blob,
          FileType: 'pdf',
          FileIndex: 1,
          Language: scopeSetting[1].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
        },
      };
    }

    if (scopeSetting[0].FileType === 'PDF' && scopeSetting[1].FileType === 'PDF') {
      const bothPdfFile: any = [fileone, filetwo];
      if (service === 'DocToDoc') {
        servicesType = 'DocToDocPDF';
      }
      if (service === 'docdiffer') {
        servicesType = 'm2-docdifferPDF';
      }

      try {
        const newWorkload = await WorkloadCreateion({
          app: 'module2',
          action: service,
          resources: bothPdfFile,
        });

        if (serviceFor.name === 'user') {
          const result = await userWorkloadRunDocToDocPDF(
            Number(newWorkload.id),
            bothPdfFile,
            servicesType
          );
        }
        if (serviceFor.name === 'project') {
          const result = await workloadRunDocToDocPDF(
            Number(newWorkload.id),
            bothPdfFile,
            servicesType
          );
        }
        setLoadingResult(false);

        return { error: false, message: 'success', workloadId: newWorkload.id };
      } catch (error) {
        setLoadingResult(false);

        return { error: true, message: 'Error while workload post.' };
      }

      //   setSnack('Error while workload post.', 'error');
    } else {
      let filePDF: any = null;
      let fileIndex: any = null;
      if (service === 'DocToDoc') {
        servicesType = 'm2-doctodoc-pdf-csv';
      }
      if (service === 'docdiffer') {
        servicesType = 'm2-docdiffer-pdf-csv';
      }
      if (scopeSetting[0].FileType === 'PDF') {
        filePDF = fileone;
        fileIndex = 1;
      }
      if (scopeSetting[1].FileType === 'PDF') {
        filePDF = filetwo;
        fileIndex = 0;
      }
      const allPromisesFun = [scopeSetting[fileIndex]].map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });

      try {
        const allresponse = await Promise.all(allPromisesFun.map((fn) => fn()));

        let len1 = null;
        let len2 = null;
        if (fileIndex === 0) {
          const len1 =
            JSON.parse(allresponse[0].output?.Text)?.length > 0
              ? JSON.parse(allresponse[0].output?.Text)?.length
              : 0;
          len2 = 10000;
        }
        if (fileIndex === 1) {
          const len2 =
            JSON.parse(allresponse[1].output?.Text)?.length > 0
              ? JSON.parse(allresponse[1].output?.Text)?.length
              : 0;
          len1 = 10000;
        }
        if (len1 === 0 || len2 === 0) {
          // setSnack('Invalid file format', 'error');
          return { error: true, message: 'Invalid file format' };
        }
        const TextLen = len1 > len2 ? len1 : len2;
        const filterScopeSettings = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            return {
              ...data,
              Sheet: data?.Sheets?.filter(
                (data) => data.SheetName === fileSetting[index].sheetName
              )[0],
            };
          }
          return data;
        });

        const r = allresponse.map((data, index) => {
          return {
            ...data,
            FileName: filterScopeSettings[index].Blob,
            scopeSettingOP: [filterScopeSettings[index]],
            TextLength: TextLen,
            filesPDF: filePDF,
          };
        });

        const workloadNew = await WorkloadCreateion({
          app: 'module2',
          action: service,
          resources: r,
        });

        if (service === 'DocToDoc') {
          const result = await UserWorkloadRunPostPdfCsv(Number(workloadNew.id), r, servicesType);

          setLoadingResult(false);
          return { error: false, message: 'Success', workloadId: workloadNew.id };
        }
        const result = await UserWorkloadRunPostPdfCsv(Number(workloadNew.id), r, servicesType);
        setLoadingResult(false);
        return { error: false, message: 'Success', workloadId: workloadNew.id };
      } catch (error) {
        return { error: true, message: 'Error while workload post.' };
      }
    }
  };

  const TwoFileAnalysis = async (service: string) => {
    const fileExtensionOne = scopeSetting[0]?.FileName.toLowerCase().split('.').pop();
    const fileExtensionTwo = scopeSetting[1]?.FileName.toLowerCase().split('.').pop();
    if (fileExtensionOne === 'pdf' || fileExtensionTwo === 'pdf') {
      const result = await handleAnalyzePdfiles(service);
      return result;
    }

    const checkError = HandleValidation();
    if (checkError.error) {
      return checkError;
    }

    if (!checkError.error) {
      setLoadingResult(true);
      const allPromisesFun = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });
      const allresponse = await Promise.all(allPromisesFun.map((fn) => fn()));
      try {
        const len1 =
          JSON.parse(allresponse[0].output?.Text)?.length > 0
            ? JSON.parse(allresponse[0].output?.Text)?.length
            : 0;
        const len2 =
          JSON.parse(allresponse[1].output?.Text)?.length > 0
            ? JSON.parse(allresponse[1].output?.Text)?.length
            : 0;
        if (len1 === 0 || len2 === 0) {
          return { error: true, message: 'Invalid file format' };
        }
        const TextLen = len1 > len2 ? len1 : len2;
        const filterScopeSettings = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            return {
              ...data,
              Sheet: data?.Sheets?.filter(
                (data) => data.SheetName === fileSetting[index].sheetName
              )[0],
            };
          }
          return data;
        });

        const r = allresponse.map((data, index) => {
          return {
            ...data,
            FileName: filterScopeSettings[index].Blob,
            scopeSettingOP: [filterScopeSettings[index]],
            TextLength: TextLen,
          };
        });

        const workloadNew = await WorkloadCreateion({
          app: 'module2',
          action: service,
          resources: r,
        });
        const runPayload = service === 'docdiffer' ? { r } : r;
        if (serviceFor.name === 'user') {
          const result = await userWorkloadRunPost(
            Number(workloadNew.id),
            runPayload,
            `m2-${service.toLowerCase()}`
          );
        }

        if (serviceFor.name === 'project') {
          const result = await workloadRunPost(
            Number(workloadNew.id),
            runPayload,
            `m2-${service.toLowerCase()}`
          );
        }

        setLoadingResult(false);
        return { error: false, message: 'Success', workloadId: workloadNew.id };
      } catch (error) {
        setLoadingResult(false);
        return { error: true, message: 'Error while captures.' };
      }
    }

    return { error: false, message: 'Success' };
  };

  const docToDatasetPdfFile = async () => {
    if (!captureName) {
      setLoadingResult(false);
      return { error: true, message: 'Please Enter Activity Name' };
    }
    // start here
    let entityIs: any = [];
    let platform: any = [];
    let sol: any = [];
    let prod: any = [];
    let project: any = [];
    let documentProfile: any = [];
    // console.log(alignment);
    selectedSimiProductLine.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    selectedSimiPlatform.forEach((obj: any) => {
      platform.push(obj.value);
    });
    selectedSimiProject.forEach((obj: any) => {
      project.push(obj.value);
    });
    selectedSimiDocumentProfile.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    selectedSimiSolution.forEach((obj: any) => {
      sol.push(obj.value);
    });
    selectedSimiProduct.forEach((obj: any) => {
      prod.push(obj.value);
    });

    if (entityIs.length === 0 || entityIs.length === simiProductLine.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === simiPlatform.length) {
      platform = ['*'];
    }
    if (sol.length === 0 || sol.length === simiSolution.length) {
      sol = ['*'];
    }
    if (prod.length === 0 || prod.length === simiProduct.length) {
      prod = ['*'];
    }
    if (project.length === 0 || project.length === simiProject.length) {
      project = ['*'];
    }
    if (documentProfile.length === 0 || documentProfile.length === simiDocumentProfile.length) {
      documentProfile = ['*'];
    }
    // done here
    const generateComplianceStatus = boolValue;
    const r = [
      {
        output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: scopeSetting[0].Blob,
          FilePath: scopeSetting[0].FilePath,
          Container: scopeSetting[0].Container,
          BlobName: scopeSetting[0].Blob,
          id: scopeSetting[0].Blob,
          FileType: 'pdf',
          Language: scopeSetting[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
          modeService: 'DocToDataset',
        },
        doctodatset: {
          businessEntity: entityIs,
          generateComplianceStatus,
          platform,
          solution: sol,
          product: prod,
          project,
          documentProfile,
        },
      },
    ];
    try {
      const newWorkload = await WorkloadCreateion({
        app: 'module2',
        action: 'DocToDataset',
        resources: r,
      });

      if (serviceFor.name === 'user') {
        const result = await userWorkloadRunPostPDF(Number(newWorkload.id), r, 'm2-multi-text');
      }

      if (serviceFor.name === 'project') {
        const result = await workloadRunPostPDF(Number(newWorkload.id), r, 'm2-multi-text');
      }

      // console.log('Run post', data);
      setLoadingResult(false);
      return { error: false, message: 'Success', workloadId: newWorkload.id };
    } catch (error) {
      setLoadingResult(false);
      return { error: true, message: 'Error while workload create.' };
    }
  };

  const SimilarityAnalysis = async (service: string) => {
    if (scopeSetting[0]?.FileType === 'PDF') {
      const result = await docToDatasetPdfFile();
      return result;
    }

    const checkError = HandleValidation();
    if (checkError.error) {
      return checkError;
    }

    // console.log('similarityAnalysis', checkError);
    // console.log('similarityAnalysis', HandleValidation());
    if (!checkError.error) {
      setLoadingResult(true);
      let entityIs: any = [];
      let platform: any = [];
      let project: any = [];
      let documentProfile: any = [];
      let sol: any = [];
      let prod: any = [];
      selectedSimiProductLine.forEach((obj: any) => {
        entityIs.push(obj);
      });
      selectedSimiPlatform.forEach((obj: any) => {
        platform.push(obj);
      });
      selectedSimiProject.forEach((obj: any) => {
        project.push(obj);
      });
      selectedSimiDocumentProfile.forEach((obj: any) => {
        documentProfile.push(obj);
      });
      selectedSimiSolution.forEach((obj: any) => {
        sol.push(obj);
      });
      selectedSimiProduct.forEach((obj: any) => {
        prod.push(obj);
      });

      if (entityIs.length === 0 || entityIs.length === simiProductLine.length) {
        entityIs = ['*'];
      }
      if (platform.length === 0 || platform.length === simiPlatform.length) {
        platform = ['*'];
      }
      if (project.length === 0 || project.length === simiProject.length) {
        project = ['*'];
      }
      if (documentProfile.length === 0 || documentProfile.length === simiDocumentProfile.length) {
        documentProfile = ['*'];
      }
      if (sol.length === 0 || sol.length === simiSolution.length) {
        sol = ['*'];
      }
      if (prod.length === 0 || prod.length === simiProduct.length) {
        prod = ['*'];
      }

      const allPromisesFun = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Excel',
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
            businessEntity: entityIs,
            platform,
            project,
            documentProfile,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Csv',
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });

      const allResponse = await Promise.all(allPromisesFun.map((fn) => fn()));
      try {
        const len =
          JSON.parse(allResponse[0].output.Text)?.length > 0
            ? JSON.parse(allResponse[0].output.Text)?.length
            : 0;
        if (len === 0) {
          setLoadingResult(false);
          return { error: true, message: 'Invalid file format' };
        }

        const rPayload = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            const r = {
              Text: allResponse[0].output.Text,
              TextLength: len,
              selectedColumn: fileSetting[index].targetColumn,
              generateComplianceBoolean: boolValue,
              entityPassed: entityIs,
              platformPassed: platform,
              projectPassed: project,
              documentProfilePassed: documentProfile,
              solutionPassed: sol,
              productPassed: prod,
              FileName: data.Blob,
              FilePath: data.FilePath,
              NameOfCapture: captureName,
              Sheet: fileSetting[index].sheetName,
            };
            return r;
          }
          if (data.FileType === 'CSV') {
            const r = {
              Text: allResponse[0].output.Text,
              TextLength: len,
              selectedColumn: fileSetting[index].columnName,
              generateComplianceBoolean: boolValue,
              entityPassed: entityIs,
              platformPassed: platform,
              projectPassed: project,
              documentProfilePassed: documentProfile,
              solutionPassed: sol,
              productPassed: prod,
              FileName: data.Blob,
              FilePath: data.FilePath,
              NameOfCapture: captureName,
            };
            return r;
          }
          return null;
        });
        const newWorkload = await WorkloadCreateion({
          app: 'module2',
          action: 'DocToDataset',
          resources: rPayload,
        });

        const _payload = {
          dataframe: { Text: allResponse[0].output.Text },
          column_name: fileSetting[0].columnName ?? fileSetting[0].targetColumn,
          generateComplianceStatus: boolValue,
          businessEntity: entityIs,
          platform,
          project,
          documentProfile,
          solution: sol,
          product: prod,
        };

        if (serviceFor.name === 'user') {
          const filnalResponse = await userWorkloadRunPost(
            Number(newWorkload.id),
            {
              ..._payload,
            },
            'm2-multi-text'
          );
        }

        if (serviceFor.name === 'project') {
          const filnalResponse = await workloadRunPost(
            Number(newWorkload.id),
            {
              ..._payload,
            },
            'm2-multi-text'
          );
        }

        setLoadingResult(false);
        return { error: false, message: 'Success', workloadId: newWorkload.id };
      } catch (error) {
        setLoadingResult(false);
        return { error: true, message: 'Error while capture.' };
      }
    }
    return { error: false, message: 'Success' };
  };

  const handleAnalyzeRex = async (service: string) => {
    const checkError = HandleValidation();
    if (checkError.error) {
      return checkError;
    }
    if (!checkError.error) {
      setLoadingResult(true);
      const resources = scopeSetting.map((data, index) => {
        // console.log('data', data);
        // if (data.FileType === 'Excel') {
        //   return {
        //     ...data,
        //     FilePath: data.FilePath,
        //     FileName: data.FileName,
        //     Container: data.Container,
        //     BlobName: data.Blob,
        //     NameOfCapture: captureName,
        //     fileName: captureName,
        //     target: 'Text',
        //     fileType: 'Tender',
        //     Sheet: fileSetting[index].Sheet_names,
        //     TargetColumn: fileSetting[index].targetColumn,
        //     ColumnNames: fileSetting.columns,
        //     Header: true,
        //   };
        //   // return {
        //   //   Container: data.Container,
        //   //   Blob: data.Blob,
        //   //   SheetName: fileSetting[index].sheetName,
        //   //   TargetColumn: fileSetting[index].targetColumn,
        //   // };
        // }
        // if (data.FileType === 'CSV') {
        //   return {
        //     ...data,
        //     FilePath: data.FilePath,
        //     FileName: data.FileName,
        //     Container: data.Container,
        //     BlobName: data.Blob,
        //     NameOfCapture: captureName,
        //     fileName: captureName,
        //     target: 'Text',
        //     fileType: 'Tender',
        //     // Sheet: fileSetting[index].Sheet_names,
        //     TargetColumn: fileSetting[index].targetColumn,
        //     ColumnNames: fileSetting.columns,
        //     Header: true,
        //   };
        //   // return {
        //   //   Blob: data.Blob,
        //   //   ColumnNamesNoHeader: data.ColumnNamesNoHeader,
        //   //   Container: data.Container,
        //   //   LanguageCode: data.LanguageCode,
        //   //   TargetColumn: fileSetting[index].columnName,
        //   //   ColumnNames: fileSetting[index].columnNames,
        //   //   TextNoHeader: data.TextNoHeader,
        //   // };
        // }
        if (data.FileType === 'PDF') {
          // console.log('capture name', captureName);
          return {
            ...data,
            FilePath: data.FilePath,
            FileName: data.FileName,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            fileName: captureName,
            TypesOfCapture: data.TypesOfCapture[0],
            target: 'Text',
            fileType: 'Tender',
            // Sheet: fileSetting[index].Sheet_names,
            // TargetColumn: fileSetting[index].targetColumn,
            // ColumnNames: fileSetting.columns,
            Header: true,
            RegId: '',
            AutoHeaderFooterDetection: false,
            TableSwitch: false,
            MergeBullet: false,
          };
          // return {
          //   Blob: data.Blob,
          //   Container: data.Container,
          //   LanguageCode: data.LanguageCode,
          //   TargetColumn: 'forced',
          // };
        }
        return null;
      });
      const passedDataBlank = {
        productLine: ['*'],
        platform: ['*'],
        product: ['*'],
        solution: ['*'],
        project: ['*'],
        country: ['*'],
        status: ['*'],
        issuerSite: ['*'],
        typology: ['*'],
        rexSource: ['*'],
      };
      try {
        const newWorkload = await WorkloadCreateion({
          app: 'module2',
          action: 'RexBook',
          resources: [
            {
              settingsModule: { ...resources[0] },
              filters: passedDataBlank,
            },
          ],
        });

        if (serviceFor.name === 'user') {
          const result = await userWorkloadRunPostForREX(Number(newWorkload.id), 'RexBook');
        }

        if (serviceFor.name === 'project') {
          const result = await workloadRunPostREX(Number(newWorkload.id), 'RexBook');
        }

        setLoadingResult(false);
        return { error: false, message: 'success', workloadId: newWorkload.id };
      } catch (error) {
        setLoadingResult(false);
        return { error: true, message: 'Error while workload create.' };
      }
    }
    return { error: false, message: 'Success' };
  };

  const handleOneFileServise = async (service: string) => {
    const checkError = HandleValidation();
    if (checkError.error) {
      return checkError;
    }
    if (!checkError.error) {
      setLoadingResult(true);
      // console.log(scopeSetting);

      const pdfResource = scopeSetting
        .map((data, index) => {
          if (data.FileType === 'PDF') {
            return {
              output: { NameOfCapture: captureName, FileName: scopeSetting[index].Blob },
              TextLength: 100000,
              settingsModule: {
                FileName: scopeSetting[index].Blob,
                FilePath: scopeSetting[index].FilePath,
                Container: scopeSetting[index].Container,
                BlobName: scopeSetting[index].Blob,
                id: scopeSetting[index].Blob,
                FileType: 'pdf',
                Language: scopeSetting[index].Language,
                StartPage: 0,
                EndPage: 0,
                HeaderMargin: 0,
                FooterMargin: 0,
                TypesOfCapture: 'Paragraph_Capture',
                RegId: '',
                TableSwitch: true,
                MergeBullet: false,
                SmartHeaderFooter: true,
                SmartTableOfContent: true,
                TranslateTo: ['en'],
                NameOfCapture: captureName,
                modeService: 'SelfDoc',
              },
            };
          }
          return null;
        })
        .filter((data) => data !== null);

      if (pdfResource.length === scopeSetting.length) {
        try {
          const newWorkload = await WorkloadCreateion({
            app: 'module2',
            action: 'SelfDoc',
            resources: pdfResource,
          });

          if (serviceFor.name === 'user') {
            const result = await userWorkloadRunPostPDF(
              Number(newWorkload.id),
              pdfResource,
              'm2-selfdoc'
            );
          }

          if (serviceFor.name === 'project') {
            const result = await workloadRunPostPDF(
              Number(newWorkload.id),
              pdfResource,
              'm2-selfdoc'
            );
          }

          setLoadingResult(false);
          return { error: false, message: 'success', workloadId: newWorkload.id };
        } catch (error) {
          setLoadingResult(false);
          return { error: true, message: 'Error while workload create.' };
        }
      } else {
        const allPromisesFun = scopeSetting
          .map((data, index) => {
            if (data.FileType === 'Excel') {
              const body = {
                FilePath: data.FilePath,
                Container: data.Container,
                BlobName: data.Blob,
                NameOfCapture: captureName,
                Sheet: fileSetting[index].sheetName,
                TargetColumn: fileSetting[index].targetColumn,
                ColumnNames: fileSetting[index].columns,
                Header: true,
              };
              return () => {
                return iCaptureExcel(body);
              };
            }

            if (data.FileType === 'CSV') {
              const body = {
                FilePath: data.FilePath,
                Container: data.Container,
                BlobName: data.Blob,
                NameOfCapture: captureName,
                TargetColumn: fileSetting[index].columnName,
                ColumnNames: fileSetting[index].columnNames,
                Header: true,
              };
              return () => {
                return iCaptureCsv(body);
              };
            }
            return null;
          })
          .filter((data) => data !== null);
        try {
          const allResponse = await Promise.all(allPromisesFun.map((fn) => fn()));
          // console.log(payload);
          const len =
            JSON.parse(allResponse[0]?.output?.Text)?.length > 0
              ? JSON.parse(allResponse[0]?.output?.Text)?.length
              : 0;
          if (len === 0) {
            return { error: true, message: 'Invalid file format' };
          }

          const filterScopeSettings = scopeSetting
            .map((data, index) => {
              if (data.FileType === 'Excel') {
                return {
                  ...data,
                  Sheet: data?.Sheets?.filter(
                    (data) => data.SheetName === fileSetting[index].sheetName
                  )[0],
                  SheetName: fileSetting[index].sheetName,
                  TargetColumn: fileSetting[index].targetColumn,
                };
              }

              if (data.FileType === 'CSV') {
                return {
                  ...data,
                  TargetColumn: fileSetting[index].columnName,
                  ColumnNames: fileSetting[index].columnNames,
                };
              }
              return null;
            })
            .filter((data) => data !== null);

          const r = allResponse.map((data, index) => {
            return {
              ...data,
              FileName: filterScopeSettings[index].Blob,
              scopeSettingOP: filterScopeSettings,
              TextLength: len,
            };
          });
          const payloadR = pdfResource.length > 0 ? [...pdfResource, ...r] : r;
          const newWorkload = await WorkloadCreateion({
            app: 'module2',
            action: 'SelfDoc',
            resources: payloadR,
          });

          if (serviceFor.name === 'user') {
            const result = await userWorkloadRunPost(
              Number(newWorkload.id),
              payloadR,
              'm2-selfdoc'
            );
          }

          if (serviceFor.name === 'project') {
            const result = await workloadRunPost(Number(newWorkload.id), payloadR, 'm2-selfdoc');
          }

          setLoadingResult(false);

          return { error: false, message: 'success', workloadId: newWorkload.id };
        } catch (error) {
          setLoadingResult(false);
          return { error: true, message: 'Error while capture.' };
        }
      }
    }
    return { error: false, message: 'success' };
  };

  const handleExtractTopicsAnalysis = async (serv) => {
    const checkError = HandleValidation();
    if (checkError.error) {
      return checkError;
    }
    if (!checkError.error) {
      // setLoadingResult(true);
      // console.log(scopeSetting);
      const containerIs = scopeSetting[0].Container;
      const FilterData = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          return {
            Container: data.Container,
            Blob: data.Blob,
            SheetName: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
          };
        }
        if (data.FileType === 'CSV') {
          return {
            Blob: data.Blob,
            ColumnNamesNoHeader: data.ColumnNamesNoHeader,
            Container: data.Container,
            LanguageCode: data.LanguageCode,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            TextNoHeader: data.TextNoHeader,
          };
        }
        if (data.FileType === 'PDF') {
          return {
            Blob: data.Blob,
            Container: data.Container,
            LanguageCode: data.LanguageCode,
            TargetColumn: 'forced',
          };
        }
        return null;
      });

      const body = {
        Container: containerIs,
        BlobFiles: FilterData,
        nameOfCapture: captureName,
      };
      try {
        const workloadNew = await WorkloadCreateion({
          app: 'module2',
          action: 'iner',
          resources: body,
        });

        if (serviceFor.name === 'user') {
          const result = await userWorkloadRunPost(
            Number(workloadNew.id),
            body,
            'm2-onto-param-analysis'
          );
        }

        if (serviceFor.name === 'project') {
          const result = await workloadRunPost(
            Number(workloadNew.id),
            body,
            'm2-onto-param-analysis'
          );
        }
        setLoadingResult(false);
        return { error: false, message: 'success', workloadId: workloadNew.id };
      } catch (error) {
        setLoadingResult(false);
        return { error: true, message: 'Error while workload create.' };
      }
    }
    return { error: false, message: 'success' };
  };

  const handleService = ({ workloadCreatePayload }) => {
    const { name, id } = serviceFor;
    if (name === 'project') {
      return workloadCreate({
        project_id: id,
        app: 'module2',
        action: 'Translator',
        resources: [workloadCreatePayload],
      });
    }

    return UserWorkloadCreate({
      app: 'module2',
      action: 'Translator',
      resources: [workloadCreatePayload],
    });
  };

  const handeTranslator = (payload) => {
    const { name, id } = serviceFor;
    // const { res } = payload;

    if (name === 'project') {
      return workloadRunPostForTranslator(
        Number(payload.id),
        id,
        docTranslation.toLang,
        [payload.res],
        docTranslation.model === 'DeepL' ? 'yes' : 'no'
      );
    }

    return userWorkloadRunPostForTranslator(
      Number(payload.id),
      id,
      docTranslation.toLang,
      [payload.res],
      docTranslation.model === 'DeepL' ? 'yes' : 'no'
    );
  };

  const validateDate = (eachSize) => {
    if (docTranslation.model !== 'DeepL') {
      if (eachSize.unit === 'MB' && eachSize.value > 40) {
        return { isInValide: true, limit: 40 };
      }
      return { isInValide: false, limit: 0 };
    }
    if (
      eachSize.unit === 'MB' &&
      eachSize.extension === 'txt' &&
      eachSize.value > deeplruntime.txt.value
    ) {
      return { isInValide: true, limit: deeplruntime.txt.value };
    }

    if (
      eachSize.unit === 'MB' &&
      eachSize.extension === 'html' &&
      eachSize.value > deeplruntime.html.value
    ) {
      return { isInValide: true, limit: deeplruntime.html.value };
    }

    if (
      eachSize.unit === 'MB' &&
      eachSize.extension === 'xlf' &&
      eachSize.value > deeplruntime.xlf.value
    ) {
      return { isInValide: true, limit: deeplruntime.xlf.value };
    }

    if (
      eachSize.unit === 'MB' &&
      eachSize.extension === 'xliff' &&
      eachSize.value > deeplruntime.xliff.value
    ) {
      return { isInValide: true, limit: deeplruntime.xliff.value };
    }

    if (eachSize.unit === 'MB' && eachSize.value > 30) {
      return { isInValide: true, limit: 30 };
    }
    return { isInValide: false, limit: 0 };
  };

  const TranslateFile = async () => {
    const size = scopeSetting.map((data) => {
      const stringSize = calculateSize(Number(data.size));
      const temp = stringSize?.split(' ');

      // console.log('temp', temp);
      return {
        value: Number(temp[0]),
        unit: temp[1],
        extension: data?.document?.filename?.split('.').pop(),
      };
    });

    for (const eachSize of size) {
      const check = validateDate(eachSize);
      if (check.isInValide) {
        // setSnack(`File size for ${model} should be less than ${check.limit}MB`, 'error');
        return {
          error: true,
          message: `File size for ${docTranslation.model} should be less than ${check.limit}MB`,
        };
      }
    }
    setLoadingResult(true);
    const selectedRows = scopeSetting.map((data, index) => {
      if ('glossary' in data && data.glossary.length > 0) {
        return {
          document: {
            FileName: data.filename,
            Container: data.container,
            size: calculateSize(Number(data.size)),
          },
          glossaryFile: {
            FileName: data.glossary[0].filename,
            Container: data.glossary[0].container,
            size: calculateSize(Number(data.glossary[0].size)),
          },
          fromLanguage: docTranslation.fromLang,
          toLanguage: docTranslation.toLang,
        };
      }

      const obj = {
        document: {
          FileName: data.filename,
          Container: data.container,
          size: calculateSize(Number(data.size)),
        },
        fromLanguage: docTranslation.fromLang,
        toLanguage: docTranslation.toLang,
      };

      return obj;
    });
    const promisses = [] as any;
    const res = [] as any;
    for (const eachpayload of selectedRows) {
      const obj = {
        ...eachpayload,
      };
      res.push(obj);
      const workloadCreatePayload = {
        ...eachpayload.document,
        toLanguage: eachpayload.toLanguage,
        deepL: docTranslation.model === 'DeepL' ? 'yes' : 'no',
      };
      promisses.push(() => {
        return handleService({ workloadCreatePayload });
      });
    }

    try {
      const result = await Promise.all(promisses.map((fn) => fn()));
      const wl = result.map((data) => data.id);
      // setWorkloads(wl);

      const translatorCALL = result.map((data, index) => {
        return () => handeTranslator({ id: data.id, res: res[index] });
      });

      try {
        await Promise.all(translatorCALL.map((fn) => fn()));
        // await new Promise((resolve) => setTimeout(resolve, 10000));
        setLoadingResult(false);
        return { error: false, message: 'success', workloadIds: wl };
      } catch {
        setLoadingResult(false);
        return { error: true, message: 'Error while workload post.' };
      }
    } catch {
      setLoadingResult(false);
      return { error: true, message: 'Error while workload create.' };
    }
  };

  const handleAnalyze = async () => {
    switch (serviceName) {
      case 'Similarity Analysis': {
        const reuslt = await SimilarityAnalysis('DocToDataset');
        return reuslt;
      }
      // return null;
      case 'Document Similarity': {
        const reuslt = await TwoFileAnalysis('DocToDoc');
        return reuslt;
      }
      case 'Compare Versions': {
        const reuslt = await TwoFileAnalysis('docdiffer');
        return reuslt;
      }
      case 'Extract Topics': {
        const reuslt = await handleExtractTopicsAnalysis('iner');
        return reuslt;
      }
      case 'Find Duplicates': {
        const reuslt = await handleOneFileServise('SelfDoc');
        return reuslt;
      }

      case 'Document Translator': {
        const reuslt = TranslateFile();
        return reuslt;
      }
      case 'Rex Book': {
        const reuslt = await handleAnalyzeRex('RexBook');
        return reuslt;
      }
      default:
        return { error: true, message: 'Invalid service' };
    }
  };

  return { handleAnalyze, loadingResult, setLoadingResult };
};

export default useModule2services;
