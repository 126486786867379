/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Dialog, DialogTitle, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import '../../../../../assets/treeview.css';
import { useParams } from 'react-router-dom';
import Loading from '../../../../../components/Core/Loading';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import CustomerDocumentTab from './CustomerDocumentTab';
import { scheduleStreamPhases } from '../../../../../api/workloads';
import { workloadGetRfpListbyPageNumber } from '../../../../../api/module1';
// import Loading from '../../components/Core/Loading';
// import TableComponent from '../../components/Datagrid/TableComponent';
// import ToolBarPlugin from '../../components/Datagrid/ToolBarPlugin';
// import {
//   tableContainerComponent,
//   tableHeaderComponent,
//   toolbarComponent,
// } from '../../components/Datagrid/TStyles';
// import { handleColumns } from '../../tools/datagrid';

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: 'unset',
    },
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 2,
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

type Props = {
  WI: Number;
  FileName: string;
  //   template?: {
  //     name: string;
  //     version: string;
  //   };
  onClose?: any;
};
const columns = ['id', 'function', 'mapping', 'example', 'created_at', 'updated_at'];

export default function ModalCdaViewer({ WI, onClose, FileName }: Props) {
  const classes = useStyles();
  const { projectId, workloadId } = useParams() as any;

  const [loading, setLoading] = useState<any>(false);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [finalPayload, setFinalPayload] = useState([]);
  const [listRFPData, setListRFPData] = useState([]);

  const renderFunction = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <b>{value}</b>
      </>
    );
  };
  useEffect(() => {
    setLoading(true);
    scheduleStreamPhases(WI)
      .then((payload) => {
        setFinalPayload(payload);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [WI]);
  useEffect(() => {
    workloadGetRfpListbyPageNumber(projectId).then((response) => {
      setListRFPData(response);
    });
  }, [WI]);

  const handleClose = () => {
    onClose();
  };
  const handleChanges = (data) => {
    scheduleStreamPhases(data)
      .then((payload) => {
        setFinalPayload(payload);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };
  return (
    <Paper>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="lg"
        fullWidth={true}
        className={classes.scrollbar}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          {/* Template : {template?.name} version : {template?.version} */}
          Customer Document Analysis
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                <CustomerDocumentTab
                  payloadFinal={finalPayload}
                  listRfp={listRFPData}
                  fileName={FileName}
                  handleLoading={handleChanges}
                  workloadPast={WI}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Dialog>
    </Paper>
  );
}
