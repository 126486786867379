const get_previous_state = (state, key, storedKey) => {
  const values_obj = {};
  const storedData = localStorage.getItem(storedKey);
  if (storedData) {
    const data = JSON.parse(storedData);

    if (key in data) {
      const temp = data[key];
      if (temp && temp.length > 0) {
        for (let i = 0; i < temp.length; i += 1) {
          values_obj[temp[i]] = true;
        }
      }
    } else if (state && state.length > 0) {
      for (let i = 0; i < state.length; i += 1) {
        values_obj[state[i]] = true;
      }
    }
  } else if (state && state.length > 0) {
    for (let i = 0; i < state.length; i += 1) {
      values_obj[state[i]] = true;
    }
  }
  return values_obj;
};

export const set_selected_option = (key, option, storedKey) => {
  const storedData = localStorage.getItem(storedKey);
  if (storedData) {
    const data = JSON.parse(storedData);
    data[key] = option;
    localStorage.setItem(storedKey, JSON.stringify(data));
  } else {
    localStorage.setItem(storedKey, JSON.stringify({ [key]: option }));
  }
};

export const get_default_state = (key, state, storedKey) => {
  const storedData = localStorage.getItem(storedKey);
  if (storedData) {
    const data = JSON.parse(storedData);
    if (data[key] && data[key].length > 0) {
      return data[key];
    }
  }
  return state;
};

export default get_previous_state;
