/* eslint-disable no-param-reassign */
import React, { useContext, useMemo } from 'react';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import { iSearcheSixLayerNew, textToText } from '../../../api/module2';
import get_previous_state, { get_default_state, set_selected_option } from './utiles';
import SliderComponent from './components/SliderComponent';
import ToggleComponent from './components/ToggleComponent';

const SimilarityFilters = ({
  similarityFilter,
  dispatch,
  callbackFilters,
  selectedOptionRef,
  service = '',
}) => {
  if (!similarityFilter) {
    return { options: [] };
  }
  const {
    simiProductLine,
    simiPlatform,
    simiSolution,
    simiProduct,
    simiProject,
    simiDocumentProfile,
    selectedSimiProductLine,
    selectedSimiPlatform,
    selectedSimiSolution,
    selectedSimiProduct,
    selectedSimiProject,
    selectedSimiDocumentProfile,
    countValue,
    threshold,
  } = similarityFilter;

  // API call for the filters
  const multifilterCallback = (key) => {
    try {
      const {
        alstomEntities,
        projects,
        documentProfiles,
        platforms,
        products,
        solutions,
      } = selectedOptionRef.current;
      textToText(alstomEntities, platforms, solutions, products, projects, documentProfiles).then(
        (res: any) => {
          const check = [
            'alstomEntities',
            'projects',
            'documentProfiles',
            'platforms',
            'products',
            'solutions',
          ];
          if (res?.filters) {
            // here we are checking if all the options are available or not
            let optionsAvailable = false;
            for (let i = 0; i < check.length; i += 1) {
              if (res?.filters[check[i]] && res?.filters[check[i]].length > 0) {
                optionsAvailable = true;
              } else {
                optionsAvailable = false;
                break;
              }
            }

            // if all the options are available then we will not send the default options

            if (!optionsAvailable) {
              const temp = {
                alstomEntities: simiProductLine,
                projects: simiProject,
                documentProfiles: simiDocumentProfile,
                platforms: simiPlatform,
                products: simiProduct,
                solutions: simiSolution,
              };
              callbackFilters(temp, 'similarityFilter');
              return;
            }

            // otherwise we will send the options which are available
            const defaultTemp = {
              ...res.filters,
            };
            const {
              alstomEntities,
              projects,
              documentProfiles,
              platforms,
              products,
              solutions,
            } = selectedOptionRef.current;

            // if the selected options are not available in the response then we will remove the selected options
            if (
              alstomEntities.length === 0 &&
              platforms.length === 0 &&
              solutions.length === 0 &&
              products.length === 0 &&
              projects.length === 0 &&
              documentProfiles.length === 0
            ) {
              callbackFilters(defaultTemp, 'similarityFilter');
            } else {
              // if the selected options are available in the response then we will keep the selected options
              delete defaultTemp[key];
              callbackFilters(defaultTemp, 'similarityFilter');
            }
          }
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  // const get_previous_state = (state) => {
  //   const values_obj = {};
  //   if (state && state.length > 0) {
  //     for (let i = 0; i < state.length; i += 1) {
  //       values_obj[state[i]] = true;
  //     }
  //   }
  //   return values_obj;
  // };

  // const get_default_state = (key, state) => {
  //   const storedData = localStorage.getItem('similarityFilter');
  //   if (storedData) {
  //     const data = JSON.parse(storedData);
  //     if (data[key] && data[key].length > 0) {
  //       return data[key];
  //     }
  //   }
  //   return state;
  // };

  const temp = [
    {
      name: 'Product Line',
      key: 'alstomEntities',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('alstomEntities', simiProductLine, 'similarityFilter'),
      _cache: get_previous_state(
        selectedSimiProductLine,
        'alstomEntities',
        'selectedSimilarityFilter'
      ),
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_ProductLine', payload: option });
        set_selected_option('alstomEntities', option, 'selectedSimilarityFilter');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          alstomEntities: option,
        };
        multifilterCallback('alstomEntities');
      },
    },
    {
      name: 'Platforms',
      key: 'platforms',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('platforms', simiPlatform, 'similarityFilter'),
      _cache: get_previous_state(selectedSimiPlatform, 'platforms', 'selectedSimilarityFilter'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Platform', payload: option });
        set_selected_option('platforms', option, 'selectedSimilarityFilter');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          platforms: option,
        };
        multifilterCallback('platforms');
      },
    },
    {
      name: 'Solution',
      key: 'solutions',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('solutions', simiSolution, 'similarityFilter'),
      _cache: get_previous_state(selectedSimiSolution, 'solutions', 'selectedSimilarityFilter'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Solution', payload: option });
        set_selected_option('solutions', option, 'selectedSimilarityFilter');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          solutions: option,
        };
        multifilterCallback('solutions');
      },
    },
    {
      name: 'Product',
      key: 'products',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('products', simiProduct, 'similarityFilter'),
      _cache: get_previous_state(selectedSimiProduct, 'products', 'selectedSimilarityFilter'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Product', payload: option });
        set_selected_option('products', option, 'selectedSimilarityFilter');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          products: option,
        };
        multifilterCallback('products');
      },
    },
    {
      name: 'Projects',
      key: 'projects',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('projects', simiProject, 'similarityFilter'),
      _cache: get_previous_state(selectedSimiProject, 'projects', 'selectedSimilarityFilter'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Project', payload: option });
        set_selected_option('projects', option, 'selectedSimilarityFilter');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          projects: option,
        };
        multifilterCallback('projects');
      },
    },
    {
      name: 'Document Profiles',
      key: 'documentProfiles',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('documentProfiles', simiDocumentProfile, 'similarityFilter'),
      _cache: get_previous_state(
        selectedSimiDocumentProfile,
        'documentProfiles',
        'selectedSimilarityFilter'
      ),
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_DocumentProfile', payload: option });
        set_selected_option('documentProfiles', option, 'selectedSimilarityFilter');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          documentProfiles: option,
        };
        multifilterCallback('documentProfiles');
      },
    },
  ];

  const ClearFilters = () => {
    dispatch({ type: 'claear_simi_filters' });
    localStorage.removeItem('selectedSimilarityFilter');
    localStorage.removeItem('similarityFilter');
  };

  const otherFilters = {
    isOtherComponent: service !== 'GenA',
    renderComponent: (props) => {
      return (
        <>
          <SliderComponent
            dispatch={dispatch}
            type="simi_change_count"
            defaultValue={60}
            reset={props}
          />
          <SliderComponent
            dispatch={dispatch}
            type="simi_change_Threshold"
            label="Similarity Score"
            defaultValue={60}
            reset={props}
          />
          {/* <ToggleComponent dispatch={dispatch} type="set_isearcher_door" /> */}
        </>
      );
    },
  };
  return { options: temp, otherFilters, ClearFilters };
};

export default SimilarityFilters;
