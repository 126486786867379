import React, { memo, useCallback } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Filters from './Filters';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  fontSize: 'average',
  // [theme.breakpoints.up('xl')]: {
  //   fontSize: 'x-large',
  // },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1em',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.2em',
  },
}));

const LeftSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  flexBasis: '17%',
  width: '17%',
  height: '100%',
  padding: '0.5rem',
  backgroundColor: '#EEF1F9',
  maxHeight: '100%',
  overflow: 'auto',
  transition: 'all 0.3s ease-out',
  // [theme.breakpoints.up('lg')]: {
  //   width: '20%',
  //   flexBasis: '20%',
  // },

  [theme.breakpoints.down('lg')]: {
    width: '20%',
    flexBasis: '20%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '20%',
    flexBasis: '20%',
  },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#002A45',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  //   [theme.breakpoints.down('lg')]: {
  //     width: '20%',
  //     flexBasis: '20%',
  //   },

  //   [theme.breakpoints.down('md')]: {
  //     width: '20%',
  //     flexBasis: '20%',
  //   },
  '&.collapsed': {
    width: '0',
    flexBasis: '0',
    padding: '0',
    overflow: 'hidden',
    '& > *': {
      transition: 'all 0.3s ease-out',
      width: '0',
      overflow: 'hidden',
    },
  },
}));

const RightSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  flexBasis: '83%',
  width: '83%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '100%',
  transition: 'all 0.3s ease-out',
  // [theme.breakpoints.up('lg')]: {
  //   width: '80%',
  //   flexBasis: '80%',
  // },

  [theme.breakpoints.down('lg')]: {
    width: '80%',
    flexBasis: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    flexBasis: '80%',
  },

  //   [theme.breakpoints.down('lg')]: {
  //     width: '80%',
  //     flexBasis: '80%',
  //   },

  //   [theme.breakpoints.down('md')]: {
  //     width: '80%',
  //     flexBasis: '80%',
  //   },
  '&.full': {
    width: '100%',
    flexBasis: '100%',
  },
}));

const ContentSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  minHeight: '100%',
  // overflow: 'auto',
  // padding: '0.5rem',
  position: 'relative',
  transition: 'all 0.3s ease-out',
}));

const FilterComponent = memo(({ children, isCollapsed, setCollapsed }) => {
  // const [isCollapsed, setIsCollapsed] = React.useState(true);
  // const { sideFilter: isCollapsed, setSideFilter } = React.useContext(M2GlobalContext);
  const { open, service } = isCollapsed;
  const toggleCollapse = useCallback((check = true) => {
    // console.log('isCollapsed:', check);
    setCollapsed((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <>
      <Container>
        <LeftSection className={!open ? 'collapsed' : ''}>
          <Filters toggleCollapse={toggleCollapse} service={service} />
        </LeftSection>
        <RightSection className={!open ? 'full' : ''}>
          <ContentSection>{children}</ContentSection>
        </RightSection>
      </Container>
    </>
  );
});

export default FilterComponent;
