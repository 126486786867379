import JSZip from 'jszip';
import { getBearerToken } from '../app/AppToken';
// import axios from '../api/axios';

const zip = new JSZip();

const CreateZip = async (urls: any[], zipFolderName: string) => {
  // const duplicateCheck = [] as string[];
  const addFilesToZip = async (url: any[], filename: string) => {
    // console.log(axios);
    const token = await getBearerToken();
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.blob();
      // if (duplicateCheck.includes(filename)) {
      //   const count = duplicateCheck.filter((item) => item === filename).length;
      //   const tempFile = filename.split('.');
      //   const f = tempFile.slice(0, tempFile.length - 1).join('');
      //   const ext = tempFile[tempFile.length - 1];
      //   // console.log(`${f}${count === 0 ? '_copy' : `_copy${count}`}.${ext}`);
      //   zip.file(filename, data);
      // } else {
      //   zip.file(filename, data);
      // }
      zip.file(filename, data);
      // duplicateCheck.push(filename);
    } catch (error) {
      console.error('Error adding file to zip', error);
    }
  };

  const promises = urls.map((data, index) => {
    const { url, filename } = data;
    return () => {
      return addFilesToZip(url, filename);
    };
  });

  try {
    await Promise.all(promises.map((promise) => promise()));
    const content = await zip.generateAsync({ type: 'blob' });
    if (content instanceof Blob) {
      const url = window.URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      const date = new Date();
      const formattedDate = `${date.getFullYear()}${date
        .getSeconds()
        .toString()
        .padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;

      a.download =
        zipFolderName.length > 0 ? `${zipFolderName}.zip` : `doc_${formattedDate}_folder.zip`;
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Invalid Blob data');
    }
  } catch (error) {
    console.error('Error generating zip archive:', error);
  }
};

export default CreateZip;
