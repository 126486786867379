import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import HistoryIcon from '@mui/icons-material/History';
import LayoutContainer from '../../../layouts/LayoutContainer';
import History from '../../../components/History/History';
import DetailforTransalator from '../../../components/History/components/DetailforTransalator';
import UserFileManager from '../FileManger/UserFileManager';
import SimilarityAnalysis from '../Components/SimilarityAnalysis';
import Description from '../../Module2/Module/Description';
import CompareVersion from '../Components/CompareVersion';
import ExtractTopics from '../Components/ExtractTopics';
import FindDuplicates from '../Components/FindDuplicates';
import RexBook from '../Components/RexBook';
import { UserSpaceContextProvider } from '../UserSpaceContext';
import useAppToken from '../../../app/useAppToken';
import ResultComponents from '../../ResultContainer/DocumentAnalysisContainer/ResultComponents';
import DocumentSimilarity from '../Components/DocumentSimilarity';
import {
  comapreVersionDescription,
  documentSimilarityDescription,
  extractTopicDescription,
  findDuplicatesDescription,
  similarityDescription,
} from '../../../const';
// import History from '../../Module2/Module/History';

const DocumentAnalysisContainer = () => {
  const { appJWT } = useAppToken();
  const { userId, workloadId } = useParams();
  const { user_id } = appJWT;
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('tab');

  const actionServiceIs = queryParams.get('service');

  const handleService = (actionServiceIs) => {
    switch (actionServiceIs) {
      case 'Similarity Analysis':
        return 0;
      case 'Document Similarity':
        return 1;
      case 'Compare Versions':
        return 2;
      case 'Extract Topics':
        return 3;
      case 'Find Duplicates':
        return 4;
      case 'Rex Book':
        return 5;
      default:
        return 0;
    }
  };

  const handleResultService = (actionIs) => {
    switch (actionIs) {
      case 'user_history':
        return 1;
      case 'result':
        return 2;
      default:
        return 0;
    }
  };

  const componentsData = useMemo(
    () => [
      {
        name: 'Similarity Analysis',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        numberOfFilesSupport: 1,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        route: '/SimilarityAnalysis',
        buttonRoute: '/userspace/DocAnalysis',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Similarity Analysis',
            numberOfFilesSupport: 1,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <SimilarityAnalysis {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: () => {
              return (
                <>
                  <Description list={similarityDescription} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Document Similarity',
        projectName: '',
        numberOfFilesSupport: 2,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/DocumentSimilarity',
        buttonRoute: '/userspace/DocAnalysis',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Document Similarity',
            numberOfFilesSupport: 2,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            renderComponent: (props) => {
              return (
                <>
                  <DocumentSimilarity {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: () => {
              return (
                <>
                  <Description list={documentSimilarityDescription} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Compare Versions',
        projectName: '',
        numberOfFilesSupport: 2,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/Compareversions',
        buttonRoute: '/userspace/DocAnalysis',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Compare Versions',
            numberOfFilesSupport: 2,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <CompareVersion {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            renderDescription: () => {
              return (
                <>
                  <Description list={comapreVersionDescription} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Extract Topics',
        projectName: '',
        numberOfFilesSupport: 5,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/Extracttopics',
        buttonRoute: '/userspace/DocAnalysis',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Extract Topics',
            numberOfFilesSupport: 5,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            select: 'multiple',
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <ExtractTopics {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            renderDescription: () => {
              return (
                <>
                  <Description list={extractTopicDescription} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Find Duplicates',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon />,
        route: 'Findduplicates',
        buttonRoute: '/userspace/DocAnalysis',
        numberOfFilesSupport: 1,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Find Duplicates',
            numberOfFilesSupport: 1,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <FindDuplicates {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            renderDescription: () => {
              return (
                <>
                  <Description list={findDuplicatesDescription} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      // {
      //   name: 'Rex Book',
      //   projectName: '',
      //   icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
      //   route: '/Rexbook',
      //   buttonRoute: '/userspace/DocAnalysis',
      //   numberOfFilesSupport: 1,
      //   fileTypeSupport: ['pdf'],
      //   enableLeftOption: true,
      //   leftOption: [
      //     {
      //       name: 'Rex book',
      //       numberOfFilesSupport: 1,
      //       fileTypeSupport: ['pdf'],
      //       icon: <TranslateIcon fontSize="small" />,
      //       renderComponent: (props) => {
      //         return (
      //           <>
      //             <RexBook {...props} />
      //           </>
      //         );
      //       },
      //       renderFiles: (props) => {
      //         return (
      //           <>
      //             <UserFileManager {...props} />
      //           </>
      //         );
      //       },
      //       renderDescription: () => {
      //         const list = [
      //           'REX Book is a compilation of lessons and Return of Experiences (REX) from the past, and applicable to your tender or project to avoid re-doing the same mistakes as in the past.',
      //           'Upon uploading a document in form of pdf, excel or csv, the REX Book service looks for and complies a list of REX applicable to your tender or project.',
      //           'You can download the REX Book document and share it across to the relevant PICs and stakeholders.',
      //         ];
      //         return (
      //           <>
      //             <Description list={list} />
      //           </>
      //         );
      //       },
      //       enableDescription: true,
      //     },
      //     {
      //       name: 'History',
      //       icon: <HistoryIcon />,
      //       renderComponent: (props) => {
      //         return (
      //           <>
      //             <History {...props} />
      //           </>
      //         );
      //       },
      //     },
      //   ],
      // },
    ],
    [actionIs, actionServiceIs]
  );

  return (
    <>
      <LayoutContainer
        componentsData={componentsData}
        workload={{
          workload: 0,
          asyncWorkload: workloadId ?? 0,
          workloads: [],
          serviceFor: { name: 'user', id: user_id },
          selectedIndex: handleService(actionServiceIs),
          selectedChildIndex: handleResultService(actionIs),
        }}
      />
    </>
  );
};

export default memo(DocumentAnalysisContainer);
