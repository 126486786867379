import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid as ExpressGrid,
  Table,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Card, CardMedia } from '@mui/material';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import React, { useCallback, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import { tableHeaderComponent } from '../../../../../components/Datagrid/TStyles';
import { handleColumns } from '../../../../../tools/datagrid';
import { rawTypeEnum } from './ChararcterizerFlow';
import ModalRawDataTable from './ModalRawDataTable';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    // position: 'absolute',
    // left: theme.spacing(110),
    // color: theme.palette.grey[100],
    position: 'absolute',
    right: theme.spacing(1),
    // paddingBottom: 5,
    top: -8,
    color: theme.palette.grey[500],
  },
  imagePanel: {
    // border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: 10,
    margin: 25,
    paddingTop: 2.5,
    paddingBottom: 1,
    // minHeight: 200,
    // display: 'flex',
    // justifyContent: 'center',
    // alignContent: 'center',
  },
  mainBox1: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    margin: 35,
    paddingTop: 2.5,
    paddingBottom: 1,
  },
  mainCard1: {
    // width: '100%',
    objectFit: 'none',
    display: 'flex',
    justifyItems: 'center',
    alignContent: 'center',
    height: 500,
    // marginTop: 5,
  },
  mainCardMedia1: {
    width: '100%',
    height: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 1,
  },
  textColor: {
    color: theme.palette.primary.main,
  },
}));
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
};
type Props = {
  imagesList: any[];
  tablesList: any[];
  rawType?: rawTypeEnum;
  onClose: any;
};

export default function ModalRawdata({ imagesList, tablesList, rawType, onClose }: Props) {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onDownload = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  // table container
  const tableContainerComponent: React.FunctionComponent<Table.CellProps> = (props) => {
    return (
      <Table.Container
        {...props}
        style={{ maxHeight: '70vh', overflowY: 'auto', border: '1px solid black' }}
      />
    );
  };
  // table toolbar
  const toolbarComponent: React.FunctionComponent<Toolbar.RootProps> = (props) => {
    return (
      <Toolbar.Root
        {...props}
        style={{ minHeight: 0, backgroundColor: '#EEF1F9', border: '1px solid black' }}
      />
    );
  };
  // download image
  const download = (e) => {
    // console.log(e.target.href);
    fetch(e.target.href, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.png'); // or any other extension
          // document.body.appendChild(link);
          // link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          <span className={classes.textColor}>.</span>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box>
            {(!rawType || rawType === 'images') && imagesList && imagesList.length && (
              <Carousel
                swipeable={true}
                showDots={true}
                responsive={responsive}
                infinite={true}
                arrows={true}
                keyBoardControl={true}
                renderArrowsWhenDisabled={true}
              >
                {imagesList.map((image: any, index: number) => {
                  return (
                    <Box className={classes.mainBox1} key={`box-${index}`}>
                      <>
                        <Card className={classes.mainCard1}>
                          <TransformWrapper>
                            {({ zoomIn, zoomOut, ...rest }) => (
                              <>
                                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                                  <IconButton
                                    onClick={zoomIn}
                                    style={{
                                      backgroundColor: '#002A45',
                                      height: 35,
                                      borderRadius: 5,
                                    }}
                                  >
                                    <ZoomInOutlinedIcon style={{ color: 'white' }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={zoomOut}
                                    style={{
                                      backgroundColor: '#002A45',
                                      height: 35,
                                      borderRadius: 5,
                                      marginLeft: 5,
                                    }}
                                  >
                                    <ZoomOutOutlinedIcon style={{ color: 'white' }} />
                                  </IconButton>
                                </div>
                                <a
                                  href={`data:image/png;base64, ${image.data}`}
                                  download
                                  onClick={(e) => download(e)}
                                  label="aa"
                                >
                                  <FileDownloadIcon
                                    style={{ color: '#002A45' }}
                                    titleAccess="download"
                                  />
                                </a>
                                {/* <Button onClick={downloadFile(image.data)} /> */}
                                <TransformComponent>
                                  <CardMedia
                                    onClick={zoomOut}
                                    className={classes.mainCardMedia1}
                                    // height="500"
                                    component="img"
                                    src={`data:image/png;base64, ${image.data}`}
                                    // style={{ width: 300, marginLeft: 'auto', marginRight: 'auto' }}
                                  />
                                </TransformComponent>
                              </>
                            )}
                          </TransformWrapper>
                        </Card>
                      </>
                    </Box>
                  );
                })}
              </Carousel>
            )}
            {rawType === 'tables' && tablesList.length && (
              <Carousel
                swipeable={true}
                showDots={true}
                responsive={responsive}
                infinite={true}
                arrows={true}
                keyBoardControl={true}
                renderArrowsWhenDisabled={true}
              >
                {tablesList.map((table: any, index: number) => {
                  return table.data.rows ? (
                    <ModalRawDataTable table={table} index={index} onClose={onClose} />
                  ) : (
                    <Box className={classes.mainBox1} key={`box-${index}`}>
                      <>
                        <Card className={classes.mainCard1}>
                          <TransformWrapper>
                            {({ zoomIn, zoomOut, ...rest }) => (
                              <>
                                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                                  <IconButton
                                    onClick={zoomIn}
                                    style={{
                                      backgroundColor: '#002A45',
                                      height: 35,
                                      borderRadius: 5,
                                    }}
                                  >
                                    <ZoomInOutlinedIcon style={{ color: 'white' }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={zoomOut}
                                    style={{
                                      backgroundColor: '#002A45',
                                      height: 35,
                                      borderRadius: 5,
                                      marginLeft: 5,
                                    }}
                                  >
                                    <ZoomOutOutlinedIcon style={{ color: 'white' }} />
                                  </IconButton>
                                </div>
                                <a
                                  href={`data:image/png;base64, ${table.data}`}
                                  download
                                  onClick={(e) => download(e)}
                                  label="aa"
                                >
                                  <FileDownloadIcon
                                    style={{ color: '#002A45' }}
                                    titleAccess="download"
                                  />
                                </a>
                                {/* <Button onClick={downloadFile(table.data)} /> */}
                                <TransformComponent>
                                  <CardMedia
                                    onClick={zoomOut}
                                    className={classes.mainCardMedia1}
                                    // height="500"
                                    component="img"
                                    src={`data:image/png;base64, ${table.data}`}
                                    // style={{ width: 300, marginLeft: 'auto', marginRight: 'auto' }}
                                  />
                                </TransformComponent>
                              </>
                            )}
                          </TransformWrapper>
                        </Card>
                      </>
                    </Box>
                  );
                })}
              </Carousel>
            )}
          </Box>
        </DialogContent>
        {/* <DialogActions className={classes.dialogContent}>
          <Button autoFocuscolor="primary" variant="contained">
            Download*
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
