import React, { useContext, useEffect, useMemo } from 'react';
import { Box, Slider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const ToggleContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  // width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '0rem 0.5rem',
  '& .MuiToggleButtonGroup-root': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiToggleButton-root': {
      '&.Mui-selected': {
        backgroundColor: '#002A45',
        color: '#fff',
      },
    },
  },
}));

const ToggleComponent = ({ dispatch, type, reset }) => {
  const [door, setDoor] = React.useState('*');

  useEffect(() => {
    setDoor('*');
  }, [reset]);

  const handleChangeDoor = (event, newDoor) => {
    if (newDoor === null) {
      dispatch({ type: 'set_isearcher_door', payload: '*' });
    } else {
      dispatch({ type: 'set_isearcher_door', payload: newDoor });
    }
    setDoor(newDoor);
  };

  return (
    <ToggleContainer>
      <ToggleButtonGroup size="small" value={door} exclusive onChange={handleChangeDoor}>
        <ToggleButton value="*">All</ToggleButton>
        <ToggleButton value="DOORS">Dataset</ToggleButton>
        {/* {callOperation === 'Content Search' && <ToggleButton value="AEC">AEC</ToggleButton>} */}
        <ToggleButton value="user_docs">User Docs</ToggleButton>
      </ToggleButtonGroup>
    </ToggleContainer>
  );
};

export default ToggleComponent;
