/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Dialog, Grid, IconButton, makeStyles, Paper, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useContext, useRef, useState } from 'react';
import '../../../../../assets/treeview.css';
import { List, ListItem } from '@mui/material';
import { saveAs } from 'file-saver';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import TextField from '@mui/material/TextField';
// import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Loading from '../../../../../components/Core/Loading';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import CustomerDocumentTab from './CustomerDocumentTab';
import ProjectContext from '../../../../Project/ProjectContext';
import { scheduleKeyDates } from '../../../../../api/workloads';

const initialData = [
  {
    cluster_id: 0,
    date: '02/08/2029',
    unit: 'NA',
    info: 'SCHEDULE 23 Cyber Security 02 08 2029 is the last for security',
    cluster_name: 'Engineering/Design',
  },
  {
    cluster_id: 1,
    date: '7',
    unit: 'Days',
    info: '7 days after NTP we need to start maintenance',
    cluster_name: 'Warranty',
  },
];

const columns = [
  { name: 'info', title: 'Description of Key Dates' },
  { name: 'unit', title: 'Unit' },
  { name: 'date', title: 'Dates/Duration' },
  { name: 'cluster_name', title: 'Cluster Name' },
  { name: 'actions', title: 'Actions' },
];

export default function CdaTabTableResult({ FinalData, streamDates }) {
  // const classes = useStyles();
  const { setSnack } = useContext(ProjectContext);
  const [rows, setRows] = useState(
    streamDates?.schedule === undefined ? [] : streamDates?.schedule
  );
  const [editedRows, setEditedRows] = useState({});
  const [deletedRows, setDeletedRows] = useState(new Set());
  const [showSave, setShowSave] = useState(false);
  const [toggleHeight, setToggleHeight] = useState(true);
  const exporterRef = useRef(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editingColumn, setEditingColumn] = useState(null);
  const [editedValue, setEditedValue] = useState('');
  const [isDatePicker, setIsDatePicker] = useState(false);
  const [datePickerState, setDatePickerState] = useState({});
  const [newRow, setNewRow] = useState(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSaveExport = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  const handleEdit = (rowId, columnName, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.cluster_id === rowId ? { ...row, [columnName]: value, newId: Date.now() } : row
      )
    );
    setEditedRows((prev) => ({ ...prev, [rowId]: true }));
    setShowSave(true);
  };

  const handleDelete = (rowId) => {
    setDeletedRows((prev) => new Set([...prev, rowId]));
    setRows((prevRows) => prevRows.filter((row) => row.cluster_id !== rowId));
    setShowSave(true);
  };

  const handleSave = async () => {
    try {
      const updatedRows = rows.filter((row) => !deletedRows.has(row.cluster_id));
      FinalData.stream_dataes.response.schedule = updatedRows;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await saveDataToDatabase(FinalData); // Mock API function
      setRows(updatedRows);
      setEditedRows({});
      setDeletedRows(new Set());
      setShowSave(false);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };
  // Mock API function
  const saveDataToDatabase = async (data) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    scheduleKeyDates(data?.stream_dataes, FinalData?.id)
      .then((p) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setSnack('updated successfully.');
        // setFinalCall(0);
      })
      .catch((err) => {
        // setHelperText(err.detail);
        // setError(true);
      })
      .finally(() => {
        // setLoading(false);
      });
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };

  // const EditableCell = ({ row, column, value }) => (
  //   <input
  //     type="text"
  //     value={value}
  //     onChange={(e) => handleEdit(row.cluster_id, column.name, e.target.value)}
  //   />
  // );

  const handleCellEditStart = (rowId, columnName, value) => {
    setEditingRow(rowId);
    setEditingColumn(columnName);

    // eslint-disable-next-line no-restricted-globals
    if (columnName === 'date') {
      if (!isNaN(value)) {
        setEditedValue(value);
        setDatePickerState((prev) => ({ ...prev, [rowId]: false }));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setEditedValue(formatDateForInput(value));
        setDatePickerState((prev) => ({ ...prev, [rowId]: true }));
      }
    } else setEditedValue(value);
  };

  const handleCellEditChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleDateChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleCellEditEnd = (e) => {
    e.stopPropagation();
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.cluster_id === editingRow ? { ...row, [editingColumn]: editedValue } : row
      )
    );
    setEditedRows((prev) => ({ ...prev, [editingRow]: true }));
    setShowSave(true);
    setEditingRow(null);
    setEditingColumn(null);
    setIsDatePicker(false);
  };

  // const switchToDatePicker = (event) => {
  //   event.stopPropagation(); // Prevent immediate re-render
  //   setTimeout(() => setIsDatePicker(true), 0); // Delayed state update
  // };
  const switchToDatePicker = (event, rowId) => {
    event.preventDefault(); // Prevent default focus loss
    event.stopPropagation(); // Prevent unnecessary re-renders

    setDatePickerState((prev) => ({ ...prev, [rowId]: true }));
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  const handleAddRow = () => {
    const lastClusterId = rows.length > 0 ? rows[rows.length - 1].cluster_id : 0;
    const newRowData = {
      cluster_id: lastClusterId + 1,
      date: '0',
      unit: 'NA',
      info: '...',
      cluster_name: '...',
    };
    setRows((prevRows) => [...prevRows, newRowData]);
    setEditingRow(newRowData.cluster_id); // Auto-edit the new row
    setEditingColumn('info'); // Start editing from 'info' column
    setEditedValue('');
    setShowSave(true);
  };

  const EditableCell = ({ row, column, value }) => {
    const isDatePicker = datePickerState[row.cluster_id];

    return editingRow === row.cluster_id && editingColumn === column.name ? (
      (console.log('column.name', column.name),
      column.name === 'date' ? (
        <>
          {isDatePicker ? (
            <TextField
              type="date"
              value={editedValue}
              onChange={handleDateChange}
              onBlur={handleCellEditEnd}
              autoFocus
            />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                type="number"
                value={editedValue}
                onChange={handleCellEditChange}
                onBlur={handleCellEditEnd}
                autoFocus
              />
              <IconButton onMouseDown={(event) => switchToDatePicker(event, row.cluster_id)}>
                <CalendarTodayIcon />
              </IconButton>
            </div>
          )}
        </>
      ) : (
        <input
          type="text"
          value={editedValue}
          onChange={handleCellEditChange}
          onBlur={handleCellEditEnd}
          autoFocus
        />
      ))
    ) : (
      <div onClick={() => handleCellEditStart(row.cluster_id, column.name, value)}>{value}</div>
    );
  };
  return (
    <Paper>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        style={{ margin: 0 }}
      >
        <Grid item lg={12} sm={12} md={12}>
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider
              for={['info', 'unit', 'date', 'cluster_name']}
              formatterComponent={EditableCell}
            />
            <DataTypeProvider
              for={['actions']}
              formatterComponent={({ row }) => (
                <Button color="secondary" onClick={() => handleDelete(row.cluster_id)}>
                  Delete
                </Button>
              )}
            />
            <FilteringState />
            <SortingState />
            <IntegratedSorting />
            <IntegratedFiltering />
            <PagingState defaultCurrentPage={0} defaultPageSize={10} />
            <IntegratedPaging />
            <Table
              columnExtensions={columns.map((c) => ({
                columnName: c.name,
                wordWrapEnabled: toggleHeight,
              }))}
              tableComponent={TableComponent}
              containerComponent={tableContainerComponent}
              headComponent={tableHeaderComponent}
            />
            {/* <TableColumnResizing
              defaultColumnWidths={[
                { columnName: 'info', width: 600 },
                { columnName: 'unit', width: 200 },
                { columnName: 'date', width: 200 },
                { columnName: 'cluster_name', width: 200 },
              ]}
            /> */}
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableColumnVisibility defaultHiddenColumnNames={[]} />
            <Toolbar rootComponent={toolbarComponent} />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
            <ToolBarPlugin name="Add" onClick={handleAddRow} />
            {showSave && <ToolBarPlugin name="Save" onClick={handleSave} />}
            <PagingPanel pageSizes={[10, 20, 50]} />
          </ExpressGrid>
          <GridExporter ref={exporterRef} rows={rows} columns={columns} onSave={onSaveExport} />
        </Grid>
      </Grid>
    </Paper>
  );
}
