/* eslint-disable react/destructuring-assignment */
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, IconButton, InputBase, Typography, Button, CircularProgress } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Files from '../../Project/ProjectContainer/components/ProjectFolder/Files';
import { FileBtn, FileInputContainer, FileInputFeildEnter, ProjectFolderContainer } from './styles';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import useGlossary from './useGlossary';
import useAppToken from '../../../app/useAppToken';

const GlossaryFiles = (props) => {
  const {
    isChild,
    numberOfFilesSupport,
    handleScopeSettings,
    select = 'single',
    openGlossary,
    serviceFor,
    fileTypeSupport,
  } = props || false;
  const history = useHistory();
  // const [files, setFiles] = useState([]);
  const { glossaryFiles: contextGlossaryFiles, setGlossaryFiles } = useContext(M2GlobalContext);
  const inputGrossary = React.useRef();
  const [moveTo, setMoveTo] = useState('files');
  const [error, setError] = useState('');
  const [searchfiles, setSearchFiles] = useState('');
  const [glossaryDetails, setGlossaryDetails] = useState({
    GlossaryFromLang: '',
    GlossaryToLang: '',
    glossaryFiles: contextGlossaryFiles,
  });
  const { GlossaryFromLang, GlossaryToLang, glossaryFiles } = glossaryDetails;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const { loading, UserUploadGlossaryFile } = useGlossary({ fileTypeSupport });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  }, [error]);

  const actualFiles = useMemo(() => {
    if (searchfiles) {
      return glossaryFiles
        .filter((file) => file.filename.toLowerCase().includes(searchfiles.toLowerCase()))
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    return glossaryFiles;
  }, [glossaryFiles, searchfiles]);

  // console.log('folders', props);

  const renderComponent = () => {
    switch (moveTo) {
      case 'files':
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
      default:
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
    }
  };

  const handleChangeGlossaryFiles = async (event: any) => {
    const result = await UserUploadGlossaryFile(
      event,
      glossaryFiles,
      user_id,
      GlossaryFromLang,
      GlossaryToLang
    );
    // console.log('result', result.data);
    if (result.status === 'error') {
      // setSnack(result.message, 'error');
      setError(result.message);
    } else if (result.status === 'success') {
      setGlossaryDetails((prev) => ({ ...prev, glossaryFiles: result.data }));
      setError('');
      setGlossaryFiles(result.data);
      // setSnack(result.message, 'success');
    }
  };

  const filehandleChangeGlossaryFiles = (event: any) => {
    const e = event?.target?.files || '';
    handleChangeGlossaryFiles(e);
  };

  const UploadFile = () => {
    inputGrossary.current.click();
  };

  return (
    <ProjectFolderContainer className="root">
      <input
        type="file"
        multiple
        ref={inputGrossary}
        hidden
        onChange={filehandleChangeGlossaryFiles}
      />
      <Box className="fileHeader">
        <Box className="fileHeaderNav">
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
          {/* <IconBtn
            onClick={() => {
              setMoveTo('folder');
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </IconBtn>
          <IconBtn
            onClick={() => {
              setMoveTo('files');
            }}
          >
            <ArrowForwardIcon fontSize="small" />
          </IconBtn> */}
        </Box>

        <Box className="fileHeaderNav">
          <IconButton
            onClick={() => {
              UploadFile();
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: '#002A45' }} size={20} />
            ) : (
              <FileUploadIcon />
            )}
          </IconButton>
          <FileInputContainer>
            <FileInputFeildEnter
              placeholder="Search for file"
              sx={{ flex: 9, pl: 1, color: '#002A45' }}
              onChange={(e) => {
                setSearchFiles(e.target.value);
              }}
            />
          </FileInputContainer>
          {isChild && props.renderCross()}
        </Box>
      </Box>
      <Box
        className="fileSpaceContent"
        // style={scopeSetting ? { maxHeight: 'calc(100% - 7rem)', overflow: 'auto' } : {}}
      >
        {renderComponent()}
      </Box>
      <Box
        style={{
          // height: '3rem',
          padding: '0.3rem 1rem',
          width: '100%',
          display: 'flex',
          border: '1px solid #ccd6f2',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          backgroundColor: 'rgb(255, 255, 255)',
        }}
      >
        <FileBtn
          disabled={
            select === 'single'
              ? numberOfFilesSupport !== selectedFiles.length
              : numberOfFilesSupport < selectedFiles.length || selectedFiles.length === 0
          }
          onClick={() => {
            handleScopeSettings(selectedFiles, openGlossary);
          }}
        >
          Proceed
        </FileBtn>
      </Box>
    </ProjectFolderContainer>
  );
};

export default memo(GlossaryFiles);
