import { Checkbox, Typography } from '@mui/material';
import React, { memo, useEffect, useMemo } from 'react';
import { RephraserButton, RephraserInputFeild, RulesList } from '../../../Styles/Rephraser';
import { incoseTextNew } from '../../../../../api/module2';

interface resultPayload {
  orginalText: string;
  rephrasedText: string;
  originalWarning: string;
  rephrasedTextWarning: string;
  original_socre: string;
  rephrased_socre: string;
}
const InputRules = memo((props) => {
  const [checked, setChecked] = React.useState(false);
  const { eachRule, Rules, isChecked } = props;
  // console.log('Rules', Rules);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = (event, item) => {
    setChecked(event.target.checked);
    if (item in Rules) {
      delete Rules[item];
    } else {
      Rules[item] = true;
    }
  };
  return (
    <>
      <Checkbox
        color="primary"
        // label={eachRule}
        sx={{ p: 0 }}
        onChange={(e) => {
          handleCheckboxChange(e, eachRule);
        }}
        // disabled={numberOfFilesSupport === selectedFiles.length && !isItemSelected}
        checked={checked}
        // onChange={(event) => handleCheckboxChange(event, item)}
      />
      <Typography>{eachRule}</Typography>
    </>
  );
});

const useInputPage = (props) => {
  const { rePhraserRules, rephraserRules, setShowResult } = props;
  const [inputdata, setInputData] = React.useState({
    textValue: '',
    rules: {},
    result: {
      orginalText: '',
      rephrasedText: '',
      originalWarning: '',
      rephrasedTextWarning: '',
      original_socre: '',
      rephrased_socre: '',
    },
  });

  const { textValue, rules, result } = inputdata;

  const handleSelectAll = (props) => {
    const { rules } = props;
    if (Object.keys(rules).length === rephraserRules.length) {
      // console.log('rephraserRules', rephraserRules);
      setInputData((prev) => ({
        ...prev,
        rules: {},
      }));
      return;
    }
    for (let i = 0; i < rephraserRules.length; i += 1) {
      rules[rephraserRules[i]] = true;
    }
    setInputData((prev) => ({
      ...prev,
      rules,
    }));
  };

  //   console.log('inputdata', inputdata);

  const handleAnalyse = (callbackInput: string) => {
    // console.log('callbackInput', callbackInput);

    if (!callbackInput) {
      return;
    }
    const keysRules = Object.keys(rules);
    const extractRules =
      keysRules.length === rePhraserRules || keysRules.length === 0
        ? ['*']
        : keysRules.map((eachRule: string) => eachRule.split(' - ')[0]);
    incoseTextNew(callbackInput.trim(), extractRules, 0, 'user').then((res: any) => {
      const resultpayload = {} as resultPayload;
      if (res.output && res.output.dataframe) {
        // console.log('incoseTextNew', JSON.parse(res.output.dataframe));
        const temp = JSON.parse(res.output.dataframe);
        resultpayload.orginalText = temp[0].Text;
        resultpayload.original_socre = temp[0].score;
        resultpayload.originalWarning = temp[0].warning;
      }

      if (res.rephrased_text && res.rephrased_text.dataframe) {
        // console.log('rephrased_text', JSON.parse(res.output.dataframe));
        const temp = JSON.parse(res.rephrased_text.dataframe);
        resultpayload.rephrasedText = temp[0].Text;
        resultpayload.rephrased_socre = temp[0].score;
        resultpayload.rephrasedTextWarning = temp[0].warning;
      }
      setInputData((prev) => ({
        ...prev,
        result: resultpayload,
      }));
      setShowResult(true);
    });
  };

  const renderProps = useMemo(() => {
    return {
      left: {
        renderHeader: (props) => {
          const { textValue } = props;
          return (
            <>
              <div className="header">
                <div className="label">
                  <Typography>Rephraser</Typography>
                </div>
                <RephraserButton
                  disabled={!textValue}
                  onClick={() => {
                    handleAnalyse(textValue);
                  }}
                >
                  Analyse
                </RephraserButton>
              </div>
            </>
          );
        },
        renderContent: () => {
          return (
            <>
              <div className="content" style={{ height: 'calc(100% - 3rem)' }}>
                <RephraserInputFeild
                  //   value={textValue}
                  onChange={(e) => {
                    setInputData((prev) => ({
                      ...prev,
                      textValue: e.target.value,
                    }));
                  }}
                />
              </div>
            </>
          );
        },
        enableFooter: false,
      },
      right: {
        renderHeader: (props) => {
          const { rules } = props;
          return (
            <>
              <div className="header">
                <div className="label">
                  <Typography>INCOSE Rule</Typography>
                </div>
                <RephraserButton
                  onClick={() => {
                    handleSelectAll({ rules });
                  }}
                >
                  {Object.keys(rules).length === rephraserRules.length
                    ? 'Deselect All'
                    : 'Select All'}
                </RephraserButton>
              </div>
            </>
          );
        },
        renderContent: (props) => {
          const { rules } = props;
          //   console.log('{ rules }', rules);
          return (
            <>
              <div className="content" style={{ height: 'calc(100% - 3rem)' }}>
                <RulesList>
                  {rephraserRules.map((eachRule) => {
                    const temp = eachRule in rules;
                    console.log('temp', temp);
                    return (
                      <div className="eachRule">
                        <InputRules eachRule={eachRule} isChecked={temp} Rules={rules} />
                      </div>
                    );
                  })}
                </RulesList>
              </div>
            </>
          );
        },
        enableFooter: false,
      },
    };
  }, [textValue, rules]);

  return { renderInputPage: renderProps, handleAnalyse, result, textValue, rules };
};

export default useInputPage;
