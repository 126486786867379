import React, { memo, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputBase,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExcelFileSetting from './core/ExcelFileSetting';
import CSVFileSetting from './core/CSVFileSetting';
import PdfFileSetting from './core/PdfFileSetting';
import useModule2services from '../../Hooks/useModule2services';
import ModelPopUp from '../../HOC/core/ModelPopUp';
import UserProfileSetting from '../../User/UserProfileSetting';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .header': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const TopSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: 'calc(100% - 4rem - 1.4rem)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const ErrorSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '1.4rem',
  width: '100%',
}));

const BottomSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '4rem',
  width: '100%',
  display: 'flex',
}));

const BottomLeftSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  height: '100%',
  width: '100%',
}));

const BottomRightSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  flex: 9,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  height: '100%',
  width: '100%',
}));

const InputFeildEnter = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  fontSize: 'average',
  height: '100%',
  width: 'calc(100% - 5rem)',
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: 'large',
  //   height: '70px',
  //   maxHeight: '70px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   fontSize: 'average',
  // },
}));

const ModuleAnalysis = (porps) => {
  const {
    scopeSettings,
    numberOfFilesSupport,
    setScopeSettings = () => {},
    serviceName,
    serviceFor,
    setWorkload,
    select = 'single',
  } = porps;
  const [payload, setPayload] = React.useState({});
  const [captureName, setCaptureName] = React.useState('');
  const [userSettingOpen, setUserSettingOpen] = React.useState(false);
  const [boolValue, setBoolValue] = React.useState(false);
  const [error, setError] = React.useState({ isError: false, message: '' });

  const { loadingResult, handleAnalyze, setLoadingResult } = useModule2services({
    scopeSetting: scopeSettings,
    captureName,
    boolValue,
    fileSetting: payload,
    serviceName,
    serviceFor,
  });
  useEffect(() => {
    setError({ isError: false, message: '' });
  }, [captureName, payload]);

  const renderFileSetting = (item, id) => {
    const { FileType = '' } = item || '';

    switch (FileType) {
      case 'Excel':
        return <ExcelFileSetting {...item} setPayload={setPayload} id={id} />;
      case 'CSV':
        return <CSVFileSetting {...item} setPayload={setPayload} id={id} />;
      case 'PDF':
        return <PdfFileSetting {...item} setPayload={setPayload} id={id} />;
      default:
        return <>Error While Capture</>;
    }
  };
  // useEffect(() => {
  //   console.log(loadingResult, 'loadingResult');
  // }, [loadingResult]);

  const HandleService = async () => {
    const result = await handleAnalyze();
    if (result.error) {
      setError({ isError: true, message: result.message });
    } else {
      setWorkload((prev) => {
        return {
          ...prev,
          workloads: [result.workloadId],
          serviceFor,
        };
      });
    }
  };

  const InputHandler = (e) => {
    setCaptureName(e.target.value);
  };

  const handleCheckboxBool = (e) => {
    setBoolValue(e.target.checked);
  };

  // console.log('analysis', scopeSettings);

  if (!scopeSettings || (scopeSettings.length > 0 && !scopeSettings[0]?.FileType)) {
    const { error = false, message = '' } = scopeSettings[0] ?? {};
    if (error) {
      return (
        <>
          <Typography style={{ color: 'red' }}>{message}</Typography>
        </>
      );
    }
    return <Typography style={{ color: 'red' }}>Error While Capture</Typography>;
  }

  return (
    <>
      <Container>
        <Box className="header">
          <Tooltip title="Back" placement="top">
            <IconButton
              style={{ minWidth: '2rem' }}
              onClick={() => {
                setScopeSettings([]);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TopSection>
          {scopeSettings?.map((item, index) => {
            return <div key={item?.id}>{renderFileSetting(item, index)}</div>;
          })}
        </TopSection>
        <ErrorSection>
          {error.isError && <Typography style={{ color: 'red' }}>{error.message}</Typography>}
        </ErrorSection>
        <BottomSection>
          {serviceName === 'Similarity Analysis' && (
            <BottomLeftSection>
              <Button variant="contained" color="primary" onClick={() => setUserSettingOpen(true)}>
                Filter
              </Button>
            </BottomLeftSection>
          )}
          <BottomRightSection>
            <Box
              style={{
                display: 'flex',
                height: '2.5rem',
                gap: '5px',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {serviceName === 'Similarity Analysis' && (
                <Box>
                  <Checkbox
                    defaultChecked={false}
                    onChange={handleCheckboxBool}
                    name="Checkbox"
                    color="primary"
                    value="yes"
                  />
                  Generate Compliance Matrix
                </Box>
              )}
              <InputFeildEnter
                placeholder="Enter Activity Name"
                inputProps={{ 'aria-label': 'Enter Activity Name' }}
                variant="outlined"
                name="captureName"
                onChange={InputHandler}
                sx={{ pl: 1 }}
                size="small"
                style={{
                  border: '1px solid #002A45',
                  borderRadius: '5px',
                  width: '170px',
                  height: '38px',
                  marginTop: 6,
                  marginBottom: 6,
                  backgroundColor: 'white',
                }}
              />
              <Button
                type="button"
                variant="contained"
                disabled={
                  loadingResult ||
                  (select === 'single'
                    ? numberOfFilesSupport !== scopeSettings.length
                    : numberOfFilesSupport < scopeSettings.length || scopeSettings.length === 0)
                }
                color="primary"
                onClick={() => {
                  // console.log('scopeSettings', scopeSettings);
                  setLoadingResult(true);
                  HandleService();
                }}
                startIcon={
                  loadingResult && <CircularProgress style={{ color: 'white' }} size={20} />
                }
                style={{
                  width: '150px',
                  height: '38px',
                  marginTop: 6,
                  marginBottom: 6,
                }}
              >
                Analyze
              </Button>
            </Box>
          </BottomRightSection>
        </BottomSection>
      </Container>
      <ModelPopUp open={userSettingOpen} setOpen={setUserSettingOpen}>
        <UserProfileSetting />
      </ModelPopUp>
    </>
  );
};

export default memo(ModuleAnalysis);
