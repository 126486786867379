import react, { useState } from 'react';
// import { uploadURLCall } from '../../../api/allocationNew';
import { BACKEND_URL } from '../../../const';
import axios from '../../../api/axios';

const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
  try {
    const uplResponse = await axios.post(uploadUrl, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return uplResponse.data;
    // setFiles(allResources);
  } catch (error) {
    console.log(error);
    return error;
  }
  return null;
};

const useGlossary = ({ fileTypeSupport }) => {
  const [loading, setLoading] = useState(false);
  const UserUploadGlossaryFile = async (event, files, user_id, fromLang, toLang) => {
    setLoading(true);
    const obj = {
      message: '',
      status: '',
      data: [],
      iconLoad: false,
    };

    // console.log(event);
    try {
      // if (!fromLang) {
      //   obj.message = `please select form Language`;
      //   obj.status = 'error';
      //   // setSnack(`${eachFile.name} not supported`, 'error');
      //   return obj;
      // }

      // if (!toLang) {
      //   obj.message = `please select To Language`;
      //   obj.status = 'error';
      //   // setSnack(`${eachFile.name} not supported`, 'error');
      //   return obj;
      // }

      const len = Object?.keys(event)?.length;
      if (len === 0) {
        return obj;
      }
      const arraylist: any[] = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }
      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');
        if (!fileTypeSupport.includes(ext[ext?.length - 1])) {
          obj.message = `Please Upload CSV. ${eachFile.name} is not supported.`;
          obj.status = 'error';
          setLoading(false);
          return obj;
        }
        if (eachFile.name?.length >= 80) {
          obj.message = 'file name should be less than 80 characters';
          obj.status = 'error';
          setLoading(false);
          return obj;
        }
      }

      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      for (const eachFile of files) {
        if (names.includes(eachFile.filename)) {
          obj.message = `File name ${eachFile.filename} already exists.`;
          obj.status = 'error';
          setLoading(false);
          return obj;
        }
      }

      if (formdata.get('files')) {
        // setIconload(true);
        const uploadUrl = `${BACKEND_URL.uploadUserGlossaryDoc.replace(
          'userId',
          user_id
        )}?from=${fromLang}&source=${toLang}`;

        const result = await uploadURLCall(uploadUrl, formdata);
        if (result) {
          const { resources } = result[0];
          const newUploadfile = resources.filter((data: any) => {
            return names.includes(data.filename);
          });
          const NewData = newUploadfile.map((data) => {
            const obj = {
              ...data,
              //   size: formatBytes(Number(data.size), 0),
              id: data.etag,
              folderName: 'user',
              folderId: 0,
              isNew: true,
            };
            return obj;
          });
          obj.data = [...NewData, ...files];
          obj.iconLoad = false;
          obj.message = 'Successfully Uploaded';
          obj.status = 'success';
          setLoading(false);
          return obj;
        }
        return obj;
      }
      obj.message = 'Unsuccessfully Uploaded';
      obj.status = 'error';
      setLoading(false);
      return obj;
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    return obj;
  };

  return { loading, UserUploadGlossaryFile };
};

export default useGlossary;
