import React, { useContext, useMemo } from 'react';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import Rex360Filters from './Rex360Filters';
import IsearcherFilters from './IsearcherFilters';
import SimilarityFilters from './SimilarityFilters';

const useFilterOptions = ({
  service,
  state,
  dispatch,
  callbackFilters,
  selectedOptionRef,
  clearRandom,
}) => {
  const { rexFilter, isearcherFilter, similarityFilter } = state;
  // console.log('rexFilter', rexFilter);
  // console.log('state', state);
  const {
    rexPaltform,
    rexSource,
    rexStatus,
    rexTypology,
    rexCountry,
    rexProductLine,
    rexProduct,
    rexSolution,
    rexIssuerSite,
    rexProject,
  } = rexFilter;

  const {
    simiProductLine,
    simiPlatform,
    simiSolution,
    simiProduct,
    simiProject,
    simiDocumentProfile,
  } = similarityFilter;

  const {
    isearcherProductLine,
    isearcherPlatforms,
    isearcherSolution,
    isearcherProduct,
    isearcherProjects,
    isearcherDocumentProfiles,
    isearcherCountry,
  } = isearcherFilter;

  // console.log('service', service);
  const options = useMemo(() => {
    // console.log('service dsfs', service);
    if (service === 'KMT REX Cards') {
      return Rex360Filters({ rexFilter, dispatch, callbackFilters, selectedOptionRef });
    }

    if (
      [
        'Referents Search',
        'Content Search',
        // 'People Search',
        'Requirements',
        'Referents',
        'Tests',
      ].includes(service)
    ) {
      return IsearcherFilters({ isearcherFilter, dispatch, callbackFilters, selectedOptionRef });
    }

    if (service === 'Similarity Search' || service === 'GenA') {
      return SimilarityFilters({
        similarityFilter,
        dispatch,
        callbackFilters,
        selectedOptionRef,
        service,
      });
    }

    return { options: [], otherFilters: { isOtherComponent: false }, ClearFilters: () => {} };
  }, [
    service,
    rexPaltform,
    rexSource,
    rexStatus,
    rexTypology,
    rexCountry,
    rexProductLine,
    rexProduct,
    rexSolution,
    rexIssuerSite,
    simiProductLine,
    simiPlatform,
    simiSolution,
    simiProduct,
    simiProject,
    simiDocumentProfile,
    rexProject,
    isearcherProductLine,
    isearcherPlatforms,
    isearcherSolution,
    isearcherProduct,
    isearcherProjects,
    isearcherDocumentProfiles,
    isearcherCountry,
    clearRandom,
  ]);

  return options;
};

export default useFilterOptions;
