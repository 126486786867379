/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LanIcon from '@mui/icons-material/Lan';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FileIcon } from '../../../containers/Project/ProjectContainer/components/ProjectFolder/Files';
import downloadTranslateFile from '../../../tools/downloadTranslateFile';
import { getBearerTokenSync } from '../../../app/AppToken';

export const RowItem = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  padding: '0.5rem',
  fontSize: '0.89rem',
  gridTemplateColumns: '0.1fr 0.3fr 1.2fr 0.9fr 0.5fr 1fr 0.5fr',
  justifyContent: 'end',
  alignItems: 'start',
  gap: '0.5rem',
  borderBottom: '1px solid #e0e0e0',
}));

const Label = (props) => {
  const { label, trimText } = props;
  const actuallabel = label?.length <= 30 ? label : `${label?.slice(0, 30)}...`;

  return (
    <>
      <Tooltip title={label}>
        <Typography style={{ fontSize: '0.89rem' }}>{trimText ? actuallabel : label}</Typography>
      </Tooltip>
    </>
  );
};

const IconLabel = (props) => {
  const { icon, label, style = {}, iconStyle = {}, trimText = false } = props;
  // console.log('IconLabel', typeof label);

  return (
    <div style={{ display: 'flex', gap: '0.3rem', alignItems: 'center', ...style }}>
      <div
        style={{
          width: 'max-content',
          backgroundColor: '#dae9f8',
          padding: '0.3rem 0.3rem',
          borderRadius: '5px',
          display: 'flex',
          gap: '0.2rem',
          ...iconStyle,
        }}
      >
        {icon}
      </div>
      {typeof label !== 'string' ? label : <Label label={label} trimText={trimText} />}
    </div>
  );
};

export const RenderDateAndTiem = ({ created_at, style = {} }) => {
  if (created_at) {
    const extractTime = created_at.split(' ')[1];
    const utcDate = new Date(`${created_at.split(' ')[0]}T${extractTime}Z`);

    const [localDate, localTime] = utcDate.toLocaleString().split(',');

    const getRelativeTime = (dateString) => {
      const date = new Date(dateString) as any;
      const now = new Date() as any;
      const diffInMs = now - date;
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

      // console.log('date', date);
      // console.log('now', now);
      // console.log('diffInMs', diffInMs);
      if (diffInHours < 24) {
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        return rtf.format(-diffInHours, 'hour');
      }

      // Check if the difference is exactly one day
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);

      if (
        diffInDays === 1 &&
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
      ) {
        return 'yesterday';
      }
      return null;
    };

    const relativeTime = getRelativeTime(utcDate);

    return (
      <div>
        <IconLabel
          style={{ ...style }}
          icon={
            <CalendarMonthIcon style={{ fontSize: '1rem', fontWeight: '500', color: '#8b8080' }} />
          }
          label={localDate}
        />
        <IconLabel
          style={{ marginTop: '0.5rem', ...style }}
          icon={
            <AccessTimeIcon style={{ fontSize: '1rem', fontWeight: '500', color: '#8b8080' }} />
          }
          label={relativeTime ?? localTime ?? ''}
        />
      </div>
    );
  }

  return null;
};

export const RenderCreatedby = ({ data }) => {
  if (data.created_by) {
    return (
      <Typography style={{ fontSize: '0.89rem' }}>
        <IconLabel
          icon={
            <AccountCircleIcon style={{ fontSize: '2rem', fontWeight: '500', color: '#b0d2f5' }} />
          }
          trimText={true}
          label={data.created_by.name}
          style={{ gap: '0rem' }}
          iconStyle={{ borderRadius: '50%', backgroundColor: 'transparent' }}
        />
      </Typography>
    );
  }

  return null;
};

export const actionForUrl = (service) => {
  switch (service) {
    case 'allocator':
      return 'iAllocator';
    case 'docdiffer':
      return 'Compare Versions';
    case 'iner':
      return 'Extract Topics';
    case 'ner':
      return 'Extract Topics';
    case 'DocToDataset':
      return 'Similarity Analysis';
    case 'DocToDoc':
      return 'Document Similarity';
    case 'SelfDoc':
      return 'Find Duplicates';
    case 'icapture':
      return 'iCapturer';
    case 'capture':
      return 'iCapturer';
    case 'characterizer':
      return 'iCharacterizer';
    default:
      return service;
  }
};

const handleDownload = (row: string) => {
  /* eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }] */
  // eslint-disable-next-line no-restricted-globals

  if (row?.errors.length > 0) {
    const error = `Error during translation ${row?.errors[0].msg}`;
    // setOpen(true);
    // setDowError(error);
    // const msg = row?.errors[0].msg;
    // alert(`Error during translation: ${msg}`, 'error');
    return;
  }
  const token = getBearerTokenSync();
  const querystring = `files=${'translate_file'}`;
  if (row.action === 'Translator') {
    const fileName = row?.resources[0]?.filename;
    const language = row?.resources[0]?.settings?.nameOfCapture;
    const lastDotIndex = fileName.lastIndexOf('.'); // Find the last dot in the filename
    const baseName = fileName.substring(0, lastDotIndex); // Extract base name "sample.test"
    const extension = fileName.substring(lastDotIndex + 1); // Extract extension "pdf"
    const newFileName = `${baseName}-${language}.${extension}`;
    downloadTranslateFile(`/download/resources/${row?.id}`, querystring, token, newFileName);
  } else
    downloadTranslateFile(
      `/download/userresources/${row?.id}/rexbook/${'projects'}`,
      querystring,
      token,
      'Rexbook.docx'
    );
};

const renderStatue = (data, projectId, setErr) => {
  const renderStatusBasedOnData = (data) => {
    if (data.status === 'end' && (data.action === 'Translator' || data.action === 'RexBook')) {
      return (
        <Button
          style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}
          size="small"
          onClick={() => handleDownload(data)}
        >
          <DownloadForOfflineIcon
            style={{
              color: 'rgb(255, 255, 255)',
              marginRight: 2,
              // backgroundColor: 'rgb(255, 255, 255)',
              borderRadius: '50%',
            }}
            titleAccess="Download translated file"
          />
        </Button>
      );
    }

    if (data.errors?.length > 0) {
      return (
        <Button
          startIcon={<ErrorIcon />}
          style={{ backgroundColor: 'rgb(255, 0, 0)', color: 'rgb(255, 255, 255)' }}
          onClick={() => {
            console.log('err');
            setErr({ open: true, msg: data.errors[0].msg });
          }}
        >
          Error
        </Button>
      );
    }
    switch (data.status) {
      case 'progress': {
        return (
          <Button style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}>
            {data.progress_percent}%
          </Button>
        );
      }
      case 'end': {
        return (
          <Link
            style={{ textDecoration: 'none' }}
            to={
              // eslint-disable-next-line no-nested-ternary
              ['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'].includes(data.action)
                ? `/project/${projectId}/${data.app}/${
                    data?.app[0] + data?.app[data?.app?.length - 1]
                  }/results/${data.id}?service=${actionForUrl(data.action)}`
                : ['ScheduleAnalysis'].includes(data.action)
                ? `/project/${projectId}/${data.app}/${
                    data?.app[0] + data?.app[data?.app?.length - 1]
                  }/results/${data.id}/Schedule_Analysis`
                : `/project/${projectId}/${data.app}/${
                    data?.app[0] + data?.app[data?.app?.length - 1]
                  }/results/${data.id}?action=${data.action}`
            }
          >
            <Button style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}>
              view
            </Button>
          </Link>
        );
      }

      default: {
        return null;
      }
    }
  };

  return <>{renderStatusBasedOnData(data)}</>;
};

const renderAction = (data) => {
  const renderType = () => {
    switch (data.action) {
      case 'allocator':
        return <span>iAllocator</span>;
      case 'docdiffer':
        return <span>Compare versions</span>;
      case 'iner':
        return <span>Extract topics</span>;
      case 'ner':
        return <span>Extract topics</span>;
      case 'DocToDataset':
        return <span>Similarity analysis</span>;
      case 'DocToDoc':
        return <span>Document similarity</span>;
      case 'SelfDoc':
        return <span>Find duplicates</span>;
      case 'icapture':
        return <span>iCapturer</span>;
      case 'capture':
        return <span>iCapturer</span>;
      case 'characterizer':
        return <span>iCharacterizer</span>;
      default:
        return <span>{data.action}</span>;
    }
  };

  return (
    <IconLabel
      icon={<LanIcon style={{ fontSize: '1rem', fontWeight: '500', color: '#8b8080' }} />}
      label={renderType()}
    />
  );
};

const convertToReadableTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':');
  const date = new Date();
  date.setHours(hours, minutes, seconds.split('.')[0]);

  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

const renderResource = (data) => {
  if (data.resources) {
    return (
      <>
        {data?.resources.map((res) => {
          return (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <FileIcon filename={res.filename} />
              {/* <Typography style={{ fontSize: '0.89rem' }}>{data.resources[0].filename}</Typography> */}
            </div>
          );
        })}

        <div
          style={{
            width: 'max-content',
            backgroundColor: '#dae9f8',
            padding: '0.2rem 0.5rem',
            borderRadius: '5px',
            marginTop: '0.3rem',
            display: 'flex',
            gap: '0.2rem',
          }}
        >
          <BookmarkBorderIcon fontSize="small" style={{ fontWeight: '500', color: '#8b8080' }} />
          <Typography style={{ fontSize: '0.8rem', fontWeight: '500', letterSpacing: '0.05em' }}>
            {data?.resources[0]?.settings?.nameOfCapture}
          </Typography>
        </div>
      </>
    );
  }

  return null;
};

const ServiceItem = (props) => {
  const { data, handleCheckboxChange, selectedWorkload } = props;
  const { projectId } = useParams();
  const [checked, setChecked] = React.useState(
    selectedWorkload?.current?.includes(data?.id) ?? false
  );
  const [err, setErr] = useState({ open: false, msg: '' });
  useEffect(() => {
    setChecked(selectedWorkload?.current?.includes(data?.id) ?? false);
  }, [data]);
  const handleClose = () => {
    setErr({ msg: '', open: false });
  };

  return (
    <>
      {err.open && (
        <Dialog
          open={err.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              backgroundColor: '#002A45',
              color: 'white',
              height: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0.3rem',
            }}
          >
            <Typography
              style={{
                fontSize: '1em',
                fontWeight: 'bold',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Error!
            </Typography>

            <IconButton aria-label="close" onClick={handleClose} style={{ padding: 0 }}>
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Typography
                style={{
                  fontSize: '1em',
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                {err.msg}
              </Typography>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
        </Dialog>
      )}
      <RowItem>
        <div>
          {' '}
          <Checkbox
            color="primary"
            sx={{ p: 0 }}
            // disabled={numberOfFilesSupport === selectedFiles.length && !isItemSelected}
            checked={checked}
            onChange={(event) => {
              setChecked(event.target.checked);
              handleCheckboxChange(event, data);
            }}
          />
        </div>
        <div>{data.id}</div>
        <div>{renderResource(data)}</div>
        <div>
          <Typography style={{ fontSize: '0.89rem' }}>{renderAction(data)}</Typography>
        </div>
        <div>{renderStatue(data, projectId, setErr)}</div>
        <div>
          <RenderCreatedby data={data} />
        </div>
        <div>
          <RenderDateAndTiem created_at={data.created_at} />
        </div>
      </RowItem>
    </>
  );
};

export default ServiceItem;
