/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/*
this new modalprojectcreatenew file is for six layer fields added
use case
based on type and if ct code already exist in tiranga data for same data project will get created
else user will manually enter the fileds and project will get created.
*/
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  Container,
  Dialog,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Box,
  TextField,
  IconButton,
  InputBase,
} from '@material-ui/core';
import { isEmpty } from '@antv/util';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import FormLabel from '@material-ui/core/FormLabel';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MultiSelect from 'react-multi-select-component';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import WorkIcon from '@mui/icons-material/Work';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
  getAllProjectCreationLanguages,
  getCTcodedetails,
  projectCreate,
} from '../../../api/projects';
import Loading from '../../../components/Core/Loading';
import { getiQualimeterFilterData } from '../../../api/module3';
import { getModalStyle, useStyles } from '../../../components/Core/Modal';
import ProjectContext from '../ProjectContext';
import { textToText } from '../../../api/module2';
import country from './country';
import { folderCreate } from '../../../api/folders';
import { get_allocationActivity } from '../../../api/allocationNew';

type Props = {
  open: boolean;
  onClose: any;
  setSideBar: any;
};

const InfoProjectName = ({ anchor, onClose }) => {
  const open = Boolean(anchor);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      popover: {
        pointerEvents: 'none',
      },
      paper: {
        padding: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Typography>
        {/* <li>Project name can be 4-20 characters long</li> */}
        {/* <li>Only characters [A-Z, a-z, 0-9, -, _] are allowed</li> */}
        <li>Project name must start with a letter</li>
      </Typography>
    </Popover>
  );
};

const dataRegion = [
  'Asia Pacific (APAC)',
  'Americas',
  'Africa Middle-East and Central Asia (AMECA)',
  'Europe',
  'France',
  'Germany - Austria - Switzerland',
];

// const dataCountry = ['APAC', 'AMERICAS', 'AMECA', 'EUROPE', 'FRANCE', 'DACH', 'CHINA'];

const formInit = {
  name: undefined,
  // description: undefined,
  entity: undefined,
  platform: undefined,
  solution: undefined,
  product: undefined,
  types: undefined,
  pgCode: undefined,
  ctCode: undefined,
  boid: undefined,
  region: undefined,
  country: undefined,
};

function ModalProjectCreateNew({ open, onClose, setSideBar }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { projects_lite, setProjects_lite } = useContext(ProjectContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [form, setForm] = useState<any | undefined>(formInit);
  const [codeValue, setCodeValue] = useState('');
  const [typeValue, setTypeValue] = useState('Tender');
  const [projectNameValue, setProjectNameValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [manual, setManual] = useState(true);
  // const [loadingFilterData, setLoadingFilterData] = useState(true); // for filter selection
  const [addPaltform, setAddPlatform] = useState(false);
  const [textPlat, setTextPlat] = useState('');
  const [disPlat, setDisPlat] = useState(false);

  const [addProduct, setAddProduct] = useState(false);
  const [textProd, setTextProd] = useState('');
  const [disProd, setDisProd] = useState(false);

  const [addSolution, setAddSolution] = useState(false);
  const [textSol, setTextSol] = useState('');
  const [disSol, setDisSol] = useState(false);
  const [textLeadingValue, setTextLeadingValue] = React.useState('');
  const [regionValue, setRegionValue] = React.useState('');

  const [filterEntities, setFilterEntities] = useState<any[]>([]);
  const [filterPlatforms, setFilterPlatforms] = useState<any[]>([]);
  const [filterProduct, setFilterProduct] = useState<any[]>([]);
  const [filterSolution, setFilterSolution] = useState<any[]>([]);

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState([]);

  const [searchedProjectName, setSearchedProjectName] = useState([]);
  const [searchedEntities, setSearchedEntities] = useState([]);
  const [searchedPlatforms, setSearchedPlatforms] = useState([]);
  const [searchedProduct, setSearchedProduct] = useState([]);
  const [searchedSolution, setSearchedSolution] = useState([]);
  // const [searchedCountry, setSearchedCountry] = useState([]);
  const [searchedRegion, setSearchedRegion] = useState([]);
  const [searchedLeadingUnit, setSearchedLeadingUnit] = useState([]);
  const [allocationNames, setAllocationNames] = useState([]);

  useEffect(() => {
    textToText('*', '*', '*', '*', '*', '*')
      .then((payload: any) => {
        // setLoadingFilterData(false);
        if (payload) {
          // console.log(payload);
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            // ent.push({
            //   label: item,
            //   value: item,
            // });
            ent.push(item);
          }
          setFilterEntities(ent);

          // another one for platform
          const plat: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.platforms) {
            const item = payload.filters.platforms[i];
            plat.push(item);
          }
          setFilterPlatforms(plat);

          // another one for product
          const prod: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.products) {
            const item = payload.filters.products[i];
            prod.push(item);
          }
          setFilterProduct(prod);

          // another one for solutions
          const sol: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.solutions) {
            const item = payload.filters.solutions[i];
            sol.push(item);
          }
          setFilterSolution(sol);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    // another api call to get the new allocation
    const nameHere: any = [];
    get_allocationActivity()
      .then((data) => {
        // console.log(data);
        data?.map((item) => {
          if (item?.status?.end?.date !== undefined) {
            nameHere.push(item?.name);
          }
        });
        setAllocationNames(nameHere);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function multifilterCall(en: any, plat: any, sol: any, prod: any) {
    const entityVal: any = [];
    const platformVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];

    en?.map((o: any) => {
      !isEmpty(o?.value) && entityVal.push(o.value);
    });

    plat?.map((o: any) => {
      !isEmpty(o?.value) && platformVal.push(o?.value);
    });

    sol?.map((o: any) => {
      !isEmpty(o?.value) && solutionVal.push(o.value);
    });
    prod?.map((o: any) => {
      !isEmpty(o?.value) && productVal.push(o.value);
    });

    textToText(entityVal, platformVal, solutionVal, productVal, [], [])
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            // entityAfterFilter.push({
            //   label: item,
            //   value: item,
            // });
            entityAfterFilter.push(item);
          }

          // if (extra !== 'noChange_entity') {
          //   setFilterEntities(entityAfterFilter);
          // }
          setFilterEntities(entityAfterFilter);

          const platformAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.platforms) {
            const item = payload.filters.platforms[i];
            platformAfterFilter.push(item);
          }
          // if (extra !== 'noChange_platform') {
          //   setFilterPlatforms(platformAfterFilter);
          // }
          setFilterPlatforms(platformAfterFilter);

          const solutionAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.solutions) {
            const item = payload.filters.solutions[i];
            solutionAfterFilter.push(item);
          }
          // if (extra !== 'noChange_solution') {
          //   setFilterSolution(solutionAfterFilter);
          // }
          setFilterSolution(solutionAfterFilter);
          const productAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.products) {
            const item = payload.filters.products[i];
            productAfterFilter.push(item);
          }
          // if (extra !== 'noChange_product') {
          //   setFilterProduct(productAfterFilter);
          // }
          setFilterProduct(productAfterFilter);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  const handleEmptyFields = () => {
    setManual(true);
    setDisProd(false);
    setDisPlat(false);
    setDisSol(false);
    setForm(formInit);
    setCodeValue('');
    // setTypeValue('');
    setProjectNameValue('');
    setAddPlatform(false);
    setAddProduct(false);
    setAddSolution(false);
    setSelectedEntities([]);
    setSelectedPlatforms([]);
    setSelectedSolution([]);
    setSelectedProduct([]);
    setSearchedProjectName([]);
    setSearchedEntities([]);
    setSearchedPlatforms([]);
    setSearchedProduct([]);
    setSearchedSolution([]);
    // setSearchedCountry([]);
    setSearchedRegion([]);
    setSearchedLeadingUnit([]);
    setError(false);
    setHelperText('');
    setRegionValue('');
    setForm({ ...form, region: '' });
    setForm({ ...form, leadingunit: '' });
    multifilterCall([], [], [], []);
  };

  const handleClose = () => {
    onClose();
    setDisProd(false);
    setDisPlat(false);
    setDisSol(false);
    setForm(formInit);
    setCodeValue('');
    setProjectNameValue('');
    setAddPlatform(false);
    setAddProduct(false);
    setAddSolution(false);
    setSelectedEntities([]);
    setSelectedPlatforms([]);
    setSelectedSolution([]);
    setSelectedProduct([]);
    setError(false);
    setHelperText('');
    setForm({ ...form, region: '' });
    setForm({ ...form, country: '' });
  };
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTimeout(() => setAnchorEl(null), 1000);
  };

  const handleChangeType = (e: ChangeEvent<HTMLInputElement>) => {
    const types = e.currentTarget.value.trim();
    setTypeValue(types);
    setForm({ ...form, types });
    handleEmptyFields();
  };

  const handleChangeEntity = (e) => {
    setManual(true);
    const entity = e.currentTarget.value;
    const option = [];
    option.push({ value: entity });
    if (entity === 'Digital & Integrated Systems') {
      setForm({ ...form, entity: 'D&IS' });
    } else if (entity === 'Rolling Stock & Components') {
      setForm({ ...form, entity: 'RSC' });
    } else if (entity === 'Services') {
      setForm({ ...form, entity: 'Services' });
    } else {
      setForm({ ...form, entity });
    }
    // setForm({ ...form, entity });
    setSelectedEntities(option);
    // const extra = 'noChange_entity';
    multifilterCall(option, selectedPlatforms, selectedSolution, selectedProduct);
  };

  const handleChangePlatfrom = (e) => {
    setManual(true);
    const platDrop = e.currentTarget.value;
    setForm({ ...form, platDrop });
    setDisPlat(true);
    const option = [];
    option.push({ value: platDrop });
    setSelectedPlatforms(option);
    // const extra = 'noChange_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(selectedEntities, option, selectedSolution, selectedProduct);
  };

  const handleChangeSolution = (e) => {
    setManual(true);
    const solDrop = e.currentTarget.value;
    setForm({ ...form, solDrop });
    setDisSol(true);
    const option = [];
    option.push({ value: solDrop });
    setSelectedSolution(option);
    // const extra = 'noChange_solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(selectedEntities, selectedPlatforms, option, selectedProduct);
  };

  const handleChangeProduct = (e) => {
    setManual(true);
    const prodDrop = e.currentTarget.value;
    setForm({ ...form, prodDrop });
    setDisProd(true);
    const option = [];
    option.push({ value: prodDrop });
    setSelectedProduct(option);
    // const extra = 'noChange_product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(selectedEntities, selectedPlatforms, selectedSolution, option);
  };

  function onChangePlatform(e) {
    setForm({ ...form, plat: e.target.value });
    setTextPlat(e.target.value);
  }

  function onChangeSolution(e) {
    setForm({ ...form, sol: e.target.value });
    setTextSol(e.target.value);
  }

  function onChangeProduct(e) {
    setForm({ ...form, prod: e.target.value });
    setTextProd(e.target.value);
  }

  const handleChangeRegion = (e) => {
    setManual(true);
    const region = e.currentTarget.value;
    setForm({ ...form, region });
    setRegionValue(e.currentTarget.value);
  };

  const handleChangeLeadingUnit = (e) => {
    setManual(true);
    // const leadingUnits = e.currentTarget.value.trim();
    setForm({ ...form, leadingunit: e.target.value });
    setTextLeadingValue(e.target.value);
  };

  const handleChangeCountry = (e) => {
    setManual(true);
    const country = e.currentTarget.value;
    setForm({ ...form, country });
  };

  const validateCode = (projectPrCode: string): boolean => {
    if (projectPrCode === '' || projectPrCode === undefined) {
      if (typeValue === 'Tender' || typeValue === 'Pre Tender') {
        setHelperText(`BO-ID Field is mandatory`);
      }
      if (typeValue === 'Project') {
        setHelperText(`CT code Field is mandatory`);
      }
      if (typeValue === 'Program') {
        setHelperText(`PG code Field is mandatory`);
      }
      // setDisableSubmit(true);
      return false;
    }
    setHelperText('');
    setDisableSubmit(false);
    return true;
  };

  const validetProjectName = (projectName: string): boolean => {
    // test duplication client side
    const existingNames = projects_lite?.map((f) =>
      String(f.name).toLowerCase().trim()
    ) as string[];

    if (existingNames?.includes(String(projectName).toLowerCase())) {
      setDisableSubmit(true);
      setHelperText(`Project with name ${projectName} already exists`);
      return false;
    }
    if (projectName?.search(/^[a-z]/i) === -1) {
      setDisableSubmit(true);
      setHelperText(`Project name must start with a letter`);
      return false;
    }
    if (projectName?.length < 4) {
      setDisableSubmit(true);
      setHelperText(`Project name is too short ${projectName.trim().length}, min 4`);
      return false;
    }
    if (isEmpty(projectName) && isEmpty(searchedProjectName)) {
      setDisableSubmit(true);
      setHelperText(`Please enter the project name`);
      return false;
    }
    // if (projectName?.length > 20) {
    //   setDisableSubmit(true);
    //   setHelperText(`Project name is too long ${projectName.trim().length}, max 20`);
    //   return false;
    // }
    // if (projectName?.search(/[^a-z0-9_-]/gi) !== -1) {
    //   setDisableSubmit(true);
    //   setHelperText(`Only characters [A-Z, a-z, 0-9, -, _] are allowed`);
    //   return false;
    // }
    setHelperText('');
    setDisableSubmit(false);
    return true;
  };

  const handleChangeCode = (e) => {
    setCodeValue(e.target.value);
    const prcode = e.target.value;
    if (validateCode(prcode)) {
      setForm({ ...form, prcode });
    }
  };

  const handleChangeProjectName = (e) => {
    setManual(true);
    setProjectNameValue(e.target.value);
    const name = e.currentTarget.value.trim();
    if (validetProjectName(name)) {
      setForm({ ...form, name });
    }
  };

  const handleSubmit = async () => {
    const {
      name,
      prcode,
      entity,
      types,
      sol,
      solDrop,
      platDrop,
      plat,
      prod,
      prodDrop,
      region,
      leadingunit,
      country,
    } = form;
    // validate PR
    if (!validateCode(prcode)) {
      return;
    }
    // validate name
    if (!validetProjectName(name)) {
      return;
    }

    // validate entity
    if ((entity === undefined || String(entity).length === 0) && searchedEntities.length === 0) {
      setHelperText('Select Product line');
      setError(true);
      return;
    }

    // if ((entity !== undefined || String(entity).length > 0) && entity === 'D&IS') {
    //   if (entityModelsValues === '') {
    //     setHelperText('Select Label Type');
    //     setError(true);
    //     return;
    //   }
    // }

    // // validate type
    // if (types === undefined || String(types).length === 0) {
    //   setHelperText('Select an Type');
    //   setError(true);
    //   return;
    // }

    // validate Solutions label type
    // if (!validatProjectSolution(solution)) {
    //   return;
    // }

    // if (name === '' || String(name).length === 0) {
    //   setHelperText('Enter ');
    //   setError(true);
    //   return;
    // }
    // console.log(form);
    setLoading(true);
    if (plat === undefined && platDrop === undefined && searchedPlatforms.length === 0) {
      setHelperText('Select an platform');
      setError(true);
      return;
    }
    if (prod === undefined && prodDrop === undefined && searchedProduct.length === 0) {
      setHelperText('Select an product');
      setError(true);
      return;
    }
    if (sol === undefined && solDrop === undefined && searchedSolution.length === 0) {
      setHelperText('Select an solution');
      setError(true);
      return;
    }

    // validate region
    if ((region === undefined || String(region).length === 0) && searchedRegion.length === 0) {
      setHelperText('Select a region');
      setError(true);
      return;
    }

    if (
      (leadingunit === undefined || String(leadingunit).length === 0) &&
      searchedLeadingUnit.length === 0
    ) {
      setHelperText('Select a leadingunit');
      setError(true);
      return;
    }

    // // validate country
    // if (country === undefined || String(country).length === 0) {
    //   setHelperText('Select a country');
    //   setError(true);
    //   return;
    // }

    const platform = manual
      ? disPlat === true
        ? platDrop
        : plat
      : searchedPlatforms?.map((item) => item?.value)[0];
    const product = manual
      ? disProd === true
        ? prodDrop
        : prod
      : searchedProduct?.map((item) => item?.value)[0];
    const solution = manual
      ? disSol === true
        ? solDrop
        : sol
      : searchedSolution?.map((item) => item?.value)[0];
    const projName = manual ? name : searchedProjectName?.map((item) => item?.value)[0];
    const projEntity = manual ? entity : searchedEntities?.map((item) => item?.value)[0];
    const projRegion = manual ? region : searchedRegion?.map((item) => item?.value)[0];
    const projLeadingUnit = manual
      ? leadingunit
      : searchedLeadingUnit?.map((item) => item?.value)[0];
    // const levelmodel = entityModelsValues !== '' ? entityModelsValues : '';
    projectCreate({
      prcode,
      name: projName,
      entity: projEntity,
      types: typeValue,
      platform,
      solution,
      product,
      region: projRegion,
      leadingunit: projLeadingUnit,
    })
      .then((p) => {
        // console.log('p after create', p);
        createFolderFun(p?.id);
        setProjects_lite([...projects_lite, p]);
        handleClose();
        history.push(`/project/${p?.id}`);
      })
      .catch((err) => {
        setHelperText(err.detail);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createFolderFun = (projectId: any) => {
    folderCreate({
      name: 'File',
      project_id: projectId,
    })
      .then((f: any) => {
        /**
         * merge folders for project id
         */
        // console.log(f);
        // const p = projects.map((_p) => {
        //   if (_p.id === project_id)
        //     return {
        //       ..._p,
        //       folders: [...(_p.folders || []), f],
        //     };
        //   return _p;
        // });
        // console.log('projects', p);
        // setProjects(p);
        // handleClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // if (loadingFilterData) {
  //   return <Loading />;
  // }
  function hereCreateAllocation() {
    // console.log('moved to another page');
    setSideBar({ isPanel: '', isOpen: false });
    handleClose();
    history.push('/allocation');
  }

  const handleSearchApiCall = async () => {
    setManual(false);
    setDisPlat(true);
    setDisProd(true);
    setDisSol(true);
    getCTcodedetails(codeValue).then((data) => {
      if (!filterEntities.includes(data?.product_line_description[0])) {
        setFilterEntities(filterEntities.concat(data?.product_line_description));
      }
      if (!filterPlatforms.includes(data?.platform_description[0])) {
        setFilterPlatforms(filterPlatforms.concat(data?.platform_description));
      }
      if (!filterSolution.includes(data?.generic_solution_description[0])) {
        setFilterSolution(filterSolution.concat(data?.generic_solution_description));
      }
      if (!filterProduct.includes(data?.product_description[0])) {
        setFilterProduct(filterProduct.concat(data?.product_description));
      }

      setSearchedProjectName([{ value: data?.contract_description[0] }]);
      setSearchedPlatforms([{ value: data?.platform_description[0] }]);
      setSearchedProduct([{ value: data?.product_description[0] }]);
      setSearchedSolution([{ value: data?.generic_solution_description[0] }]);
      // setSearchedCountry([{ value: data?.country[0] }]);
      setSearchedLeadingUnit([{ value: data?.leading_unit[0] }]);
      setSearchedRegion([{ value: data?.regions_dest[0] }]);

      if (data?.product_line_description[0] === 'Digital & Integrated Systems') {
        setSearchedEntities([{ value: 'D&IS' }]);
      } else if (data?.product_line_description[0] === 'Rolling Stock & Components') {
        setSearchedEntities([{ value: 'RSC' }]);
      } else {
        setSearchedEntities([{ value: data?.product_line_description[0] }]);
      }

      const allValuesEmpty = Object.values(data).every((value: any) => value.length === 0);
      if (allValuesEmpty) {
        setManual(true);
        setDisPlat(false);
        setDisProd(false);
        setDisSol(false);
        setHelperText('Data not found please type manually ');
      } else {
        setManual(false);
        setDisPlat(true);
        setDisProd(true);
        setDisSol(true);
      }
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      fullWidth={true}
      className={classes.PaperHeight}
    >
      <Grid container direction="column">
        <div className={classes.paper2}>
          <CancelIcon
            fontSize="small"
            style={{
              position: 'absolute',
              left: '95%',
            }}
            onClick={handleClose}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <WorkIcon fontSize="large" /> */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h3>Create New Project</h3>
              <InfoIcon
                style={{ marginLeft: 8 }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
              <InfoProjectName anchor={anchorEl} onClose={handlePopoverClose} />
            </div>
          </div>
          <Grid container direction="column" justify="center" item className={classes.type}>
            <Grid>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <FormLabel component="legend">
                  <b style={{ color: '#002A45' }}>Type:</b>
                </FormLabel>
                <RadioGroup
                  aria-label="type"
                  name="types"
                  onChange={handleChangeType}
                  value={typeValue}
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    marginLeft: 60,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '30px',
                    }}
                  >
                    <FormControlLabel value="Tender" control={<Radio />} label="Tender" />
                    <FormControlLabel value="Project" control={<Radio />} label="Project" />
                    <FormControlLabel value="Program" control={<Radio />} label="Program" />
                    <FormControlLabel value="Pre Tender" control={<Radio />} label="Pre Tender" />
                  </div>
                </RadioGroup>
              </div>
            </Grid>
            <Grid style={{ marginBottom: 10, marginTop: 10 }}>
              <form>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <b>{typeValue === 'Tender' && 'BO-ID:'}</b>
                    <b>{typeValue === 'Pre Tender' && 'BO-ID:'}</b>
                    <b>{typeValue === 'Project' && 'CT code:'}</b>
                    <b>{typeValue === 'Program' && 'PG code:'}</b>
                  </div>
                  {/* <TextField
                    style={{ marginLeft: 60, width: 450 }}
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label={
                      ((typeValue === 'Tender' || typeValue === 'Pre Tender') && 'BOID') ||
                      (typeValue === 'Project' && 'CT code') ||
                      (typeValue === 'Program' && 'PG code')
                    }
                    // name={handleTypeName}
                    defaultValue={codeValue}
                    value={codeValue}
                    onChange={handleChangeCode}
                  /> */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      border: '1px solid #002A45',
                      borderRadius: '5px',
                      marginLeft: 52,
                      height: 40,
                    }}
                  >
                    <InputBase
                      style={{
                        width: 400,
                        color: '#002A45',
                        padding: '10px',
                      }}
                      defaultValue={codeValue}
                      inputProps={{ maxLength: 2500 }}
                      onChange={handleChangeCode}
                      value={codeValue}
                      // onKeyDown={(e) => {
                      //   if (e.key === 'Enter') handleApiCall();
                      // }}
                    />
                    {typeValue === 'Project' && (
                      <IconButton
                        style={{ color: '#002A45' }}
                        value={codeValue}
                        type="button"
                        aria-label="search"
                        onClick={handleSearchApiCall}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              </form>
            </Grid>
            <Grid style={{ marginBottom: 10, marginTop: 10 }}>
              <form>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <b>Project Name:</b>
                  </div>
                  <TextField
                    style={{ marginLeft: 15, width: 450, color: '#002A45' }}
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    label="Enter Project Name"
                    name="Project Name"
                    disabled={!manual}
                    defaultValue={projectNameValue}
                    value={
                      manual ? projectNameValue : searchedProjectName?.map((item) => item?.value)
                    }
                    onChange={handleChangeProjectName}
                  />
                </div>
              </form>
            </Grid>
            <Grid style={{ marginBottom: 10, marginTop: 10 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div>
                  <b>Product Line:</b>
                </div>
                <FormControl variant="outlined" style={{ marginLeft: 18 }} size="small">
                  <InputLabel id="demo-simple-select-label" />
                  <Select
                    native
                    // label="Product Line"
                    id="demo-simple-select-label"
                    name="Product Line"
                    disabled={!manual}
                    value={
                      manual
                        ? selectedEntities?.map((item) => item?.value)
                        : searchedEntities?.map((item) => item?.value)
                    }
                    // className={classes.inputField}
                    style={{ width: 450, marginLeft: 5, color: '#002A45' }}
                    // defaultValue={data[0]}
                    onChange={handleChangeEntity}
                  >
                    <option aria-label="" value="">
                      Select the Product Line
                    </option>

                    {manual
                      ? filterEntities.map((cap: string, index: number) => (
                          <option key={`capture-type-${index}`} value={cap}>
                            {cap}
                          </option>
                        ))
                      : searchedEntities.map((cap: string, index: number) => (
                          <option key={`capture-type-${index}`} value={cap?.value}>
                            {cap?.value}
                          </option>
                        ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <b>Platforms:</b>
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: 40 }}
                    disabled={addPaltform && textPlat.length > 0}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      // label="Platforms"
                      name="Platforms"
                      disabled={!manual}
                      value={
                        manual
                          ? selectedPlatforms?.map((item) => item?.value)
                          : searchedPlatforms?.map((item) => item?.value)
                      }
                      className={classes.inputField}
                      style={{ width: 230, color: '#002A45' }}
                      // defaultValue={data[0]}
                      onChange={handleChangePlatfrom}
                    >
                      <option aria-label="None" value="">
                        Select the Platforms
                      </option>
                      {filterPlatforms.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={5}>
                <form>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 40,
                    }}
                  >
                    {addPaltform && (
                      <>
                        <div>
                          <b>Other Platform:</b>
                        </div>
                        <TextField
                          disabled={disPlat}
                          style={{ marginLeft: 10, marginBottom: 5 }}
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="Other Platform"
                          size="small"
                          value={textPlat}
                          onChange={onChangePlatform}
                          // onKeyDown={handleEnterSearch}
                        />
                      </>
                    )}
                    {!addPaltform && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 5,
                        }}
                      >
                        <p style={{ margin: '0px' }}>OR</p>
                        <div>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              return setAddPlatform(true);
                            }}
                            disabled={disPlat}
                            className={classes.button_background}
                          >
                            Other Platform
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </Grid>
              <Grid item xs={7}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <b>Product:</b>
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: 50 }}
                    disabled={addProduct && textProd.length > 0}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      // label="Product"
                      name="Product"
                      disabled={!manual}
                      value={
                        manual
                          ? selectedProduct?.map((item) => item?.value)
                          : searchedProduct?.map((item) => item?.value)
                      }
                      className={classes.inputField}
                      style={{ width: 230, color: '#002A45' }}
                      // defaultValue={data[0]}
                      onChange={handleChangeProduct}
                    >
                      <option aria-label="None" value="">
                        Select the Product
                      </option>
                      {filterProduct.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={5}>
                <form>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 40,
                    }}
                  >
                    {addProduct && (
                      <>
                        <div>
                          <b>Other Product:</b>
                        </div>
                        <TextField
                          disabled={disProd}
                          style={{ marginLeft: 10, marginBottom: 5 }}
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="Other Product"
                          size="small"
                          value={textProd}
                          onChange={onChangeProduct}
                          // onKeyDown={handleEnterSearch}
                        />
                      </>
                    )}
                    {!addProduct && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 5,
                        }}
                      >
                        <p style={{ margin: '0px' }}>OR</p>
                        <div>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              return setAddProduct(true);
                            }}
                            disabled={disProd}
                            className={classes.button_background}
                          >
                            Other Product
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </Grid>
              <Grid item xs={7}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <b>Solution:</b>
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: 45 }}
                    disabled={addSolution && textSol.length > 0}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      // label="Solution"
                      name="Solution"
                      disabled={!manual}
                      value={
                        manual
                          ? selectedSolution?.map((item) => item?.value)
                          : searchedSolution?.map((item) => item?.value)
                      }
                      className={classes.inputField}
                      style={{ width: 230, color: '#002A45' }}
                      // defaultValue={data[0]}
                      onChange={handleChangeSolution}
                    >
                      <option aria-label="None" value="">
                        Select the Solution
                      </option>
                      {filterSolution.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={5}>
                <form>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 40,
                    }}
                  >
                    {addSolution && (
                      <>
                        <div>
                          <b>Other Solution:</b>
                        </div>
                        <TextField
                          disabled={disSol}
                          style={{ marginLeft: 10, marginBottom: 5 }}
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="Other Solution"
                          size="small"
                          value={textSol}
                          onChange={onChangeSolution}
                          // onKeyDown={handleEnterSearch}
                        />
                      </>
                    )}
                    {!addSolution && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 5,
                        }}
                      >
                        <p style={{ margin: '0px' }}>OR</p>
                        <div>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              return setAddSolution(true);
                            }}
                            disabled={disSol}
                            className={classes.button_background}
                          >
                            Other Solution
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </Grid>
              <Grid item xs={7}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <b>Allocation:</b>
                  </div>
                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: 35 }}
                    disabled={addSolution && textSol.length > 0}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      // label="Solution"
                      name="Solution"
                      disabled={!manual}
                      value={
                        manual
                          ? selectedSolution?.map((item) => item?.value)
                          : searchedSolution?.map((item) => item?.value)
                      }
                      className={classes.inputField}
                      style={{ width: 230, color: '#002A45' }}
                      // defaultValue={data[0]}
                      // onChange={handleChangeSolution}
                    >
                      <option aria-label="None" value="">
                        Select the Allocation
                      </option>
                      {allocationNames?.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={5}>
                {/* <p style={{ margin: '0px' }}>OR</p> */}
                <form>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 40,
                    }}
                  >
                    {/* <> */}
                    {/* <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ fontSize: 14, marginTop: -32 }}
                        onClick={hereCreateAllocation}
                        className={classes.button_background}
                      >
                        Create Allocation Model
                      </Button>
                    </div> */}
                    {/* </> */}
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 5,
                      }}
                    >
                      <p style={{ margin: '0px' }}>OR</p>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={hereCreateAllocation}
                          className={classes.button_background}
                        >
                          Create Allocation model
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginLeft: 0 }}>
                    <b>Region:</b>
                  </div>
                  <FormControl variant="outlined" style={{ marginLeft: 52 }} size="small">
                    {/* <InputLabel id="demo-simple-select-label">Region</InputLabel> */}
                    <Select
                      native
                      // label="Region"
                      id="demo-simple-select-label"
                      name="region"
                      disabled={!manual}
                      className={classes.inputField}
                      style={{ width: 180, marginTop: 5, color: '#002A45' }}
                      value={manual ? regionValue : searchedRegion?.map((item) => item?.value)}
                      // defaultValue={data[0]}
                      onChange={handleChangeRegion}
                    >
                      <option aria-label="None" value="" />
                      {dataRegion.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={6}>
                <form noValidate autoComplete="off">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <b>Leading Unit: </b>
                    </div>
                    <TextField
                      style={{ marginLeft: 15 }}
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      disabled={!manual}
                      // label="Leading Unit"
                      // defaultValue={textLeadingValue}
                      value={
                        manual ? form?.leadingunit : searchedLeadingUnit?.map((item) => item?.value)
                      }
                      onChange={handleChangeLeadingUnit}
                      // onKeyDown={handleEnterSearch}
                    />
                  </div>
                </form>
              </Grid>
              {/* <Grid item xs={6}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ marginLeft: 5 }}>
                    <b>Country:</b>
                  </div>
                  <FormControl style={{ marginLeft: 18 }} size="small">
                    {/* <InputLabel id="demo-simple-select-label">Country</InputLabel> */}
              {/* <Select
                      native
                      label="Country"
                      id="demo-simple-select-label"
                      name="country"
                      className={classes.inputField}
                      style={{
                        width: 200,
                        marginTop: 5,
                        color: '#002A45',
                        border: '1px solid',
                        borderRadius: 5,
                        padding: 5,
                      }}
                      value={manual ? form?.country : searchedCountry?.map((item) => item?.value)}
                      // defaultValue={data[0]}
                      onChange={handleChangeCountry}
                    >
                      <option aria-label="None" value="" />
                      {country.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item className={clsx(classes.input)}>
            <center>
              <FormHelperText
                className={clsx(classes.error)}
                style={{ textAlign: 'center', fontSize: '15px' }}
              >
                {helperText}
              </FormHelperText>
            </center>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ padding: '10px' }}
          >
            <Grid item xs={8} grid-gap="10px">
              <Button
                className={classes.button}
                onClick={handleEmptyFields}
                style={{
                  color: 'white',
                  backgroundColor: '#002A45',
                  width: '100px',
                  marginRight: '20px',
                }}
              >
                RESET
              </Button>
              {loading ? (
                <>
                  <Button
                    className={classes.button}
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                    endIcon={<Loading size={20} displayIcon={true} />}
                    style={{
                      color: 'white',
                      backgroundColor: '#DC3223',
                      width: '200px',
                    }}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={classes.button}
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                    style={{
                      color: 'white',
                      backgroundColor: 'green',
                      width: '100px',
                    }}
                  >
                    Submit
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Dialog>
  );
}

export default ModalProjectCreateNew;
