import React, { useMemo } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import HistoryIcon from '@mui/icons-material/History';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import LayoutContainer from '../../../layouts/LayoutContainer';
// import DocumentTranslator from '../../Module2/Module/Components/DocumentTranslator';
import History from '../../../components/History/History';
import useDocumentTranslator from '../Hooks/useDocumentTranslator';
import useAppToken from '../../../app/useAppToken';
import UserFileManager from '../FileManger/UserFileManager';
import Description from '../../Module2/Module/Description';
import DocumentTranslator from '../Components/DocumentTranslator';
import { deeplDescription, deepLExtensionSuppot } from '../../../const';
// import History from '../../Module2/Module/History';

const DocumentContainer = () => {
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('tab');

  const handleResultService = (actionIs) => {
    switch (actionIs) {
      case 'user_history':
        return 1;
      default:
        return 0;
    }
  };
  const componentsData = useMemo(() => {
    return [
      {
        name: 'Document Translator',
        projectName: '',
        icon: <TranslateIcon fontSize="small" />,
        numberOfFilesSupport: 5,
        fileTypeSupport: deepLExtensionSuppot,
        select: 'multiple',
        route: '/Documenttranslator',
        buttonRoute: '/userspace/Documenttranslator',
        renderComponent: (props) => {
          return (
            <>
              <DocumentTranslator {...props} />
            </>
          );
        },
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Document Translator',
            icon: <TranslateIcon fontSize="small" />,
            numberOfFilesSupport: 5,
            fileTypeSupport: deepLExtensionSuppot,
            select: 'multiple',
            renderComponent: (props) => {
              return (
                <>
                  <DocumentTranslator {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: (props) => {
              const list = deeplDescription;
              return (
                <>
                  <Description list={list} {...props} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
    ];
  }, [actionIs]);
  return (
    <LayoutContainer
      componentsData={componentsData}
      workload={{
        workload: 0,
        asyncWorkload: 0,
        workloads: [],
        serviceFor: { name: 'user', id: user_id },
        selectedIndex: 0,
        selectedChildIndex: handleResultService(actionIs),
      }}
    />
  );
};

export default DocumentContainer;
