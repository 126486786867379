import { CircularProgress, IconButton, InputBase, Typography } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/Folder';
import { folderRename } from '../../../../../../api/folders';
import {
  FileInputContainer,
  FileInputFeildEnter,
  FodlerNameContaienr,
  RenameFolderContainer,
} from '../styles';

const RenameFolder = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const { folderName, projectId, folderId, setProjects, scopeSetting, setError } = props;
  const [editedFolderName, setEditedFolderName] = React.useState(folderName);
  const [actualFolderName, setActualFolderName] = React.useState(folderName);
  const renameFolder = () => {
    if (!editedFolderName) {
      setError('Folder name should not be empty');
      return;
    }
    if (folderName === editedFolderName) {
      setOpen(true);
      setLoading(false);
      return;
    }
    setLoading(true);
    folderRename(projectId, folderId, editedFolderName).then((res) => {
      setActualFolderName(editedFolderName);
      setProjects((prev) => {
        return [
          {
            ...prev[0],
            folders: prev[0]?.folders
              ? prev[0]?.folders.map((item) => {
                  if (item.id === folderId) {
                    return {
                      ...item,
                      name: editedFolderName,
                    };
                  }
                  return item;
                })
              : [],
          },
        ];
      });
      setOpen(true);
      setLoading(false);
    });
  };

  if (scopeSetting) {
    return (
      <FodlerNameContaienr>
        <FolderIcon fontSize="small" />
        <Typography style={{ color: '#002A45' }}>{actualFolderName}</Typography>
      </FodlerNameContaienr>
    );
  }
  return (
    <>
      {open ? (
        <>
          <FodlerNameContaienr>
            <FolderIcon fontSize="small" />
            <Typography style={{ color: '#002A45' }}>{actualFolderName}</Typography>
          </FodlerNameContaienr>
          <IconButton
            disabled={loading}
            onClick={() => {
              setOpen(false);
              // setEditedFolderName(folderName);
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: '#002A45' }} size={20} />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </IconButton>
        </>
      ) : (
        <>
          <FileInputContainer style={{ width: '20%' }}>
            <FileInputFeildEnter
              value={editedFolderName}
              sx={{ pl: 1, color: '#002A45' }}
              plcaeholder="Folder Name"
              onChange={(e) => {
                if (e.target.value.length > 35) {
                  setError('Folder name should be less than 35 characters');
                  return;
                }
                setEditedFolderName(e.target.value);
              }}
            />
          </FileInputContainer>
          <IconButton
            disabled={loading}
            onClick={() => {
              renameFolder();
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: '#002A45' }} size={20} />
            ) : (
              <SaveIcon fontSize="small" />
            )}
          </IconButton>
          <IconButton
            onClick={() => {
              // if (!editedFolderName) {
              //   setEditedFolderName(folderName);
              // }
              setOpen(true);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </>
  );
};

export default RenameFolder;
