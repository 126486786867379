import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button, List, ListItem, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { InputField } from '../Home/components/Homepage';
import HomeContext from '../ContextMager/HomeContext';
import useRexApiCall from '../HOC/useRexApiCall';
import REX360 from './RexResultsContainer/REX360';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import HomeContainer from '../HOC/FilterComponent';
import Module2Context from '../Module2/Module2Context';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '0rem 1rem 1rem 1rem',
  width: '100%',
  height: 'calc(100% - 5px)',
  maxHeight: 'calc(100% - 5px)',
  overflow: 'auto',
  // display: 'flex',
  // flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  // maxHeight: 'calc(100% - 5px)',
  // overflow: 'auto',
}));

const rexsButton = [
  'KMT REX Cards',
  '8D REX Cards',
  // 'REX Book',
  // 'Requirements',
  // 'Referents',
  'Change Requests',
  // 'Six Sigma',
  // 'APSYS',
  // 'CSS',
];

const ServiceList = styled((props) => <List {...props} />)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  //   maxWidth: '100%',
  margin: 0,
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none',
}));

const ServiceListItem = styled((props) => <ListItem {...props} />)(({ theme, isSelected }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  width: 'max-content',
  maxWidth: '100%',
  gap: '0.3rem',
  // flexBasis: '16%',
  //   flexGrow: 0,
  //   flexShrink: 0,
  // flex: 1,
  cursor: 'pointer',
  // maxWidth: '24%',
  textAlign: 'center',
  borderBottom: isSelected ? '5px solid #DC3223' : 'none',
  color: isSelected ? '#DC3223' : '#002A45',
}));

const ToolContainer = memo(
  ({
    toggleCollapse,
    text,
    handleApiCall,
    selectedIndex,
    setSelectedIndex,
    isCollapsed,
    numberOfResults,
    numberOfResults8d,
    numberOfResultsCR,
  }) => {
    //   const rexResult = {
    //     text: 'REX Cards',
    //     data: [],
    //   };
    // const [enterValue, setEnterValue] = React.useState(text ?? '');
    const queryParams = new URLSearchParams(window.location.search);
    const input = queryParams.get('text');
    const [enterValue, setEnterValue] = React.useState(input ?? '');
    const { setInput } = useContext(HomeContext);

    const history = useHistory();
    // const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onChangeOption = (payload) => {
      const { index, item } = payload;
      setSelectedIndex(index);
    };

    const SubmitSearch = useCallback(() => {
      // const encodedText = encodeURIComponent(enterValue);
      // history.push(`/RexChat?service=chat&text=${encodedText}`);
      setInput(enterValue);
      handleApiCall(enterValue);
    }, [enterValue, handleApiCall]);

    const handleTextChange = useCallback((e) => {
      setEnterValue(e.target.value);
    }, []);

    return (
      <div
        style={{
          // width: '70%',
          // minWidth: '70%',
          width: '70rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          // maxWidth: '50%',
          // padding: '0.5rem',
          // position: 'sticky',
          // top: '0',
          // zIndex: 999,
          // backgroundColor: '#fafafa',
        }}
      >
        <InputField
          filterType="KMT REX Cards"
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
          SubmitSearch={SubmitSearch}
          onChangeOption={onChangeOption}
          enterValue={enterValue}
          options={rexsButton}
          handleTextChange={handleTextChange}
          numberOfResults={numberOfResults}
          numberOfResults8d={numberOfResults8d}
          numberOfResultsCR={numberOfResultsCR}
          openChat={() => {
            const encodedText = encodeURIComponent(enterValue);
            history.push(`/RexChat?service=chat&text=${encodedText}`);
          }}
        />

        {/* <ServiceList>
          {rexsButton.map((button, index) => (
            <ServiceListItem
              key={index}
              isSelected={index === selectedIndex}
              onClick={() => {
                setSelectedIndex(index);
              }}
            >
              {button}
            </ServiceListItem>
          ))}
        </ServiceList> */}
      </div>
    );
  }
);

const RexResultContainer = memo(({ toggleCollapse, isCollapsed }) => {
  const { rexResult } = useContext(HomeContext);
  const { setInput } = useContext(HomeContext);
  const { data: reult, text } = rexResult;
  const { data, result } = reult || {};
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { setSnack } = useContext(Module2Context);
  const { handleApiCall, loading, error, progressTime, setSideFilter } = useRexApiCall();
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  const Input = queryParams.get('text') ? decodeURIComponent(queryParams.get('text')) : null;
  const { input } = useContext(HomeContext);
  useEffect(() => {
    if (actionIs === 'Rex360' && Input) {
      setInput(Input);
      handleApiCall(Input);
    }
  }, []);
  // `/home/Rex360?service=${'Rex360'}&text=${encodedText}`,
  const resultData = useMemo(() => {
    if (data) {
      return { data, text, result };
    }
    return { data: [], result: false };
  }, [data, text]);

  return (
    <>
      {loading && (
        <>
          <Box sx={{ width: '100%', height: '5px' }}>
            <LinearProgress variant="determinate" color="secondary" value={progressTime} />
          </Box>
        </>
      )}
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
          <ToolContainer
            toggleCollapse={toggleCollapse}
            text={text ?? Input}
            isCollapsed={isCollapsed}
            handleApiCall={handleApiCall}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            numberOfResults={resultData.result ? resultData.data[0]?.numberOfResults : 0}
            numberOfResults8d={resultData.result ? resultData.data[2]?.numberOfResults : 0}
            numberOfResultsCR={resultData.result ? resultData.data[1]?.numberOfResults : 0}
          />{' '}
        </div>
        {loading ? (
          [1, 2, 3, 4, 5].map((data) => {
            return (
              <Box style={{ marginBottom: '2rem' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            );
          })
        ) : (
          <REX360
            input={input}
            REXData={resultData}
            operation={selectedIndex}
            setSnack={setSnack}
          />
        )}
      </Container>
    </>
  );
});

export default memo(RexResultContainer);
