import { AlertProps } from '@material-ui/lab';
import React, { useState } from 'react';
import AlertSnack from '../../components/Alert/AlertSnack';

export interface GenAContextType {
  setSnack: (msg: string, severity?: AlertProps['severity']) => any;
}

const GenAContext = React.createContext<GenAContextType>({
  setSnack: (): any => {},
});

type Props = {
  children: any;
};

const GenAContextProvider = ({ children }: Props): any => {
  const [snackMsg, setSnackMsg] = useState('');
  const [snackSeverity, setSnackSeverity] = useState<AlertProps['severity']>('success');

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
  };

  return (
    <GenAContext.Provider
      value={{
        setSnack: (msg: string, severity = 'success' as AlertProps['severity']) => {
          setSnackMsg(msg);
          setSnackSeverity(severity);
        },
      }}
    >
      {snackMsg !== '' && (
        <AlertSnack
          msg={snackMsg}
          severity={snackSeverity}
          handleClose={handleClose}
          duration={5000}
        />
      )}
      {children}
    </GenAContext.Provider>
  );
};

export default GenAContext;

export { GenAContextProvider };
