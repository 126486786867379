/* eslint-disable import/prefer-default-export */
import axios from './axios';
import { BACKEND_URL } from '../const';

// export type ScopeOutput = {
//   concept_name: string;
//   NameOfCapture: string;
//   TypesOfCapture: string[];
// };

// type ScopePayload = {
//   output: ScopeOutput;
//   error?: any;
//   message?: string;
// };

type SimilarizePayload = {
  output: string;
};

type SimilarizePayloadAny = {
  output: any;
};

type QnAGenerator = {
  output: any;
};

type GenerateImages = {
  output: any;
};

type REX360Response = {
  output: any;
};

type runSimilarity = {
  output: any;
};

const runSimilarize = async (body: any, runId = 0): Promise<SimilarizePayload> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/workloads/${runId}/run/m2-text`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const runSimilarizeTextToText = async (body: any): Promise<SimilarizePayload> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-text-to-text-list`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const runSimilarityDoc = async (body: any): Promise<runSimilarity> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-isimilarizer-iner`,
      body
    );
    // const response = await axios.post(
    //   `https://isens-onto.azurewebsites.net/api/ner/iSimilarizer_NER`,
    //   body
    // );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const runSimilarizeList = async (body: any, runId = 0): Promise<SimilarizePayload> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-list`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iQnA = async (body: String, projectId: any): Promise<SimilarizePayloadAny> => {
  try {
    const url =
      projectId === undefined
        ? '/modules/pipe/m2-iqna-question'
        : `/modules/pipe/m2-iqna-question/${projectId}`;
    const response = await axios.post(`${BACKEND_URL.root}${url}`, {
      question: body,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const nodeInfo = async (body: String): Promise<SimilarizePayloadAny> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-iqna-nodeinfo`, {
      concp: body,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

type SimilarizePayload1 = {
  output: string;
};

const iQnAimageUrlList = async (body: any): Promise<SimilarizePayload1> => {
  try {
    // console.log('body', body);
    const response = await axios.post(`${BACKEND_URL.root}/download/image-url-list`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const idocdiffer = async (body: any): Promise<SimilarizePayload1> => {
  try {
    // console.log('body', body);
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-docdiffer`, body);
    // const response = await axios.post(
    //   `https://isens-m2-isimilarizer.azurewebsites.net/idocdiffer
    // `,
    //   body
    // );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const multiText = async (
  dataframe: any,
  column_name: any,
  generateComplianceStatus: any,
  businessEntity: any,
  platform: any,
  project: any,
  documentProfile: any
): Promise<SimilarizePayload1> => {
  try {
    const body = {
      dataframe,
      column_name,
      generateComplianceStatus,
      businessEntity,
      platform,
      project,
      documentProfile,
    };
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-multi-text`, body);
    // const response = await axios.post(
    //   `https://isens-m2-isimilarizer.azurewebsites.net/multi-text
    // `,
    //   body
    // );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const incoseText = async (text: any, userRules: any): Promise<SimilarizePayload1> => {
  try {
    const body = {
      text,
      userRules,
    };
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m3-inco-text`, body);
    // const response = await axios.post(`https://isens-m2.azurewebsites.net/incose_text`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const incoseTextNew = async (
  text: any,
  userRules: any,
  projectId,
  user = ''
): Promise<SimilarizePayload1> => {
  try {
    const body = {
      text,
      userRules,
    };

    const url =
      user === 'user'
        ? '/modules/pipe/incose_text_gpt'
        : `/modules/pipe/incose_text_gpt/${projectId}`;
    const response = await axios.post(`${BACKEND_URL.root}${url}`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const incoseFile = async (
  fileType: any,
  container: any,
  fileBlobName: any,
  targetColumn: any,
  sheetName: any,
  userRules: any
): Promise<SimilarizePayload1> => {
  try {
    let body: any;
    if (fileType === 'Excel') {
      body = {
        container,
        fileBlobName,
        targetColumn,
        sheetName,
        userRules,
      };
    }
    if (fileType === 'CSV') {
      body = {
        container,
        fileBlobName,
        targetColumn,
        userRules,
      };
    }
    // console.log(body);
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m3-inco-file`, body);
    // const response = await axios.post(`https://isens-m2.azurewebsites.net/incose_file`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const incoseDatasetDetails = async (): Promise<SimilarizePayloadAny> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m3-inco-dataset-details`,
      {}
    );
    // const response = await axios.get(`https://isens-m2.azurewebsites.net/dataset_details`);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const incoseDataset = async (project: any, documents: any): Promise<SimilarizePayload1> => {
  try {
    const body = {
      project,
      documents,
    };
    // console.log(body);
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m3-inco-dataset`, body);
    // const response = await axios.post(`https://isens-m2.azurewebsites.net/incose_dataset`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const idcaApi = async (body: any): Promise<SimilarizePayloadAny> => {
  try {
    // const body = { container, templateFileBlobName, projectFile };
    // container: any,
    // templateFileBlobName: any,
    // projectFile
    // console.log(body);
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m3-inco-compare-templates`,
      body
    );
    // const response = await axios.post(
    //   `https://isens-m2.azurewebsites.net/compare_templates
    // `,
    //   body
    // );
    // console.log('API called');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

const text2Dataset = async (
  inputIs: String,
  countIs: any,
  languageIs: any,
  entityIs: any,
  platformIs: any,
  projectIs: any,
  documentProfileIs: any,
  solution: any,
  product: any,
  thresholdIs: any,
  projectId: any
): Promise<SimilarizePayloadAny> => {
  try {
    const url =
      projectId === undefined
        ? '/modules/pipe/m2-isimilarizer-text'
        : `/modules/pipe/m2-isimilarizer-text/${projectId}`;
    const response = await axios.post(
      // `https://isens-m2-isimilarizer.azurewebsites.net/similarize/text`,
      `${BACKEND_URL.root}${url}`,
      {
        inputText: inputIs,
        count: countIs,
        language: 'All',
        businessEntity: entityIs,
        platform: platformIs,
        solution,
        product,
        project: projectIs,
        documentProfile: documentProfileIs,
        thresholdScore: thresholdIs,
      }
    );

    // eslint-disable-next-line max-len
    // const URL = `https://isens-m2-isimilarizer.azurewebsites.net/similarize/text?input=${input}&count=${count}&businessEntity=${r}&thresholdScore=${threshold}&language=${language}`;
    // // console.log(URL);
    // const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

const iSearcherGetFilters = async (): Promise<SimilarizePayloadAny> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-iqna-isearcher-filters`,
      {}
    );
    // const response = await axios.get(`https://isens-m2-iqna.azurewebsites.net/iSearcher/GetFilters/
    // `);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const iSearcherCEPGetFilters = async (): Promise<SimilarizePayloadAny> => {
  try {
    // const response = await axios.post(
    //   `${BACKEND_URL.root}/modules/pipe/m2-iqna-isearcher-filters`,
    //   {}
    const body = {
      productLine: '*',
      platform: '*',
      solution: '*',
      product: '*',
      project: '*',
      documentProfile: '*',
      country: '*',
    };
    // const body = `${enAll.join('')}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`;
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m2-iqna-isearcher-getFilters`,
      body
    );
    // );
    // const response = await axios.get(
    //   `https://isens-isearcher-sixlayer.20.31.20.157.sslip.io/iSearcher/GetFilters/?productLine=%2A&platform=%2A&solution=%2A&product=%2A&project=%2A&documentProfile=%2A&country=%2A`
    // );
    // const response = await axios.get(`https://isens-m2-iqna.azurewebsites.net/iSearcher/GetFilters/
    // `);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iSearcheSixLayerNew = async (
  en: any,
  plat: any,
  sol: any,
  prod: any,
  proj: any,
  docprof: any,
  cont: any
): Promise<SimilarizePayloadAny> => {
  try {
    const enAll: any = [];
    const platAll: any = [];
    const solAll: any = [];
    const prodAll: any = [];
    const projAll: any = [];
    const docprofAll: any = [];
    const countryAll: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < en.length; i++) {
      // enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
      enAll.push(en[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < plat.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      platAll.push(plat[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sol.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      solAll.push(sol[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < prod.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      prodAll.push(prod[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < proj.length; i++) {
      // projAll.push(`&project=${encodeURIComponent(proj[i])}`);
      projAll.push(proj[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < docprof.length; i++) {
      // docprofAll.push(`&documentProfile=${encodeURIComponent(docprof[i])}`);
      docprofAll.push(docprof[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cont.length; i++) {
      // docprofAll.push(`&documentProfile=${encodeURIComponent(docprof[i])}`);
      countryAll.push(cont[i]);
    }
    if (enAll.length === 0) {
      enAll.push(`*`);
    }
    if (platAll.length === 0) {
      platAll.push(`*`);
    }
    if (solAll.length === 0) {
      solAll.push(`*`);
    }
    if (prodAll.length === 0) {
      prodAll.push(`*`);
    }
    if (projAll.length === 0) {
      projAll.push(`*`);
    }
    if (docprofAll.length === 0) {
      docprofAll.push(`*`);
    }
    if (countryAll.length === 0) {
      countryAll.push(`*`);
    }

    // const response = await axios.get(
    //   // eslint-disable-next-line max-len
    //   `https://isens-m2-isimilarizer.azurewebsites.net/iSimilarizer/GetFilters/?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    const body: any = {
      alstomEntity: enAll,
      platform: platAll,
      solution: solAll,
      product: prodAll,
      project: projAll,
      documentProfile: docprofAll,
      countries: countryAll,
    };
    const productLine = body.alstomEntity
      .map((entity) => `productLine=${encodeURIComponent(entity)}`)
      .join('&');
    const platform = body.platform
      .map((entity) => `&platform=${encodeURIComponent(entity)}`)
      .join('&');
    const solution = body.solution
      .map((entity) => `&solution=${encodeURIComponent(entity)}`)
      .join('&');
    const product = body.product
      .map((entity) => `&product=${encodeURIComponent(entity)}`)
      .join('&');
    const project = body.project
      .map((entity) => `&project=${encodeURIComponent(entity)}`)
      .join('&');
    const documentProfile = body.documentProfile
      .map((entity) => `&documentProfile=${encodeURIComponent(entity)}`)
      .join('&');
    const country = body.countries
      .map((entity) => `&country=${encodeURIComponent(entity)}`)
      .join('&');
    const body1: any = {
      productLine,
      platform,
      solution,
      product,
      project,
      documentProfile,
      country,
    };
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m2-iqna-isearcher-getFilters`,
      body1
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const RexFiltersNew = async (
  Issuer: any,
  rexSources: any,
  statuss: any,
  typo: any,
  en: any,
  plat: any,
  sol: any,
  prod: any,
  proj: any,
  cont: any
): Promise<SimilarizePayloadAny> => {
  try {
    const IssuerVal: any = [];
    const rexSourcesVal: any = [];
    const statusVal: any = [];
    const typoVal: any = [];
    const enAll: any = [];
    const platAll: any = [];
    const solAll: any = [];
    const prodAll: any = [];
    const projAll: any = [];
    const countryAll: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Issuer.length; i++) {
      // enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
      IssuerVal.push(Issuer[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rexSources.length; i++) {
      // enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
      rexSourcesVal.push(rexSources[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < statuss.length; i++) {
      // enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
      statusVal.push(statuss[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < typo.length; i++) {
      // enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
      typoVal.push(typo[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < en.length; i++) {
      // enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
      enAll.push(en[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < plat.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      platAll.push(plat[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sol.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      solAll.push(sol[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < prod.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      prodAll.push(prod[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < proj.length; i++) {
      // projAll.push(`&project=${encodeURIComponent(proj[i])}`);
      projAll.push(proj[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cont.length; i++) {
      // docprofAll.push(`&documentProfile=${encodeURIComponent(docprof[i])}`);
      countryAll.push(cont[i]);
    }
    if (enAll.length === 0) {
      enAll.push(`*`);
    }
    if (platAll.length === 0) {
      platAll.push(`*`);
    }
    if (solAll.length === 0) {
      solAll.push(`*`);
    }
    if (prodAll.length === 0) {
      prodAll.push(`*`);
    }
    if (projAll.length === 0) {
      projAll.push(`*`);
    }
    if (countryAll.length === 0) {
      countryAll.push(`*`);
    }
    if (IssuerVal.length === 0) {
      IssuerVal.push(`*`);
    }
    if (rexSourcesVal.length === 0) {
      rexSourcesVal.push(`*`);
    }
    if (statusVal.length === 0) {
      statusVal.push(`*`);
    }
    if (typoVal.length === 0) {
      typoVal.push(`*`);
    }

    // const response = await axios.get(
    //   // eslint-disable-next-line max-len
    //   `https://isens-m2-isimilarizer.azurewebsites.net/iSimilarizer/GetFilters/?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    const body: any = {
      issuerSite: IssuerVal,
      rexSource: rexSourcesVal,
      status: statusVal,
      typology: typoVal,
      alstomEntity: enAll,
      platform: platAll,
      solution: solAll,
      product: prodAll,
      project: projAll,
      country: countryAll,
    };
    // const issuerSite = body.issuerSite
    //   .map((entity) => `issuerSite=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const rexSource = body.rexSource
    //   .map((entity) => `rexSource=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const status = body.status.map((entity) => `status=${encodeURIComponent(entity)}`).join('&');
    // const typology = body.typology
    //   .map((entity) => `typology=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const productLine = body.alstomEntity
    //   .map((entity) => `productLine=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const platform = body.platform
    //   .map((entity) => `&platform=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const solution = body.solution
    //   .map((entity) => `&solution=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const product = body.product
    //   .map((entity) => `&product=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const project = body.project
    //   .map((entity) => `&project=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const country = body.countries
    //   .map((entity) => `&country=${encodeURIComponent(entity)}`)
    //   .join('&');
    // const body1: any = {
    //   issuerSite,
    //   rexSource,
    //   status,
    //   typology,
    //   productLine,
    //   platform,
    //   solution,
    //   product,
    //   project,
    //   country,
    // };
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m2-dynamicFilters`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iSearcherGetArtifactEntityFilters = async (): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-isearcher-artifact-types`,
      {}
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iSearcherGetFiltersNew = async (
  en: any,
  plat: any,
  proj: any,
  docprof: any
): Promise<SimilarizePayloadAny> => {
  try {
    const enAll: any = [];
    const platAll: any = [];
    const projAll: any = [];
    const docprofAll: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < en.length; i++) {
      enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < plat.length; i++) {
      platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < proj.length; i++) {
      projAll.push(`&project=${encodeURIComponent(proj[i])}`);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < docprof.length; i++) {
      docprofAll.push(`&documentProfile=${encodeURIComponent(docprof[i])}`);
    }
    if (enAll.length === 0) {
      enAll.push(`alstomEntity=*`);
    }
    if (platAll.length === 0) {
      platAll.push(`&platform=*`);
    }
    if (projAll.length === 0) {
      projAll.push(`&project=*`);
    }
    if (docprofAll.length === 0) {
      docprofAll.push(`&documentProfile=*`);
    }
    // console.log(platAll.join(''));
    // console.log(docprofAll.join(''));

    // console.log(
    //   `https://isens-m2-iqna.azurewebsites.net/iSearcher/GetFilters/?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    // const body = {
    //   alstomEntity: '*',
    //   platform: '*',
    //   project: '*',
    //   documentProfile: '*',
    // };
    // const response = await axios.post(
    //   // eslint-disable-next-line max-len
    //   `${BACKEND_URL.root}/modules/pipe/m2-isimilarizer-getfilters`,
    //   body
    // );
    // &alstomEntity=D%26IS&platform=*&project=Amsterdam&documentProfile=*
    const body = {
      alstomEntity: enAll.join(''),
      platform: platAll.join(''),
      project: projAll.join(''),
      documentProfile: docprofAll.join(''),
    };
    // const body = `${enAll.join('')}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`;
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m2-iqna-isearcher-getFilters`,
      body
    );
    // const response = await axios.get(
    //   // eslint-disable-next-line max-len
    //   `https://isens-m2-iqna.azurewebsites.net/iSearcher/GetFilters/?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const iSearcherSearch = async (
  searchValue: String,
  input: String,
  platforms: any,
  projects: any,
  documentprofilesIs: any,
  artifactEnt: any,
  alignment: any,
  maxresults: any,
  minresults: any,
  datasetA: any,
  searchBool: any,
  projectId: any
): Promise<SimilarizePayloadAny> => {
  try {
    const r = encodeURIComponent(alignment);
    // const querySearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/Search/';
    // const peopleSearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/ThePeopleTool/';
    const url =
      projectId === undefined
        ? '/modules/pipe/m2-isearcher-search'
        : `/modules/pipe/m2-isearcher-search/${projectId}`;
    const peopleUrl =
      projectId === undefined
        ? '/modules/pipe/m2-isearcher-people-users'
        : `/modules/pipe/m2-isearcher-people-users/${projectId}`;
    const querySearchURL = `${BACKEND_URL.root}${url}`;
    const peopleSearchURL = `${BACKEND_URL.root}${peopleUrl}`;
    // eslint-disable-next-line max-len
    const peopleSkillUrl =
      projectId === undefined
        ? '/modules/pipe/m2-isearcher-people-skills-search'
        : `/modules/pipe/m2-isearcher-people-skills-search/${projectId}`;
    const peopleSkillSearchURL = `${BACKEND_URL.root}${peopleSkillUrl}`;
    const response = await axios.post(
      // eslint-disable-next-line no-nested-ternary
      searchValue === 'People Search'
        ? peopleSearchURL
        : searchValue === 'Query Search'
        ? querySearchURL
        : peopleSkillSearchURL,
      {
        // alstomEntity: alignment,
        productLine: alignment,
        query: input,
        platform: platforms,
        project: projects,
        documentProfile: documentprofilesIs,
        artifactType: artifactEnt,
        maxResults: maxresults,
        minResultLen: minresults,
        dataset: datasetA,
        searchOnlyRequirements: searchBool,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};
const iSearcherSearchCPE = async (
  // searchValue: String,
  // input: String,
  // platforms: any,
  // projects: any,
  // documentprofilesIs: any,
  // artifactEnt: any,
  // alignment: any,
  // solution: any,
  // product: any,
  // country: any,
  // maxresults: any,
  // minresults: any,
  // datasetA: any,
  // searchBool: any,
  // projectId: any
  searchValue: any,
  inputValue: any,
  platform: any,
  project: any,
  documentProfile: any,
  artifactEnt: any,
  solution: any,
  product: any,
  country: any,
  entityIs: any,
  maxResultsValue: any,
  minResulstValue: any,
  doorValue: any,
  checkedA: any,
  projectId: any
): Promise<SimilarizePayloadAny> => {
  try {
    // const body1 = {
    //   query: inputValue,
    //   maxResults: maxresults,
    //   minResultLen: minresults,
    //   alstomEntity: alignment,
    //   platform: platforms,
    //   project: projects,
    //   solution,
    //   product,
    //   documentProfile: documentprofilesIs,
    //   country,
    //   artifactType: artifactEnt,
    //   // dataset: datasetA,
    //   searchOnlyRequirements: searchBool,
    //   // new addition @Ranjan
    //   searchUser: true,
    //   useremail: '',
    //   dataset: 'DOORS',
    //   queryType: 'QuerySearch',
    //   ner: false,
    //   extractEntitiesLimit: 8000,
    //   cyberAnalysisType: 'search_results_level',
    //   translation: true,
    // };
    // console.log('searchValue body1', body1);
    // const r = encodeURIComponent(alignment);
    // const querySearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/Search/';
    // const peopleSearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/ThePeopleTool/';
    const url =
      projectId === undefined
        ? `/modules/pipe/m2-isearcher-search`
        : `/modules/pipe/m2-isearcher-search/${projectId}`;
    const peopleUrl =
      projectId === undefined
        ? '/modules/pipe/m2-isearcher-people-users'
        : `/modules/pipe/m2-isearcher-people-users/${projectId}`;
    const querySearchURL = `${BACKEND_URL.root}${url}`;
    const peopleSearchURL = `${BACKEND_URL.root}${peopleUrl}`;
    // eslint-disable-next-line max-len
    const peopleSkillUrl =
      projectId === undefined
        ? '/modules/pipe/m2-isearcher-people-skills-search'
        : `/modules/pipe/m2-isearcher-people-skills-search/${projectId}`;
    const peopleSkillSearchURL = `${BACKEND_URL.root}${peopleSkillUrl}`;
    const response = await axios.post(
      // eslint-disable-next-line no-nested-ternary
      searchValue === 'People Search'
        ? peopleSearchURL
        : searchValue === 'Query Search'
        ? querySearchURL
        : peopleSkillSearchURL,
      {
        alstomEntity: entityIs,
        productLine: entityIs,
        query: inputValue,
        platform,
        project,
        documentProfile,
        artifactType: artifactEnt,
        solution,
        product,
        country,
        maxResults: maxResultsValue,
        minResultLen: minResulstValue,
        // dataset: datasetA,
        // searchOnlyRequirements: searchBool,
        // new addition @Ranjan
        searchUser: true,
        useremail: '',
        dataset: doorValue,
        queryType: 'QuerySearch',
        ner: false,
        extractEntitiesLimit: 8000,
        cyberAnalysisType: 'search_results_level',
        translation: true,
      }
      // {
      //   alstomEntity: alignment,
      //   query: input,
      //   platform: platforms,
      //   project: projects,
      //   documentProfile: documentprofilesIs,
      //   artifactType: artifactEnt,
      //   solution,
      //   product,
      //   country,
      //   maxResults: maxresults,
      //   minResultLen: minresults,
      //   // dataset: datasetA,
      //   searchOnlyRequirements: searchBool,
      //   // new addition @Ranjan
      //   searchUser: true,
      //   useremail: '',
      //   dataset: 'DOORS',
      //   queryType: 'QuerySearch',
      //   ner: false,
      //   extractEntitiesLimit: 8000,
      //   cyberAnalysisType: 'search_results_level',
      //   translation: true,
      // }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};
const iSearcherSearchRTE = async (
  searchValue: String,
  input: String,
  platforms: any,
  projects: any,
  documentprofilesIs: any,
  artifactEnt: any,
  alignment: any,
  solution: any,
  product: any,
  country: any,
  maxresults: any,
  minresults: any,
  datasetA: any,
  searchBool: any,
  projectId: any
): Promise<SimilarizePayloadAny> => {
  try {
    // const body1 = {
    //   query: inputValue,
    //   maxResults: maxresults,
    //   minResultLen: minresults,
    //   alstomEntity: alignment,
    //   platform: platforms,
    //   project: projects,
    //   solution,
    //   product,
    //   documentProfile: documentprofilesIs,
    //   country,
    //   artifactType: artifactEnt,
    //   // dataset: datasetA,
    //   searchOnlyRequirements: searchBool,
    //   // new addition @Ranjan
    //   searchUser: true,
    //   useremail: '',
    //   dataset: 'DOORS',
    //   queryType: 'QuerySearch',
    //   ner: false,
    //   extractEntitiesLimit: 8000,
    //   cyberAnalysisType: 'search_results_level',
    //   translation: true,
    // };
    // console.log('searchValue body1', body1);
    // const r = encodeURIComponent(alignment);
    // const querySearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/Search/';
    // const peopleSearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/ThePeopleTool/';
    const url =
      projectId === undefined
        ? `/modules/pipe/m2-isearcher-search`
        : `/modules/pipe/m2-isearcher-search/${projectId}`;
    const peopleUrl =
      projectId === undefined
        ? '/modules/pipe/m2-isearcher-people-users'
        : `/modules/pipe/m2-isearcher-people-users/${projectId}`;
    const querySearchURL = `${BACKEND_URL.root}${url}`;
    const peopleSearchURL = `${BACKEND_URL.root}${peopleUrl}`;
    // eslint-disable-next-line max-len
    const peopleSkillUrl =
      projectId === undefined
        ? '/modules/pipe/m2-isearcher-people-skills-search'
        : `/modules/pipe/m2-isearcher-people-skills-search/${projectId}`;
    const peopleSkillSearchURL = `${BACKEND_URL.root}${peopleSkillUrl}`;
    const response = await axios.post(
      // eslint-disable-next-line no-nested-ternary
      searchValue === 'People Search'
        ? peopleSearchURL
        : searchValue === 'Query Search'
        ? querySearchURL
        : peopleSkillSearchURL,
      {
        alstomEntity: alignment,
        productLine: alignment,
        query: input,
        platform: platforms,
        project: projects,
        documentProfile: documentprofilesIs,
        artifactType: artifactEnt,
        solution,
        product,
        country,
        maxResults: maxresults,
        minResultLen: minresults,
        // dataset: datasetA,
        searchOnlyRequirements: searchBool,
        // new addition @Ranjan
        searchUser: true,
        useremail: '',
        dataset: 'DOORS',
        queryType: 'QuerySearch',
        ner: false,
        extractEntitiesLimit: 8000,
        cyberAnalysisType: 'search_results_level',
        translation: true,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};
const iSearcherSkillSearch = async (
  searchValue: String,
  input: String,
  platforms: any,
  projects: any,
  documentprofilesIs: any,
  artifactEnt: any,
  alignment: any,
  maxresults: any,
  minresults: any,
  datasetA: any,
  searchBool: any
): Promise<SimilarizePayloadAny> => {
  try {
    const r = encodeURIComponent(alignment);
    // const querySearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/Search/';
    // const peopleSearchURL = 'https://isens-m2-iqna.azurewebsites.net/iSearcher/ThePeopleTool/';
    const querySearchURL = `${BACKEND_URL.root}/modules/pipe/m2-isearcher-search`;
    const peopleSearchURL = `${BACKEND_URL.root}/modules/pipe/m2-isearcher-people-users`;
    const response = await axios.post(
      searchValue === 'People Search' ? peopleSearchURL : querySearchURL,
      {
        alstomEntity: alignment,
        query: input,
        platform: platforms,
        project: projects,
        documentProfile: documentprofilesIs,
        artifactType: artifactEnt,
        maxResults: maxresults,
        minResultLen: minresults,
        dataset: datasetA,
        searchOnlyRequirements: searchBool,
      }
    );
    // eslint-disable-next-line max-len
    // const URL = `https://isens-m2-iqna.azurewebsites.net/iSearcher/Search/?query=${input}&platform=${platforms}&project=${projects}&alstomEntity=${r}&documentProfile=${documentprofiles}&maxResults=${maxresults}&minResultLen=${minresults}&dataset=${dataset}`;
    // // console.log(URL);
    // const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

const nerParamExtraction = async (body: any): Promise<SimilarizePayloadAny> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-onto-param-analysis`,
      body
    );
    // const response = await axios.post(`https://isens-onto.azurewebsites.net/param_analysis`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

type ScopePayload1 = {
  output: String;
  id: number;
  created_at: string;
  updated_at: string;
  user_id: number;
};

const iNeriSearcher = async (body: any): Promise<{ output: ScopePayload1 }> => {
  try {
    // const response = await axios.post(
    //   `https://isens-m2-iqna.azurewebsites.net/iSearcher/ExtractEntities/`,
    //   body
    // );
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-isearcher-entities`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const textToText = async (
  en: any,
  plat: any,
  sol: any,
  prod: any,
  proj: any,
  docprof: any
): Promise<SimilarizePayloadAny> => {
  try {
    const enAll: any = [];
    const platAll: any = [];
    const solAll: any = [];
    const prodAll: any = [];
    const projAll: any = [];
    const docprofAll: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < en.length; i++) {
      // enAll.push(`&alstomEntity=${encodeURIComponent(en[i])}`);
      enAll.push(en[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < plat.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      platAll.push(plat[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sol.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      solAll.push(sol[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < prod.length; i++) {
      // platAll.push(`&platform=${encodeURIComponent(plat[i])}`);
      prodAll.push(prod[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < proj.length; i++) {
      // projAll.push(`&project=${encodeURIComponent(proj[i])}`);
      projAll.push(proj[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < docprof.length; i++) {
      // docprofAll.push(`&documentProfile=${encodeURIComponent(docprof[i])}`);
      docprofAll.push(docprof[i]);
    }
    if (enAll.length === 0) {
      enAll.push(`*`);
    }
    if (platAll.length === 0) {
      platAll.push(`*`);
    }
    if (solAll.length === 0) {
      solAll.push(`*`);
    }
    if (prodAll.length === 0) {
      prodAll.push(`*`);
    }
    if (projAll.length === 0) {
      projAll.push(`*`);
    }
    if (docprofAll.length === 0) {
      docprofAll.push(`*`);
    }

    // const response = await axios.get(
    //   // eslint-disable-next-line max-len
    //   `https://isens-m2-isimilarizer.azurewebsites.net/iSimilarizer/GetFilters/?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    const body: any = {
      alstomEntity: enAll,
      platform: platAll,
      solution: solAll,
      product: prodAll,
      project: projAll,
      documentProfile: docprofAll,
    };
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m2-isimilarizer-getfilters-post`,
      body
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const RexFilters = async (): Promise<{ output: any }> => {
  try {
    const body = {};
    // const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-rex-getfilters`, body);
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-dynamicFilters`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const textToTextInitialFilter = async (): Promise<{ output: any }> => {
  try {
    const body = {
      alstomEntity: '*',
      platform: '*',
      solution: '*',
      product: '*',
      project: '*',
      documentProfile: '*',
    };
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m2-isimilarizer-getfilters`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const languageDetect = async (value: any) => {
  try {
    const body = {
      text: value,
    };
    const response = await axios.post(`${BACKEND_URL.root}/projects/detectlanguage/lang`, body);
    return response.data;
  } catch (error) {
    if (error?.response) {
      return Promise.reject(error?.response.data);
    }
    return Promise.reject(error);
  }
};

const translateTextApi = async (body: any, url: string): Promise<SimilarizePayload1> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}${url}/itranslator/texttranslator`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getHistoryTranslation = async (user_id: any) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/get/${user_id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const updataHistoryTranslation = async (body: any) => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/projects/update`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getTextSearch = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/notifications/textsearch`);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getQnAGenerator = async (body: String): Promise<QnAGenerator> => {
  const text = { text: body };
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-isearcher-AutoQnA`,
      text
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getGenerateImages = async (text: String): Promise<GenerateImages> => {
  const body: any = {
    userPrompt: text,
    statusURL: '',
    dataURL: '',
    id: '',
    asyncFlag: false,
  };
  try {
    const response = await axios.post(`${BACKEND_URL.root}/dalle/generateImage`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const SixSigmaRex = async (body: any) => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-SixSigma`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getApsysFilterData = async (
  en: any,
  plat: any,
  sites: any,
  lines: any,
  proj: any,
  yX: any
): Promise<any> => {
  try {
    // const body = {
    //   waves: en.split(','), // ['*', 'a']
    //   domain: plat.split(','),
    //   xAxis: proj.split(','),
    //   yAxis: 'Performance',
    // };
    const body = {
      waves: en, // ['*', 'a']
      domain: plat,
      site: sites,
      line: lines,
      xAxis: proj,
      yAxis: 'Performance',
    };
    // const response = await axios.get(
    //   // eslint-disable-next-line max-len
    //   `https://isens-m3-iqualimeter.azurewebsites.net/dataset_details_multi_filter?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${BACKEND_URL.root}/modules/pipe/m2-DynamicFilter`,
      body
    );
    // console.log('resp', response);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getAllWaveData = async (
  prod: any,
  sites: any,
  en: any,
  plat: any,
  proj: any,
  docprof: any
): Promise<any> => {
  try {
    // const body = {
    //   waves: en.split(','), // ['*', 'a']
    //   domain: plat.split(','),
    //   xAxis: proj.split(','),
    //   yAxis: 'Performance',
    // };
    // Apsys new changes
    const body = {
      line: prod,
      site: sites,
      waves: en, // ['*', 'a']
      domain: plat,
      xAxis: proj,
      yAxis: 'Performance',
    };
    // const response = await axios.get(
    //   // eslint-disable-next-line max-len
    //   `https://isens-m3-iqualimeter.azurewebsites.net/dataset_details_multi_filter?${enAll.join(
    //     ''
    //   )}${platAll.join('')}${projAll.join('')}${docprofAll.join('')}`
    // );
    const response = await axios.post(
      // eslint-disable-next-line max-len
      // `${BACKEND_URL.root}/modules/pipe/m2-searcher-rex360/${projectId}`,
      body.domain[0] === '*'
        ? `${BACKEND_URL.root}/modules/pipe/m2-DomainAvg`
        : `${BACKEND_URL.root}/modules/pipe/m2-WaveData`,
      body
    );
    // const response = await axios.post(
    //   // eslint-disable-next-line max-len
    //   // `${BACKEND_URL.root}/modules/pipe/m2-searcher-rex360/${projectId}`,
    //   `${BACKEND_URL.root}/modules/pipe/m2-DomainAvg`,
    //   body
    // );
    // console.log('resp', response);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const SixSigmaRexSummary = async (id: any) => {
  const body = {
    query: id,
  };
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-SixSigma-summary`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const generateRexSummary = async (id: any[]): Promise<REX360Response> => {
  const body = {
    queries: id,
  };
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-rex360-summary`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const get8DsSummary = async (text: any[]): Promise<REX360Response> => {
  const body: any = {
    queries: text,
    // queries: text,
    // maxResults: 500,
    // platform: ['*'],
    // translation: true,
    // searchTitle: true,
    // searchDescription: true,
    // ner: [''],
  };
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/8DPSTSummary`, body);
    // console.log('8DPST', response);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const saveRexLike = async (id: any, rex_title: any, user_id: any) => {
  const body = {
    rex_id: id,
    rex_title,
    rex_like: [{ user_id, like: 1 }],
    rex_dislike: [],
    created_at: new Date(),
  };
  try {
    const response = await axios.post(`${BACKEND_URL.root}/rex_card`, body);

    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const updateRexLike_Dislike = async (id: any, body: any) => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/rex_card/update/${id}`, body);

    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getRexCardInfo = async (id: any) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/rex_card/get/${id}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const saveRexDisLike = async (id: any, rex_title: any, user_id: any, comment: any) => {
  const body = {
    rex_id: id,
    rex_title,
    rex_like: [],
    rex_dislike: [{ user_id, dislike: 1, comment }],
    created_at: new Date(),
  };
  try {
    const response = await axios.post(`${BACKEND_URL.root}/rex_card`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getREX360Response = async (body: any, projectId): Promise<REX360Response> => {
  try {
    const url =
      projectId === undefined
        ? '/modules/pipe/m2-searcher-rex360'
        : `/modules/pipe/m2-searcher-rex360/${projectId}`;
    const response = await axios.post(`${BACKEND_URL.root}${url}`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getAiOverview = async (body: any) => {
  try {
    const url = `${BACKEND_URL.root}/modules/pipe/m2-isearcher-AIOverview`;
    const response = await axios.post(url, body);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getCRsResponse = async (payload: any): Promise<REX360Response> => {
  const body: any = {
    // query: text,
    // maxResults: 500,
    ...payload,
    platform: ['*'],
    translation: true,
    searchTitle: true,
    searchDescription: true,
    ner: [''],
  };
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-iSearcher-CRsView`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const get8DResponse = async (body: any): Promise<REX360Response> => {
  // const body: any = {
  //   query: text,
  //   maxResults: 500,
  //   // platform: ['*'],
  //   // translation: true,
  //   // searchTitle: true,
  //   // searchDescription: true,
  //   // ner: [''],
  // };
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/8DPST`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const customerNames = async (text: any): Promise<SimilarizePayloadAny> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-customerNames`, {});
    // const response = await axios.post(
    //   `https://isens-m2-isearcher.20.31.20.157.sslip.io/CSS/CustomerNames/`
    // );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const cssFilters = async (text: any): Promise<SimilarizePayloadAny> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/modules/pipe/m2-customerSearchFilters`,
      {}
    );
    // const response = await axios.get(
    //   `https://isens-m2-isearcher.20.31.20.157.sslip.io/CSS/Filters/`
    // );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const customerSearch = async (body: any): Promise<SimilarizePayloadAny> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/modules/pipe/m2-customerSearch`, body);
    // const response = await axios.post(
    //   `https://isens-m2-isearcher.20.31.20.157.sslip.io/CSS/CustomerSearch/`,
    //   body
    // );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  runSimilarize,
  runSimilarizeTextToText,
  runSimilarizeList,
  iQnA,
  nodeInfo,
  iQnAimageUrlList,
  idocdiffer,
  multiText,
  runSimilarityDoc,
  incoseText,
  incoseTextNew,
  incoseFile,
  incoseDatasetDetails,
  incoseDataset,
  idcaApi,
  text2Dataset,
  RexFilters,
  generateRexSummary,
  get8DsSummary,
  iSearcherGetFilters,
  iSearcherGetArtifactEntityFilters,
  languageDetect,
  iSearcherGetFiltersNew,
  iSearcherCEPGetFilters,
  iSearcherSearch,
  nerParamExtraction,
  iNeriSearcher,
  textToText,
  textToTextInitialFilter,
  translateTextApi,
  getHistoryTranslation,
  updataHistoryTranslation,
  getTextSearch,
  getQnAGenerator,
  getGenerateImages,
  getREX360Response,
  getAiOverview,
  SixSigmaRex,
  SixSigmaRexSummary,
  getCRsResponse,
  get8DResponse,
  getAllWaveData,
  getApsysFilterData,
  iSearcheSixLayerNew,
  iSearcherSearchCPE,
  iSearcherSearchRTE,
  customerNames,
  cssFilters,
  customerSearch,
  RexFiltersNew,
  saveRexLike,
  saveRexDisLike,
  getRexCardInfo,
  updateRexLike_Dislike,
};
