import React from 'react';
import axios from '../../api/axios';
import { BACKEND_URL } from '../../const';
import formatBytes from '../../tools/formatBytes';
import { fileDetails } from '../../api/module1';

const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
  try {
    const uplResponse = await axios.post(uploadUrl, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return uplResponse.data;
    // setFiles(allResources);
  } catch (error) {
    console.log(error);
    return error;
  }
  return null;
};

const useUserFileUpload = (props) => {
  const {
    serviceFor = 'user',
    user_id = '',
    projectId = '',
    project = '',
    files = [],
    setFiles,
    fileTypeSupport,
    doScopeSetting = true,
  } = props;
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line consistent-return

  const handleChangeFiles = async (event: any) => {
    try {
      const len = Object?.keys(event)?.length;
      const arraylist = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }
      // console.log('fileTypeSupport', fileTypeSupport);
      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');
        if (!fileTypeSupport.includes(ext[ext?.length - 1])) {
          //   setSnack(`${eachFile.name} not supported`, 'error');
          return { error: true, message: `${eachFile.name} not supported` };
        }
        if (eachFile.name?.length >= 80) {
          //   setSnack('file name should be less than 80 characters', 'error');
          return { error: true, message: 'file name should be less than 80 characters' };
        }
      }
      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      for (const eachFile of files) {
        if (names.includes(eachFile.filename)) {
          return { error: true, message: `${eachFile.filename} already exist` };
        }
      }
      if (formdata.get('files')) {
        const uploadUrl = BACKEND_URL.uploadUserDoc.replace('userId', user_id);

        const results = await uploadURLCall(uploadUrl, formdata);
        if (!results) {
          return { error: true, message: 'Error while upload' };
        }
        return { error: false, result: results, name: names };
      }
    } catch (e) {
      console.log(e);
      return { error: true, message: e };
    }
    return null;
  };

  const UpdateNewDataInContext = (result, names, doScopeSetting, onlySingleFile) => {
    // project.folders[0] = result;
    //   setProject(project);

    const response = result[0];
    const resources = response?.resources || [];
    setFiles([...resources]);
    // this upload function is use for without scope setting
    if (!doScopeSetting) {
      // this is only for single file upload
      if (onlySingleFile) {
        return [
          resources.find((data: any) => {
            return names.includes(data.filename);
          }),
        ];
      }
      return resources.map((data: any) => {
        if (names.includes(data.filename)) {
          return {
            ...data,
            isNew: true,
          };
        }
        return data;
      });
    }

    const newUploadfile = resources.filter((data: any) => {
      return names.includes(data.filename);
    });
    const NewData = newUploadfile?.map((data) => {
      const obj = {
        ...data,
        // size: formatBytes(Number(data?.size), 0),
        // id: newUploadfile[0]?.etag,
        folderName: 'user',
        folderId: 1,
        isNew: true,
      };
      return obj;
    });

    // console.log('NewData', NewData);

    return NewData;
  };

  const FindeFileDetails = async (filename, filenameCopy, container) => {
    try {
      const data = await fileDetails(filename, filenameCopy, container);
      return data;
    } catch (error) {
      console.log('error', error);
      return error;
    }
  };

  const handleChangeInDragefile = async (event: any, onlySingleFile = false) => {
    setLoading(true);
    const data = await handleChangeFiles(event);
    if (!data?.error && data) {
      const { result, name: names } = data;
      const NewData = UpdateNewDataInContext(result, names, doScopeSetting, onlySingleFile);
      if (doScopeSetting) {
        const fileDetailsData = await FindeFileDetails(
          NewData[0]?.filename,
          NewData[0]?.filename,
          NewData[0]?.container
        );
        setLoading(false);
        return {
          data: [fileDetailsData?.output],
          error: false,
        };
      }
      setLoading(false);
      return { data: NewData, error: false, message: 'Successfully File Uploaded!' };
    }

    if (data?.error) {
      setLoading(false);
      return { data: null, error: data.message };
    }

    setLoading(false);
    return { data, error: null };
  };

  const handleUploadFile = async (event: any, onlySingleFile = false) => {
    setLoading(true);
    const data = await handleChangeFiles(event);

    if (!data?.error && data) {
      const { result, name: names } = data;
      const NewData = UpdateNewDataInContext(result, names, doScopeSetting, onlySingleFile);
      if (doScopeSetting) {
        const fileDetailsData = await FindeFileDetails(
          NewData[0]?.filename,
          NewData[0]?.filename,
          NewData[0]?.container
        );
        setLoading(false);
        return { data: [fileDetailsData?.output], error: false };
      }
      setLoading(false);
      return { data: NewData, error: false, message: 'Successfully File Uploaded!' };
    }
    setLoading(false);
    return { data, error: null };
  };

  return { loading, handleUploadFile, handleChangeInDragefile, FindeFileDetails };
};

export default useUserFileUpload;
