import Axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { getBearerToken } from '../app/AppToken';

Axios.interceptors.response.use(
  (response: AxiosResponse<any>): AxiosResponse<any> => response,
  (error: AxiosError): any => {
    if (error && error.response) {
      if (error.response.status === 401) {
        return Promise.reject(Error(`Error ${error.response.status} authentification`));
      }

      if (error.response.status === 403) {
        return Promise.reject(Error(`Error ${error.response.status} Forbidden`));
      }

      if (error.response.status === 404) {
        return Promise.reject(Error(`Error ${error.response.status} ressource not found`));
      }

      if ([408, 509, 599].includes(error.response.status)) {
        return Promise.reject(Error(`Error ${error.response.status} Request takes too long time`));
      }

      if (error.response.status === 500 || error.response.status !== 200) {
        return Promise.reject(error);
      }
    }
    // connection refused
    if (error && !error.response) {
      return Promise.reject(Error('Connection error'));
    }
    return error;
  }
);

// JSON by default
export const getConfig = (headers = {}): AxiosRequestConfig => ({
  timeout: 2000000, // TODO : 40 sec
  // headers: { Authorization: `Bearer ${getToken()}`, ...headers }, #replaced with the interceptor
});

export const getConfigPublic = (headers = {}): AxiosRequestConfig => ({
  timeout: 2000000, // TODO : 40 sec
  headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...headers },
});

// on startup set bearer for all calls
Axios.defaults.headers.common = getConfig()?.headers ?? {};

// create an instance without interceptor
export const axiosNoBearer = Axios.create();

Axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = await getBearerToken();
  if (token === null) {
    return config;
  }
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  };
});

export default Axios;
