/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import {
  Column,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Button, Checkbox, MenuItem, Select, TableCell } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CallSplit from '@material-ui/icons/CallSplit';
import DeleteIcon from '@material-ui/icons/Delete';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ImageIcon from '@mui/icons-material/Image';
import MergeIcon from '@mui/icons-material/Merge';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SaveIcon from '@mui/icons-material/Save';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Skeleton } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import {
  PayloadInsertNewCapture,
  insertCharacterizer,
  PayloadSplit,
  insertNewCapture,
  mergeCapture,
  mergeRepeatCapture,
  splitCapture,
} from '../../../../../api/module1';
import { deleteOwning, getOwning, saveOwning } from '../../../../../api/ownings';
import useProjectId from '../../../../../app/useProjectId';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';
import {
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../../components/Datagrid/TStyles';
import IAllocatorStyle from '../../../../../components/Datagrid/TableAllNewStyle/IAllocatorStyle';
import TableHeaderCellWrap from '../../../../../components/Datagrid/TableHeaderCellWrap';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import { LangCode } from '../../../../../components/Datagrid/TranslationList';
import { IS_DEBUG_MODE } from '../../../../../const';
import { handleColumns } from '../../../../../tools/datagrid';
import ProjectContext from '../../../../Project/ProjectContext';
import Module1Context from '../../../Module1Context';
import Run1Context from '../../../RunContext';
import { allColumns } from '../../../tools/allColumns';
import CaptureModalKpi from './CaptureModalKpi';
import ModalAddCaptureRow from './ModalAddCaptureRow';
import ModalRawdata from './ModalRawdata';
import ModalSplit from './ModalSplit';
import RenderCellText from './RenderCellText';
import Similarizer from './Similarizer';
import { textWidth } from './useStyle';
import RenderCellExcel from './RenderCellExcel';
import ModalConfirm from '../../../../Admin/components/ModalConfirm';
import PopupEditing, { Popup } from './core/EditRow';
import useDownloadFile from './Hooks/useDownloadFile';

dayjs.extend(utc);

const stylingObject = {
  ahref: {
    textDecoration: 'none',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    color: '#002A45',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
};

export const renderMix1 = ({ row }: DataTypeProvider.ValueFormatterProps) => (
  <>
    P{row.PageNo},
    <br />
    {row.Hierarchy}
  </>
);

type Props = {
  dispatch?: any;
  filename: string;
  onChange?: any;
  onReset?: any;
  onSearchText?: any;
  onDownload?: any;
  originalText: any;
  levelsHierarchy: any;
  setOriginalText: any;
  rows: any[];
  isRegExp?: boolean;
  targetColumn?: string;
  displayColumns?: string[];
  workloadId: number;
  setTranslationCode: any;
  pageDefault?: number;
  languageOriginCode?: string;
  setLevelsHierarchy: any;
  setActionForFile?: any;
  languages?: string[];
  actionForFile?: string;
  langCheck?: any;
};

function CaptureFlow({
  dispatch,
  filename,
  actionForFile,
  onReset,
  onSearchText,
  originalText,
  levelsHierarchy,
  setOriginalText,
  langCheck,
  setLevelsHierarchy,
  onDownload,
  rows,
  onChange,
  targetColumn,
  setActionForFile,
  displayColumns,
  isRegExp,
  workloadId,
  pageDefault,
  languageOriginCode,
  languages,
}: Props) {
  // get the project
  const { projectId } = useParams() as any;
  const { projects_lite } = useContext(ProjectContext);
  const { setSnack, translationCode, setTranslationCode, languagesCode } = useContext(
    Module1Context
  );
  const { project } = useProjectId(projectId, projects_lite);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [idsChanged, setIdsChanged] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowsDeleted, setRowsDeleted] = useState<any>([]);
  const [selection, setSelection] = useState<any>([]);
  const [openDashboard, setOpenDashboard] = useState(false);
  const [rowsEdited, setRowsEdited] = useState<any>([]);
  const [imagesList, setImagesList] = useState<any[]>([]);
  const [tablesList, setTablesList] = useState<any[]>([]);
  const [rawType, setRawType] = useState<rawTypeEnum>('images');
  const [toggleRawdata, setToggleRawdata] = useState(false);
  // state for split
  const [openModalSplit, setOpenModalSplit] = useState(false);
  const [loading, setLoading] = useState(true);
  // state for new owning
  const [openModalAddCaptureRow, setOpenModalAddCaptureRow] = useState(false);
  // state for iSimilarizer
  const [openSim, setOpenSim] = useState(false);
  const [rowsSim, setRowsSim] = useState<any>([]);
  const [columns, setColumns] = useState<any>(
    handleColumns(
      allColumns('capture', {
        levelsHierarchy,
        targetColumn,
        displayColumns,
        isRegExp,
      })
    )
  );
  const [rowsExport, setRowsExport] = useState<any>(rows);
  const [currentPage, setCurrentPage] = useState(pageDefault || 0);
  const pageSizes = [5, 10, 20, 50];
  const [dis, setDis] = useState(true);
  const exporterRef = useRef(null);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const targetPage = `${workloadId}CAPTURE${projectId}`;
  const [editingRowIds, setEditingRowIds] = useState([]);
  const classes1 = IAllocatorStyle();

  // download selected columns states
  const [col, setCol] = useState(
    columns?.map((data: any) => {
      return data?.name;
    })
  );

  const [columnSelected, setColumnSelected] = useState([
    'updated_at',
    'Text_original',
    'Context_original',
    'Original_Hierarchy',
    'Hierarchy',
    'Context',
    'Digital',
    'comment',
    'id',
    ...levelsHierarchy,
  ]);
  const [displayColumnDown, setDisplayColumnDown] = useState([]);
  const textColum = targetColumn ?? 'Text';
  const targetPageSize = `${workloadId}CAPTURESIZE${projectId}`;
  const CustomWidth2Tooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} style={{ maxWidth: '376px !important' }} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      '&.MuiTooltip-tooltip': {
        maxWidth: '376px !important',
      },
      backgroundColor: !['capture'].includes(actionForFile) ? 'lightgrey' : '#eef1f9',
      border: '1px solid black',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      width: 600,
      transition: 'opacity 1s',
      // height: 30,
      '& .MuiTooltip-arrow': {
        color: '#eef1f9',
        '&:before': {
          backgroundColor: !['capture'].includes(actionForFile) ? 'lightgrey' : '#eef1f9',
          boxShadow: 'none',
          borderBottom: `1px solid black`,
          borderRight: `1px solid black`,
          fontSize: 16,
        },
      },
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem(targetPage) == null) {
      setCurrentPage(0);
    } else {
      const previouspage = sessionStorage.getItem(targetPage, currentPage);
      setCurrentPage(previouspage);
    }
    if (sessionStorage.getItem(targetPageSize) == null) {
      setPageSize(pageSize[pageSize.length - 1] ?? 50);
    } else {
      const previouspagesize = sessionStorage.getItem(targetPageSize, pageSize);
      setPageSize(previouspagesize);
    }
    setTranslationCode(languageOriginCode);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    callAPI();
  }, []);

  useEffect(() => {
    const dynamicColumn = JSON.parse(localStorage.getItem('storedColumn')) || columnSelected;
    const difference = col.filter((x) => !dynamicColumn?.includes(x));
    const temp = [] as any;
    const rows = [...rowsEdited];
    rows?.map((data) => {
      const t = {};
      const { i18n } = data;
      difference?.map((key) => {
        if (key === 'Text' && 'i18n' in data && Object?.keys(i18n)?.length > 1) {
          if (originalText !== undefined && translationCode === '-' && data?.i18n !== undefined) {
            const oL = data?.i18n[originalText];
            t[key] = oL;
          } else if (
            data?.i18n !== undefined &&
            data?.i18 !== null &&
            data?.i18n[translationCode || 'en']
          ) {
            t[key] = data?.i18n[translationCode];
          }
        } else {
          t[key] = data[key];
        }
        return key;
      });
      temp.push(t);
      return data;
    });
    const disCol = [] as any;
    columns?.map((data) => {
      if (difference.includes(data.name)) {
        disCol.push({
          name: data.name,
          title: data.name,
        });
      }
      return data;
    });
    setDisplayColumnDown(disCol);
    setRowsExport(temp);
  }, [columnSelected, rowsEdited]);

  useEffect(() => {
    if (rowsEdited.length > 0) {
      const { i18n } = rowsEdited[0];
      if (Object?.keys(i18n)?.length === 1) {
        return;
      }
      if (originalText !== undefined && translationCode === '-') {
        const temp = rowsEdited.map((data) => {
          if (data[targetColumn]) {
            return {
              ...data,
              [targetColumn]: data?.i18n[originalText],
            };
          }
          if (data?.i18n !== undefined) {
            const oL = data?.i18n[originalText];
            return { ...data, Text: oL };
          }
          return data;
        });
        // setRowsEdited(temp);
        setRowsEdited(temp);
      } else {
        setRowsEdited((prev) => {
          return prev.map((r) => {
            if (r?.i18n !== undefined && r?.i18 !== null && r?.i18n[translationCode || 'en']) {
              if (r?.Text) {
                return {
                  ...r,
                  Text: r?.i18n[translationCode],
                };
              }

              if (r[targetColumn]) {
                return {
                  ...r,
                  [targetColumn]: r?.i18n[translationCode],
                };
              }
            }
            return r;
          });
        });
      }
    }
  }, [translationCode]);

  useEffect(() => {
    sessionStorage.setItem(targetPage, currentPage);
  }, [currentPage]);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  useEffect(() => {
    if (pageSize > rowsEdited.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rowsEdited.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const { exportAllData, loading: downloadLoad } = useDownloadFile({
    row: rowsEdited,
    columns: displayColumnDown,
    filename: `${projectId}_${filename}_captured`,
    service: 'capture',
    targetColumn: textColum,
    translationCode,
    displayColumns,
    setNotify: setSnack,
    languagesCode,
    langCheck,
  });

  const handlePageNum = () => {
    if (Number.isNaN(tempPage)) {
      return;
    }
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const callAPI = () => {
    getOwning(projectId, workloadId, filename).then((response) => {
      setLoading(false);
      const checkObj = response[0]?.row_capture || {};
      if (checkObj && 'Allocation' in checkObj) {
        dispatch({ type: 'assignment' });
        dispatch({ type: 'characterizer' });
        dispatch({ type: 'capture' });
        setActionForFile('allocator');
      } else if (checkObj && 'Char_Score' in checkObj) {
        dispatch({ type: 'characterizer' });
        setActionForFile('characterizer');
      } else {
        dispatch({ type: 'capture' });
        setActionForFile('capture');
      }
      const { i18n = {} } = checkObj;
      const keyss = Object?.keys(i18n) ?? [];
      let OriginalLang = '';
      let transCode = '';
      if (langCheck) {
        const orignalLangCode = languagesCode.filter((data: any) => {
          return data.lang === langCheck;
        });
        if (orignalLangCode[0]?.code === 'en') {
          OriginalLang = 'en';
          transCode = 'en';
          setOriginalText(orignalLangCode[0]?.code);
          setTranslationCode('-');
        } else {
          OriginalLang = orignalLangCode[0]?.code;
          setOriginalText(orignalLangCode[0]?.code);
          setTranslationCode('-');
          transCode = '-';
        }
      } else {
        const originalText = keyss.filter((data) => {
          return !['fr', 'de', 'it', 'es', 'en'].includes(data);
        });
        if (originalText[0] !== undefined) {
          // eslint-disable-next-line prefer-destructuring
          OriginalLang = originalText[0];
          setOriginalText(originalText[0]);
          setTranslationCode('-');
          transCode = '-';
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          OriginalLang = 'en';
          setOriginalText('en');
          setTranslationCode('en');
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          transCode = 'en';
        }
      }
      const rows = response.map((row) => {
        const { i18n } = row?.row_capture || {};
        if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
          if (row.row_capture[targetColumn]) {
            return {
              ...row.row_capture,
              [targetColumn]: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
              updated_at: row.updated_at,
              id: row.id,
            };
          }
          return {
            ...row.row_capture,
            Text: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
            updated_at: row.updated_at,
            id: row.id,
          };
        }
        return {
          ...row.row_capture,
          updated_at: row.updated_at,
          id: row.id,
        };
      });
      const k = Object.keys(rows[0]);
      const levels = [] as any;
      let conI = 1;
      k.map((data) => {
        if (data.includes('Heading_Level')) {
          const str = `Heading_Level_${conI}`;
          levels.push(str);
          conI += 1;
        }
        return data;
      });
      setLevelsHierarchy(levels);
      setRowsEdited(rows);
      onChange(rows);
    });
  };
  const openSimilarizer = () => {
    const selectedRows = rowsEdited.filter((row, index) => selection.includes(index));
    // validation text to similarize
    const templistText = selectedRows.map((row) => {
      return row.Text ?? row[targetColumn];
    });
    if (templistText?.length === 0) {
      setSnack('Select at least one row to run iSimialarizer', 'warning');
      setRowsSim([]);
      setOpenSim(false);
      return;
    }
    setRowsSim(templistText);
    setOpenSim(true);
  };
  const handleModalClose = () => {
    setOpenModalSplit(false);
    setOpenModalAddCaptureRow(false);
    setSelection([]);
  };
  const handleSplit = () => {
    if (selection.length === 0) {
      setSnack('Please select the row', 'warning');
    }
    setOpenModalSplit(true);
  };

  const handleRefesh = useCallback(
    (tmp, reset = true) => {
      setRowsEdited(tmp ?? rowsEdited);
      onChange(tmp ?? rowsEdited);
      setRowsDeleted([]);
      setSelection([]);
      setIdsChanged([]);
      // callAPI();
      if (!reset) return;
      if (onReset || reset) onReset(tmp ?? rowsEdited);
    },
    [rowsEdited]
  );
  const commentField = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return <a>{value}</a>;
  };
  const handleSplitSubmit = (formBody) => {
    const body = {
      project_id: Number(projectId),
      workload_id: workloadId,
      id: rowsEdited.find((row, index) => selection.includes(index)).id,
      column_name: targetColumn ?? 'Text',
      language_code: translationCode === '-' ? originalText : translationCode,
      ...formBody,
    } as PayloadSplit;
    const row_types = 'capture';
    splitCapture(body, row_types)
      .then((response: any) => {
        const tmp = response.map((row) => {
          const { i18n } = row?.row_capture || {};
          if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
            if (row.row_capture[targetColumn]) {
              return {
                ...row.row_capture,
                [targetColumn]:
                  translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
              updated_at: row.updated_at,
              id: row.id,
            };
          }
          return {
            ...row.row_capture,
            updated_at: row.updated_at,
            id: row.id,
          };
        });
        setOpenModalSplit(false);
        setTimeout(() => {
          handleRefesh(tmp, false);
          dispatch({ type: 'init' });
        }, 500);
      })
      .finally(() => {
        setSnack('Splited successfully', 'success');
      });
  };

  const handleInsertCaptureRowSubmit = (formBody: any, file: any) => {
    const columnName = targetColumn ?? 'Text';
    const row_types = 'capture';
    const id = Number(rowsEdited.find((row, index) => selection.includes(index)).id);
    /* eslint no-continue: "error" */

    const formData = new FormData();
    if (file.file !== undefined && formBody.subtype === 'Image') {
      formData.append('file', file.file);
      const value = {
        Context: formBody?.context,
        comment: formBody?.comment,
        Hierarchy: formBody?.hierarchy,
        SubType: formBody.subtype,
        capture: 'Image',
        Type: 'Text',
        i18n: { en: 'Image' },
        Original_Hierarchy: undefined,
        column_name: columnName,
        language_code: translationCode === '-' ? originalText : translationCode,
      };
      insertCharacterizer(id, value, row_types, formData).then((response: any) => {
        const tmp = response.map((row) => {
          const { i18n } = row?.row_capture || {};
          if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
            if (row.row_capture[targetColumn]) {
              return {
                ...row.row_capture,
                [targetColumn]:
                  translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
              updated_at: row.updated_at,
              id: row.id,
            };
          }
          return {
            ...row.row_capture,
            updated_at: row.updated_at,
            id: row.id,
          };
        });
        setOpenModalAddCaptureRow(false);
        setTimeout(() => {
          handleRefesh(tmp, false);
          if (dispatch) {
            setSnack('Image uploaded successfully', 'success');
            dispatch({ type: 'init' });
          }
        }, 500);
      });
    } else {
      const tempObj1: any = {};
      if (languages?.length) {
        tempObj1[originalText] = formBody.capture;
        for (const i of languages) {
          tempObj1[i] = formBody.capture;
        }
      }
      const tempObj = {} as any;
      if (Object.keys(tempObj1).length === 0) {
        tempObj[originalText] = formBody.capture;
        tempObj.en = formBody.capture;
      }
      const body = {
        Context: formBody?.context,
        comment: formBody?.comment,
        Hierarchy: formBody?.hierarchy,
        SubType: formBody.subtype,
        capture: formBody.capture,
        Type: 'Text',
        i18n: Object.keys(tempObj1).length > 0 ? tempObj1 : tempObj,
        Original_Hierarchy: undefined,
        column_name: columnName,
        language_code: translationCode === '-' ? originalText : translationCode,
      } as PayloadInsertNewCapture;
      insertNewCapture(id, body, row_types).then((response: any) => {
        const tmp = response.map((row) => {
          const { i18n } = row?.row_capture || {};
          if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
            if (row.row_capture[targetColumn]) {
              return {
                ...row.row_capture,
                [targetColumn]:
                  translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
              updated_at: row.updated_at,
              id: row.id,
            };
          }
          return {
            ...row.row_capture,
            updated_at: row.updated_at,
            id: row.id,
          };
        });
        setOpenModalAddCaptureRow(false);
        setTimeout(() => {
          handleRefesh(tmp, false);
          if (dispatch) {
            setSnack('Row added successfully', 'success');
            dispatch({ type: 'init' });
          }
        }, 500);
      });
    }
  };

  const handleTextAlter = (concatStr: any) => {
    // sort selection
    // check sequential
    // send ids to API
    selection.sort((a, b) => (a < b ? -1 : 1));
    try {
      if (selection.length < 2) {
        if (concatStr === '\n') {
          setSnack('Please select at least two rows for merge', 'warning');
        } else {
          setSnack('Please select at least two rows for concat', 'warning');
        }
        return;
      }

      selection.forEach((element, index) => {
        if (index === 0) return;
        if (Number(element) !== selection[index - 1] + 1) {
          throw new Error('selection must be squential');
        }
      });

      const rowsSelection = rowsEdited
        .filter((row, index) => selection.includes(index))
        .map((r) => r.id);
      const body = {
        project_id: Number(projectId),
        workload_id: workloadId,
        ids: rowsSelection,
        column_name: targetColumn ?? 'Text',
        concat_str: concatStr,
        language_code: translationCode === '-' ? originalText : translationCode,
      };
      const row_types = 'capture';
      mergeCapture(body, row_types)
        .then((response: any) => {
          dispatch({ type: 'init' });
          const tmp = response.map((row) => {
            const { i18n = {} } = row?.row_capture || {};
            if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
              if (row.row_capture[targetColumn]) {
                return {
                  ...row.row_capture,
                  [targetColumn]:
                    translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                  updated_at: row.updated_at,
                  id: row.id,
                };
              }
              return {
                ...row.row_capture,
                Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              updated_at: row.updated_at,
              id: row.id,
            };
          });
          setTimeout(() => {
            handleRefesh(tmp, false);
          }, 500);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          if (concatStr === '\n') {
            setSnack('Merged successfully!', 'success');
          } else {
            setSnack('Concatenated successfully!', 'success');
          }
        });
    } catch (error: any) {
      setSnack(error.message, 'warning');
    }
  };
  const handleConcat = () => {
    const emptyStr = ' ';
    handleTextAlter(emptyStr);
  };

  const handleMerge = () => {
    handleTextAlter('\n');
  };

  const handleRepeatMerge = (concatStr = '\n') => {
    // sort selection
    // check sequential
    // send ids to API
    selection.sort((a, b) => (a < b ? -1 : 1));
    try {
      if (selection.length < 2) {
        setSnack('Please select at least two rows for repeat header', 'warning');
        return;
      }

      selection.forEach((element, index) => {
        if (index === 0) return;
        if (Number(element) !== selection[index - 1] + 1) {
          throw new Error('selection must be squential');
        }
      });

      const rowsSelection = rowsEdited
        .filter((row, index) => selection.includes(index))
        .map((r) => r.id);
      const body = {
        project_id: Number(projectId),
        workload_id: workloadId,
        ids: rowsSelection,
        column_name: targetColumn ?? 'Text',
        concat_str: concatStr,
        language_code: translationCode === '-' ? originalText : translationCode,
      };
      const row_types = 'capture';
      mergeRepeatCapture(body, row_types)
        .then((response: any) => {
          dispatch({ type: 'init' });
          const tmp = response.map((row) => {
            const { i18n } = row?.row_capture || {};
            if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
              if (row.row_capture[targetColumn]) {
                return {
                  ...row.row_capture,
                  [targetColumn]:
                    translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                  updated_at: row.updated_at,
                  id: row.id,
                };
              }
              return {
                ...row.row_capture,
                Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              updated_at: row.updated_at,
              id: row.id,
            };
          });
          setTimeout(() => {
            handleRefesh(tmp, false);
          }, 500);
        })
        .finally(() => {
          setSnack('Repeat header successfully!', 'success');
        });
    } catch (error: any) {
      setSnack(error.message, 'warning');
    }
  };

  const autoSave = (myRows: any[], idsChanged: any) => {
    const rowsEditedFiltered = myRows.filter((row) => idsChanged.includes(row.id));

    if (rowsEditedFiltered.length > 0) {
      const checkLang = translationCode === '-' ? originalText : translationCode;
      saveOwning(
        rowsEditedFiltered,
        'capture',
        projectId,
        Number(workloadId),
        checkLang,
        targetColumn || 'Text',
        filename
      )
        .then((responseSave) => {
          dispatch({ type: 'init' });
          if (rowsDeleted.length > 0) {
            deleteOwning(rowsDeleted, projectId, Number(workloadId), filename)
              .then(() => {
                const deleteIds = rowsDeleted.map((row) => row.id);
                onChange(myRows.filter((row) => !deleteIds.includes(row.id)));
                setSnack('save & delete ok', 'success');
              })
              .catch((reason) => {
                console.error(reason);
              });
          } else {
            setSnack('saved successfully!', 'success');
            setRowsEdited((prev) => {
              return prev.map((data) => {
                const rowWithTranslation = responseSave.find(
                  (r2) => Number(r2.id) === Number(data.id)
                );
                if (rowWithTranslation?.id === data.id) {
                  if (
                    'i18n' in rowWithTranslation &&
                    Object?.keys(rowWithTranslation?.i18n)?.length > 1
                  ) {
                    const { i18n } = rowWithTranslation;
                    if (rowWithTranslation[targetColumn]) {
                      rowWithTranslation[targetColumn] =
                        translationCode === '-' ? i18n[originalText] : i18n[translationCode];
                    } else {
                      rowWithTranslation.Text =
                        translationCode === '-' ? i18n[originalText] : i18n[translationCode];
                    }
                  }
                  return rowWithTranslation;
                }
                return data;
              });
            });
            onChange(myRows);
          }
        })
        .catch((reason) => {
          console.error(reason);
        })
        .finally(() => {});
    } else if (rowsDeleted.length > 0) {
      deleteOwning(rowsDeleted, projectId, Number(workloadId), filename)
        .then(() => {
          const deleteIds = rowsDeleted.map((row) => row.id);
          onChange(myRows.filter((row) => !deleteIds.includes(row.id)));
          setSnack('delete ok', 'success');
        })
        .catch((reason) => {
          console.error(reason);
        });
    }
  };
  const handleSave = () => {
    autoSave(rowsEdited, idsChanged);
  };

  const handleDeleteRows = () => {
    if (selection.length === 0) {
      setSnack('Please select the row', 'warning');
    } else {
      setModalOpen(true);
    }
  };

  const handleDeleteConfirm = () => {
    const rowsNotDeleted = rowsEdited.filter((row, index) => !selection.includes(index));
    setRowsEdited(rowsNotDeleted);
    onChange(rowsNotDeleted);
    const rowsDeleted = rowsEdited.filter((row, index) => selection.includes(index));
    deleteOwning(rowsDeleted, projectId, Number(workloadId), filename)
      .then(() => {
        const deleteIds = rowsDeleted.map((row) => row.id);
        onChange(rowsEdited.filter((row) => !deleteIds.includes(row.id)));
        setSnack('Selected record(s) has been successfully deleted!', 'success');
      })
      .catch((reason) => {
        console.error(reason);
      })
      .finally(() => {
        setSelection([]);
        if (dispatch) dispatch({ type: 'init' });
      });
    setModalOpen(false);
  };
  const handleOwning = () => {
    if (selection.length === 0) {
      setSnack('Please select the row', 'warning');
      return;
    }
    const selectedRows = rowsEdited.filter((row, index) => selection.includes(index));
    if (selectedRows.length === 1) setOpenModalAddCaptureRow(true);
    else setOpenModalAddCaptureRow(false);
  };
  const handleEditDrop = () => {
    if (selection.length === 0) {
      setSnack('Please select the row', 'warning');
      return;
    }
    setEditingRowIds(selection);
  };
  const handleEdit = ({ changed }) => {
    let changedRows;
    if (changed) {
      if (translationCode === '-') {
        changedRows = rowsEdited.map((row, index) =>
          changed[index]
            ? {
                ...row,
                ...changed[index],
                i18n: {
                  ...row.i18n,
                  [originalText]: targetColumn ? changed[index][targetColumn] : changed[index].Text,
                },
                updated_at: dayjs.utc().format(),
              }
            : row
        );
      } else {
        changedRows = rowsEdited.map((row, index) =>
          changed[index]
            ? {
                ...row,
                ...changed[index],
                i18n: {
                  ...row.i18n,
                  [translationCode]: targetColumn
                    ? changed[index][targetColumn]
                    : changed[index].Text,
                },
                updated_at: dayjs.utc().format(),
              }
            : row
        );
      }

      const idsChangedNew = rowsEdited.filter((row, index) => changed[index]).map((row) => row.id);
      setIdsChanged([...new Set([...idsChanged, ...idsChangedNew])]);
      setRowsEdited(changedRows);
      if (dispatch) dispatch({ type: 'init' });
      autoSave(changedRows, [...new Set([...idsChanged, ...idsChangedNew])]);
    }
  };
  // KPI
  const showDashboardHandler = () => {
    setOpenDashboard(true);
  };
  const modalImage = (row, rawType = 'images' as rawTypeEnum) => {
    if (rawType === 'images') {
      const imageIndex = imagesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...imagesList.slice(imageIndex),
        ...imagesList.slice(0, imageIndex).reverse(),
      ];
      setImagesList(imagesListSorted);
    }

    if (rawType === 'tables') {
      const itemIndex = tablesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...tablesList.slice(itemIndex),
        ...tablesList.slice(0, itemIndex).reverse(),
      ];
      setTablesList(imagesListSorted);
    }

    setRawType(rawType);
    setToggleRawdata(true);
  };
  const renderSubType = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    if (row.SubType === 'Image') {
      return (
        <Button title="Image" onClick={() => modalImage(row, 'images')}>
          <ImageIcon />
          {value}
        </Button>
      );
    }
    if (row.SubType === 'Table') {
      return (
        <Button title="Table" onClick={() => modalImage(row, 'tables')}>
          <TableChartIcon />
          {value}
        </Button>
      );
    }

    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <Select defaultValue={value} onChange={(e) => handleSubType(e, row, column)}>
        <MenuItem value="Bullet">Bullet</MenuItem>
        <MenuItem value="ParaBullet">ParaBullet</MenuItem>
        <MenuItem value="Heading">Heading</MenuItem>
        <MenuItem value="Paragraph">Paragraph</MenuItem>
        {/* <MenuItem value="Information">Information</MenuItem> */}
        {/* <MenuItem value="Image">Image</MenuItem>
        <MenuItem value="Table">Table</MenuItem> */}
      </Select>
    );
  };

  const renderSubTypeDropDown = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <Select defaultValue={value} onChange={(e) => handleSubType(e, row, column)}>
        <MenuItem value="Bullet">Bullet</MenuItem>
        <MenuItem value="Heading">Heading</MenuItem>
        <MenuItem value="Paragraph">Paragraph</MenuItem>
        <MenuItem value="Information">Information</MenuItem>
        {/* <MenuItem value="Image">Image</MenuItem>
        <MenuItem value="Table">Table</MenuItem> */}
      </Select>
    );
  };
  const handleSubType = (e, row: any, column: Column) => {
    setIdsChanged([...new Set([...idsChanged, row.id])]);
    const rowsEditedNew = rowsEdited.map((r) => {
      if (r.id === row.id) {
        return {
          ...r,
          [column.name]: e.target.value,
          updated_at: dayjs.utc().format(),
        };
      }
      return r;
    });
    setRowsEdited(rowsEditedNew);
  };
  useEffect(() => {
    // populate images
    const imageArray = [];
    const tableArray = [];
    for (const row of rowsEdited) {
      if (row.SubType === 'Image') {
        imageArray.push({ id: row.id, title: row.Text, data: row.RawData });
      }
      if (row.SubType === 'Table') {
        tableArray.push({ id: row.id, data: row.RawData });
      }
    }

    setImagesList(imageArray);
    setTablesList(tableArray);
  }, [rowsEdited]);

  if (!project) return null;

  const cond = [
    'Text',
    'Context',
    'comment',
    // 'Hierarchy',
    // 'Text_original',
    'Context_original',
  ];
  if (targetColumn && targetColumn.length > 0) {
    cond.push(targetColumn);
  }

  const columnsEdit = columns?.map((col: Column) => {
    if (cond.includes(col.name)) {
      return { columnName: col.name, editingEnabled: true };
    }
    return { columnName: col.name, editingEnabled: col.name === 'comment' };
  });

  // table Row Menu
  const SelectionCellComponent = ({ row, selected, onToggle, value, column }) => (
    <TableCell>
      {/* <CheckBoxOutlineBlankIcon onClick={abcd(row)} /> */}
      <div className={classes1.IconStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomWidth2Tooltip
            // open={true}
            placement="top-start"
            // disableTouchListener
            arrow
            title={
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    pointerEvents: !['capture'].includes(actionForFile) ? 'none' : 'initial',
                  }}
                >
                  <a
                    href="#text-buttons"
                    onClick={handleConcat}
                    style={stylingObject.ahref}
                    title="Concat, add a space between text"
                    label="a"
                  >
                    <Avatar className={classes1.avatar}>
                      <PlaylistAddIcon fontSize="small" />
                    </Avatar>
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleMerge}
                    style={stylingObject.ahref}
                    title="Merge, add a new line between text"
                    label="b"
                  >
                    <Avatar className={classes1.avatar}>
                      <MergeIcon fontSize="small" />
                    </Avatar>
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={(e) => handleRepeatMerge('\n')}
                    style={stylingObject.ahref}
                    title="Repeat header"
                  >
                    <Avatar className={classes1.avatar}>
                      <HorizontalSplitIcon fontSize="small" />
                    </Avatar>

                    {/* Repeat Header */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleSplit}
                    style={stylingObject.ahref}
                    title="Split the row"
                  >
                    <Avatar className={classes1.avatar}>
                      <CallSplit fontSize="small" />
                    </Avatar>

                    {/* Split */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleDeleteRows}
                    style={stylingObject.ahref}
                    title="Delete the row"
                  >
                    <Avatar className={classes1.avatar}>
                      <DeleteIcon fontSize="small" />
                    </Avatar>

                    {/* Delete */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleOwning}
                    style={stylingObject.ahref}
                    title="Insert new capture row"
                  >
                    <Avatar className={classes1.avatar}>
                      <ControlPointIcon fontSize="small" />
                    </Avatar>

                    {/* New Capture */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={openSimilarizer}
                    style={stylingObject.ahref}
                    title="Find Similar Items"
                  >
                    <Avatar className={classes1.avatar}>
                      <FileCopyOutlinedIcon fontSize="small" />
                    </Avatar>

                    {/* iSimilarizer */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleEditDrop}
                    style={stylingObject.ahref}
                    title="Edit"
                  >
                    <Avatar className={classes1.avatar}>
                      <EditIcon fontSize="small" />
                    </Avatar>

                    {/* Edit */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleSave}
                    style={stylingObject.ahref}
                    title="Save"
                  >
                    <Avatar className={classes1.avatar}>
                      <SaveIcon fontSize="small" />
                    </Avatar>

                    {/* Edit */}
                  </a>
                </div>
              </>
            }
          >
            <DragIndicatorIcon style={{ color: '#002A45', fontWeight: 'bold' }} fontSize="small" />
          </CustomWidth2Tooltip>
          <Checkbox checked={selected} onChange={onToggle} className={classes1.tableCheck} />
        </div>
      </div>
    </TableCell>
  );
  // Table Edit css for Save and Cancel
  const cellComponentEdit = ({ ...restProps }: any) => {
    return (
      <TableEditColumn.Cell
        {...restProps}
        style={{ paddingRight: 50 }}
        className={classes1.PaginationButton}
      />
    );
  };
  return (
    <>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            selection,
            languages,
            translationCode,
            languageOriginCode,
            currentPage,
            rowsEdited,
            targetColumn,
            columns,
            displayColumns,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      {loading ? (
        <div
          style={{
            width: '100%',
            minHeight: 250,
            display: 'flex',
            gap: '10px',
            padding: '1rem',
            flexDirection: 'column',
          }}
        >
          <Skeleton variant="rounded" height={80} animation="wave" />
          <Skeleton variant="rounded" height={80} animation="wave" />
          <Skeleton variant="rounded" height={80} animation="wave" />
        </div>
      ) : (
        <>
          <div style={{ padding: '0 10px' }}>
            <ExpressGrid rows={rowsEdited} columns={columns}>
              {/* <DataTypeProvider for={['Menu']} formatterComponent={renderId} /> */}
              <DataTypeProvider
                for={['Text']}
                formatterComponent={(dataTable) =>
                  //   {
                  //   console.log(dataTable, 'dataTable');
                  //   return <p>hello</p>;
                  // }
                  //   {
                  //   console.log(dataTable, 'dataTable');
                  //   return <p>hello</p>;
                  // }
                  RenderCellText({
                    filename,
                    dataTable,
                    translationCode,
                    originalText,
                    toggleHeight,
                    onSearchText,
                  })
                }
              />
              {displayColumns && (
                <DataTypeProvider
                  for={displayColumns}
                  formatterComponent={(dataTable) =>
                    RenderCellExcel({
                      dataTable,
                      toggleHeight,
                    })
                  }
                />
              )}
              {targetColumn && (
                <DataTypeProvider
                  for={[targetColumn]}
                  formatterComponent={(dataTable) =>
                    RenderCellExcel({
                      dataTable,
                      translationCode,
                      toggleHeight,
                    })
                  }
                />
              )}
              <DataTypeProvider for={['comment']} formatterComponent={commentField} />
              {displayColumns && (
                <DataTypeProvider for={['comment']} formatterComponent={commentField} />
              )}

              <DataTypeProvider for={['key_mix1']} formatterComponent={renderMix1} />
              <DataTypeProvider
                for={['RecordStatus']}
                formatterComponent={({ value }) => (
                  <span className={value === 'Active' ? classes.active : classes.inactive}>
                    {value}
                  </span>
                )}
              />
              <DataTypeProvider for={['SubType']} formatterComponent={renderSubType} />
              {/* <DataTypeProvider for={['SubType']} formatterComponent={renderSubTypeDropDown} /> */}
              {/* selection */}
              <SelectionState selection={selection} onSelectionChange={setSelection} />
              <IntegratedSelection />

              <SortingState />
              <IntegratedSorting />

              <FilteringState />
              <PagingState
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                defaultCurrentPage={0}
                currentPage={currentPage}
                defaultPageSize={50}
                onCurrentPageChange={(p) => {
                  setCurrentPage(p);
                }}
              />

              <IntegratedFiltering />
              <IntegratedPaging />
              <Table
                columnExtensions={columns?.map((c) => ({
                  columnName: c.name,
                  wordWrapEnabled: toggleHeight,
                }))}
                tableComponent={MultiAllocTableComp}
                containerComponent={tableAllocatorComponent}
                headComponent={tableHeaderComponent}
              />
              <TableColumnResizing
                defaultColumnWidths={[
                  ...columns.map((c: any) => {
                    const { name } = c || '';
                    if (name === 'Text') return { columnName: c.name, width: textWidth };
                    // if (c.name === 'Menu') return { columnName: 'Menu', width: 80 };
                    // if (c.name === 'Text_original') return { columnName: c.name, width: textWidth };
                    if (name === 'comment') return { columnName: c.name, width: 150 };
                    if (name === targetColumn)
                      return { columnName: targetColumn, width: textWidth };
                    if (name === 'Context') return { columnName: c.name, width: 300 };
                    if (name === 'Context_original') return { columnName: c.name, width: 300 };
                    if (name === 'updated_at') return { columnName: c.name, width: 200 };
                    if (name === 'id') return { columnName: c.name, width: 120 };
                    if (name === 'PageNo') return { columnName: c.name, width: 150 };
                    if (name === 'Hierarchy') return { columnName: c.name, width: 120 };
                    if (name === 'Original_Hierarchy') return { columnName: c.name, width: 200 };
                    if (name.includes('Heading_Level')) return { columnName: c.name, width: 200 };
                    if (name === 'Char_Score') return { columnName: c.name, width: 80 };
                    if (name === 'SubType') return { columnName: c.name, width: 120 };
                    return {
                      columnName: c.name,
                      width: Math.ceil(1400 / columns?.length),
                    };
                  }),
                ]}
              />
              <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} />
              <TableFilterRow />
              <TableColumnVisibility
                defaultHiddenColumnNames={
                  JSON.parse(localStorage.getItem('storedColumn')) || columnSelected
                }
                onHiddenColumnNamesChange={(arr) => {
                  localStorage.setItem('storedColumn', JSON.stringify(arr));
                  setColumnSelected(arr);
                }}
              />
              <Toolbar rootComponent={toolbarComponent} />
              {/* selection */}
              <TableSelection showSelectAll cellComponent={SelectionCellComponent} />
              <ColumnChooser />
              {languages && languages.length > 0 && (
                <ToolBarPlugin
                  languages={languages}
                  languageCode={translationCode}
                  languageOriginCode={languageOriginCode}
                  name="Translation"
                  title="Translation"
                  onTranslate={(lang) => {
                    if (lang) setTranslationCode(lang);
                  }}
                />
              )}
              {/* <ToolBarPlugin
          name="Merge"
          title="Merge, add a new line between text"
          onClick={handleMerge}
          hide={stateOfTab}
        /> */}

              {/* <ToolBarPlugin
          name="Concat"
          title="Concat, add a space between text"
          onClick={handleConcat}
          hide={stateOfTab}
        /> */}
              {/* <ToolBarPlugin
          name="Merge"
          title="Merge, add a new line between text"
          onClick={handleMerge}
        />
        <ToolBarPlugin name="MergeRepeat" title="Repeat header" onClick={handleRepeatMerge} />
        <ToolBarPlugin name="Split" onClick={handleSplit} /> */}
              {/* <ToolBarPlugin name="NewCapture" onClick={handleOwning} title="Insert new capture row" /> */}
              {/* <ToolBarPlugin name="Save" onClick={handleSave} /> */}
              {/* <ToolBarPlugin name="Delete" onClick={handleDeleteRows} /> */}
              <ToolBarPlugin name="Refresh" onClick={handleRefesh} />
              <ToolBarPlugin name="Dashboard" onClick={showDashboardHandler} title="KPIs" />
              {/* <ToolBarPlugin name="iSimilarizer" title="Find Similar Items" onClick={openSimilarizer} /> */}
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <ToolBarPlugin
                name="Download"
                hide={downloadLoad}
                loading={downloadLoad}
                onClick={() => {
                  exportAllData();
                }}
              />
              {/* edition */}
              <EditingState
                onCommitChanges={handleEdit}
                columnExtensions={columnsEdit}
                // these two lines added to Edit button shift to dropdown edit
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={setEditingRowIds}
              />
              {/* <TableEditRow /> */}
              <PopupEditing popupComponent={Popup} targetColumn={targetColumn} />
              <PagingPanel
                pageSizes={pageSizes}
                containerComponent={(props) => (
                  <>
                    <PagingPanel.Container {...props} className={classes1.pagingPanelContainer} />
                  </>
                )}
              />
              <TableEditColumn width={5} cellComponent={cellComponentEdit} />
            </ExpressGrid>
            <GridExporter
              ref={exporterRef}
              rows={rowsExport}
              columns={displayColumnDown}
              onSave={onDownload}
            />
            <NavigationGotoNew
              setPageSize={setPageSize}
              fileLength={rowsEdited?.length}
              pageSize={pageSize}
              dis={dis}
              setTemPage={setTemPage}
              handlePageNum={handlePageNum}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          {openSim && rowsSim.length && <Similarizer inputSimList={rowsSim} />}
          {openModalSplit && selection.length === 1 && (
            <ModalSplit
              requirement={rowsEdited.find((row, index) => selection.includes(index))}
              onClose={handleModalClose}
              onSubmit={handleSplitSubmit}
              targetColumn={targetColumn}
            />
          )}
          {openModalAddCaptureRow && selection.length === 1 && (
            <ModalAddCaptureRow
              onClose={handleModalClose}
              onSubmit={handleInsertCaptureRowSubmit}
              targetColumn={targetColumn}
            />
          )}
          {toggleRawdata && (
            <ModalRawdata
              imagesList={imagesList}
              tablesList={tablesList}
              rawType={rawType}
              onClose={() => {
                setToggleRawdata(false);
              }}
            />
          )}
          {openDashboard && (
            <CaptureModalKpi
              project_id={projectId}
              workload_id={workloadId}
              file_name={filename}
              // user_id={userJWT.user_id}
              onClose={() => {
                setOpenDashboard(false);
              }}
            />
          )}
          {modalOpen && (
            <ModalConfirm
              open={modalOpen}
              onConfirm={handleDeleteConfirm}
              onCancel={() => {
                setModalOpen(false);
              }}
              label="isDeleteRow"
            />
          )}
        </>
      )}
    </>
  );
}

export default CaptureFlow;
