import { styled } from '@mui/material/styles';
import React from 'react';
import { Box, IconButton, InputBase, Typography, Button } from '@mui/material';

const ProjectFolderContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',

  '& .fileHeader': {
    display: 'grid',
    height: '3.5rem',
    borderBottom: '1px solid #ccd6f2',
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '0.5rem',
    gridTemplateColumns: '1fr 1fr',

    '& .fileHeaderNav': {
      display: 'flex',
      gap: '10px',
      height: '100%',
      alignItems: 'center',
      '& .MuiIconButton-root': {
        color: '#002A45',
      },
    },
  },
  '& .fileSpaceContent': {
    gap: '5px',
    width: '100%',
    height: 'calc(100% - 7rem)',

    '& .eachFolder': {
      display: 'flex',
      width: '6rem',
      height: '6rem',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '1px',
      '&:hover': {
        backgroundColor: '#dde4ea',
        transition: 'all 0.4s ease-out',
      },
      '& .label': {
        color: '#002A45',
        letterSpacing: 'normal',
        fontSize: '0.7rem',
      },
    },
  },
  '& .scopeSetting': {
    padding: '0.3rem 1rem',
    width: '100%',
    height: '3.5rem',
    display: 'flex',
    gap: '0.5rem',
    border: '1px solid #ccd6f2',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'rgb(255, 255, 255)',
  },
}));

const FileBtn = styled((props) => <Button {...props} />)(({ theme, disabled, cancel = false }) => ({
  // margin: '0 auto !important',
  minHeight: '3rem !important',
  minWidth: '5rem !important',
  opacity: disabled ? '0.3' : '1',
  backgroundColor: cancel ? 'rgb(255, 255, 255)' : '#002a45 !important',
  color: cancel ? '#002A45' : 'rgb(255, 255, 255) !important',
  display: 'flex !important',
  borderRadius: '0.5rem !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
  transition: 'all 0.3s ease !important',
  boxShadow:
    'rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem !important',
}));

const FolderContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  '& .eachFolder': {
    display: 'flex',
    width: '6rem',
    height: '6rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1px',
    '&:hover': {
      backgroundColor: '#dde4ea',
      transition: 'all 0.4s ease-out',
    },
    '& .label': {
      color: '#002A45',
      letterSpacing: 'normal',
      fontSize: '0.7rem',
    },
  },
}));

const FolderContainerLabel = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: '#002A45',
  letterSpacing: 'normal',
  fontSize: '0.7rem',
  // fontWeight: 'bold',
}));

const FileSpaceContent = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '1rem 0.5rem',
  // border: '1px solid #ccd6f2',
  gap: '5px',
  width: '100%',
  height: 'calc(100% - 3.5rem)',
  maxHeight: '100%',
  overflow: 'auto',
}));

const FileHeader = styled((props) => <Box {...props} />)(({ theme }) => ({}));

// const FileHeaderNav = styled((props) => <Box {...props} />)(({ theme }) => ({
//   display: 'flex',
//   gap: '10px',
//   height: '100%',
//   alignItems: 'center',
// }));

const FileHeaderIconBtn = styled((props) => <IconButton {...props} />)(({ theme }) => ({
  '&.MuiIconButton-root': {
    padding: '2px',
    margin: '2px',
  },
}));

const FileInputContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  margin: '2px',
  width: '100%',
  height: '100p%',
  maxHeight: '100%',
  borderRadius: '8px',
  border: '1px solid #998760CE',
}));

const FodlerNameContaienr = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  // backgroundColor: 'rgb(23, 193, 232)',
  borderBottom: '3px solid #002a45',
  minWidth: '4rem',
  color: '#002a45',
  '& .MuiTypography-root': {
    color: '#002A45',
    letterSpacing: 'normal',
    fontSize: '0.89rem',
    fontWeight: 'bold',
  },
}));

const RenameFolderContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  '& .MuiIconButton-root': {
    color: '#002A45',
  },
}));

const FileInputFeildEnter = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  fontSize: 'average',
  height: '100%',
  borderRadius: '8px 0 0 8px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
}));

export {
  FileBtn,
  FolderContainer,
  FolderContainerLabel,
  FileSpaceContent,
  FileHeader,
  ProjectFolderContainer,
  FileHeaderIconBtn,
  FileInputContainer,
  FileInputFeildEnter,
  FodlerNameContaienr,
  RenameFolderContainer,
};
