import React, { useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useModule2services from '../../Hooks/useModule2services';
import DocTranslatorHeader from './core/DocTranslatorHeader';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import { FileIcon } from '../../Project/ProjectContainer/components/ProjectFolder/Files';
import useError from '../../Project/ProjectContainer/components/ProjectFolder/Hooks/useError';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '& .header': {
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .content': {
    height: 'calc(100% - 5rem)',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    padding: '1rem 0.5rem',
    gap: '5px',

    '& .fileContainer': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '2px',
      gap: '5px',
      // border: '1px solid #ccc',
    },
  },

  '& .footer': {
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
const ToolTipComponent = ({ children, style = {} }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const rows = [
    { col1: 'Fluid', col2: 'Fluide' },
    { col1: 'Fuel', col2: 'Combustible' },
    { col1: 'Fuse', col2: 'Fusible' },
    { col1: 'Pneumatic', col2: 'Pneumatique' },
  ];

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        style={{ ...style }}
        onMouseLeave={handlePopoverClose}
      >
        {children}{' '}
        {/* <div
          style={{
            position: 'absolute',
            top: '8px',
            right: '3px',
            height: '15px',
            width: '15px',
            borderRadius: '50%',
            // backgroundColor: 'red',
            border: ' 1px solid',
            color: 'white',
            padding: '2px',
            fontSize: '9px',
            textAlign: 'center',
          }}
        >
          i
        </div> */}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        PaperProps={{
          style: {
            width: '25rem',
            backgroundColor: '#002A45',
            color: 'white',
            borderRadius: '19px',
            textAlign: 'justify',
            marginTop: '1.2rem',
          },
        }}
        // style={{
        //   width: '40rem',
        //   backgroundColor: '#002A45',
        //   // color: 'white',
        //   // borderRadius: '19px',
        // }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }} style={{ fontSize: '0.98em', color: 'white' }}>
          {' '}
          A glossary is a list of terms with definitions that you create for the Document
          Translation service to use during the translation process.
        </Typography>
        <Typography sx={{ p: 1 }} style={{ fontSize: '0.98em', color: 'white' }}>
          {' '}
          A glossary file is specific to a single language pair. For different language pairs,
          different glossaries need to be used.
        </Typography>
        <Typography sx={{ p: 1 }} style={{ fontSize: '0.98em', color: 'white' }}>
          {' '}
          The following shall be the format of the glossary containing two columns in csv:
        </Typography>
        <Paper sx={{ overflow: 'hidden', margin: '20px' }}>
          <TableContainer sx={{ maxHeight: 300 }}>
            <Table size="small" aria-label="caption table">
              {/* <caption>A basic table example with a caption</caption> */}

              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.col1}>
                    <TableCell scope="row">{row.col1}</TableCell>
                    <TableCell align="left">{row.col2}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Popover>
    </>
  );
};

const DocTranslatorAnalysis = (props) => {
  const {
    scopeSettings: temp,
    setScopeSettings,
    serviceFor,
    serviceName,
    setWorkload,
    globalGlossary,
    docTranslation,
    setDocTranslation,
    setOpenGlossary,
    setInputFileOpen,
    actualFilSupported,
  } = props;
  // const [FileSettting, setFileSetting] = React.useState(scopeSettings);
  const { model } = docTranslation;
  const [errorMsg, setErrorMsg] = useError();

  const scopeSettings = useMemo(() => {
    return temp.filter((item) => {
      const ext = item.filename.split('.').pop();
      return actualFilSupported.includes(ext);
    });
  }, [actualFilSupported, temp]);

  const { loadingResult, handleAnalyze } = useModule2services({
    scopeSetting: scopeSettings,
    globalGlossary,
    docTranslation,
    serviceName,
    serviceFor,
  });

  const herderProps = {
    docTranslation,
    setDocTranslation,
  };

  // console.log('scopeSettings', scopeSettings);
  const HandleService = async () => {
    // if (!docTranslation.toLang) {
    //   console.log('error', 'Please select the target language');
    //   return;
    // }
    // if (!docTranslation.model) {
    //   console.log('error', 'Please select the translation model');
    //   return;
    // }
    const result = await handleAnalyze();
    // const result = { error: false, message: '', workloadIds: [1, 2, 2] };
    if (result.error) {
      console.log('service error', result.message);
      setErrorMsg(result.message);
    } else {
      setWorkload((prev) => {
        return {
          ...prev,
          workloads: result.workloadIds,
          serviceFor,
        };
      });
    }
  };

  return (
    <>
      <Container>
        <Box className="header">
          <Tooltip title="Back" placement="top">
            <IconButton
              style={{ minWidth: '2rem' }}
              onClick={() => {
                setScopeSettings([]);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <div style={{ width: 'calc(100% - 2rem - 8rem)', display: 'flex' }}>
            <DocTranslatorHeader {...herderProps} />
          </div>
          {model !== 'DeepL' && (
            <ToolTipComponent style={{ minWidth: '8rem' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenGlossary({
                    open: true,
                    indexes: [...scopeSettings.map((d, index) => index)],
                  });
                  setInputFileOpen(true);
                }}
              >
                Use Glossary
              </Button>
            </ToolTipComponent>
          )}
        </Box>
        <Box className="content">
          {scopeSettings.map((item, index) => {
            return (
              <Box className="fileContainer">
                <FileIcon filename={item.filename} />
                {/* <Typography>{item.filename}</Typography> */}
                <div>
                  {model !== 'DeepL' && (
                    <IconButton
                      onClick={() => {
                        setOpenGlossary({ open: true, indexes: [index] });
                        setInputFileOpen(true);
                      }}
                      disabled={'glossary' in item}
                    >
                      {'glossary' in item ? (
                        <CheckCircleIcon style={{ color: 'green' }} fontSize="small" />
                      ) : (
                        <AttachmentIcon />
                      )}
                    </IconButton>
                  )}
                  {'glossary' in item && item.glossary.length > 0 && (
                    <Typography style={{ display: 'inline' }} fontSize="small">
                      {item.glossary[0].filename}
                    </Typography>
                  )}
                </div>
              </Box>
            );
          })}
        </Box>
        <Box className="footer">
          {errorMsg && (
            <Typography variant="caption" color="error">
              {errorMsg}
            </Typography>
          )}
          <Button
            disabled={loadingResult}
            variant="contained"
            color="primary"
            onClick={() => {
              HandleService();
            }}
          >
            Translate
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default DocTranslatorAnalysis;
