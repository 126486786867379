import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, InputBase, Typography } from '@mui/material';
import { FileBtn } from './styles';
import { folderCreate } from '../../../../../api/folders';
import ProjectContext from '../../../ProjectContext';

const FolderCreationBox = styled((props) => <Box {...props} />)(({ theme, openFolderCreate }) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: 'large',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.7em',
  },
  '&.PopUpvisible': {
    // [panelName]: 0,
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    maxHeight: '100vh',
    maxWidth: '100vw',
    zIndex: 1299,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '& .Foldercontainer': {
      width: '100%',
      height: '100%',
      display: openFolderCreate ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      '& .FolderInputFeild': {
        padding: '1rem',
        // height: '5rem',
        borderRadius: '5px',
        position: 'relative',
        // width: '3rem',
        width: 'max-content',
        backgroundColor: 'white',
        display: openFolderCreate ? 'flex' : 'none',
        gap: '10px',

        '& .errorMessage': {
          position: 'absolute',
          fontSize: '0.67rem',
          marginLeft: '0.5rem',
          color: 'red',
          top: '0',
          left: '0',
        },
        '& .MuiInputBase-root': {
          display: openFolderCreate ? 'inline-flex' : 'none',
        },
        '& .MuiButton-root': {
          display: openFolderCreate ? 'block' : 'none',
        },
        '& .MuiIconButton-root': {
          display: openFolderCreate ? 'block' : 'none',
        },
      },
    },
  },
  '&.PopUpinVisible': {
    display: 'none',
  },
}));

const FolderModel = ({ openFolderCreate, setOpenFolderCreate, project_id }) => {
  const [folderState, setFolderState] = useState({
    name: '',
    error: false,
    message: '',
  });
  const { name, error, message } = folderState;
  const { projects, setProjects } = useContext(ProjectContext);
  // console.log(projects);
  const validetFolderName = (name: string): boolean => {
    const existingNames = projects[0]?.folders?.map((f) =>
      String(f.name).toLowerCase().trim()
    ) as string[];
    if (existingNames?.includes(name.toLowerCase())) {
      setFolderState((prev) => {
        return {
          ...prev,
          error: true,
          message: `Folder with name ${name} already exists`,
        };
      });
      return false;
    }
    // setFolderState((prev) => {
    //   return {
    //     ...prev,
    //     error: false,
    //     message: '',
    //   };
    // });
    return true;
  };

  const handleSubmit = async () => {
    // validate name
    if (!validetFolderName(name)) {
      return;
    }

    folderCreate({
      name,
      project_id,
    })
      .then((f: any) => {
        const p = projects.map((_p) => {
          if (_p.id === Number(project_id))
            return {
              ..._p,
              folders: [...(_p.folders || []), f],
            };
          return _p;
        });
        setProjects(p);
        setFolderState((prev) => {
          return {
            ...prev,
            error: false,
            message: '',
            name: '',
          };
        });
        setOpenFolderCreate(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <FolderCreationBox
        className={openFolderCreate ? 'PopUpvisible' : 'PopUpinVisible'}
        openFolderCreate={openFolderCreate}
      >
        <Box className="Foldercontainer">
          <Box className="FolderInputFeild">
            <InputBase
              value={name}
              placeholder="Folder Name"
              onChange={(event) => {
                setFolderState((prev) => {
                  return {
                    ...prev,
                    name: event.target.value,
                  };
                });
              }}
            />
            <FileBtn
              disabled={name === undefined || String(name).length === 0}
              onClick={() => {
                handleSubmit();
              }}
            >
              Create
            </FileBtn>
            <IconButton
              onClick={() => {
                setOpenFolderCreate(false);
                setFolderState((prev) => {
                  return {
                    ...prev,
                    error: false,
                    message: '',
                    name: '',
                  };
                });
              }}
            >
              <CloseIcon />
            </IconButton>
            {error && (
              <Typography color="error" className="errorMessage">
                {message}
              </Typography>
            )}
          </Box>
        </Box>
      </FolderCreationBox>
    </>
  );
};

export default FolderModel;
