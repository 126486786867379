/* eslint-disable react/destructuring-assignment */
import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FileDocIcon } from '../../../assets/svg';
import Files from '../../Project/ProjectContainer/components/ProjectFolder/Files';
import UserSpaceContext from '../UserSpaceContext';
import { FileBtn, FileInputContainer, FileInputFeildEnter, ProjectFolderContainer } from './styles';
import useUserFileUpload from '../../../layouts/Hooks/useUserFileUpload';
import useSuccess from '../../Project/ProjectContainer/components/ProjectFolder/Hooks/useSuccess';
import useDeletFile from '../../../layouts/Hooks/useDeletFile';

const UserFileManager = (props) => {
  const {
    isChild,
    numberOfFilesSupport,
    handleScopeSettings,
    select = 'single',
    fileTypeSupport,
    serviceFor,
  } = props || false;
  const { files: filesfromContext, setFiles } = useContext(UserSpaceContext);
  const [filesDetails, setFilesDetails] = useState({
    folderId: null,
    files: filesfromContext,
  });
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useSuccess();
  const { files } = filesDetails;
  const [moveTo, setMoveTo] = useState('files');
  const [searchfiles, setSearchFiles] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);
  const { id = 0, name = 'user' } = serviceFor ?? {};
  const inputRef = useRef();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  }, [error]);

  const actualFiles = useMemo(() => {
    try {
      const temp =
        files
          ?.filter((file: any) => {
            if (fileTypeSupport.length > 0) {
              const ext = file?.filename?.split('.')?.pop() ?? '';
              return fileTypeSupport?.includes(ext);
            }
            return file;
          })
          .sort((a, b) => new Date(b.date) - new Date(a.date)) ?? [];
      if (searchfiles) {
        return temp.filter((file) =>
          file.filename.toLowerCase().includes(searchfiles.toLowerCase())
        );
      }
      return temp;
    } catch (error) {
      console.log('error', error);
      return [];
    }
  }, [files, searchfiles]);

  const { loading, handleUploadFile } = useUserFileUpload({
    user_id: id,
    fileTypeSupport,
    doScopeSetting: false,
    files: actualFiles,
    setFiles,
  });

  const { loading: deleteLoading, DeleteUserFile } = useDeletFile({
    user_id: id,
    selectedFiles,
    setFiles,
    files: actualFiles,
  });

  const deleteFile = async () => {
    try {
      const result = await DeleteUserFile();
      if (!result?.error) {
        setFilesDetails((prev) => {
          return { ...prev, files: result?.data };
        });
        setSuccessMsg(result?.message);
        setSelectedFiles([]);
      } else {
        setError(result?.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  // console.log('folders', files);

  const renderComponent = () => {
    switch (moveTo) {
      case 'files':
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
      default:
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
    }
  };

  const filehandleChangeFiles = async (event: any) => {
    try {
      const arr = Object.values(event.target.files);
      if (arr.length === 0) {
        return;
      }
      const data = await handleUploadFile(arr);
      if (!data?.data.error) {
        setFilesDetails((prev) => {
          return { ...prev, files: data?.data };
        });
        setSuccessMsg(data.message);
        setFiles(data?.data);
      } else {
        setError(data?.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const UploadFile = () => {
    inputRef.current.click();
  };

  return (
    <ProjectFolderContainer className="root">
      <input type="file" ref={inputRef} multiple hidden onChange={filehandleChangeFiles} />
      <Box className="fileHeader">
        <Box className="fileHeaderNav">
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
          {successMsg && (
            <Typography variant="caption" color="success">
              {successMsg}!
            </Typography>
          )}
          {/* <IconBtn
            onClick={() => {
              setMoveTo('folder');
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </IconBtn>
          <IconBtn
            onClick={() => {
              setMoveTo('files');
            }}
          >
            <ArrowForwardIcon fontSize="small" />
          </IconBtn> */}
        </Box>

        <Box className="fileHeaderNav">
          <IconButton
            disabled={deleteLoading}
            onClick={() => {
              deleteFile();
            }}
          >
            {deleteLoading ? (
              <CircularProgress style={{ color: '#002A45' }} size={20} />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
          <IconButton
            disabled={loading}
            onClick={() => {
              UploadFile();
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: '#002A45' }} size={20} />
            ) : (
              <FileUploadIcon />
            )}
          </IconButton>
          <FileInputContainer>
            <FileInputFeildEnter
              placeholder="Search for file"
              sx={{ flex: 9, pl: 1, color: '#002A45' }}
              onChange={(e) => {
                setSearchFiles(e.target.value);
              }}
            />
          </FileInputContainer>
          {isChild && props.renderCross()}
        </Box>
      </Box>
      <Box
        className="fileSpaceContent"
        // style={scopeSetting ? { maxHeight: 'calc(100% - 7rem)', overflow: 'auto' } : {}}
      >
        {renderComponent()}
      </Box>
      <Box
        style={{
          // height: '3rem',
          padding: '0.3rem 1rem',
          width: '100%',
          display: 'flex',
          border: '1px solid #ccd6f2',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          backgroundColor: 'rgb(255, 255, 255)',
        }}
      >
        <FileBtn
          disabled={
            select === 'single'
              ? numberOfFilesSupport !== selectedFiles.length
              : numberOfFilesSupport < selectedFiles.length || selectedFiles.length === 0
          }
          onClick={() => {
            handleScopeSettings(selectedFiles);
          }}
        >
          Proceed
        </FileBtn>
      </Box>
    </ProjectFolderContainer>
  );
};

export default memo(UserFileManager);
