/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PercentageLoading from './PercentageLoading';
import useWebSocketClient from './useWebSocketClient';
// import sampleData from './sampleData';
import { FileIcon } from '../../containers/Project/ProjectContainer/components/ProjectFolder/Files';
import downloadTranslateFile from '../../tools/downloadTranslateFile';
import { getBearerTokenSync } from '../../app/AppToken';

const MultiFileLoading = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  // justifyContent: 'center',
  // alignItems: 'center',
  padding: '1rem 0.5rem',
  gap: '5px',
  '& .fileContainer': {
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    // padding: '2px',
    // gap: '10px',
    display: 'grid',

    // border: '1px solid #ccc',
  },
}));

const SkipButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: '100px',
  height: '3rem',
  padding: 0,
  psotion: 'relative',
  // outline: 'none',
  // border: 'none',
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'flex-start',
}));

const LoadingParent = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '1rem 0.5rem',
  gap: '10px',
  '& .skipButton': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    '& .button': {
      width: '100px',
      height: '3rem',
      '& .skipload': {
        height: '100%',
        backgroundColor: '#76c7c0',
        transition: 'width 0.5s ease-in-out',
      },
    },
  },
}));

const hyperLinkSkip = (serviceFor, serviceName) => {
  const { name, id } = serviceFor;
  if (name === 'project') {
    return `/project/${id}/?service=${'project_history'}`;
  }

  if (serviceName === 'Document Translator') {
    return `/userspace/DocumentTranslator?service=${serviceName}&tab=${'user_history'}`;
  }
  if (serviceName === 'Rex Book') {
    return `/userspace/RexBook?service=${serviceName}&tab=${'user_history'}`;
  }
  return `/userspace/DocAnalysis?service=${serviceName}&tab=${'user_history'}`;
};

const RenderSkipButton = ({ serviceFor, serviceName }) => {
  const [normalizedPercentage, setNormalizedPercentage] = useState(1);
  const [wait, setWait] = useState(false);
  const history = useHistory();
  const interval = useRef<number | undefined>(undefined);
  // React.useEffect(() => {
  //   interval.current = setInterval(() => {
  //     setNormalizedPercentage((prev) => {
  //       if (prev >= 100) {
  //         clearInterval(interval.current);
  //         history.push(hyperLinkSkip(serviceFor, serviceName));

  //         return 100;
  //       }
  //       return prev + 5;
  //     });
  //   }, 250);

  //   return () => {
  //     clearInterval(interval.current);
  //   };
  // }, []);
  // console.log('normalizedPercentage', normalizedPercentage);

  const handleWait = () => {
    setWait(true);
    clearInterval(interval.current);
  };

  const handleSkip = () => {
    history.push(hyperLinkSkip(serviceFor, serviceName));
  };

  return (
    <div className="skipButton">
      {/* {!wait && (
        <Box className="button">
          <SkipButton
            style={{ width: '100%' }}
            // variant="contained"
            // color="primary"
            onClick={() => {
              handleWait();
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              Wait
            </div>
          </SkipButton>
        </Box>
      )} */}
      <Box className="button">
        <SkipButton
          style={{ width: '100%', height: '100%' }}
          // variant="contained"
          // color="primary"
          onClick={() => {
            handleSkip();
          }}
        >
          {/* {!wait && (
            <div style={{ width: `${normalizedPercentage}%` }} className="skipload">
              {''}
            </div>
          )} */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            Skip
          </div>
        </SkipButton>
      </Box>
    </div>
  );
};

const WebSocketLoading = (props) => {
  const { serviceName, serviceFor, workload, setSelectedChildIndex } = props;

  const { status, webSocketResponse } = useWebSocketClient({
    serviceName,
    serviceFor,
    workloads: workload.workloads,
    workload: workload.workload ?? 0,
  });

  // console.log('serviceFor', serviceFor);
  // const fileName = row?.resources[0]?.filename;
  // const language = row?.resources[0]?.settings?.nameOfCapture;

  const extractFileName = (filename_, nameOfCapture) => {
    const fileName = filename_;
    const language = nameOfCapture;
    const lastDotIndex = fileName.lastIndexOf('.'); // Find the last dot in the filename
    const baseName = fileName.substring(0, lastDotIndex); // Extract base name "sample.test"
    const extension = fileName.substring(lastDotIndex + 1); // Extract extension "pdf"
    const newFileName = `${baseName}-${language}.${extension}`;
    return newFileName;
  };

  const handleDownload = (row: string) => {
    /* eslint consistent-return: ["error", { "treatUndefinedAsUnspecified": false }] */
    // eslint-disable-next-line no-restricted-globals

    if (row?.errors.length > 0) {
      const error = `Error during translation ${row?.errors[0].msg}`;
      // setOpen(true);
      // setDowError(error);
      // const msg = row?.errors[0].msg;
      // alert(`Error during translation: ${msg}`, 'error');
      return;
    }
    const token = getBearerTokenSync();
    const querystring = `files=${'translate_file'}`;
    const { name, id } = serviceFor;
    const fileName = row?.resources[0]?.filename;
    const language = row?.resources[0]?.settings?.nameOfCapture;
    const newFileName = extractFileName(fileName, language);
    if (row.action === 'Translator') {
      if (name === 'project')
        downloadTranslateFile(`/download/resources/${row?.id}`, querystring, token, newFileName);
      else if (name === 'user')
        downloadTranslateFile(
          `/download/userresources/${row?.id}`,
          querystring,
          token,
          newFileName
        );
    } else if (row.action === 'RexBook') {
      if (name === 'project')
        downloadTranslateFile(
          `/download/userresources/${row?.id}/rexbook/${'projects'}`,
          querystring,
          token,
          newFileName
        );
      else if (name === 'user')
        downloadTranslateFile(
          `/download/userresources/${row?.id}/rexbook/${'userworkspace'}`,
          querystring,
          token,
          'Rexbook.docx'
        );
    }
  };

  const renderLoading = (status) => {
    return status.percentage.length === 1 && serviceName !== 'Document Translator' ? (
      <PercentageLoading percentage={status.percentage[0]} />
    ) : (
      <MultiFileLoading>
        {webSocketResponse.map((item, index) => {
          const { resources, progress_percent, status, action } = item ?? {
            resources: [],
            progress_percent: 0,
          };
          let newFileName = '';
          const { filename } = resources[0] ?? { filename: '' };
          if (action === 'Translator') {
            const language = resources[0]?.settings?.nameOfCapture ?? '';
            newFileName = extractFileName(filename, language);
          } else {
            newFileName = filename;
          }
          return (
            <div
              key={index}
              className="fileContainer"
              style={
                status === 'end' && (action === 'Translator' || action === 'RexBook')
                  ? { display: 'flex', alignItems: 'center' }
                  : { gridTemplateColumns: '1fr 1fr' }
              }
            >
              <FileIcon filename={newFileName} />
              {/* <p>{filename}</p> */}

              {status === 'end' && (action === 'Translator' || action === 'RexBook') ? (
                <div style={{ display: 'inline' }}>
                  <IconButton
                    style={{ color: '#002a45' }}
                    size="small"
                    onClick={() => handleDownload(item)}
                  >
                    <DownloadForOfflineIcon
                      style={{
                        color: 'green',
                        marginRight: 2,
                        // backgroundColor: 'rgb(255, 255, 255)',
                        borderRadius: '50%',
                      }}
                      titleAccess="Download translated file"
                    />
                  </IconButton>
                </div>
              ) : (
                <PercentageLoading percentage={progress_percent} isLinear={true} />
              )}
            </div>
          );
        })}
      </MultiFileLoading>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {status.isDone && serviceName !== 'Document Translator' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            // setSelectedChildIndex(2);
          }}
        >
          View
        </Button>
      ) : (
        <LoadingParent>
          {renderLoading(status)}
          <RenderSkipButton serviceFor={serviceFor} serviceName={serviceName} />
        </LoadingParent>
      )}
      {/* <h1>WebSocketLoading</h1> */}
    </div>
  );
};

export default WebSocketLoading;
