/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import React, { useState, useRef, useCallback } from 'react';
import { Tooltip, BarChart, XAxis, YAxis, CartesianGrid, Legend, Bar, Cell } from 'recharts';
import {
  ColumnChooser,
  TableColumnVisibility,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  makeStyles,
} from '@material-ui/core';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@material-ui/icons/Close';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import { rowComponent } from '../../../components/Datagrid/TStyles';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import Milestone from './Milestone';
import KeyDatesTable from './KeyDatesTable';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
}));

const tabKey = {
  0: '0px',
  1: '300px',
  2: '600px',
  3: '800px',
};

const ScheduleDetailTable = ({ open, setOpen, dataName, tableData, tab }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageSize1, setPageSize1] = useState(5);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [tempPage1, setTemPage1] = useState(0);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [newLine, setNewLine] = useState<any>('');
  const [currentPage1, setCurrentPage1] = useState(0);
  const [toggleHeight, setToggleHeight] = useState(false);
  const exporterRef = useRef(null);
  // const checkDtTt = JSON.parse(wbs_vs_schedule);
  const typicalDeliverables = [];
  const detailedActivities = [];

  tableData.forEach((item) => {
    if (item['Typical Deliverables']) {
      typicalDeliverables.push(item['Typical Deliverables']);
    }
    if (item['Detailed activities']) {
      detailedActivities.push(item['Detailed activities']);
    }
  });

  let tableCol0 = [
    { name: 'WBE', title: 'WBE' },
    { name: 'Task Name', title: 'Task Name' },
    { name: 'Activities', title: 'Activities' },
    { name: 'Detailed activities', title: 'Detailed activities' },
    { name: 'Typical Deliverables', title: 'Typical Deliverables' },
    { name: 'Project_Description', title: 'Description from Project file' },
    { name: 'ABS_from_PM2020', title: 'ABS' },
    { name: 'Matched To', title: 'Matched To' },
    { name: 'Score', title: 'Score' },
    { name: 'ABS upper level element', title: 'ABS upper level element' },
  ];
  if (typicalDeliverables.length === 0 && detailedActivities.length === 0) {
    tableCol0 = tableCol0.filter(
      (item) => item.name !== 'Typical Deliverables' && item.name !== 'Detailed activities'
    );
  }

  const tableCol1 = [
    { name: 'Project_Description', title: 'Description from Project file' },
    { name: 'PM 2020 Text', title: 'PM2020 Text' },
    { name: 'Type', title: 'Type' },
    { name: 'ABS_from_PM2020', title: 'ABS' },
    { name: 'Score', title: 'Score' },
    { name: 'ABS upper level element', title: 'ABS upper level element' },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  const rowData = tableData.filter(
    (item) => item['ABS upper level element'] === dataName && item.Score < 1
  );

  const colData = tab === 0 ? tableCol0 : tableCol1;

  return (
    <Paper>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" className={classes.heading}>
          {dataName}
          <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading ? (
          <Loading />
        ) : (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
            style={{ margin: 0, padding: '20px' }}
          >
            <Grid item lg={12} sm={6} md={12}>
              {rowData.length > 0 && (
                <Box
                  style={{
                    backgroundColor: '#EEF1F9',
                    border: '1px solid #002A45',
                    borderRadius: '5px',
                  }}
                >
                  <ExpressGrid rows={rowData} columns={colData}>
                    {/* <DataTypeProvider
                      for={tableCol}
                      formatterComponent={({ value }) => (
                        <span title={value} style={{ whiteSpace: `${newLine}` }}>
                          {value}
                        </span>
                      )}
                    /> */}
                    <FilteringState />
                    <IntegratedFiltering />
                    <SortingState defaultSorting={[{ columnName: 'id', direction: 'desc' }]} />
                    <IntegratedSorting />
                    <PagingState defaultCurrentPage={0} defaultPageSize={10} />
                    <IntegratedPaging />
                    <Table
                      tableComponent={TableComponent}
                      columnExtensions={[
                        ...colData.map((c, i) => {
                          return {
                            columnName: c.name,
                            width: 'auto',
                            wordWrapEnabled: toggleHeight,
                          };
                        }),
                      ]}
                    />
                    <TableHeaderRow showSortingControls />
                    <TableFilterRow rowComponent={rowComponent} />
                    <TableColumnVisibility
                      hiddenColumnNames={hiddenColumnNames}
                      onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <Toolbar />
                    <ColumnChooser />
                    <ToolBarPlugin name="Download" onClick={startExport} />
                    <ToolBarPlugin
                      name="Height"
                      title="Wrap Text"
                      onClick={() => {
                        setToggleHeight(!toggleHeight);
                      }}
                    />
                    {/* <ToolBarPlugin
                      name="NewLine"
                      onClick={() =>
                        newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine('')
                      }
                    /> */}
                    <PagingPanel pageSizes={pageSizes} />
                  </ExpressGrid>
                  {/* <NavigationGoto
                    setPageSize={setPageSize1}
                    fileLength={rowData.length}
                    pageSize={pageSize1}
                    dis={true}
                    setTemPage={setTemPage1}
                    handlePageNum={handlePageNum1}
                    currentPage={currentPage1}
                    setCurrentPage={setCurrentPage1}
                  /> */}
                  <GridExporter
                    ref={exporterRef}
                    rows={rowData}
                    columns={colData}
                    onSave={onSave}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Dialog>
    </Paper>
  );
};

const TableValue = ({ data, tableData, tab }) => {
  const [open, setOpen] = useState(false);
  const [dataKey, setDatakey] = useState();
  const avgValue = data.map((val) => val.value);
  const sum = avgValue.reduce((total, num) => total + num, 0);
  const average = sum / avgValue.length;

  const openModalTable = (e: { name: any }) => {
    setDatakey(e.name);
    setOpen(true);
  };

  const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${
    y + height
  }
          Z`;
  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} cursor="pointer" />;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          border: '1px solid #ccd6f2',
          borderRadius: '10px',
          //   backgroundColor:'#dae2f8',
          //   padding: '0rem 1rem',
        }}
      >
        <div style={{ flex: 1, borderRight: '1px solid #ccd6f2' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0.5rem',
              backgroundColor: '#EEF1F9',
              borderBottom: '1px solid #ccd6f2',
              borderTopLeftRadius: '10px',
            }}
          >
            <Typography style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
              Acitivities Name
            </Typography>
          </div>
          <ul
            style={{
              listStyle: 'none',
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
              padding: '1rem',
            }}
          >
            {data?.map((data, index) => {
              const { name, value } = data || '';
              if (!name) {
                return (
                  <li key={index}>
                    <Typography style={{ fontSize: '1.4rem' }}>No data</Typography>
                  </li>
                );
              }
              return (
                <div onClick={() => openModalTable({ name: `${name}` })}>
                  <li key={index} style={{ cursor: 'pointer' }}>
                    {' '}
                    <Typography style={{ fontSize: '1.4rem' }}>{name}</Typography>
                  </li>
                </div>
              );
            })}
          </ul>
          <div
            style={{
              display: 'flex',
              padding: '0.5rem',
              borderTop: '1px solid #ccd6f2',
              backgroundColor: '#EEF1F9',
              borderBottomLeftRadius: '10px',
            }}
          >
            <Typography style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>Grand total</Typography>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0.5rem',
              borderBottom: '1px solid #ccd6f2',
              backgroundColor: '#EEF1F9',
              borderTopRightRadius: '10px',
            }}
          >
            <Typography style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
              Match with standard WBS
            </Typography>
          </div>
          <ul
            style={{
              listStyle: 'none',
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
              padding: '1rem',
            }}
          >
            {data?.map((data, index) => {
              const { name, value } = data || '';
              if (!value) {
                return (
                  <li key={index}>
                    <Typography style={{ fontSize: '1.4rem' }}>No data</Typography>
                  </li>
                );
              }
              return (
                <li key={index}>
                  <Typography style={{ fontSize: '1.4rem' }}>
                    {Number(value * 100).toFixed(1)}%
                  </Typography>
                </li>
              );
            })}
          </ul>
          <div
            style={{
              display: 'flex',
              padding: '0.5rem',
              borderTop: '1px solid #ccd6f2',
              backgroundColor: '#EEF1F9',
              borderBottomRightRadius: '10px',
            }}
          >
            <Typography style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
              {Number(average * 100).toFixed(1)}%
            </Typography>
          </div>
        </div>
      </div>
      <br />
      <div
        style={{
          border: '1px solid #ccd6f2',
          borderRadius: '10px',
          paddingBottom: '40px',
        }}
      >
        <div>
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0.5rem',
              fontSize: '1.8rem',
              fontWeight: 'bold',
            }}
          >
            Total
          </Typography>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        >
          <BarChart
            width={data?.length < 4 ? 300 : data?.length * 90}
            height={550}
            data={data.map((item) => {
              return { name: item.name, value: `${Number(item.value * 100).toFixed(1)}` };
            })}
            // layout="vertical"
            margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
          >
            {/* angle={30} */}
            <XAxis
              dataKey="name"
              textAnchor="end"
              sclaeToFit="true"
              verticalAnchor="start"
              interval={0}
              angle="-45"
              stroke="#000"
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Bar
              dataKey="value"
              fill="#8884d8"
              onClick={openModalTable}
              barSize={50}
              shape={<TriangleBar />}
            >
              {data.map((entry, index) => {
                const percentage = parseFloat((entry.value * 100).toFixed(1));
                if (percentage >= 90) {
                  return <Cell key={`cell-${index}`} fill="green" />;
                }
                if (percentage >= 70) {
                  return <Cell key={`cell-${index}`} fill="orange" />;
                }
                return <Cell key={`cell-${index}`} fill="red" />;
              })}
            </Bar>
          </BarChart>
        </div>
      </div>
      <ScheduleDetailTable
        open={open}
        setOpen={setOpen}
        dataName={dataKey}
        tableData={tableData}
        tab={tab}
      />
    </>
  );
};

const ScheduleAnalysisResult = ({ resultData, textData, loadMilestone }) => {
  const [tab, setTab] = useState(0);
  // const [mileStoneData, setMileStoneData] = useState();
  const [loading, setLoading] = useState(false);
  const exporterRef = useRef(null);
  const {
    schedule_vs_wbs,
    wbs_vs_schedule,
    schedule_vs_wbs_consolidation,
    wbs_vs_schedule_consolidation,
    scheduleDates_vs_userDates,
  } = resultData;
  const checkDtTt = JSON.parse(wbs_vs_schedule);
  const typicalDeliverables = [];
  const detailedActivities = [];

  checkDtTt.forEach((item) => {
    if (item['Typical Deliverables']) {
      typicalDeliverables.push(item['Typical Deliverables']);
    }
    if (item['Detailed activities']) {
      detailedActivities.push(item['Detailed activities']);
    }
  });

  let tableCol0 = [
    { name: 'WBE', title: 'WBE' },
    { name: 'Task Name', title: 'Task Name' },
    { name: 'Activities', title: 'Activities' },
    { name: 'Detailed activities', title: 'Detailed activities' },
    { name: 'Typical Deliverables', title: 'Typical Deliverables' },
    { name: 'Project_Description', title: 'Description from Project file' },
    { name: 'ABS_from_PM2020', title: 'ABS' },
    { name: 'Matched To', title: 'Matched To' },
    { name: 'Score', title: 'Score' },
    { name: 'ABS upper level element', title: 'ABS upper level element' },
  ];
  // Remove entries from tableCol0 if both typicalDeliverables and detailedActivities arrays are empty
  if (typicalDeliverables.length === 0 && detailedActivities.length === 0) {
    tableCol0 = tableCol0.filter(
      (item) => item.name !== 'Typical Deliverables' && item.name !== 'Detailed activities'
    );
  }

  const tableCol1 = [
    { name: 'Project_Description', title: 'Description from Project file' },
    { name: 'PM 2020 Text', title: 'PM2020 Text' },
    { name: 'Type', title: 'Type' },
    { name: 'ABS_from_PM2020', title: 'ABS' },
    { name: 'Score', title: 'Score' },
    { name: 'ABS upper level element', title: 'ABS upper level element' },
  ];

  const handleTabValue = (value) => {
    setTab(value);
  };
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  return (
    <div style={{ border: '2px solid #002A45', borderRadius: '14px' }}>
      <div
        style={{
          display: 'flex',
          backgroundColor: '#002A45',
          padding: '0.5rem',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
      >
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
          <div
            style={{
              // width: '400px',
              display: 'flex',
              justifyContent: 'flex-start',
              position: 'relative',
              width: 'max-content',
              backgroundColor: 'white',
              borderRadius: '30px',
              border: '1px solid white',
              marginLeft: '10px',
            }}
          >
            {/* analysis ? { left: '0px' } : { left: '100px' } */}
            <div
              style={{
                left: tabKey[tab],
                top: 0,
                position: 'absolute',
                width: '300px',
                height: '100%',
                background: '#002a45',
                borderRadius: '30px',
                transition: '0.5s',
              }}
              // className="btn2"
            >
              {}
            </div>
            {/* analysis ? { color: 'white' } : {} */}
            <button
              type="button"
              className="toggleBtn2"
              style={{
                // padding: '10px 20px',
                cursor: 'pointer',
                width: '300px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                background: 'transparent',
                transition: 'color 0.5s',
                color: tab === 0 ? 'white' : 'black',
                border: 0,
                outline: 'none',
                position: 'relative',
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setTab(0);
              }}
            >
              Alstom Expectation vs Schedule
            </button>
            <button
              type="button"
              style={{
                // padding: '10px 20px',
                cursor: 'pointer',
                width: '300px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                background: 'transparent',
                transition: 'color 0.5s',
                border: 0,
                color: tab === 1 ? 'white' : 'black',
                outline: 'none',
                position: 'relative',
                textAlign: 'center',
              }}
              // className="toggleBtn2"
              // style={analysis ? {} : { color: 'white' }}
              onClick={() => {
                setTab(1);
              }}
            >
              Schedule vs Alstom Expectation
            </button>
            <button
              type="button"
              style={{
                // padding: '10px 20px',
                cursor: 'pointer',
                width: '300px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                background: 'transparent',
                transition: 'color 0.5s',
                border: 0,
                color: tab === 2 ? 'white' : 'black',
                outline: 'none',
                position: 'relative',
                textAlign: 'center',
              }}
              onClick={async () => {
                setTab(2);
              }}
            >
              Milestone
            </button>
            <button
              type="button"
              style={{
                // padding: '10px 20px',
                cursor: 'pointer',
                width: '200px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                background: 'transparent',
                transition: 'color 0.5s',
                border: 0,
                color: tab === 3 ? 'white' : 'black',
                outline: 'none',
                position: 'relative',
                textAlign: 'center',
              }}
              onClick={async () => {
                setTab(3);
              }}
            >
              Key Dates Comparison
            </button>
          </div>
        </div>
        {(tab === 0 || tab === 1) && (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={startExport}>
              <FileDownloadIcon style={{ color: 'white' }} titleAccess="download" />
            </IconButton>
          </div>
        )}
      </div>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        {tab === 0 && (
          <>
            <TableValue
              data={wbs_vs_schedule_consolidation}
              tableData={JSON.parse(wbs_vs_schedule)}
              tab={0}
            />
            <GridExporter
              ref={exporterRef}
              rows={JSON.parse(wbs_vs_schedule)}
              columns={tableCol0}
              onSave={onSave}
            />
          </>
        )}
        {tab === 1 && (
          <>
            <TableValue
              data={schedule_vs_wbs_consolidation}
              tableData={JSON.parse(schedule_vs_wbs)}
              tab={1}
            />
            <GridExporter
              ref={exporterRef}
              rows={JSON.parse(schedule_vs_wbs)}
              columns={tableCol1}
              onSave={onSave}
            />
          </>
        )}
        {tab === 2 && (
          <>
            <Milestone mileStoneData={textData} loadMilestone={loadMilestone} />
            {loadMilestone ? <Loading /> : ''}
          </>
        )}
        {tab === 3 && (
          <>
            <KeyDatesTable mileStoneData={scheduleDates_vs_userDates} />
            {loadMilestone ? <Loading /> : ''}
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduleAnalysisResult;
