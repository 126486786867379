import React, { memo, useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, InputBase, Typography } from '@mui/material';

const FolderCreationBox = styled((props) => <Box {...props} />)(({ theme, open }) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: 'large',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.7em',
  },
  '&.PopUpvisible': {
    // [panelName]: 0,
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    maxHeight: '100vh',
    maxWidth: '100vw',
    zIndex: 1299,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '& .Modelcontainer': {
      width: '100%',
      height: '100%',
      display: open ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  '&.PopUpinVisible': {
    display: 'none',
  },
}));

const ModelPopUp = ({ children, open, setOpen }) => {
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { setOpen, open })
  );
  return (
    <>
      <FolderCreationBox className={open ? 'PopUpvisible' : 'PopUpinVisible'} open={open}>
        <Box className="Modelcontainer">{childrenWithProps}</Box>
      </FolderCreationBox>
    </>
  );
};

export default memo(ModelPopUp);
