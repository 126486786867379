import { Grid, StepConnector, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { Workload3Create } from 'WorkloadType';
import { getAllImportedBreakdown, getCaptureName } from '../../api/breakdowns';
import { workloadGetById } from '../../api/workloads';
import useAppToken from '../../app/useAppToken';
import icaptureLogo from '../../assets/m1_blue.png';
import Loading from '../../components/Core/Loading';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import UploadFile from '../../components/Core/UploadFile';
import UploadFileRunContainer from '../../components/Core/UploadFileRunContainer';
import { FILES_BY_MODULES, IS_DEBUG_MODE } from '../../const';
import formatBytes from '../../tools/formatBytes';
import { Resource } from '../Module2/RunContext';
import StepCapture from './components/RunStepper/StepCapture';
import StepInputFiles from './components/RunStepper/StepInputFiles';
import StepReview from './components/RunStepper/StepReview';
import StepSettings from './components/RunStepper/StepSettings';
import Module1Context from './Module1Context';
import Run1Context from './RunContext';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#DC3223',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#DC3223',
    },
  },
  line: {
    borderColor: 'gray',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    buttonContainer: {
      alignItems: 'flex-end',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    wholeBorder: {
      border: '2px solid #002A45',
      borderRadius: 4,
    },
  })
);

type Props = {
  step?: number;
};

function RunContainer({ step }: Props) {
  const classes = useStyles();
  const { appJWT } = useAppToken();
  const { projectId, workloadId } = useParams() as any;
  const { project, setProject } = useContext(Module1Context);
  const runContext = useContext(Run1Context);
  const { resources, setResources, setWorkload, toggleCapture } = runContext;
  const [files, setFiles] = useState<any[]>([]);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [activeStep, setActiveStep] = useState(step || 0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const { setSnack } = useContext(Module1Context);
  const [loading, setLoading] = useState(false);

  const steps = ['Select input files', 'Settings', 'iCapture', 'Review'];

  // on startup
  useEffect(() => {
    const { folders } = project;
    // const dis_nonpm = project?.modelpm === 'dis_nonpm2020' ? 'dis_nonpm2020' : undefined;
    // getAllImportedBreakdown(projectId, dis_nonpm).then((payload) => {
    //   setProject({ ...project, groupsImport: payload });
    // });
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
      }
    });
    // setLoading(false);
    if (workloadId) {
      setLoading(true);

      workloadGetById(workloadId).then((workload: Workload3Create) => {
        if (setWorkload) setWorkload(workload);
        const { resources, results_data, app } = workload;
        // check app TODO
        if (app !== 'module1') return;

        setResources(
          // attach results to each resource
          resources.map((res: Resource) => {
            const result = results_data.find((resultData: any) => resultData.id === res.filename);
            result.data.output.Text = undefined;
            return {
              ...res,
              results: result.data.output,
            };
          })
        );
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
    }
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            allResources.push({
              ...r,
              size: formatBytes(Number(r.size), 0),
              id: r.etag,
              folderName: eachFolder.name,
              folderId: eachFolder.id,
            });
          }
      }
    }
    setFiles(allResources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getStepContent = (step: number): React.ReactNode => {
    switch (step) {
      case 0:
        return (
          <Box
            style={{
              border: '1px solid rgb(0, 42, 69)',
              // borderRight: '1px solid rgb(0, 42, 69)',
              // borderLeft: '1px solid rgb(0, 42, 69)',
              backgroundColor: '#EEF1F9',
              marginTop: -20,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
              <UploadFileRunContainer
                files={files}
                setFiles={setFiles}
                projectId={projectId}
                // style={{ backgroundColor: 'none' }}
              />
            </div>
            <StepInputFiles
              files={files}
              selected={resources.map((file) =>
                files.findIndex((f: any) => f.filename === file.filename)
              )}
              filterFiles={FILES_BY_MODULES.module1}
            />
          </Box>
        );
      case 1:
        return (
          <>
            <StepSettings />
          </>
        );
      case 2:
        return (
          <>
            <StepCapture onFinish={handleNext} />
          </>
        );
      case 3:
        return (
          <>
            {loading === true ? (
              <Loading />
            ) : (
              <>
                <StepReview />
              </>
            )}
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    // eslint-disable-next-line eqeqeq
    const foundValue: any = project?.users?.find((obj) => obj.user_id == appJWT.user_id);
    if (foundValue?.role_id === 3) {
      setSnack('You are not allowed as you are a performer', 'warning');
      return;
    }
    // validation for step settings == 1
    if (activeStep === 1) {
      const err = [];
      const errName = [];
      for (const res of resources) {
        // test empty name of capture
        if (!res.settings?.nameOfCapture || res.settings?.nameOfCapture?.trim() === '') {
          err.push(res?.filename);
        }
        if (
          (res?.scope?.FileType.toLowerCase() === 'excel' ||
            res?.scope?.FileType.toLowerCase() === 'csv') &&
          !res?.settings?.targetColumn
        ) {
          setSnack('Please select target column', 'warning', 4000);
          return;
        }
        if (res.settings?.captureAll === false) {
          if (res?.settings?.startPage === '') {
            setSnack('Please enter the start page in numbers', 'warning', 4000);

            return;
          }

          if (res?.settings?.endPage === '') {
            setSnack('Please enter the end page in numbers', 'warning', 4000);

            return;
          }
        }
        if (res.settings?.nameOfCapture || !res.settings?.nameOfCapture === '') {
          // test valid name
          if (captureNames.includes(res.settings?.nameOfCapture)) {
            setSnack('That capture name is taken.Please try another', 'warning', 4000);
            return;
          }
          if (res.settings?.nameOfCapture.search(/[^a-z0-9_-]/gi) !== -1) {
            errName.push(res.settings?.nameOfCapture);
          }
          if (res.settings?.nameOfCapture.search(/^[a-z]/i) === -1) {
            errName.push(res.settings?.nameOfCapture);
          }
        }
      }
      if (err.length) {
        if (resources[0].settings.fastm1 === 'no' || resources[0]?.scope?.FileType === 'word') {
          setSnack(
            <>
              <div>You must provide a name of capture for these files</div>{' '}
              {err.map((e, k) => (
                <li key={k}>{e}</li>
              ))}
            </>,
            'warning',
            4000
          );
          return;
        }
      }
      if (errName.length) {
        setSnack(
          <>
            <div>Capture names are invalid</div>{' '}
            {errName.map((e, k) => (
              <>
                <li key={k} style={{ color: 'red' }}>
                  {e}
                </li>
                <li key={k}>Capture name can be maximum 20 characters long</li>
                <li key={k}>Only characters [A-Z, a-z, 0-9, -, _] are allowed</li>
                <li key={k}>Capture name must start with a letter</li>
              </>
            ))}
          </>,
          'error',
          4000
        );

        return;
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClickStep = (index: number) => {
    if (resources.length === 0) return;
    if (
      index >= 2
      // TODO: test & scope settings
    ) {
      handleNext();
    }
    setActiveStep(index);
  };

  return (
    <>
      {/* <div style={{ backgroundColor: '#EEF1F9', padding: '0.4rem' }}>
        <ProjectBreadCrumbs
          projectId={projectId}
          nodes={[
            {
              label: 'Smart Requirement Magagement',
              to: `#`,
              icon: (
                <img
                  src={icaptureLogo}
                  alt="Smart Requirement Magagement"
                  title="Smart Requirement Magagement"
                />
              ),
            },
          ]}
        />
      </div> */}
      <Grid container>
        {IS_DEBUG_MODE && <ReactJson src={{ runContext }} collapsed={true} theme="monokai" />}
        {/* <Grid item xs={12}>
          <ProjectBreadCrumbs
            projectId={projectId}
            nodes={[
              {
                label: 'Smart Requirement Magagement',
                to: `#`,
                icon: (
                  <img
                    src={icaptureLogo}
                    alt="Smart Requirement Magagement"
                    title="Smart Requirement Magagement"
                  />
                ),
              },
            ]}
          />
        </Grid> */}
      </Grid>
      <Grid container className={classes.wholeBorder}>
        <Grid item xs={12}>
          <Grid>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                  StepIconComponent?: React.ElementType;
                } = {};
                return (
                  <Step key={label} {...stepProps} style={{ width: '100%' }}>
                    <StepLabel
                      {...labelProps}
                      onClick={() => handleClickStep(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          {/* Footer navigation */}
        </Grid>
        <Grid item xs={12}>
          {/* stepper content */}
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '2rem' }}>
            <div>{getStepContent(activeStep)}</div>
            <div
              style={{
                position: 'sticky',
                bottom: 0,
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="baseline"
                className={classes.buttonContainer}
              >
                <Grid item>
                  {activeStep === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      // className={buttonClasses.buttonPrim}
                      disabled={resources.length === 0}
                    >
                      Settings
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="baseline"
                className={classes.buttonContainer}
              >
                <Grid item style={{ display: 'flex', gap: '1rem' }}>
                  {activeStep === 1 && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                        // className={buttonClasses.buttonSec}
                        style={{ width: '6rem' }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        // className={buttonClasses.buttonPrim}
                        disabled={resources.length === 0 || toggleCapture === false}
                        style={{ width: '6rem' }}
                      >
                        Capture
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </Box>

          {/* stepper buttons */}
        </Grid>
      </Grid>
    </>
  );
}

export default RunContainer;
