/**
 * This component renders of a contentSearch comp of the page
 * added new attributes of Pie and bar Graph UI changes done by @Ranjan @Rahul
 * Css also fixed here
 * Ui changes done by @Ranjan @Rahul
 * @param {string} props
 * @returns {ReactNode} A React element that renders contentSearch component of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import Highlighter from 'react-highlight-words';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { useParams } from 'react-router-dom';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Bar,
  Sector,
} from 'recharts';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  CardHeader,
  IconButton,
  Button,
} from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SummarizeIcon from '@mui/icons-material/Summarize';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@material-ui/icons/MoreVert'; // Example icon
import FullscreenIcon from '@material-ui/icons/Fullscreen'; // Import Fullscreen icon
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { resultStyle } from '../../../components/Filter/textAnalysis';
import IsearcherStyle from '../../../components/Layout/IsearcherStyle';
import { generateRexSummary, translateTextApi } from '../../../api/module2';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  toolbarComponent,
  tableHeaderComponent,
} from '../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import useAppToken from '../../../app/useAppToken';
// import { AppJwtType } from '../../../app/AppJwt';
import NavigationGoto from '../../../components/Core/NavigationGoto';

// import Remind from '../../coredata/Remind';
// import Module1Context from '../../../../Module1/Module1Context';
// import { SummaryCard } from '../../REXAnalysis/ChildCompnents/RexChild';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';
import Module1Context from '../../Module1/Module1Context';

const columns: any = [
  { name: 'artifactText', title: 'Text' },
  // { name: 'productLine', title: 'Entity' },
  { name: 'productLine', title: 'ProductLine' },
  { name: 'platform', title: 'Platform' },
  { name: 'solution', title: 'Solution' },
  { name: 'product', title: 'Product' },
  { name: 'project', title: 'Project' },
  { name: 'country', title: 'Country' },
  { name: 'ctCode', title: 'CT Code' },
  { name: 'documentProfile', title: 'Doc Profile' },
  { name: 'doorsArtifactAllocation', title: 'Allocation' },
  { name: 'documentTitle', title: 'Doc Title' },
  { name: 'documentPath', title: 'Doc Path' },
  { name: 'documentVersion', title: 'Doc Version' },
  { name: 'documentABSActivity', title: 'Doc ABS' },
  { name: 'documentOBSOwner', title: 'Doc OBS' },
  { name: 'artifactType', title: 'Artifact Type' },
  { name: 'lastModifiedBy', title: 'Last Modified By' },
  { name: 'lastModifiedOn', title: 'Last Modified On' },
  { name: 'url', title: 'URL' },
  { name: 'moduleLastModifiedBy', title: 'Module Last Modified By' },
  { name: 'moduleLastModifiedOn', title: 'Module Last Modified On' },
  { name: 'moduleUrl', title: 'Module Url' },
  { name: 'createdBy', title: 'Created By' },
  { name: 'createdOn', title: 'Created On' },
  { name: 'moduleCreatedBy', title: 'Module Created By' },
  { name: 'moduleCreatedOn', title: 'Module Created On' },
  { name: 'compliance', title: 'Compliance' },
  { name: 'artifactLifeCycle', title: 'Artifact Life Cycle' },
  { name: 'artifactActivity', title: 'Artifact Activity' },
];

const temp = [
  {
    lang: 'Arabic-AR',
    value: 'Arabic',
    voice_cd: 'ar-DZ',
  },
  {
    lang: 'English-EN',
    value: 'English',
    voice_cd: 'en-GB',
  },
  {
    lang: 'French-FR',
    value: 'French',
    voice_cd: 'fr-FR',
  },
  {
    lang: 'German-DE',
    value: 'German',
    voice_cd: 'de-DE',
  },
  {
    lang: 'Hindi-HI',
    value: 'Hindi',
    voice_cd: 'hi-IN',
  },
  {
    lang: 'Italian-IT',
    value: 'Italian',
    voice_cd: 'it-IT',
  },
  {
    lang: 'Spanish-ES',
    value: 'Spanish',
    voice_cd: 'es-ES',
  },
];

const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];
const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${
  y + height
}
          Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const highlightConfig = {
  VALIDATED: {
    backgroundColor: '#8BC34A',
  },
  SUBMITTED: {
    backgroundColor: '#673AB7',
  },
  ANALYZED: {
    backgroundColor: '#4CAF50',
  },
  CAPITALIZED: {
    backgroundColor: '#2196F3',
  },
  CLOSED: {
    backgroundColor: '#9E9E9E',
  },
  'DISCARDED IN METIER': {
    backgroundColor: '#FFC107',
  },
  DRAFT: {
    backgroundColor: '#FF9800',
  },
  DUPLICATED: {
    backgroundColor: '#607D8B',
  },
  Draft: {
    backgroundColor: '#FF9800',
  },
  'Not defined': {
    backgroundColor: '#9E9E9E',
  },
  OBSOLETED: {
    backgroundColor: '#FF5722',
  },
  REJECTED: {
    backgroundColor: '#F44336',
  },
  'TO BE DELETED': {
    backgroundColor: '#E91E63',
  },
  'D&IS': {
    backgroundColor: '#002a45',
  },
  'Digital & Integrated Systems': {
    backgroundColor: '#002a45',
  },
  DIS: {
    backgroundColor: '#002a45',
  },
  RSC: {
    backgroundColor: '#3498DB',
  },
  'Rolling Stock & Components': {
    backgroundColor: '#3498DB',
  },
  Services: {
    backgroundColor: '#E91E63',
  },
  'Not Defined': {
    backgroundColor: '#95A5A6',
  },
  'Not Available': {
    backgroundColor: '#95A5A6',
  },
};

const SummaryCard = ({ name, title, text, action, context, problem, showCashIcon, last }: any) => {
  return (
    <Card style={{ flex: '1', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
      <CardContent>
        <div
          style={{
            borderRadius: '5px',
            backgroundColor: !showCashIcon ? '#002A45 ' : '#e8b923',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              marginBottom: 16,
              gap: 5,
            }}
          >
            <Tooltip title={title}>
              <div
                style={{
                  flexBasis: !showCashIcon ? '100%' : '90%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SummarizeIcon style={{ color: !showCashIcon ? '#FFFFFF' : '#002A45' }} />
                <Typography
                  variant="h5"
                  component="div"
                  style={{ color: !showCashIcon ? '#FFFFFF' : '#002A45' }}
                >
                  {title.length > last ? `${title.slice(0, last)}...` : title} {name}
                </Typography>
              </div>
            </Tooltip>
            {showCashIcon && (
              <div
                style={{
                  flexBasis: '10%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: '5px',
                }}
              >
                {' '}
                <img src={money} height="30" width="30" alt="money" />{' '}
              </div>
            )}
          </div>
        </div>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            color: '#002A45',
          }}
        >
          <>
            {problem && (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>Problem:</Typography>
                <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{problem}</Typography>
              </>
            )}

            {context && (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>Context :</Typography>
                <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{context}</Typography>
              </>
            )}

            {context || problem ? (
              <>
                <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>
                  Key Takeaways :
                </Typography>
                <Typography style={{ textAlign: 'justify', fontSize: 15 }}>{action}</Typography>
              </>
            ) : (
              <ol style={{ fontSize: 15, fontWeight: 'bolds', margin: 0 }}>
                {text?.split('\n').map((data, index) => {
                  const t = data?.split(' ')?.slice(1, data.split(' ')?.length).join(' ');
                  return (
                    <li key={index}>
                      <Typography style={{ fontSize: 15, fontWeight: 'normal' }}>{t}</Typography>
                    </li>
                  );
                })}
              </ol>
            )}
          </>
        </Box>
      </CardContent>
    </Card>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const COLORS_ENTITY = ['#DC3223', '#19AA6E'];

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

function ContentSearchResult({
  label,
  rowResult,
  alstomEntityWiseDistribution,
  platformWiseDistribution,
  projectWiseDistribution,
  documentProfileWiseDistribution,
  artifactWiseDistribution,
  solutionWiseDistribution,
  countryWiseDistribution,
  productWiseDistribution,
  timeTaken,
  totalResult,
  inputValue,
  contentSearchPayload,
}) {
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    'doorsArtifactAllocation',
    // 'documentTitle',
    'documentVersion',
    'documentABSActivity',
    'documentOBSOwner',
    'documentPath',
    'artifactType',
    'lastModifiedBy',
    'lastModifiedOn',
    'url',
    'moduleLastModifiedOn',
    'moduleLastModifiedBy',
    'moduleUrl',
    'createdBy',
    'createdOn',
    'moduleCreatedBy',
    'moduleCreatedOn',
    'compliance',
    'artifactLifeCycle',
    'artifactActivity',
  ]);
  const { terms } = contentSearchPayload?.alstopedia || {};
  const [graphType, setGraphType] = React.useState<any>('bar');
  const [remind, setRemind] = useState(false);
  const [remidtext, setRemidText] = useState('');
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const classes = IsearcherStyle();
  const pageSizes = [5, 10, 20, 50];
  const classes2 = resultStyle();
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };
  const [toggleHeight, setToggleHeight] = useState(false);
  const exporterRef = useRef(null);
  const { projectId } = useParams() as any;
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const [activeIndex1, setActivate1] = useState(0);
  const [activeIndex2, setActivate2] = useState(0);
  const [activeIndex3, setActivate3] = useState(0);
  const [activeIndex4, setActivate4] = useState(0);
  const [activeIndex5, setActivate5] = useState(0);
  const [activeIndex6, setActivate6] = useState(0);
  const [activeIndex7, setActivate7] = useState(0);
  const [activeIndex8, setActivate8] = useState(0);
  const [speak, setSpeak] = useState(false);
  const [code, setCode] = useState('en-GB');
  const [originalText, setOriginalText] = useState('');
  const { appJWT } = useAppToken();
  const colorCode = [
    '#07d981',
    '#C3DDF6',
    '#50d39f',
    '#ebe6dc',
    '#c3b9a0',
    '#C3DDF6',
    '#468AD1',
    '#F8A600',
    '#2f475f',
  ];

  const onPieEnter1 = (_, index) => {
    setActivate1(index);
  };
  const onPieEnter2 = (_, index) => {
    setActivate2(index);
  };
  const onPieEnter3 = (_, index) => {
    setActivate3(index);
  };
  const onPieEnter4 = (_, index) => {
    setActivate4(index);
  };
  const onPieEnter5 = (_, index) => {
    setActivate5(index);
  };
  const onPieEnter6 = (_, index) => {
    setActivate6(index);
  };
  const onPieEnter7 = (_, index) => {
    setActivate7(index);
  };
  const onPieEnter8 = (_, index) => {
    setActivate8(index);
  };

  // states for voice speak
  const voices = window.speechSynthesis.getVoices();
  const utterance = new SpeechSynthesisUtterance();
  const [voiceLang, setVoiceLang] = React.useState('English-EN');
  const [definition, setDefinition] = React.useState('');
  const [wordPronunciation, setWordPronunciation] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [selection, setSelection] = useState([]);
  const [summaryText, setSummaryText] = useState('');
  const [loading, setLoading] = useState(false);
  const { setSnack } = useContext(Module1Context);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setVoiceLang(event.target.value);
    speechSynthesis.cancel();
    const fil = temp.filter((data) => {
      return data.lang === event.target.value;
    });

    if (fil[0].voice_cd !== undefined) {
      setCode(fil[0].voice_cd);
    }
    const body = {
      query: originalText,
      fromLanguage: '',
      toLanguage: event.target.value,
      deepL: 'no',
    };
    const user_id = Number(appJWT?.user_id);
    const url = `/user_translator/${user_id}`;
    translateTextApi(body, url).then((results) => {
      setDefinition(results?.output[1]);
    });
  };

  useEffect(() => {
    if (contentSearchPayload?.alstopedia[terms[0]]?.definition[0] !== undefined) {
      setOriginalText(contentSearchPayload?.alstopedia[terms[0]]?.definition[0]);
      setDefinition(contentSearchPayload.alstopedia[terms[0]]?.definition[0]);
      setWordPronunciation(terms[0]);
    }
    if (sessionStorage.getItem('contentSearchCol') !== null) {
      setHiddenColumnNames(sessionStorage.getItem('contentSearchCol')?.split(','));
    }
  }, []);

  useEffect(() => {
    return () => {
      speechSynthesis.cancel();
    };
  });

  useEffect(() => {
    sessionStorage.setItem('contentSearchCol', hiddenColumnNames);
  }, [hiddenColumnNames]);

  useEffect(() => {
    if (pageSize > rowResult.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rowResult.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const speakVoice = () => {
    // const temp = voices.filter((data) => {
    //   return data.voiceURI === code;
    // });
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      return;
    }
    let t = 1;
    for (let i = 0; i < voices.length; i += 1) {
      if (voices[i].lang === code) {
        t = i;
        break;
      }
    }
    utterance.voice = voices[t];
    setTimeout(() => {
      utterance.text = definition;
      speechSynthesis.speak(utterance);
    }, 2000);
    utterance.text = wordPronunciation;
    speechSynthesis.speak(utterance);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (
    { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }: any,
    full = false
  ) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const displayLabel = () => {
      if (full) return <>{percent !== 0 ? `${`${name} - ${(percent * 100).toFixed(0)}%`}` : ''}</>;
      return <>{percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''}</>;
    };
    return (
      <text
        transform={`translate(${x},${y}) rotate(320)`} // 330
        fill="black"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize="9px"
        fontStretch="collapsed"
        fontFamily="sans-serif, Helvetica Neue, Lucida Grande, Arial"
      >
        {/* {`${(percent * 100).toFixed(0)}%`} */}
        {/* to remove 0% from graph display */}
        {percent !== 0 ? `${`${name.substring(0, 5)} ${(percent * 100).toFixed(3)}%`}` : ''}
        {/* {percent !== 0 ? `${`${(percent * 100).toFixed(3)}%`}` : ''} */}
        {/* by David {displayLabel()} */}
      </text>
    );
  };

  const renderCustomizedLabelFull = (params: any) => {
    return renderCustomizedLabel(params, true);
  };

  const GenerateSummary = () => {
    if (selection.length > 5) {
      setSnack('Summary can be generated for less least Five rows', 'warning');
      return;
    }
    let text = '' as any;
    // Description + Proposed Action + Mitigation Action + Analysis + Capitalization Evidence
    if (selection?.length > 0) {
      setLoading(true);
      selection?.map((data: any) => {
        text += String(rowResult[data]?.artifactText);
        return data;
      });

      const temp = encodeURI(text);
      generateRexSummary(temp).then((data) => {
        setSummaryText(data);
        setLoading(false);
      });
    } else {
      setSnack('please select row', 'warning');
    }
  };

  const handleChangeGraph = (event, passedData) => {
    setGraphType(passedData);
    if (passedData === '') {
      setGraphType('bar');
    }
  };

  const renderTextHighlighter = ({ row, value }) => {
    return (
      <div
        style={{
          fontWeight: 'bold',
          color: 'white',
          width: [
            'DIS',
            'D&IS',
            'RSC',
            'Services',
            'Not Defined',
            'TK',
            'Rolling Stock & Components',
            'Digital & Integrated Systems',
            'Not Available',
          ].includes(value)
            ? '13rem'
            : '8rem',
          textAlign: 'center',
          padding: '0.5rem',
          margin: '0.1rem',
          borderRadius: '5px',
          backgroundColor: highlightConfig[value]?.backgroundColor || 'black',
        }}
      >
        {value}
      </div>
    );
  };

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'iSearcher_Report.xlsx');
    });
    setRemind(true);
    setRemidText('Downloaded');
  };

  const renderModuleUrl = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${value}`;
    document.body.appendChild(link);
    return (
      <>
        <Button
          onClick={() => {
            link.click();
          }}
          style={{ textDecoration: 'none', textDecorationLine: 'underline' }}
          title={value}
        >
          {value}
        </Button>
      </>
    );
  };

  const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${value}`;
    document.body.appendChild(link);
    return (
      <>
        <Button
          onClick={() => {
            link.click();
          }}
          style={{ textDecoration: 'none', textDecorationLine: 'underline' }}
          title={value}
        >
          {value}
        </Button>
      </>
    );
  };

  function urlSearch(a: any) {
    // console.log(a);
  }
  // const customizeCell = (cell, row, column) => {
  //   const s = cell;
  //   // s.font = { size: 10, color: { argb: 'AAAAAA' } };
  // };

  // const customizeHeader = (worksheet) => {
  //   const w = worksheet;
  //   for (let i = 0; i < columns.length; i += 1) {
  //     const t = i + 1;
  //     w.getRow(1).getCell(t).value = columns[i].title;
  //     w.getRow(1).getCell(t).font = { bold: true, size: 16 };
  //   }
  //   console.log(columns);
  // };
  const handleFullScreen1 = useFullScreenHandle(); // For the first chart
  const handleFullScreen2 = useFullScreenHandle(); // For the second chart
  const handleFullScreen3 = useFullScreenHandle(); // For the third chart
  const handleFullScreen4 = useFullScreenHandle(); // For the first chart
  const handleFullScreen5 = useFullScreenHandle(); // For the second chart
  const handleFullScreen6 = useFullScreenHandle(); // For the third chart
  const fullScreenStyle = {
    backgroundColor: '#fff', // Match the background color of your CardContent
    padding: '20px', // Add padding if needed
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    placeContent: 'center',
    height: '100vh', // Fullscreen height
    width: '100vw', // Fullscreen width
    boxSizing: 'border-box',
    overflowX: 'scroll',
  };

  return (
    <>
      {/* {remind && <Remind open={remind} text={remidtext} setRemind={setRemind} />} */}

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        {definition && !label && (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                marginBottom: '0.5rem',
                width: '80%',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h4">Alstopedia</Typography>
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                {terms.map((data, index) => {
                  return (
                    <Box>
                      <Button
                        style={{
                          backgroundColor: colorCode[index],
                          padding: '0.3rem 0.4rem',
                        }}
                        onClick={() => {
                          setVoiceLang('English-EN');
                          setCode('en-GB');
                          setShowMore(false);
                          setWordPronunciation(data);
                          setOriginalText(contentSearchPayload.alstopedia[data].definition[0]);
                          setDefinition(contentSearchPayload.alstopedia[data].definition[0]);
                          speechSynthesis.cancel();
                        }}
                      >
                        {data}
                      </Button>
                    </Box>
                  );
                })}
              </div>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <div
                  style={{
                    flexBasis: '85%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  <Typography>Definition</Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'flex-start',
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // setSpeak((prev) => !prev);
                          speakVoice();
                        }}
                        size="small"
                      >
                        <VolumeUpIcon style={{ color: '#0764fa' }} fontSize="large" />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.3rem',
                      }}
                    >
                      {definition.length > 200 && (
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1.2rem', textAlign: 'justify' }}
                        >
                          {showMore ? definition : `${definition.slice(0, 200)}...`}
                          {!showMore && (
                            <IconButton size="small" onClick={toggleShowMore}>
                              <ExpandMoreIcon />
                            </IconButton>
                          )}
                          {showMore && (
                            <IconButton size="small" onClick={toggleShowMore}>
                              <KeyboardArrowUpIcon />
                            </IconButton>
                          )}
                        </Typography>
                      )}
                      {definition.length <= 200 && (
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1.2rem', textAlign: 'justify' }}
                        >
                          {definition}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ flexBasis: '15%' }}>
                  <FormControl style={{ width: '100%', height: '1rem' }}>
                    <InputLabel size="small">translate</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={voiceLang}
                      label="translate"
                      size="small"
                      onChange={handleChangeLanguage}
                    >
                      {temp.map((data) => (
                        <MenuItem value={data.lang}>{data.value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </Box>
        )}
        {rowResult.length > 0 && (
          <>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              <span>
                <b>{totalResult}</b> results found
              </span>
              {/* <span style={{ marginLeft: '10px' }}>({timeTaken} seconds)</span> */}
            </Typography>
            <Box
              style={{
                backgroundColor: '#EEF1F9',
                border: '1px solid #002A45',
                borderRadius: '20px',
              }}
            >
              <ExpressGrid rows={rowResult} columns={columns}>
                <DataTypeProvider
                  for={[
                    'productLine',
                    'artifactText',
                    'platform',
                    'project',
                    'ctCode',
                    'documentProfile',
                    'artifactType',
                    'doorsArtifactAllocation',
                    'documentTitle',
                    'documentVersion',
                    'documentABSActivity',
                    'documentOBSOwner',
                    'documentPath',
                    'lastModifiedBy',
                    'lastModifiedOn',
                    'url',
                    'productLine',
                    'product',
                    'solution',
                    'country',
                    'artifactType',
                    'compliance',
                    'artifactLifeCycle',
                    'artifactActivity',
                  ]}
                  formatterComponent={({ value }) => <span title={value}>{value}</span>}
                />
                <DataTypeProvider for={['url']} formatterComponent={renderText} />
                <DataTypeProvider for={['moduleUrl']} formatterComponent={renderModuleUrl} />
                {label === 'rex360' ? (
                  <DataTypeProvider
                    for={['productLine']}
                    formatterComponent={renderTextHighlighter}
                  />
                ) : null}
                <FilteringState defaultFilters={[{ columnName: 'productLine', value: '' }]} />
                <IntegratedFiltering />
                <SortingState />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <IntegratedSorting />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <Table
                  // tableComponent={TableComponent}
                  tableComponent={MultiAllocTableComp}
                  containerComponent={tableAllocatorComponent}
                  headComponent={tableHeaderComponent}
                  columnExtensions={[
                    { columnName: 'productLine', width: '200px' },
                    { columnName: 'platform', width: '200px' },
                    //   { columnName: 'Score', width: '100px' },
                    {
                      columnName: 'artifactText',
                      width: '300px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'documentPath',
                      width: '300px',
                      wordWrapEnabled: toggleHeight,
                    },
                    {
                      columnName: 'url',
                      width: '500px',
                      wordWrapEnabled: toggleHeight,
                    },
                  ]}
                />
                <TableColumnResizing
                  defaultColumnWidths={[
                    { columnName: 'Document_Title', width: 400 },
                    { columnName: 'artifactText', width: 300 },
                    { columnName: 'productLine', width: 250 },
                    { columnName: 'product', width: 100 },
                    { columnName: 'solution', width: 100 },
                    { columnName: 'country', width: 100 },
                    { columnName: 'platform', width: 100 },
                    { columnName: 'project', width: 200 },
                    { columnName: 'ctCode', width: 200 },
                    { columnName: 'documentProfile', width: 150 },
                    { columnName: 'artifactType', width: 200 },
                    { columnName: 'doorsArtifactAllocation', width: 200 },
                    { columnName: 'documentTitle', width: 200 },
                    { columnName: 'documentVersion', width: 100 },
                    { columnName: 'documentABSActivity', width: 200 },
                    { columnName: 'documentOBSOwner', width: 200 },
                    { columnName: 'documentPath', width: 200 },
                    { columnName: 'lastModifiedBy', width: 200 },
                    { columnName: 'lastModifiedOn', width: 200 },
                    { columnName: 'url', width: 500 },
                    { columnName: 'moduleLastModifiedOn', width: 200 },
                    { columnName: 'moduleLastModifiedBy', width: 200 },
                    { columnName: 'moduleUrl', width: 500 },
                    { columnName: 'createdBy', width: 200 },
                    { columnName: 'createdOn', width: 200 },
                    { columnName: 'moduleCreatedBy', width: 200 },
                    { columnName: 'moduleCreatedOn', width: 200 },
                    { columnName: 'compliance', width: 200 },
                    { columnName: 'artifactLifeCycle', width: 200 },
                    { columnName: 'artifactActivity', width: 200 },
                  ]}
                />
                {/* table header bold <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} /> */}
                <TableHeaderRow showSortingControls />
                {/* {label === 'rex360' && <TableSelection />} */}
                <TableFilterRow rowComponent={rowComponent} />
                <TableColumnVisibility
                  hiddenColumnNames={hiddenColumnNames}
                  onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                <Toolbar rootComponent={toolbarComponent} />
                {/* {label === 'rex360' && (
                  <ToolBarPlugin name="Generate summary" onClick={GenerateSummary} />
                )} */}
                <ColumnChooser />
                <ToolBarPlugin name="Download" onClick={startExport} />
                <ToolBarPlugin
                  name="Height"
                  title="Wrap Text"
                  onClick={() => setToggleHeight(!toggleHeight)}
                />
                {/* <PagingPanel pageSizes={pageSizes} /> */}
                <PagingPanel
                  pageSizes={pageSizes}
                  containerComponent={(props) => (
                    <>
                      <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                    </>
                  )}
                />
              </ExpressGrid>
              <GridExporter
                ref={exporterRef}
                rows={rowResult}
                columns={columns}
                onSave={onSave}
                // customizeCell={customizeCell}
                // customizeHeader={customizeHeader}
              />
              <NavigationGotoNew
                setPageSize={setPageSize}
                fileLength={rowResult.length}
                pageSize={pageSize}
                dis={dis}
                setTemPage={setTemPage}
                handlePageNum={handlePageNum}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Box>
          </>
        )}
        {loading && (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
        {summaryText && !loading && <SummaryCard name="Summary" text={summaryText} />}
        {alstomEntityWiseDistribution.length > 0 ||
        platformWiseDistribution.length > 0 ||
        solutionWiseDistribution.length > 0 ||
        productWiseDistribution.length > 0 ||
        countryWiseDistribution.length > 0 ||
        projectWiseDistribution.length > 0 ||
        documentProfileWiseDistribution.length > 0 ? (
          <>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ToggleButtonGroup
                size="small"
                value={graphType}
                exclusive
                onChange={handleChangeGraph}
                className={classes.toggle}
              >
                <ToggleButton value="bar">
                  <span>
                    <b style={{ textTransform: 'none' }}>BAR GRAPH</b>
                  </span>
                </ToggleButton>
                <ToggleButton value="pie">
                  <span>
                    <b style={{ textTransform: 'none' }}>PIE GRAPH</b>
                  </span>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {graphType === 'pie' && (
              <Box className={classes2.ToggleResultContainer}>
                <Box className={classes2.ToggleResBox}>
                  {alstomEntityWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader title="ProductLine Wise Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart width={600} height={400} className={classes2.pieChart}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex1}
                            data={alstomEntityWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter1}
                          >
                            {alstomEntityWiseDistribution.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS_ENTITY[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                  {platformWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader title="Platform Wise Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart width={600} height={400} className={classes2.pieChart}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex2}
                            data={platformWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter2}
                          >
                            {platformWiseDistribution.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                </Box>
                <Box className={classes2.ToggleResBox}>
                  {solutionWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader title="Solution Wise Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart width={600} height={400} className={classes2.pieChart}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex6}
                            data={solutionWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter6}
                          >
                            {solutionWiseDistribution.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                  {productWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader title="Product Wise Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart width={600} height={400} className={classes2.pieChart}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex7}
                            data={productWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter7}
                          >
                            {productWiseDistribution.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                </Box>
                <Box className={classes2.ToggleResBox}>
                  {projectWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader title="Project Wise Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart className={classes2.pieChart} width={600} height={400}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex3}
                            data={projectWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter3}
                          >
                            {projectWiseDistribution.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                  {documentProfileWiseDistribution.length > 0 && (
                    <Card Card className={classes2.cardRoot}>
                      <CardHeader title="Document Profile Wise Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart className={classes2.pieChart} width={600} height={400}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex4}
                            data={documentProfileWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter4}
                          >
                            {documentProfileWiseDistribution.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                </Box>
                {/* <Box>
                  {countryWiseDistribution.length > 0 && (
                    <Card Card className={classes2.cardRoot2}>
                      <CardHeader title="Country Level Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart className={classes2.pieChart} width={600} height={400}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex8}
                            data={countryWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter8}
                          >
                            {countryWiseDistribution.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                </Box> */}
                {/* <Box>
                  {artifactWiseDistribution.length > 0 && (
                    <Card Card className={classes2.cardRoot2}>
                      <CardHeader title="Artifact Level Distribution" />
                      <CardContent className={classes2.cardContent}>
                        <PieChart className={classes2.pieChart} width={600} height={400}>
                          <Pie
                            isAnimationActive={true}
                            activeIndex={activeIndex5}
                            data={artifactWiseDistribution}
                            activeShape={renderActiveShape}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            legendType="circle"
                            onMouseEnter={onPieEnter5}
                          >
                            {artifactWiseDistribution.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </CardContent>
                    </Card>
                  )}
                </Box> */}
              </Box>
            )}
            {graphType === 'bar' && (
              <Box className={classes2.ToggleResultContainer}>
                <Box className={classes2.ToggleResBox}>
                  {alstomEntityWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader
                        title="Product Line Wise Distribution"
                        action={
                          <IconButton
                            title="Full Screen"
                            style={{ padding: 0 }}
                            onClick={handleFullScreen1.enter}
                          >
                            <FullscreenIcon />
                          </IconButton>
                        }
                      />

                      <CardContent>
                        <Box
                          className={
                            alstomEntityWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                        >
                          <FullScreen handle={handleFullScreen1}>
                            <BarChart
                              width={300}
                              height={350}
                              style={handleFullScreen1.active ? fullScreenStyle : {}}
                              data={alstomEntityWiseDistribution
                                .sort((a: any, b: any) => {
                                  return a.value - b.value;
                                })
                                .reverse()}
                              layout="horizontal"
                              margin={{
                                bottom: handleFullScreen1.active ? 150 : 75,
                                left: 40,
                              }}
                            >
                              <XAxis
                                dataKey="name"
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle="-20"
                                stroke="#000"
                                fontSize={10}
                              />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} align="right" />
                              <Bar
                                dataKey="value"
                                fill="#DC3223"
                                shape={<TriangleBar />}
                                barSize={50}
                              />
                            </BarChart>
                          </FullScreen>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                  {platformWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader
                        title="Platform Wise Distribution"
                        action={
                          <IconButton
                            title="Full Screen"
                            style={{ padding: 0 }}
                            onClick={handleFullScreen2.enter}
                          >
                            <FullscreenIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Box
                          className={
                            platformWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                        >
                          <FullScreen handle={handleFullScreen2}>
                            <BarChart
                              width={
                                projectWiseDistribution.length < 7
                                  ? 300
                                  : projectWiseDistribution.length * 20
                              }
                              style={handleFullScreen2.active ? fullScreenStyle : {}}
                              height={350}
                              data={platformWiseDistribution
                                .sort((a: any, b: any) => {
                                  return a.value - b.value;
                                })
                                .reverse()}
                              layout="horizontal"
                              margin={{
                                bottom: handleFullScreen2.active ? 150 : 75,
                                left: 40,
                              }}
                            >
                              <XAxis
                                dataKey="name"
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle="-20"
                                stroke="#000"
                                fontSize={10}
                              />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} align="right" />
                              <Bar
                                dataKey="value"
                                fill="#1e3246"
                                shape={<TriangleBar />}
                                barSize={50}
                              />
                            </BarChart>
                          </FullScreen>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Box>
                <Box className={classes2.ToggleResBox}>
                  {solutionWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader
                        title="Solution Wise Distribution"
                        action={
                          <IconButton
                            title="Full Screen"
                            style={{ padding: 0 }}
                            onClick={handleFullScreen3.enter}
                          >
                            <FullscreenIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Box
                          className={
                            solutionWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                        >
                          <FullScreen handle={handleFullScreen3}>
                            <BarChart
                              width={
                                productWiseDistribution.length < 7
                                  ? 300
                                  : productWiseDistribution.length * 40
                              }
                              style={handleFullScreen3.active ? fullScreenStyle : {}}
                              height={350}
                              // //   width={300}
                              //   height={350}
                              data={solutionWiseDistribution
                                .sort((a: any, b: any) => {
                                  return a.value - b.value;
                                })
                                .reverse()}
                              layout="horizontal"
                              margin={{
                                bottom: handleFullScreen3.active ? 150 : 75,
                                left: 40,
                              }}
                            >
                              <XAxis
                                dataKey="name"
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle="-20"
                                stroke="#000"
                                fontSize={10}
                              />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} align="right" />
                              <Bar
                                dataKey="value"
                                fill="#DC3223"
                                shape={<TriangleBar />}
                                barSize={50}
                              />
                            </BarChart>
                          </FullScreen>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                  {productWiseDistribution.length > 0 && (
                    <Card className={classes2.cardRoot}>
                      <CardHeader
                        title="Product Wise Distribution"
                        action={
                          <IconButton
                            title="Full Screen"
                            style={{ padding: 0 }}
                            onClick={handleFullScreen4.enter}
                          >
                            <FullscreenIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Box
                          className={
                            productWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                        >
                          <FullScreen handle={handleFullScreen4}>
                            <BarChart
                              width={
                                productWiseDistribution.length < 7
                                  ? 300
                                  : productWiseDistribution.length * 40
                              }
                              style={handleFullScreen4.active ? fullScreenStyle : {}}
                              height={350}
                              data={productWiseDistribution
                                .sort((a: any, b: any) => {
                                  return a.value - b.value;
                                })
                                .reverse()}
                              layout="horizontal"
                              margin={{
                                bottom: handleFullScreen4.active ? 150 : 75,
                                left: 40,
                              }}
                            >
                              <XAxis
                                dataKey="name"
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle="-20"
                                stroke="#000"
                                fontSize={10}
                              />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} align="right" />
                              <Bar
                                dataKey="value"
                                fill="#1e3246"
                                shape={<TriangleBar />}
                                barSize={50}
                              />
                            </BarChart>
                          </FullScreen>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Box>
                <Box className={classes2.ToggleResBox}>
                  {projectWiseDistribution.length > 0 && (
                    <Card Card className={classes2.cardRoot}>
                      <CardHeader
                        title="Project Wise Distribution"
                        action={
                          <IconButton
                            title="Full Screen"
                            style={{ padding: 0 }}
                            onClick={handleFullScreen5.enter}
                          >
                            <FullscreenIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Box
                          className={
                            projectWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                          // style={handleFullScreen5.active ? fullScreenStyle : {}}
                        >
                          <FullScreen handle={handleFullScreen5}>
                            <BarChart
                              width={
                                projectWiseDistribution.length < 7
                                  ? 300
                                  : projectWiseDistribution.length * 40
                              }
                              style={handleFullScreen5.active ? fullScreenStyle : {}}
                              height={350}
                              data={projectWiseDistribution
                                .sort((a: any, b: any) => {
                                  return a.value - b.value;
                                })
                                .reverse()}
                              layout="horizontal"
                              margin={{
                                bottom: handleFullScreen5.active ? 150 : 75,
                                left: 40,
                              }}
                            >
                              <XAxis
                                dataKey="name"
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle="-20"
                                stroke="#000"
                                fontSize={10}
                              />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} align="right" />
                              <Bar
                                dataKey="value"
                                fill="#205723"
                                shape={<TriangleBar />}
                                barSize={50}
                              />
                            </BarChart>
                          </FullScreen>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                  {documentProfileWiseDistribution.length > 0 && (
                    <Card Card className={classes2.cardRoot}>
                      <CardHeader
                        title="Document Profile Wise Distribution"
                        action={
                          <IconButton
                            title="Full Screen"
                            style={{ padding: 0 }}
                            onClick={handleFullScreen6.enter}
                          >
                            <FullscreenIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Box
                          className={
                            documentProfileWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                        >
                          <FullScreen handle={handleFullScreen6}>
                            <BarChart
                              width={
                                documentProfileWiseDistribution.length < 7
                                  ? 300
                                  : documentProfileWiseDistribution.length * 40
                              }
                              height={350}
                              style={handleFullScreen6.active ? fullScreenStyle : {}}
                              data={documentProfileWiseDistribution
                                .sort((a: any, b: any) => {
                                  return a.value - b.value;
                                })
                                .reverse()}
                              layout="horizontal"
                              margin={{
                                bottom: handleFullScreen6.active ? 150 : 75,
                                left: 40,
                              }}
                            >
                              <XAxis
                                dataKey="name"
                                textAnchor="end"
                                sclaeToFit="true"
                                verticalAnchor="start"
                                interval={0}
                                angle="-20"
                                stroke="#000"
                                fontSize={10}
                              />
                              <YAxis />
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} align="right" />
                              <Bar
                                dataKey="value"
                                fill="#5e35b1"
                                shape={<TriangleBar />}
                                barSize={50}
                              />
                            </BarChart>
                          </FullScreen>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Box>
                {/* <Box>
                  {countryWiseDistribution.length > 0 && (
                    <Card Card className={classes2.cardRoot2}>
                      <CardHeader title="Country Level Distribution" />
                      <CardContent
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          className={
                            countryWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                        >
                          <BarChart
                            width={
                              countryWiseDistribution.length === 1
                                ? 900
                                : countryWiseDistribution.length * 120
                            }
                            height={350}
                            data={countryWiseDistribution
                              .sort((a: any, b: any) => {
                                return a.value - b.value;
                              })
                              .reverse()}
                            layout="horizontal"
                            margin={{
                              bottom: 48,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              textAnchor="end"
                              sclaeToFit="true"
                              verticalAnchor="start"
                              interval={0}
                              angle="-45"
                              stroke="#000"
                              fontSize={10}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Legend verticalAlign="top" height={36} align="right" />
                            <Tooltip />
                            <Bar
                              dataKey="value"
                              fill="#4fa7f9"
                              shape={<TriangleBar />}
                              barSize={50}
                            />
                          </BarChart>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Box> */}
                {/* <Box>
                  {console.log('artifactWiseDistribution', artifactWiseDistribution)}
                  {artifactWiseDistribution.length > 0 && (
                    <Card Card className={classes2.cardRoot2}>
                      <CardHeader title="Artifact Level Distribution" />
                      <CardContent
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          className={
                            artifactWiseDistribution.length > 5
                              ? classes2.barGraph
                              : classes2.barGraph2
                          }
                        >
                          <BarChart
                            width={
                              artifactWiseDistribution.length === 1
                                ? 900
                                : artifactWiseDistribution.length * 120
                            }
                            height={350}
                            data={artifactWiseDistribution
                              .sort((a: any, b: any) => {
                                return a.value - b.value;
                              })
                              .reverse()}
                            layout="horizontal"
                            margin={{
                              bottom: 48,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              textAnchor="end"
                              sclaeToFit="true"
                              verticalAnchor="start"
                              interval={0}
                              angle="-45"
                              stroke="#000"
                              fontSize={10}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Legend verticalAlign="top" height={36} align="right" />
                            <Tooltip />
                            <Bar
                              dataKey="value"
                              fill="#4fa7f9"
                              shape={<TriangleBar />}
                              barSize={50}
                            />
                          </BarChart>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Box> */}
              </Box>
            )}
          </>
        ) : null}
      </Box>
    </>
  );
}

export default ContentSearchResult;
