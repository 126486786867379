import React from 'react';
import CircularPogressBar, { LinearProgressBar } from './CircularProgressBar';

function PercentageLoading({ percentage, isLinear = false }) {
  if (isLinear) {
    return (
      <>
        <LinearProgressBar percentage={percentage} />
      </>
    );
  }
  return (
    <div
      style={{
        width: '100%',
        minHeight: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexDirection: 'column',
        // position: 'relative',
      }}
    >
      <CircularPogressBar percentage={percentage} />
    </div>
  );
}

export default PercentageLoading;
