import { styled } from '@mui/material/styles';
import React from 'react';
import { Grid } from '@mui/material';

const ProjectAnalysisGrid = styled((props) => <Grid {...props} />)(({ theme }) => ({
  '& .root': {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  '& .content': {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
  '& .select': {
    '& > *': { fontWeight: '600 !important', fontSize: theme.typography.body1 },
  },
  '& .formControl': {
    margin: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    width: '95%',
  },
}));
export default ProjectAnalysisGrid;
