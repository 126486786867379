/* eslint-disable max-len */
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { BACKEND_URL } from '../const';
import { getBearerTokenSync } from '../app/AppToken';

const getW3client = () => {
  const bearerToken = getBearerTokenSync();
  return new W3CWebSocket(`${BACKEND_URL.websocket}/workloads?bearerToken=${bearerToken}`);
};

const getW3clientForUser = (serve) => {
  const bearerToken = getBearerTokenSync();
  return new W3CWebSocket(
    `${BACKEND_URL.websocket}/user_workload/ws?bearerToken=${bearerToken}&action=${serve}`
  );
};
export { getW3clientForUser };
export default getW3client;
