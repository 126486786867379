import {
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Button,
  FormControl,
  Grid,
  // Paper,
  Select,
  TextField,
  // Box,
} from '@mui/material';

import WorkIcon from '@mui/icons-material/Work';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ProjectContext from '../../../ProjectContext';
import useAppToken from '../../../../../app/useAppToken';
import { searchUsers } from '../../../../../api/users';
import {
  getAllProjectCreationLanguages,
  getAllProjectDetailsById,
  projectUpdate,
} from '../../../../../api/projects';
import Loading from '../../../../../components/Core/Loading';
import PropertieHide from '../../../components/PropertieHide';
import ProjectPropertiesContainer from './styles/ProjectPropertiesStyle';

const ROLE_PERFORMER = 3;

const ProjectPropertiesNew = () => {
  const [languageCode, setLanguageCode] = useState([]);
  // const dataRegion = ['APAC', 'AMERICAS', 'AMECA', 'EUROPE', 'FRANCE', 'DACH', 'CHINA'];
  const dataRegion = [
    { name: 'Asia Pacific (APAC)', dbValue: 'Asia Pacific (APAC)' },
    { name: 'Americas', dbValue: 'Americas' },
    { name: 'Africa Middle-East and Central Asia (AMECA)', dbValue: 'AMECA' },
    { name: 'Europe', dbValue: 'Europe' },
    { name: 'France', dbValue: 'France' },
    { name: 'Germany - Austria - Switzerland', dbValue: 'DACH' },
  ];
  // const [error, setError] = useState(false);
  const history = useHistory();
  const { setProject, userJWT, getProject, setProjects, projects, setSnack } = useContext(
    ProjectContext
  );
  const [projectUsers, setProjectUsers] = useState<any[]>([]);
  const [projectDetails, setProjetDetails] = useState<any[]>([]);
  const { projectId } = useParams() as any;
  const project = getProject(Number(projectId));
  const { appJWT } = useAppToken() as any;
  const token: any = appJWT.user_id;
  /// pased
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);
  // get all project
  useEffect(() => {
    setLoading(true);
    searchUsers({ project_id: projectId }).then((dbusers: any[]) => {
      const myusers = dbusers
        .map((userdt) => {
          return {
            ...userdt.user,
            role_id: userdt.user_project.role_id,
          };
        })
        .filter((users: any) => {
          // eslint-disable-next-line eqeqeq
          if (users.id == token) {
            return users;
          }
          return null;
        });
      setProjectUsers(myusers);
    });
    getAllProjectDetailsById(projectId).then((dbProjects) => {
      setProjetDetails(dbProjects);
    });
    getAllProjectCreationLanguages()
      .then((dbProjects) => {
        const db = dbProjects.map((item) => {
          return `${item.codes}-${item.language}`;
        });
        setLanguageCode(db);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
    // setLoading(false);
  }, [projectId]);

  const data = languageCode;

  const formInit = {
    name: undefined,
    description: undefined,
    entity: undefined,
    platform: undefined,
    product: undefined,
    solution: undefined,
    types: undefined,
    language: undefined,
    prcode: undefined,
    leadingunit: undefined,
    region: undefined,
  };
  const [form, setForm] = useState<any | undefined>(formInit);
  const handleSubmit = async () => {
    const {
      name,
      description,
      entity,
      types,
      solution,
      platform,
      product,
      language,
      prcode,
      leadingunit,
      region,
    } = form;

    // validate name
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < projectDetails.length; index++) {
      if (form.name && projectDetails[0]?.name !== form.name) {
        projectDetails[0].name = form.name;
      }
      if (form.description && projectDetails[0]?.description !== form.description) {
        projectDetails[0].description = form.description;
      }
      if (form.platform && projectDetails[0]?.platform !== form.platform) {
        projectDetails[0].platform = form.platform;
      }
      if (form.product && projectDetails[0]?.product !== form.product) {
        projectDetails[0].product = form.product;
      }
      if (form.solution && projectDetails[0]?.solution !== form.solution) {
        projectDetails[0].solution = form.solution;
      }
      if (form.prcode && projectDetails[0]?.prcode !== form.prcode) {
        projectDetails[0].prcode = form.prcode;
      }
      if (form.leadingunit && projectDetails[0]?.leadingunit !== form.leadingunit) {
        projectDetails[0].leadingunit = form.leadingunit;
      }
      if (form.language && projectDetails[0]?.language !== form.language) {
        projectDetails[0].language = form.language;
      }
      if (form.region && projectDetails[0]?.region !== form.region) {
        projectDetails[0].region = form.region;
      }
      if (form.entity && projectDetails[0]?.entity !== form.entity) {
        projectDetails[0].entity = form.entity;
      }
      if (form.types && projectDetails[0]?.types !== form.types) {
        projectDetails[0].types = form.types;
      }
    }
    if (!projectDetails[0].entity) {
      setSnack('Please select an entity', 'error');
      return;
    }
    projectUpdate(projectDetails[0], projectId)
      .then((p) => {
        setSnack('updated successfully.');
      })
      .catch((err) => {
        setHelperText(err.detail);
        setError(true);
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTimeout(() => setAnchorEl(null), 1000);
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, name: e.currentTarget.value });
  };
  const handleChangeSolution = (e) => {
    setForm({ ...form, solution: e.target.value });
  };
  const handleChangePlatform = (e) => {
    setForm({ ...form, platform: e.target.value });
  };
  const handleChangeDescription = (e) => {
    setForm({ ...form, description: e.target.value });
  };
  const handleChangePrCode = (e) => {
    setForm({ ...form, prcode: e.target.value });
  };
  const handleChangeProduct = (e) => {
    setForm({ ...form, product: e.target.value });
  };
  const handleChangeRegion = (e) => {
    setForm({ ...form, region: e.target.value });
  };
  const handleChangeLeadingUnit = (e) => {
    // const leadingUnits = e.currentTarget.value.trim();
    setForm({ ...form, leadingunit: e.target.value });
  };
  const handleChangeEntity = (e) => {
    const entity = e.target.value;
    setForm({ ...form, entity });
  };
  const handleChangeType = (e) => {
    const types = e.target.value;
    setForm({ ...form, types });
  };

  // console.log(projectDetails);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ProjectPropertiesContainer className="ProjectProperties">
          {/* <ProjectBreadCrumbs projectId={projectId} /> */}
          <>
            {projectUsers.length > 0 &&
            (projectUsers[0].is_admin === true ||
              projectUsers[0].role_id === 1 ||
              projectUsers[0].role_id === 2) ? (
              <>
                {projectDetails.length > 0 ? (
                  <>
                    <div className="paper_ProjectProperties">
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <form>
                              <div className="projectNameStyle_ProjectProperties">
                                <div
                                  style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    // justifyContent: 'center',
                                    paddingTop: 5,
                                  }}
                                >
                                  <WorkIcon />
                                  <b style={{ paddingLeft: 5 }}> Project Name</b>
                                </div>
                                <TextField
                                  size="small"
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  inputProps={{
                                    style: { color: '#777676', fontWeight: 'bold', border: 'none' },
                                  }}
                                  disabled
                                  className="projectInput_ProjectProperties"
                                  defaultValue={projectDetails[0]?.name}
                                  // size="small"
                                  name="name"
                                  onChange={handleChangeName}
                                />
                              </div>
                            </form>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="projectNameStyle_ProjectProperties">
                              <Grid item className="entity_ProjectProperties">
                                <FormLabel component="legend" style={{ color: '#002A45' }}>
                                  <b>Select Alstom Entity</b>
                                </FormLabel>
                                <RadioGroup
                                  aria-label="entity"
                                  name="entity"
                                  onChange={handleChangeEntity}
                                  defaultValue={projectDetails[0]?.entity}
                                  value={projectDetails[0]?.entity}
                                  style={{ fontWeight: 'bold', display: 'flex' }}
                                >
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormControlLabel
                                      disabled={!!projectDetails[0]?.entity}
                                      value="RSC"
                                      control={<Radio />}
                                      label="Rolling Stock & Component (RSC)"
                                    />
                                    <FormControlLabel
                                      disabled={!!projectDetails[0]?.entity}
                                      value="D&IS"
                                      control={<Radio />}
                                      label="Digital & Integrated Systems (D&IS)"
                                    />
                                    <FormControlLabel
                                      disabled={!!projectDetails[0]?.entity}
                                      value="Services"
                                      control={<Radio />}
                                      label="Services"
                                    />
                                  </div>
                                </RadioGroup>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="secondPart_ProjectProperties">
                          <Grid container>
                            <Grid item xs={12}>
                              <Grid>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <FormLabel component="legend">
                                    <b style={{ color: '#002A45', marginLeft: 10 }}>Type:</b>
                                  </FormLabel>
                                  <RadioGroup
                                    aria-label="type"
                                    name="types"
                                    // value={projectDetails[0]?.types}
                                    defaultValue={projectDetails[0]?.types || 'Tender'}
                                    // value={typeValue}
                                    onChange={handleChangeType}
                                    style={{ fontWeight: 'bold', display: 'flex', marginLeft: 10 }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '200%',
                                      }}
                                    >
                                      <FormControlLabel
                                        // disabled
                                        value="Project"
                                        control={<Radio />}
                                        label="Project"
                                      />
                                      {/* <FormControlLabel
                                        // disabled
                                        value="Product"
                                        control={<Radio />}
                                        label="Product"
                                      /> */}
                                      <FormControlLabel
                                        // disabled
                                        value="Program"
                                        control={<Radio />}
                                        label="Program"
                                      />
                                      <FormControlLabel
                                        // disabled
                                        value="Tender"
                                        control={<Radio />}
                                        label="Tender"
                                      />
                                      <FormControlLabel
                                        // disabled
                                        value="Pre Tender"
                                        control={<Radio />}
                                        label="Pre Tender"
                                      />
                                    </div>
                                  </RadioGroup>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <form>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginLeft: 10,
                                  }}
                                >
                                  <div>
                                    <b>Platform:</b>
                                  </div>
                                  <TextField
                                    style={{ marginLeft: 38 }}
                                    fullWidth
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    name="platform"
                                    defaultValue={projectDetails[0]?.platform}
                                    // value={projectDetails[0]?.solution}
                                    inputProps={{
                                      style: { color: '#002A45', fontWeight: 'bold' },
                                    }}
                                    onChange={handleChangePlatform}
                                  />
                                </div>
                              </form>
                            </Grid>
                            <Grid item xs={6}>
                              <form>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    <b>Solution:</b>
                                  </div>
                                  <TextField
                                    style={{ marginLeft: 10 }}
                                    fullWidth
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    name="solution"
                                    defaultValue={projectDetails[0]?.solution}
                                    // value={projectDetails[0]?.solution}
                                    inputProps={{
                                      style: { color: '#002A45', fontWeight: 'bold' },
                                    }}
                                    onChange={handleChangeSolution}
                                  />
                                </div>
                              </form>
                            </Grid>
                          </Grid>
                          <br />
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <form>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginLeft: 10,
                                  }}
                                >
                                  <div>
                                    <b>Product:</b>
                                  </div>
                                  <TextField
                                    style={{ marginLeft: 43 }}
                                    fullWidth
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    name="product"
                                    defaultValue={projectDetails[0]?.product}
                                    // value={projectDetails[0]?.solution}
                                    inputProps={{
                                      style: { color: '#002A45', fontWeight: 'bold' },
                                    }}
                                    onChange={handleChangeProduct}
                                  />
                                </div>
                              </form>
                            </Grid>
                            <Grid item xs={6}>
                              <form>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div style={{ whiteSpace: 'nowrap' }}>
                                    <b>PR Code:</b>
                                  </div>
                                  <TextField
                                    style={{ marginLeft: 10 }}
                                    fullWidth
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    defaultValue={projectDetails[0]?.prcode}
                                    inputProps={{
                                      style: { color: '#002A45', fontWeight: 'bold' },
                                    }}
                                    onChange={handleChangePrCode}
                                  />
                                </div>
                              </form>
                            </Grid>
                          </Grid>
                          <br />
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <form noValidate autoComplete="off">
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginLeft: 10,
                                  }}
                                >
                                  <div style={{ whiteSpace: 'nowrap' }}>
                                    <b>Leading Unit:</b>
                                  </div>
                                  <TextField
                                    style={{ marginLeft: 15 }}
                                    fullWidth
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                      style: { color: '#002A45', fontWeight: 'bold' },
                                    }}
                                    defaultValue={projectDetails[0]?.leadingunit}
                                    onChange={handleChangeLeadingUnit}
                                  />
                                </div>
                              </form>
                            </Grid>
                            <Grid item xs={6}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <div>
                                  <b>Region:</b>
                                </div>
                                <FormControl
                                  variant="outlined"
                                  style={{ marginLeft: 15 }}
                                  className="formControlMulti_ProjectProperties"
                                >
                                  <Select
                                    native
                                    label="Region"
                                    name="region"
                                    className="inputField_ProjectProperties"
                                    style={{ color: '#002A45', fontWeight: 'bold' }}
                                    // defaultValue={data[0]}
                                    defaultValue={
                                      dataRegion.filter((data) => {
                                        return projectDetails[0]?.region === data.dbValue;
                                      })[0]?.name ?? ''
                                    }
                                    onChange={handleChangeRegion}
                                  >
                                    <option aria-label="None" value="" />
                                    {dataRegion.map((cap: string, index: number) => (
                                      <option key={`capture-type-${index}`} value={cap.name}>
                                        {cap.name}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                          <br />
                        </div>
                        <Grid
                          // container
                          style={{ textAlign: 'right', marginTop: 10, marginRight: 10 }}
                        >
                          <Grid>
                            <Button
                              onClick={handleSubmit}
                              // endIcon={<Loading size={20} displayIcon={true} />}
                              style={{
                                color: 'white',
                                backgroundColor: '#DC3223',
                                width: '100px',
                              }}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              <PropertieHide />
            )}
          </>
        </ProjectPropertiesContainer>
      )}
    </>
  );
};

export default ProjectPropertiesNew;
