/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/**
 * This component renders of a Isearcher Text analysis comp of the page
 * Css also fixed here
 * @param {string} props
 * @returns {ReactNode} A React element that renders Isearcher Text analysis component of the page.
 *  Copyright @ Alstom 2023
 */

import { isEmpty } from '@antv/util';
import React, { useContext, useEffect, useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useParams,
  useHistory,
} from 'react-router-dom';

// import FindSimilarTexts from './Components/FindSimilarTexts';
import {
  iNeriSearcher,
  text2Dataset,
  iSearcherSearch,
  iQnA,
  getGenerateImages,
  iSearcherSearchCPE,
} from '../../api/module2';
import useAppToken from '../../app/useAppToken';
// import Module2Context from '../../Module2Context';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import HomeContext from '../ContextMager/HomeContext';

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function useKnowledge360Api() {
  // TextAnalsis operation states
  const { appJWT } = useAppToken();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let progressInterval = null as any;
  const [progressTime, setProgressTime] = useState(0);
  // const { clicked, setClicked, points, setPoints, eachShort, setEachShort } = useContextMenu();
  // const { shortCuts, setShortCuts } = useContext(ProjectContext);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { isearcherFilter, similarityFilter } = state;
  // console.log('issuer', issureSite);
  const { response, setResponse } = useContext(HomeContext);

  useEffect(() => {
    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  const {
    isearcherProductLine,
    isearcherProjects,
    isearcherDocumentProfiles,
    isearcherPlatforms,
    isearcherArtiFact,
    isearcherProduct,
    isearcherSolution,
    isearcherMaxResultsValue,
    isearcherMinResultsValue,
    isearcherDoor,
    isearcherCheckedA,
    isearcherCountry,
    selectedIsearcherProductLine,
    selectedIsearcherProjects,
    selectedIsearcherDocumentProfiles,
    selectedIsearcherPlatforms,
    selectedIsearcherArtiFact,
    selectedIsearcherProduct,
    selectedIsearcherSolution,
    selectedIsearcherCountry,
    selectedIsearcherMaxResultsValue,
    selectedIsearcherMinResultsValue,
    selectedIsearcherDoor,
    selectedIsearcherCheckedA,
  } = isearcherFilter;

  // console.log('door', door);

  const {
    simiProductLine,
    simiPlatform,
    simiSolution,
    simiProduct,
    simiProject,
    simiDocumentProfile,
    selectedSimiProductLine,
    selectedSimiPlatform,
    selectedSimiSolution,
    selectedSimiProduct,
    selectedSimiProject,
    selectedSimiDocumentProfile,
    countValue,
    threshold,
  } = similarityFilter;

  // const [remind, setRemind] = useState();
  const { projectId, userId } = useParams() as any;
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');

  // required for Iner new API call
  // const [ent, setEnt] = useState<any>();
  // const [proj, setProj] = useState<any>();
  // const [plat, setPlat] = useState<any>();
  // const [docpro, setDocpro] = useState<any>();
  // const [artPro, setArtPro] = useState<any>();
  // const [inerDisplay, setInerDisplay] = useState<any>(false);
  // const [loadIner, setLoadIner] = useState<any>(false);
  // const [iNerUsableData, setINerUsableData] = useState<any>();
  // const { setSnack } = useContext(Module1Context);

  // Changing permission for Production and development

  /* This useEffect for selceting the service when user redirect from Home page
       it will check the actionIS from params, and select the service
     */

  // Calling api when user move one tab to another
  // Api Call for Find SimilarText
  function FindSimilarText(inputValue, asyncFlag, redirect) {
    // language is harded changes done on 06th jan 2022
    let entityIs: any = [];
    let platform: any = [];
    let project: any = [];
    let documentProfile: any = [];
    let solution: any = [];
    let product: any = [];

    const startTime = Date.now();

    progressInterval = setInterval(() => {
      setProgressTime(Math.floor((Date.now() - startTime) / 1000) + 40);
    }, 1000);

    selectedSimiProductLine?.forEach((obj: any) => {
      entityIs.push(obj);
    });
    selectedSimiPlatform?.forEach((obj: any) => {
      platform.push(obj);
    });
    selectedSimiProject?.forEach((obj: any) => {
      project.push(obj);
    });
    selectedSimiDocumentProfile?.forEach((obj: any) => {
      documentProfile.push(obj);
    });
    selectedSimiSolution?.forEach((obj: any) => {
      solution.push(obj);
    });
    selectedSimiProduct?.forEach((obj: any) => {
      product.push(obj);
    });

    if (entityIs.length === 0 || entityIs.length === simiProductLine.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === simiPlatform.length) {
      platform = ['*'];
    }
    if (project.length === 0 || project.length === simiProject.length) {
      project = ['*'];
    }
    if (documentProfile.length === 0 || documentProfile.length === simiDocumentProfile.length) {
      documentProfile = ['*'];
    }

    if (solution.length === 0 || solution.length === simiSolution.length) {
      solution = ['*'];
    }

    if (product.length === 0 || product.length === simiProduct.length) {
      product = ['*'];
    }
    text2Dataset(
      inputValue,
      countValue,
      'All',
      entityIs,
      platform,
      project,
      documentProfile,
      solution,
      product,
      threshold,
      projectId ?? undefined
    )
      .then((payload: any) => {
        const tempResponse = {} as any;
        const checkData = JSON.parse(payload.output.result_data);
        tempResponse.text = inputValue;
        tempResponse.name = 'Similarity Search';
        tempResponse.error = false;
        if (checkData.length === 0) {
          tempResponse.display = true;
          tempResponse.error = true;
          //   setDisplay(true);
          //   setErrorMsg(true);
        } else {
          //   if (errorMsg) setErrorMsg(false);
          const initRow = JSON.parse(payload.output.result_data);
          const thresholdFiltered = initRow.filter((initRow) => initRow.Score >= threshold);
          //   setSimilarTextRow(thresholdFiltered);
          tempResponse.similarTextRow = thresholdFiltered;
          //   setDisplay(true);
          if (payload.output.text_is_translated) {
            // setLangResult(payload.output.translated_text);
            tempResponse.langResult = payload.output.translated_text;
          }

          tempResponse.language = payload.output.translated_language;

          setResponse(tempResponse);
          setLoading(false);
          //   setLanguage(payload.output.translated_language);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        clearInterval(progressInterval);
        if (asyncFlag) {
          history.push(redirect);
        }
        // setLoad(false);
      });
  }

  // Api Call for generate images
  function generateImagesApiCall(inputValue) {
    getGenerateImages(inputValue)
      .then((data) => {
        // setImages(data.output);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        // setDisplay(true);
        // setLoad(false);
      });
  }

  // Api Call for Content Search, Expert search and People search
  function IsearcherApiCall(
    searchValue: any,
    oldPayload: any,
    inputValue: string,
    route: string,
    asyncFlag: boolean
  ) {
    let entityIs: any = [];
    let platform: any = [];
    let project: any = [];
    let documentProfile: any = [];
    let artifactEnt: any = [];
    // @ ranjan
    let solution: any = [];
    let product: any = [];
    let country: any = [];

    const startTime = Date.now();

    progressInterval = setInterval(() => {
      setProgressTime(Math.floor((Date.now() - startTime) / 1000) + 40);
    }, 1000);

    selectedIsearcherProductLine.forEach((obj: any) => {
      entityIs.push(obj);
    });
    selectedIsearcherPlatforms.forEach((obj: any) => {
      platform.push(obj);
    });
    selectedIsearcherProjects.forEach((obj: any) => {
      project.push(obj);
    });
    selectedIsearcherDocumentProfiles.forEach((obj: any) => {
      documentProfile.push(obj);
    });
    selectedIsearcherSolution.forEach((obj: any) => {
      solution.push(obj);
    });
    selectedIsearcherProduct.forEach((obj: any) => {
      product.push(obj);
    });
    selectedIsearcherCountry.forEach((obj: any) => {
      country.push(obj);
    });
    selectedIsearcherArtiFact.forEach((obj: any) => {
      artifactEnt.push(obj);
    });
    // const startTime = performance.now();
    if (entityIs.length === 0 || entityIs.length === isearcherProductLine.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === isearcherPlatforms.length) {
      platform = ['*'];
    }
    if (project.length === 0 || project.length === isearcherProjects.length) {
      project = ['*'];
    }
    if (artifactEnt.length === 0 || artifactEnt.length === isearcherArtiFact.length) {
      artifactEnt = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === isearcherDocumentProfiles.length
    ) {
      documentProfile = ['*'];
    }
    if (solution.length === 0 || solution.length === isearcherSolution.length) {
      solution = ['*'];
    }
    if (product.length === 0 || product.length === isearcherProduct.length) {
      product = ['*'];
    }
    if (country.length === 0 || country.length === isearcherCountry.length) {
      country = ['*'];
    }

    // setEnt(entityIs);
    // setPlat(platform);
    // setProj(project);
    // setDocpro(documentProfile);
    // setArtPro(artifactEnt);
    // console.log(entityIs, platform, solution, product, project, documentProfile, country);

    iSearcherSearchCPE(
      searchValue,
      inputValue,
      platform,
      project,
      documentProfile,
      artifactEnt,
      solution,
      product,
      country,
      entityIs,
      selectedIsearcherMaxResultsValue,
      selectedIsearcherMinResultsValue,
      selectedIsearcherDoor,
      selectedIsearcherCheckedA,
      projectId ?? undefined
    )
      .then((payload: any) => {
        const tempResponse = {
          kpi: {},
          contentSearchPayload: {},
        } as any;
        tempResponse.text = inputValue;
        tempResponse.error = false;
        if (!payload) {
          tempResponse.error = true;
          //   setDisplay(true);
          //   setErrorMsg(true);
        } else if (searchValue === 'Query Search') {
          if (payload?.numberOfResultsFound === 0 && payload?.alstopedia.terms.length === 0) {
            // setDisplay(true);
            tempResponse.display = true;
            tempResponse.error = true;
            // setErrorMsg(true);
          } else {
            // if (errorMsg) setErrorMsg(false);
            // setContentSearchPayload(payload);
            // setRows(payload?.searchResults);
            // setLanguage(payload?.translation?.detectedLanguage);
            // setDetectLanguage(payload?.translation?.translatedText);
            // setShowTranslation(payload?.translation?.showTranslation);
            // setLangResult(payload?.translation?.translatedText);
            // setShowTransParent(true);
            tempResponse.error = false;
            tempResponse.name = 'Content Search';
            tempResponse.rows = payload?.searchResults ?? [];
            tempResponse.Language = payload?.translation?.detectedLanguage ?? '';
            tempResponse.detectLanguage = payload?.translation?.translatedText ?? '';
            tempResponse.showTranslation = payload?.translation?.showTranslation ?? '';
            tempResponse.langResult = payload?.translation?.translatedText ?? '';
            tempResponse.contentSearchPayload.alstopedia = payload?.alstopedia ?? {};
            const r: any = payload.searchResults;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < r.length; i++) {
              // eslint-disable-next-line dot-notation
              r[i]['id'] = i;
            }
            // setINerUsableData(JSON.stringify(r));
            // setSearchPayload(JSON.stringify(r));
            tempResponse.searchPayload = JSON.stringify(r);
            const endTime = performance.now();
            const t = ((endTime - startTime) / 3600).toFixed(2);
            tempResponse.timeTaken = t;
            // setTimeTaken(t);
            const count1 = payload?.visualizationData?.productLineWiseDistribution?.['D&IS'] || 0;
            const count2 = payload?.visualizationData?.productLineWiseDistribution?.RSC || 0;
            const count = count1 + count2;
            tempResponse.totalResult = payload?.numberOfResultsFound;
            // setTotalResult(payload?.numberOfResultsFound);
            // for graph code starts here
            const keysAlstomEntityWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData?.productLineWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productLineWiseDistribution
            );
            const valuesAlstomEntityWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.productLineWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productLineWiseDistribution
            );
            const alstomEntityWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
              if (valuesAlstomEntityWiseDistribution[index] !== 0) {
                alstomEntityWiseDistribution_array.push({
                  name: keysAlstomEntityWiseDistribution[index],
                  value: valuesAlstomEntityWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.alstomEntityWiseDistribution = alstomEntityWiseDistribution_array;
            // setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
            // second graph
            const keysPlatformWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.platformWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.platformWiseDistribution
            );
            const valuesPlatformWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.platformWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.platformWiseDistribution
            );
            const platformWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
              if (valuesPlatformWiseDistribution[index] !== 0) {
                platformWiseDistribution_array.push({
                  name: keysPlatformWiseDistribution[index],
                  value: valuesPlatformWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.platformWiseDistribution = platformWiseDistribution_array;
            // setPlatformWiseDistribution(platformWiseDistribution_array);
            const keysProjectWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.projectWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.projectWiseDistribution
            );
            const valuesProjectWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.projectWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.projectWiseDistribution
            );
            const projectWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
              if (valuesProjectWiseDistribution[index] !== 0) {
                projectWiseDistribution_array.push({
                  name: keysProjectWiseDistribution[index],
                  value: valuesProjectWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.projectWiseDistribution = projectWiseDistribution_array;
            // setProjectWiseDistribution(projectWiseDistribution_array);
            // third graph documentProfileWiseDistribution
            const keysdocumentProfileWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.documentProfileWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.documentProfileWiseDistribution
            );
            const valuesdocumentProfileWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.documentProfileWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.documentProfileWiseDistribution
            );
            const documentProfileWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
              if (valuesdocumentProfileWiseDistribution[index] !== 0) {
                documentProfileWiseDistribution_array.push({
                  name: keysdocumentProfileWiseDistribution[index],
                  value: valuesdocumentProfileWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.documentProfileWiseDistribution = documentProfileWiseDistribution_array;
            // setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
            // Fourth Graph
            const keysArtifactWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.artifactWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.artifactWiseDistribution
            );
            const valuesArtifactWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.artifactWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.artifactWiseDistribution
            );
            const artifactWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
              if (valuesArtifactWiseDistribution[index] !== 0) {
                artifactWiseDistribution_array.push({
                  name: keysArtifactWiseDistribution[index],
                  value: valuesArtifactWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.artifactWiseDistribution = artifactWiseDistribution_array;
            // setArticatWiseDistribution(artifactWiseDistribution_array);
            // Fiffth graph documentProfileWiseDistribution
            const keysdocumentSolutionWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.solutionWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const valuesdocumentSolutionWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.solutionWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const solutionWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
              if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
                solutionWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.solutionWiseDistribution = solutionWiseDistribution_array;
            // setSolutionWiseDistribution(solutionWiseDistribution_array);
            // sixth graph documentProfileWiseDistribution
            const keysProductWiseDistribution = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.productWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const valuesProductWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.productWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const productWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProductWiseDistribution.length; index++) {
              if (
                valuesProductWiseDistribution[index] !== 0 &&
                keysdocumentSolutionWiseDistribution[index] &&
                valuesdocumentSolutionWiseDistribution[index]
              ) {
                productWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.productWiseDistribution = productWiseDistribution_array;
            // setProductWiseDistribution(productWiseDistribution_array);
            // Fiffth graph documentProfileWiseDistribution
            const keysCountryWiseDistribution: any = Object.keys(
              searchValue === 'Query Search'
                ? payload.visualizationData.countryWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.countryWiseDistribution
            );
            const valuesCountryWiseDistribution = Object.values(
              searchValue === 'Query Search'
                ? payload.visualizationData.countryWiseDistribution
                : payload.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.countryWiseDistribution
            );
            const countryWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysCountryWiseDistribution.length; index++) {
              if (valuesCountryWiseDistribution[index] !== 0) {
                countryWiseDistribution_array.push({
                  name: keysCountryWiseDistribution[index],
                  value: valuesCountryWiseDistribution[index],
                });
              }
            }
            tempResponse.kpi.countryWiseDistribution = countryWiseDistribution_array;
            // setCountryWiseDistribution(countryWiseDistribution_array);
            // setDisplay(true);
          }
          // console.log('content', solutionWiseDistribution);
        } else if (searchValue === 'Expertise Search') {
          // console.log('Expertise Search', payload);
          if (isEmpty(payload)) {
            // setDisplay(true);
            // setErrorMsg(true);
          } else {
            // if (errorMsg) setErrorMsg(false);
            // setShowTranslation(payload?.translation?.showTranslation);
            // setLangResult(payload?.translation?.translatedText);
            // setShowTransParent(true);
            const totalExpertiseResult = payload ? payload.experts : {};
            const keysExpertDomain = Object.keys(totalExpertiseResult);
            const valuesExpertDomain = Object.values(totalExpertiseResult);
            const expertDomain_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysExpertDomain.length; index++) {
              if (valuesExpertDomain[index] !== 0) {
                expertDomain_array.push({
                  name: keysExpertDomain[index],
                  'Number Of Artifacts': valuesExpertDomain[index],
                });
              }
            }
            // setExpertDomain(expertDomain_array);
            tempResponse.name = 'Referents Search';
            tempResponse.expertDomain = expertDomain_array;
            nameClick(Object.keys(payload?.experts)[0], true, payload, tempResponse);
            // setDisplay(true);
          }
        }
        if (searchValue === 'People Search') {
          if (isEmpty(payload)) {
            // setDisplay(true);
            // setErrorMsg(true);
          } else {
            // if (errorMsg) setErrorMsg(false);
            const x = payload?.people[0];
            const payL = payload[x];
            if (oldPayload) {
              //   setOldPayload(payload);
              tempResponse.oldPayload = { people: [...oldPayload.people] };
            } else {
              // setPayloadPeopleSearch(payL);
              tempResponse.oldPayload = { people: payload?.people };
            }
            tempResponse.payloadPeopleSearch = payL;
            tempResponse.name = 'People Search';
            tempResponse.payloadPeopleSearch = payL;
            const r: any = payL?.searchResults;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < r.length; i++) {
              // eslint-disable-next-line dot-notation
              r[i]['id'] = i;
            }
            // setINerUsableData(JSON.stringify(r));
            // setSearchPayload(JSON.stringify(r));
            tempResponse.searchPayload = JSON.stringify(r);
            // console.log(JSON.stringify(f));
            // setTotalPeopleResult(payL?.numberOfResults);
            tempResponse.totalPeopleResult = payL?.numberOfResults;
            const endTime = performance.now();
            const t = ((endTime - startTime) / 3600).toFixed(2);
            // setTimeTaken(t);
            tempResponse.timeTaken = t;

            // setRows(payL?.searchResults);
            tempResponse.rows = payL?.searchResults;
            const count1 =
              payL?.visualizationData?.productLineWiseDistribution?.[
                'Digital & Integrated Systems'
              ] || 0;
            const count2 = payL?.visualizationData?.productLineWiseDistribution?.RSC || 0;
            const count = count1 + count2;
            // for graph code starts here
            const keysAlstomEntityWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData?.productLineWiseDistribution
                : null
            );
            const valuesAlstomEntityWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.productLineWiseDistribution
                : null
            );
            const alstomEntityWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
              if (valuesAlstomEntityWiseDistribution[index] !== 0) {
                alstomEntityWiseDistribution_array.push({
                  name: keysAlstomEntityWiseDistribution[index],
                  value: valuesAlstomEntityWiseDistribution[index],
                });
              }
            }

            tempResponse.kpi.alstomEntityWiseDistribution = alstomEntityWiseDistribution_array;
            // setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
            // second graph
            const keysPlatformWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.platformWiseDistribution
                : null
            );
            const valuesPlatformWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.platformWiseDistribution
                : null
            );
            const platformWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
              if (valuesPlatformWiseDistribution[index] !== 0) {
                platformWiseDistribution_array.push({
                  name: keysPlatformWiseDistribution[index],
                  value: valuesPlatformWiseDistribution[index],
                });
              }
            }

            tempResponse.kpi.platformWiseDistribution = platformWiseDistribution_array;
            // setPlatformWiseDistribution(platformWiseDistribution_array);
            // console.log(platformWiseDistribution_array);
            // second graph
            const keysProjectWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.projectWiseDistribution
                : null
            );
            const valuesProjectWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.projectWiseDistribution
                : null
            );
            const projectWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
              if (valuesProjectWiseDistribution[index] !== 0) {
                projectWiseDistribution_array.push({
                  name: keysProjectWiseDistribution[index],
                  value: valuesProjectWiseDistribution[index],
                });
              }
            }
            // console.log(projectWiseDistribution_array);
            tempResponse.kpi.projectWiseDistribution = projectWiseDistribution_array;
            // setProjectWiseDistribution(projectWiseDistribution_array);
            // third graph documentProfileWiseDistribution
            const keysdocumentProfileWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.documentProfileWiseDistribution
                : null
            );
            const valuesdocumentProfileWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.documentProfileWiseDistribution
                : null
            );
            const documentProfileWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
              if (valuesdocumentProfileWiseDistribution[index] !== 0) {
                documentProfileWiseDistribution_array.push({
                  name: keysdocumentProfileWiseDistribution[index],
                  value: valuesdocumentProfileWiseDistribution[index],
                });
              }
            }
            // console.log(documentProfileWiseDistribution_array);
            tempResponse.kpi.documentProfileWiseDistribution = documentProfileWiseDistribution_array;
            // setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
            // Fourth Graph
            const keysArtifactWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.artifactWiseDistribution
                : null
            );
            const valuesArtifactWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.artifactWiseDistribution
                : null
            );
            const artifactWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
              if (valuesArtifactWiseDistribution[index] !== 0) {
                artifactWiseDistribution_array.push({
                  name: keysArtifactWiseDistribution[index],
                  value: valuesArtifactWiseDistribution[index],
                });
              }
            }

            tempResponse.kpi.artifactWiseDistribution = artifactWiseDistribution_array;
            // setArticatWiseDistribution(artifactWiseDistribution_array);
            // Fiffth graph documentProfileWiseDistribution
            const keysdocumentSolutionWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.solutionWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const valuesdocumentSolutionWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.solutionWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.solutionWiseDistribution
            );
            const solutionWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
              if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
                solutionWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }

            tempResponse.kpi.solutionWiseDistribution = solutionWiseDistribution_array;
            // setSolutionWiseDistribution(solutionWiseDistribution_array);
            // sixth graph documentProfileWiseDistribution
            const keysProductWiseDistribution = Object.keys(
              searchValue === 'People Search'
                ? payL.visualizationData.productWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const valuesProductWiseDistribution = Object.values(
              searchValue === 'People Search'
                ? payL.visualizationData.productWiseDistribution
                : payL.individualResults[
                    inputValue.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
                  ]?.visualizationData?.productWiseDistribution
            );
            const productWiseDistribution_array: any = [];
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < keysProductWiseDistribution.length; index++) {
              if (valuesProductWiseDistribution[index] !== 0) {
                productWiseDistribution_array.push({
                  name: keysdocumentSolutionWiseDistribution[index],
                  value: valuesdocumentSolutionWiseDistribution[index],
                });
              }
            }

            tempResponse.kpi.productWiseDistribution = productWiseDistribution_array;
          }
        }

        setResponse(tempResponse);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setDisplay(true);
        // setErrorMsg(true);
      })
      .finally(() => {
        // setLoad(false);
        clearInterval(progressInterval);
        if (asyncFlag) {
          history.push(route);
        }
      });
  }

  // API call for people serach when user click on list of peoples
  const handleClickText2DatasetNew = (popleName, modeV, oldPayload) => {
    const searchValue = 'People Search';
    // setInerDisplay(false);
    // setLoadIner(false);
    IsearcherApiCall(searchValue, oldPayload, popleName, '', false);
  };

  const nameClick = (
    getName: string,
    _e_targetDefault: any = false,
    payload: any,
    tempResponse: any
  ) => {
    const expertResponse = tempResponse;
    const startTime = performance.now();
    const nameValue = getName;
    const payloadMain = payload;
    const specsKpis = payloadMain.specs[nameValue]?.artifactKPIs;
    const keysSpecsKpi = Object.keys(specsKpis);
    const valuesSpecsKpi = Object.values(specsKpis);
    const expertSpecsKpi_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysSpecsKpi.length; index++) {
      if (valuesSpecsKpi[index] !== 0) {
        expertSpecsKpi_array.push({
          name: keysSpecsKpi[index],
          value: valuesSpecsKpi[index],
        });
      }
    }
    // for graph code starts here
    const alstomEntity = payloadMain?.specKPIs[nameValue];
    const keysAlstomEntityWiseDistribution = Object.keys(alstomEntity?.productLineWiseDistribution);
    const valuesAlstomEntityWiseDistribution = Object.values(
      alstomEntity?.productLineWiseDistribution
    );
    const alstomEntityWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysAlstomEntityWiseDistribution.length; index++) {
      if (valuesAlstomEntityWiseDistribution[index] !== 0) {
        alstomEntityWiseDistribution_array.push({
          name: keysAlstomEntityWiseDistribution[index],
          value: valuesAlstomEntityWiseDistribution[index],
        });
      }
    }
    // second graph
    const keysPlatformWiseDistribution = Object.keys(alstomEntity?.platformWiseDistribution);
    const valuesPlatformWiseDistribution = Object.values(alstomEntity?.platformWiseDistribution);
    const platformWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysPlatformWiseDistribution.length; index++) {
      if (valuesPlatformWiseDistribution[index] !== 0) {
        platformWiseDistribution_array.push({
          name: keysPlatformWiseDistribution[index],
          value: valuesPlatformWiseDistribution[index],
        });
      }
    }
    // Third graph
    const keysProjectWiseDistribution = Object.keys(alstomEntity?.projectWiseDistribution);
    const valuesProjectWiseDistribution = Object.values(alstomEntity?.projectWiseDistribution);
    const projectWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProjectWiseDistribution.length; index++) {
      if (valuesProjectWiseDistribution[index] !== 0) {
        projectWiseDistribution_array.push({
          name: keysProjectWiseDistribution[index],
          value: valuesProjectWiseDistribution[index],
        });
      }
    }
    // Fourth graph documentProfileWiseDistribution
    const keysdocumentProfileWiseDistribution = Object.keys(
      alstomEntity?.documentProfileWiseDistribution
    );
    const valuesdocumentProfileWiseDistribution = Object.values(
      alstomEntity?.documentProfileWiseDistribution
    );
    const documentProfileWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentProfileWiseDistribution.length; index++) {
      if (valuesdocumentProfileWiseDistribution[index] !== 0) {
        documentProfileWiseDistribution_array.push({
          name: keysdocumentProfileWiseDistribution[index],
          value: valuesdocumentProfileWiseDistribution[index],
        });
      }
    }
    // Fifth Graph
    const keysArtifactWiseDistribution = Object.keys(alstomEntity?.artifactWiseDistribution);
    const valuesArtifactWiseDistribution = Object.values(alstomEntity?.artifactWiseDistribution);
    const artifactWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysArtifactWiseDistribution.length; index++) {
      if (valuesArtifactWiseDistribution[index] !== 0) {
        artifactWiseDistribution_array.push({
          name: keysArtifactWiseDistribution[index],
          value: valuesArtifactWiseDistribution[index],
        });
      }
    }
    // Fiffth graph documentProfileWiseDistribution
    const keysdocumentSolutionWiseDistribution = Object.keys(
      alstomEntity?.solutionWiseDistribution
    );
    const valuesdocumentSolutionWiseDistribution = Object.values(
      alstomEntity?.solutionWiseDistribution
    );
    const solutionWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysdocumentSolutionWiseDistribution.length; index++) {
      if (valuesdocumentSolutionWiseDistribution[index] !== 0) {
        solutionWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    expertResponse.kpi.solutionWiseDistribution = solutionWiseDistribution_array;
    // setSolutionWiseDistribution(solutionWiseDistribution_array);
    // sixth graph documentProfileWiseDistribution
    const keysProductWiseDistribution = Object.keys(alstomEntity?.productWiseDistribution);
    const valuesProductWiseDistribution = Object.values(alstomEntity?.productWiseDistribution);
    const productWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysProductWiseDistribution.length; index++) {
      if (valuesProductWiseDistribution[index] !== 0) {
        productWiseDistribution_array.push({
          name: keysdocumentSolutionWiseDistribution[index],
          value: valuesdocumentSolutionWiseDistribution[index],
        });
      }
    }
    // setProductWiseDistribution(productWiseDistribution_array);
    expertResponse.kpi.productWiseDistribution = productWiseDistribution_array;
    // Fiffth graph documentProfileWiseDistribution
    const keysCountryWiseDistribution = Object.keys(alstomEntity?.countryWiseDistribution);
    const valuesCountryWiseDistribution = Object.values(alstomEntity?.countryWiseDistribution);
    const countryWiseDistribution_array: any = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keysCountryWiseDistribution.length; index++) {
      if (valuesCountryWiseDistribution[index] !== 0) {
        countryWiseDistribution_array.push({
          name: keysCountryWiseDistribution[index],
          value: valuesCountryWiseDistribution[index],
        });
      }
    }
    expertResponse.kpi.countryWiseDistribution = countryWiseDistribution_array;
    expertResponse.language = payloadMain?.translation?.detectedLanguage ?? '';
    expertResponse.detectLanguage = payloadMain?.translation?.translatedText ?? '';
    // setCountryWiseDistribution(countryWiseDistribution_array);
    // setLanguage(payloadMain?.translation?.detectedLanguage);
    // setDetectLanguage(payloadMain?.translation?.translatedText);
    expertResponse.smartData = payloadMain?.specs[nameValue]?.smartIDCard;
    // setSmartData(payloadMain?.specs[nameValue]?.smartIDCard);
    expertResponse.artifactKpi = expertSpecsKpi_array ?? [];
    // setArtifactKpi(expertSpecsKpi_array);
    const endTime = performance.now();
    const t = ((endTime - startTime) / 3600).toFixed(2);
    expertResponse.timeTaken = t;
    expertResponse.rows = payloadMain?.specs[nameValue]?.searchResults;
    expertResponse.totalPeopleResult = payloadMain?.specs[nameValue]?.numberOfResults;
    // setTimeTaken(t);
    // setRows(payloadMain?.specs[nameValue]?.searchResults);
    // setTotalPeopleResult(payloadMain?.specs[nameValue]?.numberOfResults);
    expertResponse.kpi.alstomEntityWiseDistribution = alstomEntityWiseDistribution_array;
    // setAlstomEntityWiseDistribution(alstomEntityWiseDistribution_array);
    expertResponse.kpi.platformWiseDistribution = platformWiseDistribution_array;
    // setPlatformWiseDistribution(platformWiseDistribution_array);
    expertResponse.kpi.projectWiseDistribution = projectWiseDistribution_array;
    // setProjectWiseDistribution(projectWiseDistribution_array);
    expertResponse.kpi.documentProfileWiseDistribution = documentProfileWiseDistribution_array;
    // setDocumentProfileWiseDistribution(documentProfileWiseDistribution_array);
    // setArticatWiseDistribution(artifactWiseDistribution_array);
    expertResponse.kpi.artifactWiseDistribution = artifactWiseDistribution_array;
    // setPayloadMain(payload);
    expertResponse.payloadMain = payload;
    expertResponse.displaySmart = false;

    return expertResponse;
    // setDisplaySmart(false);
  };

  //   function getiQnA2(a: any) {
  //     // setTextValue(a);
  //     const bodyObj = a;
  //     iQnA(bodyObj)
  //       .then((payload: any) => {
  //         if (payload) {
  //           setAskQuesRes(payload);
  //           setApiData(`${url}/01/${payload.concept?.label}`);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }

  // API call for Ask QuestionAskQuesAp

  // Movement of search bar when result is displayed
  // handeling common function call for all operational API
  /* 
    This function is making a call for selected Call Operation so here in ths 
    function switch case is used, it will call an API for respective selected 
    Services
    */
  const handleApiCall = (inputValue = '', route = '', callOperation = '', asyncFlag = false) => {
    if (asyncFlag) {
      history.push(route);
      return [];
    }
    setLoading(true);

    const checkservice = ['Referents Search', 'Referents'].includes(callOperation)
      ? 'Referents'
      : callOperation;
    switch (checkservice) {
      case 'Similarity Search': {
        FindSimilarText(inputValue, asyncFlag, route);
        break;
      }

      case 'Content Search': {
        const searchValue = 'Query Search';
        // setLoad(true);
        // setDisplay(false);
        IsearcherApiCall(searchValue, '', inputValue, route, asyncFlag);
        break;
      }
      case 'Referents': {
        const searchValue = 'Expertise Search';
        // setLoad(true);
        // setDisplay(false);
        IsearcherApiCall(searchValue, '', inputValue, route, asyncFlag);
        break;
      }

      case 'People Search': {
        const searchValue = 'People Search';
        // setLoad(true);
        // setDisplay(false);
        IsearcherApiCall(searchValue, undefined, inputValue, route, asyncFlag);
        break;
      }

      default:
        break;
    }
    return [];
  };

  //   /* The getIner function */
  //   function getINer() {
  //     setInerDisplay(false);
  //     setLoadIner(true);
  //     const r = {
  //       dataframe: searchPayload,
  //       target: 'artifactText',
  //     };
  //     // console.log(JSON.stringify(r));
  //     let searchValue;
  //     if (operation === 0) {
  //       searchValue = 'Query Search';
  //     } else if (operation === 3) {
  //       searchValue = 'People Search';
  //     }
  //     const bodyHere = {
  //       query: inputValue,
  //       maxResults: maxResultsValue,
  //       minResultLen: 1,
  //       alstomEntity: ent,
  //       platform: plat,
  //       project: proj,
  //       documentProfile: docpro,
  //       artiFact: artPro,
  //       dataset: door,
  //       queryType: searchValue?.replace(/ /g, ''),
  //       ner: 'false',
  //     };
  //     iNeriSearcher(bodyHere)
  //       .then((payload) => {
  //         setINerUsableData(JSON.stringify(payload));
  //         setInerDisplay(true);
  //         // console.log('iNerUsableData', iNerUsableData);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         // console.log(iNerUsableData);
  //         setLoadIner(false);
  //       });
  //   }

  return {
    response,
    progressTime,
    handleMuliApiCall: handleApiCall,
    knowlede360loading: loading,
    nameClick,
    handleClickText2DatasetNew,
  };
}

export default useKnowledge360Api;
