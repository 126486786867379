/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
import axios from 'axios';
import { useAppContext } from '../components/appContextProvider/AppContextProvider';

type AxiosCall = {
  url: string;
  method: string;
  data?: any;
  header?: any;
};

const useAxiosAuth = () => {
  const { state } = useAppContext();
  const { jwtTokenDetails } = state;
  const { accessToken } = jwtTokenDetails;

  //   console.log("jwtTokenDetails", jwtTokenDetails);
  const createAxiosInstance = (url: string) => {
    const axiosInstance = axios.create({
      baseURL: url, // Replace with your actual base URL
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add a request interceptor to include the bearer token
    axiosInstance.interceptors.request.use(
      (config) => {
        const bearerToken = accessToken; // Replace with your actual token
        if (bearerToken) {
          config.headers['Authorization'] = `Bearer ${bearerToken.replace(/"/g, '')}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  };

  const AxiosCall = async (payload: AxiosCall) => {
    const { url, method, data, header } = payload;

    const axios = createAxiosInstance(url);
    try {
      switch (method) {
        case 'GET': {
          if (header !== null) {
            const response = await axios.get(url, { headers: header });
            return response.data;
          } else {
            const response = await axios.get(url);
            return response.data;
          }
        }

        case 'POST': {
          if (header !== null) {
            const response = await axios.post(url, data, { headers: header });
            return response.data;
          } else {
            const response = await axios.post(url, data);
            return response.data;
          }
        }

        case 'DELETE': {
          if (header !== null) {
            const response = await axios.delete(url, { headers: header });
            return response.data;
          } else {
            const response = await axios.delete(url);
            return response.data;
          }
        }

        case 'PATCH': {
          if (header !== null) {
            const response = await axios.patch(url, data, { headers: header });
            return response.data;
          }

          const response = await axios.patch(url, data);
          return response.data;
        }

        case 'PUT': {
          if (header !== null) {
            const response = await axios.patch(url, data, { headers: header });
            return response.data;
          }

          const response = await axios.patch(url, data);
          return response.data;
        }

        default: {
          return null;
        }
      }
      //   return response;
    } catch (error) {
      return error;
    }
  };

  return { AxiosCall };
};

export default useAxiosAuth;
