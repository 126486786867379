import { Box, Paper, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import chat_home from '../../../../assets/images/chat_icon_large.png';
import InputPrompt from './InputPrompt';
import { ChatContext } from './ChatContext';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '5px',
    padding: 5,
    boxShadow: 'none',
    textAlign: 'center',
    height: '48vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '@media (max-height: 500px)': {
      height: 'auto',
      justifyContent: 'top',
      alignItems: 'top',
    },

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      justifyContent: 'top',
      alignItems: 'top',
    },
  },
  box: {
    '@media (max-height: 500px)': {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  bgImage: {
    height: '240px',
    [theme.breakpoints.down('lg')]: {
      height: '150px',
    },
  },
}));
function BoardNewMessage({ toggleCollapse, setToggleMenu, checkedFile, indexName }: any) {
  const { state } = useContext(ChatContext);
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.root}>
        <Box className={classes.box}>
          <img className={classes.bgImage} src={chat_home} alt="Chat new conversation" />
          <h2>
            You are starting a new conversation
            <br />
            <span style={{ color: '#A6A2A2' }}>Type your first message below</span>
          </h2>
        </Box>
      </Paper>
      <div style={{ width: '100%' }}>
        <InputPrompt
          init={state.initPrompt}
          toggleCollapse={toggleCollapse}
          setToggleMenu={setToggleMenu}
          checkedFile={checkedFile}
          indexName={indexName}
        />
      </div>
    </>
  );
}

export default BoardNewMessage;
