import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import docIcon from '../../../../assets/docx_ico.png';
import xlsIcon from '../../../../assets/xlsx_ico.png';
import { LeftBlock, MainDiv, RightBlock } from './Styled';
// import DocAnalysisContext from '../../../DocAnalysisContext';
// import excelFilereducers, { excelInitialState } from '../../../../../Reducers/excelFilereducer';
// import excelFilereducers, { excelInitialState } from '../../../Reducers/excelFilereducer';

const ExcelFileSetting = (props) => {
  const { FileName, Sheet_names, Sheets, Blob, Container, setPayload, FileType, id } = props;
  const [exceldata, setExceldata] = React.useState({
    ...props,
    columns: [],
  });
  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        [id]: {
          sheetName: '',
          targetColumn: '',
          Blob,
          Container,
          FileType,
          FileName,
          columns: [],
        },
      };
    });
  }, []);

  const settingsData = [
    {
      FileType: 'Excel',
      ColumnNames: ['one', 'two', 'three'],
      Blob: FileName,
      Sheet_names,
      Sheets,
    },
  ];
  const state = {
    columns: null,
  };

  const HandleReg = (e) => {
    // dispatch({ type: 'change_sheets', payload: e.target.value });

    const { Sheets } = settingsData[0];
    const filterSheetDetails = Sheets.filter((data) => {
      return data.SheetName === e.target.value;
    });
    setPayload((prev) => {
      return {
        ...prev,
        [id]: {
          sheetName: e.target.value,
          targetColumn: '',
          Blob,
          Container,
          FileType,
          FileName,
          columns: filterSheetDetails[0].ColumNames,
        },
      };
    });
    setExceldata({ ...exceldata, columns: filterSheetDetails[0].ColumNames });
    // dispatch({ type: 'change_columns', payload: obj });
  };

  const HandleTargetColumn = (e) => {
    setPayload((prev) => {
      return {
        ...prev,
        [id]: { ...prev[id], targetColumn: e.target.value, Blob, Container, FileType, FileName },
      };
    });
    setExceldata({ ...exceldata, targetColumn: e.target.value });
    // dispatch({ type: 'change_targetColumn', payload: e.target.value });
  };

  return (
    <>
      <MainDiv>
        <LeftBlock>
          <FormControl variant="outlined">
            <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              {exceldata?.FileType === 'unsupported' ? (
                <img src={docIcon} alt="isens" height="30" />
              ) : (
                ''
              )}
              &nbsp;
              {exceldata?.FileType === 'Excel' ? (
                <img src={xlsIcon} alt="isens" height="30" width="30" />
              ) : (
                ''
              )}
              {exceldata?.FileType !== 'unsupported' ? (
                <span>
                  <span style={{ color: 'red' }}>{exceldata?.Error}</span>
                </span>
              ) : (
                ''
              )}
              <Tooltip title={exceldata?.Blob} placement="top" arrow>
                <b style={{ color: 'blue', wordWrap: 'break-word', wordBreak: 'break-all' }}>
                  {exceldata?.Blob.substring(0, 25)}
                </b>
              </Tooltip>
            </span>
          </FormControl>
        </LeftBlock>
        <RightBlock>
          {exceldata.FileType === 'Excel' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                gap: '20px',
                width: '100%',
              }}
            >
              <FormControl
                variant="outlined"
                size="small"
                style={{ flexBasis: '50%', height: '2.5rem' }}
              >
                <InputLabel
                  htmlFor="outlined-age-native-simple"
                  style={{ color: '#002A45', fontWeight: 'bold' }}
                  size="small"
                >
                  Sheet Column
                </InputLabel>
                <Select
                  native
                  label="Sheet  Name"
                  size="small" // name="sheetname"
                  style={{ width: '100%' }}
                  onChange={(e) => HandleReg(e)}
                  defaultValue=""
                >
                  <option aria-label="None" value="" disabled />
                  {exceldata?.Sheet_names !== null
                    ? exceldata?.Sheet_names.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          &nbsp;&nbsp;{cap}
                        </option>
                      ))
                    : ''}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                size="small"
                style={{ flexBasis: '50%', height: '2.5rem' }}
              >
                <InputLabel
                  htmlFor="outlined-age-native-simple"
                  style={{ color: '#002A45', fontWeight: 'bold' }}
                >
                  Target Column
                </InputLabel>
                <Select
                  native
                  label="Target  Column"
                  name="targetColumn"
                  style={{ width: '100%' }}
                  onChange={(e) => HandleTargetColumn(e)}
                  defaultValue=""
                >
                  <option aria-label="None" value="" disabled />
                  {exceldata?.columns !== null
                    ? exceldata?.columns.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          &nbsp;&nbsp;{cap}
                        </option>
                      ))
                    : ''}
                </Select>
              </FormControl>
            </div>
          )}
        </RightBlock>
      </MainDiv>
    </>
  );
};

export default ExcelFileSetting;
