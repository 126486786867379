/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Avatar,
  Box,
  Card,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { ApiWorkload3Status, workloadGetById, workloadStatus } from '../../api/workloads';
import useProjectId from '../../app/useProjectId';
import { Face } from '../../assets/icons';
import LinearProgressWithLabel from '../../components/Charts/LineProgress';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import downloadFileFromUrl from '../../tools/downloadFileFromUrl';
import ProjectContext from '../Project/ProjectContext';
import PieVerificationFiles from './components/PieVerificationFiles2';
import PieVerificationRules from './components/PieVerificationRules2';
import TableArtifactResults from './components/TableArtifactResults';
import TableRuleResults from './components/TableRuleResults';
import { getBearerTokenSync } from '../../app/AppToken';

export const colorsRules = {
  nok: '#EA847B', // '#DC3223',
  ok: '#57df74', // '#23DC97',
  notApplicable: '#FFA200',
  notTested: '#242424',
};

export const colorsFiles = { nok: '#EA847B', ok: '#57df74', outOfScope: '#002A452E' };

export const useStyles = makeStyles((theme) => ({
  root: {},
  bloc: {
    height: 323,
    width: 323,
    borderRadius: '17px',
    border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
  },
  blocPie: {
    width: 363,
    '& > *': { textDecoration: 'none' },
  },
  overflow: {
    height: 275,
    overflowY: 'scroll',
    wordWrap: 'break-word',
  },
  iconMargin: {
    marginRight: '16px',
    color: theme.palette.primary.dark,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.light,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

type State = {
  status?: WorkloadStatus3;
  statusErrors?: any[];
  result_files?: any;
  progress?: number;
  artifactRepartition?: any;
  artifactResults?: any;
  ruleRepartition?: any;
  ruleResults?: any;
  ruleResults2?: any;
};

const reducer = (state: any, action: any): State => {
  const { status, result_files, result_data: _result_data } = action.payload;
  const result_data = (_result_data || []).filter(
    (r: any) => r.artifacts.filename !== 'TOTAL_SUMMARY'
  );
  switch (action.type) {
    default:
    case 'init':
      return {
        status,
        statusErrors: status?.errors?.map((err: any) => err.filename) || [],
        result_files,
        progress:
          Math.ceil(
            (Number(status.progress?.length ?? 0) * 100) / Number(status.start?.file_number || 1)
          ) || 100,
        // pie chart
        artifactRepartition: result_data
          .map((r: any) => r.artifacts)
          .reduce(
            (acc: ArtifactResult3, r: any): ArtifactResult3 => ({
              nok: acc.nok + r.results.nok,
              ok: acc.ok + r.results.ok,
              out_of_scope: acc.out_of_scope + r.results.out_of_scope,
            }),
            {
              nok: 0,
              ok: 0,
              out_of_scope: 0,
            }
          ),
        // for files
        artifactResults: result_data.map((r: any) => ({
          label: String(r.artifacts.filename).replace('.xlsx', ''),
          ...r.artifacts.results,
        })),

        // for rules
        ruleResults: result_data.map((r: any) => ({
          filename: r.artifacts.filename,
          // spread total for filename
          ...r.rules.reduce(
            (acc: RuleResult3, r: any): RuleResult3 => ({
              nok: acc.nok + r.results.nok,
              ok: acc.ok + r.results.ok,
              not_applicable: acc.not_applicable + r.results.not_applicable,
              not_tested: acc.not_tested + r.results.not_tested,
            }),
            {
              nok: 0,
              ok: 0,
              not_applicable: 0,
              not_tested: 0,
            }
          ),
          // sub rows
          rules: r.rules.map((r: any) => ({
            rule: r.rule,
            ...r.results,
          })),
        })),
        // for rules
        ruleResults2: Object.entries(
          result_data.reduce((acc: any, r: any) => {
            r.rules.forEach((element: any) => {
              const accRule = acc[element.rule];
              if (accRule) {
                const res = element.results;
                acc[element.rule] = {
                  nok: accRule.nok + res.nok,
                  ok: accRule.ok + res.ok,
                  not_applicable: accRule.not_applicable + res.not_applicable,
                  not_tested: accRule.not_tested + res.not_tested,
                };
              } else {
                acc[element.rule] = element.results;
              }
            });
            return acc;
          }, {})
        ).map((r: any) => ({
          label: r[0],
          ...r[1],
          sub_rows: result_data.map((result: any) => {
            return {
              label: result.artifacts.filename,
              ...result.rules.find((rule: any): boolean => rule.rule === r[0]).results,
            };
          }),
        })),
      };
  }
};

// for total row and pie chart
const ruleRepartition = (ruleResults: RuleResult3[]) => {
  const initialState = {
    nok: 0,
    ok: 0,
    not_applicable: 0,
    not_tested: 0,
  };
  if (!ruleResults) return initialState;
  return ruleResults.reduce(
    (acc: RuleResult3, r: any): RuleResult3 => ({
      nok: acc.nok + r.nok,
      ok: acc.ok + r.ok,
      not_applicable: acc.not_applicable + r.not_applicable,
      not_tested: acc.not_tested + r.not_tested,
    }),
    initialState
  );
};

const artifactTotal = (artifactRepartition: ArtifactResult3) => {
  if (!artifactRepartition) return 0;
  return artifactRepartition.ok + artifactRepartition.nok + artifactRepartition.out_of_scope;
};

const ruleTotal = (payload: RuleResult3) => {
  if (!payload) return 0;
  return payload.ok + payload.nok + payload.not_applicable + payload.not_tested;
};

const isEnded = (status: any, result_files: any): boolean => {
  if (!result_files) return status.end && Object.keys(status.end).length > 0;
  const hasSummary =
    result_files.findIndex((r: any) => String(r.filename).search(/summary/i) !== -1) !== -1;
  return status.end && Object.keys(status.end).length > 0 && hasSummary;
};

function ResultContainer() {
  const classes = useStyles();
  const { projectId, workloadId } = useParams() as any;
  const initialState = {
    progress: 0,
    artifactRepartition: { nok: 0, ok: 0, out_of_scope: 0 },
    artifactResults: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState, () => initialState);
  const [totalRules, setTotalRules] = useState<any>();
  const [, setPayload] = useState<any>();
  const [workload, setWorkload] = useState<Workload3>();

  const { projects_lite } = useContext(ProjectContext);

  const { project } = useProjectId(projectId, projects_lite);

  const [availableRules, setAvailableRules] = useState<any>();

  useEffect(() => {
    workloadStatus(workloadId).then((payload) => {
      if (payload) {
        setPayload(payload);
        dispatch({ type: 'init', payload });
      }
    });
    workloadGetById(workloadId).then((payload) => {
      if (payload) {
        setWorkload(payload);
        // console.log('payload: ', payload?.rules);
        // to count the available Script Rule ID not blank
        let count = 0;
        // eslint-disable-next-line array-callback-return
        payload?.rules.map((e: any) => {
          // eslint-disable-next-line eqeqeq
          if (e['Script - Rule - ID'] != '') {
            count += 1;
          }
        });
        // console.log('count ', count);
        setAvailableRules(count);
      }
    });
  }, [workloadId]);

  useEffect((): any => {
    if (!state.status) return () => null;
    const { status, result_files } = state as ApiWorkload3Status;
    const timer = setInterval(() => {
      workloadStatus(workloadId).then((payload) => {
        const { status, result_files } = payload;
        dispatch({ type: 'init', payload });
        if (isEnded(status, result_files)) {
          clearInterval(timer);
        }
      });
    }, 5000);
    if (isEnded(status, result_files)) {
      clearInterval(timer);
      return null;
    }
    return () => clearInterval(timer);
  }, [workloadId, state]);

  useEffect(() => {
    setTotalRules(ruleRepartition(state.ruleResults2));
  }, [state]);

  if (!state.status) return null;

  const handleDownload = (filename: string) => {
    const token = getBearerTokenSync();
    downloadFileFromUrl(`/workloads/${workloadId}/results/download`, filename, token);
  };

  const { status, result_files } = state as State;
  const isStart = status?.start && Object.keys(status.start).length > 0;
  const isProgress = status?.progress && status.progress.length > 0;
  const isEnd = status?.end && Object.keys(status.end).length > 0;
  const resultsFilenames = result_files ? result_files.map((f: any) => f.filename) : [];

  if (!isStart) {
    return <>todo</>;
  }

  const summaryFilename = `Summary${workloadId}.xlsx`;

  return (
    <div className={classes.root}>
      <ProjectBreadCrumbs
        projectId={projectId}
        nodes={[{ label: `Workload ${workloadId}`, to: '#' }]}
      />
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
        <Grid item>
          <Card className={clsx(classes.bloc)} style={{ width: 220 }}>
            <Typography variant="subtitle1" className={classes.title}>
              Run Details
            </Typography>
            <List>
              {/* <ListItem key="file-avatar">
                <ListItemAvatar>
                  <Avatar>
                    <Face />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="isens user" />
              </ListItem> */}
              {status?.errors && status.errors.length > 0 && (
                <ListItem key="details-nb-errors" className={classes.error}>
                  <Avatar
                    className={clsx(classes.iconMargin, classes.error)}
                  >{`${status.errors.length}`}</Avatar>
                  <ListItemText primary=" Errors" />
                </ListItem>
              )}
              <ListItem key="details-nb-files">
                <Avatar className={classes.iconMargin}>{`${status?.count}`}</Avatar>
                <ListItemText primary=" Files" />
              </ListItem>
              {/* <ListItem key="details-nb-rules">
                <Avatar className={classes.iconMargin}>{`${workload?.rules?.length}`}</Avatar>
                <ListItemText primary=" Rules" />
              </ListItem> */}
              <ListItem key="details-nb-rules">
                <Avatar className={classes.iconMargin}>{`${availableRules}`}</Avatar>
                <ListItemText primary=" Rules" />
              </ListItem>
              {/* <ListItem key="file-nb-func">
                <Avatar className={classes.iconMargin}>{`${workload?.functions?.length}`}</Avatar>
                <ListItemText primary=" Functions" />
              </ListItem> */}
            </List>
          </Card>
        </Grid>
        <Grid item>
          <Box className={classes.bloc}>
            {isEnd || !state.progress || state.progress === 100 ? (
              <Typography variant="subtitle1" className={classes.title}>
                Files
              </Typography>
            ) : (
              <>
                <Typography variant="body1" className={classes.title}>
                  Files in progess
                </Typography>
                <LinearProgressWithLabel value={state.progress} />
              </>
            )}
            <List className={classes.overflow}>
              {isEnd && resultsFilenames.includes(summaryFilename) && (
                <ListItem key="file-summary">
                  <ListItemAvatar>
                    <Avatar>
                      <RiFileExcel2Line
                        style={{ color: 'green', cursor: 'pointer' }}
                        onClick={() => handleDownload(summaryFilename)}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Summary" />
                </ListItem>
              )}
              {isProgress &&
                result_files.map((p: any, index: number) => (
                  <ListItem key={`file-${p.filename}-${index}`}>
                    <ListItemAvatar>
                      <Avatar>
                        {resultsFilenames.includes(String(p.filename).replace('csv', 'xlsx')) ? (
                          <RiFileExcel2Line
                            style={{ color: 'green', cursor: 'pointer' }}
                            onClick={() =>
                              handleDownload(String(p.filename).replace('csv', 'xlsx'))
                            }
                          />
                        ) : (
                          <RiFileExcel2Line />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className={
                        state.statusErrors?.includes(p.filename) ? classes.error : undefined
                      }
                      title={state.statusErrors?.includes(p.filename) ? 'error' : undefined}
                      primary={String(p.filename).replace('.csv', '')}
                      secondary={p.date}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
        <Grid item>
          <Box className={clsx(classes.bloc, classes.blocPie)}>
            <a href="#fileresults">
              <Typography variant="subtitle1" className={classes.title}>
                Verification & Qualimetry by files
              </Typography>
              <Typography variant="body1" className={classes.title}>
                Total {artifactTotal(state.artifactRepartition || initialState.artifactRepartition)}
              </Typography>
            </a>
            <PieVerificationFiles
              notOK={state.artifactRepartition.nok}
              ok={state.artifactRepartition.ok}
              outOfScope={state.artifactRepartition.out_of_scope}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box className={clsx(classes.bloc, classes.blocPie)}>
            <a href="#ruleresults">
              <Typography variant="subtitle1" className={classes.title}>
                Verification & Qualimetry by rules
              </Typography>

              <Typography variant="body1" className={classes.title}>
                Total {ruleTotal(totalRules)}
              </Typography>
            </a>
            <PieVerificationRules
              notOK={totalRules.nok}
              ok={totalRules.ok}
              notApplicable={totalRules.not_applicable}
              notTested={totalRules.not_tested}
            />
          </Box>
        </Grid>
      </Grid>
      <a id="fileresults" />
      <TableArtifactResults
        rows={state.artifactResults}
        stickyRow={{
          label: 'Total',
          nok: state.artifactRepartition.nok,
          ok: state.artifactRepartition.ok,
          out_of_scope: state.artifactRepartition.out_of_scope,
        }}
      />
      <a id="ruleresults" />
      <TableRuleResults
        rows={state.ruleResults2}
        stickyRow={{
          label: 'Total',
          ...totalRules,
        }}
      />
      {/* <ReactJson src={{ root: state.ruleResults2 }} collapsed={true} theme="monokai" />
      <ReactJson src={{ payload }} collapsed={true} theme="monokai" /> */}
    </div>
  );
}

export default ResultContainer;
