// eslint-disable-next-line import/prefer-default-export
// export const ENV = 'local';
export const ENV = process.env.REACT_APP_ENV ?? process.env.NODE_ENV;
// if undefined debug = true
export const IS_DEBUG_MODE = false;

export const APP_NAME = String(process.env.REACT_APP_NAME ?? 'iSenS app');
export const APP_VERSION = '0.1.34';
// module 3
const URL_ROOT = process.env.REACT_APP_BACKEND_URL ?? '';
export const BACKEND_URL = {
  root: URL_ROOT,
  service_gpt: process.env.REACT_APP_GPT_URL ?? '',
  iframePdfHost: process.env.REACT_APP_IFRAME_URL ?? '',
  websocket: process.env.REACT_APP_BACKEND_WEBSOCKET ?? '',
  // users_folder/115/folders/newworkspacesss
  uploadUserDoc: `${URL_ROOT}/users_folder/userId/folders/newworkspace`,
  upload: `${URL_ROOT}/projects/projectId/folders/folderId/resources`,
  uploadRFP: `${URL_ROOT}/schedule_analysis_rfp_file/userId/projectId/rfpfolders`,
  uploadUserGlossaryDoc: `${URL_ROOT}/translator_glossary/userId/translator_glossary`,
  uploadGlossaryDoc: `${URL_ROOT}/translator_glossary/userId/translator_glossary`,
  configurations: `${URL_ROOT}/configurations`,
  run: `${URL_ROOT}/run`,
  adminUploadGlossaryDoc: `${URL_ROOT}/admin_default_glossary`,
  downloadDataset: process.env.REACT_APP_DOWNLOAD ?? '',
};

export const FILES_BY_MODULES = {
  module1: ['pdf', 'csv', 'xls', 'xlsx', 'doc', 'docx'], // doc and docx removed by @Ranjan
  module3: ['csv', 'xls', 'xlsx'],
};
// red , red-lite,  blue-dark, gray, green, green-lite
export const ISENS_COLORS = ['#dc3223', '#eb837d', '#1e3246', '#788291', '#19aa6e', '#73cdab'];

export const CHARACTERIZE_COLORS = {
  high: '#19AA6E',
  low: '#E15A50',
  medium: '#FF6633',
};

export const COMPLIANCE_COLORS = {
  compliant: '#19AA6E',
  nc: '#E15A50',
  gap: '#FF6633',
  tbd: '#AFA082',
  clarification: '#03568A',
  'need-clarification': '#03568A',
};

export const COMPLIANCE_LABELS = {
  compliant: 'compliant',
  nc: 'non compliant',
  gap: 'gap',
  tbd: 'to be done',
  clarification: 'need clarification',
  'need-clarification': 'need clarification',
};

export const LIFECYCLE_COLORS = {
  assigned: '#19AA6E',
  owned: '#002A45',
  rejected: '#E15A50',
  characterized: '#788291',
  analyzed: '#AFA082',
  recaptured: '#FF6633',
  clarification: '#03568A',
  'need-clarification': '#03568A',
};

export const LIFECYCLE_LABELS = {
  assigned: 'assigned',
  owned: 'owned',
  rejected: 'rejected',
  characterized: 'characterized',
  analyzed: 'analyzed',
  recaptured: 'recaptured',
  clarification: 'need clarification',
  'need-clarification': 'need clarification',
};
export const ASSIGN_LIFECYCLE_COLORS = {
  assigned: '#19AA6E',
  owned: '#002A45',
  rejected: '#E15A50',
  characterized: '#788291',
  analyzed: '#AFA082',
  recaptured: '#FF6633',
  'need-clarification': '#03568A',
};
export const ASSIGN_LIFECYCLE_LABELS = {
  assigned: 'assigned',
  owned: 'owned',
  rejected: 'rejected',
  characterized: 'characterized',
  analyzed: 'analyzed',
  recaptured: 'recaptured',
  'need-clarification': 'need clarification',
};
export const ALLOCATION_COLORS = {
  'Automatic Train Control': '#002A45',
  'Communication Network and Security': '#FF6633',
  Cybersecurity: '#19AA6E',
  'Diagnostic and Maintenance System': '#788291',
  Infrastructure: 'rgb(120, 130, 145)',
  Interlocking: 'rgb(210, 215, 220)',
  'Power Cabling and Field Equipment': 'rgb(235, 130, 125)',
  'Rolling Stock': 'rgb(250, 215, 210)',
  'SIG System': 'rgb(210, 240, 225)',
  'Supervision and Control Center': 'rgb(115, 205, 170)',
};

export const ALLOCATION_LABELS = {
  'Automatic Train Control': 'Automatic Train Control',
  'Communication Network and Security': 'Communication Network and Security',
  Cybersecurity: 'Cybersecurity',
  'Diagnostic and Maintenance System': 'Diagnostic and Maintenance System',
  Infrastructure: 'Infrastructure',
  Interlocking: 'Interlocking',
  'Power Cabling and Field Equipment': 'Power Cabling and Field Equipment',
  'Rolling Stock': 'Rolling Stock',
  'SIG System': 'SIG System',
  'Supervision and Control Center': 'Supervision and Control Center',
};

export const DOCTODATASET_COLORS = {
  Compliant: '#19AA6E',
  'Partially Compliant': '#AFA082',
  'Not Available': '#03568A',
  'Non Compliant': '#E15A50',
  Gap: '#FF875A',
};

export const DOCTODATASET_LABELS = {
  Compliant: 'Compliant',
  'Not Available': 'Not Available',
  'Non Compliant': 'Non Compliant',
  Gap: 'Gap',
  'Partially Compliant': 'Partially Compliant',
};

export const deepl_language = [
  {
    language: 'Arabic',
    code: 'AR',
  },
  {
    language: 'English',
    code: 'EN',
  },
  {
    language: 'French',
    code: 'FR',
  },
  {
    language: 'German',
    code: 'DE',
  },
  {
    language: 'Italian',
    code: 'IT',
  },
  {
    language: 'Spanish',
    code: 'ES',
  },
  {
    language: 'Bulgarian',
    code: 'BG',
  },
  {
    language: 'Chinese',
    code: 'ZH',
  },
  {
    language: 'Czech',
    code: 'CS',
  },
  {
    language: 'Danish',
    code: 'DA',
  },
  {
    language: 'Dutch',
    code: 'NL',
  },
  {
    language: 'Estonian',
    code: 'ET',
  },
  {
    language: 'Finnish',
    code: 'FI',
  },
  {
    language: 'Greek',
    code: 'EL',
  },
  {
    language: 'Hungarian',
    code: 'HU',
  },
  {
    language: 'Indonesian',
    code: 'ID',
  },
  {
    language: 'Japanese',
    code: 'JA',
  },
  {
    language: 'Korean',
    code: 'KO',
  },
  {
    language: 'Latvian',
    code: 'LV',
  },
  {
    language: 'Lithuanian',
    code: 'LT',
  },
  {
    language: 'Norwegian',
    code: 'NB',
  },
  {
    language: 'Polish',
    code: 'PL',
  },
  {
    language: 'Portuguese',
    code: 'PT',
  },
  {
    language: 'Romanian',
    code: 'RO',
  },
  {
    language: 'Russian',
    code: 'RU',
  },
  {
    language: 'Slovak',
    code: 'SK',
  },
  {
    language: 'Slovenian',
    code: 'SL',
  },
  {
    language: 'Swedish',
    code: 'SV',
  },
  {
    language: 'Turkish',
    code: 'TR',
  },
  {
    language: 'Ukrainian',
    code: 'UK',
  },
];

export const deepDocSupportedCode = [
  'BG',
  'CS',
  'DA',
  'DE',
  'EL',
  'EN',
  'ES',
  'ET',
  'FI',
  'FR',
  'HU',
  'ID',
  'IT',
  'JA',
  'KO',
  'LT',
  'LV',
  'NB',
  'NL',
  'PL',
  'RO',
  'PT',
  'RU',
  'SK',
  'SL',
  'SV',
  'TR',
  'UK',
  'ZH',
];

export const deepLExtensionSuppot = [
  'docx',
  'doc',
  'pptx',
  'xlsx',
  'pdf',
  'html',
  'txt',
  'xlf',
  'xliff',
  'ppt',
];

export const supportedfiles = [
  'xlsx',
  'csv',
  'pdf',
  'docx',
  'pptx',
  'ppt',
  'htm',
  'msg',
  'xls',
  'xlf',
  'html',
  'markdown',
  'mdown',
  'mkdn',
  'md',
  'mkd',
  'mdwn',
  'mdtxt',
  'mdtext',
  'rmd',
  'mthml',
  'mht',
  'doc',
  'odt',
  'odp',
  'ods',
  'rtf',
  'tsv',
  'tab',
  'txt',
];

export const deeplDescription = [
  'The DeepL service excels in handling translated documents in various formats, such as PDFs and Word documents, making the translated content accessible and usable for all our team members.',
  'With support for a wide range of languages, iTranslator is ideal for our global operations and diverse workforce. The service supports various languages, including English, Spanish, French, German, Italian, and many more.',
  'Currently, The DeepL Service does not support glossaries.To improve translation accuracy, pleace use the Microsoft Model.',
];

export const microSoftDescription = [
  'The Microsoft service excels in handling translated documents in various formats, such as PDFs and Word documents, making the translated content accessible and usable for all our team members.',
  'With support for a wide range of languages, iTranslator is ideal for our global operations and diverse workforce. The service supports various languages, including English, Spanish, French, German, Italian, and many more.',
  'To enhance translation accuracy, iTranslator allows the incorporation of user-provided glossaries. This feature ensures that specific terminology and phrases relevant to our industry are accurately translated, providing a contextually relevant and superior translation experience for our organization.',
];

export const similarityDescription = [
  "With iSimilarizer, you can easily find similar information within your organization's database.",
  'Simply upload your document in Excel, CSV, or PDF format, and iSimilarizer will search for related information across multiple texts.',
  'It compares each record in your document with records in the reference document to find matches.',
  'Additionally, you have the option to predict the compliance of a requirement, helping you ensure that your documents meet necessary standards.',
];

export const documentSimilarityDescription = [
  'This feature lets you compare two documents with each other. Each part of one document is compared to each part of the other document.',
  'It also calculates the overall similarity between the two documents.',
  'Supported formats are Excel,CSV,and PDF.',
];

export const comapreVersionDescription = [
  'This feature allows you to upload two versions of a document and compare them',
  'It identifies parts of the document that have been deleted, modified, or are new. Supported formats are Excel, CSV, and PDF.',
  'It is recommended to use this feature for comparing versions of a single document rather than two different documents.',
];

export const extractTopicDescription = [
  'This feature allows you to automatically identify and extract key entities from your document.',
  'It can recognize various types of information such as system components, activities, acronyms, standards, cyber security terms, project management terms, financial terms, commercial terms, and contractual terms.',
  ' This helps you quickly find and categorize important information from your data tables.',
];

export const findDuplicatesDescription = [
  'This feature helps you identify semantically duplicate requirements within your documents.',
  'It can detect similar parts of the document and provide an analysis for you. Supported formats are Excel, CSV, and PDF.',
];

export const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;
export const OAUTH_AUTHORITY = process.env.REACT_APP_OAUTH_AUTHORITY;
export const OAUTH_REDIRECT_URI = process.env.REACT_APP_OAUTH_REDIRECT_URI;
