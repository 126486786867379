/* eslint-disable react/button-has-type */
import { Box } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UploadIcon from '@mui/icons-material/Upload';

import { useHistory, useParams } from 'react-router-dom';
import TabContainerDiv, {
  TabBtnContainer,
  Button,
  TabHeader,
  Line,
  DropDownContainer,
  TabMain,
  GlossaryButton,
} from './styles/TabContainerStyle';
import DragAndDrop from '../../../../../components/Core/DragAndDrop';
import FileStatus from '../../../../../components/DocumentTranslator/Components/FileStatus';
import FileTranslateHistory from '../../../../../components/DocumentTranslator/Components/FileTranslateHistory';
import DocumentFiles from '../../../../../components/DocumentTranslator/Components/DocumentFiles';
import FileAnalysis from './FileAnalysis';
import RFPHistory from './RFPHistory';

const TabContainer = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const { projectId, workloadId } = useParams() as any;

  const [transformLine, setTransformLine] = useState('0rem');
  const queryParams = window.location.pathname;
  const getLastPath = queryParams.split('/').pop();

  useEffect(() => {
    if (getLastPath === 'history') {
      setTransformLine('6rem');
    }
  }, [getLastPath]);

  const HandleComponent = () => {
    switch (transformLine) {
      case '0rem':
        return (
          <>
            <FileAnalysis />
          </>
        );
      case '6rem':
        return <RFPHistory />;

      default:
        return null;
    }
  };

  return (
    <>
      <TabContainerDiv>
        <TabHeader>
          <TabBtnContainer>
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  setTransformLine('0rem');
                  history.push(`/project/${projectId}/module6/m6/Customer_RFP/document`);
                }}
              >
                Documents
              </Button>

              {/* <Button
                onClick={() => {
                  setTransformLine('6rem');
                  // history.push(`/projects/${projectId}/module6/m6/Tab/history`);
                }}
              >
                status
              </Button> */}
              <Button
                onClick={() => {
                  setTransformLine('6rem');
                  history.push(`/project/${projectId}/module6/m6/Customer_RFP/history`);
                }}
              >
                Reports
              </Button>
            </div>
            <Line
              style={
                transformLine !== 'glossary'
                  ? { left: transformLine }
                  : { right: '0rem', transition: 'right 0.5s' }
              }
            >
              {}
            </Line>
          </TabBtnContainer>

          {/* <DropDownContainer>{HandleButton()}</DropDownContainer> */}
        </TabHeader>
        <TabMain>{HandleComponent()}</TabMain>
      </TabContainerDiv>
    </>
  );
};

export default TabContainer;
