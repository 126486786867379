import React, { useContext, useEffect, useState } from 'react';
import { languageDetect, translateTextApi } from '../../../api/module2';
import useAppToken from '../../../app/useAppToken';

const useTransation = (props) => {
  const { translationState, setResultText, languageCode, setTranslationState } = props;
  const { appJWT } = useAppToken();
  const [interval, setInterval] = useState(null);
  const { user_id } = appJWT;

  let delayCallInterval = null as any;

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchTranslation = (text, _cache) => {
    const payload = _cache || translationState;
    if (!text) {
      return;
    }
    const body = {
      query: text.replace(/['"]+/g, ''),
      fromLanguage: payload.sourceLang,
      toLanguage: payload.destinationLang,
      deepL: payload.deepL === 'DeepL' ? 'yes' : 'no',
    };
    // const url = projectId !== undefined ? `/projects/${projectId}` : `/user_translator/${user_id}`;
    const url = `/user_translator/${user_id}`;
    Promise.all([translateTextApi(body, url), languageDetect(text.replace(/['"]+/g, ''))]).then(
      (results) => {
        try {
          const extractText = results[0].output;
          const txt = extractText[extractText.length - 1];
          setResultText(txt);
          const langcode = languageCode?.filter((d: string) => {
            const filterLang = d.toLowerCase()?.split('-');
            return filterLang.includes(results[1]?.langcode);
          });
          setTranslationState((prev) => {
            return {
              ...prev,
              sourceLang: langcode[0],
            };
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    );
  };

  const handleTextChange = (text, _cache = null) => {
    if (interval) {
      clearTimeout(interval);
      //   console.log('cleared');
    }
    delayCallInterval = setTimeout(() => {
      fetchTranslation(text, _cache);
    }, 1500);
    setInterval(delayCallInterval);
  };

  return { handleTextChange };
};

export default useTransation;
