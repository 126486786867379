/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ProjectContext from './ProjectContext';
import { getProjectbyID } from '../../api/projects';

const RenderComponents = (props) => {
  const contextValue = useContext(ProjectContext);
  const memomizedContextValue = useMemo(() => contextValue, [contextValue]);
  const { setProjects, getProjectDetails } = memomizedContextValue;
  const { projectId } = useParams() as any;
  const project = getProjectDetails(Number(projectId));
  const queryParams = new URLSearchParams(window.location.search);

  // console.log('reder project router', project);
  // console.log('projectId', projectId);
  // console.log('queryParams', queryParams);

  useEffect(() => {
    if (project) {
      getProjectbyID(projectId).then((data) => {
        const obj = {
          ...project,
          folders: data[0]?.folders,
        };
        const arr = [];
        arr.push(obj);
        setProjects(arr);
      });
    }
  }, [projectId, project]);

  const { renderComponent } = props;

  if (!project) {
    return (
      <Box
        style={{
          width: '100%',
          minHeight: '20rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" color="error">
          Oops! It seems you are not in the project.
        </Typography>
      </Box>
    );
  }

  return <>{renderComponent()}</>;
};

export default RenderComponents;
