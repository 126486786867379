import React from 'react';
import { AppBar, Theme as theme } from '@mui/material';
import { styled } from '@mui/material/styles';

const BORDER_RADIUS = '34px';
const ProjectGroupsContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),

  '& .content': {
    width: '100%',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },

  '& .bloc': {
    minHeight: 250,
    borderRadius: 10,
    border: 'solid 1px',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },

  '& .error': {
    color: theme.palette.error.main,
  },

  '& .heading': {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },

  '& .treeitem': {
    width: '95%',
    marginTop: '2rem',
  },

  '& .treeLabel': {
    borderRadius: '13px',
    paddingLeft: 10,
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },

  '& .box': {
    padding: '0px 0px 40px 0px',
    borderRadius: '34px',
    border: `1px solid`,
    borderTopColor: 'transparent',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'white',
    margin: theme.spacing(1),
    opacity: 1,
  },
  '& .root': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    opacity: '1 !important',
    fontSize: '-0.125rem',
  },
  '& .tab': {
    '& .MuiBox-root-11117': {
      padding: '0px',
    },
  },
  '& .boxTab': {
    padding: '0px 0px 20px 0px',
    borderRadius: BORDER_RADIUS,
    border: `1px solid`,
    borderTopColor: 'transparent',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#EEF1F9',
    height: '100%',
    opacity: 1,
  },

  '& .boxDocumentReview': {
    padding: '0px 0px 0px 0px',
    borderRadius: BORDER_RADIUS,
    border: `1px solid`,
    borderTopColor: 'transparent',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#EEF1F9',
    margin: theme.spacing(1),
    opacity: 1,
  },
  '& .tabstyle': {
    borderRadius: BORDER_RADIUS,
    background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
    color: '#ffff !important',
    fontSize: '-0.125rem',
    fontWeight: 700,
    boxShadow: '0px 3px 6px #00000029',
  },
  '& .appStyle': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: BORDER_RADIUS,
    backgroundColor: '#fff',
    '& .MuiTouchRipple-root': {
      backgroundColor: 'none',
      transition: 'none',
      borderRadius: '50px !important',
    },
  },
  '& .iconLabelWrapper': {
    cursor: 'pointer',
    width: '20px',
    marginRight: '15px',
    '& .MuiTouchRipple-root': {
      borderRadius: '50px !important',
    },
  },
  '& .tabLebelText': {
    opacity: '1 !important',
    fontWeight: 'bold',
    font: 'normal normal medium 23px/36px Alstom',
    display: 'flex',
    padding: '11px 0 4px 0',
  },
  '& .labels': {
    padding: '18px 0',
  },
}));

export default ProjectGroupsContainer;
