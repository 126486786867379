import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { isEmpty } from '@antv/util';
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import { textToText } from '../../../api/module2';
import { get_workbench, post_workbench } from '../../../api/allocationNew';
import useAppToken from '../../../app/useAppToken';
import Module1Context from '../../Module1/Module1Context';
import ProjectContext from '../../Project/ProjectContext';

const useStyles = makeStyles({
  parentConatiner: {
    margin: '4rem auto',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  root: {
    // marginTop: 60,
    // marginLeft: 20,
    // margin: '4rem auto',
    width: '100%',
    borderRadius: 10,
    height: '400px',
    // padding: 0,
  },
  root1: {
    marginTop: 0,
    // marginLeft: 20,
    width: '100%',
    borderRadius: 10,
    height: 500,
  },
  root2: {
    // marginTop: 20,
    // marginLeft: 20,
    width: '100%',
    borderRadius: 10,
    height: 40,
  },
  content: {
    backgroundColor: '#002A45',
    height: 'calc(400px-1.5rem)',
    // padding: 0,
  },
  title: {
    marginTop: -10,
    fontSize: 14,
    opecity: 1,
    color: 'white',
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
});

const formInit = {
  name: undefined,
  // description: undefined,
  entity: undefined,
  platform: undefined,
  solution: undefined,
  product: undefined,
  types: undefined,
  pgCode: undefined,
  ctCode: undefined,
  boid: undefined,
  region: undefined,
  country: undefined,
};

const dataRegion = [
  'Asia Pacific (APAC)',
  'Americas',
  'Africa Middle-East and Central Asia (AMECA)',
  'Europe',
  'France',
  'Germany - Austria - Switzerland',
];

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  { field: 'product_line', headerName: 'Product Line', width: 120 },
  { field: 'platforms', headerName: 'Platforms', width: 150 },
  { field: 'product', headerName: 'Product', width: 150 },
  { field: 'solution', headerName: 'Solution', width: 150 },
  { field: 'region', headerName: 'Region', width: 150 },
  { field: 'leading_unit', headerName: 'Leading Unit', width: 150 },
  { field: 'user_id', headerName: 'User Id' },
  { field: 'created_at', headerName: 'Created At', width: 1 },
];

export default function AllocationHome() {
  const { appJWT } = useAppToken();
  const history = useHistory();
  const { setSnack } = useContext(ProjectContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [form, setForm] = useState<any | undefined>(formInit);
  const [codeValue, setCodeValue] = useState('');
  const [typeValue, setTypeValue] = useState('Tender');
  const [projectNameValue, setProjectNameValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [manual, setManual] = useState(true);
  // const [loadingFilterData, setLoadingFilterData] = useState(true); // for filter selection
  const [addPaltform, setAddPlatform] = useState(false);
  const [textPlat, setTextPlat] = useState('');
  const [disPlat, setDisPlat] = useState(false);

  const [addProduct, setAddProduct] = useState(false);
  const [textProd, setTextProd] = useState('');
  const [disProd, setDisProd] = useState(false);

  const [addSolution, setAddSolution] = useState(false);
  const [textSol, setTextSol] = useState('');
  const [disSol, setDisSol] = useState(false);
  const [textLeadingValue, setTextLeadingValue] = React.useState('');
  const [regionValue, setRegionValue] = React.useState('');

  const [filterEntities, setFilterEntities] = useState<any[]>([]);
  const [filterPlatforms, setFilterPlatforms] = useState<any[]>([]);
  const [filterProduct, setFilterProduct] = useState<any[]>([]);
  const [filterSolution, setFilterSolution] = useState<any[]>([]);

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState([]);

  const [searchedProjectName, setSearchedProjectName] = useState([]);
  const [searchedEntities, setSearchedEntities] = useState([]);
  const [searchedPlatforms, setSearchedPlatforms] = useState([]);
  const [searchedProduct, setSearchedProduct] = useState([]);
  const [searchedSolution, setSearchedSolution] = useState([]);
  // const [searchedCountry, setSearchedCountry] = useState([]);
  const [searchedRegion, setSearchedRegion] = useState([]);
  const [searchedLeadingUnit, setSearchedLeadingUnit] = useState([]);
  const [getApiResult, setGetApiResult] = useState<any>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const [selectedRows, setSelectedRows] = useState(0);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState<any>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  function getTableData() {
    setLoadingTable(true);
    get_workbench()
      .then((res) => {
        setGetApiResult(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, []);
  useEffect(() => {
    textToText('*', '*', '*', '*', '*', '*')
      .then((payload: any) => {
        // setLoadingFilterData(false);
        if (payload) {
          // console.log(payload);
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            // ent.push({
            //   label: item,
            //   value: item,
            // });
            ent.push(item);
          }
          setFilterEntities(ent);

          // another one for platform
          const plat: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.platforms) {
            const item = payload.filters.platforms[i];
            plat.push(item);
          }
          setFilterPlatforms(plat);

          // another one for product
          const prod: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.products) {
            const item = payload.filters.products[i];
            prod.push(item);
          }
          setFilterProduct(prod);

          // another one for solutions
          const sol: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.solutions) {
            const item = payload.filters.solutions[i];
            sol.push(item);
          }
          setFilterSolution(sol);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  const handleChangeEntity = (e) => {
    setManual(true);
    const entity = e.currentTarget.value;
    const option = [];
    option.push({ value: entity });
    if (entity === 'Digital & Integrated Systems') {
      setForm({ ...form, entity: 'D&IS' });
    } else if (entity === 'Rolling Stock & Components') {
      setForm({ ...form, entity: 'RSC' });
    } else if (entity === 'Services') {
      setForm({ ...form, entity: 'Services' });
    } else {
      setForm({ ...form, entity });
    }
    // setForm({ ...form, entity });
    setSelectedEntities(option);
    // const extra = 'noChange_entity';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(option, selectedPlatforms, selectedSolution, selectedProduct);
  };

  const handleChangePlatfrom = (e) => {
    setManual(true);
    const platDrop = e.currentTarget.value;
    setForm({ ...form, platDrop });
    setDisPlat(true);
    const option = [];
    option.push({ value: platDrop });
    setSelectedPlatforms(option);
    // const extra = 'noChange_platform';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(selectedEntities, option, selectedSolution, selectedProduct);
  };

  const handleChangeSolution = (e) => {
    setManual(true);
    const solDrop = e.currentTarget.value;
    setForm({ ...form, solDrop });
    setDisSol(true);
    const option = [];
    option.push({ value: solDrop });
    setSelectedSolution(option);
    // const extra = 'noChange_solution';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(selectedEntities, selectedPlatforms, option, selectedProduct);
  };

  const handleChangeProduct = (e) => {
    setManual(true);
    const prodDrop = e.currentTarget.value;
    setForm({ ...form, prodDrop });
    setDisProd(true);
    const option = [];
    option.push({ value: prodDrop });
    setSelectedProduct(option);
    // const extra = 'noChange_product';
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    multifilterCall(selectedEntities, selectedPlatforms, selectedSolution, option);
  };

  function onChangePlatform(e) {
    setForm({ ...form, plat: e.target.value });
    setTextPlat(e.target.value);
  }

  function onChangeSolution(e) {
    setForm({ ...form, sol: e.target.value });
    setTextSol(e.target.value);
  }

  function onChangeProduct(e) {
    setForm({ ...form, prod: e.target.value });
    setTextProd(e.target.value);
  }

  const handleChangeRegion = (e) => {
    setManual(true);
    const region = e.currentTarget.value;
    setForm({ ...form, region });
    setRegionValue(e.currentTarget.value);
  };

  const handleChangeLeadingUnit = (e) => {
    setManual(true);
    // const leadingUnits = e.currentTarget.value.trim();
    setForm({ ...form, leadingunit: e.target.value });
    setTextLeadingValue(e.target.value);
  };

  const handleChangeCountry = (e) => {
    setManual(true);
    const country = e.currentTarget.value;
    setForm({ ...form, country });
  };

  const validateCode = (projectPrCode: string): boolean => {
    if (projectPrCode === '' || projectPrCode === undefined) {
      if (typeValue === 'Tender' || typeValue === 'Pre Tender') {
        setHelperText(`BO-ID Field is mandatory`);
      }
      if (typeValue === 'Project') {
        setHelperText(`CT code Field is mandatory`);
      }
      if (typeValue === 'Program') {
        setHelperText(`PG code Field is mandatory`);
      }
      // setDisableSubmit(true);
      return false;
    }
    setHelperText('');
    setDisableSubmit(false);
    return true;
  };

  function multifilterCall(en: any, plat: any, sol: any, prod: any) {
    const entityVal: any = [];
    const platformVal: any = [];
    const solutionVal: any = [];
    const productVal: any = [];

    // eslint-disable-next-line array-callback-return
    en?.map((o: any) => {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(o?.value) && entityVal.push(o.value);
    });
    // eslint-disable-next-line array-callback-return
    plat?.map((o: any) => {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(o?.value) && platformVal.push(o?.value);
    });
    // eslint-disable-next-line array-callback-return
    sol?.map((o: any) => {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(o?.value) && solutionVal.push(o.value);
    });
    // eslint-disable-next-line array-callback-return
    prod?.map((o: any) => {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(o?.value) && productVal.push(o.value);
    });

    textToText(entityVal, platformVal, solutionVal, productVal, [], [])
      .then((payload: any) => {
        if (payload) {
          // console.log(payload);
          const entityAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            // entityAfterFilter.push({
            //   label: item,
            //   value: item,
            // });
            entityAfterFilter.push(item);
          }

          // if (extra !== 'noChange_entity') {
          //   setFilterEntities(entityAfterFilter);
          // }
          setFilterEntities(entityAfterFilter);

          const platformAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.platforms) {
            const item = payload.filters.platforms[i];
            platformAfterFilter.push(item);
          }
          // if (extra !== 'noChange_platform') {
          //   setFilterPlatforms(platformAfterFilter);
          // }
          setFilterPlatforms(platformAfterFilter);

          const solutionAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.solutions) {
            const item = payload.filters.solutions[i];
            solutionAfterFilter.push(item);
          }
          // if (extra !== 'noChange_solution') {
          //   setFilterSolution(solutionAfterFilter);
          // }
          setFilterSolution(solutionAfterFilter);
          const productAfterFilter: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters.products) {
            const item = payload.filters.products[i];
            productAfterFilter.push(item);
          }
          // if (extra !== 'noChange_product') {
          //   setFilterProduct(productAfterFilter);
          // }
          setFilterProduct(productAfterFilter);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  const handleEmptyFields = () => {
    setManual(true);
    setDisProd(false);
    setDisPlat(false);
    setDisSol(false);
    setForm(formInit);
    setCodeValue('');
    // setTypeValue('');
    setProjectNameValue('');
    setAddPlatform(false);
    setAddProduct(false);
    setAddSolution(false);
    setSelectedEntities([]);
    setSelectedPlatforms([]);
    setSelectedSolution([]);
    setSelectedProduct([]);
    setSearchedProjectName([]);
    setSearchedEntities([]);
    setSearchedPlatforms([]);
    setSearchedProduct([]);
    setSearchedSolution([]);
    // setSearchedCountry([]);
    setSearchedRegion([]);
    setSearchedLeadingUnit([]);
    setError(false);
    setHelperText('');
    setRegionValue('');
    setForm({ ...form, region: '' });
    setForm({ ...form, leadingunit: '' });
    multifilterCall([], [], [], []);
  };

  const handleSubmit = async () => {
    setHelperText('');
    const { entity, platDrop, plat, prodDrop, prod, solDrop, sol, region, leadingunit } = form;
    if ((entity === undefined || entity === null) && searchedEntities.length === 0) {
      setHelperText('Please select Product line');
      setError(true);
      return;
    }

    setLoading(true);
    if (plat === undefined && platDrop === undefined && searchedPlatforms.length === 0) {
      setHelperText('Please select an platform');
      setError(true);
      return;
    }
    if (prod === undefined && prodDrop === undefined && searchedProduct.length === 0) {
      setHelperText('Please select an product');
      setError(true);
      return;
    }
    if (sol === undefined && solDrop === undefined && searchedSolution.length === 0) {
      setHelperText('Please select an solution');
      setError(true);
      return;
    }

    // validate region
    if ((region === undefined || String(region).length === 0) && searchedRegion.length === 0) {
      setHelperText('Please select a region');
      setError(true);
      return;
    }

    if (
      (leadingunit === undefined || String(leadingunit).length === 0) &&
      searchedLeadingUnit.length === 0
    ) {
      setHelperText('Please enter leading unit');
      setError(true);
      return;
    }
    // const { entity, platDrop, plat, prodDrop, prod, solDrop, sol, region, leadingunit } = form;
    const body = {
      user_id: appJWT?.user_id,
      product_line: entity,
      solution: solDrop === undefined ? sol : solDrop,
      platforms: platDrop === undefined ? plat : platDrop,
      region,
      leading_unit: leadingunit,
      product: prodDrop === undefined ? prod : prodDrop,
      created_at: 'string',
    };
    post_workbench(body)
      .then((res: any) => {
        getTableData();
        // console.log(typeof res);
        if (typeof res === 'boolean') {
          setSnack('Configuration already available please check table', 'warning');
        } else {
          setSnack(
            `New configuration added please check table. Created Id is: ${res?.id}`,
            'success'
          );
          history.push(`/allocationUpload/${res?.id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectionModelChange = (newSelection) => {
    setSelectedIds(newSelection);
  };

  function handleUpload() {
    if (selectedIds.length === 0) {
      setSnack('Please select at least one configuration to proceed', 'warning');
    } else {
      // console.log(selectedIds);
      // localStorage.setItem(
      //   'allocationNew',
      //   JSON.stringify({ allocationHomePageId: selectedIds[0] })
      // );
      history.push(`/allocationUpload/${selectedIds}`);
    }
  }

  return (
    <>
      <Box className={classes.parentConatiner}>
        <Card className={classes.root2}>
          <CardContent className={classes.content}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Provide the following details to Create\Edit\Test the Automatic allocation workbench
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.root}>
          <CardContent
            className={classes.content}
            style={{ width: '100%', backgroundColor: 'white' }}
          >
            <Grid style={{ marginTop: 25 }}>
              <b>Product Line:</b>
              <FormControl
                variant="outlined"
                style={{ marginLeft: 18, marginTop: -10 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" />
                <Select
                  native
                  // label="Product Line"
                  id="demo-simple-select-label"
                  name="Product Line"
                  disabled={!manual}
                  value={
                    manual
                      ? selectedEntities?.map((item) => item?.value)
                      : searchedEntities?.map((item) => item?.value)
                  }
                  // className={classes.inputField}
                  style={{ width: 230, marginLeft: 5, color: '#002A45' }}
                  // defaultValue={data[0]}
                  onChange={handleChangeEntity}
                >
                  <option aria-label="" value="" disabled>
                    Select the Product Line
                  </option>

                  {manual
                    ? filterEntities.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))
                    : searchedEntities.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap?.value}>
                          {cap?.value}
                        </option>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid spacing={2} style={{ marginTop: 10, maxWidth: '1000px', minWidth: '1000px' }}>
              <Grid container style={{ marginTop: 25, maxWidth: '1000px', minWidth: '1000px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <b>Platforms:</b>

                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: 35, marginTop: -10 }}
                    disabled={addPaltform && textPlat.length > 0}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      // label="Platforms"
                      name="Platforms"
                      disabled={!manual}
                      value={
                        manual
                          ? selectedPlatforms?.map((item) => item?.value)
                          : searchedPlatforms?.map((item) => item?.value)
                      }
                      // className={classes.inputField}
                      style={{ width: 230, color: '#002A45' }}
                      // defaultValue={data[0]}
                      onChange={handleChangePlatfrom}
                    >
                      <option aria-label="None" value="" disabled>
                        Select the Platforms
                      </option>
                      {filterPlatforms.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <form>
                    <div>
                      {addPaltform && (
                        <>
                          <TextField
                            disabled={disPlat}
                            style={{ marginLeft: 10, marginTop: -12 }}
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            label="Other Platform"
                            size="small"
                            value={textPlat}
                            onChange={onChangePlatform}
                          />
                        </>
                      )}
                      {!addPaltform && (
                        <div style={{ marginTop: -15 }}>
                          <span style={{ margin: '0px', fontWeight: 'bold' }}>
                            {' '}
                            &nbsp; OR &nbsp;
                          </span>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              return setAddPlatform(true);
                            }}
                            disabled={disPlat}
                          >
                            Other Platform
                          </Button>
                        </div>
                      )}
                    </div>
                  </form>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 25, maxWidth: '1000px', minWidth: '1000px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <b>Product:</b>
                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: 45, marginTop: -10 }}
                    disabled={addProduct && textProd.length > 0}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      // label="Product"
                      name="Product"
                      disabled={!manual}
                      value={
                        manual
                          ? selectedProduct?.map((item) => item?.value)
                          : searchedProduct?.map((item) => item?.value)
                      }
                      // className={classes.inputField}
                      style={{ width: 230, color: '#002A45' }}
                      // defaultValue={data[0]}
                      onChange={handleChangeProduct}
                    >
                      <option aria-label="None" value="" disabled>
                        Select the Product
                      </option>
                      {filterProduct.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <form>
                    <div>
                      {addProduct && (
                        <>
                          <TextField
                            disabled={disProd}
                            style={{ marginLeft: 10, marginTop: -12 }}
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            label="Other Product"
                            size="small"
                            value={textProd}
                            onChange={onChangeProduct}
                          />
                        </>
                      )}
                      {!addProduct && (
                        <div style={{ marginTop: -15 }}>
                          <span style={{ margin: '0px', fontWeight: 'bold' }}>
                            &nbsp; OR &nbsp;
                          </span>

                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              return setAddProduct(true);
                            }}
                            disabled={disProd}
                          >
                            Other Product
                          </Button>
                        </div>
                      )}
                    </div>
                  </form>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 25, maxWidth: '1000px', minWidth: '1000px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <b>Solution:</b>

                  <FormControl
                    variant="outlined"
                    style={{ marginLeft: 45, marginTop: -10 }}
                    disabled={addSolution && textSol.length > 0}
                    size="small"
                  >
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      // label="Solution"
                      name="Solution"
                      disabled={!manual}
                      value={
                        manual
                          ? selectedSolution?.map((item) => item?.value)
                          : searchedSolution?.map((item) => item?.value)
                      }
                      // className={classes.inputField}
                      style={{ width: 230, color: '#002A45' }}
                      // defaultValue={data[0]}
                      onChange={handleChangeSolution}
                    >
                      <option aria-label="None" value="" disabled>
                        Select the Solution
                      </option>
                      {filterSolution.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <form>
                    <div>
                      {addSolution && (
                        <>
                          <TextField
                            disabled={disSol}
                            style={{ marginLeft: 10, marginTop: -12 }}
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            label="Other Solution"
                            size="small"
                            value={textSol}
                            onChange={onChangeSolution}
                          />
                        </>
                      )}
                      {!addSolution && (
                        <div style={{ marginTop: -15 }}>
                          <span style={{ margin: '0px', fontWeight: 'bold' }}>
                            &nbsp; OR &nbsp;
                          </span>

                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              return setAddSolution(true);
                            }}
                            disabled={disSol}
                          >
                            Other Solution
                          </Button>
                        </div>
                      )}
                    </div>
                  </form>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 10, maxWidth: '1000px', minWidth: '1000px' }}
            >
              <Grid item>
                <b>Region:</b>
                <FormControl
                  variant="outlined"
                  style={{ marginLeft: 52, marginTop: -10 }}
                  size="small"
                >
                  {/* <InputLabel id="demo-simple-select-label">Region</InputLabel> */}
                  <Select
                    native
                    // label="Region"
                    id="demo-simple-select-label"
                    name="region"
                    disabled={!manual}
                    // className={classes.inputField}
                    style={{ width: 230, marginTop: 5, color: '#002A45' }}
                    value={manual ? regionValue : searchedRegion?.map((item) => item?.value)}
                    // defaultValue={data[0]}
                    onChange={handleChangeRegion}
                  >
                    {/* <option aria-label="None" value="" /> */}
                    <option aria-label="" value="" disabled>
                      Select the Region
                    </option>
                    {dataRegion.map((cap: string, index: number) => (
                      <option key={`capture-type-${index}`} value={cap}>
                        {cap}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <form noValidate autoComplete="off">
                  <div>
                    <b>Leading Unit: &nbsp;</b>
                    <TextField
                      style={{ marginLeft: 0, marginTop: -5, width: 300 }}
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      disabled={!manual}
                      // label="Leading Unit"
                      // defaultValue={textLeadingValue}
                      value={
                        manual ? form?.leadingunit : searchedLeadingUnit?.map((item) => item?.value)
                      }
                      onChange={handleChangeLeadingUnit}
                    />
                  </div>
                </form>
              </Grid>
              <Grid item>
                <span style={{ color: 'red', marginTop: 20, marginLeft: 50, fontWeight: 'bold' }}>
                  {error && helperText}
                </span>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ height: '1.5rem', float: 'right' }}>
            <Button variant="contained" color="secondary" onClick={handleEmptyFields}>
              Reset
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </CardActions>
        </Card>
        {/* <Card className={classes.root1}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Already created configuration:
          </Typography>
          <Grid style={{ marginTop: 20 }}>
            {getApiResult?.map((a, b) => {
              return (
                <p key={a.created_at}>
                  <input
                    type="checkbox"
                    checked={checkedItems[a.created_at] || false}
                    onChange={handleChange}
                    name={a.created_at}
                  />
                  {a.created_at}
                </p>
              );
            })}
          </Grid>
        </CardContent>
      </Card> */}
        <Card className={classes.root2}>
          <CardContent className={classes.content}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Already created allocation configuration
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Rows:{' '}
              {getApiResult.length}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="contained" color="secondary" size="small" onClick={handleUpload}>
                Proceed
              </Button>
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.root1}>
          {loadingTable && (
            <h4>
              <span style={{ color: 'red' }}>Loading...</span>
            </h4>
          )}
          {!loadingTable && (
            <DataGrid
              rows={getApiResult}
              columns={columns}
              checkboxSelection
              selectionModel={selectionModel}
              disableMultipleRowSelection
              onRowSelectionModelChange={(newSelection) => handleSelectionModelChange(newSelection)}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              columnHeaderHeight={36}
            />
          )}
        </Card>
        <br />
        <br />
      </Box>
    </>
  );
}
