import React, { useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import ProjectContext from '../Project/ProjectContext';
// import Module2 from './Module2';
import Module2Context, { Module2ContextProvider } from './Module2Context';
import PageLayout from '../../layouts/PageLayout';
import Module2Home from './NewModule/Module2Home';
import Loading from '../../components/Core/Loading';
import REXAnalysis from '../HOC/REXAnalysis';
import Module2Components from './Module/Module2Components';
import theme from '../Home/homeTheme';

function Module2Router() {
  const { projectId, moduleroute } = useParams() as any;
  const { projects } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects);

  // if (project === undefined) return <Loading />;

  return (
    <Module2ContextProvider project={project}>
      <Module2Context.Consumer>
        {(): any => {
          return (
            <Switch>
              <Route
                path="/project/:projectId/module2/:moduleroute/new"
                render={() => {
                  return (
                    <>
                      <Redirect to={`/project/${projectId}/module2/:moduleroute/run`} />
                    </>
                  );
                }}
              />
              <Route
                path="/project/:projectId/module2/:moduleroute/results/:workloadId"
                render={() => {
                  // return <Module2 />;
                  return (
                    <ThemeProvider theme={theme}>
                      <Module2Components />
                    </ThemeProvider>
                  );
                }}
              />
              <Route
                path="/project/:projectId/module2/:moduleroute/run"
                render={() => {
                  return (
                    <>
                      {/* <Redirect
                        to={`/projects/${projectId}/module2/${moduleroute}/run/?action=${'Translator'}`}
                      /> */}
                      <Module2Home />
                    </>
                  );
                }}
              />
              <Route
                path="/project/:projectId/module2/:moduleroute/run/:RouteName"
                render={() => {
                  return (
                    <>
                      {/* <Module2 /> */}
                      <Module2Home />
                    </>
                  );
                }}
              />
              <Route
                path="/project/:projectId/module2/:moduleroute/short/"
                render={() => {
                  return (
                    <>
                      {/* <Module2 /> */}
                      <Module2Home />
                    </>
                  );
                }}
              />
              <Route
                path="/project/:projectId/module2/:moduleroute/REXAnalysis"
                render={() => {
                  return (
                    <>
                      {/* <Module2 /> */}
                      <REXAnalysis />
                    </>
                  );
                }}
              />
              <Route
                path="/project/:projectId/module2"
                render={() => {
                  // return <Module2 />;
                  return (
                    <>
                      <ThemeProvider theme={theme}>
                        <Module2Components />
                      </ThemeProvider>
                    </>
                  );
                }}
              />
            </Switch>
          );
        }}
      </Module2Context.Consumer>
    </Module2ContextProvider>
  );
}

export default Module2Router;
