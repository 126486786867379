import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  // MRT_ShowHideColumnsButton as ShowHideColumnsButton,
  MRT_GlobalFilterTextField as GlobalFilterTextField,
} from 'material-react-table';
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  AddCircle as AddCircleIcon,
  Send as SendIcon,
  PlaylistAdd as PlaylistAddIcon,
  Merge as MergeIcon,
  CallSplit,
  HorizontalSplit as HorizontalSplitIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  ControlPoint as ControlPointIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Assessment as AssessmentIcon,
  Image as ImageIcon,
  TableChart as TableChartIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Allocation from '../AssignmentFlow/components/Allocations';
import allDataStyles from '../Styles/allDAta';
import Toogle from '../components/Toggle';
import hiddenColumns from '../const/allData';
import { charScoreColor, lifeCycle } from '../const/allocatorConst';
import FullScreenToogle from '../components/FullScreenToogle';
import RenderText from '../components/RenderText';
import TranslationList from '../../../../../../components/Datagrid/TranslationList';
import TranslationDropdown from '../components/TranslationDropdown';
import useColumnData from './useColumnData';
import Analyis from '../AnalysisFlow/components/Analyis';
import useDownloadFile from './useDownloadFile';
import RowChildContainer from '../components/RowChildContainer';
import { ButtonMUI } from '../Styles/utils';
import Refresh from '../components/Refresh';
import useIconsForTable from './useIconsForTable';
import ShowHideColumnsButton from '../core/ShowHideColumnsButton';

function useAllData(args) {
  const {
    data,
    loading,
    onChangeToggle,
    isFullScreen,
    onChangeFullScreen,
    toggleTable,
    handleConcat,
    handleMerge,
    handleRepeatHeader,
    handleSaveAlldata,
    translationCode,
    languages,
    onChangeModalData,
    setTranslationCode,
    languageOriginCode,
    rowSelection,
    setRowSelection,
    updateRowLang,
    levelsHierarchy,
    targetColumn,
    displayColumns,
    service,
    user_id,
    Filetype,
    projectGroups,
    groups,
    users,
    filename,
    projectId,
    urlFile,
    refreshDATA,
    setNotify,
    languagesCode,
    langCheck,
  } = args;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [DisplayCheckedCols, setDisplayCheckedCols] = useState([]);

  const [columnOrder, setColumnOrder] = useState([
    ...(JSON.parse(localStorage.getItem('Module_1_column_state')) ?? []),
  ]);

  const [editedRow, setEditedRow] = useState({});

  const textColum = targetColumn ?? 'Text';

  useMemo(() => {
    setEditedRow({});
  }, [data]);

  const { IconsForTable } = useIconsForTable();

  const { extractedColumns, hideColumns } = useColumnData({
    editedRow,
    textColum,
    levelsHierarchy,
    hiddenColumns,
    displayColumns,
    data,
    setEditedRow,
    service,
    type: Filetype,
    onChangeModalData,
  });

  const { exportAllDataforAllocator, loading: downloadProgess, progress } = useDownloadFile({
    columns: DisplayCheckedCols,
    row: data,
    filename: `${projectId}_${filename}_${service}`,
    service,
    targetColumn: textColum,
    displayColumns,
    projectGroups,
    userId: user_id,
    translationCode,
    groups,
    users,
    setNotify,
    languagesCode,
    langCheck,
  });

  const handlePaginationChange = (updater) => {
    setPagination(updater);
  };
  const handleColumnOrderChange = (updater) => {
    localStorage.setItem('Module_1_column_state', JSON.stringify(updater));
    setColumnOrder(updater);
  };

  const handleDeletRow = (table: { getState: () => any; getSelectedRowModel: () => any }) => {
    const selectedRows = table.getSelectedRowModel(); // or read entire rows
    const { flatRows } = selectedRows;

    const rows = flatRows.map((data) => {
      return data.original;
    });

    const keys = flatRows.map((data) => {
      return data.original.id;
    });

    onChangeModalData((prev) => {
      return { ...prev, deletData: { open: true, requirements: rows, keys } };
    });
  };

  const checkSequenceRows = (
    table: { getState: () => any; getSelectedRowModel: () => any },
    type: string
  ) => {
    const rowSelection = table.getState(); // read state
    const selectedRows = table.getSelectedRowModel(); // or read entire rows

    const { flatRows } = selectedRows;
    let check = true;

    const indexs = flatRows.map((data) => {
      return data.index;
    });

    if (indexs.length > 2 && type === 'two') {
      return check;
    }

    let trackSequence = indexs[0];

    for (const item of indexs) {
      if (trackSequence !== item) {
        check = true;
        break;
      }
      check = false;
      trackSequence += 1;
    }
    return check;
  };

  const renderSerivceComponent = (row) => {
    switch (service) {
      case 'analysis': {
        return (
          //  urlFile, Text, coordinates, children
          <RowChildContainer urlFile={urlFile} row={row} textColum={textColum} filename={filename}>
            <Analyis data={row} user_id={user_id} />
          </RowChildContainer>
        );
      }

      case 'assignment': {
        return (
          <RowChildContainer urlFile={urlFile} row={row} textColum={textColum} filename={filename}>
            <Allocation data={row.allocations} />
          </RowChildContainer>
        );
      }

      default:
        return null;
    }
  };

  const allDataObject = useMemo(
    () => ({
      columns: [...extractedColumns],
      data,
      enableColumnResizing: true,
      enableGrouping: true,
      enableExpandAll: true,
      enableStickyHeader: true,
      enableRowSelection: true,
      enableStickyFooter: true,
      autoResetPageIndex: false,
      enableColumnOrdering: true,
      enableColumnPinning: true,
      editDisplayMode: 'table',
      getRowId: (row) => row.row_id,
      enableEditing: true,
      // enableRowActions: true,
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'none',
      onRowSelectionChange: setRowSelection,
      onPaginationChange: handlePaginationChange,
      onColumnOrderChange: handleColumnOrderChange,
      ...allDataStyles,
      initialState: {
        density: 'compact',
        // showGlobalFilter: true,
        expanded: true,
        columnVisibility: { ...hideColumns },
        showColumnFilters: true,
        // columnOrder: JSON.parse(localStorage.getItem('Module_1_column_state')) ?? [],
        // expanded: true, // expand all groups by default
        // grouping: ['Text'], // an array of columns to group by by default (can be multiple)
        // pagination: { pageIndex: 0, pageSize: 20 },
        //   sorting: [{ id: "state", desc: false }], //sort by state by default
      },
      state: {
        columnOrder,
        rowSelection,
        showProgressBars: loading || downloadProgess,
        pagination,
      },
      muiLinearProgressProps: ({ isTopToolbar }) => ({
        color: 'secondary',
        sx: {
          display: isTopToolbar ? 'block' : 'none',
          height: 7,
        },
      }),
      muiExpandButtonProps: ({ row, table }) => ({
        onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), // only 1 detail panel open at a time
        sx: {
          transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
          transition: 'transform 0.2s',
        },
      }),

      renderDetailPanel: ({ row }) => {
        return <>{row.original.allocations ? renderSerivceComponent(row.original) : null}</>;
      },
      muiDetailPanelProps: ({ row }) => ({
        sx: {
          '& .MuiCollapse-root': {
            width: '100%',
          },
        },
      }),

      renderTopToolbarCustomActions: ({ table }) => {
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {service !== 'analysis' && (
                  <>
                    <Tooltip title="Concat, add a space between text">
                      <IconButton
                        color="info"
                        aria-label="s"
                        onClick={() => {
                          const { flatRows } = table.getSelectedRowModel();

                          const keys = flatRows.map((data) => {
                            return data.original.id;
                          });

                          handleConcat(keys, 'assignment');
                        }}
                        disabled={
                          checkSequenceRows(table, 'two') ||
                          Object.keys(table.getState().rowSelection).length !== 2
                        }
                      >
                        <PlaylistAddIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Merge, add a new line between text">
                      <IconButton
                        color="info"
                        aria-label="v"
                        onClick={() => {
                          const { flatRows } = table.getSelectedRowModel();

                          const keys = flatRows.map((data) => {
                            return data.original.id;
                          });
                          handleMerge(keys, 'assignment');
                        }}
                        disabled={
                          checkSequenceRows(table, 'two') ||
                          Object.keys(table.getState().rowSelection).length !== 2
                        }
                      >
                        <MergeIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Repeat header">
                      <IconButton
                        color="info"
                        aria-label="vs"
                        onClick={() => {
                          const { flatRows } = table.getSelectedRowModel();

                          const keys = flatRows.map((data) => {
                            return data.original.id;
                          });

                          handleRepeatHeader(keys, 'assignment');
                        }}
                        disabled={
                          Object.keys(
                            checkSequenceRows(table, 'multiple') || table.getState().rowSelection
                          ).length < 2
                        }
                      >
                        <HorizontalSplitIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Split the row">
                      <IconButton
                        color="info"
                        aria-label="vss"
                        onClick={() => {
                          // const itemObj = keys[0] ? keysData[keys[0]] : {};
                          const { rowsById, flatRows } = table.getSelectedRowModel();

                          const keys = flatRows.map((data) => {
                            return data.original.id;
                          });

                          const originalRow = flatRows.map((data) => {
                            return data;
                          });

                          const { original } = originalRow[0];

                          onChangeModalData((prev) => {
                            return {
                              ...prev,
                              splitData: { open: true, requirements: original, keys },
                            };
                          });
                        }}
                        disabled={Object.keys(table.getState().rowSelection).length !== 1}
                      >
                        <CallSplit fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton
                        color="info"
                        aria-label="vfs"
                        onClick={() => {
                          handleDeletRow(table);
                        }}
                        disabled={Object.keys(table.getState().rowSelection).length === 0}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                      <IconButton
                        color="info"
                        aria-label="vsv"
                        onClick={() => {
                          // const keys = Object.keys(rowSelection).map((data) => Number(data));
                          // const itemObj = keys[0] ? keysData[keys[0]] : {};

                          const { rowsById, flatRows } = table.getSelectedRowModel();

                          const keys = flatRows.map((data) => {
                            return data.original.id;
                          });

                          const originalRow = flatRows.map((data) => {
                            return data;
                          });

                          const { original } = originalRow[0];

                          onChangeModalData((prev) => {
                            return {
                              ...prev,
                              editText: { open: true, requirements: original, keys },
                            };
                          });
                        }}
                        disabled={Object.keys(table.getState().rowSelection).length !== 1}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="New Capture">
                      <IconButton
                        color="info"
                        aria-label="vsq"
                        onClick={() => {
                          const { rowsById, flatRows } = table.getSelectedRowModel();

                          const keys = flatRows.map((data) => {
                            return data.original.id;
                          });

                          const originalRow = flatRows.map((data) => {
                            return data;
                          });

                          const { original } = originalRow[0];
                          onChangeModalData((prev) => {
                            return {
                              ...prev,
                              newCapture: { open: true, requirements: original, keys },
                            };
                          });
                        }}
                        disabled={Object.keys(table.getState().rowSelection).length !== 1}
                      >
                        <ControlPointIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Tooltip title="Find Similar Items">
                  <IconButton
                    color="info"
                    aria-label="vsq"
                    onClick={() => {
                      const { rowsById, flatRows } = table.getSelectedRowModel();

                      const keys = flatRows.map((data) => {
                        return data.original.id;
                      });

                      const originalRow = flatRows.map((data) => {
                        return data.original;
                      });

                      // const { original } = originalRow[0];

                      onChangeModalData((prev) => {
                        return {
                          ...prev,
                          findSimilarity: { open: true, requirements: originalRow, keys },
                        };
                      });
                    }}
                    disabled={Object.keys(table.getState().rowSelection).length < 1}
                  >
                    <FileCopyOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {service !== 'analysis' && (
                  <ButtonMUI
                    // color="secondary"
                    startIcon={<SaveIcon />}
                    disabled={Object.keys(editedRow)?.length === 0}
                    sx={{ backgroundColor: 'green', marginLeft: '5px' }}
                    onClick={() => {
                      const rows = table.getSelectedRowModel();
                      handleSaveAlldata(editedRow, 'assignment');
                    }}
                    variant="contained"
                  >
                    Save
                  </ButtonMUI>
                )}
              </div>
            </>
          </Box>
        );
      },

      renderToolbarInternalActions: ({ table }) => {
        return (
          <>
            <Box style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              {/* <GlobalFilterTextField table={table} /> */}
              <Toogle onChangeToggle={onChangeToggle} toggleTable={toggleTable} />
              <Refresh refreshDATA={refreshDATA} />
              <IconButton
                color="info"
                onClick={() => {
                  onChangeModalData((prev) => {
                    return {
                      ...prev,
                      kpis: { open: true, requirements: [], keys: [] },
                    };
                  });
                }}
              >
                <AssessmentIcon />
              </IconButton>
              <TranslationDropdown
                languages={languages}
                languageCode={translationCode}
                languageOriginCode={languageOriginCode}
                onClick={setTranslationCode}
                onChangeTranslationCode={updateRowLang}
              />
              {/* <ShowHideColumnsButton table={table} /> */}
              <ShowHideColumnsButton table={table} />
              <ToggleDensePaddingButton table={table} />

              <IconButton
                disabled={downloadProgess}
                color="info"
                onClick={() => {
                  setDisplayCheckedCols([]);
                  const { getAllLeafColumns } = table;
                  const arr = getAllLeafColumns()
                    .filter((col) => col.columnDef.enableHiding !== false)
                    .filter((col) => col.getIsVisible() === true)
                    .map((col) => ({
                      header: col.columnDef.header,
                      key: col.id,
                      width: 30,
                    }));
                  // setDisplayCheckedCols(arr);
                  // console.log(arr, 'curr');
                  // console.log(arr, 'arr');
                  exportAllDataforAllocator({ column: arr });
                }}
              >
                <FileDownloadIcon />
              </IconButton>

              {/* <ToggleFullScreenButton table={table} /> */}
              <FullScreenToogle
                isFullScreen={isFullScreen}
                onChangeFullScreen={onChangeFullScreen}
              />
            </Box>
          </>
        );
      },
      icons: IconsForTable,
    }),
    [
      columnOrder,
      data,
      extractedColumns,
      isFullScreen,
      toggleTable,
      rowSelection,
      loading,
      pagination,
      editedRow,
      service,
      downloadProgess,
    ]
  );
  return allDataObject;
}

export default useAllData;
