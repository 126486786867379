import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid as ExpressGrid,
  Table,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Card, CardMedia } from '@mui/material';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import React, { useCallback, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import { tableHeaderComponent } from '../../../../../components/Datagrid/TStyles';
import { handleColumns } from '../../../../../tools/datagrid';
import { rawTypeEnum } from './ChararcterizerFlow';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    // position: 'absolute',
    // left: theme.spacing(110),
    // color: theme.palette.grey[100],
    position: 'absolute',
    right: theme.spacing(1),
    // paddingBottom: 5,
    top: -8,
    color: theme.palette.grey[500],
  },
  imagePanel: {
    // border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: 10,
    margin: 25,
    paddingTop: 2.5,
    paddingBottom: 1,
    // minHeight: 200,
    // display: 'flex',
    // justifyContent: 'center',
    // alignContent: 'center',
  },
  mainBox1: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    margin: 35,
    paddingTop: 2.5,
    paddingBottom: 1,
  },
  mainCard1: {
    // width: '100%',
    objectFit: 'none',
    display: 'flex',
    justifyItems: 'center',
    alignContent: 'center',
    height: 500,
    // marginTop: 5,
  },
  mainCardMedia1: {
    width: '100%',
    height: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contain',
  },
  dialogContent: {
    backgroundColor: '#FBFBFB',
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 1,
  },
  textColor: {
    color: theme.palette.primary.main,
  },
}));
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
};
type Props = {
  imagesList: any[];
  tablesList: any[];
  rawType?: rawTypeEnum;
  onClose: any;
  table: any;
  index: any;
};

export default function ModalRawDataTable({ table, index, onClose }: Props) {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onDownload = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  // table container
  const tableContainerComponent: React.FunctionComponent<Table.CellProps> = (props) => {
    return (
      <Table.Container
        {...props}
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          border: '1px solid black',
        }}
      />
    );
  };
  // table toolbar
  const toolbarComponent: React.FunctionComponent<Toolbar.RootProps> = (props) => {
    return (
      <Toolbar.Root
        {...props}
        style={{
          minHeight: 0,
          backgroundColor: '#EEF1F9',
          border: '1px solid black',
        }}
      />
    );
  };
  // download image
  const download = (e) => {
    // console.log(e.target.href);
    fetch(e.target.href, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.png'); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Box className={classes.imagePanel} key={`box-${index}`}>
        <ExpressGrid rows={table.data.rows} columns={handleColumns(table.data.columns)}>
          <Table
            columnExtensions={handleColumns(table.data.columns).map((c) => ({
              columnName: c.name,
              wordWrapEnabled: true,
            }))}
            tableComponent={TableComponent}
            containerComponent={tableContainerComponent}
            headComponent={tableHeaderComponent}
          />
          <TableHeaderRow />
          <Toolbar rootComponent={toolbarComponent} />
          <ToolBarPlugin name="Download" onClick={startExport} />
        </ExpressGrid>
        <GridExporter
          key={`box-${index}`}
          ref={exporterRef}
          rows={table.data.rows}
          columns={handleColumns(table.data.columns)}
          onSave={onDownload}
        />
      </Box>
    </>
  );
}
