/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Slide from '@mui/material/Slide';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AlarmIcon from '@mui/icons-material/Alarm';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InfoIcon from '@mui/icons-material/Info';
import BugReportIcon from '@mui/icons-material/BugReport';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { display } from '@mui/system';
import { w3cwebsocket } from 'websocket';
import AboutIsens from '../../src/components/Layout/AboutIsens';
import getW3client, { notifGetCompact, notifSetRead, notifSetReadByCat } from '../api/wsNotifs';
import sleep from '../../src/tools/sleep';

const RightPanelLayoutCardContent = styled((props) => <CardContent {...props} />)(
  ({ theme, isNew }) => ({
    backgroundColor: !isNew ? 'wheat' : 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 0,
  })
);

interface layoutType {
  pannelLayout: any;
}

function RightPanelLayout({ pannelLayout }: layoutType) {
  const { setSideBar, isOpen } = pannelLayout;
  const history = useHistory();
  const containerRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <div>
        <CloseIcon
          onClick={() => {
            setSideBar({ isPanel: '', isOpen: false });
          }}
          style={{
            color: 'black',
            position: 'absolute',
            right: '3px',
            top: '3px',
            cursor: 'pointer',
          }}
        />
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: 'column',
            height: '2rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* {isPanel === 'ownings' && <NotificationsNoneIcon style={{ color: 'white' }} />}
              {isPanel === 'workloads' && <AlarmIcon style={{ color: 'white' }} />}
              {isPanel === 'userManagements' && <InfoIcon style={{ color: 'white' }} />} */}
          <Typography variant="h6" style={{ color: '#002a45', font: 'bold' }}>
            {pannelLayout.name}
          </Typography>
        </div>
        <hr style={{ width: '100%', color: 'grey' }} />
        <div
          style={{
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: 'calc(100% - 2rem)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '10px !important',
              height: '10px !important',
            },
            '&::-webkit-scrollbar-thumb': {
              border: '5px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box',
              borderRadius: '10px !important',
              backgroundColor: 'rgba(0, 0, 0, .42)',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'red !important',
            },
            // marginBottom: '5px',
            padding: '5px',
          }}
        >
          {pannelLayout.cardData.map((eachData, i) => (
            <Slide
              direction="right"
              in={isOpen}
              // container={containerRef.current}
              timeout={800 + i * 100}
            >
              <Card
                key={i}
                // style={{
                //   boxShadow:
                //     '0 12px 30px 0 rgba(0, 0, 0, 0.09), 0 0 18px 0 rgba(0, 0, 0, 0.08) !important',
                // marginBottom: '10px',
                // marginTop: '10px',
                // marginLeft: '10px',
                // marginRight: '10px',
                // }}
                sx={{
                  boxShadow: '0 12px 30px 0 rgba(0, 0, 0, 0.09), 0 0 18px 0 rgba(0, 0, 0, 0.08)',
                  marginBottom: '10px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  backgroundColor: !eachData?.isReadByUser ? 'wheat' : 'white',
                }}
              >
                <>
                  <RightPanelLayoutCardContent isNew={eachData?.isReadByUser}>
                    <Grid container>
                      <Grid item xs={1.5}>
                        {pannelLayout.renderCardIcon({
                          style: { color: !eachData?.isReadByUser ? 'red' : 'black' },
                        })}
                        {/* <NotificationsNoneIcon /> */}
                      </Grid>
                      <Grid item xs={10.5}>
                        {eachData.content.map((eachContent) => {
                          return (
                            <>
                              <Typography variant="h1" fontSize="12px">
                                {eachContent.label}
                              </Typography>
                              <Typography variant="h6" fontSize="12px">
                                {eachContent.value}
                              </Typography>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </RightPanelLayoutCardContent>
                  {pannelLayout.isActionRequired && (
                    <>
                      {' '}
                      <CardActions
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          paddingBottom: 0,
                          marginBottom: 0,
                          // height: '1.5rem',
                        }}
                      >
                        <div
                          onClick={() => {
                            setSideBar({ isPanel: '', isOpen: false });
                            history.push(eachData.url);
                            if (eachData.notifyID) notifSetRead([Number(eachData.notifyID)]);
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 0,
                              paddingRight: '16px',
                              paddingBottom: '16px',
                              border: '2px',
                            }}
                          >
                            <VisibilityIcon
                              fontSize="small"
                              style={{ marginBottom: 0, color: '#2878ec' }}
                            />

                            <Typography variant="subtitle2" style={{ fontSize: '10px' }}>
                              View
                            </Typography>
                          </div>
                        </div>
                      </CardActions>
                    </>
                  )}
                </>
              </Card>
            </Slide>
          ))}
        </div>
      </div>
    </>
  );
}

export default RightPanelLayout;
