import { useState } from 'react';
import { AppJwtType } from 'Auth';
import { getAppJWTSync, saveAppJWT } from './AppToken';
// keep as JS

interface UseAppTokenType {
  appJWT: AppJwtType;
}

export default function useAppToken() {
  const getJWTContent = () => {
    return getAppJWTSync();
  };

  const [appJWT, setJwtContent] = useState(getJWTContent());

  const setAppJWT = (jwt) => {
    saveAppJWT(jwt);
    setJwtContent(jwt);
  };

  return {
    setAppJWT,
    appJWT,
  } as UseAppTokenType;
}
