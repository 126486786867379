import axios from 'axios';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { BACKEND_URL } from '../const';
import { getBearerTokenSync } from '../app/AppToken';

const getW3client = () => {
  const bearerToken = getBearerTokenSync();
  return new W3CWebSocket(`${BACKEND_URL.websocket}/notifications?bearerToken=${bearerToken}`);
};

const notifGetCompact = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/notifications/projectnotification`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

const notifSetRead = async (listIds: any[]): Promise<any> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/notifications/isread`, listIds);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

const notifSetReadByCat = async (
  category: 'owning' | 'workload' | 'userManagement'
): Promise<any> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/notifications/isread/${category}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};
const notifByProjectId = async (): Promise<{ output: any }> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/notifications/projectnotification`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

const notifByInbox = async (): Promise<{ output: any }> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/notifications/projectnotificationinbox`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
    throw new Error(error);
  }
};

const getProjectRequirements = async (
  projectId: any,
  user_id: any,
  file_name: any,
  workload_id: any
): Promise<any> => {
  try {
    let querystring = `project_id=${projectId}&user_id=${user_id}`;
    if (file_name) querystring = `${querystring}&filename=${file_name}`;
    if (workload_id) querystring = `${querystring}&workload_id=${workload_id}`;
    const response = await axios.get(`${BACKEND_URL.root}/kpi/requirements?${querystring}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
export {
  notifGetCompact,
  notifSetRead,
  notifSetReadByCat,
  notifByProjectId,
  getProjectRequirements,
  notifByInbox,
};

export default getW3client;
