import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Item from './components/Item';
import useHistory from './Hooks/useHistory';
import { ZipUrls } from '../../tools/downloadSourceFile';
import CreateZip from '../../tools/CreateZip';

const History = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [optionSelected, setOptionSelected] = React.useState(false);
  const { modelService, workload } = props;
  // console.log('modelService', props);
  const selectedWorkload = React.useRef([]);
  const [ziptLoading, setZipLoading] = React.useState(false);
  const { loading, rows, totalCount, getWorkLoadAll, deleteWorkLoad } = useHistory({
    serviceName: modelService,
    workload,
    currentPage,
  });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleCheckboxChange = (event, data) => {
    const { id = 0 } = data ?? { id: 0 };
    if (event.target.checked && id) {
      selectedWorkload.current.push(id);
    } else if (selectedWorkload.current.includes(id) && id) {
      selectedWorkload.current = selectedWorkload.current.filter((item) => item !== id);
    }

    if (selectedWorkload.current.length > 0 && !optionSelected) {
      setOptionSelected(true);
    }
    if (selectedWorkload.current.length === 0 && optionSelected) {
      setOptionSelected(false);
    }
  };

  const GenerateZipFolder = () => {
    if (selectedWorkload.current.length === 0) {
      // setSnack('Please select the files', 'error');
      return;
    }
    setZipLoading(true);
    const row = rows.filter((data) => selectedWorkload.current.includes(data.id));
    const urls = row?.map((data: any) => {
      const querystring = `files=${'translate_file'}`;
      const { name } = workload.serviceFor;
      if (name === 'user') {
        return ZipUrls(
          `/download/userresources/${data?.id}`,
          querystring,
          data.results_data && data.results_data?.length > 0
            ? data.results_data[0]?.data?.output?.filename
            : ''
        );
      }
      return ZipUrls(
        `/download/resources/${data?.id}`,
        querystring,
        data.results_data && data.results_data?.length > 0
          ? data.results_data[0]?.data?.output?.filename
          : ''
      );
    });

    const zipFolderName = 'DownloadedFiles'.concat(Math.random().toString(36).substring(7));
    CreateZip(urls, zipFolderName)
      .then((res) => {
        // setSnack('Downloaded Zip Successfully', 'success');
        // setZipLoading(false);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setZipLoading(false);
      });
  };

  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
        <Button
          startIcon={<DeleteIcon />}
          disabled={!optionSelected}
          onClick={() => {
            const { name } = workload.serviceFor;
            deleteWorkLoad(selectedWorkload.current, name);
          }}
          style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}
        >
          Delete
        </Button>
        <div>
          {modelService === 'Document Translator' && (
            <Button
              onClick={GenerateZipFolder}
              startIcon={ziptLoading ? <CircularProgress size={20} /> : <FolderZipIcon />}
              disabled={!optionSelected || ziptLoading}
              style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}
            >
              Create Zip
            </Button>
          )}
          <Button
            style={{
              backgroundColor: '#002a45',
              color: 'rgb(255, 255, 255)',
              marginLeft: '5px',
            }}
            startIcon={<RefreshIcon />}
            onClick={() => {
              getWorkLoadAll(currentPage, modelService);
            }}
          >
            Refresh
          </Button>
        </div>
      </Box>
      {loading && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
          }}
        >
          <CircularProgress style={{ color: '#002a45' }} size={20} />
        </Box>
      )}
      {!loading &&
        (rows.length > 0 ? (
          rows.map((item, index) => {
            return (
              <>
                <Item
                  {...props}
                  key={index}
                  data={item}
                  workload={workload}
                  selectedWorkload={selectedWorkload}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </>
            );
          })
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: '#d9534f', fontWeight: 'bold', textAlign: 'center' }}
            >
              Oops! No item to display :(
            </Typography>
          </Box>
        ))}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          padding: '0.3rem',
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / 10)}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

export default History;
