/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ModuleLayout from '../../../../layouts/ModuleLayout';
import ModuleAnalysis from '../ModuleAnalysis';
import DocTranslatorHeader from '../core/DocTranslatorHeader';
import ProjectContext from '../../../Project/ProjectContext';
import useUpload from '../../../../layouts/Hooks/useUpload';
import DocTranslatorAnalysis from '../DocTranslatorAnalysis';
import UserFileManager from '../../../UserWorkSpace/FileManger/UserFileManager';
import GlossaryFiles from '../../../UserWorkSpace/FileManger/GlossaryFiles';
import { supportedfiles } from '../../../../const';

const DocumentTranslator = (props) => {
  const [inputFileOpen, setInputFileOpen] = React.useState(false);
  const [openGlossary, setOpenGlossary] = React.useState({ open: false, indexes: [] });
  const [isAnalysis, setIsAnalysis] = React.useState(false);
  const [selectionFile, setSelectionFile] = React.useState(null);
  const [gloablGlossary, setGlobalGlossary] = React.useState([]);
  const [docTranslation, setDocTranslation] = React.useState({
    fromLang: 'French-FR',
    toLang: 'French-FR',
    model: 'DeepL',
    glossary: null,
  });

  const { model } = docTranslation;

  const {
    enableDescription,
    renderDescription,
    numberOfFilesSupport,
    fileTypeSupport,
    setSelectedChildIndex,
    renderFiles,
    select,
    workload,
    setWorkload,
  } = props;
  const { projectId } = useParams();
  const { getProject, setProjects } = useContext(ProjectContext);
  const project = getProject(Number(projectId)) || {};

  const actualFilSupported = useMemo(() => {
    if (model === 'Microsoft') {
      return supportedfiles;
    }
    return fileTypeSupport;
  }, [model, fileTypeSupport]);
  const { loading, handleChangeInDragefile, handleUploadFile, FindeFileDetails } = useUpload({
    project,
    projectId,
    fileTypeSupport: actualFilSupported,
    doScopeSetting: false,
    setProjects,
    files: project?.folders ? project?.folders[0]?.resources : [], // default file upload to foler 1st
  });

  // console.log('fileTypeSupport DocumentTranslatorProps', fileTypeSupport);

  const desciptionProps = {
    docTranslation,
    setDocTranslation,
  };

  const DocumentTranslatorProps = useMemo(
    () => ({
      state: {
        inputFileOpen,
        setInputFileOpen,
        isAnalysis,
        setIsAnalysis,
        selectionFile,
        setSelectionFile,
        setSelectedChildIndex,
        loading,
        workload,
        setWorkload,
        openGlossary,
        setOpenGlossary,
      },
      enableDescription,
      enableHeader: false,
      select,
      renderDescription: () => {
        return <>{renderDescription({ desciptionProps, renderTranslation: true })}</>;
      },
      renderFiles,
      serviceName: 'Document Translator',
      serviceFor: { name: 'project', id: projectId },
      numberOfFilesSupport,
      fileTypeSupport: actualFilSupported,
      disableScopeSettings: true,
      handleChangeInDragefile,
      handleUploadFile,
      FindeFileDetails,
      renderGlossary: (props) => {
        return (
          <>
            <GlossaryFiles {...props} />
          </>
        );
      },
      renderHeader: () => {
        return (
          <>
            <DocTranslatorHeader docTranslation={docTranslation} />
          </>
        );
      },
      renderAnalysis: (props) => {
        // console.log('props', props);
        return (
          <>
            <DocTranslatorAnalysis
              {...props}
              gloablGlossary={gloablGlossary}
              docTranslation={docTranslation}
              setDocTranslation={setDocTranslation}
              actualFilSupported={actualFilSupported}
            />
          </>
        );
      },
    }),
    [
      inputFileOpen,
      isAnalysis,
      selectionFile,
      enableDescription,
      renderDescription,
      loading,
      workload,
      gloablGlossary,
      docTranslation,
      actualFilSupported,
    ]
  );

  return (
    <>
      <ModuleLayout moduleData={DocumentTranslatorProps} />{' '}
    </>
  );
};

export default memo(DocumentTranslator);
