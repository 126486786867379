import { styled } from '@mui/material/styles';

const ProjectPropertiesContainer = styled('div')(({ theme }) => ({
  '&.ProjectProperties': {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    '& .bloc_ProjectProperties': {
      minHeight: 250,
      borderRadius: 10,
      border: 'solid 1px',
      borderColor: theme.palette.primary.light,
      margin: 10,
    },
    '& .error_ProjectProperties': {
      color: theme.palette.error.main,
    },
    '& .heading_ProjectProperties': {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      textAlign: 'center',
      marginBottom: 10,
      color: 'white',
    },
    '& .dirty_ProjectProperties': {
      backgroundColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 3,
      color: 'white',
      padding: 1,
    },
    '& .paper_ProjectProperties': {
      padding: 10,
      color: '#002A45',
    },
    '& .input_ProjectProperties': {
      marginBottom: '15px',
    },
    '& .button_ProjectProperties': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    '& .paper2_ProjectProperties': {
      color: theme.palette.primary.main,
      position: 'absolute',
      minWidth: 400,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0, 4, 3),
      textAlign: 'center',
      borderRadius: '10px',
      opacity: 1,
      marginTop: 90,
      paddingBottom: 10,
    },
    '& .inputField_ProjectProperties': {
      fontSize: '0.875rem',
      height: '5vh',
    },
    '& .formControlMulti_ProjectProperties': {
      width: '100%',
    },
    '& .input2_ProjectProperties': {
      marginBottom: '15px',
      background: '#0000000B 0% 0% no-repeat padding-box',
      borderRadius: '4px 4px 0px 0px',
    },
    '& .button2_ProjectProperties': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    '& .projectName_ProjectProperties': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .secondPart_ProjectProperties': {
      border: '1px solid #002A45',
      padding: 10,
      borderRadius: 5,
      marginTop: 10,
    },
    '& .entity_ProjectProperties': {
      textAlign: 'left',
      padding: 5,
      color: '#002A45',
    },
    '& .projectNameStyle_ProjectProperties': {
      background: '#F4F4F4 0% 0% no-repeat padding-box',
      borderRadius: '5px',
    },
    '& .projectInput_ProjectProperties': {
      marginTop: 0,
      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
    },
    '& .type_ProjectProperties': {
      textAlign: 'left',
      padding: 10,
    },
    '& .textColor_ProjectProperties': {
      '& .MuiTextField-root': {
        color: 'red',
      },
    },
  },
}));

const ProjectPropertiesHidden = styled('div')(({ theme }) => ({
  '&.projecthiden': {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),

    '& .bloc_projecthiden': {
      minHeight: 250,
      borderRadius: 10,
      border: 'solid 1px',
      borderColor: theme.palette.primary.light,
      margin: 10,
    },

    '& .error_projecthiden': {
      color: theme.palette.error.main,
    },

    '& .heading_projecthiden': {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      textAlign: 'center',
      marginBottom: 10,
      color: 'white',
    },

    '& .dirty_projecthiden': {
      backgroundColor: theme.palette.secondary.light,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 3,
      color: 'white',
      padding: 1,
    },

    '& .paper_projecthiden': {
      padding: 10,
      color: '#002A45',
    },

    '& .input_projecthiden': {
      marginBottom: '15px',
    },

    '& .button_projecthiden': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },

    '& .paper2_projecthiden': {
      color: theme.palette.primary.main,
      position: 'absolute',
      minWidth: 400,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0, 4, 3),
      textAlign: 'center',
      borderRadius: '10px',
      opacity: 1,
      marginTop: 90,
      paddingBottom: 10,
    },

    '& .inputField_projecthiden': {
      fontSize: '0.875rem',
      height: '5vh',
    },

    '& .formControlMulti_projecthiden': {
      width: '100%',
    },

    '& .input2_projecthiden': {
      marginBottom: '15px',
      background: '#0000000B 0% 0% no-repeat padding-box',
      borderRadius: '4px 4px 0px 0px',
    },

    '& .button2_projecthiden': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },

    '& .projectName_projecthiden': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .secondPart_projecthiden': {
      border: '1px solid #002A45',
      padding: 10,
      borderRadius: 5,
    },

    '& .entity_projecthiden': {
      textAlign: 'left',
      padding: 5,
      color: '#002A45',
    },

    '& .projectNameStyle_projecthiden': {
      background: '#F4F4F4 0% 0% no-repeat padding-box',
      borderRadius: '5px',
    },

    '& .projectInput_projecthiden': {
      marginTop: 0,
      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
    },

    '& .type_projecthiden': {
      textAlign: 'left',
      padding: 10,
    },

    '& .textColor_projecthiden': {
      '& .MuiTextField-root': {
        color: 'red',
      },
    },
  },
}));

export { ProjectPropertiesHidden };
export default ProjectPropertiesContainer;
