import axios from './axios';
import { BACKEND_URL } from '../const';
import { BlobFile } from '../react-app-env';

export interface FolderUpdate {
  name?: string;
  id?: number;
  project_id?: number;
  folder_id?: number;
  resources?: any[];
}

const folderCreate = async (body: FolderUpdate): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/projects/${body.project_id}/folders`, {
      name: body.name,
      user_id: 1,
      project_id: body.project_id,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const TestGenfolderCreate = async (user_id: number, folderName: string): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/testcase/${user_id}/${folderName}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const TestGenCreate = async (
  user_id: number,
  folderId: string,
  testCaseName: string,
  body: any
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/testcase/create/${user_id}/${folderId}/${testCaseName}`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const getAllTestGenFolder = async (user_id: number) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/testcase/get_folder/${user_id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const TestGenfolderDelete = async (userId: number, folderId: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL.root}/testcase/delete_folders/${userId}/${folderId}`
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const TestGenfolderChildDelete = async (userId: number, folderChildId: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL.root}/testcase/delete_items/${userId}/${folderChildId}`
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const TestGenfolderRename = async (
  userId: number,
  folderId: number,
  folderName: string
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/testcase/folder/${userId}/${folderId}/${folderName}`
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const TestGenfolderChildRename = async (
  userId: number,
  testCaseId: number,
  testCaseName: string
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/testcase/test_name/${userId}/${testCaseId}/${testCaseName}`
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const folderSave = async (body: FolderUpdate): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${body.project_id}/folders/${body.id}`,
      {
        name: body.name,
      }
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const folderRename = async (
  projectId: number,
  folderId: number,
  folderName: string
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/projects/${projectId}/folders/${folderId}/rename`,
      folderName.trim(),
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getAdminDefaultGlossary = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL.adminUploadGlossaryDoc}/get`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const folderDelete = async (projectId: number, folderId: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL.root}/projects/${projectId}/folders/${folderId}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const resourcesDelete = async (
  projectId: number,
  folderId: number,
  resources: BlobFile[]
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${projectId}/folders/${folderId}/resources/delete`,
      resources
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const userResourcesDelete = async (folderId: number, resources: BlobFile[]): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/users_folder/${folderId}/resources/delete`,
      resources
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const uploadResources = async (body: FolderUpdate, files: any[]): Promise<any> => {
  try {
    const data = new FormData();
    for (const file of files) {
      data.append('files', file);
    }
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${body.project_id}/folders/${body.folder_id}/resources`,
      {
        name: body.name,
        project_id: body.project_id,
      }
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const adminFolderDelete = async (folderId: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL.root}/admin_default_glossary/folders/${folderId}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
export {
  folderSave,
  folderCreate,
  folderDelete,
  getAdminDefaultGlossary,
  folderRename,
  resourcesDelete,
  uploadResources,
  TestGenfolderCreate,
  getAllTestGenFolder,
  TestGenCreate,
  TestGenfolderRename,
  TestGenfolderDelete,
  TestGenfolderChildRename,
  TestGenfolderChildDelete,
  userResourcesDelete,
  adminFolderDelete,
};
