/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,

  // Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { styled } from '@mui/material/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MultiSelect from 'react-multi-select-component';

import { getResultsByWorkload, userWorkloadGetById } from '../../../../../../api/workloads';

import TStyles, {
  tableAllocatorComponent,
  toolbarComponent,
} from '../../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../../components/Datagrid/ToolBarPlugin';
import Loading from '../../../../../../components/Core/Loading';
import MultiAllocTableComp from '../../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../../components/Core/NavigationGotoNew';
import WorkSpaceContext from '../../../../WorkspceContext';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

type PayloadText2Dataset = {
  Entity: string;
  Entity_type: string;
};
type PayloadText2Dataset1 = {
  Entity: string;
  Entity_type: string;
};
type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
  id_service?: string;
};
const useStyles = makeStyles((theme) => ({
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px', // Set the width of the scrollbar
    },
  },
  cardStyle: {
    paddingBottom: 0,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 5,
    },
  },
  pagingPanelContainer: {
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
    },
  },
}));
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    // maxWidth: 1500,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

const Highlighter = ({ children, highlight }) => {
  if (!highlight) return children;
  const regexp = new RegExp(highlight, 'gi');
  const matches = children.match(regexp);
  const parts = children.split(new RegExp(`${highlight?.replace()}`, 'gi'));

  for (let i = 0; i < parts.length; i += 1) {
    if (i !== parts.length - 1) {
      let match = matches[i];
      // While the next part is an empty string, merge the corresponding match with the current
      // match into a single <span/> to avoid consequent spans with nothing between them.
      while (parts[i + 1] === '') {
        match += matches[(i += 1)];
      }

      parts[i] = (
        <React.Fragment key={i}>
          {parts[i]}
          <span className="highlighted">{match}</span>
        </React.Fragment>
      );
    }
  }
  return <div className="highlighter">{parts}</div>;
};

function ExtractTopics({ id: workloadId, files, setFiles, id_service }: Props) {
  const tClasses = useStyles();
  const { setSnack } = useContext(WorkSpaceContext);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const exporterRef = useRef(null);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };
  // const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);

  // to control the loader and display data full Grid
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  // const { setSnack } = useContext(Module1Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [filterEntities, setFilterEntities] = useState<any[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<any>([]);

  const [filterEntitiesType, setFilterEntitiesType] = useState<any[]>([]);
  const [selectedEntitiesType, setSelectedEntitiesType] = useState([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('pre-line');
  const [filter, setFilter] = useState<any>([]);
  const [filterEntity, setFilterEntity] = useState<any>([]);
  const [payload1, setPayload1] = useState<any>();
  const [resourcesAre, setResourcesAre] = useState<any>();
  const [inputValue, setInputValue] = useState('');
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  // const [pageSizes] = useState([5, 10, 20, 50]);
  const [pageSize, setPageSize] = useState(5);
  const [pageSize2, setPageSize2] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [col, setCol] = useState('');
  const inputHandler = (event: any) => setInputValue(event.target.value);
  const [columnWidths, setColumnWidths] = useState([] as any);

  const handleSetRowsColumns = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'i18n') {
        myCols.push({
          name: key,
          title: key.toUpperCase(),
        });
      }
      return key;
    });
    const resizeColumns = myCols.map((c, i) => {
      // console.log(i);
      if (i === 0) {
        return {
          columnName: c.name,
          width: Math.ceil(400 / myCols.length),
        };
      }
      if (i === 1) {
        return {
          columnName: c.name,
          width: Math.ceil(400 / myCols.length),
        };
      }
      return {
        columnName: c.name,
        width: Math.ceil(2300 / myCols.length),
      };
    });
    setColumnWidths(resizeColumns);
    setColumnsAre(myCols);
    setCol(myCols[2].name);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
    setCurrentPage2(rel);
  };

  // console.log(workloadId);

  useEffect(() => {
    if (workloadId === undefined) {
      return;
    }
    setLoading(true);
    // setLoadingResult(true);
    getResultsByWorkload(workloadId, id_service)
      .then((workload: WorkloadBase) => {
        setResourcesAre(workload?.resources);
        setMessageToDisplay(workload?.status?.errors[0]?.msg);
        setDisplay(true);
        setLoading(false);
        setPayload1(workload.results_data[0].data);
        const arr: any = [];
        const arr1: any = [];
        JSON.parse(workload.results_data[0].data.output).filter((row: PayloadText2Dataset) =>
          arr.push(row.Entity_type)
        );
        // Entity multiFilter
        const ent: any = [];
        const entType: any = [];
        JSON.parse(workload.results_data[0].data.output).filter((row: PayloadText2Dataset) =>
          ent.push({
            label: row.Entity,
            value: row.Entity,
          })
        );

        setFilterEntities(ent.filter((v, i, a) => a.indexOf(v) === i));
        // Entity multiFilter End

        JSON.parse(workload.results_data[0].data.output).filter((row: PayloadText2Dataset1) =>
          arr1.push(row.Entity)
        );
        setFilter(Array.from(new Set(arr)));
        const r = Array.from(new Set(arr));
        const enttype: any = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < r.length; i++) {
          enttype.push({
            label: r[i],
            value: r[i],
          });
        }
        setFilterEntitiesType(enttype);
        setFilterEntity(Array.from(new Set(arr1)));
        handleSetRowsColumns(JSON.parse(workload.results_data[0].data.output));
      })
      .catch(() => {
        setLoading(false);
        setSnack(messageToDisplay, 'error');
      });
  }, [workloadId]);

  function onChangeEntity(option: any) {
    setSelectedEntities(option);
    setSelectedEntitiesType([]);
    const selectedArray = option.map((obj: any, i: any) => option[i].value);
    if (selectedArray.length === 0) {
      handleSetRowsColumns(JSON.parse(payload1.output));
    } else {
      handleSetRowsColumns(
        JSON.parse(payload1.output).filter((row: PayloadText2Dataset) =>
          selectedArray.includes(row.Entity)
        )
      );
    }
  }
  function onChangeEntityType(option: any) {
    setSelectedEntitiesType(option);
    setSelectedEntities([]);
    const selectedArray = option.map((obj: any, i: any) => option[i].value);
    // console.log(selectedArray);

    if (selectedArray.length === 0) {
      handleSetRowsColumns(JSON.parse(payload1.output));
    } else {
      handleSetRowsColumns(
        JSON.parse(payload1.output).filter((row: PayloadText2Dataset) =>
          selectedArray.includes(row.Entity_type)
        )
      );
    }
  }

  const renderHighlighter = ({ row, value }) => {
    const { Entity } = row;

    return (
      <>
        <Highlighter highlight={Entity}>{value}</Highlighter>
      </>
    );
  };
  const tableHeaderComponents: React.FunctionComponent<Table.RowProps> = (props) => {
    return (
      <Table.TableHead
        {...props}
        style={{
          position: 'unset',
          top: 0,
          zIndex: 400,
        }}
      />
    );
  };

  return (
    <>
      {loading && <Loading />}
      {display && (
        <>
          <Paper style={{ color: '#002A45', border: '1px solid #002A45' }}>
            <Card className={tClasses.cardStyle}>
              <CardContent>
                <Grid container>
                  <Grid xs={6}>
                    <Grid container>
                      <Grid xs={1}>
                        <SettingsOutlinedIcon style={{ color: '#002A45' }} />
                      </Grid>
                      <Grid xs={11}>
                        <b style={{ fontSize: 16, marginLeft: -5 }}>Details</b>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Paper
              style={{
                borderTop: '1px solid #002A45',
                borderBottom: '1px solid #002A45',
                // borderWidth: '0.6px',
                backgroundColor: '#EEF1F9',
                borderRadius: 0,
              }}
            >
              <div style={{ marginLeft: '5px' }}>
                <h3>Resource details: {resourcesAre?.nameOfCapture}</h3>
                {resourcesAre?.BlobFiles?.map((data: any) => {
                  return (
                    <p>
                      {data?.Blob && (
                        <>
                          <b>File Name:</b>&nbsp;
                          <span>{data.Blob}&nbsp;&nbsp;</span>
                        </>
                      )}
                      {data?.SheetName && (
                        <>
                          <b>Sheet Name:</b>&nbsp;
                          <span>{data.SheetName}&nbsp;&nbsp;</span>
                        </>
                      )}
                      {data?.TargetColumn && (
                        <>
                          <b>Target Column:</b>&nbsp;
                          <span>{data.TargetColumn}&nbsp;&nbsp;</span>
                        </>
                      )}
                    </p>
                  );
                })}
              </div>
            </Paper>
            <br />
            <Grid container style={{ padding: 2 }}>
              {filter && (
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <b>Select Entity Type: </b>
                    </Grid>
                    <Grid item xs={9}>
                      <MultiSelect
                        options={filterEntitiesType}
                        value={selectedEntitiesType}
                        onChange={onChangeEntityType}
                        labelledBy="EntitiesType"
                        // style={{ width: '50%' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {filter && (
                <Grid item xs={6}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={2}>
                      <b>&nbsp; Select Entity: </b>
                    </Grid>
                    <Grid item xs={10}>
                      <MultiSelect
                        // isOpen={true}
                        options={filterEntities}
                        value={selectedEntities}
                        onChange={onChangeEntity}
                        labelledBy="Entities"
                        // style={{ width: '50%' }}
                        displayValue="entities"
                        className="entities"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <br />
          </Paper>
          <div style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <ExpressGrid rows={rows} columns={columnsAre}>
              <DataTypeProvider
                for={['Entity']}
                formatterComponent={({ value }) => (
                  <CustomWidthTooltip
                    // placement="bottom-start"
                    arrow
                    title={
                      <>
                        <Card
                          style={{
                            background: 'while',
                            color: '#002A45',
                            fontWeight: 'bold',
                            margin: 0,
                            padding: 5,
                          }}
                        >
                          {value}
                        </Card>
                      </>
                    }
                  >
                    <span
                      title={value}
                      style={{ whiteSpace: `${newLine}`, fontWeight: 'bold', color: '#002A45' }}
                    >
                      {value}
                    </span>
                  </CustomWidthTooltip>
                )}
              />
              <DataTypeProvider
                for={['Entity_type']}
                formatterComponent={({ value }) => (
                  <CustomWidthTooltip
                    arrow
                    title={
                      <>
                        <Card
                          style={{
                            background: 'while',
                            color: '#002A45',
                            fontWeight: 'bold',
                            margin: 0,
                            padding: 5,
                          }}
                        >
                          {value}
                        </Card>
                      </>
                    }
                  >
                    <span
                      title={value}
                      style={{ whiteSpace: `${newLine}`, fontWeight: 'bold', color: '#002A45' }}
                    >
                      {value}
                    </span>
                  </CustomWidthTooltip>
                )}
              />
              <DataTypeProvider
                for={[
                  ...columnNamesAre.map((data, i) => {
                    if (i >= 2) {
                      return data;
                    }
                    return null;
                  }),
                ]}
                formatterComponent={({ value }) => (
                  <CustomWidthTooltip
                    arrow
                    title={
                      <>
                        <Card
                          style={{
                            background: 'while',
                            color: '#002A45',
                            fontWeight: 'bold',
                            margin: 0,
                            padding: 5,
                            overflowY: 'scroll',
                            maxHeight: '25vh',
                            // padding: 5,
                          }}
                          className={tClasses.customScrollbar}
                        >
                          {value?.replace(/-{28,}/g, '')}
                        </Card>
                      </>
                    }
                  >
                    <Card
                      // title={value.replace(/-{28,}/g, '')}
                      style={{
                        whiteSpace: `${newLine}`,
                        fontWeight: 'bold',
                        color: '#002A45',
                        overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                        maxHeight: '15vh',
                        padding: 5,
                      }}
                      className={tClasses.customScrollbar}
                    >
                      {value?.replace(/-{28,}/g, '')}
                    </Card>
                  </CustomWidthTooltip>
                )}
              />

              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage2}
                onCurrentPageChange={setCurrentPage2}
                pageSize={pageSize2}
                onPageSizeChange={setPageSize2}
              />
              <IntegratedPaging />
              <Table
                tableComponent={MultiAllocTableComp}
                containerComponent={tableAllocatorComponent}
                headComponent={tableHeaderComponents}
                columnExtensions={[
                  ...columnNamesAre.map((c, i) => {
                    return {
                      columnName: c.name,
                      width: 'auto',
                      wordWrapEnabled: toggleHeight,
                    };
                  }),
                ]}
              />

              <TableColumnResizing
                onColumnWidthsChange={setColumnWidths}
                columnWidths={columnWidths}
                resizingMode="nextColumn"
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar rootComponent={toolbarComponent} />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />

              <ToolBarPlugin
                name="NewLine"
                onClick={() => {
                  return newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine('');
                }}
              />
              {/* <PagingPanel pageSizes={pageSizes} /> */}
              <PagingPanel
                pageSizes={pageSizes}
                containerComponent={(props) => (
                  <>
                    <PagingPanel.Container {...props} className={tClasses.pagingPanelContainer} />
                  </>
                )}
              />
            </ExpressGrid>
            <NavigationGotoNew
              setPageSize={setPageSize}
              fileLength={rows.length}
              pageSize={pageSize2}
              dis={dis}
              setTemPage={setTemPage}
              handlePageNum={handlePageNum}
              currentPage={currentPage2}
              setCurrentPage={setCurrentPage2}
            />
            {/* <NavigationGoto
              setPageSize={setPageSize}
              fileLength={rows.length}
              pageSize={pageSize2}
              dis={dis}
              setTemPage={setTemPage}
              handlePageNum={handlePageNum}
              currentPage={currentPage2}
              setCurrentPage={setCurrentPage2}
            /> */}
            <GridExporter ref={exporterRef} rows={rows} columns={columnsAre} onSave={onSave} />
          </div>
        </>
      )}
    </>
  );
}

export default ExtractTopics;
