import React, { memo, useContext, useEffect, useMemo } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import useAppToken from '../../app/useAppToken';
import icaptureLogo from '../../assets/m1_blue.png';
import m2Logo from '../../assets/m2_blue.png';
import m3Logo from '../../assets/m3_blue.png';
import m4Logo from '../../assets/m4_blue.png';
import m5Logo from '../../assets/m5_blue.png';
import LoadingApp from '../../components/Core/LoadingApp';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import ModulesBar from '../../components/Layout/ModulesBar';
import KpiRouter from '../KPI/KpiRouter';
import Module1Router from '../Module1/Module1Router';
import Module2Router from '../Module2/Module2Router';
import Module2Viewer from '../Module2/Module2Viewer';
import Module3Router from '../Module3/Module3Router';
import ProjectMenuSettings from '../Module3/components/ProjectMenuSettings';
import Module4Router from '../Module4/Module4Router';
import Module5Router from '../Module5/Module5Router';
import Assessment from './Assessment/Assessment';
import ProjectActivityContainer from './ProjectActivityContainer';
import ProjectAnalysisContainer from './ProjectAnalysisContainer';
import ProjectBreakdownsContainer from './ProjectBreakdownsContainer';
import ProjectConfigContainer from './ProjectConfigContainer';
import ProjectContext from './ProjectContext';
import ProjectDashboardContainer from './ProjectDashboardContainer';
import ProjectReq from './ProjectReq';
import ProjectInbox from './components/ProjectInbox';
import ProjectActions from './components/ProjectUsers';
import Module6Router from '../Module6/Module6Router';
import theme from '../Home/homeTheme';
import ProjectComponents from './ProjectContainer/ProjectComponents';
import { getProjectbyID } from '../../api/projects';
import RenderComponents from './RenderComponents';

const BreadcrumbsContainer = styled.div`
  background-color: #eef1f9;
  padding: 0.8rem;
`;

function ProjectRouter() {
  const contextValue = useContext(ProjectContext);
  const memomizedContextValue = useMemo(() => contextValue, [contextValue]);
  const {
    projects_lite,
    projectBread,
    completedProjects,
    setProjects,
    getProjectDetails,
    projects,
  } = memomizedContextValue;
  // console.log(memomizedContextValue);
  const { appJWT } = useAppToken();

  if (!completedProjects) {
    return (
      <>
        <LoadingApp />
        {/* <h2 style={{ textAlign: 'center' }}>Loading projects ...</h2>
        <Loading /> */}
      </>
    );
  }
  if (projects_lite.length > 0) {
    // If page is not in loading state, display page.
    return (
      <>
        <Switch>
          {/* all routes for module3 */}
          <Route
            path="/project/:projectId/module3/:moduleroute"
            render={() => {
              return (
                <RenderComponents
                  renderComponent={() => (
                    <>
                      <>
                        <BreadcrumbsContainer>
                          <ProjectBreadCrumbs
                            projectId={projectBread}
                            nodes={[
                              {
                                label: 'Verification & Quality',
                                to: '#',
                                icon: (
                                  <img
                                    src={m3Logo}
                                    alt="Verification & Quality"
                                    title="Verification & Quality"
                                  />
                                ),
                              },
                            ]}
                          />
                        </BreadcrumbsContainer>
                        <Module3Router /> {/* </div> */}
                      </>
                    </>
                  )}
                />
              );
            }}
          />
          {/* <Route
            exact
            path="/projects/:projectId"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs projectId={projectBread} />
                  </BreadcrumbsContainer>
                  <ProjectActivityContainer />{' '}
                </>
              );
            }}
          /> */}
          {/* <Route
            exact
            path="/projects/:projectId/activity"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs projectId={projectBread} />
                  </BreadcrumbsContainer>
                  <ProjectActivityContainer />{' '}
                </>
              );
            }}
          /> */}
          {/* <Route
            exact
            path="/projects/:projectId/settings"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <ProjectMenuSettings />{' '}
                </>
              );
            }}
          /> */}
          {/* <Route
            exact
            path="/projects"
            render={() => {
              return <ProjectDashboardContainer projects={projects_lite} />;
            }}
          /> */}
          {/* <Route
            exact
            path="/module2"
            render={() => {
              return <Module2Viewer />;
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/folders/:folderId"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs projectId={projectBread} folderId={floderBread} />
                  </BreadcrumbsContainer>
                  <ProjectFolder />
                </>
              );
            }}
          /> */}
          <Route
            path="/project/:projectId/module1/:moduleroute/*"
            render={() => {
              return (
                <RenderComponents
                  renderComponent={() => (
                    <>
                      <BreadcrumbsContainer>
                        <ProjectBreadCrumbs
                          projectId={projectBread}
                          nodes={[
                            {
                              label: 'Smart Requirement Magagement',
                              to: `#`,
                              icon: (
                                <img
                                  src={icaptureLogo}
                                  alt="Smart Requirement Magagement"
                                  title="Smart Requirement Magagement"
                                />
                              ),
                            },
                          ]}
                        />
                      </BreadcrumbsContainer>
                      <Module1Router />
                    </>
                  )}
                />
              );
            }}
          />
          {/* <Route
            path="/project/:projectId/(module2|module2-text)/:moduleroute/*"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs
                      projectId={projectBread}
                      nodes={[
                        {
                          label: 'Knowledge & Efficiency',
                          to: `#`,
                          icon: (
                            <img
                              src={m2Logo}
                              alt="Knowledge & Efficiency"
                              title="Knowledge & Efficiency"
                            />
                          ),
                        },
                      ]}
                    />
                  </BreadcrumbsContainer>
                  <Module2Router />
                </>
              );
            }}
          /> */}
          <Route
            path="/project/:projectId/module2"
            render={() => {
              return <RenderComponents renderComponent={() => <Module2Router />} />;
            }}
          />
          <Route
            path="/project/:projectId/module5/:moduleroute"
            render={() => {
              return (
                <RenderComponents
                  renderComponent={() => (
                    <>
                      <BreadcrumbsContainer>
                        <ProjectBreadCrumbs
                          projectId={projectBread}
                          nodes={[
                            {
                              label: 'Safety & CyberSecurity',
                              to: `#`,
                              icon: (
                                <img
                                  src={m5Logo}
                                  alt="Safety & CyberSecurity"
                                  title="Safety & CyberSecurity"
                                />
                              ),
                            },
                          ]}
                        />
                      </BreadcrumbsContainer>
                      <Module5Router />
                    </>
                  )}
                />
              );
            }}
          />
          <Route
            path="/project/:projectId/module6/:moduleroute"
            render={() => {
              return (
                <RenderComponents
                  renderComponent={() => {
                    return (
                      <>
                        <BreadcrumbsContainer>
                          <ProjectBreadCrumbs
                            projectId={projectBread}
                            nodes={[
                              {
                                label: 'Project Management',
                                to: `#`,
                                icon: (
                                  <img
                                    src={m5Logo}
                                    alt="Project Management"
                                    title="Project Management"
                                  />
                                ),
                              },
                            ]}
                          />
                        </BreadcrumbsContainer>
                        <Module6Router />
                      </>
                    );
                  }}
                />
              );
            }}
          />
          {/* <Route
            path="/projects/:projectId/kpi"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs
                      projectId={projectBread}
                      nodes={[
                        {
                          label: 'KPI',
                          to: `#`,
                          // icon: (
                          //   <img src={m3Logo} alt="Verification & Quality" title="Verification & Quality" />
                          // ),
                        },
                      ]}
                    />
                  </BreadcrumbsContainer>
                  <KpiRouter />
                </>
              );
            }}
          /> */}
          <Route
            path="/project/:projectId/module4/:moduleroute"
            render={() => {
              return (
                <RenderComponents
                  renderComponent={() => (
                    <>
                      {/* <ModulesBar /> */}
                      <BreadcrumbsContainer>
                        <ProjectBreadCrumbs
                          projectId={projectBread}
                          nodes={[
                            {
                              label: 'Performance & Maturity',
                              to: `#`,
                              icon: (
                                <img
                                  src={m4Logo}
                                  alt="Performance & Maturity"
                                  title="Performance & Maturity"
                                />
                              ),
                            },
                          ]}
                        />
                      </BreadcrumbsContainer>
                      <Module4Router />
                    </>
                  )}
                />
              );
            }}
          />
          {/* <Route
            path="/projects/:projectId/manage/inbox"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <ProjectInbox />
                </>
              );
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/manage/Assessment"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs
                      projectId={projectBread}
                      nodes={[
                        {
                          label: 'Assessment',
                          to: `#`,
                          icon: <img src={m2Logo} alt="Assessment" title="Assessment" />,
                        },
                      ]}
                    />
                  </BreadcrumbsContainer>
                  <Assessment />
                </>
              );
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/manage/config"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs
                      projectId={projectBread}
                      nodes={[
                        {
                          label: 'Configuration',
                          to: `#`,
                        },
                      ]}
                    />
                  </BreadcrumbsContainer>
                  <ProjectConfigContainer />
                </>
              );
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/manage/users"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs projectId={projectId} />
                  </BreadcrumbsContainer>
                  <ProjectActions />
                </>
              );
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/manage/groups"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs projectId={projectBread} />
                  </BreadcrumbsContainer>
                  <ProjectBreakdownsContainer />
                </>
              );
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/manage/analysis"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs projectId={projectBread} />
                  </BreadcrumbsContainer>
                  <ProjectAnalysisContainer />
                </>
              );
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/manage/requirements"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs
                      projectId={projectBread}
                      nodes={[
                        {
                          label: 'Requirements',
                          to: `#`,
                        },
                      ]}
                    />
                  </BreadcrumbsContainer>
                  <ProjectReq />
                </>
              );
            }}
          /> */}
          {/* <Route
            path="/projects/:projectId/manage/iverifier"
            render={() => {
              return (
                <>
                  <ModulesBar />
                  <BreadcrumbsContainer>
                    <ProjectBreadCrumbs projectId={projectBread} />
                  </BreadcrumbsContainer>
                  <ProjectAnalysisContainer />
                </>
              );
            }}
          /> */}
          <Route
            path="/project/:projectId"
            render={() => {
              return (
                <RenderComponents
                  renderComponent={() => (
                    <>
                      <ThemeProvider theme={theme}>
                        <ProjectComponents />
                      </ThemeProvider>
                    </>
                  )}
                />
              );
            }}
          />
        </Switch>
      </>
    );
  }
  if (appJWT.profile === 'viewer') {
    return (
      <switch>
        <Route
          exact
          path="/module2/:moduleroute"
          render={() => {
            return <Module2Viewer />;
          }}
        />
      </switch>
    );
  }
  return <h3>You don&apos;t have projects</h3>;
}

export default memo(ProjectRouter);
