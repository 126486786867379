/* eslint-disable react/destructuring-assignment */
import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Button,
  CircularProgress,
  Input,
} from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProjectContext from '../../../ProjectContext';
import { getProjectbyID } from '../../../../../api/projects';
import { DocumentIcon, FileDocIcon } from '../../../../../assets/svg';
import Files from './Files';
import { FileBtn, ProjectFolderContainer, FileInputContainer, FileInputFeildEnter } from './styles';
import useUpload from '../../../../../layouts/Hooks/useUpload';
import FolderModel from './FolderModel';
import RenameFolder from './Hooks/RenameFolder';
import useDeletFile from '../../../../../layouts/Hooks/useDeletFile';
import useSuccess from './Hooks/useSuccess';
import { supportedfiles } from '../../../../../const';

const Folders = memo(({ folders, setFiles, setMoveTo, style = {} }) => {
  return (
    <Box style={{ ...style }}>
      {folders?.map((folder, index) => {
        return (
          <Box
            className="eachFolder"
            key={index}
            onClick={() => {
              setFiles((prev) => ({
                ...prev,
                folderId: folder.id,
                files: folder?.resources
                  ? folder?.resources?.map((data) => {
                      return { ...data, folderId: folder.id };
                    })
                  : [],
                folderName: folder.name,
              }));
              setMoveTo('files');
              // setProjectBread(name);
              // setFloderBread(folder.name);
              // history.push(`/project/${projectId}/folders/${folder.id}`);
            }}
          >
            <FolderIcon style={{ width: '2rem', height: '2rem', color: '#006A45' }} />
            <Box className="label">{folder.name}</Box>
          </Box>
        );
      })}
    </Box>
  );
});

const ProjectFolder = (props) => {
  const {
    isChild,
    numberOfFilesSupport,
    handleScopeSettings,
    fileTypeSupport = supportedfiles,
    scopeSetting = true,
    select = 'single',
    setDrawerOpen = () => {},
    setOpenDialog = () => {},
  } = props || false;
  const history = useHistory();
  const [openFolderCreate, setOpenFolderCreate] = useState(false);
  const [successMsg, setSuccessMsg] = useSuccess();
  const [error, setError] = useState('');
  const [filesDetails, setFilesDetails] = useState({
    folderId: null,
    folderName: '',
    files: [],
  });

  const { folderId, folderName } = filesDetails;
  const [moveTo, setMoveTo] = useState('folder');
  const { setProjects, projects, getProject } = useContext(ProjectContext);
  const { projectId } = useParams();
  const [searchfiles, setSearchFiles] = useState('');

  const project = useMemo(() => getProject(Number(projectId)), [projects, projectId]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  }, [error]);

  const actualFiles = useMemo(() => {
    const { files } = filesDetails;
    const temp =
      files
        ?.filter((file: any) => {
          if (fileTypeSupport.length > 0) {
            const ext = file?.filename?.split('.')?.pop() ?? '';
            return fileTypeSupport?.includes(ext);
          }
          return file;
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date)) ?? [];
    if (searchfiles) {
      return temp.filter((file: any) =>
        file.filename.toLowerCase().includes(searchfiles.toLowerCase())
      );
    }
    return temp;
  }, [filesDetails, searchfiles]);

  // console.log('projects', projects);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const { loading: deleteLoading, DeleteFile } = useDeletFile({
    projectId,
    folderId,
    selectedFiles,
    setProjects,
    files: actualFiles,
  });
  const { loading, handleUploadFile } = useUpload({
    project,
    projectId,
    fileTypeSupport,
    doScopeSetting: false,
    folderId,
    files: actualFiles,
    selectedFiles,
    setProjects,
  });
  const inputRef = useRef();

  const folders = useMemo(() => project?.folders ?? [], [project]);

  const [alignment, setAlignment] = React.useState<string | null>('onlyFolders');
  // console.log('folders', folders);
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    if (newAlignment === 'extractAllFiles') {
      const temp =
        folders
          ?.map((folder) => {
            return (
              folder?.resources?.map((data) => {
                return { ...data, folderId: folder.id };
              }) ?? []
            );
          })
          .flat() ?? [];
      setFilesDetails((prev) => ({ ...prev, files: temp, folderId: null }));
      setMoveTo('files');
    } else {
      setFilesDetails((prev) => ({ ...prev, files: [] }));
      setMoveTo('folder');
    }
    setAlignment(newAlignment);
  };

  const deleteFile = async () => {
    try {
      const result = await DeleteFile();
      if (!result?.error) {
        setFilesDetails((prev) => {
          return { ...prev, files: result?.data };
        });
        setSuccessMsg(result?.message);
        setSelectedFiles([]);
      } else {
        setError(result?.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const filehandleChangeFiles = async (event: any) => {
    try {
      const arr = Object.values(event.target.files);
      if (arr.length === 0) {
        return;
      }
      const data = await handleUploadFile(arr);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      // console.log('data', data);
      if (!data?.data.error) {
        setSuccessMsg(data?.message);
        setFilesDetails((prev) => {
          return { ...prev, files: data?.data };
        });
      } else {
        setError(data?.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const UploadFile = () => {
    inputRef.current.click();
  };

  const renderComponent = () => {
    switch (moveTo) {
      case 'folder':
        return (
          <Folders
            folders={folders}
            setFiles={setFilesDetails}
            setMoveTo={setMoveTo}
            style={{ display: 'flex', flexWrap: 'wrap' }}
          />
        );
      case 'files':
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            scopeSetting={scopeSetting}
            filesDetails={filesDetails}
            projectId={projectId}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
      default:
        return <Folders folders={folders} setFiles={setFiles} setMoveTo={setMoveTo} />;
    }
  };

  const handleFolderCreation = () => {
    setOpenFolderCreate(true);
  };

  const FolderRenameProps = {
    folderName,
    projectId,
    folderId,
    setProjects,
    scopeSetting,
    setError,
  };

  return (
    <ProjectFolderContainer className="root">
      <input type="file" ref={inputRef} hidden multiple onChange={filehandleChangeFiles} />
      <Box className="fileHeader">
        <Box className="fileHeaderNav" style={{ justifyContent: 'flex-start' }}>
          {moveTo === 'files' && (
            <>
              <IconButton
                style={{ padding: '2px', margin: '2px' }}
                onClick={() => {
                  setMoveTo('folder');
                  setAlignment('onlyFolders');
                }}
              >
                <ArrowBackIcon fontSize="small" />
              </IconButton>
              {folderName && alignment === 'onlyFolders' && <RenameFolder {...FolderRenameProps} />}
              {error && (
                <Typography variant="caption" color="error">
                  {error}
                </Typography>
              )}
              {successMsg && (
                <Typography variant="caption" color="success">
                  {successMsg}!
                </Typography>
              )}
            </>
          )}
        </Box>

        <Box className="fileHeaderNav" style={{ justifyContent: 'flex-end' }}>
          {moveTo === 'files' && (
            <>
              <IconButton
                disabled={deleteLoading}
                onClick={() => {
                  deleteFile();
                }}
              >
                {deleteLoading ? (
                  <CircularProgress style={{ color: '#002A45' }} size={20} />
                ) : (
                  <DeleteIcon />
                )}
              </IconButton>
              <IconButton
                disabled={loading}
                onClick={() => {
                  UploadFile();
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: '#002A45' }} size={20} />
                ) : (
                  <FileUploadIcon />
                )}
              </IconButton>
              <FileInputContainer>
                <FileInputFeildEnter
                  placeholder="Search for file"
                  sx={{ flex: 9, pl: 1, color: '#002A45' }}
                  onChange={(e) => {
                    setSearchFiles(e.target.value);
                  }}
                />
              </FileInputContainer>
            </>
          )}

          {moveTo === 'folder' && (
            <>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                size="small"
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="extractAllFiles" aria-label="left aligned">
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="onlyFolders" aria-label="centered">
                  <FolderIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button startIcon={<CreateNewFolderIcon />} onClick={handleFolderCreation}>
                <Typography>Create Folder</Typography>
              </Button>
            </>
          )}
          {isChild && props.renderCross()}
        </Box>
      </Box>
      <Box
        className="fileSpaceContent"
        style={scopeSetting ? { maxHeight: 'calc(100% - 7rem)', overflow: 'auto' } : {}}
      >
        {renderComponent()}
      </Box>
      {moveTo === 'files' && scopeSetting && (
        <Box className="scopeSetting">
          <FileBtn
            disabled={selectedFiles.length === 0}
            cancel={true}
            // style={{ backgroundColor: 'rgb(255, 255, 255)', color: '#002A45' }}
            onClick={() => {
              setSelectedFiles([]);
            }}
          >
            Cancel
          </FileBtn>
          <FileBtn
            disabled={
              select === 'single'
                ? numberOfFilesSupport !== selectedFiles.length
                : numberOfFilesSupport < selectedFiles.length || selectedFiles.length === 0
            }
            onClick={() => {
              handleScopeSettings(selectedFiles);
              setDrawerOpen(true);
              setOpenDialog(false);
            }}
          >
            Proceed
          </FileBtn>
        </Box>
      )}
      <FolderModel
        openFolderCreate={openFolderCreate}
        setOpenFolderCreate={setOpenFolderCreate}
        project_id={projectId}
      />
    </ProjectFolderContainer>
  );
};

export default memo(ProjectFolder);
