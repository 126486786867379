import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';
import M2CardProfile from '../../../assets/images/M2CardProfile.jpg';
import M1CardImage from '../../../assets/images/M1CardImage.jpg';
import M2CardImage from '../../../assets/images/M2CardImage.jpg';
import M3CardImage from '../../../assets/images/M3CardImage.jpg';
import SafetyCyber_Security from '../../../assets/images/SafetyCyber_Security.jpg';
import performanceandmu from '../../../assets/images/performanceandmu.jpg';
import project_management from '../../../assets/images/project_management.jpg';
import { ENV } from '../../../const';

const MuiCard = styled((props) => <Box {...props} />)(({ theme }) => ({
  minWidth: '300px',
  maxWidth: '300px',
  minHeight: '300px',
  maxHeight: '300px',

  [theme.breakpoints.up('lg')]: {
    minWidth: '300px',
    maxWidth: '350px',
    minHeight: '300px',
    maxHeight: '350px',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '400px',
    maxWidth: '500px',
    minHeight: '400px',
    maxHeight: '500px',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '200px',
    maxWidth: '250px',
    minHeight: '200px',
    maxHeight: '250px',
  },

  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  backgroundColor: 'rgb(255, 255, 255)',
  // height: '200px',
  // gap: '5px',
  // padding: '10px',
  border: '1px solid rgba(255, 255, 255, .25)',
  borderRadius: '8px',
  // backgroundColor: 'rgba(255, 255, 255, 0.45)',
  boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
  backdropFilter: 'blur(15px)',
  transition: 'all 0.3s ease-out',
}));

const CardImage = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 8rem)',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100% - 7rem)',
  },
  [theme.breakpoints.up('xl')]: {
    height: 'calc(100% - 7rem)',
  },
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 6rem)',
  },
}));

const CarderContent = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  padding: '10px',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '8rem',
  gap: '5px',
  [theme.breakpoints.up('lg')]: {
    height: '7rem',
  },
  [theme.breakpoints.up('xl')]: {
    height: '7rem',
  },
  [theme.breakpoints.down('md')]: {
    height: '6rem',
  },
}));

const CardHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  // backgroundColor: '#EEF1F9',
  // padding: '0.2rem',
  borderRadius: '8px',
}));

const MuiContainer = styled((props) => <Box {...props} />)(({ theme, inProd }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  // display: 'grid',
  // gridTemplateColumns: '1fr 1fr 1fr',

  // [theme.breakpoints.down('lg')]: {
  //   display: 'grid',
  //   gridTemplateColumns: inProd ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr',
  // },

  // [theme.breakpoints.down('md')]: {
  //   display: 'grid',
  //   gridTemplateColumns: inProd ? '1fr 1fr 1fr' : '1fr 1fr 1fr',
  // },
  gap: '30px',
  // gap: '20px',
  // flexWrap: 'wrap',
  padding: '0.5rem',
  // width: '100%',
  // height: '100%',
  // maxHeight: '100%',
  // overflow: 'auto',
}));

export const CardContainer = ({ item }) => {
  const { name, route, image } = item;
  const history = useHistory();

  return (
    <MuiCard
      onClick={() => {
        history.push(route);
      }}
    >
      <CardImage>
        <img
          src={image}
          alt="M2CardProfile"
          style={{ width: '100%', height: '100', pointerEvents: 'none' }}
        />
      </CardImage>
      <CarderContent>
        <CardHeader>
          <Typography style={{ fontSize: '1.2rem' }}>{name}</Typography>
        </CardHeader>
        <Typography variant="body2" color="text.secondary" style={{ fontSize: '0.78rem' }}>
          {item.renderText()}
        </Typography>
      </CarderContent>
    </MuiCard>
  );
};

const ModuleContainer = () => {
  const { projectId } = useParams();
  // const ENV = 'production';
  const inProd = ENV !== 'production' && ENV !== 'test';
  const tabs = [
    {
      name: 'Smart Requirement Management',
      route: `/project/${projectId}/module1/${'m1'}/new`,
      image: M1CardImage,
      inProd: true,
      renderText: () => {
        return (
          <>
            <strong>iSenS</strong> provides end-to-end <strong>requirement management </strong>for{' '}
            <strong>contract documents,Project requirement Database, Requirement Tables</strong>.
          </>
        );
      },
    },
    {
      name: 'Knowledge & Efficiency',
      route: `/project/${projectId}/module2`,
      image: M2CardImage,
      inProd: true,
      renderText: () => {
        return (
          <>
            Objective of <strong>Knowledge & Efficiency</strong> modules is to centralise{' '}
            <strong>
              knowledge base, utilizing return on experience, providing live domain support
            </strong>{' '}
            and <strong>making fast decision</strong>.
          </>
        );
      },
    },
    {
      name: 'Verification & Quality',
      route: `/project/${projectId}/module3/${'m3'}`,
      image: M3CardImage,
      inProd: true,
      renderText: () => {
        return (
          <>
            Measures the <strong>quality</strong> of <strong>requirements</strong> and automatically{' '}
            <strong>rephrases</strong> them. <strong>Verification</strong> of documents with{' '}
            <strong>custom</strong> checks.
          </>
        );
      },
    },
    {
      name: 'Project Management',
      route: `/project/${projectId}/module6/${'m6'}`,
      image: project_management,
      inProd: true,
      renderText: () => {
        return (
          <>
            <strong>Schedule analysis</strong> aids planners in aligning schedules with{' '}
            <strong>customer needs</strong> by defining scope and{' '}
            <strong>execution strategies</strong>, while assessing milestones and project sequencing
          </>
        );
      },
    },
    {
      name: 'Performance & Maturity',
      route: `/project/${projectId}/module4/${'m4'}`,
      image: performanceandmu,
      inProd,
      renderText: () => {
        return (
          <>
            Measures the <strong>quality</strong> of <strong>requirements</strong> and automatically{' '}
            <strong>rephrases</strong> them. <strong>Verification</strong> of documents with{' '}
            <strong>custom</strong> checks.
          </>
        );
      },
    },
    {
      name: 'Safety & CyberSecurity',
      route: `/project/${projectId}/module5/${'m5'}`,
      image: SafetyCyber_Security,
      inProd,
      renderText: () => {
        return (
          <>
            Measures the <strong>quality</strong> of <strong>requirements</strong> and automatically{' '}
            <strong>rephrases</strong> them. <strong>Verification</strong> of documents with{' '}
            <strong>custom</strong> checks.
          </>
        );
      },
    },
  ];
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
      <MuiContainer inProd={inProd}>
        {tabs
          .filter((data) => {
            return data.inProd;
          })
          .map((items) => {
            return <CardContainer item={items} />;
          })}
      </MuiContainer>
    </div>
  );
};

export default ModuleContainer;
