const rexFilterReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'change_platform': {
      return {
        ...state,
        rexSelectedPlatform: action.payload,
      };
    }

    case 'change_status': {
      return {
        ...state,
        rexSelectedStatus: action.payload,
      };
    }

    case 'change_issuerSite': {
      return {
        ...state,
        rexSelectedIssuerSite: action.payload,
      };
    }

    case 'change_typology': {
      return {
        ...state,
        rexSelectedTypology: action.payload,
      };
    }

    case 'change_source': {
      return {
        ...state,
        rexSelectedSource: action.payload,
      };
    }

    case 'change_solution': {
      return {
        ...state,
        rexSelectedSolution: action.payload,
      };
    }

    case 'change_product': {
      return {
        ...state,
        rexSelectedProduct: action.payload,
      };
    }

    case 'change_project': {
      return {
        ...state,
        rexSelectedProject: action.payload,
      };
    }

    case 'change_country': {
      return {
        ...state,
        rexSelectedCountry: action.payload,
      };
    }

    case 'change_productLine': {
      return {
        ...state,
        rexSelectedProductLine: action.payload,
      };
    }

    case 'set_Rex_filter': {
      return {
        ...state,
        rexPaltform: action.payload.rexPaltform,
        rexStatus: action.payload.rexStatus,
        rexIssuerSite: action.payload.rexIssuerSite,
        rexTypology: action.payload.rexTypology,
        rexSource: action.payload.rexSource,
        rexSolution: action.payload.rexSolution,
        rexProduct: action.payload.rexProduct,
        rexProject: action.payload.rexProject,
        rexCountry: action.payload.rexCountry,
        rexProductLine: action.payload.rexProductLine,
      };
    }

    case 'set_Rex_Max_result': {
      return {
        ...state,
        rexSelectedMaxResultsValue: action.payload,
      };
    }

    case 'clear_rex_filters': {
      return {
        ...state,
        rexSelectedPlatform: [],
        rexSelectedStatus: [],
        rexSelectedIssuerSite: [],
        rexSelectedTypology: [],
        rexSelectedSource: [],
        rexSelectedSolution: [],
        rexSelectedProduct: [],
        rexSelectedProject: [],
        rexSelectedCountry: [],
        rexSelectedProductLine: [],
        rexSelectedMaxResultsValue: 100,
      };
    }
    default:
      return state;
  }
};

export default rexFilterReducer;
