import { Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import { TreeItem, TreeView } from '@material-ui/lab';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import WorkIcon from '@mui/icons-material/Work';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectUsers from './components/ProjectConfiguration/ProjectUsers';
import ProjectGroups from './components/ProjectConfiguration/ProjectGroups';
import ProjectIverifier from './components/ProjectConfiguration/ProjectIverifier';
import ProjectiQualimeter from './components/ProjectConfiguration/ProjectiQualimeter';
import ProjectAttribute from './components/ProjectConfiguration/ProjectAttribute';
import ProjectPropertiesNew from './components/ProjectConfiguration/ProjectPropertiesNew';

function ProjectConfiguration(props) {
  const { selectedOption } = props;
  const { projectId } = useParams() as any;
  const queryParams = new URLSearchParams(window.location.search);
  const treeItemOpen = queryParams.get('action') || '';

  const renderComponent = () => {
    switch (selectedOption) {
      case 'Properties':
        return (
          <>
            <ProjectPropertiesNew />
          </>
        );
      case 'Users':
        return (
          <>
            <ProjectUsers />
          </>
        );
      case 'Groups':
        return (
          <>
            <ProjectGroups />
          </>
        );

      case 'Attributes':
        return (
          <>
            <ProjectAttribute projectId={projectId} />
          </>
        );

      case 'iVerifier':
        return (
          <>
            <ProjectIverifier projectId={projectId} />
          </>
        );
      case 'iQualimeter':
        return (
          <>
            <ProjectiQualimeter projectId={projectId} />
          </>
        );

      default:
        return <></>;
    }
  };

  return <>{renderComponent()}</>;
}

export default ProjectConfiguration;
