import React from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const ProjectAttributeContainer = styled((props) => <Paper {...props} />)(({ theme }) => ({
  '& .root': {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  '& .table': {
    minWidth: 650,
  },

  '& .snackbar': {
    bottom: '104px',
  },

  '& .active': {
    backgroundColor: 'red',
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'white',
    },
  },

  '& .bloc': {
    minHeight: 250,
    borderRadius: 10,
    border: 'solid 1px',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },

  '& .error': {
    color: theme.palette.error.main,
  },

  '& .heading': {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },

  '& .treeitem': {
    width: '95%',
    marginTop: '2rem',
  },

  '& .treeLabel': {
    borderRadius: '13px',
    paddingLeft: 10,
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },

  '& .gridList': {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },

  '& .paper': {
    color: theme.palette.primary.main,
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 4, 3),
    textAlign: 'center',
    borderRadius: '10px',
    opacity: 1,
    marginTop: 60,
  },

  '& .input': {
    marginBottom: '15px',
  },

  '& .button': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },

  '& .paper2': {
    color: theme.palette.primary.main,
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 4, 3),
    textAlign: 'center',
    borderRadius: '10px',
    opacity: 1,
    marginTop: 60,
    paddingBottom: 10,
  },

  '& .input2': {
    marginBottom: '15px',
    background: '#0000000B 0% 0% no-repeat padding-box',
    borderRadius: '4px 4px 0px 0px',
  },

  '& .button2': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },

  '& .projectName': {
    display: 'flex',
    justifyContent: 'center',
  },

  '& .entity': {
    textAlign: 'left',
    border: '1px solid #707070',
    borderRadius: '10px',
    padding: 10,
    color: '#002A45',
  },

  '& .type': {
    textAlign: 'left',
    border: '1px solid #707070',
    borderRadius: '10px',
    padding: 10,
  },

  '& .input6': {
    // '&:invalid': {
    //   border: 'red solid 2px',
    // },
  },

  '& .root6': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
  },

  '& .noBorder': {
    border: 'none',
  },

  '& .ListItemBg': {
    '& .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected': {
      backgroundColor: '#DC3223 !important',
    },
    // '& .MuiList-padding': {
    //   paddingTop: '0px !important',
    // },
  },

  '& .iconButtonNoBorder': {
    padding: '8px',
  },

  '& .iconButtonInner': {
    color: 'white',
    fontSize: '1.5rem',
  },
}));

export default ProjectAttributeContainer;
