import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
}

export default function IconBreadcrumbs({ nodes, service, style = {} }) {
  const history = useHistory();
  return (
    <div
      style={{
        height: '2rem',
        fontSize: '0.78rem',
        ...style,
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: '0.5rem',
      }}
      role="presentation"
      onClick={handleClick}
    >
      <Breadcrumbs aria-label="breadcrumb" style={{ cursor: 'pointer' }}>
        {nodes.map((eachNode) => {
          return (
            <Box
              //   underline="hover"
              sx={{ display: 'flex', alignItems: 'center', fontSize: '0.78rem' }}
              //   color="inherit"
              onClick={() => {
                history.push(eachNode.route);
              }}
              //   href={eachNode.route}
            >
              {/* <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
              {eachNode.name}
            </Box>
          );
        })}
        <Typography
          sx={{ color: 'text.primary', display: 'flex', alignItems: 'center', fontSize: '0.78rem' }}
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {service}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
