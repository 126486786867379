import isearcherFilterReducer from './isearcherFilterReducer';
import rexFilterReducer from './rexFilterReducer';
import similaritySearchFilterReducer from './similaritySearchFilterReducer';
import textTanslatorreducer from './TextTanslatorReducer';

const isearcherFilterInitialState = {
  productLineDef: [],
  productLine: [],
  filterProjectsDef: [],
  filterProjects: [],
  filterDocumentProfilesDef: [],
  filterDocumentProfiles: [],
  filterPlatformsDef: [],
  filterPlatforms: [],
  artiFact: [],
  maxResultsValue: 500,
  minResultsValue: 1,
  door: '*',
  checkedA: false,
  productLinefilt1: [],
  filterPlatformsfilt2: [],
  filterProjectsfilt3: [],
  filterDocumentProfilesfilt4: [],
  filt5: [],
  filterSolutionfilt6: [],
  filterProductfilt7: [],
  filt8: [],
  filterissureSite: [],
  filterexPlatform: [],
  filterrexSource: [],
  filterrexStatus: [],
  filterTypology: [],
  // added
  filterrexProductLine: [],
  filterrexProduct: [],
  filterrexSolution: [],
  filterrexProject: [],
  filterrexCountry: [],
};

const isearcherInitialState = {
  isearcherProductLine: [],
  isearcherProjects: [],
  isearcherDocumentProfiles: [],
  isearcherPlatforms: [],
  isearcherArtiFact: [],
  isearcherProduct: [],
  isearcherSolution: [],
  isearcherMaxResultsValue: 100,
  isearcherMinResultsValue: 1,
  isearcherDoor: '*',
  isearcherCheckedA: false,
  isearcherCountry: [],
  selectedIsearcherProductLine: [],
  selectedIsearcherProjects: [],
  selectedIsearcherDocumentProfiles: [],
  selectedIsearcherPlatforms: [],
  selectedIsearcherArtiFact: [],
  selectedIsearcherProduct: [],
  selectedIsearcherSolution: [],
  selectedIsearcherCountry: [],
  selectedIsearcherMaxResultsValue: 100,
  selectedIsearcherMinResultsValue: 1,
  selectedIsearcherDoor: '*',
  selectedIsearcherCheckedA: false,
};

const rexFilterInitialState = {
  rexPaltform: [],
  rexSelectedPlatform: [],
  rexStatus: [],
  rexSelectedStatus: [],
  rexIssuerSite: [],
  rexSelectedIssuerSite: [],
  rexTypology: [],
  rexSelectedTypology: [],
  rexSource: [],
  rexSelectedSource: [],
  rexSolution: [],
  rexSelectedSolution: [],
  rexProduct: [],
  rexSelectedProduct: [],
  rexProject: [],
  rexSelectedProject: [],
  rexCountry: [],
  rexSelectedCountry: [],
  rexProductLine: [],
  rexSelectedProductLine: [],
  rexMaxResultsValue: 500,
  rexSelectedMaxResultsValue: 100,
  rexMinResultsValue: 1,
  rexSelectedMinResultsValue: 1,
  rexDoor: '*',
  rexSelectedDoor: false,
};

const similarityFilterInitialState = {
  simiProductLine: [],
  simiPlatform: [],
  simiSolution: [],
  simiProduct: [],
  simiProject: [],
  simiDocumentProfile: [],
  selectedSimiProductLine: [],
  selectedSimiPlatform: [],
  selectedSimiSolution: [],
  selectedSimiProduct: [],
  selectedSimiProject: [],
  selectedSimiDocumentProfile: [],
  countValue: 5,
  threshold: 69,
};

const textTranslatorIntialState = {
  text: '',
  destText: '',
  sourceLanguage: '',
  destinationLanguage: 'English-EN',
  textTranslationHistory: [],
  mode: 'iSenS Core (MS Translator)',
};

export const initialState = {
  rexFilter: rexFilterInitialState,
  isearcherFilter: isearcherInitialState,
  similarityFilter: similarityFilterInitialState,
  textTranslator: textTranslatorIntialState,
};

const combineReducer = (reducers) => {
  return (state = {}, action) => {
    const newState = {};
    // eslint-disable-next-line guard-for-in
    for (const key in reducers) {
      newState[key] = reducers[key](state[key], action);
    }
    return newState;
  };
};

// eslint-disable-next-line import/prefer-default-export
export const reducers = combineReducer({
  rexFilter: rexFilterReducer,
  isearcherFilter: isearcherFilterReducer,
  similarityFilter: similaritySearchFilterReducer,
  textTranslator: textTanslatorreducer,
});
