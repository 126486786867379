/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Grid, Paper } from '@mui/material';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import { useHistory, useParams } from 'react-router-dom';
import { Cell, Pie, PieChart, Tooltip as TooltipGraph } from 'recharts';
import { Workload3Create } from 'WorkloadType';
import { getResultsByWorkload } from '../../../../../../api/workloads';

import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  tUseStyles,
} from '../../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../../components/Datagrid/ToolBarPlugin';
import formatBytes from '../../../../../../tools/formatBytes';
import NavigationGoto from '../../../../../../components/Core/NavigationGoto';
import { isensTheme } from '../../../../../../app/isensTheme';
import { getCaptureName } from '../../../../../../api/breakdowns';
import Loading from '../../../../../../components/Core/Loading';
import MultiAllocTableComp from '../../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../../components/Core/NavigationGotoNew';
import NERParamTarget from '../../../../../../components/NERParamTarget';
import WorkSpaceContext from '../../../../WorkspceContext';

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const COLORS = [
  `${isensTheme.palette.primary.main}`,
  `${isensTheme.palette.secondary.main}`,
  `${isensTheme.palette.green.light}`,
  `${isensTheme.palette.secondary.veryLight}`,
  `${isensTheme.palette.primary.dark}`,
  `${isensTheme.palette.secondary.light}`,
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {/* to remove 0% from graph display */}
      {percent * 100 > 1 ? `${`${(percent * 100).toFixed(0)}%`}` : ''}
    </text>
  );
};

function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
//   paper: {
//     '& .MuiPaper-rounded': {
//       background: 'red !important',
//     },
//   },
//   captureHandle: {
//     '& .MuiInputLabel-outlined': {
//       color: '#002A45 !important',
//       fontWeight: 'bold',
//     },
//   },
//   pagingPanelContainer: {
//     // display: 'flex',
//     // justifyContent: 'center',
//     // alignItems: 'center',
//     // marginTop: '10px',
//     // width: '50% !important',
//     padding: '0px !important',
//     marginRight: '244px !important',
//     '& .Pager-pager': {
//       padding: 0,
//     },
//     // Apply other custom styles as needed
//     '& .MuiIconButton-label': {
//       color: '#001F3C',
//       padding: 5,
//       backgroundColor: '#FFFFFF',
//       borderRadius: '50%',
//       boxShadow: '#00000029',
//     },
//     // '& .MuiButton-label': {
//     //   color: '#001F3C',
//     //   padding: 2,
//     //   backgroundColor: '#FFFFFF',
//     //   borderRadius: '50%',
//     //   boxShadow: '#00000029',
//     // },
//     '& .MuiButton-root': {
//       '&.MuiButton-textPrimary .Pagination-activeButton': {
//         backgroundColor: 'red !important',
//         color: 'white !important',
//         // Add other custom styles as needed
//       },
//       color: '#001F3C',
//       padding: '0px 5px',
//       backgroundColor: 'white',
//       // borderRadius: '50%',
//       boxShadow: '#00000029',
//       // gap: 10,
//       margin: 9,
//       // '&.Pagination-activeButton': {
//       //   background: 'red !important',
//       // },
//       // '& .Pagination-button': {
//       //   '& .Pagination-activeButton': {
//       //     background: 'red !important',
//       //   },
//       // },
//     },
//   },
// }));

type PayloadText2Dataset = {
  Entity_type: string;
};

type Props = {
  id?: number;
  id_service?: string;
};
const CustomTabLabel = ({ fileName1, fileName2 }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
      }}
    >
      <span>{fileName1}</span>
      <span style={{ margin: '0 8px', fontWeight: 'bold' }}>vs</span>
      <span>{fileName2}</span>
    </div>
  );
};

function ComapareVersions({ id: workloadId, id_service }: Props) {
  // const classes = useStyles();
  const history = useHistory();
  // const tableUseStyles = tUseStyles();
  const { setSnack } = useContext(WorkSpaceContext);
  // const { projectId } = useParams() as any;
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${currentCaptureName}.xlsx`
      );
    });
  };
  // const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);

  // to control the loader and display data full Grid
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);

  // const { setSnack } = useContext(Module1Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [dataoutput, setDataoutput] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState<any>([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const pageSizes = [5, 10, 20, 50];
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [rows1, setRows1] = useState<any>([]);
  const [columnsAre1, setColumnsAre1] = useState<any>([]);
  const [rows2, setRows2] = useState<any>([]);
  const [columnsAre2, setColumnsAre2] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('');
  const [filter, setFilter] = useState<any>([]);
  const [payload1, setPayload1] = useState<any>();
  const [resourcesAre, setResourcesAre] = useState<any>();
  const [inputCaptureName, setInputCaptureName] = useState('');
  const [currentCaptureName, setCurrentCaptureName] = useState<any>('');
  const [arrayFileLen, setArrayFileLen] = useState();
  const inputHandler = (event: any) => setInputCaptureName(event.target.value);

  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();

  const [sheetName1, setSheetName1] = useState<any>();
  const [targetName1, setTargetName1] = useState<any>();
  const [columnName1, setColumnName1] = useState<any>();

  const [similarity, setSimilarity] = useState<any>('');
  // for table data
  const [filesOP1, setFilesOP1] = useState<any[]>([]);
  const [filesOP2, setFilesOP2] = useState<any[]>([]);
  // for graph
  const [graphOP1, setGraphOP1] = useState<any>([]);
  const [graphOP2, setGraphOP2] = useState<any>([]);

  const [fileName1, setFileName1] = useState<any>('');
  const [fileName2, setFileName2] = useState<any>('');
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = React.useState(0);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [dis, setDis] = useState(true);
  const [disA, setDisA] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);

  const [currentPage2, setCurrentPage2] = useState(0);
  const [tempPage2, setTemPage2] = useState(0);
  const [dis2, setDis2] = useState(true);
  const [pageSize2, setPageSize2] = useState(5);

  const handlePageNum2 = () => {
    const rel = tempPage2 - 1;
    setCurrentPage2(rel);
  };
  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };

  const handleSetRfilowsColumns1 = async (myRows: {}[]) => {
    // const myCols = Object.keys(myRows[0]).map((key) => ({
    //   name: key,
    //   title: key,
    // })) as Column[];
    // setColumnsAre1(myCols);
    if (!Array.isArray(myRows) || myRows.length === 0) {
      console.error('Invalid myRows:', myRows);
      return;
    }
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    setColumnsAre1(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });

    // Required to delete i18n from Rows
    const tempdeleted = myRows.map((data) => {
      // eslint-disable-next-line no-param-reassign
      delete data?.i18n;
      return data;
    });
    setRows1(tempdeleted);
  };
  const handleSetRfilowsColumns2 = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    setColumnsAre2(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    const tempdeleted = myRows.map((data) => {
      // eslint-disable-next-line no-param-reassign
      delete data?.i18n;
      return data;
    });
    setRows2(tempdeleted);
  };
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  /**
   * Get all files from project
   */

  /**
   * Render results
   */
  useEffect(() => {
    if (workloadId === undefined) {
      return;
    }
    setLoading(true);
    getResultsByWorkload(workloadId, id_service)
      .then((payload) => {
        // console.log(payload);
        setMessageToDisplay(payload?.status?.errors[0]?.msg);
        setSimilarity(payload?.results_data[0]?.data.output[4]?.Similarity);
        setResourcesAre(payload?.resources);
        // console.log('resourcesAre userprofile: ', payload);
        setLoading(false);
        setDisplay(true);
        setCurrentCaptureName(payload?.resources[0]?.output?.NameOfCapture);
        // console.log('idocdiffer payload:', payload?.resources[0]);
        // addding start
        const unordered = JSON.parse(payload.results_data[0].data.output[1]);
        const itemHere = [
          { Tag: 'Unchanged', Count: 0 },
          { Tag: 'Removed/Deleted', Count: 0 },
          { Tag: 'New', Count: 0 },
          { Tag: 'Slightly Modified', Count: 0 },
          { Tag: 'Modified', Count: 0 },
          { Tag: 'Significantly Modified', Count: 0 },
        ];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < itemHere.length; index++) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          addItem(itemHere[index], unordered);
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const reOrdered = unordered.sort(dynamicSort('Tag'));
        // console.log(payload.output[1]);
        // console.log('reOrdered ac to key: ', reOrdered);
        // adding  end
        setFilesOP1(JSON.parse(payload.results_data[0].data.output[0]));
        handleSetRfilowsColumns1(JSON.parse(payload.results_data[0].data.output[0]));
        setGraphOP1(
          reOrdered.map((rec: any) => {
            return {
              name: rec.Tag,
              value: rec.Count,
            };
          })
        );

        // addding start
        const unordered_second = JSON.parse(payload.results_data[0].data.output[3]);
        const itemHere_second = [
          { Tag: 'Unchanged', Count: 0 },
          { Tag: 'Removed/Deleted', Count: 0 },
          { Tag: 'New', Count: 0 },
          { Tag: 'Slightly Modified', Count: 0 },
          { Tag: 'Modified', Count: 0 },
          { Tag: 'Significantly Modified', Count: 0 },
        ];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < itemHere_second.length; index++) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          addItem(itemHere_second[index], unordered_second);
        }
        const reOrdered_second = unordered_second.sort(dynamicSort('Tag'));
        // console.log(payload.output[3]);
        // console.log('reOrdered ac to key: ', reOrdered_second);
        // adding  end
        setFilesOP2(JSON.parse(payload.results_data[0].data.output[2]));
        handleSetRfilowsColumns2(JSON.parse(payload.results_data[0].data.output[2]));
        setGraphOP2(
          reOrdered_second.map((rec: any) => {
            return {
              name: rec.Tag,
              value: rec.Count,
            };
          })
        );

        function dynamicSort(property: any) {
          let sortOrder = 1;
          if (property[0] === '-') {
            sortOrder = -1;
            // eslint-disable-next-line no-param-reassign
            property = property.substr(1);
          }
          // eslint-disable-next-line func-names
          return function (a: any, b: any) {
            // eslint-disable-next-line eqeqeq
            if (sortOrder == -1) {
              return b[property].localeCompare(a[property]);
              // eslint-disable-next-line no-else-return
            } else {
              return a[property].localeCompare(b[property]);
            }
          };
        }

        function addItem(item: any, inArray: any) {
          // eslint-disable-next-line eqeqeq
          const index = inArray.findIndex((x: any) => x.Tag == item.Tag);
          if (index === -1) {
            inArray.push(item);
          } else {
            // console.log('object already exists');
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSnack(messageToDisplay, 'error');
        setDisplay(false);
      })
      .finally(() => {});
  }, [workloadId]);

  useEffect(() => {
    if (pageSize1 > rows1.length) {
      if (dis1) {
        setDis1((prev) => {
          return !prev;
        });
      }
    } else if (pageSize1 < rows1.length) {
      setDis1((prev) => {
        return !prev;
      });
    }
  }, [pageSize1]);

  useEffect(() => {
    if (pageSize2 > rows2.length) {
      if (dis2) {
        setDis2((prev) => {
          return !prev;
        });
      }
    } else if (pageSize2 < rows2.length) {
      setDis2((prev) => {
        return !prev;
      });
    }
  }, [pageSize2]);

  return (
    <>
      {loading && <Loading />}
      {display && (
        <>
          <Paper>
            {display && (
              <div>
                <AppBar position="static" style={{ backgroundColor: '#002A45' }}>
                  {resourcesAre?.length > 1 ? (
                    <>
                      <Tabs value={value} onChange={handleChange}>
                        <Tab
                          // eslint-disable-next-line max-len
                          label={
                            <CustomTabLabel
                              fileName1={
                                resourcesAre[0]?.output?.FileName ||
                                resourcesAre[0]?.scopeSettingOP[0]?.FileName
                              }
                              fileName2={
                                resourcesAre[1]?.output?.FileName ||
                                resourcesAre[1]?.scopeSettingOP[0]?.FileName
                              }
                            />
                          }
                          style={{
                            margin: '0 auto',
                          }}
                        />
                        <Tab
                          // eslint-disable-next-line max-len
                          label={
                            <CustomTabLabel
                              fileName1={
                                resourcesAre[1]?.output?.FileName ||
                                resourcesAre[1]?.scopeSettingOP[0]?.FileName
                              }
                              fileName2={
                                resourcesAre[0]?.output?.FileName ||
                                resourcesAre[0]?.scopeSettingOP[0]?.FileName
                              }
                            />
                          }
                          style={{
                            margin: '0 auto',
                          }}
                        />
                      </Tabs>
                    </>
                  ) : (
                    <>
                      <Tabs value={value} onChange={handleChange}>
                        <Tab
                          // eslint-disable-next-line max-len
                          label={
                            <CustomTabLabel
                              fileName1={resourcesAre[0]?.FileName}
                              fileName2={resourcesAre[0]?.filesPDF?.output?.FileName}
                            />
                          }
                          style={{
                            margin: '0 auto',
                          }}
                        />
                        <Tab
                          // eslint-disable-next-line max-len
                          label={
                            <CustomTabLabel
                              fileName1={resourcesAre[0]?.filesPDF?.output?.FileName}
                              fileName2={resourcesAre[0]?.FileName}
                            />
                          }
                          style={{
                            margin: '0 auto',
                          }}
                        />
                      </Tabs>
                    </>
                  )}
                </AppBar>
                {value === 0 && (
                  <TabContainer>
                    {/* <ReactJson src={{ payloadOutput0 }} collapsed={true} theme="monokai" /> */}
                    <Grid container>
                      <Grid item xs={8}>
                        <PieChart width={800} height={400}>
                          <Pie
                            isAnimationActive={true}
                            data={graphOP1}
                            cx={300}
                            cy={200}
                            outerRadius={170}
                            innerRadius={100}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            fill="#8884d8"
                            dataKey="value"
                            cornerRadius={10}
                          >
                            {graphOP1.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <TooltipGraph />
                        </PieChart>
                      </Grid>
                      <Grid item xs={2}>
                        <h3>
                          {' '}
                          Similarity: <span style={{ color: 'blue' }}>{similarity}%</span>
                        </h3>
                      </Grid>
                      <Grid item xs={2}>
                        {/* <img src={docdifferbase64} alt="Red dot" height="50%" /> */}
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.green.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Removed/Deleted</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; New</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Unchanged</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.dark}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Slightly Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.veryLight}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Significantly Modified</span>
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <Paper>
                        <Grid container style={{ backgroundColor: 'light blue' }}>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName1}</b>
                          </Grid>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName2}</b>
                          </Grid>
                        </Grid>
                        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
                          <ExpressGrid rows={rows1} columns={columnsAre1}>
                            <DataTypeProvider
                              for={['Text', 'Similar Requirement']}
                              formatterComponent={({ value }) => <span title={value}>{value}</span>}
                            />
                            <FilteringState defaultFilters={[{ columnName: 'Text', value: '' }]} />
                            <IntegratedFiltering />
                            <SortingState />
                            <IntegratedSorting />
                            <PagingState
                              currentPage={currentPage1}
                              onCurrentPageChange={setCurrentPage1}
                              pageSize={pageSize1}
                              onPageSizeChange={setPageSize1}
                            />
                            <IntegratedPaging />
                            <Table
                              tableComponent={TableComponent}
                              columnExtensions={[
                                ...columnsAre1.map((c) => {
                                  return {
                                    columnName: c.name,
                                    width: '180px',
                                    wordWrapEnabled: toggleHeight,
                                  };
                                }),
                              ]}
                            />
                            <TableColumnResizing
                              defaultColumnWidths={[
                                ...columnsAre1.map((c) => {
                                  return {
                                    columnName: c.name,
                                    width: parseInt(Math.ceil(2400 / columnsAre1.length), 10),
                                  };
                                }),
                              ]}
                            />
                            <TableFilterRow rowComponent={rowComponent} />
                            <TableHeaderRow showSortingControls />
                            <TableColumnVisibility
                              hiddenColumnNames={hiddenColumnNames}
                              onHiddenColumnNamesChange={setHiddenColumnNames}
                            />
                            <Toolbar />
                            <ColumnChooser />
                            <ToolBarPlugin name="Download" onClick={startExport} />
                            {/* <PagingPanel pageSizes={pageSizes} /> */}
                            <ToolBarPlugin
                              name="Height"
                              title="Wrap Text"
                              onClick={() => setToggleHeight(!toggleHeight)}
                            />
                            {/* <PagingPanel pageSizes={pageSizes} /> */}
                          </ExpressGrid>
                          <NavigationGoto
                            setPageSize={setPageSize1}
                            fileLength={rows1.length}
                            pageSize={pageSize1}
                            dis={dis1}
                            setTemPage={setTemPage1}
                            handlePageNum={handlePageNum1}
                            currentPage={currentPage1}
                            setCurrentPage={setCurrentPage1}
                          />
                        </Box>
                        <GridExporter
                          ref={exporterRef}
                          rows={rows1}
                          columns={columnsAre1}
                          onSave={onSave}
                        />
                      </Paper>
                    </div>
                  </TabContainer>
                )}
                {value === 1 && (
                  <TabContainer>
                    {/* <ReactJson src={{ graphOP2 }} collapsed={true} theme="monokai" /> */}
                    <Grid container>
                      <Grid item xs={8}>
                        <PieChart width={800} height={400}>
                          <Pie
                            isAnimationActive={true}
                            data={graphOP2}
                            cx={300}
                            cy={200}
                            outerRadius={170}
                            innerRadius={100}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            fill="#8884d8"
                            dataKey="value"
                            cornerRadius={10}
                          >
                            {graphOP2.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <TooltipGraph />
                        </PieChart>
                      </Grid>
                      <Grid item xs={2}>
                        <h3>
                          {' '}
                          Similarity: <span style={{ color: 'blue' }}>{similarity}%</span>
                        </h3>
                      </Grid>
                      {/* <Grid item xs={2}>
                          <img src={docdifferbase64} alt="Red dot" height="50%" />
                        </Grid> */}
                      <Grid item xs={2}>
                        {/* <img src={docdifferbase64} alt="Red dot" height="50%" /> */}
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.green.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Removed/Deleted</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; New</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Unchanged</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.dark}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Slightly Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.veryLight}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Significantly Modified</span>
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <Paper>
                        <Grid container>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName2}</b>
                          </Grid>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName1}</b>
                          </Grid>
                        </Grid>
                        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
                          <ExpressGrid rows={rows2} columns={columnsAre2}>
                            <DataTypeProvider
                              for={['Text', 'Similar Requirement']}
                              formatterComponent={({ value }) => <span title={value}>{value}</span>}
                            />
                            <SortingState />
                            <IntegratedSorting />
                            <PagingState
                              currentPage={currentPage2}
                              onCurrentPageChange={setCurrentPage2}
                              pageSize={pageSize2}
                              onPageSizeChange={setPageSize2}
                            />
                            <IntegratedPaging />
                            <Table
                              tableComponent={TableComponent}
                              columnExtensions={[
                                ...columnsAre2.map((c) => {
                                  return {
                                    columnName: c.name,
                                    width: '180px',
                                    wordWrapEnabled: toggleHeight,
                                  };
                                }),
                              ]}
                            />
                            <TableColumnResizing
                              defaultColumnWidths={[
                                ...columnsAre2.map((c) => {
                                  return {
                                    columnName: c.name,
                                    width: parseInt(Math.ceil(2400 / columnsAre2.length), 10),
                                  };
                                }),
                              ]}
                            />
                            {/* <TableFilterRow rowComponent={rowComponent} /> */}
                            <TableHeaderRow showSortingControls />
                            <TableColumnVisibility
                              hiddenColumnNames={hiddenColumnNames}
                              onHiddenColumnNamesChange={setHiddenColumnNames}
                            />
                            <Toolbar />
                            <ColumnChooser />
                            <ToolBarPlugin name="Download" onClick={startExport} />
                            <ToolBarPlugin
                              name="Height"
                              title="Wrap Text"
                              onClick={() => setToggleHeight(!toggleHeight)}
                            />
                            {/* < pageSizes={pageSizes} /> */}
                          </ExpressGrid>

                          <GridExporter
                            ref={exporterRef}
                            rows={rows2}
                            columns={columnsAre2}
                            onSave={onSave}
                          />
                          <NavigationGoto
                            setPageSize={setPageSize2}
                            fileLength={rows2.length}
                            pageSize={pageSize2}
                            dis={dis2}
                            setTemPage={setTemPage2}
                            handlePageNum={handlePageNum2}
                            currentPage={currentPage2}
                            setCurrentPage={setCurrentPage2}
                          />
                        </Box>
                      </Paper>
                    </div>
                  </TabContainer>
                )}
              </div>
            )}
          </Paper>
        </>
      )}
    </>
  );
}

export default ComapareVersions;
