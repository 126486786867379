import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@mui/material';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Loading from '../../../../../components/Core/Loading';
import TStyles, { rowComponent, tUseStyles } from '../../../../../components/Datagrid/TStyles';

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const Columns = [{ name: 'label', title: 'Description' }];
type PayloadNotif = {
  ownings: any[];
  workloads: any[];
  userManagements: any[];
};

const payloadNotifInit = {
  ownings: [],
  workloads: [],
  userManagements: [],
} as PayloadNotif;

type MessageNotif = {
  ownings: number;
  workloads: number;
  userManagements: number;
};

const messageInit = {
  ownings: 0,
  workloads: 0,
  userManagements: 0,
} as MessageNotif;

function Information({ thirdTablerows, loading }) {
  const tableUseStyles = tUseStyles();
  const [pageSizes] = useState([5, 10, 20, 50, 100]);

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <Paper>
          <ExpressGrid rows={thirdTablerows} columns={Columns}>
            <SelectionState />
            <FilteringState defaultFilters={[{ columnName: 'Descriptions', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={5} />
            <IntegratedPaging />
            <VirtualTable />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <PagingPanel pageSizes={pageSizes} />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
          </ExpressGrid>
        </Paper>
      )}
    </>
  );
}
export default Information;
