import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  Checkbox,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import useFilterOptions from './Hooks/useFilterOptions';

const Header = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '2rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  transition: 'all 0.3s ease-out',
}));

const AppliedFilters = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  position: 'sticky',
  zIndex: 2,
  top: '-0.5rem',
  backgroundColor: '#EEF1F9',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '2px',
  transition: 'all 0.3s ease-out',
}));
const LeftHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  //   justifyContent: 'space-between',
  alignItems: 'center',
  gap: '5px',
  fontWeight: 'bold',
  transition: 'all 0.3s ease-out',
}));

const DropDownBox = styled((props) => <List {...props} />)(({ theme, maxHeight = '500px' }) => ({
  // height: '20%',
  width: '100%',
  backgroundColor: '#fafafa',
  // minHeight: '20%',
  overflow: 'auto',
  padding: 0,
  transition: 'all 0.3s ease-out',
  maxHeight, // Adjust this value based on your content
  [theme.breakpoints.down('lg')]: {
    maxHeight: '200px',
  },
  '&.collapsed': {
    maxHeight: '0',
    overflow: 'hidden',
  },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccd6f2',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
}));

const CheckItem = styled((props) => <ListItem {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontSize: '0.84rem',
  transition: 'all 0.3s ease-out',
}));

const CheckItemCheckBox = styled((props) => <Checkbox {...props} />)(({ theme }) => ({
  color: 'primary',
  transition: 'all 0.3s ease-out',
  padding: 0,
}));

const ItemsHeader = memo(
  ({
    index,
    _cache,
    options,
    name,
    collapsed,
    toggleCollapse,
    freezed,
    setFreezed,
    onChangeOption,
    setCheckAll,
    checkAll,
  }: any) => {
    // const [checkAllOption, setCheckAllOption] = useState(false);

    const handleHeaderCheckAll = (e) => {
      // setCheckAllOption(e.target.checked);
      const temp = _cache;
      if (e.target.checked) {
        options.forEach((eachOption) => {
          temp[eachOption.item] = e.target.checked;
        });
      } else {
        options.forEach((eachOption) => {
          delete temp[eachOption.item];
        });
      }
      setCheckAll(e.target.checked);
      onChangeOption(Object.keys(temp));
    };

    return (
      <>
        <Header>
          <LeftHeader>
            <CheckItemCheckBox
              checked={checkAll}
              disabled={freezed}
              setFreezed={setFreezed}
              onChange={(e) => {
                handleHeaderCheckAll(e);
              }}
            />
            {name}
          </LeftHeader>
          <div>
            {/* <IconButton onClick={() => console.log('clicked')}>
              <FilterListOffIcon />
            </IconButton> */}
            <IconButton onClick={() => toggleCollapse(index)}>
              {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </div>
        </Header>
      </>
    );
  }
);

const CheckBoxSection = ({
  eachoption,
  _cache,
  changeInOption,
  isChecked,
  freezed,
  setFreezed,
}) => {
  // const [check, setCheck] = useState(Object.keys(_cache).includes(eachoption));
  const [check, setCheck] = useState(isChecked);
  // console.log('isChecked', isChecked);
  useEffect(() => {
    // setCheck(Object.keys(_cache).includes(eachoption));
    setCheck(isChecked);
  }, [eachoption, _cache, isChecked]);

  const onChangeChecBox = (e) => {
    setCheck(e.target.checked);
    setFreezed(true);
    const temp = _cache;
    if (e.target.checked) temp[eachoption] = e.target.checked;
    else delete temp[eachoption];

    changeInOption();
  };

  return (
    <>
      <CheckItem>
        <CheckItemCheckBox
          checked={check}
          disabled={freezed}
          onChange={(e) => {
            // handleSelection(index, childIndex, e.target.checked);
            onChangeChecBox(e);
          }}
        />
        {eachoption}
      </CheckItem>
    </>
  );
};

const ItemsSection = memo(
  ({ collapsed, _cache, onChangeOption, options, maxHeight, freezed, setFreezed }: any) => {
    // console.log('options', options);
    const changeInOption = useCallback(() => {
      const temp = Object.keys(_cache);
      onChangeOption(temp);
    }, [_cache]);
    return (
      <>
        <DropDownBox className={collapsed ? 'collapsed' : ''} maxHeight={maxHeight}>
          {options.map((eachopiton, childIndex) => {
            return (
              <CheckBoxSection
                eachoption={eachopiton.item}
                freezed={freezed}
                _cache={_cache}
                setFreezed={setFreezed}
                key={childIndex}
                isChecked={eachopiton.isChecked}
                changeInOption={changeInOption}
              />
            );
          })}
        </DropDownBox>
      </>
    );
  }
);

export const FilterItem = memo((props: any) => {
  const {
    collapsed,
    setFreezed,
    freezed = false,
    name,
    checkAllOption,
    options: optionsProp,
    _cache,
    key,
    onChangeOption,
    index,
    toggleCollapse,
    maxHeight,
  } = props;
  const [checkAll, setCheckAll] = useState(Object.keys(_cache).length === optionsProp.length);

  useEffect(() => {
    setCheckAll(Object.keys(_cache).length === optionsProp.length);
  }, [_cache]);

  const options = useMemo(() => {
    // console.log('checkAll', checkAll);
    // if (checkAll) return optionsProp.map((eachItem) => ({ item: eachItem, isChecked: true }));
    return optionsProp.map((eachItem) => ({
      item: eachItem,
      isChecked: Object.keys(_cache).length > 0 && Object.keys(_cache).includes(eachItem),
    }));
  }, [optionsProp, checkAll, _cache]);

  return (
    <>
      <ItemsHeader
        index={index}
        name={name}
        onChangeOption={onChangeOption}
        options={options}
        _cache={_cache}
        setFreezed={setFreezed}
        freezed={freezed}
        collapsed={collapsed}
        toggleCollapse={toggleCollapse}
        setCheckAll={setCheckAll}
        checkAll={checkAll}
      />
      <ItemsSection
        collapsed={collapsed}
        setFreezed={setFreezed}
        freezed={freezed}
        // handleSelection={handleSelection}
        onChangeOption={onChangeOption}
        options={options}
        index={index}
        maxHeight={maxHeight}
        checkAll={checkAll}
        _cache={_cache}
      />
    </>
  );
});

const Filters = ({ toggleCollapse: filterToggle, service }) => {
  const { state, dispatch } = useContext(M2GlobalContext);
  const [collapseAll, setCollapseAll] = useState(false);
  const [syncFilters, setSyncFilters] = useState({});
  const selectedOptionRef = useRef({});
  const [clearRandom, setClearRandom] = useState(0);
  const [freezed, setFreezed] = useState(false);

  const callbackFilters = (options, storeKey) => {
    setFreezed(false);
    setSyncFilters((prev) => {
      localStorage.setItem(storeKey, JSON.stringify({ ...prev, ...options }));
      return {
        ...prev,
        ...options,
      };
    });
  };
  const { options, otherFilters, ClearFilters } = useFilterOptions({
    service,
    state,
    dispatch,
    callbackFilters,
    selectedOptionRef,
    clearRandom,
  });

  // console.log('state', state);
  // console.log('service', options);

  const [NumberOfFilters, setNumberOfFilters] = useState([...options]);

  const appliedFilters = useMemo(() => {
    return options
      .filter((item) => Object.keys(item._cache).length > 0)
      .map((item) => {
        return {
          name: item.name,
          value: Object.keys(item._cache).join(','),
        };
      });
  }, [state, options]);

  useMemo(() => {
    if (options && options.length > 0) {
      setNumberOfFilters(options);

      const temp = {};
      const seletedtemp = {};

      options.forEach((eachOption) => {
        temp[eachOption.key] = eachOption.options;
        seletedtemp[eachOption.key] = [];
      });
      selectedOptionRef.current = seletedtemp;
      setSyncFilters(temp);
    } else {
      setNumberOfFilters([]);
      setSyncFilters({});
    }
  }, [options, service]);

  const toggleCollapse = useCallback((index) => {
    setNumberOfFilters((prev) => {
      return prev.map((item, i) => (i === index ? { ...item, collapsed: !item.collapsed } : item));
    });
  }, []);

  const handleAllCollapse = () => {
    setNumberOfFilters((prev) => {
      return prev.map((item) => {
        return { ...item, collapsed: !collapseAll };
      });
    });
    setCollapseAll(!collapseAll);
  };

  // console.log('state', state.isearcherFilter);

  return (
    <>
      <Header
        sx={{ borderBottom: '1px solid #002A45', paddingBottom: '0.5rem', cursor: 'pointer' }}
        onClick={filterToggle}
      >
        <Typography>{appliedFilters.length > 0 ? 'Applied Filters' : 'Filters'}</Typography>
        <Button variant="text" style={{ outline: 'none', border: 'none' }}>
          <ArrowBackIosIcon fontSize="small" />
        </Button>
      </Header>
      {otherFilters?.isOtherComponent && otherFilters?.renderComponent(clearRandom)}
      {appliedFilters.length > 0 && (
        <AppliedFilters>
          {appliedFilters.map((eachItem) => {
            return (
              <div style={{ width: '100%' }}>
                <Typography style={{ fontSize: '0.83rem', fontWeight: 'bold', display: 'inline' }}>
                  {eachItem.name} :
                </Typography>
                <Tooltip title={eachItem.value.replace(',', '\n')}>
                  <Typography style={{ fontSize: '0.83rem', display: 'inline' }}>
                    {eachItem.value.slice(0, 20)} {eachItem.value.length > 20 ? '...' : ''}
                  </Typography>
                </Tooltip>
              </div>
            );
          })}
        </AppliedFilters>
      )}
      {/* <Header>
        <IconButton
          onClick={() => {
            handleAllCollapse();
          }}
        >
          {collapseAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
        </IconButton>
      </Header> */}
      {NumberOfFilters.map((data, index) => {
        return (
          <div key={index} style={{ padding: '0.3rem', fontSize: '0.89rem' }}>
            <FilterItem
              {...data}
              freezed={freezed}
              setFreezed={setFreezed}
              options={syncFilters[data.key] ?? []}
              index={index}
              toggleCollapse={toggleCollapse}
            />
          </div>
        );
      })}

      {NumberOfFilters.length > 0 && (
        <Box style={{ position: 'sticky', bottom: 0 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%', marginTop: '0.5rem' }}
            onClick={() => {
              ClearFilters();
              setClearRandom((prev) => prev + 1);
            }}
          >
            Clear Filters
          </Button>
        </Box>
      )}
    </>
  );
};

export default memo(Filters);
