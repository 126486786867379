import React, { useMemo } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import HistoryIcon from '@mui/icons-material/History';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import LayoutContainer from '../../../layouts/LayoutContainer';
// import DocumentTranslator from '../../Module2/Module/Components/DocumentTranslator';
import History from '../../../components/History/History';
import useDocumentTranslator from '../Hooks/useDocumentTranslator';
import useAppToken from '../../../app/useAppToken';
import UserFileManager from '../FileManger/UserFileManager';
import Description from '../../Module2/Module/Description';
import DocumentTranslator from '../Components/DocumentTranslator';
import RexBook from '../Components/RexBook';

// import History from '../../Module2/Module/History';

const RexBookContainer = () => {
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const queryParams = new URLSearchParams(window.location.search);
  // const actionIs = queryParams.get('service');
  const actionIs = queryParams.get('tab');

  console.log('actionIs', actionIs);

  const handleResultService = (actionIs) => {
    switch (actionIs) {
      case 'user_history':
        return 1;
      default:
        return 0;
    }
  };

  const componentsData = useMemo(() => {
    return [
      {
        name: 'Rex Book',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/Rexbook',
        buttonRoute: '/userspace/RexBook',
        numberOfFilesSupport: 1,
        fileTypeSupport: ['pdf'],
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Rex book',
            numberOfFilesSupport: 1,
            fileTypeSupport: ['pdf'],
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <RexBook {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            renderDescription: () => {
              const list = [
                'REX Book is a compilation of lessons and Return of Experiences (REX) from the past, and applicable to your tender or project to avoid re-doing the same mistakes as in the past.',
                'Upon uploading a document in form of pdf, excel or csv, the REX Book service looks for and complies a list of REX applicable to your tender or project.',
                'You can download the REX Book document and share it across to the relevant PICs and stakeholders.',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
    ];
  }, [actionIs]);

  return (
    <LayoutContainer
      componentsData={componentsData}
      workload={{
        workload: 0,
        asyncWorkload: 0,
        workloads: [],
        serviceFor: { name: 'user', id: user_id },
        selectedIndex: 0,
        selectedChildIndex: handleResultService(actionIs),
      }}
    />
  );
};

export default RexBookContainer;
