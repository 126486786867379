import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { Box, List, ListItem, Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { InputField } from '../Home/components/Homepage';
import HomeContext from '../ContextMager/HomeContext';
import useKnowledge360Api from '../HOC/useKnowledge360Api';
import KnowledgeResultContainer from './TextAnalysisResultContainer/KnowledgeResultContainer';
import NoResultsFound from '../../components/Core/NoResultsFound';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '0rem 1rem 1rem 1rem',
  width: '100%',
  height: 'calc(100% - 5px)',
  maxHeight: 'calc(100% - 5px)',
  overflow: 'auto',
  // display: 'flex',
  // flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  // maxHeight: 'calc(100% - 5px)',
  // overflow: 'auto',
}));

const serviceButton = ['Content Search', 'Similarity Search', 'Referents Search', 'People Search'];

const ServiceList = styled((props) => <List {...props} />)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  //   maxWidth: '100%',
  margin: 0,
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none',
}));

const ServiceListItem = styled((props) => <ListItem {...props} />)(({ theme, isSelected }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  width: 'max-content',
  maxWidth: '100%',
  gap: '0.3rem',
  // flexBasis: '16%',
  //   flexGrow: 0,
  //   flexShrink: 0,
  // flex: 1,
  cursor: 'pointer',
  // maxWidth: '24%',
  textAlign: 'center',
  borderBottom: isSelected ? '5px solid #DC3223' : 'none',
  color: isSelected ? '#DC3223' : '#002A45',
}));

const ToolContainer = memo(
  ({ toggleCollapse, text, handleApiCall, selectedIndex, setSelectedIndex, isCollapsed }) => {
    //   const rexResult = {
    //     text: 'REX Cards',
    //     data: [],
    //   };
    const [enterValue, setEnterValue] = React.useState(text ?? '');
    const history = useHistory();
    // const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onChangeOption = (payload) => {
      const { index, item } = payload;
      const encodedText = encodeURIComponent(enterValue);
      history.push(`/home/Knowledge360?service=${item}&text=${encodedText}`);
      handleApiCall(enterValue, '/home/Knowledge360', item ?? '', false);
      setSelectedIndex(index);
    };

    const SubmitSearch = useCallback(() => {
      const encodedText = encodeURIComponent(enterValue);
      history.push(
        `/home/Knowledge360?service=${serviceButton[selectedIndex]}&text=${encodedText}`
      );
      handleApiCall(enterValue, '/home/Knowledge360', serviceButton[selectedIndex] ?? '', false);
    }, [enterValue, handleApiCall]);

    const handleTextChange = useCallback((e) => {
      setEnterValue(e.target.value);
    }, []);

    return (
      <div
        style={{
          // width: '70%',
          // minWidth: '70%',
          width: '70rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          // maxWidth: '50%',
          // padding: '0.5rem',
          // position: 'sticky',
          // top: '0',
          // zIndex: 999,
          // backgroundColor: '#fafafa',
        }}
      >
        <InputField
          filterType={serviceButton[selectedIndex]}
          initialIndex={selectedIndex}
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
          onChangeOption={onChangeOption}
          SubmitSearch={SubmitSearch}
          enterValue={enterValue}
          options={serviceButton}
          handleTextChange={handleTextChange}
        />
        {/* <ServiceList>
          {serviceButton.map((button, index) => (
            <ServiceListItem
              key={index}
              isSelected={index === selectedIndex}
              onClick={() => {
                setSelectedIndex(index);
              }}
            >
              {button}
            </ServiceListItem>
          ))}
        </ServiceList> */}
      </div>
    );
  }
);

const Knowledge360Result = memo(({ toggleCollapse, isCollapsed }) => {
  const { response } = useContext(HomeContext);
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service')
    ? decodeURIComponent(queryParams.get('service'))
    : null;
  const Input = queryParams.get('text') ? decodeURIComponent(queryParams.get('text')) : null;
  const [selectedIndex, setSelectedIndex] = React.useState(serviceButton.indexOf(actionIs) ?? 0);
  const {
    progressTime: progressTimeKnowledge,
    handleMuliApiCall,
    knowlede360loading,
    nameClick,
    handleClickText2DatasetNew,
  } = useKnowledge360Api();
  const { text, error, name } = response;
  // enterValue, '/home/Knowledge360', serviceButton[selectedIndex] ?? '', false
  useEffect(() => {
    if (actionIs && Input) {
      // const index = serviceButton.indexOf(actionIs);
      // setSelectedIndex(index);
      handleMuliApiCall(Input, '', actionIs, false);
      // console.log('rendered');
    }
  }, []);

  // console.log('selectedIndex', selectedIndex);

  // useEffect(() => {}, [response]);

  return (
    <>
      {knowlede360loading && (
        <>
          <Box sx={{ width: '100%', height: '5px' }}>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={progressTimeKnowledge + 10}
            />
          </Box>
        </>
      )}
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <ToolContainer
            toggleCollapse={toggleCollapse}
            text={text ?? Input}
            isCollapsed={isCollapsed}
            handleApiCall={handleMuliApiCall}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />{' '}
        </div>
        {knowlede360loading ? (
          [1, 2, 3, 4, 5].map((data) => {
            return (
              <Box style={{ marginBottom: '2rem' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            );
          })
        ) : (
          <>
            {error ? (
              <NoResultsFound />
            ) : (
              <KnowledgeResultContainer
                response={response}
                callOperation={response.name}
                nameClick={nameClick}
                handleClickText2DatasetNew={handleClickText2DatasetNew}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
});

export default memo(Knowledge360Result);
