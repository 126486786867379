import * as React from 'react';
import { Link } from 'react-router-dom';
import Accordion, { AccordionSlots, accordionClasses } from '@mui/material/Accordion';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails, { accordionDetailsClasses } from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import { Box, Button, Checkbox, CircularProgress } from '@mui/material';
import { FileIcon } from '../../../containers/Project/ProjectContainer/components/ProjectFolder/Files';
import DetailforTransalator from './DetailforTransalator';
import { actionForUrl, RenderDateAndTiem } from './ServiceItem';
import downloadTranslateFile from '../../../tools/downloadTranslateFile';
import { getBearerTokenSync } from '../../../app/AppToken';

const redirectUrl = (serviceFor, data) => {
  const { id, name } = serviceFor;

  if (name === 'project') {
    return ['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'].includes(data.action)
      ? `/project/${id}/${data.app}/${data?.app[0] + data?.app[data?.app?.length - 1]}/results/${
          data.id
        }?service=${actionForUrl(data.action)}`
      : '';
  }

  return `/userspace/DocAnalysis/${id}/results/${data.id}?service=${actionForUrl(
    data.action
  )}&tab=${'result'}`;
};

const RenderStatus = ({ data, workload }) => {
  const [loading, setLoading] = React.useState(false);
  const { status, action } = data;

  const handleDownload = (row: string) => {
    setLoading(true);

    if (
      row?.errors?.length > 0 &&
      row?.errors[0].msg !== '(TargetFileAlreadyExists): Target document file already exists.'
    ) {
      const error = `Error during translation ${row?.errors[0].msg}`;
      // setSnack(error);
      return;
    }
    const token = getBearerTokenSync();
    const fileName = row?.resources[0]?.filename;
    const language = row?.resources[0]?.settings?.nameOfCapture;
    const lastDotIndex = fileName.lastIndexOf('.'); // Find the last dot in the filename
    const baseName = fileName.substring(0, lastDotIndex); // Extract base name "sample.test"
    const extension = fileName.substring(lastDotIndex + 1); // Extract extension "pdf"
    const newFileName = `${baseName}-${language}.${extension}`;
    const querystring = `files=${'translate_file'}`;
    const { id, name } = workload?.serviceFor;
    if (row.action === 'Translator') {
      if (name === 'user') {
        const querystring = `files=${'translate_file'}`;
        downloadTranslateFile(`/download/userresources/${row?.id}`, querystring, token, newFileName)
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else if (name === 'project') {
        const querystring = `files=${'translate_file'}`;
        downloadTranslateFile(`/download/resources/${row?.id}`, querystring, token, newFileName)
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } else if (row.action === 'RexBook') {
      const check = name === 'user' ? 'userworkspace' : 'projects';
      downloadTranslateFile(
        `/download/userresources/${row?.id}/rexbook/${check}`,
        querystring,
        token,
        'Rexbook.docx'
      )
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  switch (status) {
    case 'progress':
      return (
        <>
          <Button style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}>
            {data.progress_percent}%
          </Button>
        </>
      );
    case 'end':
      return (
        <>
          {action === 'Translator' || action === 'RexBook' ? (
            <Button
              style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}
              size="small"
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(data);
              }}
            >
              {loading ? (
                <CircularProgress style={{ color: 'rgb(255, 255, 255)' }} size={20} />
              ) : (
                <DownloadForOfflineIcon
                  style={{
                    color: 'rgb(255, 255, 255)',
                    marginRight: 2,
                    // backgroundColor: 'rgb(255, 255, 255)',
                    borderRadius: '50%',
                  }}
                  titleAccess="Download translated file"
                />
              )}
            </Button>
          ) : (
            <Link style={{ textDecoration: 'none' }} to={redirectUrl(workload.serviceFor, data)}>
              <Button style={{ backgroundColor: '#002a45', color: 'rgb(255, 255, 255)' }}>
                view
              </Button>
            </Link>
            // <Box
            //   style={{
            //     display: 'flex',
            //     justifyContent: 'center',
            //   }}
            //   onClick={(e) => {
            //     e.stopPropagation();
            //   }}
            // >
            //   <Button>View</Button>
            // </Box>
          )}
        </>
      );

    default:
      return null;
  }
};

export default function Item(props) {
  const [expanded, setExpanded] = React.useState(false);

  const { data, workload, selectedWorkload, handleCheckboxChange } = props ?? {
    results_data: [
      {
        id: 0,
        data: {
          output: {
            filename: '',
            toLanguage: '',
          },
        },
      },
    ],
    resources: [
      {
        filename: '',
        settings: {
          nameOfCapture: '',
        },
      },
    ],
    action: '',
  };

  const [checked, setChecked] = React.useState(
    selectedWorkload?.current?.includes(data?.id) ?? false
  );

  const { results_data, resources, action, status } = data;
  // const { name, id } = workload?.serviceFor ?? { name: '', id: '' };
  // console.log('results_data', data);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={[
          expanded
            ? {
                [`& .${accordionClasses.region}`]: {
                  height: 'auto',
                },
                [`& .${accordionDetailsClasses.root}`]: {
                  display: 'block',
                },
              }
            : {
                [`& .${accordionClasses.region}`]: {
                  height: 0,
                },
                [`& .${accordionDetailsClasses.root}`]: {
                  display: 'none',
                },
              },
        ]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              paddingRight: '10px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
              }}
            >
              <Checkbox
                color="primary"
                sx={{ p: 0 }}
                // disabled={numberOfFilesSupport === selectedFiles.length && !isItemSelected}
                checked={checked}
                onChange={(event) => {
                  setChecked(event.target.checked);
                  handleCheckboxChange(event, data);
                }}
              />
              {/* <Typography style={{ fontWeight: 'bold' }}>{data.id}</Typography> */}

              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {action !== 'Translator' ? (
                  <FileIcon
                    filename={resources && resources.length > 0 ? resources[0]?.filename : ''}
                    trimText={false}
                  />
                ) : (
                  <FileIcon
                    filename={
                      results_data && results_data?.length > 0
                        ? results_data[0]?.data?.output?.filename
                        : 'File is processing... '
                    }
                    trimText={false}
                  />
                )}
                {/* <Typography>{data.resources[0].filename}</Typography> */}
              </div>
              {action !== 'Translator' && (
                <div
                  style={{
                    width: 'max-content',
                    backgroundColor: '#dae9f8',
                    padding: '0.2rem 0.5rem',
                    borderRadius: '5px',
                    marginTop: '0.3rem',
                    display: 'flex',
                    gap: '0.2rem',
                  }}
                >
                  <BookmarkBorderIcon
                    fontSize="small"
                    style={{ fontWeight: '500', color: '#8b8080' }}
                  />
                  <Typography
                    style={{ fontSize: '0.8rem', fontWeight: '500', letterSpacing: '0.05em' }}
                  >
                    {data?.resources[0]?.settings?.nameOfCapture}
                  </Typography>
                </div>
              )}
            </Box>
            <Box>
              <RenderStatus data={data} workload={workload} />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {action === 'Translator' ? (
            <DetailforTransalator
              id={data?.id}
              workload={workload}
              source_file={resources && resources.length > 0 ? resources[0]?.filename : ''}
              translatedLang={
                results_data && results_data?.length > 0
                  ? results_data[0]?.data?.output?.toLanguage
                  : ''
              }
              created_at={data.created_at}
            />
          ) : (
            <RenderDateAndTiem created_at={data.created_at} style={{ fontSize: '0.89rem' }} />
            // <Typography>created at: {data.created_at}</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
