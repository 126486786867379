import { useState } from 'react';
import { getAzJWTSync, saveAzJWT } from './AzureToken';

export default function useAzureToken() {
  const getJWTContent = () => {
    return getAzJWTSync();
  };

  const [azJWT, setJwtContent] = useState(getJWTContent());

  const setAzJWT = (jwt) => {
    saveAzJWT(jwt);
    setJwtContent(jwt);
  };

  return {
    setJWT: setAzJWT,
    jwt: azJWT,
  };
}
