import React, { useContext, useMemo, useState } from 'react';
import { Button, Checkbox, Typography } from '@mui/material';
import RephraserContainer, {
  RephraserButton,
  RephraserInputFeild,
  RulesList,
} from '../Styles/Rephraser';
import HomeContext from '../../ContextMager/HomeContext';
import useInputPage from './core/Rephrase/InputPage';
import useResultPage from './core/Rephrase/ResultPage';

const Rephraser = () => {
  const { rePhraserRules } = useContext(HomeContext);
  const [showResult, setShowResult] = useState(false);
  const rephraserRules = useMemo(() => {
    return Object.values(rePhraserRules?.text) ?? [];
  }, [rePhraserRules]);
  const { renderInputPage, handleAnalyse, result, textValue, rules } = useInputPage({
    rePhraserRules,
    rephraserRules,
    setShowResult,
  });
  const { resultProps } = useResultPage({
    handleAnalyse,
    result,
  });

  const renderProps = useMemo(() => {
    return !showResult ? renderInputPage : resultProps;
  }, [showResult]);

  return (
    <div style={{ minHeight: '20rem' }}>
      <RephraserContainer>
        <div className="mainGrid">
          <div className="section">
            {renderProps.left.renderHeader({ textValue })}
            {renderProps.left.renderContent()}
            {renderProps.left.enableFooter && renderProps.left.renderFotter()}
          </div>
          <div className="section">
            {renderProps.right.renderHeader({ rules })}
            {renderProps.right.renderContent({ rules })}
            {renderProps.right.enableFooter && renderProps.right.renderFotter()}
            {/* <div className="header">
              <div className="label">
                <Typography>INCOSE Rule</Typography>
              </div>
              <RephraserButton>Select All</RephraserButton> 
            </div>

            <div className="fotter">
              <Typography>Warning</Typography>
            </div> */}
          </div>
        </div>
      </RephraserContainer>
    </div>
  );
};

export default Rephraser;
