import { styled } from '@mui/material/styles';

const ProjectUsersContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,

  '& .bloc': {
    minHeight: 250,
    borderRadius: 10,
    border: 'solid 1px',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },

  '& .error': {
    color: theme.palette.error.main,
  },

  '& .heading': {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },

  '& .dirty': {
    backgroundColor: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 3,
    color: 'white',
    padding: 1,
  },
}));
export default ProjectUsersContainer;
