import React from 'react';
import SimilarityAnalysisNew from '../../Workspace/Components/Knowledge360/DocumentAnalysis/Components/SimilarityAnalysisNew';
import DocSimilarity from '../../Workspace/Components/Knowledge360/DocumentAnalysis/Components/DocSimilarity';
import CompareVersions from '../../Workspace/Components/Knowledge360/DocumentAnalysis/Components/CompareVersions';
import ExtractTopics from '../../Workspace/Components/Knowledge360/DocumentAnalysis/Components/ExtractTopics';
import FindDuplicates from '../../Workspace/Components/Knowledge360/DocumentAnalysis/Components/FindDuplicates';

const ResultComponents = (props) => {
  // console.log('workloadId', props);
  const { modelService, workload: wl } = props;
  const { serviceFor, asyncWorkload: workload } = wl;

  const serviceName = serviceFor?.name ?? '';
  const HandleResultComponent = () => {
    switch (modelService) {
      case 'Similarity Analysis':
        return (
          <SimilarityAnalysisNew
            id={workload ? Number(workload) : undefined}
            id_service={serviceName}
          />
        );
      case 'Document Similarity':
        return (
          <DocSimilarity id={workload ? Number(workload) : undefined} id_service={serviceName} />
        );
      case 'Compare Versions':
        return (
          <CompareVersions id={workload ? Number(workload) : undefined} id_service={serviceName} />
        );
      case 'Extract Topics':
        return (
          <ExtractTopics id={workload ? Number(workload) : undefined} id_service={serviceName} />
        );
      case 'Find Duplicates':
        return (
          <FindDuplicates id={workload ? Number(workload) : undefined} id_service={serviceName} />
        );
      default:
        return null;
    }
  };

  if (!modelService || !workload) return null;

  return <>{HandleResultComponent()}</>;
};

export default ResultComponents;
