import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import { deleteConfigsBulk, duplicateConfigsBulk, getProjectConfigs } from '../../api/iverifier';
import Loading from '../../components/Core/Loading';
import NavigationGotoNew from '../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../components/Datagrid/MultiAllocTableComp';
import TableComponent from '../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../components/Datagrid/ToolBarPlugin';
import {
  tableAllocatorComponent,
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../components/Datagrid/TStyles';
import { IS_DEBUG_MODE } from '../../const';
import { handleColumns } from '../../tools/datagrid';
import formatDate, { formatLocal } from '../../tools/formatDate';
import { renderUser } from './Admin/IverifierTemplatesAdmin';
import ModalFunctionViewer from './ModalFunctionViewer';
import ModalProjectConfig from './ModalProjectConfig';
import ModalRulesViewer from './ModalRulesViewer';

const columns = handleColumns([
  'id',
  'name',
  {
    name: 'rule_tree_ids',
    title: 'Rules',
  },
  {
    name: 'function_ids',
    title: 'Functions',
  },
  'resources',
  'created_at',
  'created_by_user',
  'updated_at',
  'updated_by_user',
]);

type Props = {
  project_id?: number;
};
const useStyles = makeStyles((theme) => ({
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

function IverifierProjectConfigs({ project_id }: Props) {
  const classes = useStyles();
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setloading] = useState(true);
  const [selection, setSelection] = useState<any[]>([]);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [tempPage, setTemPage] = useState(0);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [pageSize, setPageSize] = useState(5);
  const [pageSize2, setPageSize2] = useState(5);
  const [pageSize3, setPageSize3] = useState(5);
  const [pageSize4, setPageSize4] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [dis, setDis] = useState(true);

  // modal state
  const [toggleModalName, setToggleModalName] = useState<
    '' | 'modalConfirm' | 'modalRule' | 'modalFunction'
  >('');

  const [modalData, setModalData] = useState([]);
  const [modalData2, setModalData2] = useState([]);

  useEffect(() => {
    getProjectConfigs(project_id).then((response) => {
      setRows(response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
      setloading(false);
    });
  }, []);
  useEffect(() => {
    if (pageSize > rows.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rows.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const handleNewConfig = () => {
    setToggleModalName('modalConfirm');
  };

  const handleDelete = () => {
    if (selection.length === 0) return;
    const selectionIds = selection.map((e) => rows[e].id);
    deleteConfigsBulk(selectionIds).then((res) => {
      setRows(rows.filter((e) => !selectionIds.includes(e.id)));
      setSelection([]);
    });
  };

  const handleDuplicate = () => {
    if (selection.length === 0) return;
    const selectionIds = selection.map((e) => rows[e].id);
    duplicateConfigsBulk(selectionIds).then((res) => {
      setRows([...res, ...rows]);
      setSelection([]);
    });
  };

  const handleButtonId = (id: number) => {
    setToggleModalName('modalConfig');
  };

  const renderId = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return <Button onClick={() => handleButtonId(Number(value))}>{value}</Button>;
  };

  const renderName = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return <Link to={`/project/${row.project_id}/module3/m3/configs/${row.id}`}>{value}</Link>;
  };

  const renderResources = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (value)
      return (
        <>
          {value.map((r, k) => (
            <div key={`${r.filename}-${k}`}>{r.filename}</div>
          ))}
        </>
      );
    return null;
  };
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const renderRules = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    const ruleCount = (row.rules || []).filter((r) => {
      return String(r.object_number).search('-') !== -1;
    }).length;
    return (
      <>
        {ruleCount > 0 && (
          <Button
            onClick={() => {
              setModalData2(row.rules_tree);
              setModalData(row.rules);
              setToggleModalName('modalRule');
            }}
          >
            {ruleCount}
          </Button>
        )}
      </>
    );
  };

  const renderFunctions = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        {row.functions?.length > 0 && (
          <Button
            onClick={() => {
              setToggleModalName('modalFunction');
              setModalData(row.functions);
            }}
          >
            {row.functions.length}
          </Button>
        )}
      </>
    );
  };

  if (loading) return <Loading />;
  return (
    <div>
      {IS_DEBUG_MODE && <ReactJson src={{}} collapsed={true} theme="monokai" />}
      <ExpressGrid rows={rows} columns={columns}>
        <DataTypeProvider for={['id']} formatterComponent={renderId} />
        <DataTypeProvider for={['name']} formatterComponent={renderName} />
        <DataTypeProvider for={['resources']} formatterComponent={renderResources} />
        <DataTypeProvider for={['rule_tree_ids']} formatterComponent={renderRules} />
        <DataTypeProvider for={['function_ids']} formatterComponent={renderFunctions} />
        <DataTypeProvider
          for={['created_at', 'updated_at']}
          formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
        />
        <DataTypeProvider
          for={['created_by_user', 'updated_by_user']}
          formatterComponent={renderUser}
        />
        {/* selection */}
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <IntegratedSelection />
        {/* filtering */}
        <FilteringState />
        <SortingState />
        <IntegratedSorting />
        <IntegratedFiltering />
        {/* <PagingState defaultCurrentPage={0} defaultPageSize={10} /> */}
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <IntegratedPaging />
        <Table
          columnExtensions={columns.map((c) => ({
            columnName: c.name,
            wordWrapEnabled: toggleHeight,
          }))}
          tableComponent={MultiAllocTableComp}
          containerComponent={tableAllocatorComponent}
          headComponent={tableHeaderComponent}
        />
        <TableColumnResizing
          defaultColumnWidths={[
            ...columns.map((c) => {
              if (c.name === 'id') return { columnName: c.name, width: 80 };
              if (c.name === 'name') return { columnName: c.name, width: 200 };
              if (c.name === 'rule_tree_ids') return { columnName: c.name, width: 80 };
              if (c.name === 'function_ids') return { columnName: c.name, width: 80 };
              if (c.name === 'resources') return { columnName: c.name, width: 400 };
              if (
                ['created_at', 'created_by_user', 'updated_at', 'updated_by_user'].includes(c.name)
              )
                return { columnName: c.name, width: 150 };
              return {
                columnName: c.name,
                width: Math.ceil(1400 / (columns.length - 7)),
              };
            }),
          ]}
        />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility />
        <Toolbar rootComponent={toolbarComponent} />
        {/* selection */}
        <TableSelection showSelectAll />
        <ColumnChooser />
        <ToolBarPlugin name="NewCapture" onClick={handleNewConfig} title="Create config" />
        <ToolBarPlugin name="Copy" onClick={handleDuplicate} />
        <ToolBarPlugin name="Delete" onClick={handleDelete} />
        <ToolBarPlugin
          name="Height"
          title="Wrap Text"
          onClick={() => {
            setToggleHeight(!toggleHeight);
          }}
        />
        {/* <ToolBarPlugin name="Download" onClick={null} /> */}
        {/* <PagingPanel pageSizes={pageSize} /> */}
        <PagingPanel
          pageSizes={pageSizes}
          containerComponent={(props) => (
            <>
              <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
            </>
          )}
        />
      </ExpressGrid>
      <NavigationGotoNew
        setPageSize={setPageSize}
        fileLength={rows?.length}
        pageSize={pageSize}
        dis={dis}
        setTemPage={setTemPage}
        handlePageNum={handlePageNum}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {toggleModalName === 'modalConfirm' && (
        <ModalProjectConfig
          projectId={project_id}
          onClose={(response) => {
            if (response?.id) {
              setRows([...rows, response]);
            }
            setToggleModalName('');
          }}
        />
      )}

      {toggleModalName === 'modalRule' && (
        <ModalRulesViewer
          rows={modalData}
          rows_tree={modalData2}
          onClose={() => {
            setToggleModalName('');
          }}
        />
      )}
      {toggleModalName === 'modalFunction' && (
        <>
          <ModalFunctionViewer
            rows={modalData}
            onClose={() => {
              setToggleModalName('');
            }}
          />
        </>
      )}
    </div>
  );
}

export default IverifierProjectConfigs;
