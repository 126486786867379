/* eslint-disable no-param-reassign */
import { Grid, Paper } from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import Tree from 'rc-tree';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { getRules } from '../../../../api/iverifier';
import '../../../../assets/treeview.css';
import Loading from '../../../../components/Core/Loading';
import { IS_DEBUG_MODE } from '../../../../const';
import RuleDetails from '../../../Iverifier/RulesTree/RuleDetails';
import Run3Context from '../../RunContext';

export const renderNodeChapter = (node: any) => {
  return (
    <div
      style={{
        fontWeight: 'bold',
      }}
    >
      {node.data.object_number} {node.data.rule}
    </div>
  );
};

export const renderNodeRule = (node: any) => {
  return (
    <div
      style={{
        fontWeight: 'normal',
      }}
    >
      {node.data.rule}
    </div>
  );
};

export const isNodeRule = (node: any) => {
  return String(node.data?.object_number || '').search('-') !== -1;
};

const getChildRows = (item: any) => {
  if (item.children) {
    item.children = item.children.map((childItem: any) => {
      childItem.key = childItem.data.id;
      if (isNodeRule(childItem)) {
        childItem.title = renderNodeRule(childItem);
      } else {
        childItem.title = renderNodeChapter(childItem);
      }
      getChildRows(childItem);
      return childItem;
    });
  }
};

const getTreeView = (rules: any) => {
  const myRules = rules?.map((rule: any) => {
    const myRule = rule;
    myRule.key = rule.data.id;
    if (isNodeRule(rule)) {
      myRule.title = renderNodeRule(rule);
    } else {
      myRule.title = renderNodeChapter(rule);
    }
    getChildRows(myRule);
    return myRule;
  });
  return myRules;
};

type Props = {
  selected?: any[];
};

export default function StepRules({ selected }: Props) {
  const { projectId } = useParams() as any;
  const runContext = useContext(Run3Context);
  const { config, setConfig } = runContext;
  const [nodeSelection, setNodeSelection] = useState(undefined);
  const [rows, setRows] = useState<any>([]);
  const [treeData, setTreeData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    Promise.all([
      getRules(projectId, undefined, 'hierarchy'),
      getRules(projectId, undefined, 'flat'),
    ]).then(([hierarchyRules, flatRules]) => {
      setTreeData(getTreeView(hierarchyRules.children, ''));
      setRows(flatRules);
      setLoading(false);
    });
  }, [projectId]);

  const onChecked = (checkedKeys: any, event: any) => {
    const checkedRules = rows.filter((rule: any) => checkedKeys.includes(rule.id));
    const halfCheckedRules = rows.filter((rule: any) => {
      if (event.halfCheckedKeys.includes(rule.id)) {
        rule.halfChecked = true;
        return rule;
      }
      return false;
    });
    const treeRuleIds = checkedRules.concat(halfCheckedRules).map((e) => e.id);
    setConfig({ ...config, rule_ids: checkedKeys, rule_tree_ids: treeRuleIds });
  };

  const onSelect = (selectedKeys: any) => {
    if (selectedKeys.length > 0) {
      const nodeFind = rows.find((rule: any) => selectedKeys.includes(rule.id));
      setNodeSelection(nodeFind);
    }
  };

  return (
    <Paper>
      {IS_DEBUG_MODE && <ReactJson src={{ treeData }} collapsed={true} theme="monokai" />}
      {treeData === undefined ? (
        <span style={{ color: 'red', fontSize: 20 }}>
          Rules are not configured. Please configure it.
        </span>
      ) : (
        ''
      )}
      {/* style removed by Rahul */}
      {/*  overflowX: 'auto' */}
      <div style={{ margin: '0 20px', width: '100%', minHeight: 200 }}>
        {loading ? (
          <Loading />
        ) : (
          <Grid
            container
            direction="row"
            // justify="flex-start"
            justify="space-evenly"
            alignItems="stretch"
            spacing={3}
            style={{ margin: 0 }}
          >
            <Grid
              item
              lg={5}
              sm={5}
              md={5}
              style={{ overflow: 'auto', msScrollbarHighlightColor: 'red' }}
            >
              <Tree
                autoExpandParent={true}
                checkable
                defaultCheckedKeys={selected}
                defaultExpandAll={true}
                defaultExpandedKeys={selected}
                onCheck={onChecked}
                onSelect={onSelect}
                showIcon={false}
                treeData={treeData}
                switcherIcon={(props) => {
                  const { expanded, isLeaf } = props;

                  if (isLeaf) return <ChevronRight style={{ visibility: 'hidden' }} />;
                  return expanded ? <ChevronRight /> : <ExpandMore />;
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6}>
              <RuleDetails data={nodeSelection} />
            </Grid>
          </Grid>
        )}
      </div>
    </Paper>
  );
}
