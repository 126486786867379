import React, { useMemo } from 'react';
import AlarmIcon from '@mui/icons-material/Alarm';
import RightPanelLayout from './RightPanelLayout';
import { actionForUrl } from '../components/History/components/ServiceItem';

const AlertPanel = (props) => {
  const { data, isOpen, setSideBar } = props;

  const createUrl = (action) => {
    if (['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'].includes(action)) {
      return `?service=${actionForUrl(action)}`;
    }

    return `?action=${actionForUrl(action)}`;
  };

  const renderData = useMemo(() => {
    return {
      name: 'Alerts',
      renderCardIcon: (props) => {
        return <AlarmIcon {...props} />;
      },
      cardData: data.map((data) => {
        return {
          content: [
            {
              label: ' Content:',
              value: `${data.label} Action ${actionForUrl(data?.redirect_params?.action)}`,
            },
          ],
          isReadByUser: data?.read_at,
          notifyID: data?.id,
          url: `/project/${data.redirect_params?.project_id}/${data.redirect_params?.app_name}/${
            data.redirect_params?.app_name[0] +
            data.redirect_params?.app_name[data.redirect_params?.app_name?.length - 1]
          }/results/${data.redirect_params?.workload_id}${createUrl(
            data?.redirect_params?.action
          )}`,
        };
      }),
      isOpen,
      setSideBar,
      isActionRequired: true,
    };
  }, [data, isOpen]);

  return <RightPanelLayout pannelLayout={renderData} />;
};

export default AlertPanel;
