import React, { useEffect, useState } from 'react';
import getW3client, { getW3clientForUser } from '../../api/wsWorkloads';
import { Artifact, CreatedByUser } from '../../react-app-env';

type wsMessageType = {
  id: number;
  project_id: number;
  app: string;
  action: string;
  created_by: CreatedByUser;
  created_at: string;
  file_number: any;
  progress_percent: number;
  updated_at: string;
  status: 'end' | 'start' | 'progress';
  errors: any[];
  resources: any[];
};

const useWebSocketClient = (props) => {
  const { serviceFor, workloads, serviceName } = props;
  const [wsClientNew, setWsClientNew] = useState<WebSocket | null>(null);
  const [status, setStatus] = useState({
    percentage: [],
    isDone: false,
  });
  const [webSocketResponse, setWebSocketResponse] = useState([]);

  useEffect(() => {
    return () => {
      // console.log('removed wsClientNew');
      // console.log('wsClientNew', wsClientNew);
      if (wsClientNew) {
        console.log('websocket closed');
        wsClientNew.close();
      }
    };
  }, [wsClientNew]);

  function handleMessage(message: { data: string }, ws: { webSocketInstance: WebSocket }) {
    const msgData: wsMessageType[] = JSON.parse(String(message.data));
    // console.log('msgData', msgData);
    if (msgData && msgData.length > 0) {
      const rowWebsocket = msgData.filter((r) => workloads.includes(r.id));
      // const closeflag = rowWebsocket.find((r) => r.status !== 'end');

      let closeflag = true;
      for (const row of rowWebsocket) {
        if (row.status === 'progress') {
          closeflag = false;
        }
        if (row.status === 'end') {
          closeflag = true;
        }
      }

      // let isTaskDone = false;
      if (closeflag && ws?.webSocketInstance) {
        // isTaskDone = true;
        ws.webSocketInstance?.close();
      }

      setStatus((prev) => {
        return {
          ...prev,
          percentage: rowWebsocket.map((data) => data.progress_percent),
          isDone: closeflag,
        };
      });
      setWebSocketResponse(rowWebsocket);
    } else {
      // console.log('wsClientNew', ws);
      ws.webSocketInstance?.close();
      console.log('websocket closed');
    }
  }

  useEffect(() => {
    if (workloads.length > 0) {
      // const stingTemp = workloads.join(',');
      // console.log('stingTemp', stingTemp);

      if (wsClientNew) {
        wsClientNew.close();
        console.log('websocket closed');
      }

      let tempWS: WebSocket;
      const { name, id } = serviceFor;
      if (name === 'user') {
        const serve = serviceName === 'Document Translator' ? 'Translator' : 'none';
        tempWS = getW3clientForUser(serve);
      } else {
        tempWS = getW3client();
      }

      if (!wsClientNew) {
        setWsClientNew(tempWS);
      }
      tempWS.onopen = () => {
        const stingTemp = workloads.join(',');
        tempWS.send(`${stingTemp}`);
      };
      tempWS.onmessage = (message) => handleMessage(message, { webSocketInstance: tempWS });
      // console.log('workloads', workloads);
    }
    return () => {
      if (wsClientNew) {
        wsClientNew.close();
      }
    };
  }, [workloads]);

  return { status, webSocketResponse };
};

export default useWebSocketClient;
