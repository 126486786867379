import React from 'react';

const useError = () => {
  const [errorMsg, setErrorMsg] = React.useState('');

  React.useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg('');
      }, 2000);
    }
  }, [errorMsg]);

  return [errorMsg, setErrorMsg] as [string, React.Dispatch<React.SetStateAction<string>>];
};

export default useError;
