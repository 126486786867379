import {
  Column,
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, MenuItem, Paper, Select, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory, useParams } from 'react-router-dom';
import ProjectContext from '../../../ProjectContext';
import useProjectId from '../../../../../app/useProjectId';
import {
  removeUsersFromProject,
  searchUsers,
  updateUserRolesToProject,
} from '../../../../../api/users';
import LoadingApp from '../../../../../components/Core/LoadingApp';
import { IS_DEBUG_MODE } from '../../../../../const';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import ModalAddUsers from '../../../components/ModalAddUsers';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import ProjectUsersContainer from './styles/ProjectUsersStyle';

const ROLE_PERFORMER = 3;
const CustomCell = (props: any) => {
  const { column } = props;
  if (column.name === 'email' || column.name === 'name') {
    return (
      <Table.Cell
        {...props}
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          wordBreak: 'break-all',
        }}
      />
    );
  }
  return <Table.Cell {...props} />;
};

const ProjectUsers = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { setProject, setSnack, userJWT, getProject, setProjects, projects } = useContext(
    ProjectContext
  );
  const [projectUsers, setProjectUsers] = useState<any[]>([]);
  const { projectId } = useParams() as any;
  // const project = getProject(Number(projectId));
  // const { projects } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects);
  const [pageSizes] = useState([10, 20, 50]);
  const [selection, setSelection] = useState<any>(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [myUserRole, setMyUserRole] = useState(ROLE_PERFORMER);

  const getProjectUsers = () => {
    searchUsers({ project_id: projectId }).then((dbusers: any[]) => {
      const myusers = dbusers.map((userdt) => {
        return {
          ...userdt.user,
          role_id: userdt.user_project.role_id,
        };
      });
      setProjectUsers(myusers);
      const pos = myusers.findIndex((user) => user.id === userJWT.user_id);
      if (pos === -1) {
        setMyUserRole(ROLE_PERFORMER);
      } else {
        setMyUserRole(myusers[pos].role_id);
      }
    });
  };

  useEffect(() => {
    if (projects.length === 0) return;
    searchUsers({ project_id: projectId }).then((dbusers: any[]) => {
      setLoading(false);
      const myusers = dbusers.map((userdt) => {
        return {
          ...userdt.user,
          role_id: userdt.user_project.role_id,
        };
      });
      setProjectUsers(myusers);
      const pos = myusers.findIndex((user) => user.id === userJWT.user_id);
      if (pos === -1) {
        setMyUserRole(ROLE_PERFORMER);
      } else {
        setMyUserRole(myusers[pos].role_id);
      }
    });
  }, [projects]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleRemoveUsers = () => {
    if (selection.length === 0) setSnack('Please select a least one user', 'warning');
    else {
      const selectedRows = projectUsers
        .filter((row, index) => selection.includes(index))
        .map((row) => {
          return row.id;
        });
      removeUsersFromProject(projectId, selectedRows).then((resp: any) => {
        setSnack('Removed users successfully');
        // if cuurent in selectedRows update projects : you remove yourself
        if (selectedRows.includes(userJWT.user_id)) {
          const projectNew = projects.filter((p) => p.id !== Number(projectId));
          setProjects(projectNew, true);
          const inter = setInterval(() => {
            if (projects.length !== projectNew.length) {
              clearInterval(inter);
              history.push('/projects');
            }
          }, 500);
        } else {
          // update user in current project
          setProject({
            ...project,
            users: resp,
          });
          getProjectUsers();
          setSelection([]);
        }
      });
    }
  };
  const handleModalClose = () => {
    setModalOpen(false);
    getProjectUsers();
  };

  const handleChangeRoles = () => {
    const selectedRows: any = {};
    projectUsers
      .filter((row) => row.roleChanged)
      .map((row) => {
        selectedRows[row.id] = row.role_id;
        return row;
      });

    if (selectedRows === {}) setSnack('Please select a least one user', 'warning');
    else {
      updateUserRolesToProject(projectId, selectedRows).then((resp: any) => {
        setProject({
          ...project,
          users: resp,
        });
        setSnack('Updated user profile successfully');
        getProjectUsers();
        setSelection([]);
      });
    }
  };

  const handleAddUsers = (users: any[]) => {
    setProject({
      ...project,
      users,
    });
  };

  const handleRoleChange = (e: any, row: any, column: Column) => {
    const rowsEditedNew = projectUsers.map((r) => {
      if (r.id === row.id) {
        return { ...r, [column.name]: e.target.value, roleChanged: true };
      }
      return r;
    });

    setProjectUsers(rowsEditedNew);
  };

  const renderRoles = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    let result = value;
    if (myUserRole === 1) {
      // if (value === 1) result = 'Project Admin';
      // else
      result = (
        <Select
          defaultValue={value}
          className={row.roleChanged && 'dirty'}
          onChange={(e) => handleRoleChange(e, row, column)}
        >
          <MenuItem value={1}>Project Admin</MenuItem>
          <MenuItem value={2}>Project Leader</MenuItem>
          <MenuItem value={3}>Performer</MenuItem>
        </Select>
      );
    } else if (value === 1) result = 'Project Admin';
    else if (value === 2) result = 'Project Leader';
    else if (value === 3) result = 'Performer';
    return result;
  };

  if (loading) {
    return (
      <>
        <LoadingApp />
      </>
    );
  }

  return (
    <ProjectUsersContainer>
      {/* <ProjectBreadCrumbs projectId={projectId} /> */}
      {/* <Paper style={{ marginTop: '2.5rem' }}> */}
      <Box className="bloc">
        {/* <Typography variant="subtitle1" className={classes.heading}>
          Project Users
        </Typography> */}
        {IS_DEBUG_MODE && (
          <ReactJson
            src={{ users: project?.users, projectUsers }}
            collapsed={true}
            theme="monokai"
          />
        )}
        <ExpressGrid
          rows={projectUsers}
          columns={[
            { name: 'id', title: 'ID' },
            { name: 'name', title: 'User Name' },
            { name: 'email', title: 'Email' },
            { name: 'role_id', title: 'Profile' },
          ]}
        >
          <DataTypeProvider for={['role_id']} formatterComponent={renderRoles} />
          {(myUserRole === 1 || myUserRole === 2) && (
            <SelectionState selection={selection} onSelectionChange={setSelection} />
          )}
          {(myUserRole === 1 || myUserRole === 2) && <IntegratedSelection />}
          <SortingState defaultSorting={[{ columnName: 'Id', direction: 'asc' }]} />
          <IntegratedSorting />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <IntegratedPaging />
          <Table
            cellComponent={CustomCell}
            columnExtensions={[
              { columnName: 'id', width: '80px' },
              {
                columnName: 'name',
                width: '20%',
                wordWrapEnabled: true,
              },
              {
                columnName: 'email',
                width: '48%',
                wordWrapEnabled: true,
              },
              { columnName: 'role_id', width: 'auto' },
            ]}
            tableComponent={TableComponent}
          />
          <TableHeaderRow showSortingControls />
          {(myUserRole === 1 || myUserRole === 2) && <Toolbar />}
          {myUserRole === 1 && <ToolBarPlugin name="Save" onClick={handleChangeRoles} />}
          {(myUserRole === 1 || myUserRole === 2) && (
            <ToolBarPlugin name="Add" onClick={handleModalOpen} />
          )}
          {(myUserRole === 1 || myUserRole === 2) && (
            <ToolBarPlugin name="Delete" onClick={handleRemoveUsers} />
          )}
          {(myUserRole === 1 || myUserRole === 2) && <TableSelection showSelectAll />}
          <PagingPanel pageSizes={pageSizes} />
        </ExpressGrid>
        {modalOpen && (
          <ModalAddUsers
            open={modalOpen}
            onAddUsers={handleAddUsers}
            onClose={handleModalClose}
            projectId={projectId}
          />
        )}
      </Box>
      {/* </Paper> */}
    </ProjectUsersContainer>
  );
};

export default ProjectUsers;
