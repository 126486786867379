import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { useParams } from 'react-router-dom';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  CardHeader,
  IconButton,
  Button,
} from '@material-ui/core';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import './transitionCss.css';
import Kpis from '../../../components/Filter/Kpis';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';
import IsearcherStyle from '../../../components/Layout/IsearcherStyle';

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'id', title: 'ID' },
  { name: 'title', title: 'Title' },
  // { name: 'description', title: 'Description' },
  { name: 'site', title: 'Site' },
  { name: 'ctCode', title: 'CT Code' },
  { name: 'project', title: 'Project' },
  { name: 'Product', title: 'Product' },
  { name: 'product', title: 'product' },
  { name: 'category', title: 'Category' },
  { name: 'severityRS', title: 'severity' },
  { name: 'defectDetectionPhase', title: 'Defect Detection Phase' },
  { name: 'recordedDate', title: 'Recorded Date' },
  { name: 'state', title: 'State' },
  { name: 'subState', title: 'Sub State' },
  { name: 'linkCR', title: 'Link CR' },
  { name: 'scheduledVersion', title: 'Scheduled Version' },
  { name: 'type', title: 'Type' },
  { name: 'noDetectionCauseDefect', title: 'No Detection Cause Defect' },
  { name: 'submitterDate', title: 'Submitter Date' },
  { name: 'analyseDate', title: 'Analyse Date' },
  { name: 'realizationDate', title: 'Realization Date' },
  { name: 'validationDate', title: 'Validation Date' },
];

const ChangeRequest = ({ result, data1, data2, data3, data4, data5 }) => {
  const [rows, setRows] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [graphType, setGraphType] = React.useState<any>('bar');
  const [remind, setRemind] = useState(false);
  const [remidtext, setRemidText] = useState('');
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20, 50];
  const classes = IsearcherStyle();
  // const classes2 = resultStyle();
  const [currentPage, setCurrentPage] = useState(0);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    'linkCR',
    'scheduledVersion',
    'submitterDate',
    'type',
    'subState',
    'defectDetectionPhase',
    'noDetectionCauseDefect',
    'analyseDate',
    'realizationDate',
    'validationDate',
  ]);
  const exporterRef = useRef(null);
  const { projectId } = useParams() as any;
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const tableScrollComponent: React.FunctionComponent<Table.CellProps> = (props) => {
    return <Table.Container {...props} className={classes.tablescrol} />;
  };

  useEffect(() => {
    // const columns = Object.keys(result?.searchResults[0]);
    // const modifiedCol = columns.map((data) => {
    //   const t = data.toLowerCase();

    //   const temp = {
    //     name: data,
    //     title: t[0].toUpperCase() + t.slice(1),
    //   };
    //   return temp;
    // });
    // setColumns(modifiedCol);
    setRows(result?.searchResults);
  }, []);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'iSearcher_Report.xlsx');
    });
    // setRemind(true);
    // setRemidText('Downloaded');
  };

  const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
    <a
      id={`search-${row.id}`}
      // href="https://myworkplace.alstom.hub/Citrix/Alstom_PROD_StoreWeb/"
      href={row.url}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        // urlSearch(String(row.url));
      }}
      style={{ textDecoration: 'none' }}
      title={value}
    >
      {value}
    </a>
  );

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          width: '100%',
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          <span>
            <b>{result.numberOfResults}</b> results found
          </span>
        </Typography>
        <Box
          style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45', borderRadius: '20px' }}
        >
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider for={['title', 'description']} formatterComponent={renderText} />
            <FilteringState />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <Table
              // tableComponent={TableComponent}
              tableComponent={MultiAllocTableComp}
              containerComponent={tableScrollComponent}
              headComponent={tableHeaderComponent}
              columnExtensions={[
                { columnName: 'title', wordWrapEnabled: toggleHeight },
                { columnName: 'description', wordWrapEnabled: toggleHeight },
              ]}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                {
                  columnName: 'id',
                  width: 100,
                },
                {
                  columnName: 'title',
                  width: 200,
                },
                {
                  columnName: 'site',
                  width: 100,
                },
                {
                  columnName: 'description',
                  width: 200,
                },
                {
                  columnName: 'ctCode',
                  width: 100,
                },
                {
                  columnName: 'state',
                  width: 100,
                },
                {
                  columnName: 'Product',
                  width: 200,
                },
                {
                  columnName: 'linkCR',
                  width: 300,
                },
                {
                  columnName: 'project',
                  width: 100,
                },
                {
                  columnName: 'product',
                  width: 300,
                },
                {
                  columnName: 'scheduledVersion',
                  width: 350,
                },
                {
                  columnName: 'type',
                  width: 100,
                },
                {
                  columnName: 'subState',
                  width: 200,
                },
                {
                  columnName: 'severityRS',
                  width: 100,
                },
                {
                  columnName: 'category',
                  width: 100,
                },
                {
                  columnName: 'defectDetectionPhase',
                  width: 100,
                },
                {
                  columnName: 'noDetectionCauseDefect',
                  width: 100,
                },
                {
                  columnName: 'submitterDate',
                  width: 100,
                },
                {
                  columnName: 'recordedDate',
                  width: 100,
                },
                {
                  columnName: 'analyseDate',
                  width: 100,
                },
                {
                  columnName: 'realizationDate',
                  width: 100,
                },
                {
                  columnName: 'validationDate',
                  width: 100,
                },
              ]}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar rootComponent={toolbarComponent} />
            <ColumnChooser />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
            <PagingPanel
              pageSizes={pageSizes}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
          </ExpressGrid>
          <GridExporter
            ref={exporterRef}
            rows={rows}
            columns={columns}
            onSave={onSave}
            // customizeCell={customizeCell}
            // customizeHeader={customizeHeader}
          />
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={rows.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
        {/* <Kpis data1={data1} data2={data2} data3={data3} data4={data4} data5={data5} /> */}
        <Kpis data1={data1} data2={data2} data3={data3} data4={data4} />
      </Box>
    </>
  );
};

export default ChangeRequest;
