import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid as ExpressGrid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React from 'react';

import { Card } from '@material-ui/core';
import ImageIcon from '@mui/icons-material/Image';
import TableChartIcon from '@mui/icons-material/TableChart';
import { LangCode } from '../../../../../components/Datagrid/TranslationList';
import { handleColumns } from '../../../../../tools/datagrid';

import TableComponent from '../../../../../components/Datagrid/TableComponent';
import TableHeaderCellWrap from '../../../../../components/Datagrid/TableHeaderCellWrap';

import {
  multiTableRowComponent,
  tableHeaderComponent,
} from '../../../../../components/Datagrid/TStyles';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    // maxWidth: 1500,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});
const CustomWidthImageTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

const CustomWidthTableTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    backgroundColor: '#002A45',
    border: '1px solid black',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 1,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: '#eef1f9',
      '&:before': {
        backgroundColor: '#002A45',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid black`,
        fontSize: 50,
      },
    },
  },
});

type Props = {
  dataTable: DataTypeProvider.ValueFormatterProps;
  translationCode: LangCode;
  toggleHeight?: boolean;
  filename?: string;
  onSearchText?: any;
};

// table container style
const tableChildContainer: React.FunctionComponent<Table.CellProps> = (props) => {
  return (
    <Table.Container
      {...props}
      style={{
        maxHeight: '70vh',
        overflowY: 'auto',
        border: '1px solid black',
      }}
    />
  );
};

const RenderCellText = ({
  filename,
  dataTable,
  translationCode,
  originalText,
  toggleHeight,
  onSearchText,
}: Props) => {
  const { value, row } = dataTable;
  if (row.SubType === 'Image' || row.SubType === 'Images') {
    return (
      <>
        <CustomWidthImageTooltip
          placement="right-start"
          arrow
          // open={true}
          title={
            <>
              <Card
                style={{
                  background: 'while',
                  // color: '#002A45',
                  // fontWeight: 'bold',
                  margin: 0,
                  padding: 5,
                  maxHeight: 400,
                  overflowY: 'scroll',
                  maxWidth: 500,
                  overflowX: 'scroll',
                }}
              >
                <img
                  style={{ objectFit: 'cover' }}
                  src={`data:image/png;base64, ${row.RawData}`}
                  alt=""
                />
              </Card>
            </>
          }
        >
          <a
            id={`search-${row.id}`}
            href={`#top-pdf-${filename}`}
            onClick={() => {
              onSearchText(String(row.Text_original).trim().substr(0, 100), row.Coordinates);
            }}
            title=""
            label="as"
          >
            <ImageIcon />
          </a>
        </CustomWidthImageTooltip>
      </>
    );
  }
  if (row.SubType === 'Table' || row.SubType === 'Tables') {
    return (
      <>
        {row.RawData &&
          (row.RawData?.rows ? (
            <CustomWidthTableTooltip
              placement="right-start"
              arrow
              // open={true}
              title={
                <>
                  <Card
                    style={{
                      background: 'while',
                      // color: '#002A45',
                      // fontWeight: 'bold',
                      margin: 0,
                      padding: 5,
                      // maxHeight: '30vh',
                      // overflowY: 'scroll',
                      // maxWidth: 700,
                    }}
                  >
                    <ExpressGrid
                      rows={row.RawData.rows}
                      columns={handleColumns(row.RawData.columns)}
                    >
                      <Table
                        tableComponent={TableComponent}
                        containerComponent={tableChildContainer}
                        headComponent={tableHeaderComponent}
                        columnExtensions={handleColumns(row.RawData.columns).map((c) => ({
                          columnName: c.name,
                          wordWrapEnabled: true,
                        }))}
                      />
                      <TableHeaderRow
                        cellComponent={TableHeaderCellWrap}
                        rowComponent={multiTableRowComponent}
                      />
                    </ExpressGrid>
                  </Card>
                </>
              }
            >
              <a
                id={`search-${row.id}`}
                href={`#top-pdf-${filename}`}
                onClick={() => {
                  onSearchText(String(row.Text).trim().substr(0, 100), row.Coordinates);
                }}
                title=""
                label="asd"
                style={{ textDecoration: 'none' }}
              >
                <TableChartIcon />
                {row.Text}
              </a>
            </CustomWidthTableTooltip>
          ) : (
            <CustomWidthTableTooltip
              placement="right-start"
              arrow
              // open={true}
              title={
                <>
                  <Card
                    style={{
                      background: 'while',
                      // color: '#002A45',
                      // fontWeight: 'bold',
                      margin: 0,
                      padding: 5,
                      maxHeight: 400,
                      overflowY: 'scroll',
                      maxWidth: 500,
                      overflowX: 'scroll',
                    }}
                  >
                    <img
                      style={{ objectFit: 'cover' }}
                      src={`data:image/png;base64, ${row.RawData}`}
                      alt=""
                    />
                  </Card>
                </>
              }
            >
              <a
                id={`search-${row.id}`}
                href={`#top-pdf-${filename}`}
                onClick={() => {
                  onSearchText(String(row.Text).trim().substr(0, 100), row.Coordinates);
                }}
                title=""
                label="asd"
                style={{ textDecoration: 'none' }}
              >
                <TableChartIcon />
              </a>
            </CustomWidthTableTooltip>
          ))}
      </>
    );
  }
  let text2display = value;
  // text with translation if available
  if ('i18n' in row) {
    const { i18n } = row;
    if (i18n !== undefined && i18n !== null) {
      if (translationCode === '-') {
        text2display = i18n[originalText];
      } else {
        try {
          text2display = i18n[translationCode];
        } catch (error) {
          text2display = value;
        }
      }
    }
  }

  return (
    <CustomWidthTooltip
      placement="bottom-start"
      arrow
      // open={true}
      title={
        <>
          <Card
            style={{
              background: 'while',
              color: '#002A45',
              fontWeight: 'bold',
              margin: 0,
              padding: 5,
            }}
          >
            {value}
          </Card>
        </>
      }
    >
      <a
        id={`search-${row.id}`}
        href={`#top-pdf-${filename}`}
        onMouseEnter={() => {
          onSearchText(String(row?.Text)?.trim()?.substr(0, 100), row?.Coordinates);
        }}
        style={
          row.SubType === 'Heading'
            ? {
                fontWeight: 'bold',
                textDecoration: 'none',
                whiteSpace: toggleHeight ? 'pre-line' : 'inherit',
                color: '#002A45',
              }
            : {
                fontWeight: 'none',
                textDecoration: 'none',
                whiteSpace: toggleHeight ? 'pre-line' : 'inherit',
                color: '#002A45',
              }
        }
        // title={text2display}
      >
        {value}
      </a>
    </CustomWidthTooltip>
  );
};

export default RenderCellText;
