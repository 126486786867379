import React from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const RephraserContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#fafafa',
  borderRadius: '10px',
  border: '1px solid #998760CE',
  '& .mainGrid': {
    display: 'flex',
    height: '100%',
    '& .section': {
      flex: 1,
      padding: '0.5rem',
    },

    '& .header': {
      height: '3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      //   padding: '0.5rem',

      '& .label': {
        // backgroundColor: '#20635e !important',
        padding: '0.5rem',
        border: '1px solid #998760CE',
        borderRadius: '5px',

        // '& .MuiTypography-root': {
        // },
      },
    },

    '& .content': {
      height: 'calc(100% - 1.5rem - 50%)',
      display: 'flex',
      flexDirection: 'column',
      //   padding: '0.5rem',
    },
    '& .fotter': {
      height: 'calc(50% - 1.5rem)',
      '& .MuiTypography-root': {
        fontSize: '0.9rem',
      },
    },
  },
}));

const RulesList = styled((props) => <div {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: '#fafafa',
  maxHeight: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  overflow: 'auto',
  padding: 0,
  transition: 'all 0.3s ease-out',
  //   maxHeight: '500px', // Adjust this value based on your content
  '&.collapsed': {
    maxHeight: '0',
    overflow: 'hidden',
  },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccd6f2',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '& .eachRule': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '5px',
    padding: '0.5rem',
    '& .MuiCheckbox-root': {
      padding: 0,
    },
    '& .MuiTypography-root': {
      fontSize: '0.89rem',
    },
  },
}));

const RephraserInputFeild = styled((props) => <textarea {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
  alignItems: 'flex-start',
  fontFamily: 'Roboto Condensed, serif',
  transition: 'all 0.3s ease-out',
  //   border: '1px solid #ccd6f2',
  color: '#002a45',
  fontSize: '22px',
  outline: 'none',
  border: 'none',
  resize: 'none',
  '&::placeholder': {
    fontSize: '22px',
    color: 'rgba(128, 128, 128, 0.4)',
  },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccd6f2',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
}));

const RephraserButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  backgroundColor: '#20635e !important',
  color: '#fafafa',
  '&:disabled': {
    backgroundColor: '#20635e !important',
    color: '#fafafa',
    opacity: 0.4,
    cursor: 'not-allowed',
  },
}));

export { RephraserInputFeild, RulesList, RephraserButton };

export default RephraserContainer;
