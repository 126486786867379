import React from 'react';
import { Card, CardContent, Typography, Grid, Box, ListItem, List, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import DirectionsIcon from '@mui/icons-material/Directions';
import PeopleIcon from '@mui/icons-material/People';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

const BottomSection = styled((props) => <Box {...props} />)(({ theme, isSelected }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  padding: '2rem',
  backgroundColor: '#002A45',
}));

const IconBackground = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  height: '20rem',
}));
const MuiCard = styled((props) => <Box {...props} />)(({ theme }) => ({
  margin: 5,
  color: '#EEF1F9',
  width: '20%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  opacity: 1,
  background: 'transparent',
  transition:
    '.7s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
  cursor: 'pointer',
  // '&:hover': {
  //   backgroundColor: '#dde4ea',
  //   transition: 'all 0.4s ease-out',
  // },
}));

const FooterInFo = () => {
  return (
    <>
      <BottomSection>
        <MuiCard
          component="a"
          href="https://alstomgroup.sharepoint.com/sites/iSenS_Communication"
          target="_blank"
        >
          <PeopleIcon
            fontSize="large"
            style={{ color: 'rgb(74, 101, 238)', marginBottom: '0.5rem' }}
          />
          <Typography variant="h6" style={{ color: '#EEF1F9' }}>
            iSenS Community
          </Typography>
          {/* <Typography style={{ color: '#EEF1F9', fontSize: '0.89rem' }}>200+ recordings</Typography> */}
        </MuiCard>
        <MuiCard
          component="a"
          href="https://isens-qual.alstom.com/user-manual/user_manual.html"
          target="_blank"
        >
          <LocalLibraryIcon
            fontSize="large"
            style={{ color: 'rgb(74, 101, 238)', marginBottom: '0.5rem' }}
          />
          <Typography variant="h6" style={{ color: '#EEF1F9' }}>
            User Manual
          </Typography>
          {/* <Typography style={{ color: '#EEF1F9', fontSize: '0.89rem' }}>200+ recordings</Typography> */}
        </MuiCard>
        <MuiCard
          component="a"
          href="https://alstomgroup.sharepoint.com/sites/ISENS/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FISENS%2FShared%20Documents%2F98%20%2D%20Training%20and%20Videos%2FTraining%5FVideos&p=true&ga=1"
          target="_blank"
        >
          <OndemandVideoIcon
            fontSize="large"
            style={{ color: 'rgb(74, 101, 238)', marginBottom: '0.5rem' }}
          />
          <Typography variant="h6" style={{ color: '#EEF1F9' }}>
            Help Videos
          </Typography>
          {/* <Typography style={{ color: '#EEF1F9', fontSize: '0.89rem' }}>200+ recordings</Typography> */}
        </MuiCard>
        {/* </MuiCard> */}
      </BottomSection>
    </>
  );
};

export default FooterInFo;
