import { ApiWorkload3Status, Workload3Create } from 'WorkloadType';
import axios from './axios';
import { BACKEND_URL } from '../const';
import { Artifact } from '../react-app-env';

const workloadGetAll = async (projectId: number, withStatus = false): Promise<Artifact[]> => {
  try {
    const querystring = withStatus ? `status` : '';
    const response = await axios.get(
      `${BACKEND_URL.root}/projects/${projectId}/workloads?${querystring}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const userWorkloadGetbyPageNumber = async (
  userId: number,
  pageNumber: number,
  action: string,
  mode_changes: string,
  projectId: any
): Promise<Artifact[]> => {
  try {
    let response = '';

    if (mode_changes === 'user') {
      response = await axios.get(
        `${BACKEND_URL.root}/user_workload/${userId}/workloads/${pageNumber}/${action}`
      );
    } else {
      response = await axios.get(
        `${BACKEND_URL.root}/workloads/${projectId}/activity/${pageNumber}/${action}`
      );
    }
    return response?.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadGetbyPageNumber = async (
  projectId: number,
  pageNumber: number,
  filterValues: any
): Promise<Artifact[]> => {
  try {
    const { activityId, modules, action, fromDate, toDate } = filterValues;
    let valuesModules = '';
    let valuesActions = '';
    if (modules.length) {
      valuesModules = modules.map((item) => item).join(',');
    }
    if (action.length) {
      valuesActions = action.map((item) => item).join(',');
    }
    const response = await axios.get(
      `${BACKEND_URL.root}/projects/${projectId}/workloads/${pageNumber}?activityId=${activityId}&modules=${valuesModules}&action=${valuesActions}&fromDate=${fromDate}&toDate=${toDate}`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const workloadGetRfpHistorybyPageNumber = async (
  projectId: number,
  pageNumber: number
): Promise<Artifact[]> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/schedule_analysis_rfp_file/${projectId}/history`
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const rfpGetFiles = async (id: number): Promise<Workload3Create> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/schedule_analysis_rfp_file/get/${id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadGetById = async (id: number): Promise<Workload3Create> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/workloads/${id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const userWorkloadGetById = async (id: number): Promise<Workload3Create> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/user_workload/${id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getResultsByWorkload = async (id: number, id_service: string) => {
  try {
    if (id_service === 'user') {
      const response = await axios.get(`${BACKEND_URL.root}/user_workload/${id}`);
      return response.data as any;
    }
    if (id_service === 'project') {
      const response = await axios.get(`${BACKEND_URL.root}/workloads/${id}`);
      return response.data as any;
    }
    return null;
  } catch (error) {
    return Promise.reject(error);
  }
};

const workloadGetByIdIQualimeter = async (id: number): Promise<Workload3Create> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/workloads/${id}/qualimeter`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadIQualimeterPut = async (workloadId: any, payload: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BACKEND_URL.root}/workloads/${workloadId}/update_qualimeter`,
      payload
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadIQualimeterRepharasedText = async (workloadId: any, payload: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${workloadId}/rephrase`,
      payload
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadProjectStatus = async (
  project_id: number,
  workload_id: number
): Promise<ApiWorkload3Status> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/projects/${project_id}/workloads?workload_id=${workload_id}`
    );
    return response.data as any;
  } catch (error) {
    // do not throw error for status
    // console.error(error);
    return Promise.reject(error);
  }
};

const workloadStatus = async (id: number): Promise<ApiWorkload3Status> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/workloads/${id}/status`);
    return response.data as any;
  } catch (error) {
    // do not throw error for status
    // console.error(error);
    return Promise.reject(error);
  }
};

const UserWorkloadCreate = async (body: Workload3Create): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/user_workload`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadCreate = async (body: Workload3Create): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/workloads`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const scheduleStreamDates = async (body: Workload3Create, run_id): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${run_id}/stream4_dates`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const schedulephasesData = async (body: Workload3Create, run_id): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${run_id}/stream4_phases`,
      body
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const scheduleStreamPhases = async (run_id): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/workloads/stream/${run_id}/phase/data`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const schedulephasesTabFinal = async (body: Workload3Create, run_id): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/workloads/${run_id}/stream_data`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const scheduleKeyDates = async (body: Workload3Create, run_id): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/workloads/${run_id}/key_dates`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadDelete = async (body: Number[]): Promise<any> => {
  try {
    const response = await axios.delete(`${BACKEND_URL.root}/workloads`, {
      data: body,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadDeleteById = async (Ids: [], service: string): Promise<any> => {
  // console.log('Ids', Ids);
  // console.log('service', service);
  const pathUrl = service === 'user' ? 'user_workload' : 'workloads';
  try {
    const response = await axios.delete(`${BACKEND_URL.root}/${pathUrl}`, {
      data: Ids,
    });
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRun = async (id: number): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/workloads/${id}/run`);
    // JSON.stringify(response.data);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRunPostREX = async (id: number, runType: any): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/workloads/${id}/${runType}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRunPost = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${id}/run/${runType}`,
      payload
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const workloadRunschedulePost = async (id: number, payload: any, runType): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${id}/run/${runType}`,
      payload
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const userWorkloadRunPostForREX = async (id: number, runType: any): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/user_workload/${id}/${runType}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const userWorkloadRunPost = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/user_workload/${id}/run/${runType}`,
      payload
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRunAsync = (id: number, separator = ',') => {
  try {
    axios.get(`${BACKEND_URL.root}/workloads/${id}/run?separator=${encodeURIComponent(separator)}`);
  } catch (error) {
    console.log(error);
  }
};

const userWorkloadRunPostForTranslator = async (
  id: number,
  userId: number,
  defaultLanguage: string,
  payload: any,
  model: string
): Promise<any> => {
  try {
    /* eslint object-shorthand: [2, "consistent"] */
    /* eslint-env es6 */
    const payloads = {
      document: {
        filename: payload[0]?.document?.FileName,
        containername: payload[0]?.document?.Container,
        size: payload[0]?.document.size,
      },
      glossaryFile: {
        filename: payload[0]?.glossaryFile?.FileName,
        containername: payload[0]?.glossaryFile?.Container,
        // size: payload[0]?.document.size,
      },
      fromLanguage: payload[0]?.fromLanguage,
      toLanguage: defaultLanguage,
      id: id,
      deepL: model,
    };
    const response = await axios.post(
      `${BACKEND_URL.root}/user_translator/${userId}/itranslator/documenttranslator/${id}`,
      payloads
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRunPostForTranslator = async (
  id: number,
  projectId: number,
  defaultLanguage: string,
  payload: any,
  model: string
): Promise<any> => {
  try {
    /* eslint object-shorthand: [2, "consistent"] */
    /* eslint-env es6 */
    const payloads = {
      document: {
        filename: payload[0]?.document?.FileName,
        containername: payload[0]?.document?.Container,
        size: payload[0]?.document.size,
      },
      glossaryFile: {
        filename: payload[0]?.glossaryFile?.FileName,
        containername: payload[0]?.glossaryFile?.Container,
        // size: payload[0]?.document.size,
      },
      fromLanguage: payload[0]?.fromLanguage,
      toLanguage: defaultLanguage,
      id: id,
      deepL: model,
    };
    const response = await axios.post(
      `${BACKEND_URL.root}/projects/${projectId}/itranslator/documenttranslator/${id}`,
      payloads
    );
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRunPostPDF = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${id}/module2/pdf_selfdoc/`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const userWorkloadRunPostPDF = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/user_workload/${id}/module2/pdf_selfdoc/`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRunDocToDocPDF = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${id}/run/${runType}`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const workloadRunPostPdfCsv = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/workloads/${id}/run/${runType}`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
const userWorkloadRunDocToDocPDF = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/user_workload/${id}/run/${runType}`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const UserWorkloadRunPostPdfCsv = async (
  id: number,
  payload: any,
  runType = 'm2-onto-param-analysis'
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL.root}/user_workload/${id}/run/${runType}`,
      payload
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
export {
  workloadCreate,
  UserWorkloadCreate,
  workloadDelete,
  workloadDeleteById,
  workloadGetAll,
  userWorkloadGetbyPageNumber,
  workloadGetById,
  userWorkloadGetById,
  workloadGetByIdIQualimeter,
  workloadRun,
  workloadRunAsync,
  workloadStatus,
  workloadRunPost,
  workloadRunPostForTranslator,
  userWorkloadRunPostForTranslator,
  workloadProjectStatus,
  userWorkloadRunPost,
  userWorkloadRunPostForREX,
  workloadGetbyPageNumber,
  workloadIQualimeterPut,
  workloadIQualimeterRepharasedText,
  workloadRunPostREX,
  workloadRunPostPDF,
  userWorkloadRunPostPDF,
  getResultsByWorkload,
  workloadRunDocToDocPDF,
  workloadRunPostPdfCsv,
  userWorkloadRunDocToDocPDF,
  UserWorkloadRunPostPdfCsv,
  rfpGetFiles,
  workloadRunschedulePost,
  workloadGetRfpHistorybyPageNumber,
  scheduleStreamDates,
  scheduleStreamPhases,
  schedulephasesData,
  schedulephasesTabFinal,
  scheduleKeyDates,
};
