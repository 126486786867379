import jwt_decode from 'jwt-decode';
import { AuthenticationResult } from '@azure/msal-browser';
import msalInstance, { scopes } from '../api/auth';

const isTokenExpired = (decodedToken) => {
  if (decodedToken.exp < Date.now() / 1000) {
    return true;
  }
  return false;
};

const getLocalAzJWTContent = () => {
  const azJWT = localStorage.getItem('azJWT');
  return azJWT === null ? null : JSON.parse(azJWT);
};

export const saveAzJWT = (jwt) => {
  localStorage.setItem('azJWT', JSON.stringify(jwt));
};

export const getAzJWT = async () => {
  // Check if token exists and is still valid
  const azJWT = getLocalAzJWTContent();
  if (azJWT !== null) {
    console.log('AzJWT not expired', azJWT);
    const { idToken } = azJWT;
    const decodedIdToken = jwt_decode(idToken);
    if (!isTokenExpired(decodedIdToken)) {
      return azJWT;
    }
  }

  // get a new token
  const request = { scopes };
  let newAzJWT = null;
  // Get Microsoft Token
  await msalInstance.handleRedirectPromise();
  newAzJWT = await msalInstance.loginPopup(request);
  // Save Microsoft Token
  saveAzJWT(newAzJWT);

  return newAzJWT;
};

export const getAzJWTSync = () => {
  const azJWT = getLocalAzJWTContent();
  return azJWT;
};
