import { styled } from '@mui/material/styles';

const BORDER_RADIUS = '34px';

const ProjectIverifierContainer = styled('div')(({ theme }) => ({
  '& .root': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    opacity: '1 !important',
    fontSize: '-0.125rem',
  },
  '& .tab': {
    '& .MuiBox-root-11117': {
      padding: '0px',
    },
  },
  '& .box': {
    padding: '0px 0px 20px 0px',
    borderRadius: BORDER_RADIUS,
    border: `1px solid`,
    borderTopColor: 'transparent',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#EEF1F9',
    height: '100%',
    opacity: 1,
  },
  '& .boxDocumentReview': {
    padding: '0px 0px 0px 0px',
    borderRadius: BORDER_RADIUS,
    border: `1px solid`,
    borderTopColor: 'transparent',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#EEF1F9',
    margin: theme.spacing(1),
    opacity: 1,
  },
  '& .tabstyle': {
    borderRadius: BORDER_RADIUS,
    background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
    color: '#ffff !important',
    fontSize: '-0.125rem',
    fontWeight: 700,
    boxShadow: '0px 3px 6px #00000029',
  },
  '& .appStyle': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: BORDER_RADIUS,
    backgroundColor: '#fff',
    '& .MuiTouchRipple-root': {
      backgroundColor: 'none',
      transition: 'none',
      borderRadius: '50px !important',
    },
  },
  '& .iconLabelWrapper': {
    cursor: 'pointer',
    width: '20px',
    marginRight: '15px',
    '& .MuiTouchRipple-root': {
      borderRadius: '50px !important',
    },
  },
  '& .tabLebelText': {
    opacity: '1 !important',
    fontWeight: 'bold',
    font: 'normal normal medium 23px/36px Alstom',
    display: 'flex',
    padding: '11px 0 4px 0',
  },
  '& .labels': {
    padding: '18px 0',
  },
}));

export default ProjectIverifierContainer;
