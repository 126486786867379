const isearcherFilterReducer = (
  state: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  switch (action.type) {
    case 'set_iSearcher_default_fill': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'change_IsercherProductLine': {
      return {
        ...state,
        selectedIsearcherProductLine: action.payload,
      };
    }

    case 'change_IsercherPlatforms': {
      return {
        ...state,
        selectedIsearcherPlatforms: action.payload,
      };
    }

    case 'change_IsercherSolution': {
      return {
        ...state,
        selectedIsearcherSolution: action.payload,
      };
    }

    case 'change_IsercherProduct': {
      return {
        ...state,
        selectedIsearcherProduct: action.payload,
      };
    }

    case 'change_IsercherProjects': {
      return {
        ...state,
        selectedIsearcherProjects: action.payload,
      };
    }

    case 'change_IsercherDocumentProfiles': {
      return {
        ...state,
        selectedIsearcherDocumentProfiles: action.payload,
      };
    }

    case 'change_IsercherCountry': {
      return {
        ...state,
        selectedIsearcherCountry: action.payload,
      };
    }

    case 'change_IsercherMaxResultsValue': {
      return {
        ...state,
        selectedIsearcherMaxResultsValue: action.payload,
      };
    }

    case 'set_isearcher_door': {
      return {
        ...state,
        selectedIsearcherDoor: action.payload,
      };
    }

    case 'clear_isearcher_filters': {
      return {
        ...state,
        selectedIsearcherProductLine: [],
        selectedIsearcherPlatforms: [],
        selectedIsearcherSolution: [],
        selectedIsearcherProduct: [],
        selectedIsearcherProjects: [],
        selectedIsearcherDocumentProfiles: [],
        selectedIsearcherCountry: [],
        selectedIsearcherMaxResultsValue: 500,
        selectedIsearcherMinResultsValue: 1,
        selectedIsearcherDoor: '*',
        selectedIsearcherCheckedA: false,
      };
    }
    default:
      return state;
  }
};

export default isearcherFilterReducer;
