/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Typography,
  withStyles,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
  MenuItem,
  Card,
  CardContent,
  makeStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import CheckboxTree from 'react-checkbox-tree';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile,
} from 'react-icons/md';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  DataTypeProvider,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import styled from 'styled-components';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import MultiSelect from 'react-multi-select-component';
import { Workload3Create } from 'WorkloadType';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import Module6Context from '../Module6Context';
import TStyles, { rowComponent } from '../../../components/Datagrid/TStyles';
import {
  fileDetails,
  getDropdownsConfigAll,
  getMilestones,
  iCaptureCsv,
  iCaptureExcel,
  workloadGetRfpListbyPageNumber,
} from '../../../api/module1';
import Loading from '../../../components/Core/Loading';
import docIcon from '../../../assets/docx_ico.png';
import pdfIcon from '../../../assets/pdf_ico.png';
import xlsIcon from '../../../assets/xlsx_ico.png';
import NERParamTarget from '../../../components/NERParamTarget';
import { getCaptureName } from '../../../api/breakdowns';
import {
  scheduleStreamPhases,
  workloadCreate,
  workloadGetById,
  workloadRunPost,
} from '../../../api/workloads';
import { getM6FilterConditions } from '../../../api/module6';
import ProjectContext from '../../Project/ProjectContext';
import ScheduleAnalysisResult from './ScheduleAnalysisResult';
import UploadFile from '../../../components/Core/UploadFile';
import './ScheduleAnalysis.css';
// eslint-disable-next-line import/order
import { Modal } from '@mui/material';

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);
const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9', // Change this to your desired background color
    color: 'black', // Optional: Change the text color
    boxShadow: 'none', // Optional: Add a shadow to the tooltip
    padding: 0,
  },
  // arrow: {
  //   color: '#f5f5f9', // Optional: Match the arrow color to the tooltip background
  // },
}))(Tooltip);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);
const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];
const useStyles = makeStyles((theme) => ({
  tooltipPlacementTop: {
    backgroundColor: 'red !important',
    // fontWeight: 'bold',
  },
  textField: {
    // color: '#002A45 !important',
    backgroundColor: '#EEF1F9',
    borderRadius: '5px !important',
    '& .MuiInputBase-input': {
      // '&&:before': {
      //   borderBottom: 'none',
      // },
      // '&&:after': {
      //   borderBottom: 'none',
      // },
      color: '#002A45 !important',
      fontWeight: 'bold',
      border: '1px solid #002A45',
      borderRadius: 4,
      height: '5px',
    },
  },
}));
export function NERParamTargetDates(data: any) {
  const r = data;

  return (
    <>
      {r?.data?.NumberOfColumns > 0
        ? r.data.ColumNames.filter((cap) => cap === 'Planned finish').map((cap, index) => (
            <option key={`capture-type-${index}`} value={cap}>
              &nbsp;&nbsp;{cap}
            </option>
          ))
        : ''}
    </>
  );
}

const ScheduleAnalysis = React.memo(() => {
  const { project, files, setFiles } = useContext(Module6Context);
  const { setSnack } = useContext(ProjectContext);
  const classes = useStyles();
  const history = useHistory();
  const { projectId, workloadId } = useParams() as any;
  const [textCapture, setTextCapture] = useState([]);
  const [selection, setSelection] = useState([]);
  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);
  const [targetDate0, setTargetDate0] = useState<any>('');
  const [targetDate1, setTargetDate1] = useState<any>();
  const [columnCsv, setColumnCsv] = useState<any>([]);
  const [dataoutput, setDataoutput] = useState<any>([]);
  const [captureNames, setCaptureNames] = useState<any[]>([]);

  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();

  const [sheetName1, setSheetName1] = useState<any>();
  const [targetName1, setTargetName1] = useState<any>();
  const [columnName1, setColumnName1] = useState<any>();

  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const inputHandler = (event: any) => setInputValue(event.target.value);

  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);
  const [loadingMilestone, setLoadingMilestone] = useState(false);

  const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageSize, setPageSize] = useState(5);
  const pageSizes = [5, 10, 20, 50];
  const [currentPage, setCurrentPage] = useState(0);

  const [sigPlatform, setSigPlatform] = useState([]);
  const [sigOptions, setSigOptions] = useState('');

  const [turnkeyProducts, setTurnkeyProducts] = useState([]);
  const [turnkeyOptions, setTurnkeyOptions] = useState([]);

  const [infraProjects, setInfraProjects] = useState([]);
  const [infraOptions, setInfraOptions] = useState([]);
  const [resultData, setResultData] = useState();

  const [sigResponseConfig, setSigResponseConfig] = useState([]);
  const [turnkeyResponseConfig, setTurnkeyResponseConfig] = useState([]);
  const [infraResponseConfig, setInfraResponseConfig] = useState([]);

  const [checked, setChecked] = useState([]);
  const [checkedAuto, setCheckedAuto] = useState([]);
  const [listRfpJson, setListRfpJson] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const [checkedTurnedKey, setCheckedTurnedKey] = useState([]);
  const [expandedTurnedKey, setExpandedTurnedKey] = useState([]);

  const [checkedInfra, setCheckedInfra] = useState([]);
  const [expandedInfra, setExpandedInfra] = useState([]);

  const [mileStoneData, setMileStoneData] = useState();
  const [listRFPData, setListRFPData] = useState([]);
  const [listRFPStreamDatesData, setListRFPStreamDatesData] = useState([]);
  const [newUserData, setNewUserData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [phasesData, setPhasesData] = useState([]);
  const [commonChecked1, setCommonChecked1] = useState([]);
  const [commonCheckedBool1, setCommonCheckedBool1] = useState(false);
  const [streamDateVal, setStreamDateVal] = useState('');
  const [commonCheckedBool2, setCommonCheckedBool2] = useState(false);
  const [commonChecked2, setCommonChecked2] = useState([]);
  const [commonExpanded, setCommonExpanded] = useState([]);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [open, setOpen] = useState(false);
  const [pFinish, setPFinish] = useState('');
  const [Language0, setLanguage0] = useState<any>();
  const [Language1, setLanguage1] = useState<any>();

  const nodeturnkey = turnkeyResponseConfig;
  const nodeinfra = infraResponseConfig;

  // function removeChildren(node) {
  //   for (let i = 0; i < node.children.length; i++) {
  //     const child = node.children[i];
  //     if (child.label === 'Detailed activities' || child.label === 'Typical Deliverables') {
  //       node.children.splice(i, 1);
  //       i--;
  //     } else {
  //       removeChildren(child);
  //     }
  //   }
  // }
  // removeChildren(a[0]);
  const setDisabledProperty = (nodes, checked) => {
    return nodes.map((node) => {
      const value = node.value.split('==')[0];

      const checkedValue = checked[0]?.split('==')[0];
      let disabled = false;
      if (checkedValue) {
        if (!(value === checkedValue)) {
          disabled = true;
        } else disabled = false;
      }

      return {
        ...node,
        disabled,
        children: node.children ? node.children : null,
      };
    });
  };

  const nodes = useMemo(() => {
    return setDisabledProperty(sigResponseConfig, checked);
  }, [checked, sigResponseConfig]);
  useEffect(() => {
    workloadGetRfpListbyPageNumber(projectId).then((response) => {
      setListRFPData(response);
    });
  }, []);
  useEffect(() => {
    getDropdownsConfigAll().then((response) => {
      response.map((resp) => {
        if (resp?.signaling_name === 'sig_platform') {
          setSigResponseConfig(resp?.signaling_tree_data);
        }
        if (resp?.signaling_name === 'turnkey_value') {
          setTurnkeyResponseConfig(resp?.signaling_tree_data);
        }
        if (resp?.signaling_name === 'infra_value') {
          setInfraResponseConfig(resp?.signaling_tree_data);
        }
      });
    });
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
      }
    });
    getM6FilterConditions().then((data) => {
      // const sigArray = [] as any;
      const trunkeyArray = [] as any;
      const infraArray = [] as any;
      if (data?.SigPlatform) {
        // data?.SigPlatform.map((item: any) => {
        //   sigArray.push({
        //     label: item,
        //     value: item,
        //   });
        // });
        setSigPlatform(data?.SigPlatform);
      }

      if (data?.TurnkeyProducts) {
        data?.TurnkeyProducts.map((item: any) => {
          trunkeyArray.push({
            label: item,
            value: item,
          });
        });
        setTurnkeyProducts(trunkeyArray);
      }

      if (data?.InfraProjects) {
        data?.InfraProjects.map((item: any) => {
          infraArray.push({
            label: item,
            value: item,
          });
        });
        setInfraProjects(infraArray);
      }
    });
  }, []);

  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    setLoadingResult(true);
    // setLoadingMilestone(true);
    workloadGetById(workloadId)
      .then((payload) => {
        setLoadingResult(false);

        if (payload?.results_data[0]?.OTD) {
          setMileStoneData(payload.results_data[0]);
          setResultData(payload?.results_data[1]);
        } else {
          // it will set the schedule analysis data
          // it will set the milestone data taking more time
          setMileStoneData(payload.results_data[1]);
          setResultData(payload?.results_data[0]);
        }

        // setResultData(payload.results_data[0]); // it will set the schedule analysis data
        // setMileStoneData(payload.results_data[1]); // it will set the milestone data taking more time

        setDisplay(true);
      })
      .catch(() => {
        setDisplay(false);
      });
  }, [files, projectId, workloadId]);

  const getFileDetails = (inputFiles: any[]) => {
    setLoad(true);
    setDisplay(false);
    const falseArray: any = [];
    const arrayIs: any = [{ columnName: 'Entity', width: 300 }];
    for (const myFile of inputFiles) {
      falseArray.push(
        fileDetails(files[myFile].filename, files[myFile].filename, files[myFile].container)
      );
      arrayIs.push({
        columnName: files[myFile].filename.split('.').slice(0, -1).join('.'),
        width: 300,
      });
    }

    Promise.all(falseArray)
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        const ar: any = [];
        data.map((c: any) => ar.push(c.output));
        setDataoutput(ar);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });

    // promise all end
  };

  const handleCheck = (currentChecked) => {
    setChecked(currentChecked);
  };

  const onCheckedTurnKey = (value) => {
    setCheckedTurnedKey(value);
  };

  const onCheckedInfra = (value) => {
    setCheckedInfra(value);
  };

  const handleSelectionChange = (selectedKeys: any) => {
    setErrorMessage('');
    const ArrayFiles = selectedKeys;
    setSelection(selectedKeys);
    if (ArrayFiles.length === 0) {
      setSheetName0('');
      // setSheetName1('');
      setTargetColumnsAre([]);
      setChecked([]);
    }
    if (ArrayFiles.length > 1) {
      setSnack('more than 1 files not allowed!', 'error');
    } else {
      getFileDetails(ArrayFiles);
    }
  };

  function HandleReg(e: any, indexIs: any) {
    if (e.target.name === 'sheetname') {
      const state = [...targetColumnsAre];
      state[indexIs] =
        dataoutput[indexIs].Sheets[dataoutput[indexIs].Sheet_names.indexOf(e.target.value)];
      setTargetColumnsAre(state);
      if (
        !state[0].ColumNames.includes('Description') ||
        !state[0].ColumNames.includes('Categories') ||
        !state[0].ColumNames.includes('Planned finish')
      ) {
        setSnack(
          'selected sheet doesnt contain description or categories or Planned Finish column',
          'error'
        );
      }
      if (state[0].ColumNames.includes('Planned finish')) {
        setPFinish('Planned finish');
      }
      Object.assign(dataoutput[indexIs], { SheetName: e.target.value });
      if (indexIs === 0) {
        setSheetName0(e.target.value);
      } else {
        setSheetName1(e.target.value);
      }
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetName0(e.target.value);
      } else {
        setTargetName1(e.target.value);
      }
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetName0(e.target.value);
      } else {
        setTargetName1(e.target.value);
      }
    }
    if (e.target.name === 'FinishDates') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetDate0(e.target.value);
      } else {
        setTargetDate1(e.target.value);
      }
    }
    if (e.target.name === 'Language') {
      Object.assign(dataoutput[indexIs], { Language: e.target.value });
      if (indexIs === 0) {
        setLanguage0(e.target.value);
      } else {
        setLanguage1(e.target.value);
      }
    }
  }
  function handleActivity(e: any) {
    // if (e.target.name === 'targetColumn') {
    //   Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
    //   if (indexIs === 0) {
    //     setTargetName0(e.target.value);
    //   } else {
    //     setTargetName1(e.target.value);
    //   }
    // }
    // const actname = 'newRFP2';

    const matchedItem = listRFPData.find((item) => item?.activityName === e.target.value);

    if (matchedItem) {
      scheduleStreamPhases(matchedItem.id)
        .then((payload) => {
          const desiredData = payload.find((item) => item.stream_phases === null);
          const desiredPhasesData = payload
            .find((item) => item.stream_dataes === null)
            ?.stream_phases?.response?.map((item) => item?.phase);
          setDatesData(desiredData);
          setPhasesData(desiredPhasesData);
          // console.log('datesData', desiredPhasesData);

          const allInfoArray = payload
            .filter(
              (item) =>
                item.stream_dataes &&
                item.stream_dataes.response &&
                item.stream_dataes.response.schedule
            )
            .flatMap((item) =>
              item.stream_dataes.response.schedule.map((scheduleItem) => scheduleItem.info)
            );
          setListRFPStreamDatesData(allInfoArray);
          // setFinalPayload(payload);
          // setLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      console.log('Activity not found');
    }
    const a = listRFPData
      .filter((item) => item.activityName === e.target.value)
      .map((item) => item.results_data);
    const d = JSON.parse(a[0]);
    const arr = [];
    d.map((item) => arr.push(item.WBS_Path));
    // arr.push(d[0]?.WBS_Path);
    setListRfpJson(d);
    setCheckedAuto(arr);
    setChecked(arr);
  }
  const streamDates = (e) => {
    // setSelectedValues(e.target.value);
    // setStreamDateVal(e.target.value);
    // const matchedItems = datesData?.stream_dataes?.response.schedule
    //   .filter((item) => e.target.value.includes(item.info)) // Filter matched items
    //   .map((item) =>
    //     JSON.stringify([
    //       {
    //         'Description of Key Dates': item.info,
    //         // eslint-disable-next-line prefer-template
    //         'Dates/Duration': item.date,
    //       },
    //     ])
    //   );
    if (e.target.value.includes('all')) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleSelectAllClick();
    } else {
      setSelectedValues(e.target.value);
      const matchedItems = datesData?.stream_dataes?.response.schedule
        .filter((item) => e.target.value.includes(item.info)) // Filter matched items
        .map((item) => ({
          'Description of Key Dates': item.info,
          // eslint-disable-next-line prefer-template
          'Dates/Duration': item.date,
        }));
      const notS = [
        'SCHEDULE 23 Cyber Security 02 08 2029 is the last for security',
        '7 days after NTP we need to start maintanence',
      ];

      const result = {
        Text: JSON.stringify(matchedItems),
      };
      // const matchedSchedule = datesData?.stream_dataes?.response.schedule.find(
      //   (item) => item.info === e.target.value
      // );

      setNewUserData(result);
    }
  };
  const handleSelectAllClick = () => {
    if (selectedValues.length === listRFPStreamDatesData.length) {
      setSelectedValues([]);
    } else {
      setSelectedValues(listRFPStreamDatesData);
      const matchedItems = datesData?.stream_dataes?.response.schedule
        .filter((item) => listRFPStreamDatesData.includes(item.info)) // Filter matched items
        .map((item) => ({
          'Description of Key Dates': item.info,
          // eslint-disable-next-line prefer-template
          'Dates/Duration': item.date,
        }));
      const notS = [
        'SCHEDULE 23 Cyber Security 02 08 2029 is the last for security',
        '7 days after NTP we need to start maintanence',
      ];

      const result = {
        Text: JSON.stringify(matchedItems),
      };
      // const matchedSchedule = datesData?.stream_dataes?.response.schedule.find(
      //   (item) => item.info === e.target.value
      // );

      setNewUserData(result);
    }
  };
  // console.log('newUserData', newUserData);

  function changeSigPlatform(event: any) {
    setSigOptions(event.target.value);
  }

  function changeTrunkeyProducts(option: any) {
    setTurnkeyOptions(option);
  }

  function trunkeyValueRendered() {
    if (!turnkeyOptions.length) {
      return <b style={{ color: '#002A45' }}>Select Turnkey value</b>;
    }
    return null;
  }

  function infraValueRendered() {
    if (!infraOptions.length) {
      return <b style={{ color: '#002A45' }}>Select Infra value</b>;
    }
    return null;
  }

  function changeInfraProject(option: any) {
    setInfraOptions(option);
  }
  const handleCommonTreeCheck1 = (checkedItem) => {
    const isParent1Checked = checkedItem.includes('Detailed activities');
    setCommonChecked1(checkedItem);
    setCommonCheckedBool1(isParent1Checked);
  };
  const handleCommonTreeCheck2 = (checkedItem) => {
    const isParent1Checked = checkedItem.includes('Typical Deliverables');
    setCommonChecked2(checkedItem);
    setCommonCheckedBool2(isParent1Checked);
  };

  function handleAnalyze() {
    setErrorMessage('');
    if (dataoutput[0].FileType === 'Excel') {
      if (!sheetName0 || !targetName0) {
        setSnack('Please select both dropdown options', 'error');
        return;
      }
    } else if (dataoutput[0].FileType === 'CSV') {
      if (!columnName0) {
        setSnack('Please select dropdown options', 'error');
        return;
      }
    }
    // if (!targetDate0) {
    //   setSnack('please select the Finish Dates Column', 'error');
    //   return;
    // }
    // if (selectedValues.length === 0) {
    //   setSnack('Pleaseselect Key Dates', 'error');
    //   return;
    // }

    if (checked[0]?.split('==')[0].length === 0) {
      setSnack('Please select sig platform options', 'error');
      return;
    }
    if (captureNames.includes(inputValue.trim())) {
      setSnack('That activity name is taken.Please try another', 'error');
      return;
    }
    if (inputValue.trim().length === 0) {
      setSnack('Please enter activity name', 'error');
      return;
    }
    if (checked.length === 0 && checkedTurnedKey.length === 0 && checkedInfra.length === 0) {
      setSnack('Please select  the value', 'error');
      return;
    }

    // if (checkedTurnedKey.length === 0) {
    //   setSnack('Please select TurnedKey value', 'error');
    //   return;
    // }
    // if (checkedInfra.length === 0) {
    //   setSnack('Please select Infra value', 'error');
    //   return;
    // }

    setLoading(true);
    setDisplay(false);

    let tempdataoutput: any = [];
    tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
    Promise.all([
      fileDetails(
        files[selection[0]].filename,
        files[selection[0]].filename,
        files[selection[0]].container
      ),
      // fileDetails(
      //   files[selection[1]].filename,
      //   files[selection[1]].filename,
      //   files[selection[1]].container
      // ),
    ])
      .then(async (responseAll) => {
        // for data 0 process to do iCapture
        // console.log('resp', responseAll);
        const arrPromises: any = [];
        function captureAll(): Promise[] {
          for (const [i, itemData] of responseAll.entries()) {
            if (itemData.output.FileType === 'Excel') {
              let columnPosition: any;
              if (sheetName0 !== undefined) {
                if (sheetName0 !== null) {
                  columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName0);
                }
              }
              if (sheetName1 !== undefined) {
                if (sheetName1 !== null) {
                  columnPosition = (responseAll[1]?.output?.Sheet_names).indexOf(sheetName1);
                }
              }
              const sheetNameFind = i === 0 ? sheetName0 : sheetName1;
              const body = {
                FilePath: itemData.output.FilePath,
                Container: itemData.output.Container,
                BlobName: itemData.output.Blob,
                NameOfCapture: inputValue,
                Sheet: sheetNameFind,
                TargetColumn: i === 0 ? targetName0 : targetName1,
                ColumnNames: itemData?.output?.Sheets[columnPosition]?.ColumNames,
                Header: true,
              };
              arrPromises.push(iCaptureExcel(body));
              // to arrange sheet number in first position because of middleware 388-405
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < tempdataoutput.length; index++) {
                if (tempdataoutput[index].FileName === itemData.output.Blob) {
                  // eslint-disable-next-line no-plusplus
                  for (let jindex = 0; jindex < tempdataoutput[index].Sheets.length; jindex++) {
                    // eslint-disable-next-line eqeqeq
                    if (tempdataoutput[index].Sheets[jindex].SheetName == sheetNameFind) {
                      tempdataoutput[index].Sheet = tempdataoutput[index].Sheets[jindex];
                    }
                  }
                }
              }
            } else if (itemData.output.FileType === 'CSV') {
              const body = {
                FilePath: itemData.output.FilePath,
                Container: itemData.output.Container,
                BlobName: itemData.output.Blob,
                NameOfCapture: inputValue,
                TargetColumn: i === 0 ? columnName0 : columnName1,
                ColumnNames: itemData?.output?.ColumnNames,
                Header: true,
              };
              arrPromises.push(iCaptureCsv(body));
            }
          }
          return arrPromises;
        }

        const responseCharacters = await Promise.all(captureAll());
        const len =
          JSON.parse(responseCharacters[0]?.output?.Text)?.length > 0
            ? JSON.parse(responseCharacters[0]?.output?.Text)?.length
            : 0;
        if (len === 0) {
          setSnack('Invalid file format', 'error');
          setLoading(false);
          setDisplay(false);
          return;
        }
        // console.log('rr', responseCharacters);
        const r = responseCharacters.map((d, index) => ({
          ...d,
          FileName: responseAll[index].output.Blob,
          NameOfCapture: inputValue,
          scopeSettingOP: tempdataoutput,
          TextLength: len,
          target: columnName0,
          SigPlatform: checked,
          TurnkeyProducts: checkedTurnedKey,
          InfraProjects: checkedInfra,
          detailActivities: commonCheckedBool1,
          typicalDeliverables: commonCheckedBool2,
          target_dates_column: pFinish,
          phases: phasesData,
          userDates_df:
            newUserData.length === 0
              ? {
                  Text: '[]',
                }
              : newUserData,
          userDates_desc_column: 'Description of Key Dates',
          userDates_date_coulmn: 'Dates/Duration',
          language: dataoutput
            .flatMap((file) => file.Sheets)
            .find((sheet) => sheet.SheetName === sheetName0)?.Language,
          languageCode: dataoutput
            .flatMap((file) => file.Sheets)
            .find((sheet) => sheet.SheetName === sheetName0)?.LanguageCode,
        }));
        // console.log('rr', responseCharacters);
        // const r = responseCharacters;
        // workload here
        workloadCreate({
          project_id: projectId,
          app: 'module6',
          action: 'ScheduleAnalysis',
          resources: r,
        })
          .then((workloadNew: Workload3Create) => {
            // post the milestone in workload
            const projectType: Iterable<any> | null | undefined = [];

            checked.map((item) => projectType.push(item?.split('==')[0]));
            checkedTurnedKey.map((item) => projectType.push(item?.split('==')[1]));
            checkedInfra.map((item) => projectType.push(item?.split('==')[1]));

            getMilestones(
              Number(workloadNew.id),
              responseCharacters[0]?.output?.Text,
              [...new Set(projectType)],
              'm6-schedule-milestone'
            );

            // post the schedule analysis  in workload
            workloadRunPost(Number(workloadNew.id), r, 'm6-schedule')
              .then(() => {
                window.history.pushState('', '', `/projects/${projectId}`);
                history.push(`/project/${projectId}/?service=${'project_history'}`);
              })
              .catch((e) => {
                setErrorMessage(e.detail);
                setSnack('Error while workload post.', 'error');
                setDisplay(false);
                setLoading(false);
              })
              .finally(() => {});
          })
          .catch(() => {
            setSnack('Error while workload create.', 'error');
            setDisplay(false);
            setLoading(false);
          })
          .finally(() => {});
      })
      .catch((error) => {
        console.log(error);
        setSnack('Error while processing the scopeSetting for Selected file.', 'error');
        setDisplay(false);
        setLoading(false);
      });
  }

  const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />,
    expandClose: <MdChevronRight className="rct-icon rct-icon-expand-close" />,
    expandOpen: <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />,
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />,
    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
    leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />,
  };
  // Find the matching sheet in dataoutput
  const matchingSheet = dataoutput
    .flatMap((file) => file.Sheets)
    .find((sheet) => sheet.SheetName === sheetName0);
  // State to manage the language value
  const [language, setLanguage] = useState(matchingSheet ? matchingSheet.Language : '');

  // Effect to update the language state when matchingSheet changes
  useEffect(() => {
    if (matchingSheet) {
      setLanguage(matchingSheet.Language);
    }
  }, [matchingSheet]);
  // Handle when hovering over a node
  const handleMouseEnter = (label) => {
    setHoveredNode(label);
    setOpen(true); // Open the modal when hovered
  };

  // Handle when the mouse leaves the node
  const handleMouseLeave = () => {
    setOpen(false); // Close the modal when hover ends
    setHoveredNode(null);
  };
  const renderNodeWithTooltip = (label, value) => {
    // Function to find labels based on the checked values
    const findLabelsForCheckedNodes = (nodes, checked) => {
      let labels = [];

      nodes.forEach((node) => {
        // If the node value is in the checked array, add its label to the result
        if (checked.includes(node.value)) {
          labels.push(node.label);
        }

        // If the node has children, recursively search for the labels in its children
        if (node.children) {
          labels = labels.concat(findLabelsForCheckedNodes(node.children, checked));
        }
      });

      return labels;
    };

    const checkedLabels = findLabelsForCheckedNodes(nodes, checkedAuto);

    // console.log(checkedLabels, listRfpJson);

    const isChecked = checkedLabels.includes(label);
    const compare_path = 'Urban==CNS==Cybersecurity Solution';

    // Function to find the matching WBS_Path
    function findMatchingPath(arr, path) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].WBS_Path === path) {
          return arr[i];
        }
      }
      return null; // Return null if no match is found
    }

    const result = findMatchingPath(listRfpJson, value);

    return (
      <>
        <CustomTooltip
          title={
            isChecked ? (
              <>
                {result && (
                  <Card>
                    <CardContent>
                      <span style={{ color: 'red', fontWeight: 'bold' }}>Entity from doc:</span>
                      {result.Entity_from_doc}
                    </CardContent>
                    <CardContent>
                      <span style={{ color: 'red', fontWeight: 'bold' }}>Node Path:</span>
                      {result['Node Path']}
                    </CardContent>
                    <CardContent>
                      <span style={{ color: 'red', fontWeight: 'bold' }}>PBS Element Name:</span>
                      {result['PBS Element Name']}
                    </CardContent>
                  </Card>
                )}
              </>
            ) : (
              ''
            )
          }
          arrow
        >
          <span>{label}</span>
        </CustomTooltip>
      </>
    );
  };
  // Render nodes with mouse event listeners for hovering
  const renderNodeLabel = (label) => (
    <>
      <span onMouseEnter={() => handleMouseEnter(label)} onMouseLeave={handleMouseLeave}>
        {label}
      </span>
    </>
  );
  // Recursively map nodes and apply the hover effect
  const mapNodesWithHoverEffect = (node) => ({
    ...node,
    label: renderNodeWithTooltip(node.label, node.value), // Apply hoverable label to each node
    children: node.children
      ? node.children.map((child) => mapNodesWithHoverEffect(child)) // Recursively apply hoverable labels to children
      : [],
  });

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <UploadFile files={files} setFiles={setFiles} projectId={projectId} />
      </div>
      <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
        <ExpressGrid rows={files} columns={columns}>
          <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
          <FilteringState />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <VirtualTable />
          <Table tableComponent={TableComponent} />
          <TableColumnResizing
            defaultColumnWidths={[
              { columnName: 'filename', width: '300px' },
              { columnName: 'size', width: '200px' },
              { columnName: 'folderName', width: '200px' },
              { columnName: 'date', width: '200px' },
              { columnName: 'userName', width: '200px' },
            ]}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow rowComponent={rowComponent} />
          <TableSelection />
          <PagingPanel pageSizes={pageSizes} />
        </ExpressGrid>
      </Box>

      {load && <Loading />}
      <Paper style={{ marginTop: '5px' }}>
        {/* <div style={{ backgroundColor: 'rgb(238, 241, 249)' }}> */}
        {dataoutput.length > 0 && (
          <>
            <div style={{ border: 'solid black', borderWidth: '0.1px' }}>
              <Grid container style={{ marginLeft: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <SettingsApplicationsOutlinedIcon style={{ width: 40, height: 40 }} />
                  </div>
                  <div>
                    <h2>Scope Settings</h2>
                  </div>
                </div>
              </Grid>
              <Grid spacing={0} style={{ padding: '1rem', paddingBottom: 0 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        gap: '40px',
                      }}
                    >
                      {dataoutput.map((c, indexIs) => (
                        <FormControl variant="outlined">
                          <span>
                            {c?.FileType === 'unsupported' ? (
                              <img
                                src={docIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'Excel' || c?.FileType === 'CSV' ? (
                              <img
                                src={xlsIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {c?.FileType === 'PDF' ? (
                              <img
                                src={pdfIcon}
                                alt="isens"
                                style={{
                                  width: 20,
                                  height: 25,
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              ''
                            )}
                            &nbsp;
                            <Tooltip title={c?.Blob} placement="top" arrow>
                              <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                                {c?.Blob.substring(0, 25)}
                              </b>
                            </Tooltip>
                          </span>
                          {c?.FileType !== 'unsupported' ? (
                            <span>
                              <span style={{ color: 'red' }}>{c?.Error}</span>
                            </span>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {dataoutput.map((c, indexIs) => (
                        <Grid container xs={12}>
                          <Grid item xs={2}>
                            {}
                          </Grid>
                          {c.FileType === 'Excel' && (
                            <Grid item xs={10}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  gap: '20px',
                                  marginBottom: '2rem',
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  style={{ flexBasis: '50%', height: '2.5rem' }}
                                >
                                  <InputLabel
                                    htmlFor="outlined-age-native-simple"
                                    style={{ color: '#002A45', fontWeight: 'bold' }}
                                    size="small"
                                  >
                                    Sheet Column
                                  </InputLabel>
                                  <Select
                                    native
                                    label="Sheet  Name"
                                    size="small"
                                    // name={'sheetname'.concat(indexIs)}
                                    name="sheetname"
                                    style={{ width: '100%' }}
                                    onChange={(e) => HandleReg(e, indexIs)}
                                    defaultValue=""
                                  >
                                    <option aria-label="None" value="" disabled />
                                    {c?.Sheet_names !== null
                                      ? c?.Sheet_names.map((cap: string, index: number) => (
                                          <option key={`capture-type-${index}`} value={cap}>
                                            &nbsp;&nbsp;{cap}
                                          </option>
                                        ))
                                      : ''}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  style={{ flexBasis: '50%', height: '2.5rem' }}
                                >
                                  <InputLabel
                                    htmlFor="outlined-age-native-simple"
                                    style={{ color: '#002A45', fontWeight: 'bold' }}
                                  >
                                    Target Column
                                  </InputLabel>
                                  {/* {console.log(
                                    'targetColumnsAre',
                                    JSON.parse(targetColumnsAre[indexIs]?.Text).map(
                                      (item) => item['Planned finish']
                                    )
                                  )} */}
                                  <Select
                                    native
                                    label="Target  Column"
                                    name="targetColumn"
                                    style={{ width: '100%' }}
                                    onChange={(e) => HandleReg(e, indexIs)}
                                    defaultValue=""
                                  >
                                    <option aria-label="None" value="" disabled />

                                    <>
                                      <NERParamTarget
                                        key={`NERParamTarget-${indexIs}`}
                                        data={targetColumnsAre[indexIs]}
                                      />
                                    </>
                                  </Select>
                                </FormControl>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  style={{ flexBasis: '70%', height: '2.5rem', display: 'none' }}
                                >
                                  <InputLabel
                                    htmlFor="outlined-age-native-simple"
                                    style={{ color: '#002A45', fontWeight: 'bold' }}
                                  >
                                    Finish Dates Column
                                  </InputLabel>
                                  <Select
                                    native
                                    label="Finish Dates"
                                    name="FinishDates"
                                    style={{ width: '100%' }}
                                    onChange={(e) => HandleReg(e, indexIs)}
                                    defaultValue=""
                                  >
                                    <option aria-label="None" value="" disabled />
                                    <NERParamTargetDates
                                      key={`NERParamTarget-${indexIs}`}
                                      data={targetColumnsAre[indexIs]}
                                    />
                                  </Select>
                                </FormControl>
                              </div>
                            </Grid>
                          )}
                          {c.FileType === 'CSV' && (
                            <Grid item xs={8}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                style={{
                                  flexBasis: '100%',
                                  width: '100%',
                                  height: '2.5rem',
                                  marginBottom: 10,
                                }}
                              >
                                <InputLabel
                                  htmlFor="outlined-age-native-simple"
                                  style={{ color: '#002A45', fontWeight: 'bold' }}
                                >
                                  Column Name
                                </InputLabel>
                                <Select
                                  native
                                  label="Column Name"
                                  name="columnname"
                                  style={{ width: '100%' }}
                                  onChange={(e) => HandleReg(e, indexIs)}
                                  defaultValue=""
                                >
                                  <option aria-label="None" value="" disabled />
                                  {c?.ColumnNames !== null
                                    ? c?.ColumnNames.map((cap: string, index: number) => (
                                        <option key={`capture-type-${index}`} value={cap}>
                                          &nbsp;&nbsp;{cap}
                                        </option>
                                      ))
                                    : ''}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <>
                {dataoutput[0].FileType === 'CSV' ? (
                  <>
                    {dataoutput[0].ColumnNames.includes('Description') &&
                      dataoutput[0].ColumnNames.includes('Categories') &&
                      dataoutput[0].ColumNames.includes('Planned finish') && (
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="center"
                          style={{ marginBottom: 10 }}
                        >
                          <Grid item xs={2}>
                            <CheckboxTree
                              nodes={[
                                { value: 'Detailed activities', label: 'Detailed activities' },
                              ]}
                              checked={commonChecked1}
                              expanded={commonExpanded}
                              onCheck={handleCommonTreeCheck1}
                              onExpand={() => setCommonChecked1}
                              icons={icons}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <CheckboxTree
                              nodes={[
                                { value: 'Typical Deliverables', label: 'Typical Deliverables' },
                              ]}
                              checked={commonChecked2}
                              expanded={commonExpanded}
                              onCheck={handleCommonTreeCheck2}
                              onExpand={() => setCommonChecked2}
                              icons={icons}
                            />
                          </Grid>
                        </Grid>
                      )}
                  </>
                ) : (
                  <>
                    {targetColumnsAre.length > 0 &&
                      targetColumnsAre[0].ColumNames.includes('Description') &&
                      targetColumnsAre[0].ColumNames.includes('Categories') &&
                      targetColumnsAre[0].ColumNames.includes('Planned finish') && (
                        <>
                          <Grid
                            container
                            justify="flex-end"
                            alignItems="center"
                            spacing={1}
                            style={{ marginBottom: 10 }}
                          >
                            {/* <Grid item xs={4}>
                              {}
                            </Grid> */}
                            <Grid item xs={3} container>
                              <Grid item xs={2}>
                                {}
                              </Grid>
                              <Grid item xs={10}>
                                {matchingSheet && sheetName0 !== undefined && (
                                  <>
                                    <TextField
                                      disabled
                                      id="filled-disabled"
                                      variant="filled"
                                      label="detected Language"
                                      name="language"
                                      value={language}
                                      style={{ flexBasis: '33.33%' }}
                                      className={classes.textField}
                                      onChange={(e) => setLanguage(e.target.value)}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={9} style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span
                                  style={{ color: '#002A45', fontWeight: 'bold', marginRight: 2 }}
                                >
                                  Select Processed Customer requirement file:
                                </span>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  // style={{ flexBasis: '50%', height: '2.5rem' }}
                                >
                                  {/* <InputLabel
                                  htmlFor="outlined-age-native-simple"
                                  style={{ color: '#002A45', fontWeight: 'bold' }}
                                  size="small"
                                >
                                  Activity Name
                                </InputLabel> */}
                                  <Select
                                    native
                                    label="Activity Name"
                                    size="small"
                                    // name={'sheetname'.concat(indexIs)}
                                    name="activityName"
                                    style={{ width: '100%' }}
                                    onChange={(e) => handleActivity(e)}
                                    defaultValue=""
                                  >
                                    <option aria-label="None" value="" disabled />
                                    {listRFPData.map((c, indexIs) => (
                                      <>
                                        <option
                                          key={`capture-type-${indexIs}`}
                                          // value={c?.activityName}
                                        >
                                          {/* &nbsp;&nbsp;{c?.activityName} */}
                                          {c?.activityName}
                                        </option>
                                      </>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span
                                  style={{ color: '#002A45', fontWeight: 'bold', marginRight: 2 }}
                                >
                                  Key Dates
                                </span>
                                <FormControl
                                  variant="outlined"
                                  size="small"
                                  // style={{ flexBasis: '50%', height: '2.5rem' }}
                                >
                                  {/* <InputLabel
                                  htmlFor="outlined-age-native-simple"
                                  style={{ color: '#002A45', fontWeight: 'bold' }}
                                  size="small"
                                >
                                  Activity Name
                                </InputLabel> */}
                                  <Select
                                    multiple
                                    native={false}
                                    label="Stream Dates"
                                    style={{ width: '200px' }}
                                    name="activityName"
                                    value={selectedValues}
                                    onChange={streamDates}
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    <MenuItem value="all">
                                      <Checkbox
                                        checked={
                                          selectedValues.length === listRFPStreamDatesData.length
                                        }
                                        indeterminate={
                                          selectedValues.length > 0 &&
                                          selectedValues.length < listRFPStreamDatesData.length
                                        }
                                      />
                                      <ListItemText primary="Select All" />
                                    </MenuItem>
                                    {listRFPStreamDatesData.map((c, indexIs) => (
                                      <MenuItem key={`capture-type-${indexIs}`} value={c}>
                                        <Checkbox checked={selectedValues.indexOf(c) > -1} />
                                        <ListItemText primary={c} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <CheckboxTree
                                  nodes={[
                                    { value: 'Detailed activities', label: 'Detailed activities' },
                                  ]}
                                  checked={commonChecked1}
                                  expanded={commonExpanded}
                                  onCheck={handleCommonTreeCheck1}
                                  onExpand={() => setCommonChecked1}
                                  icons={icons}
                                />
                                <CheckboxTree
                                  nodes={[
                                    {
                                      value: 'Typical Deliverables',
                                      label: 'Typical Deliverables',
                                    },
                                  ]}
                                  checked={commonChecked2}
                                  expanded={commonExpanded}
                                  onCheck={handleCommonTreeCheck2}
                                  onExpand={() => setCommonChecked2}
                                  icons={icons}
                                />
                              </div>
                            </Grid>
                            {/* <Grid item xs={2}>
                              
                            </Grid> */}
                          </Grid>
                        </>
                      )}
                  </>
                )}
              </>
              <Grid item xs={4} alignItems="center" justify="center">
                {errorMessage !== '' && (
                  <Typography style={{ color: '#DC3223' }}>Error : {errorMessage}</Typography>
                )}
              </Grid>
              <>
                {dataoutput[0].FileType === 'CSV' ? (
                  <>
                    {dataoutput[0].ColumnNames.includes('Description') &&
                      dataoutput[0].ColumnNames.includes('Categories') &&
                      dataoutput[0].ColumNames.includes('Planned finish') && (
                        <Box
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                            backgroundColor: '#EEF1F9',
                            padding: '1rem',
                            borderTop: '1px solid #002A45',
                            minHeight: 500,
                            gap: '30px',
                          }}
                        >
                          <Box style={{ flexBasis: '25%' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #002A45',
                                borderRadius: '5px',
                                fontWeight: 'bold',
                              }}
                            >
                              <div>
                                <Typography
                                  style={{
                                    backgroundColor: '#002A45',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '10px',
                                  }}
                                >
                                  Select Sig Platform
                                </Typography>
                              </div>
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  color: '#002A45',
                                  marginTop: '5px',
                                }}
                              >
                                <CheckboxTree
                                  nodes={[mapNodesWithHoverEffect(nodes)]}
                                  checked={checked}
                                  expanded={expanded}
                                  // noCascade
                                  onCheck={(checked) => handleCheck(checked)}
                                  onExpand={(expanded) => setExpanded(expanded)}
                                  icons={icons}
                                />
                              </div>
                            </div>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #002A45',
                                borderRadius: '5px',
                                fontWeight: 'bold',
                              }}
                            >
                              <div>
                                <Typography
                                  style={{
                                    backgroundColor: '#002A45',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '10px',
                                  }}
                                >
                                  Select Turnkey value
                                </Typography>
                              </div>
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  marginTop: '5px',
                                }}
                              >
                                <CheckboxTree
                                  nodes={nodeturnkey}
                                  checked={checkedTurnedKey}
                                  expanded={expandedTurnedKey}
                                  // noCascade
                                  onCheck={(checked) => onCheckedTurnKey(checked)}
                                  onExpand={(expanded) => setExpandedTurnedKey(expanded)}
                                  icons={icons}
                                />
                              </div>
                            </div>
                          </Box>
                          <Box style={{ flexBasis: '25%' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #002A45',
                                borderRadius: '5px',
                                fontWeight: 'bold',
                              }}
                            >
                              <div>
                                <Typography
                                  style={{
                                    backgroundColor: '#002A45',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '10px',
                                  }}
                                >
                                  Select Infra value
                                </Typography>
                              </div>
                              <div style={{ backgroundColor: 'white', marginTop: '5px' }}>
                                <CheckboxTree
                                  nodes={nodeinfra}
                                  checked={checkedInfra}
                                  expanded={expandedInfra}
                                  // noCascade
                                  onCheck={(checked) => onCheckedInfra(checked)}
                                  onExpand={(expanded) => setExpandedInfra(expanded)}
                                  icons={icons}
                                />
                              </div>
                            </div>
                          </Box>
                          <Box style={{ flexBasis: '20%' }}>
                            <TextField
                              id="outlined-basic"
                              label="Enter Activity Name"
                              variant="outlined"
                              name="captureName"
                              onChange={inputHandler}
                              size="small"
                              style={{
                                width: '100%',
                                // flex: 1,
                                // marginTop: 6,
                                // marginBottom: 6,
                                backgroundColor: 'white',
                              }}
                            />
                          </Box>
                          <Box style={{ flexBasis: '5%' }}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              startIcon={
                                loading && <CircularProgress style={{ color: 'white' }} size={20} />
                              }
                              onClick={handleAnalyze}
                              style={{
                                width: '100%',
                                // flex: 1,
                                // marginTop: 6,
                                // marginBottom: 6,
                              }}
                            >
                              Analyze
                            </Button>
                          </Box>
                        </Box>
                      )}
                  </>
                ) : (
                  <>
                    {targetColumnsAre.length > 0 &&
                      targetColumnsAre[0].ColumNames.includes('Description') &&
                      targetColumnsAre[0].ColumNames.includes('Categories') &&
                      targetColumnsAre[0].ColumNames.includes('Planned finish') && (
                        <>
                          <Box
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'flex-start',
                              alignItems: 'start',
                              backgroundColor: '#EEF1F9',
                              padding: '1rem',
                              borderTop: '1px solid #002A45',
                              minHeight: 500,
                              gap: '30px',
                            }}
                          >
                            <Box style={{ flexBasis: '25%' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  border: '1px solid #002A45',
                                  borderRadius: '5px',
                                  fontWeight: 'bold',
                                }}
                              >
                                <div>
                                  <Typography
                                    style={{
                                      backgroundColor: '#002A45',
                                      color: 'white',
                                      fontWeight: 'bold',
                                      padding: '10px',
                                    }}
                                  >
                                    Select Sig Platform
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: 'white',
                                    color: '#002A45',
                                    marginTop: '5px',
                                  }}
                                >
                                  <CheckboxTree
                                    nodes={nodes.map(mapNodesWithHoverEffect)}
                                    checked={checked}
                                    expanded={expanded}
                                    // noCascade
                                    onCheck={(checked) => handleCheck(checked)}
                                    onExpand={(expanded) => setExpanded(expanded)}
                                    icons={icons}
                                  />
                                </div>
                              </div>
                            </Box>
                            <Box style={{ flexBasis: '25%' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  border: '1px solid #002A45',
                                  borderRadius: '5px',
                                  fontWeight: 'bold',
                                }}
                              >
                                <div>
                                  <Typography
                                    style={{
                                      backgroundColor: '#002A45',
                                      color: 'white',
                                      fontWeight: 'bold',
                                      padding: '10px',
                                    }}
                                  >
                                    Select Turnkey value
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: 'white',
                                    marginTop: '5px',
                                  }}
                                >
                                  <CheckboxTree
                                    nodes={nodeturnkey}
                                    checked={checkedTurnedKey}
                                    expanded={expandedTurnedKey}
                                    // noCascade
                                    onCheck={(checked) => onCheckedTurnKey(checked)}
                                    onExpand={(expanded) => setExpandedTurnedKey(expanded)}
                                    icons={icons}
                                  />
                                </div>
                              </div>
                            </Box>
                            <Box style={{ flexBasis: '25%' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  border: '1px solid #002A45',
                                  borderRadius: '5px',
                                  fontWeight: 'bold',
                                }}
                              >
                                <div>
                                  <Typography
                                    style={{
                                      backgroundColor: '#002A45',
                                      color: 'white',
                                      fontWeight: 'bold',
                                      padding: '10px',
                                    }}
                                  >
                                    Select Infra value
                                  </Typography>
                                </div>
                                <div style={{ backgroundColor: 'white', marginTop: '5px' }}>
                                  <CheckboxTree
                                    nodes={nodeinfra}
                                    checked={checkedInfra}
                                    expanded={expandedInfra}
                                    // noCascade
                                    onCheck={(checked) => onCheckedInfra(checked)}
                                    onExpand={(expanded) => setExpandedInfra(expanded)}
                                    icons={icons}
                                  />
                                </div>
                              </div>
                            </Box>
                            <Box style={{ flexBasis: '20%' }}>
                              <TextField
                                id="outlined-basic"
                                label="Enter Activity Name"
                                variant="outlined"
                                name="captureName"
                                onChange={inputHandler}
                                size="small"
                                style={{
                                  width: '100%',
                                  // flex: 1,
                                  // marginTop: 6,
                                  // marginBottom: 6,
                                  backgroundColor: 'white',
                                }}
                              />
                            </Box>
                            <Box style={{ flexBasis: '5%' }}>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                startIcon={
                                  loading && (
                                    <CircularProgress style={{ color: 'white' }} size={20} />
                                  )
                                }
                                onClick={handleAnalyze}
                                style={{
                                  width: '100%',
                                  // flex: 1,
                                  // marginTop: 6,
                                  // marginBottom: 6,
                                }}
                              >
                                Analyze
                              </Button>
                            </Box>
                          </Box>
                        </>
                      )}
                  </>
                )}
              </>
            </div>
          </>
        )}
      </Paper>
      <br />

      <Modal
        open={open}
        onClose={handleMouseLeave}
        aria-labelledby="node-name-modal"
        aria-describedby="node-name-description"
      >
        {/* <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="node-name-modal">Hovered Node</h2>
          <p id="node-name-description">{hoveredNode}</p>
        </Box> */}
        <>hi</>
      </Modal>
      {loadingResult ? <Loading /> : ''}
      {display && (
        <ScheduleAnalysisResult
          resultData={resultData}
          textData={mileStoneData}
          loadMilestone={loadingMilestone}
        />
      )}
    </>
  );
});

export default ScheduleAnalysis;
