/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import HeaderButton from '../../Module2/Module/core/HeaderButton';
import ModuleAnalysis from '../../Module2/Module/ModuleAnalysis';
import ModuleLayout from '../../../layouts/ModuleLayout';
import useAppToken from '../../../app/useAppToken';
import useUserFileUpload from '../../../layouts/Hooks/useUserFileUpload';
import UserSpaceContext from '../UserSpaceContext';

const FindDuplicates = (props) => {
  const [inputFileOpen, setInputFileOpen] = React.useState(false);
  const [isAnalysis, setIsAnalysis] = React.useState(false);
  const [selectionFile, setSelectionFile] = React.useState(null);
  const { files: filesfromContext, setFiles } = useContext(UserSpaceContext);
  const {
    enableDescription,
    renderDescription,
    numberOfFilesSupport,
    fileTypeSupport,
    setSelectedChildIndex,
    renderFiles,
    workload,
    setWorkload,
  } = props;
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const {
    loading,
    handleChangeInDragefile,
    handleUploadFile,
    FindeFileDetails,
  } = useUserFileUpload({
    user_id,
    fileTypeSupport,
    files: filesfromContext,
    setFiles,
  });

  const similarAnalysisProps = useMemo(
    () => ({
      state: {
        inputFileOpen,
        setInputFileOpen,
        isAnalysis,
        setIsAnalysis,
        selectionFile,
        setSelectionFile,
        setSelectedChildIndex,
        loading,
        workload,
        setWorkload,
      },
      enableDescription,
      renderDescription,
      enableHeader: false,
      renderFiles,
      serviceName: 'Find Duplicates',
      serviceFor: { name: 'user', id: user_id },
      numberOfFilesSupport,
      fileTypeSupport,
      handleChangeInDragefile,
      handleUploadFile,
      FindeFileDetails,
      renderHeader: () => {
        return <>{/* <HeaderButton icon={<ArticleIcon />} name="Scope setting" /> */}</>;
      },
      renderAnalysis: (props) => {
        return (
          <>
            <ModuleAnalysis {...props} />
          </>
        );
      },
    }),
    [
      inputFileOpen,
      isAnalysis,
      selectionFile,
      enableDescription,
      renderDescription,
      loading,
      workload,
    ]
  );

  return (
    <>
      <ModuleLayout moduleData={similarAnalysisProps} />{' '}
    </>
  );
};

export default memo(FindDuplicates);
