import React, { useMemo } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import RightPanelLayout from './RightPanelLayout';

const NotificationPanel = (props) => {
  const { data, isOpen, setSideBar } = props;
  const renderData = useMemo(() => {
    return {
      name: 'Notifications',
      renderCardIcon: (props) => {
        return <NotificationsNoneIcon {...props} />;
      },
      cardData: data.map((data) => {
        return {
          content: [
            {
              label: ' Created At:',
              value: data.created_at.split('T')[0],
            },
            // {
            //   label: 'Application:',
            //   value: data.redirect_params?.app_name,
            // },
            // {
            //   label: 'File Name:',
            //   value: data.redirect_params?.filename,
            // },
            {
              label: 'Content:',
              value: data.label,
            },
          ],
          isReadByUser: data?.read_at,
          notifyID: data?.id,
          url: `/project/${data.redirect_params?.project_id}/${data.redirect_params?.app_name}/${
            data.redirect_params?.app_name[0] +
            data.redirect_params?.app_name[data.redirect_params?.app_name?.length - 1]
          }/results/${data.redirect_params?.workload_id}`,
          // ?filename=${
          //   data.redirect_params?.filename
          // }&action=analyze
        };
      }),
      isOpen,
      setSideBar,
      isActionRequired: true,
    };
  }, [data, isOpen]);

  return <RightPanelLayout pannelLayout={renderData} />;
};

export default NotificationPanel;
