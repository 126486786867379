import axios, { axiosNoBearer } from './axios';
import { BACKEND_URL } from '../const';

export interface UserUpdate {
  id?: string;
  name?: string;
  email?: string;
  details?: any;
}

export interface SearchOptions {
  name?: string;
  email?: string;
  project_id?: Number;
  project_id_out?: Number;
}

const getAllUsers = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/users`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// the below url only for used for lab purpose and once the POC is done we will replace it with the env url
const getAllEmployees = async (body: any): Promise<any> => {
  try {
    const response = await axios.post(
      `https://talent-group-hr.20.31.20.157.sslip.io/employees_reporting_to_manager`,
      body
    );

    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getObjectives = async (body: any): Promise<any> => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/talent_group/create/employeeObj`, body);

    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// the below url only for used for lab purpose and once the POC is done we will replace it with the env url
const getOrgAwareness = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `https://talent-group-hr.20.31.20.157.sslip.io/organization_awareness`
    );

    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getMyDBObjectives = async (firstName: String, lastname: String): Promise<any> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL.root}/talent_group/get/employee/${firstName}/lastname/${lastname}`
    );

    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

// the below url only for used for lab purpose and once the POC is done we will replace it with the env url
const getEmp_colleagues_tree_ofuser = async (body: any): Promise<any> => {
  try {
    const response = await axios.post(
      `https://talent-group-hr.20.31.20.157.sslip.io/employees_colleagues_and_bosses_of_user`,
      body
    );
    console.log(response.data);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const registerUserWithAzToken = async (body: UserUpdate, accessToken: string): Promise<any> => {
  try {
    // const response = await axios.post(`${BACKEND_URL.root}/public/jwtlogin`, {
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //     Details: JSON.stringify(body.details),
    //   },
    // });

    const response = await fetch(`${BACKEND_URL.root}/public/jwtlogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken} X-Details ${JSON.stringify(body.details)}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getUser = async (body: UserUpdate): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/users/${body.id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const searchUsers = async (body: SearchOptions): Promise<any> => {
  try {
    let url = `${BACKEND_URL.root}/users/search?`;
    if (!body) {
      url = `${BACKEND_URL.root}/users/search`;
    } else {
      Object.entries(body).forEach((element) => {
        url += `${element[0]}=${element[1]}`;
      });
    }

    const response = await axios.get(url);

    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getUserByProjectId = async (projectId: Number): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/projects/users/${projectId}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const addUsersToProject = async (projectId: Number, userList: Number[]): Promise<any> => {
  try {
    const url = `${BACKEND_URL.root}/projects/${projectId}/users`;
    const response = await axios.post(url, userList);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const removeUsersFromProject = async (projectId: Number, userList: Number[]): Promise<any> => {
  try {
    const url = `${BACKEND_URL.root}/projects/${projectId}/users/remove`;
    const response = await axios.post(url, userList);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const updateUserRolesToProject = async (projectId: Number, userList: any[]): Promise<any> => {
  try {
    const url = `${BACKEND_URL.root}/projects/${projectId}/users/roles`;
    const response = await axios.put(url, userList);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const getNewUsers = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/users/admin/newusers`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const activateNewUsers = async (userList: any): Promise<any> => {
  try {
    const response = await axios.put(`${BACKEND_URL.root}/users/admin/activateusers`, userList);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const refreshToken = async (refresh_token: string, access_token: string): Promise<any> => {
  try {
    const body = {
      refresh_token,
      access_token,
    };
    const response = await axiosNoBearer.post(`${BACKEND_URL.root}/public/jwtrefresh`, body);
    return response.data;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

export {
  getAllUsers,
  getUser,
  registerUserWithAzToken,
  searchUsers,
  addUsersToProject,
  removeUsersFromProject,
  updateUserRolesToProject,
  getNewUsers,
  activateNewUsers,
  getUserByProjectId,
  getAllEmployees,
  getObjectives,
  getEmp_colleagues_tree_ofuser,
  getMyDBObjectives,
  getOrgAwareness,
  refreshToken,
};
