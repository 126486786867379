import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InfoIcon from '@mui/icons-material/Info';
import AlarmIcon from '@mui/icons-material/Alarm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton, Box, Typography, Badge } from '@mui/material';
import { w3cwebsocket } from 'websocket';
import isEqual from 'react-fast-compare';
import getW3client, { notifGetCompact, notifSetReadByCat } from '../api/wsNotifs';
import logoIcon from '../assets/logo-icon.png';
import SideBar from './SideBar';
import sleep from '../tools/sleep';

const rightPannel = ['userMangement', 'workloads', 'ownings', 'profile'];
type MessageNotif = {
  ownings: number;
  workloads: number;
  userManagements: number;
};

const messageInit = {
  ownings: 0,
  workloads: 0,
  userManagements: 0,
} as MessageNotif;

// wsMessageJSONPrev issue must be global
let wsMessageJSONPrev: MessageNotif = messageInit;

const AppTopMenu = ({ setModalUserOpen }) => {
  const [sideBar, setSideBar] = useState({
    isPanel: '',
    isOpen: false,
    selectedRightPannel: -1,
  });
  const { isOpen } = sideBar;
  const [wsClient, setWsClient] = useState<w3cwebsocket | undefined>(undefined);
  const [wsMessageJSON, setWsMessageJSON] = useState<MessageNotif>(messageInit);
  const handleMessage = useCallback(
    (message: any) => {
      if (!isEqual(JSON.parse(String(message.data)), wsMessageJSONPrev)) {
        // console.log(JSON.parse(String(message.data)), wsMessageJSONPrev);
        wsMessageJSONPrev = wsMessageJSON;
        setWsMessageJSON(JSON.parse(String(message.data)));
      }
    },
    [wsMessageJSON]
  );
  useEffect(() => {
    try {
      // avoid multiple connexion
      if (wsClient) wsClient?.close();

      sleep(10000).then(() => {
        try {
          // avoid multiple connexion
          if (wsClient) wsClient?.close();
          const wsClientNew = getW3client();
          setWsClient(wsClientNew);
          wsClientNew.onopen = () => {};
          wsClientNew.onerror = (error) => {
            console.error(error);
          };
          wsClientNew.onmessage = handleMessage;
        } catch (error) {
          console.error(error);
        }
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      // don't forget to close

      wsClient?.close();
    };
  }, []);

  useEffect(() => {
    try {
      // avoid multiple connexion
      if (isOpen) {
        if (wsClient) wsClient?.close();

        sleep(2000).then(() => {
          try {
            // avoid multiple connexion
            if (wsClient) wsClient?.close();
            const wsClientNew = getW3client();
            setWsClient(wsClientNew);
            wsClientNew.onopen = () => {};
            wsClientNew.onerror = (error) => {
              console.error(error);
            };
            wsClientNew.onmessage = handleMessage;
          } catch (error) {
            console.error(error);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [isOpen]);

  const SaveDataSeen = (type) => {
    notifSetReadByCat(type);
  };

  return (
    <Box
      style={{
        position: 'sticky',
        padding: '0.2rem',
        zIndex: '1000',
        display: 'flex',
        height: '55px',
        alginItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#002A45',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', height: '100%', gap: '10px' }}>
        <IconButton
          onClick={() => {
            setSideBar({ isPanel: 'left', isOpen: true });
          }}
          style={{
            color: '#EEF1F9',
            cursor: 'pointer',
          }}
        >
          <MenuIcon fontSize="medium" />
        </IconButton>
        <Box component={Link} to="/home" sx={{ display: 'flex', gap: 1, cursor: 'pointer' }}>
          <img src={logoIcon} alt="iSenS" height={30} width={30} style={{ borderRadius: '50%' }} />
          <Typography variant="h5" style={{ color: '#EEF1F9' }}>
            iSenS
          </Typography>
        </Box>
      </div>
      <div>
        <IconButton
          // className={classes.menuButtonBadge}
          data-notif-type="userManagement"
          onClick={() => {
            setSideBar({ isPanel: 'right', isOpen: true, selectedRightPannel: 0 });
            SaveDataSeen('userManagement');
          }}
        >
          <Badge badgeContent={wsMessageJSON.userManagements} color="secondary">
            {/* badgeContent={wsMessageJSON.ownings} */}
            <InfoIcon style={{ color: 'white' }} />
          </Badge>
        </IconButton>
        <IconButton
          // className={classes.menuButtonBadge}
          data-notif-type="workloads"
          onClick={() => {
            setSideBar({ isPanel: 'right', isOpen: true, selectedRightPannel: 1 });
            SaveDataSeen('workloads');
          }}
        >
          <Badge badgeContent={wsMessageJSON.workloads} color="secondary">
            {/* badgeContent={wsMessageJSON.ownings} */}
            <AlarmIcon style={{ color: 'white' }} />
          </Badge>
        </IconButton>

        <IconButton
          // className={classes.menuButtonBadge}
          data-notif-type="ownings"
          onClick={() => {
            setSideBar({ isPanel: 'right', isOpen: true, selectedRightPannel: 2 });
            SaveDataSeen('ownings');
          }}
        >
          <Badge badgeContent={wsMessageJSON.ownings} color="secondary">
            {/* badgeContent={wsMessageJSON.ownings} */}
            <NotificationsNoneIcon style={{ color: 'white' }} />
          </Badge>
        </IconButton>

        <IconButton
          onClick={() => {
            setSideBar({ isPanel: 'right', isOpen: true, selectedRightPannel: 3 });
          }}
          style={{ color: '#EEF1F9' }}
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </div>
      <SideBar sideBar={sideBar} setSideBar={setSideBar} />
    </Box>
  );
};

export default memo(AppTopMenu);
