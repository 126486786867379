import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';
import formatDate, { formatLocal } from '../../../tools/formatDate';

export const useStyles = makeStyles((theme) => ({
  bloc: {
    background: '#EEF1F9 0% 0% no-repeat padding-box',
    boxShadow: ' 0px 8px 8px #00000029',
    border: '1px solid #002A45',
    borderRadius: '5px 5px 0px 0px',
    opacity: 1,
    margin: '30px 20px 30px 20px',
  },
  title: {
    color: '#002A45',
    font: '26px',
    fontWeight: 'bold',
    textAlign: 'left',
    height: '56px',
    paddingTop: '10px',
    paddingLeft: '10px',
  },
}));

type Props = {
  data?: any;
};

const DetailRule = ({
  label,
  value,
  classname,
}: {
  label: string;
  value: string;
  classname?: any;
}): React.ReactElement => {
  return (
    <Grid container>
      <Grid
        item
        xs={4}
        style={{
          paddingTop: 10,
          paddingLeft: 10,
          color: '#FFFFFF',
          background: '#002A45',
          minHeight: '50px',
          verticalAlign: 'bottom-line',
          borderBottom: '1px solid rgba(238, 241, 249, 0.3)',
        }}
      >
        {label}
      </Grid>
      <Grid
        item
        xs={8}
        style={{
          paddingTop: 10,
          paddingLeft: 10,
          minHeight: '50px',
          verticalAlign: 'bottom-line',
          borderBottom: '1px solid rgba(0, 42, 69, 0.3)',
          wordBreak: 'break-word',
        }}
      >
        {value}
      </Grid>
    </Grid>
  );
};

export default function RuleDetails({ data }: Props) {
  const classes = useStyles();

  return (
    <>
      {data && (
        <Box className={classes.bloc}>
          <Grid
            container
            style={{
              borderBottom: '1px solid #002A45',
            }}
          >
            <Typography variant="h6" className={classes.title}>
              Rule {data.object_number}{' '}
              {data.standard && (
                <CheckCircleIcon
                  titleAccess="standard rule"
                  style={{ color: 'green', paddingTop: '10px' }}
                />
              )}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <DetailRule label="Object Number" value={data.object_number} key="detail-1" />
              <DetailRule label="Rule" value={data.rule} key="detail-2" />
              <DetailRule label="Rule Id" value={data.script_rule_id} key="detail-3" />
              <DetailRule label="Rule Script" value={data.script_rule} key="detail-4" />
              <DetailRule
                label="Local Condition"
                value={data.script_rule_condition}
                key="detail-5"
              />
              <DetailRule label="Result" value={data.script_rule_result} key="detail-6" />
              <DetailRule
                label="Full Condition"
                value={data.script_rule_condition_full || ''}
                key="detail-7"
              />
              <DetailRule label="Project Id" value={data.project_id} key="detail-8" />
            </Grid>
            <Grid item xs={6}>
              <DetailRule label="Criticity" value={data.criticity} key="detail-9" />
              <DetailRule label="Comments" value={data.comments} key="detail-10" />
              <DetailRule label="ID" value={data.id} key="detail-11" />
              <DetailRule
                label="Created At"
                value={formatDate(data.created_at, formatLocal())}
                key="detail-12"
              />
              <DetailRule
                label="Created By"
                value={data.created_by_user?.name ?? ''}
                key="detail-12"
              />
              <DetailRule
                label="Updated At"
                value={formatDate(data.updated_at, formatLocal())}
                key="detail-12"
              />
              <DetailRule
                label="Updated By"
                value={data.updated_by_user?.name ?? ''}
                key="detail-12"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
