import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
// import RexBook from './Components/RexBook';
import UserWorkSpaceContainer from '../Home/components/UserWorkSpaceContainer';
import DocumentContainer from './Containers/DocumentContainer';
import DocumentAnalysisContainer from './Containers/DocumentAnalysisContainer';
import { UserSpaceContextProvider } from './UserSpaceContext';
import RexBookContainer from './Containers/RexBookContainer';
import useAppToken from '../../app/useAppToken';
// import RexContainer from './Components/RexBookContainer';

export const UserValidation = ({ children }) => {
  const { userId } = useParams();
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;

  if (Number(user_id) !== Number(userId)) {
    return (
      <>
        <Box
          style={{
            width: '100%',
            minHeight: '20rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" color="error">
            Oops! Invalid User.
          </Typography>
        </Box>
      </>
    );
  }
  return <>{children}</>;
};

const UserRouter = () => {
  const { service } = useParams();

  return (
    <UserSpaceContextProvider>
      <Switch>
        <Route
          exact
          path="/userspace/DocAnalysis/:userId/results/:workloadId"
          render={() => {
            return (
              <>
                <UserValidation>
                  <DocumentAnalysisContainer />
                </UserValidation>
              </>
            );
          }}
        />

        <Route
          exact
          path="/userspace/DocAnalysis"
          render={() => {
            return (
              <>
                <DocumentAnalysisContainer />
              </>
            );
          }}
        />

        <Route
          exact
          path="/userspace/DocumentTranslator"
          render={() => {
            return (
              <>
                <DocumentContainer />
              </>
            );
          }}
        />
        <Route
          exact
          path="/"
          render={() => {
            return (
              <>
                <UserWorkSpaceContainer />
              </>
            );
          }}
        />
        <Route
          exact
          path="/userspace/RexBook"
          render={() => {
            return (
              <>
                <RexBookContainer />
              </>
            );
          }}
        />
      </Switch>
    </UserSpaceContextProvider>
  );
};

export default UserRouter;
