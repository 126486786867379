import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
// import { makeStyles, withStyles } from '@material-ui/styles';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import Loading from '../../../../../components/Core/Loading';
import { rowComponent } from '../../../../../components/Datagrid/TStyles';

function Alert({ loading, rows }) {
  const columns = [
    { name: 'label', title: 'Description' },
    { name: 'Link', title: 'Link' },
  ];
  const [pageSizes] = useState([5, 10, 20, 50, 100]);

  const renderLinkNotification = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
    <Link
      // /projects/${params.project_id}/${params.app_name}/results/${params.workload_id}?filename=${params.filename}&action=owning
      // eslint-disable-next-line max-len
      to={`/projects/${row?.redirect_params?.project_id}/${row?.redirect_params?.app_name}/results/${row?.redirect_params?.workload_id}?filename=${rows?.filename}&action=owning`}
    >
      view
    </Link>
  );

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <>
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider for={['Link']} formatterComponent={renderLinkNotification} />
            <SelectionState />
            <FilteringState defaultFilters={[{ columnName: 'Descriptions', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={5} />
            <IntegratedPaging />
            <VirtualTable />
            <Table tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <PagingPanel pageSizes={pageSizes} />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
          </ExpressGrid>
        </>
      )}
    </>
  );
}
export default Alert;
