import React from 'react';
import { resourcesDelete, userResourcesDelete } from '../../api/folders';

const useDeletFile = (props) => {
  const { projectId, folderId: id, selectedFiles, setProjects, files, user_id, setFiles } = props;
  const [loading, setLoading] = React.useState(false);

  const DeleteFile = async () => {
    try {
      if (selectedFiles.length === 0)
        return { data: files, error: true, message: 'error while deleting' };
      setLoading(true);
      const { folderId = id } = selectedFiles[0];
      const result = await resourcesDelete(projectId, folderId, selectedFiles);
      if (!result) {
        setLoading(true);
        return { data: files, error: true, message: 'error while deleting ' };
      }
      setProjects((prev) => {
        return [
          {
            ...prev[0],
            folders: prev[0]?.folders
              ? prev[0]?.folders.map((item) => {
                  if (item.id === folderId) {
                    return {
                      ...item,
                      resources: [...result],
                    };
                  }
                  return item;
                })
              : [],
          },
        ];
      });
      setLoading(false);
      return { data: result, error: false, message: 'Successfully File deleted!' } ?? null;
    } catch (error) {
      console.log(error);
      setLoading(false);
      return { data: files, errro: true, message: 'error while deleting ' };
    }
  };

  const DeleteUserFile = async () => {
    try {
      if (selectedFiles.length === 0)
        return { data: files, error: true, message: 'error while deleting' };
      setLoading(true);
      const { folderId } = selectedFiles[0];
      const addressetag = selectedFiles.map((item) => item.etag);
      const result = await userResourcesDelete(folderId, selectedFiles);
      if (!result) {
        setLoading(true);
        return { data: files, error: true, message: 'error while deleting ' };
      }
      setFiles((prev) => {
        return prev.filter((item) => !addressetag.includes(item.etag));
      });

      const temp = files.filter((item) => !addressetag.includes(item.etag));
      setLoading(false);
      return { data: temp, error: false, message: 'Successfully File deleted!' } ?? null;
    } catch (error) {
      console.log(error);
      setLoading(false);
      return { data: files, errro: true, message: 'error while deleting ' };
    }
  };

  return { DeleteFile, loading, DeleteUserFile };
};

export default useDeletFile;
