import React, { useEffect, useMemo, useReducer } from 'react';
import { getAllProjectCreationLanguages } from '../../api/projects';
import { getiQualimeterRules } from '../../api/module3';

export interface HomeContextType {
  rexResult: any;
  input: any;
  setInput: any;
  setRexResult: any;
  languageCode: any;
  setLanguageCode: any;
  response: any;
  setResponse: any;
  rePhraserRules: any;
  setRePhraserRule: any;
}

type Props = {
  children: any;
};

const HomeContext = React.createContext<HomeContextType>({
  rexResult: {} as any,
  input: {} as any,
  setInput: {} as any,
  setRexResult: {} as any,
  languageCode: [] as any,
  setLanguageCode: [] as any,
  response: {} as any,
  setResponse: {} as any,
  rePhraserRules: [] as any,
  setRePhraserRule: [] as any,
});

const HomeContextProvider = ({ children }: Props) => {
  const [rexResult, setRexResult] = React.useState<any>([]);
  const [input, setInput] = React.useState<any>('');
  const [response, setResponse] = React.useState({});
  const [rePhraserRules, setRePhraserRule] = React.useState<any>([]);
  const [languageCode, setLanguageCode] = React.useState<any>([]);

  useEffect(() => {
    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });
    getiQualimeterRules().then((data) => {
      setRePhraserRule(data);
    });
  }, []);

  const value = useMemo(
    () => ({
      rexResult,
      setRexResult,
      languageCode,
      response,
      setResponse,
      rePhraserRules,
      input,
      setInput,
    }),
    [rexResult, languageCode, response, rePhraserRules, setResponse, input, setInput]
  );

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

export default HomeContext;

export { HomeContextProvider };
