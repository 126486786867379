/* eslint-disable no-param-reassign */
import React, { useContext, useMemo } from 'react';
import { RexFiltersNew } from '../../../api/module2';
import get_previous_state, { get_default_state, set_selected_option } from './utiles';
import SliderComponent from './components/SliderComponent';
import ToggleComponent from './components/ToggleComponent';

const Rex360Filters = ({ rexFilter, dispatch, callbackFilters, selectedOptionRef }) => {
  if (!rexFilter) {
    return { options: [] };
  }
  const {
    rexPaltform,
    rexSource,
    rexStatus,
    rexTypology,
    rexCountry,
    rexProductLine,
    rexProduct,
    rexSolution,
    rexIssuerSite,
    rexProject,
    rexSelectedPlatform,
    rexSelectedStatus,
    rexSelectedTypology,
    rexSelectedSource,
    rexSelectedSolution,
    rexSelectedProduct,
    rexSelectedProject,
    rexSelectedIssuerSite,
    rexSelectedCountry,
    rexSelectedProductLine,
  } = rexFilter;

  // console.log('rexFilter', rexFilter);

  const multifilterCallback = (key) => {
    try {
      const {
        sites,
        sources,
        statuses,
        typologies,
        productLines,
        platforms,
        solutions,
        products,
        projects,
        countries,
      } = selectedOptionRef.current;
      RexFiltersNew(
        sites,
        sources,
        statuses,
        typologies,
        productLines,
        platforms,
        solutions,
        products,
        projects,
        countries
      ).then((res: any) => {
        const check = [
          'sites',
          'sources',
          'statuses',
          'typologies',
          'productLines',
          'platforms',
          'solutions',
          'products',
          'projects',
        ];
        if (res?.filters) {
          let optionsAvailable = false;
          for (let i = 0; i < check.length; i += 1) {
            if (res?.filters[check[i]] && res?.filters[check[i]].length > 0) {
              optionsAvailable = true;
            } else {
              optionsAvailable = false;
              break;
            }
          }

          if (!optionsAvailable) {
            const temp = {
              sites: rexIssuerSite,
              sources: rexSource,
              statuses: rexStatus,
              typologies: rexTypology,
              productLines: rexProductLine,
              platforms: rexPaltform,
              solutions: rexSolution,
              products: rexProduct,
              projects: rexProject,
              countries: rexCountry,
            };
            callbackFilters(temp, 'rexFilter');
            return;
          }
          const defaultTemp = {
            ...res.filters,
          };

          const {
            sites,
            sources,
            statuses,
            typologies,
            productLines,
            platforms,
            solutions,
            products,
            projects,
            countries,
          } = selectedOptionRef.current;

          if (
            sites.length === 0 &&
            sources.length === 0 &&
            statuses.length === 0 &&
            typologies.length === 0 &&
            productLines.length === 0 &&
            platforms.length === 0 &&
            solutions.length === 0 &&
            products.length === 0 &&
            projects.length === 0 &&
            countries.length === 0
          ) {
            callbackFilters(defaultTemp, 'rexFilter');
          } else {
            delete defaultTemp[key];
            callbackFilters(defaultTemp, 'rexFilter');
          }
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const temp = [
    {
      name: 'Product Line',
      key: 'productLines',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('productLines', rexProductLine, 'rexFilter'),
      _cache: get_previous_state(rexSelectedProductLine, 'productLines', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_productLine', payload: option });
        set_selected_option('productLines', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          productLines: option,
        };
        multifilterCallback('productLines');
      },
    },
    {
      name: 'Platform',
      check: 'false',
      key: 'platforms',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('platforms', rexPaltform, 'rexFilter'),
      _cache: get_previous_state(rexSelectedPlatform, 'platforms', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_platform', payload: option });
        set_selected_option('platforms', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          platforms: option,
        };
        multifilterCallback('platforms');
      },
    },
    {
      name: 'Solution',
      key: 'solutions',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('solutions', rexSolution, 'rexFilter'),
      _cache: get_previous_state(rexSelectedSolution, 'solutions', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_solution', payload: option });
        set_selected_option('solutions', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          solutions: option,
        };
        multifilterCallback('solutions');
      },
    },
    {
      name: 'Product',
      key: 'products',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('products', rexProduct, 'rexFilter'),
      _cache: get_previous_state(rexSelectedProduct, 'products', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_product', payload: option });
        set_selected_option('products', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          products: option,
        };
        multifilterCallback('products');
      },
    },
    {
      name: 'Project',
      key: 'projects',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('projects', rexProject, 'rexFilter'),
      _cache: get_previous_state(rexSelectedProject, 'projects', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_project', payload: option });
        set_selected_option('projects', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          projects: option,
        };
        multifilterCallback('projects');
      },
    },
    {
      name: 'Country',
      key: 'countries',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('countries', rexCountry, 'rexFilter'),
      _cache: get_previous_state(rexSelectedCountry, 'countries', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_country', payload: option });
        set_selected_option('countries', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          countries: option,
        };
        multifilterCallback('countries');
      },
    },
    {
      name: 'Issuer Site',
      key: 'sites',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('sites', rexIssuerSite, 'rexFilter'),
      _cache: get_previous_state(rexSelectedIssuerSite, 'sites', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_source', payload: option });
        set_selected_option('sites', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          sites: option,
        };
        multifilterCallback('sites');
      },
    },
    {
      name: 'Source',
      key: 'sources',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('sources', rexSource, 'rexFilter'),
      _cache: get_previous_state(rexSelectedSource, 'sources', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_source', payload: option });
        set_selected_option('sources', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          sources: option,
        };
        multifilterCallback('sources');
      },
    },
    {
      name: 'Status',
      key: 'statuses',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('statuses', rexStatus, 'rexFilter'),
      _cache: get_previous_state(rexSelectedStatus, 'statuses', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_status', payload: option });
        set_selected_option('statuses', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          statuses: option,
        };
        multifilterCallback('statuses');
      },
    },
    {
      name: 'Typology',
      key: 'typologies',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('typologies', rexTypology, 'rexFilter'),
      _cache: get_previous_state(rexSelectedTypology, 'typologies', 'rexFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_typology', payload: option });
        set_selected_option('typologies', option, 'rexFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          typologies: option,
        };
        multifilterCallback('typologies');
      },
    },
  ];

  const ClearFilters = () => {
    dispatch({ type: 'clear_rex_filters' });
    localStorage.removeItem('rexFilterSlected');
    localStorage.removeItem('rexFilter');
  };

  const otherFilters = {
    isOtherComponent: true,
    renderComponent: (props) => {
      return (
        <>
          <SliderComponent
            dispatch={dispatch}
            type="set_Rex_Max_result"
            defaultValue={100}
            max={500}
            reset={props}
          />
          {/* <ToggleComponent dispatch={dispatch} type="set_isearcher_door" /> */}
        </>
      );
    },
  };
  return { options: temp, otherFilters, ClearFilters };
};

export default Rex360Filters;
