import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import React, { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import useFilterOptions from '../HOC/Hooks/useFilterOptions';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import { FilterItem } from '../HOC/Filters';

const UserProfileContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 'average',
  backgroundColor: '#EEF1F9',
  '& .header': {
    display: 'flex',
    width: '100%',
    height: '2.5rem',
    padding: '0.3rem',
    backgroundColor: '#002A45',
    color: '#EEF1F9',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiTypography-root': {
      color: '#EEF1F9',
    },
    '& .MuiButtonBase-root': {
      fontSize: '1.2rem',
      color: '#EEF1F9',
      fontWeight: 800,
    },
  },

  '& .content': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    padding: '0.3rem',
    gridTemplateRows: '1fr 1fr 1fr',
    width: '100%',
    height: 'calc(100% - 2.5rem)',
    maxHeight: 'calc(100% - 2.5rem)',
    // justifyContent: 'space-between',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1em',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.2em',
  },
}));

const UserProfileSetting = (props) => {
  const { setOpen, open } = props;
  const { state, dispatch } = useContext(M2GlobalContext);
  const [syncFilters, setSyncFilters] = useState({});
  const selectedOptionRef = useRef({});
  const [clearRandom, setClearRandom] = useState(0);
  const [freezed, setFreezed] = useState(false);
  const service = 'Similarity Search';

  const callbackFilters = (options, storeKey) => {
    setSyncFilters((prev) => {
      setFreezed(false);
      localStorage.setItem(storeKey, JSON.stringify({ ...prev, ...options }));
      return {
        ...prev,
        ...options,
      };
    });
  };

  const { options, otherFilters, ClearFilters } = useFilterOptions({
    service,
    state,
    dispatch,
    callbackFilters,
    clearRandom,
    selectedOptionRef,
  });
  const [NumberOfFilters, setNumberOfFilters] = useState([...options]);

  useMemo(() => {
    if (options && options.length > 0) {
      setNumberOfFilters(options);

      const temp = {};
      const seletedtemp = {};

      options.forEach((eachOption) => {
        temp[eachOption.key] = eachOption.options;
        seletedtemp[eachOption.key] = [];
      });
      selectedOptionRef.current = seletedtemp;
      setSyncFilters(temp);
    }
  }, [options, service]);

  const toggleCollapse = useCallback((index) => {
    setNumberOfFilters((prev) => {
      return prev.map((item, i) => (i === index ? { ...item, collapsed: !item.collapsed } : item));
    });
  }, []);

  return (
    <UserProfileContainer>
      <div className="header">
        <Typography>
          <ManageAccountsIcon /> User Profile
        </Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="content">
        {NumberOfFilters.map((data, index) => {
          return (
            <div key={index} style={{ padding: '0.3rem', fontSize: '0.89rem' }}>
              <FilterItem
                {...data}
                collapsed={false}
                freezed={freezed}
                setFreezed={setFreezed}
                options={syncFilters[data.key] ?? []}
                index={index}
                maxHeight="300px"
                toggleCollapse={toggleCollapse}
              />
            </div>
          );
        })}
      </div>
      {NumberOfFilters.length > 0 && (
        <Box
          style={{
            position: 'sticky',
            bottom: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.5rem',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '0.5rem' }}
            onClick={() => {
              ClearFilters();
              setClearRandom((prev) => prev + 1);
            }}
          >
            Clear Filters
          </Button>
        </Box>
      )}
    </UserProfileContainer>
  );
};

export default memo(UserProfileSetting);
