import React, { memo, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import TuneIcon from '@mui/icons-material/Tune';

import { Card, CardContent, Typography, Grid, Box, ListItem, List, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import useRexApiCall from '../../HOC/useRexApiCall';
import Translator from './Translator';
import IsensImage, {
  aiBackground,
  expertSearchIcon,
  translatorIcon,
  GenaMenuIcon,
  rex360Icon,
} from '../../../assets/IconComponents/IsensImage';
import HomeBackgroundImage from '../../HOC/core/HomeBackgroundImage';
import useKnowledge360Api from '../../HOC/useKnowledge360Api';
import logoGenA from '../../../assets/images/chat_icon_mini.png';
import Rephraser from './Rephraser';
import FooterInFo from './FooterInFo';

const InputContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '50px',
  maxHeight: '50px',
  fontSize: 'average',
  transition: 'display 0.3s ease-out',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5em',
    height: '55px',
    maxHeight: '55px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '100px',
    maxHeight: '100px',
  },
  // transition: 'all 0.3s ease-out',
  '&.hide': {
    display: 'none',
  },
}));

const InputWrapper = styled((props) => <Box {...props} />)(({ theme }) => ({
  border: '1px solid #998760CE',
  backgroundColor: '#fafafa',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));
const InputFeildEnter = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  fontSize: 'average',
  height: '100%',
  width: 'calc(100% - 5rem)',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: 'large',
  //   height: '70px',
  //   maxHeight: '70px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   fontSize: 'average',
  // },
}));

const WrapInputFeild = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  flex: 9,
  display: 'flex',
  flexDirection: 'column',
}));

const ButtonSearch = styled((props) => <Button {...props} />)(({ theme }) => ({
  fontSize: 'large',
  height: '100%',
  color: '#EEF1F9',
  backgroundColor: '#20635e !important',
  border: '1px solid rgba(128, 128, 128, 0.4)',
  // color: '#002A45',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  '&:hover': {
    backgroundColor: '#00988c',
    color: '#EEF1F9',
  },
}));

const ServiceButton = styled((props) => <Button {...props} />)(({ theme, isSelectd }) => ({
  border: isSelectd ? '1px solid #998760CE' : '1px solid rgba(128, 128, 128, 0.4)',
  // backgroundColor: isSelectd ? '#20635e' : '#fafafa',
  // color: '#EEF1F9',
  color: '#002A45',
  scale: isSelectd ? '1.05' : '1',
  boxShadow: isSelectd ? '0 8px 22px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)' : 'none',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  '&:hover': {
    border: '1px solid #998760CE',
    // color: '#EEF1F9',
  },
}));

type ServicesProps = {
  option: string[];
  selectedOption: number;
  handleItemSelection: (service: Object) => void;
  numberOfResults: number;
  numberOfResults8d: number;
  numberOfResultsCR: number;
};

const Services = memo(
  ({
    option = [],
    selectedOption = 0,
    handleItemSelection,
    numberOfResults,
    numberOfResults8d,
    numberOfResultsCR,
  }: ServicesProps) => {
    return (
      <div style={{ display: 'flex', gap: '8px' }}>
        {option.map((item, index) => {
          return (
            <ServiceButton
              key={index}
              isSelectd={selectedOption === index}
              onClick={() => {
                handleItemSelection({ index, item });
              }}
            >
              {item}
              {numberOfResults !== undefined && item === 'KMT REX Cards' && numberOfResults > 0 && (
                <span
                  style={{
                    position: 'absolute',
                    top: '-15px',
                    right: '-5px',
                    width: 'min-content',
                    background: 'rgb(102, 187, 106)',
                    color: 'white',
                    padding: '0rem 0.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '15px',
                  }}
                >
                  {numberOfResults}
                </span>
              )}
              {numberOfResults8d !== undefined && item === '8D REX Cards' && numberOfResults8d > 0 && (
                <span
                  style={{
                    position: 'absolute',
                    top: '-15px',
                    right: '-5px',
                    width: 'min-content',
                    background: 'rgb(102, 187, 106)',
                    color: 'white',
                    padding: '0rem 0.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '15px',
                  }}
                >
                  {numberOfResults8d}
                </span>
              )}
              {numberOfResultsCR !== undefined &&
                item === 'Change Requests' &&
                numberOfResultsCR > 0 && (
                  <span
                    style={{
                      position: 'absolute',
                      top: '-15px',
                      right: '-5px',
                      width: 'min-content',
                      background: 'rgb(102, 187, 106)',
                      color: 'white',
                      padding: '0rem 0.5rem',
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '15px',
                    }}
                  >
                    {numberOfResultsCR}
                  </span>
                )}
            </ServiceButton>
          );
        })}
      </div>
    );
  }
);

export const InputField = memo(
  ({
    filterType,
    toggleCollapse,
    isCollapsed,
    SubmitSearch,
    serviceSearched = { current: '' },
    initialIndex = 0,
    onChangeOption,
    enterValue,
    handleTextChange,
    numberOfResults,
    numberOfResults8d,
    numberOfResultsCR,
    style = {},
    options = [],
    openChat = () => {},
    check,
    filterName = 'Filter',
    placeholder = 'Enter Text',
  }: any) => {
    const [selectedIndex, setSelectedIndex] = React.useState(options.indexOf(filterType) ?? 0);
    const option = useMemo(() => {
      setSelectedIndex(options.indexOf(filterType));
      if (options.indexOf(filterType) === 0) {
        toggleCollapse({ open: isCollapsed.open, service: options[0] });
      }
      return options;
    }, [filterType, options]);

    // console.log('option[selectedIndex]', option[selectedIndex]);

    const handleItemSelection = (payload) => {
      const { index, item } = payload;
      toggleCollapse({ open: isCollapsed.open, service: option[index] });
      setSelectedIndex(index);
      // eslint-disable-next-line no-param-reassign
      serviceSearched.current = item;
      onChangeOption(payload);
    };

    return (
      <>
        <InputContainer style={style} className={check}>
          <ButtonSearch
            sx={{
              flex: filterName !== 'Filter' ? 1.3 : 1,
              borderRight: 'none',
              borderRadius: '8px 0 0 8px',
            }}
            onClick={() => {
              toggleCollapse({ open: true, service: option[selectedIndex] });
            }}
            disabled={
              (selectedIndex !== 0 && filterType === 'KMT REX Cards') ||
              option[selectedIndex] === 'People Search'
            }
            startIcon={<TuneIcon />}
          >
            {filterName ?? 'Filter'}
          </ButtonSearch>
          <InputWrapper
            sx={{ flex: filterName !== 'Filter' ? 8.7 : 9, pl: 1, pr: 1, color: '#002A45' }}
          >
            <InputFeildEnter
              sx={{ pr: 1 }}
              value={enterValue}
              placeholder={placeholder}
              onChange={handleTextChange}
              inputProps={{ 'aria-label': `${placeholder}` }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  SubmitSearch({ selectedService: option[selectedIndex] });
                }
              }}
            />
            {(filterType === 'KMT REX Cards' || filterType === 'Rex360') && (
              <ServiceButton
                // startIcon={<AutoAwesomeIcon />}
                style={{
                  width: '5rem',
                  fontSize: '0.89rem',
                  color: '#002A45',
                  fontWeight: 'bold',
                  border: 'none',
                }}
                onClick={() => {
                  openChat();
                }}
              >
                <img
                  src={logoGenA}
                  alt="isens genA"
                  height="30"
                  width="30"
                  style={{ borderRadius: '50%', marginRight: '5px' }}
                />
                Chat
              </ServiceButton>
            )}
          </InputWrapper>

          <ButtonSearch
            sx={{ flex: '0.5', borderLeft: 'none', borderRadius: '0 8px 8px 0' }}
            onClick={() => {
              SubmitSearch({ selectedService: option[selectedIndex] });
            }}
          >
            <SearchIcon fontSize="large" />
          </ButtonSearch>
        </InputContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Services
            selectedOption={selectedIndex}
            handleItemSelection={handleItemSelection}
            option={option}
            numberOfResults={numberOfResults}
            numberOfResults8d={numberOfResults8d}
            numberOfResultsCR={numberOfResultsCR}
          />
        </div>
      </>
    );
  }
);

const MainContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  // paddingTop: '5rem',
  display: 'inline-flex',
  alignItems: 'center',
  // justifyContent: 'space-between',
  scrollBehavior: 'smooth',
  flexDirection: 'column',
  // gap: '50px',
  width: '100%',
  height: 'calc(100% - 5px)',
  maxHeight: 'calc(100% - 5px)',
  overflow: 'auto',
}));

const SearchBackground = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  zIndex: 1,
  justifyContent: 'center',
  backgroundImage: `url(${aiBackground})`,
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
  // backgroundPosition: 'center',
  width: '100%',
  filter: 'brightness(1)',
  height: 'calc(55rem - 200px)',
  minHeight: 'calc(55rem - 200px)',
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100% - 200px)',
  },
  [theme.breakpoints.up('xl')]: {
    height: 'calc(100% - 200px)',
  },
  // maxHeight: '40rem',
  // '&::after': {
  //   content: '""',
  //   position: 'absolute',
  //   width: '100%',
  //   height: '100%',
  //   top: 0,
  //   left: 0,
  //   backgroundColor: 'rgba(0, 0, 0, 0.55)',
  // },
}));

const TopSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  paddingTop: '3.8rem',
  display: 'inline-flex',
  // minHeight: '20rem',
  color: '#EEF1F9',
  zIndex: 2,
  // maxHeight: '40rem',
  // justifyContent: 'center',
  // justifyContent: 'center',
  // height: '100%',
  // alignItems: 'center',
  flexDirection: 'column',
  width: '90%',
  [theme.breakpoints.up('lg')]: {
    width: '75%',
  },
  // [theme.breakpoints.up('md')]: {
  //   width: '75%',
  // },

  //   height: '40%',
  gap: '25px',
}));

const Rendersection = styled((props) => <Box {...props} />)(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  // [theme.breakpoints.up('lg')]: {
  //   width: '80%',
  // },
  // [theme.breakpoints.up('xl')]: {
  //   width: '85%',
  // },
}));

const ServiceList = styled((props) => <List {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-around',
}));

const ServiceListItem = styled((props) => <ListItem {...props} />)(({ theme, isSelected }) => ({
  width: '20%',
  display: 'flex',
  justifyContent: 'center',
  gap: '0.1rem',
  // flexBasis: '16%',
  flexGrow: 0,
  flexShrink: 0,
  // flex: 1,
  fontFamily: 'Roboto Condensed, serif',
  fontSize: '1.46rem',
  letterSpacing: '0.055rem',
  fontWeight: '600',
  cursor: 'pointer',
  // maxWidth: '24%',
  textAlign: 'center',
  borderBottom: isSelected ? '5px solid #DC3223' : 'none',
  color: isSelected ? '#DC3223' : '#002A45',
}));

const Homepage = ({ toggleCollapse, isCollapsed }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [enterValue, setEnterValue] = React.useState('');
  const serviceSearched = React.useRef(null);
  // const { sideFilter: isCollapsed, setSideFilter } = React.useContext(M2GlobalContext);
  const [services, setServices] = React.useState([
    {
      name: 'GenA',
      selected: false,
      icon: GenaMenuIcon,
      enableOption: false,
      placeholder: 'Search AI insights on train engineering',
    },
    { name: 'Translator', selected: true, icon: translatorIcon, enableOption: false },
    {
      name: 'Knowledge360',
      selected: false,
      icon: expertSearchIcon,
      placeholder: 'Search for Knowledge360',
      enableOption: true,
      options: ['Content Search', 'Similarity Search', 'Referents Search', 'People Search'],
    },
    {
      name: 'Rex360',
      selected: false,
      icon: rex360Icon,
      placeholder: 'Search something',
      enableOption: true,
      options: [
        'KMT REX Cards',
        '8D REX Cards',
        // 'Requirements',
        // 'Referents',
        // 'Tests',
        'Change Requests',
        // 'Six Sigma',
        // 'APSYS',
        // 'CSS',
      ],
    },
    // {
    //   name: 'Re-phraser',
    //   selected: false,
    //   icon: rex360Icon,
    //   placeholder: 'Quality Check',
    //   enableOption: false,
    // },
  ]);

  const history = useHistory();
  const { handleApiCall, loading, error, progressTime } = useRexApiCall();
  const {
    progressTime: progressTimeKnowledge,
    handleMuliApiCall,
    knowlede360loading,
  } = useKnowledge360Api();

  const SubmitSearch = async (payload) => {
    switch (selectedIndex) {
      case 0: {
        return history.push('/home/GenA');
      }

      case 1: {
        return history.push('/home/GenA');
      }
      case 2: {
        const { selectedService } = payload;
        const encodedText = encodeURIComponent(enterValue);
        const service = serviceSearched.current ?? 'Content Search';
        const encodedService = encodeURIComponent(service);
        return handleMuliApiCall(
          enterValue,
          `/home/Knowledge360?service=${encodedService}&text=${encodedText}`,
          selectedService ?? '',
          true
        );
      }
      case 3: {
        const encodedText = encodeURIComponent(enterValue);
        return handleApiCall(
          enterValue,
          `/home/Rex360?service=${'Rex360'}&text=${encodedText}`,
          true
        );
      }

      // case 4: {
      //   const { selectedService } = payload;
      //   return handleMuliApiCall(enterValue, '/home/Knowledge360', selectedService ?? '', true);
      // }

      default:
        return null;
    }
  };

  // console.log('services', serviceSearched);

  const handleItemSelection = (index) => {
    setSelectedIndex(index);
    const newServices = services.map((item, i) => {
      if (i === index) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setServices(newServices);
  };

  const handleTextChange = (e) => {
    setEnterValue(e.target.value);
  };

  const RouteGenA = () => {
    history.push('/GenA');
  };

  const renderService = (index) => {
    switch (index) {
      case 0:
        return RouteGenA();
      case 1:
        return <Translator selectedIndex={selectedIndex} />;
      case 2:
        return (
          <InputField
            filterType="Content Search"
            serviceSearched={serviceSearched}
            toggleCollapse={toggleCollapse}
            isCollapsed={isCollapsed}
            SubmitSearch={SubmitSearch}
            enterValue={enterValue}
            onChangeOption={() => {}}
            handleTextChange={handleTextChange}
            placeholder={services[selectedIndex].placeholder ?? 'Enter Text'}
            options={services[selectedIndex].enableOption ? services[selectedIndex].options : []}
            check={![0, 2, 3].includes(selectedIndex) ? 'hide' : ''}
          />
        );
      case 3:
        return (
          <InputField
            filterType="KMT REX Cards"
            toggleCollapse={toggleCollapse}
            onChangeOption={() => {}}
            isCollapsed={isCollapsed}
            SubmitSearch={SubmitSearch}
            enterValue={enterValue}
            handleTextChange={handleTextChange}
            placeholder={services[selectedIndex].placeholder ?? 'Enter Text'}
            options={services[selectedIndex].enableOption ? services[selectedIndex].options : []}
            check={![0, 2, 3].includes(selectedIndex) ? 'hide' : ''}
            openChat={() => {
              const encodedText = encodeURIComponent(enterValue);
              history.push(`/RexChat?service=chat&text=${encodedText}`);
            }}
          />
        );

      case 4:
        return (
          <>
            <Rephraser />
          </>
        );
      default:
        return <HomeBackgroundImage />;
    }
  };
  // console.log('rendering homepage search');

  const barLaoding = loading || knowlede360loading;
  const progressTimeBar = (progressTime || progressTimeKnowledge) + 10;

  return (
    <>
      <>
        <Box sx={{ width: '100%', height: '5px' }}>
          {barLaoding && (
            <LinearProgress variant="determinate" color="secondary" value={progressTimeBar} />
          )}
        </Box>
      </>

      <MainContainer>
        <SearchBackground>
          <TopSection>
            {/* <h1>Knowledge360 Search</h1> */}
            <ServiceList>
              {services.map((item, index) => {
                return (
                  <ServiceListItem
                    isSelected={item.selected}
                    onClick={() => {
                      handleItemSelection(index);
                    }}
                  >
                    <img src={item.icon} alt={item.name} width="35" height="35" />
                    {item.name}
                  </ServiceListItem>
                );
              })}
            </ServiceList>
            <Rendersection>{renderService(selectedIndex)}</Rendersection>
          </TopSection>
        </SearchBackground>
        <FooterInFo />
      </MainContainer>
    </>
  );
};

export default memo(Homepage);
