import React, { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import HistoryIcon from '@mui/icons-material/History';
import ProjectContext from '../../Project/ProjectContext';
import Description from './Description';
import LayoutContainer from '../../../layouts/LayoutContainer';
import ModuleLayout from '../../../layouts/ModuleLayout';
import SimilarityAnalysis from './Components/SimilarityAnalysis';
import CompareVersion from './Components/CompareVersion';
import ExtractTopics from './Components/ExtractTopics';
import FindDuplicates from './Components/FindDuplicates';
import RexBook from './Components/RexBook';
import DocumentTranslator from './Components/DocumentTranslator';
// import History from './History';
import ProjectFolder from '../../Project/ProjectContainer/components/ProjectFolder/ProjectFolder';
import DetailforTransalator from '../../../components/History/components/DetailforTransalator';
import DocResultContainer from '../../ResultContainer/DocResultContainer';
import History from '../../../components/History/History';
import ResultComponents from '../../ResultContainer/DocumentAnalysisContainer/ResultComponents';
import DocumentSimilarity from './Components/DocumentSimilarity';
import {
  comapreVersionDescription,
  deeplDescription,
  deepLExtensionSuppot,
  documentSimilarityDescription,
  extractTopicDescription,
  findDuplicatesDescription,
  similarityDescription,
} from '../../../const';

const Module2Components = () => {
  const { setProjects, projects, getProjectDetails } = useContext(ProjectContext);
  const { projectId, workloadId } = useParams();
  const project = getProjectDetails(Number(projectId));
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');

  // console.log('actionIs', actionIs);

  const handleResultService = (actionIs) => {
    switch (actionIs) {
      case 'Similarity Analysis':
        return 1;
      case 'Document Similarity':
        return 2;
      case 'Compare Versions':
        return 3;
      case 'Extract Topics':
        return 4;
      case 'Find Duplicates':
        return 5;
      default:
        return 0;
    }
  };

  const componentsData = useMemo(
    () => [
      {
        name: 'Document Translator',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 5,
        fileTypeSupport: deepLExtensionSuppot,
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        buttonRoute: `/project/${projectId}/module2`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Document Translator',
            icon: <TranslateIcon fontSize="small" />,
            numberOfFilesSupport: 5,
            fileTypeSupport: deepLExtensionSuppot,
            select: 'multiple',
            renderComponent: (props) => {
              return (
                <>
                  <DocumentTranslator {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: (props) => {
              const list = deeplDescription;
              return (
                <>
                  <Description list={list} {...props} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Similarity Analysis',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 1,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        buttonRoute: `/project/${projectId}/module2`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Similarity Analysis',
            numberOfFilesSupport: 1,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderComponent: (props) => {
              return (
                <>
                  <SimilarityAnalysis {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: () => {
              return (
                <>
                  <Description list={similarityDescription} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Document Similarity',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 2,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        buttonRoute: `/project/${projectId}/module2`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Document Similarity',
            numberOfFilesSupport: 2,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderComponent: (props) => {
              return (
                <>
                  <DocumentSimilarity {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: () => {
              return (
                <>
                  <Description list={documentSimilarityDescription} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
            renderHistoryDetail: (props) => {
              return (
                <>
                  <DetailforTransalator {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Compare Versions',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 2,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        buttonRoute: `/project/${projectId}/module2`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Compare Versions',
            numberOfFilesSupport: 2,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <CompareVersion {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderDescription: () => {
              return (
                <>
                  <Description list={comapreVersionDescription} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Extract Topics',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 5,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        buttonRoute: `/project/${projectId}/module2`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Extract Topics',
            numberOfFilesSupport: 5,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            select: 'multiple',
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <ExtractTopics {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderDescription: () => {
              return (
                <>
                  <Description list={extractTopicDescription} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Find Duplicates',
        projectName: project?.name ?? '',
        icon: <PlaylistAddCheckOutlinedIcon />,
        route: `/project/${projectId}`,
        buttonRoute: `/project/${projectId}/module2`,
        numberOfFilesSupport: 1,
        fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Find Duplicates',
            numberOfFilesSupport: 1,
            fileTypeSupport: ['pdf', 'xls', 'xlsx', 'csv'],
            icon: <PlaylistAddCheckOutlinedIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <FindDuplicates {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderDescription: () => {
              return (
                <>
                  <Description list={findDuplicatesDescription} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ResultComponents {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Rex Book',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 1,
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        buttonRoute: `/project/${projectId}/module2`,
        fileTypeSupport: ['pdf'],
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Rex Book',
            numberOfFilesSupport: 1,
            fileTypeSupport: ['pdf'],
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <RexBook {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderDescription: () => {
              const list = [
                'REX Book is a compilation of lessons and Return of Experiences (REX) from the past, and applicable to your tender or project to avoid re-doing the same mistakes as in the past.',
                'Upon uploading a document in form of pdf, excel or csv, the REX Book service looks for and complies a list of REX applicable to your tender or project.',
                'You can download the REX Book document and share it across to the relevant PICs and stakeholders.',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          // {
          //   name: 'Result',
          //   icon: <HistoryIcon />,
          //   renderComponent: (props) => {
          //     return (
          //       <>
          //         <DocResultContainer {...props} />
          //       </>
          //     );
          //   },
          // },
        ],
      },
    ],
    [projectId, project, actionIs, workloadId]
  );

  // console.log('workloadId dsd', workloadId);

  return (
    <LayoutContainer
      componentsData={componentsData}
      workload={{
        workload: 0,
        asyncWorkload: workloadId ?? 0,
        serviceFor: { name: 'project', id: projectId },
        selectedIndex: handleResultService(actionIs),
        selectedChildIndex: workloadId && actionIs ? 2 : 0,
      }}
    />
  );
};

export default memo(Module2Components);
