/* eslint-disable no-param-reassign */
import React from 'react';
import { iSearcheSixLayerNew } from '../../../api/module2';
import get_previous_state, { get_default_state, set_selected_option } from './utiles';
import SliderComponent from './components/SliderComponent';
import ToggleComponent from './components/ToggleComponent';

const IsearcherFilters = ({ isearcherFilter, dispatch, callbackFilters, selectedOptionRef }) => {
  if (!isearcherFilter) {
    return { options: [] };
  }
  const {
    isearcherProductLine,
    isearcherPlatforms,
    isearcherSolution,
    isearcherProduct,
    isearcherProjects,
    isearcherDocumentProfiles,
    selectedIsearcherProductLine,
    selectedIsearcherProjects,
    selectedIsearcherDocumentProfiles,
    selectedIsearcherPlatforms,
    selectedIsearcherProduct,
    selectedIsearcherSolution,
    isearcherMaxResultsValue,
  } = isearcherFilter;

  const multifilterCallback = (key) => {
    try {
      const {
        productLines,
        projects,
        documentProfiles,
        platforms,
        products,
        solutions,
        countries = [],
      } = selectedOptionRef.current;
      iSearcheSixLayerNew(
        productLines,
        platforms,
        solutions,
        products,
        projects,
        documentProfiles,
        countries
      ).then((res: any) => {
        const check = [
          'productLines',
          'projects',
          'documentProfiles',
          'platforms',
          'products',
          'solutions',
          'countries',
        ];
        if (res?.filters) {
          // if the response does not have the selected options then we will remove the selected options
          let optionsAvailable = false;
          for (let i = 0; i < check.length; i += 1) {
            if (res?.filters[check[i]] && res?.filters[check[i]].length > 0) {
              optionsAvailable = true;
            } else {
              optionsAvailable = false;
              break;
            }
          }

          if (!optionsAvailable) {
            const temp = {
              productLines: isearcherProductLine,
              projects: isearcherProjects,
              documentProfiles: isearcherDocumentProfiles,
              platforms: isearcherPlatforms,
              products: isearcherProduct,
              solutions: isearcherSolution,
              // countries: isearcherCountry,
            };
            callbackFilters(temp, 'isearcherFilter');
            return;
          }
          const defaultTemp = {
            ...res.filters,
          };

          const {
            productLines,
            projects,
            documentProfiles,
            platforms,
            products,
            solutions,
            countries = [],
          } = selectedOptionRef.current;

          if (
            productLines.length === 0 &&
            projects.length === 0 &&
            documentProfiles.length === 0 &&
            platforms.length === 0 &&
            products.length === 0 &&
            solutions.length === 0
          ) {
            callbackFilters(defaultTemp, 'isearcherFilter');
          } else {
            delete defaultTemp[key];
            callbackFilters(defaultTemp, 'isearcherFilter');
          }
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const temp = [
    {
      name: 'Product Line',
      key: 'productLines',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('productLines', isearcherProductLine, 'isearcherFilter'),
      _cache: get_previous_state(
        selectedIsearcherProductLine,
        'productLines',
        'isearcherFilterSlected'
      ),
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherProductLine', payload: option });
        set_selected_option('productLines', option, 'isearcherFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          productLines: option,
        };
        multifilterCallback('productLines');
      },
    },
    {
      name: 'Platforms',
      key: 'platforms',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('platforms', isearcherPlatforms, 'isearcherFilter'),
      _cache: get_previous_state(selectedIsearcherPlatforms, 'platforms', 'isearcherFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherPlatforms', payload: option });
        set_selected_option('platforms', option, 'isearcherFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          platforms: option,
        };
        multifilterCallback('platforms');
      },
    },
    {
      name: 'Solution',
      key: 'solutions',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('solutions', isearcherSolution, 'isearcherFilter'),
      _cache: get_previous_state(selectedIsearcherSolution, 'solutions', 'isearcherFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherSolution', payload: option });
        set_selected_option('solutions', option, 'isearcherFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          solutions: option,
        };

        multifilterCallback('solutions');
      },
    },
    {
      name: 'Product',
      key: 'products',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('products', isearcherProduct, 'isearcherFilter'),
      _cache: get_previous_state(selectedIsearcherProduct, 'products', 'isearcherFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherProduct', payload: option });
        set_selected_option('products', option, 'isearcherFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          products: option,
        };

        multifilterCallback('products');
      },
    },
    {
      name: 'Projects',
      key: 'projects',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('projects', isearcherProjects, 'isearcherFilter'),
      _cache: get_previous_state(selectedIsearcherProjects, 'projects', 'isearcherFilterSlected'),
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherProjects', payload: option });
        set_selected_option('projects', option, 'isearcherFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          projects: option,
        };

        multifilterCallback('projects');
      },
    },
    {
      name: 'Document Profiles',
      key: 'documentProfiles',
      check: 'false',
      collapsed: true,
      checkAllOption: false,
      options: get_default_state('documentProfiles', isearcherDocumentProfiles, 'isearcherFilter'),
      _cache: get_previous_state(
        selectedIsearcherDocumentProfiles,
        'documentProfiles',
        'isearcherFilterSlected'
      ),
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherDocumentProfiles', payload: option });
        set_selected_option('documentProfiles', option, 'isearcherFilterSlected');
        selectedOptionRef.current = {
          ...selectedOptionRef.current,
          documentProfiles: option,
        };
        multifilterCallback('documentProfiles');
      },
    },
  ];

  const ClearFilters = () => {
    dispatch({ type: 'clear_isearcher_filters' });
    localStorage.removeItem('isearcherFilterSlected');
    localStorage.removeItem('isearcherFilter');
  };

  const otherFilters = {
    isOtherComponent: true,
    renderComponent: (props) => {
      return (
        <>
          <SliderComponent
            dispatch={dispatch}
            type="change_IsercherMaxResultsValue"
            max={500}
            defaultValue={100}
            reset={props}
          />
          <ToggleComponent dispatch={dispatch} type="set_isearcher_door" reset={props} />
        </>
      );
    },
  };

  return { options: temp, otherFilters, ClearFilters };
};

export default IsearcherFilters;
