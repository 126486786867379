/* eslint-disable no-nested-ternary */
import { IconButton, List, ListItem, Tooltip, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React, { memo, useMemo } from 'react';
import { styled } from '@mui/styles';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { padding } from '@mui/system';
import xlsx_icons from '../../../../../assets/xlsx_icons.png';
import csv_icons from '../../../../../assets/csv_icons.png';
import pdf_icons from '../../../../../assets/pdf_icons.png';
import docx_icons from '../../../../../assets/docx_icons.png';
import unsupported from '../../../../../assets/unsupported.png';
import downloadFile from '../../../../../tools/downloadFile';
import { defaultFileIcon, PptDoc } from '../../../../../components/DocumentTranslator/const/png';
import { getBearerTokenSync } from '../../../../../app/AppToken';

const ListItemMui = styled((props) => <ListItem {...props} />)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
}));

export const calculateSize = (size) => {
  let modifiedSize = size / 1000;
  if (modifiedSize < 1024) {
    return `${modifiedSize.toFixed(2)} KB`;
  }
  modifiedSize /= 1024;
  if (modifiedSize < 1024) {
    return `${modifiedSize.toFixed(2)} MB`;
  }
  modifiedSize /= 1024;
  return `${modifiedSize.toFixed(2)} GB`;
};

export const FileIcon = ({ filename, trimText = true }) => {
  const extension = filename?.split('.')?.pop();
  const ImgSrc = () => {
    switch (extension) {
      case 'xlsx':
        return (
          <>
            <Icon component="img" fontSize="small" src={xlsx_icons} alt="xlsx_icon" />
          </>
        );
      case 'pdf':
        return <Icon component="img" fontSize="small" src={pdf_icons} alt="pdf_icon" />;
      case 'csv':
        return <Icon component="img" src={csv_icons} alt="csv_icon" />;
      case 'docx':
        return <Icon component="img" fontSize="large" src={docx_icons} alt="docx_icon" />;
      case 'pptx':
        return <Icon component="img" src={PptDoc} alt="pptx_icon" />;
      case 'ppt':
        return <Icon component="img" src={PptDoc} alt="pptx_icon" />;
      default:
        return <Icon component="img" src={defaultFileIcon} alt="AdemIcon" />;
    }
  };
  // console.log('filename', trimText);

  return (
    <>
      <div style={{ display: 'flex', gap: '0.4rem' }}>
        <ImgSrc />
        <Tooltip title={filename}>
          <Typography style={{ fontSize: '0.89rem' }}>
            {trimText
              ? filename?.length <= 30
                ? filename
                : `${filename?.slice(0, 30)}...`
              : filename}
            {/* {filename?.length <= 30 ? filename : `${filename?.slice(0, 30)}...`} */}
          </Typography>
        </Tooltip>
      </div>
    </>
  );
};

const Files = (props) => {
  const {
    data,
    setSelectedFiles,
    numberOfFilesSupport,
    selectedFiles,
    scopeSetting = true,
    filesDetails,
    projectId,
  } = props;
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedFiles((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectedFiles((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleDownload = (filename: string) => {
    const token = getBearerTokenSync();
    const querystring = `filename=${encodeURI(filename)}&project_id=${projectId}&folder_id=${
      filesDetails.folderId
    }`;
    downloadFile(`/download/resources`, querystring, token, filename);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {/* <Checkbox
                  color="primary"
                  checked={numberOfFilesSupport === selectedFiles.length}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedFiles(data);
                    } else {
                      setSelectedFiles([]);
                    }
                  }}
                /> */}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>
                File Name
              </TableCell>
              {!scopeSetting && (
                <TableCell align="left" style={{ fontWeight: 'bold' }}>
                  Download
                </TableCell>
              )}
              <TableCell align="left" style={{ fontWeight: 'bold' }}>
                Size
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>
                Uploaded By
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice((currentPage - 1) * 10, 10 * currentPage).map((item, index) => {
              const isItemSelected = selectedFiles.includes(item);
              return (
                <TableRow
                  hover
                  // onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  //   aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  //   selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      disabled={numberOfFilesSupport === selectedFiles.length && !isItemSelected}
                      checked={isItemSelected}
                      onChange={(event) => handleCheckboxChange(event, item)}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '7px' }}>
                    <FileIcon filename={item.filename} />
                  </TableCell>
                  {!scopeSetting && (
                    <TableCell align="left">
                      <IconButton
                        onClick={() => {
                          handleDownload(item.filename);
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  <TableCell align="left">{calculateSize(Number(item.size))}</TableCell>
                  <TableCell align="left">{item.userName}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'sticky',
          bottom: '-0.1rem',
          backgroundColor: 'white',
          padding: '0.3rem',
          width: '100%',
        }}
      >
        <Pagination
          count={Math.ceil(data.length / 10)}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

export default memo(Files);
