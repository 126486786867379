import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  ListItem,
  List,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ListIcon from '@mui/icons-material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconBreadcrumbs from '../containers/Home/BreadCrumbs';
import LayoutContainerContext, { LayoutContainerContextProvider } from './LayoutContainerContext';

const MainContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'column',
  fontFamily: 'Roboto, sans-serif',
  //   gap: '50px',
  width: '100%',
  height: 'calc(100% - 5px)',
  maxHeight: 'calc(100% - 5px)',
  // minHeight: 'calc(70% - 5px)',
}));

const ServiceListContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  // height: '4rem',
  padding: '0.7rem 2rem',
  position: 'sticky',
  zIndex: 100,
  top: 0,
  // alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    padding: '0.5rem 1rem',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0.7rem 2rem',
  },
  // justifyContent: 'space-around',
}));

const ServiceList = styled((props) => <List {...props} />)(({ theme }) => ({
  boxShadow: '0 2px 20px 0 rgb(0 0 0 / 10%)',
  overflowX: 'auto',
  padding: '0.2rem 0.5rem',
  fontSize: '0.82rem',
  [theme.breakpoints.up('lg')]: {
    padding: '0.2rem 1rem',
    fontSize: '0.89rem',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0.2rem 1rem',
    fontSize: '0.89rem',
  },
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '10px',
  display: 'flex',
  '::-webkit-scrollbar': {
    display: 'none', // Hide scrollbar for WebKit browsers
  },
  '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
  'scrollbar-width': 'none', // Hide scrollbar for Firefox
}));

const ServiceListItem = styled((props) => <ListItem {...props} />)(({ theme, isSelected }) => ({
  flex: 1,
  justifyContent: 'center',
  minWidth: 'fit-content',
  textAlign: 'center',
  gap: '0.3rem',
  padding: '0.5rem 0.25rem',
  cursor: 'pointer',
  color: '#002A45',
}));

const ServiceListItemSpam = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
}));

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  fontSize: 'average',
  fontFamily: 'Roboto, sans-serif !important',
  '& > *': {
    fontFamily: 'Roboto, sans-serif !important',
  },
  // [theme.breakpoints.up('xl')]: {
  //   fontSize: 'x-large',
  // },
  [theme.breakpoints.up('lg')]: {
    fontSize: 'medium',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1em',
  },
}));

const LeftSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  flexBasis: '15%',
  width: '15%',
  height: '100%',
  padding: '0.5rem',
  backgroundColor: '#EEF1F9',
  maxHeight: '100%',
  overflow: 'auto',
  transition: 'all 0.3s ease-out',
  // [theme.breakpoints.up('lg')]: {
  //   width: '10%',
  //   flexBasis: '10%',
  // },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#002A45',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  //   [theme.breakpoints.down('lg')]: {
  //     width: '20%',
  //     flexBasis: '20%',
  //   },

  //   [theme.breakpoints.down('md')]: {
  //     width: '20%',
  //     flexBasis: '20%',
  //   },
  '&.collapsed': {
    width: '0',
    flexBasis: '0',
    padding: '0',
    overflow: 'hidden',
    '& > *': {
      transition: 'all 0.3s ease-out',
      width: '0',
      overflow: 'hidden',
    },
  },
}));

const LeftOptionHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0rem 0.5rem 0.5rem 0.5rem',
  marginBottom: '0.5rem',
  gap: '0.5rem',
  borderBottom: '1px solid #002A45',
  color: '#002A45',
}));

const ProjectCardContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '0rem 0.5rem 0rem 0rem',
  width: '100%',
  height: 'calc(100% - 40px)',
  maxHeight: 'calc(100% - 40px)',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#002A45',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
}));

const ProjectCard = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '5px 15px',
  borderLeft: '11px solid #DC3223 !important',
  borderRight: '1px solid gray !important',
  borderTop: '1px solid gray !important',
  borderBottom: '1px solid gray !important',
  borderColor: theme.palette.primary.light,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  margin: 1,
  color: '#EEF1F9',
  width: '100%',
  height: '10%',
  opacity: 1,
  background:
    'transparent linear-gradient(128deg, #002A45 0%, #495866 100%) 0% 0% no-repeat padding-box',
  transition:
    '.7s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
  cursor: 'pointer',
  '&:hover': {
    color: '#EEF1F9',
    //   border: 'none',
    transform: 'scale(1.05)',
    zIndex: 4,
    transition: 'all 0.4s ease-out',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
}));

const RightSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  flexBasis: '85%',
  width: '85%',
  display: 'flex',
  flexDirection: 'column',
  // gap: '10px',
  height: '100%',
  maxHeight: '100%',
  overflow: 'auto',
  transition: 'all 0.3s ease-out',
  // [theme.breakpoints.up('lg')]: {
  //   width: '90%',
  //   flexBasis: '90%',
  // },

  //   [theme.breakpoints.down('lg')]: {
  //     width: '80%',
  //     flexBasis: '80%',
  //   },

  //   [theme.breakpoints.down('md')]: {
  //     width: '80%',
  //     flexBasis: '80%',
  //   },
  '&.full': {
    width: '100%',
    flexBasis: '100%',
  },
}));

const RightOptionList = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '5px',
  padding: '0.5rem',
}));

const LeftOptionListItem = styled((props) => <Box {...props} />)(({ theme, isSelected }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  gap: '7px',
  width: '100%',
  color: isSelected ? '#DC3223' : '#002A45',
  cursor: 'pointer',
  //   height: '100%',
  padding: '0.5rem',
  transition: 'width 0.3s ease',
  // borderBottom: isSelected ? '5px solid #DC3223' : 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    backgroundColor: isSelected ? '#DC3223' : '',
    left: 0,
    height: '3px',
    width: isSelected ? '100%' : '0',
  },
  '&:hover': {
    backgroundColor: '#dde4ea',
    transition: 'all 0.4s ease-out',
  },
}));

const ContentSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  // height: 'calc(100% - 10rem)',
  position: 'relative',
  width: '100%',
  // height: 'calc(100% - 2rem)',
  padding: '0rem 2rem 1rem 2rem',
  transition: 'all 0.3s ease-out',
  [theme.breakpoints.down('lg')]: {
    padding: '0rem 1rem 0.5rem 1rem',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0rem 2rem 1rem 2rem',
  },
}));

const SideOptionButton = styled((props) => <IconButton {...props} />)(({ theme }) => ({
  position: 'absolute',
  top: '2.2rem',
  left: 0,
  height: '2rem',
  color: '#EEF1F9',
  borderRadius: '0px 10px 10px 0px',
  zIndex: 100,
  backgroundColor: '#002A45',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const SideOptionButtonIcon = styled((props) => <ChevronLeftIcon {...props} />)(
  ({ theme, isCollapsed }) => ({
    transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.3s ease',
  })
);

const ContentComponent = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 2rem)',
  padding: '0.5rem',
  gap: '1rem',
  width: '90%',
  margin: '0 auto',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: 0,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 0,
  },
}));

const MuiIcon = styled((props) => <Box {...props} />)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? '#002a45' : '#dde4ea',
  color: isSelected ? 'rgb(255, 255, 255)' : 'rgb(73, 78, 89)',
  minHeight: '2rem',
  minWidth: '2rem',
  display: 'flex',
  borderRadius: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  boxShadow:
    'rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem',
}));

const LayoutContainer = ({ componentsData, workload: _workload }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(_workload.selectedIndex ?? 0);
  const [selectedChildIndex, setSelectedChildIndex] = React.useState(
    _workload.selectedChildIndex ?? 0
  );
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [workload, setWorkload] = React.useState({
    workload: 0,
    workloads: [],
    serviceFor: {},
    ..._workload,
  });
  const history = useHistory();

  useEffect(() => {
    setWorkload((prev) => ({ ...prev, ..._workload }));
    if (_workload.selectedChildIndex) {
      setSelectedChildIndex(_workload.selectedChildIndex ?? 0);
    }
  }, [_workload]);

  const services = useMemo(() => componentsData, [componentsData]);

  const toggleCollapse = useCallback(
    (check = true) => {
      setIsCollapsed(check);
    },
    [isCollapsed]
  );

  const handleItemSelection = (index) => {
    setSelectedIndex(index);
    setSelectedChildIndex(0);
    setWorkload((prev) => ({ ...prev, workloads: [] }));
  };

  const handleChildItemSelection = (index) => {
    setSelectedChildIndex(index);
  };

  return (
    <>
      <LayoutContainerContextProvider>
        <MainContainer>
          {/* <div style={{ width: '100%', height: '1.5rem' }}>
          <IconBreadcrumbs
            nodes={[{ name: services[selectedIndex].projectName, route: '/home' }]}
            service={services[selectedIndex].name}
            style={{ paddingLeft: '2rem' }}
          />
        </div> */}
          <Container>
            <LeftSection
              className={
                isCollapsed || !services[selectedIndex].enableLeftOption ? 'collapsed' : ''
              }
            >
              <LeftOptionHeader>
                <ListIcon />
                <Typography variant="h7" style={{ fontWeight: 'bold' }}>
                  Menu
                </Typography>
              </LeftOptionHeader>
              {services[selectedIndex].enableLeftOption &&
                services[selectedIndex]?.leftOption.map((item, index) => {
                  return (
                    <>
                      <LeftOptionListItem
                        isSelected={selectedChildIndex === index}
                        onClick={() => {
                          handleChildItemSelection(index);
                          history.push(services[index]?.buttonRoute);
                        }}
                      >
                        {item.icon}
                        {item.name}
                      </LeftOptionListItem>
                    </>
                  );
                })}
            </LeftSection>
            <RightSection
              className={isCollapsed || !services[selectedIndex].enableLeftOption ? 'full' : ''}
            >
              <ServiceListContainer>
                <ServiceList>
                  {services.map((item, index) => {
                    return (
                      <ServiceListItem
                        isSelected={selectedIndex === index}
                        onClick={() => {
                          handleItemSelection(index);
                          // console.log('buttonRoute', services[index].buttonRoute, selectedIndex);
                          history.push(services[index].buttonRoute);
                        }}
                      >
                        <ServiceListItemSpam>
                          <MuiIcon isSelected={selectedIndex === index}>{item.icon}</MuiIcon>
                          {item.name}
                        </ServiceListItemSpam>
                      </ServiceListItem>
                    );
                  })}
                </ServiceList>
              </ServiceListContainer>
              <ContentSection>
                <div
                  style={{
                    width: '100%',
                    height: '1.5rem',
                    marginBottom: '1.8rem',
                  }}
                >
                  <IconBreadcrumbs
                    nodes={[
                      {
                        name: services[selectedIndex].projectName,
                        route: services[selectedIndex].route,
                      },
                    ]}
                    service={services[selectedIndex].name}
                    // style={{ paddingLeft: '2rem' }}
                  />
                </div>
                {services[selectedIndex].enableLeftOption ? (
                  <>
                    <SideOptionButton
                      onClick={() => {
                        toggleCollapse(!isCollapsed);
                        // setWorkload((prev) => ({ ...prev, workload: 0 }));
                      }}
                      color="primary"
                    >
                      <SideOptionButtonIcon isCollapsed={isCollapsed} />
                    </SideOptionButton>
                    <ContentComponent>
                      {services[selectedIndex].leftOption[selectedChildIndex].renderComponent({
                        selectedOption:
                          services[selectedIndex].leftOption[selectedChildIndex].name ?? '',
                        ...services[selectedIndex].leftOption[selectedChildIndex],
                        setSelectedChildIndex,
                        modelService: services[selectedIndex].name,
                        workload,
                        setWorkload,
                      })}
                    </ContentComponent>
                  </>
                ) : (
                  <ContentComponent>
                    {services[selectedIndex].renderComponent({
                      ...services[selectedIndex],
                      setSelectedChildIndex,
                      modelService: services[selectedIndex].name,
                      workload,
                      setWorkload,
                    })}
                  </ContentComponent>
                )}
              </ContentSection>
            </RightSection>
          </Container>
        </MainContainer>
      </LayoutContainerContextProvider>
    </>
  );
};

export default memo(LayoutContainer);
