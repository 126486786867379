import React, { useContext } from 'react';
import { useTheme, AppBar, Tabs, Tab, Typography, Box } from '@mui/material';
import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import IverifierTemplateConfig from '../../../../Iverifier/Config/IverifierTemplateConfig';
import IVerifierAttributeCRUD from '../../../../Iverifier/Config/IVerifierAttributeCRUD';
import IverifierRules from '../../../../Iverifier/Config/IverifierRules';
import IverifierFunction from '../../../../Iverifier/Config/IverifierFunction';
import TabStyle from '../../../../../components/Layout/TabStyle';
import Module1Context from '../../../../Module1/Module1Context';
import ProjectIverifierContainer from './styles/ProjectiVerifierStyle';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function ProjectIverifier(projectId: any) {
  const classesTab = TabStyle();

  const theme = useTheme();
  const [value, setValue] = React.useState(2);

  const { project } = useContext(Module1Context);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ProjectIverifierContainer className="root" style={{ marginTop: 10 }}>
      <AppBar position="static" className="appStyle">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          // style={{ backgroundColor: '#EBE6DC' }}
          style={{
            color: '#002A45',
            fontWeight: 'bold',
            opacity: '1 !important',
            fontSize: '-0.125rem',
          }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className="tabLebelText">
                  <span>
                    <AssignmentOutlinedIcon className="iconLabelWrapper" />
                  </span>
                  Template Upload
                </Typography>
              </>
            }
            {...a11yProps(0)}
            className={value === 0 ? 'tabstyle' : ''}
            // style={{ fontSize: '18px', fontWeight: 'bold' }}
          />
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className="tabLebelText">
                  <span>
                    <InsertDriveFileOutlinedIcon className="iconLabelWrapper" />
                  </span>
                  Rule Configurations
                </Typography>
              </>
            }
            {...a11yProps(1)}
            className={value === 1 ? 'tabstyle' : ''}
            // style={{ fontSize: '18px', fontWeight: 'bold' }}
          />
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className="tabLebelText">
                  <span>
                    <FontDownloadOutlinedIcon className="iconLabelWrapper" />
                  </span>
                  Rules
                </Typography>
              </>
            }
            {...a11yProps(2)}
            className={value === 2 ? 'tabstyle' : ''}
          />
          <Tab
            label={
              <>
                <Typography variant="subtitle2" className="tabLebelText">
                  <span>
                    <InsertDriveFileOutlinedIcon className="iconLabelWrapper" />
                  </span>
                  Functions
                </Typography>
              </>
            }
            {...a11yProps(3)}
            className={value === 3 ? 'tabstyle' : ''}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <div>
          <IverifierTemplateConfig />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <div>
          <IVerifierAttributeCRUD projectId={projectId} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <div>
          <IverifierRules />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <div>
          <IverifierFunction />
        </div>
      </TabPanel>
    </ProjectIverifierContainer>
  );
}

export default ProjectIverifier;
