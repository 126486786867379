import React, { memo } from 'react';
import ExpertSearch from './ExpertSearch';
import ContentSearchResult from './ContentSearchResult';
import FindSimilarTexts from './FindSimilarTexts';
import PeopleSearch from './PeopleSearch';
import NoResultsFound from '../../../components/Core/NoResultsFound';

const KnowledgeResultContainer = ({
  response,
  callOperation,
  nameClick,
  handleClickText2DatasetNew,
}) => {
  const renderService = () => {
    // {errorMsg && <NoResultsFound />}

    switch (callOperation) {
      case 'Similarity Search': {
        const { similarTextRow } = response;
        return <FindSimilarTexts rowResult={similarTextRow} />;
      }

      case 'Content Search': {
        const { rows, contentSearchPayload, text, kpi, timeTaken, totalResult } = response;
        const {
          alstomEntityWiseDistribution,
          platformWiseDistribution,
          projectWiseDistribution,
          documentProfileWiseDistribution,
          solutionWiseDistribution,
          countryWiseDistribution,
          productWiseDistribution,
          artifactWiseDistribution,
        } = kpi;
        return (
          <ContentSearchResult
            lable=""
            rowResult={rows}
            contentSearchPayload={contentSearchPayload}
            inputValue={text}
            alstomEntityWiseDistribution={alstomEntityWiseDistribution}
            platformWiseDistribution={platformWiseDistribution}
            projectWiseDistribution={projectWiseDistribution}
            documentProfileWiseDistribution={documentProfileWiseDistribution}
            solutionWiseDistribution={solutionWiseDistribution}
            countryWiseDistribution={countryWiseDistribution}
            productWiseDistribution={productWiseDistribution}
            artifactWiseDistribution={artifactWiseDistribution}
            timeTaken={timeTaken}
            totalResult={totalResult}
          />
        );
      }

      case 'Referents Search': {
        const {
          rows,
          payloadMain,
          inputValue,
          kpi,
          artifactKpi,
          timeTaken,
          totalResult,
          smartData,
          totalPeopleResult,
          expertDomain,
        } = response;

        const {
          alstomEntityWiseDistribution,
          platformWiseDistribution,
          projectWiseDistribution,
          documentProfileWiseDistribution,
          solutionWiseDistribution,
          countryWiseDistribution,
          productWiseDistribution,
          artifactWiseDistribution,
        } = kpi;
        return (
          <ExpertSearch
            rowResult={rows}
            alstomEntityWiseDistribution={alstomEntityWiseDistribution}
            platformWiseDistribution={platformWiseDistribution}
            projectWiseDistribution={projectWiseDistribution}
            documentProfileWiseDistribution={documentProfileWiseDistribution}
            solutionWiseDistribution={solutionWiseDistribution}
            countryWiseDistribution={countryWiseDistribution}
            productWiseDistribution={productWiseDistribution}
            artifactWiseDistribution={artifactWiseDistribution}
            expertDomain={expertDomain}
            nameClick={nameClick}
            smartData={smartData}
            artifactKpi={artifactKpi}
            totalPeopleResult={totalPeopleResult}
            timeTaken={timeTaken}
            payloadMain={payloadMain}
          />
        );
      }

      case 'People Search': {
        const {
          rows,
          payloadPeopleSearch,
          kpi,
          timeTaken,
          totalPeopleResult,
          text,
          oldPayload,
        } = response;

        const {
          alstomEntityWiseDistribution,
          platformWiseDistribution,
          projectWiseDistribution,
          documentProfileWiseDistribution,
          solutionWiseDistribution,
          countryWiseDistribution,
          productWiseDistribution,
          artifactWiseDistribution,
        } = kpi;
        if (text === '') return <NoResultsFound />;
        return (
          <PeopleSearch
            rows={rows}
            payloadPeopleSearch={payloadPeopleSearch}
            alstomEntityWiseDistribution={alstomEntityWiseDistribution}
            platformWiseDistribution={platformWiseDistribution}
            projectWiseDistribution={projectWiseDistribution}
            documentProfileWiseDistribution={documentProfileWiseDistribution}
            solutionWiseDistribution={solutionWiseDistribution}
            countryWiseDistribution={countryWiseDistribution}
            productWiseDistribution={productWiseDistribution}
            artifactWiseDistribution={artifactWiseDistribution}
            timeTaken={timeTaken}
            TotalPeopleResult={totalPeopleResult}
            oldPayload={oldPayload}
            handleClickText2DatasetNew={handleClickText2DatasetNew}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  return <>{renderService()}</>;
};

export default memo(KnowledgeResultContainer);
