import React from 'react';

const useSuccess = () => {
  const [successMsg, setSuccessMsg] = React.useState('');

  React.useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg('');
      }, 2000);
    }
  }, [successMsg]);

  return [successMsg, setSuccessMsg] as [string, React.Dispatch<React.SetStateAction<string>>];
};

export default useSuccess;
