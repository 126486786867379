/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ModuleLayout from '../../../layouts/ModuleLayout';
import ProjectContext from '../../Project/ProjectContext';
import useUpload from '../../../layouts/Hooks/useUpload';
import DocTranslatorHeader from '../../Module2/Module/core/DocTranslatorHeader';
import useUserFileUpload from '../../../layouts/Hooks/useUserFileUpload';
import useAppToken from '../../../app/useAppToken';
import GlossaryFiles from '../FileManger/GlossaryFiles';
import DocTranslatorAnalysis from '../../Module2/Module/DocTranslatorAnalysis';
import { supportedfiles } from '../../../const';
import UserSpaceContext from '../UserSpaceContext';

const DocumentTranslator = (props) => {
  const [inputFileOpen, setInputFileOpen] = React.useState(false);
  const [openGlossary, setOpenGlossary] = React.useState({ open: false, indexes: [] });
  const [isAnalysis, setIsAnalysis] = React.useState(false);
  const [selectionFile, setSelectionFile] = React.useState(null);
  const { files: filesfromContext, setFiles } = useContext(UserSpaceContext);
  const [gloablGlossary, setGlobalGlossary] = React.useState([]);
  const [docTranslation, setDocTranslation] = React.useState({
    fromLang: 'French-FR',
    toLang: 'French-FR',
    model: 'DeepL',
    glossary: null,
  });
  const {
    enableDescription,
    renderDescription,
    numberOfFilesSupport,
    fileTypeSupport,
    renderFiles,
    select,
    setSelectedChildIndex,
    workload,
    setWorkload,
  } = props;
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const { model } = docTranslation;
  const actualFilSupported = useMemo(() => {
    if (model === 'Microsoft') {
      return supportedfiles;
    }
    return fileTypeSupport;
  }, [model, fileTypeSupport]);
  const { loading, handleChangeInDragefile, handleUploadFile } = useUserFileUpload({
    user_id,
    fileTypeSupport: actualFilSupported,
    doScopeSetting: false,
    files: filesfromContext,
    setFiles,
  });

  // console.log('actualFilSupported', actualFilSupported);

  const desciptionProps = {
    docTranslation,
    setDocTranslation,
  };

  const DocumentTranslatorProps = useMemo(
    () => ({
      state: {
        inputFileOpen,
        setInputFileOpen,
        isAnalysis,
        setIsAnalysis,
        selectionFile,
        setSelectionFile,
        setSelectedChildIndex,
        loading,
        workload,
        setWorkload,
        openGlossary,
        setOpenGlossary,
      },
      enableDescription,
      enableHeader: false,
      select,
      renderDescription: () => {
        return <>{renderDescription({ desciptionProps, renderTranslation: true })}</>;
      },
      renderFiles,
      serviceName: 'Document Translator',
      serviceFor: { name: 'user', id: user_id },
      numberOfFilesSupport,
      fileTypeSupport: actualFilSupported,
      disableScopeSettings: true,
      handleChangeInDragefile,
      handleUploadFile,
      renderGlossary: (props) => {
        return (
          <>
            <GlossaryFiles {...props} />
          </>
        );
      },
      renderHeader: () => {
        return (
          <>
            <DocTranslatorHeader docTranslation={docTranslation} />
          </>
        );
      },
      renderAnalysis: (props) => {
        // console.log('props', props);
        return (
          <>
            <DocTranslatorAnalysis
              {...props}
              gloablGlossary={gloablGlossary}
              docTranslation={docTranslation}
              setDocTranslation={setDocTranslation}
              actualFilSupported={actualFilSupported}
            />
          </>
        );
      },
    }),
    [
      inputFileOpen,
      isAnalysis,
      selectionFile,
      enableDescription,
      renderDescription,
      loading,
      workload,
      gloablGlossary,
      docTranslation,
      actualFilSupported,
    ]
  );

  return (
    <>
      <ModuleLayout moduleData={DocumentTranslatorProps} />{' '}
    </>
  );
};

export default memo(DocumentTranslator);
