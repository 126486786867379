/**
 * This component renders of a Context M2Global comp of the page
 * new api iSearcherCEPGetFilters changes done by @Ranjan for the Filter First time loaded
 * new object atrributes added
 * @param {string} props
 * @returns {ReactNode} A React element that renders M2Global component of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import React, { useEffect, useReducer, useState } from 'react';
import {
  getHistoryTranslation,
  iSearcherCEPGetFilters,
  iSearcherGetArtifactEntityFilters,
  iSearcherGetFilters,
  RexFilters,
  RexFiltersNew,
  textToTextInitialFilter,
} from '../../api/module2';
import useAppToken from '../../app/useAppToken';
import { reducers, initialState } from '../Reducers/combineReducer';
import { getAllProjectCreationLanguages } from '../../api/projects';
import { getGrossaryFile } from '../../api/Workspace';

export interface M2GlobalContextType {
  state: any;
  dispatch: any;
  sideFilter: any;
  setSideFilter: any;
  languageCode: any;
  setLanguageCode: any;
  setGlossaryFiles: any;
  glossaryFiles: any;
}

type Props = {
  children: any;
};

const M2GlobalContext = React.createContext<M2GlobalContextType>({
  state: {} as any,
  dispatch: {} as any,
  sideFilter: Boolean as any,
  setSideFilter: {} as any,
  languageCode: [] as any,
  setLanguageCode: {} as any,
  glossaryFiles: [] as any,
  setGlossaryFiles: (): any => {},
});

const M2GlobalContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducers, initialState);
  const [glossaryFiles, setGlossaryFiles] = useState([]);
  const [sideFilter, setSideFilter] = useState({ open: false, service: '' });
  const { appJWT } = useAppToken();
  const [languageCode, setLanguageCode] = React.useState<any>([]);

  useEffect(() => {
    // getHistoryTranslation(Number(appJWT?.user_id))
    //   .then((data) => {
    //     if (data.length > 0) setHistoryTranslation(data[0]?.translated_data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });

    RexFilters().then((payload: any) => {
      if (payload) {
        // console.log('payload', payload);
        const issuerS = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.sites) {
          const item = payload.filters.sites[i];
          issuerS.push(item);
        }
        const plat = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.platforms) {
          const item = payload.filters.platforms[i];
          plat.push(item);
        }
        const rexSource = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.sources) {
          const item = payload.filters.sources[i];
          rexSource.push(item);
        }
        const statusFil = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.statuses) {
          const item = payload.filters.statuses[i];
          statusFil.push(item);
        }
        const typologyREX = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.typologies) {
          const item = payload.filters.typologies[i];
          typologyREX.push(item);
        }
        const productLine = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.productLines) {
          const item = payload.filters.productLines[i];
          productLine.push(item);
        }
        const product = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.products) {
          const item = payload.filters.products[i];
          product.push(item);
        }
        const solution = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.solutions) {
          const item = payload.filters.solutions[i];
          solution.push(item);
        }
        const project = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.projects) {
          const item = payload.filters.projects[i];
          project.push(item);
        }
        const country = [];
        // eslint-disable-next-line guard-for-in
        for (const i in payload.filters?.countries) {
          const item = payload.filters.countries[i];
          country.push(item);
        }
        const obj = {
          rexIssuerSite: issuerS,
          rexPaltform: plat,
          rexSource,
          rexStatus: statusFil,
          rexTypology: typologyREX,
          rexProductLine: productLine,
          rexProduct: product,
          rexSolution: solution,
          rexProject: project,
          rexCountry: country,
        };

        // sessionStorage.setItem('rex_def_filter', JSON.stringify(obj));
        dispatch({ type: 'set_Rex_filter', payload: obj });
      }
    });

    iSearcherCEPGetFilters()
      .then((payload: any) => {
        if (payload) {
          const ent: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.productLines) {
            const item = payload.filters.productLines[i];
            ent.push(item);
          }
          const plat: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            plat.push(item);
          }
          const pro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            pro.push(item);
          }
          const docpro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            docpro.push(item);
          }
          const sol: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            sol.push(item);
          }
          const prod: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            prod.push(item);
          }
          const cont: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.countries) {
            const item = payload.filters.countries[i];
            cont.push(item);
          }
          const obj = {
            isearcherProductLine: ent,
            isearcherProjects: pro,
            isearcherDocumentProfiles: docpro,
            isearcherPlatforms: plat,
            isearcherSolution: sol,
            isearcherProduct: prod,
            isearcherCountry: cont,
          };
          sessionStorage.setItem('iSearcher_def_filter', JSON.stringify(obj));
          dispatch({ type: 'set_iSearcher_default_fill', payload: obj });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    if (sessionStorage.getItem('artifact_def_filter')) {
      dispatch({
        type: 'set_artifact_fill',
        payload: JSON.parse(sessionStorage.getItem('artifact_def_filter')),
      });
    } else {
      iSearcherGetArtifactEntityFilters()
        .then((payload: any) => {
          if (payload) {
            const ent: any = [];
            // eslint-disable-next-line guard-for-in
            for (const i in payload) {
              const item = payload[i];
              ent.push({
                label: item,
                value: item,
              });
            }
            sessionStorage.setItem('artifact_def_filter', JSON.stringify(ent));
            dispatch({ type: 'set_artifact_fill', payload: ent });
            // setArtiFact(ent);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }

    textToTextInitialFilter()
      .then((payload: any) => {
        if (payload) {
          const ent = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.alstomEntities) {
            const item = payload.filters.alstomEntities[i];
            ent.push(item);
          }
          const plat: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.platforms) {
            const item = payload.filters.platforms[i];
            plat.push(item);
          }
          const sol: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.solutions) {
            const item = payload.filters.solutions[i];
            sol.push(item);
          }
          const prod: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.products) {
            const item = payload.filters.products[i];
            prod.push(item);
          }
          const pro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.projects) {
            const item = payload.filters.projects[i];
            pro.push(item);
          }
          const docpro: any = [];
          // eslint-disable-next-line guard-for-in
          for (const i in payload.filters?.documentProfiles) {
            const item = payload.filters.documentProfiles[i];
            docpro.push(item);
          }

          const obj = {
            simiProductLine: ent,
            simiProject: pro,
            simiSolution: sol,
            simiProduct: prod,
            simiDocumentProfile: docpro,
            simiPlatform: plat,
          };
          sessionStorage.setItem('simi_def_filter', JSON.stringify(obj));
          dispatch({ type: 'set_simi_default_fill', payload: obj });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });

    getGrossaryFile(appJWT.user_id).then((result) => {
      const allResources = [] as any;
      if (result.length > 0) {
        const { resources } = result[0];
        for (const r of resources) {
          allResources.push({
            ...r,
            // size: formatBytes(Number(r.size), 0),
            size: r.size,
            id: r.etag,
            folderName: 'user',
            folderId: 0,
            isNew: false,
          });
        }
      }
      setGlossaryFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));
    });
  }, []);
  return (
    <M2GlobalContext.Provider
      value={{
        state,
        dispatch,
        sideFilter,
        setSideFilter,
        languageCode,
        setLanguageCode,
        glossaryFiles,
        setGlossaryFiles,
      }}
    >
      {children}
    </M2GlobalContext.Provider>
  );
};

export default M2GlobalContext;

export { M2GlobalContextProvider };
