/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import {
  Column,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Button, Chip, MenuItem, Select, TableCell, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CallSplit from '@material-ui/icons/CallSplit';
import DeleteIcon from '@material-ui/icons/Delete';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import ImageIcon from '@mui/icons-material/Image';
import MergeIcon from '@mui/icons-material/Merge';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SaveIcon from '@mui/icons-material/Save';
import TableChartIcon from '@mui/icons-material/TableChart';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Checkbox from '@mui/material/Checkbox';
import { deleteOwning, saveOwning, getOwning } from '../../../../../api/ownings';
import useProjectId from '../../../../../app/useProjectId';
import Loading from '../../../../../components/Core/Loading';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import {
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../../components/Datagrid/TStyles';
import {
  insertNewCapture,
  insertCharacterizer,
  mergeCapture,
  mergeRepeatCapture,
  PayloadInsertNewCapture,
  PayloadSplit,
  splitCapture,
} from '../../../../../api/module1';
import { CHARACTERIZE_COLORS, IS_DEBUG_MODE } from '../../../../../const';
import { handleColumns } from '../../../../../tools/datagrid';
import ProjectContext from '../../../../Project/ProjectContext';
import Module1Context from '../../../Module1Context';
import { allColumns } from '../../../tools/allColumns';
import ChararcterizerModalKpi from './ChararcterizerModalKpi';
import ModalAddCharacterizerRow from './ModalAddCharacterizerRow';
import ModalRawdata from './ModalRawdata';
import ModalSplit from './ModalSplit';
import Similarizer from './Similarizer';
import { textWidth } from './useStyle';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';
import IAllocatorStyle from '../../../../../components/Datagrid/TableAllNewStyle/IAllocatorStyle';
import { workloadProjectStatus } from '../../../../../api/workloads';
import RenderCellText from './RenderCellText';
import RenderCellExcel from './RenderCellExcel';
import ModalConfirm from '../../../../Admin/components/ModalConfirm';
import { LangCode } from '../../../../../components/Datagrid/TranslationList';
import PopupEditing, { Popup } from './core/EditRow';
import CircularPogressBar from '../../../../../components/Core/CircularProgressBar';
import useDownloadFile from './Hooks/useDownloadFile';

dayjs.extend(utc);
const stylingObject = {
  ahref: {
    textDecoration: 'none',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    color: '#002A45',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
};

export type rawTypeEnum = 'images' | 'tables';

type Props = {
  dispatch?: any;
  filename: string;
  onChange?: any;
  onSearchText?: any;
  actionForFile?: string;
  setActionForFile?: any;
  originalText?: any;
  setOriginalText?: any;
  levelsHierarchy?: any;
  entity?: any;
  onDownload?: any;
  rows: any[];
  isRegExp?: boolean;
  targetColumn?: any;
  displayColumns?: any[];
  workloadId: number;
  onReset?: any;
  langCheck?: any;
  languageOriginCode?: LangCode;
  languages?: LangCode[];
  setSctionForFile: any;
};

function CharacterizerFlow({
  dispatch,
  filename,
  actionForFile,
  setActionForFile,
  onChange,
  onSearchText,
  originalText,
  setOriginalText,
  levelsHierarchy,
  entity,
  onDownload,
  rows,
  langCheck,
  targetColumn,
  displayColumns,
  isRegExp,
  workloadId,
  onReset,
  languageOriginCode,
  languages,
}: Props) {
  // get the project

  const { projectId } = useParams() as any;
  const { projects_lite } = useContext(ProjectContext);
  const { setSnack, translationCode, setTranslationCode, languagesCode } = useContext(
    Module1Context
  );
  const { project } = useProjectId(projectId, projects_lite);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [idsChanged, setIdsChanged] = useState<any>([]);
  const [rowsEdited, setRowsEdited] = useState<any>(rows);
  const [rowsDeleted, setRowsDeleted] = useState<any>([]);
  const [selection, setSelection] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  // state for iSimilarizer
  const [openSim, setOpenSim] = useState(false);
  const [rowsSim, setRowsSim] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const classes1 = IAllocatorStyle();
  const textColum = targetColumn ?? 'Text';

  const [columns] = useState<any>(
    handleColumns(
      allColumns('characterize', {
        entity,
        targetColumn,
        displayColumns,
        isRegExp,
        levelsHierarchy,
      })
    )
  );
  const [rowsExport, setRowsExport] = useState<any>([]);
  const [openModalSplit, setOpenModalSplit] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSizes = [5, 10, 20, 50];
  const [tempPage, setTemPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(pageSizes[pageSizes.length - 1] ?? 50);
  const [files, setFiles] = useState();

  // modal raw data
  const [imagesList, setImagesList] = useState<any[]>([]);
  const [tablesList, setTablesList] = useState<any[]>([]);
  const [openDashboard, setOpenDashboard] = useState(false);
  const [rawType, setRawType] = useState<rawTypeEnum>('images');
  const [toggleRawdata, setToggleRawdata] = useState(false);
  const [openModalAddCaptureRow, setOpenModalAddCaptureRow] = useState(false);
  const [editingRowIds, setEditingRowIds] = useState([]);
  // const columnsExport = columns.map((c) => ({
  //   name: c.name,
  //   title: c.name,
  // }));

  const exporterRef = useRef(null);
  const targetPage = `${workloadId}CHARCTER${projectId}`;
  const targetPageSize = `${workloadId}CHARCTERSIZE${projectId}`;

  // status states

  const [percentage, setPercentage] = useState(0);
  const [loadCAll, setLoadCAll] = useState(false);

  const [showTable, setShowTable] = useState(false);
  let intervalStatus: number;
  let intervalCallApI: number;

  // download selected columns states

  const [col, setCol] = useState(
    columns.map((data) => {
      return data?.name;
    })
  );
  const [columnSelected, setColumnSelected] = useState([
    'updated_at',
    'Text_original',
    'Context_original',
    // 'Original_Hierarchy',
    'Hierarchy',
    // 'Characterization',
    // 'Char_Score',
    ...levelsHierarchy,
    // 'Deeper_Characterization',
    // 'Deeper_Char_Score',
    'Context',
    'Digital',
    'comment',
    'id',
  ]);
  const [displayColumnDown, setDisplayColumnDown] = useState([]);

  const CustomWidth2Tooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} style={{ maxWidth: '376px !important' }} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      '&.MuiTooltip-tooltip': {
        maxWidth: '376px !important',
      },
      backgroundColor: !['characterizer'].includes(actionForFile) ? 'lightgrey' : '#eef1f9',
      border: '1px solid black',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      width: 600,
      transition: 'opacity 1s',
      // height: 30,
      '& .MuiTooltip-arrow': {
        color: '#eef1f9',
        '&:before': {
          backgroundColor: !['characterizer'].includes(actionForFile) ? 'lightgrey' : '#eef1f9',
          boxShadow: 'none',
          borderBottom: `1px solid black`,
          borderRight: `1px solid black`,
          fontSize: 16,
        },
      },
    },
  });

  const ProjectStatus = () => {
    workloadProjectStatus(projectId, workloadId).then((data) => {
      setPercentage(data[0]?.progress_percent);
      if (
        (data[0]?.action === 'characterizer' && data[0]?.progress_percent >= 100) ||
        data[0]?.action === 'allocator'
      ) {
        clearInterval(intervalStatus);
        callingAPI();
      }
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem(targetPageSize) == null) {
      setPageSize(pageSize[pageSize.length - 1] ?? 50);
    } else {
      const previouspagesize = sessionStorage.getItem(targetPageSize, pageSize);
      setPageSize(previouspagesize);
    }
    if (sessionStorage.getItem(targetPage) == null) {
      setCurrentPage(0);
    } else {
      const previouspage = sessionStorage.getItem(targetPage, currentPage);
      setCurrentPage(previouspage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (['characterizer', 'allocator'].includes(actionForFile)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const fileStatus = false;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      callingAPI(fileStatus);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalStatus = setInterval(ProjectStatus, 2000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalStatus);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    // populate images
    const imageArray = [];
    const tableArray = [];
    for (const row of rowsEdited) {
      if (row.Characterization === 'Image') {
        imageArray.push({ id: row.id, title: row.Text, data: row.RawData });
      }
      if (row.Characterization === 'Table') {
        tableArray.push({ id: row.id, data: row.RawData });
      }
    }
    setImagesList(imageArray);
    setTablesList(tableArray);

    setLoading(false);
    if (loadCAll) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervalCallApI = setInterval(callingAPI, 2000);
    }
    // eslint-disable-next-line consistent-return
    return () => {
      // clearInterval(intervalStatus);
      clearInterval(intervalCallApI);
    };
  }, [loadCAll]);
  useEffect(() => {
    // populate images
    const imageArray = [];
    const tableArray = [];
    for (const row of rowsEdited) {
      if (row.SubType === 'Image') {
        imageArray.push({ id: row.id, title: row.Text, data: row.RawData });
      }
      if (row.SubType === 'Table') {
        tableArray.push({ id: row.id, data: row.RawData });
      }
    }

    setImagesList(imageArray);
    setTablesList(tableArray);
  }, [rowsEdited]);

  useEffect(() => {
    sessionStorage.setItem(targetPageSize, pageSize);
  }, [pageSize]);

  function callingAPI(_status = true) {
    getOwning(projectId, Number(workloadId), filename)
      .then((response) => {
        const checOjb = response[0].row_capture || {};
        if (checOjb && 'Allocation' in checOjb) {
          dispatch({ type: 'assignment' });
          dispatch({ type: 'characterizer' });
          dispatch({ type: 'capture' });
          setActionForFile('allocator');
        } else if (checOjb && 'Char_Score' in checOjb) {
          dispatch({ type: 'characterizer' });
          setActionForFile('characterizer');
        } else {
          dispatch({ type: 'capture' });
          setActionForFile('capture');
        }

        if (checOjb) {
          const { i18n = {} } = response[0]?.row_capture || {};
          const keyss = Object?.keys(i18n);
          let OriginalLang = '';
          let transCode = '';
          if (langCheck) {
            const orignalLangCode = languagesCode.filter((data: any) => {
              return data.lang === langCheck;
            });
            if (orignalLangCode[0]?.code === 'en') {
              OriginalLang = 'en';
              transCode = 'en';
              setOriginalText(orignalLangCode[0]?.code);
              setTranslationCode('-');
            } else {
              OriginalLang = orignalLangCode[0]?.code;
              setOriginalText(orignalLangCode[0]?.code);
              setTranslationCode('-');
              transCode = '-';
            }
          } else {
            const originalText = keyss.filter((data) => {
              return !['fr', 'de', 'it', 'es', 'en'].includes(data);
            });
            if (originalText[0] !== undefined) {
              // eslint-disable-next-line prefer-destructuring
              OriginalLang = originalText[0];
              setOriginalText(originalText[0]);
              setTranslationCode('-');
              transCode = '-';
            } else {
              OriginalLang = 'en';
              setOriginalText('en');
              setTranslationCode('en');
              transCode = 'en';
            }
          }
          const responseArr = response.map((row) => {
            const checkObj = row?.row_capture;
            const { i18n } = row?.row_capture || {};
            if ('i18n' in checkObj && Object?.keys(i18n)?.length > 1) {
              if (row.row_capture[targetColumn]) {
                return {
                  ...row.row_capture,
                  [targetColumn]: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
                  updated_at: row.updated_at,
                  id: row.id,
                };
              }
              return {
                ...row.row_capture,
                Text: transCode === '-' ? i18n[OriginalLang] : i18n[transCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              updated_at: row.updated_at,
              id: row.id,
            };
          });
          setRowsEdited(responseArr);
          onChange(responseArr);
          clearInterval(intervalCallApI);
          setShowTable(true);
        } else {
          setLoadCAll(true);
        }
      })
      .catch((e) => {
        setSnack(`${e} `, 'warning');
        console.log(e);
      });
  }

  useEffect(() => {
    sessionStorage.setItem(targetPage, currentPage);
  }, [currentPage]);
  const handlePageNum = () => {
    if (Number.isNaN(tempPage)) {
      return;
    }
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  // console.log('languagesCode', languagesCode);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  useEffect(() => {
    const dynamicColumn = JSON.parse(localStorage.getItem('storedColumnChar')) || columnSelected;
    const difference = col.filter((x) => !dynamicColumn?.includes(x));
    const temp = [] as any;
    rowsEdited?.map((data) => {
      const t = {};
      const { i18n } = data;
      difference?.map((key) => {
        if (key === 'Text' && 'i18n' in data && Object?.keys(i18n)?.length > 1) {
          if (originalText !== undefined && translationCode === '-' && data?.i18n !== undefined) {
            const oL = data?.i18n[originalText];
            t[key] = oL;
          } else if (
            data?.i18n !== undefined &&
            data?.i18 !== null &&
            data?.i18n[translationCode || 'en']
          ) {
            t[key] = data?.i18n[translationCode];
          }
        } else {
          t[key] = data[key];
        }
        return key;
      });

      temp.push(t);
      return data;
    });
    const disCol = [] as any;
    columns.map((data) => {
      if (difference?.includes(data.name)) {
        disCol.push({
          name: data.name,
          title: data.name,
        });
      }
      return data;
    });
    setDisplayColumnDown(disCol);
    setRowsExport(temp);
  }, [columnSelected, rowsEdited]);

  useEffect(() => {
    if (rowsEdited?.length > 0) {
      const { i18n = {} } = rowsEdited[0];
      if (Object?.keys(i18n)?.length === 1) {
        return;
      }

      if (originalText !== undefined && translationCode === '-') {
        setRowsEdited((prev) => {
          return prev.map((data) => {
            if (data[targetColumn]) {
              return {
                ...data,
                [targetColumn]: data?.i18n[originalText],
              };
            }
            if (data?.i18n !== undefined) {
              const oL = data?.i18n[originalText];
              return { ...data, Text: oL };
            }
            return data;
          });
        });
      } else {
        setRowsEdited((prev) => {
          return prev.map((r) => {
            if (r?.i18n !== undefined && r?.i18 !== null && r?.i18n[translationCode || 'en']) {
              if (r?.Text) {
                return {
                  ...r,
                  Text: r?.i18n[translationCode],
                };
              }
              if (r[targetColumn]) {
                return {
                  ...r,
                  [targetColumn]: r?.i18n[translationCode],
                };
              }
            }
            return r;
          });
        });
      }
    }
  }, [translationCode]);

  const { exportAllData, loading: downloadLoad } = useDownloadFile({
    row: rowsEdited,
    columns: displayColumnDown,
    filename: `${projectId}_${filename}_characterized`,
    service: 'characterizer',
    targetColumn: textColum,
    translationCode,
    displayColumns,
    setNotify: setSnack,
    languagesCode,
    langCheck,
  });

  const openSimilarizer = () => {
    const selectedRows = rows.filter((row, index) => selection.includes(index));
    // validation text to similarize
    const templistText = selectedRows.map((row) => {
      return row.Text ?? row[targetColumn];
    });
    if (templistText?.length === 0) {
      setSnack('Select at least one row to run iSimialarizer', 'warning');
      setRowsSim([]);
      setOpenSim(false);
      return;
    }
    setRowsSim(templistText);
    setOpenSim(true);
  };
  const handleRefesh = useCallback(
    (tmp, reset = true) => {
      setRowsEdited(tmp ?? rowsEdited);
      onChange(tmp ?? rowsEdited);
      setRowsDeleted([]);
      setSelection([]);
      setIdsChanged([]);
      if (!reset) return;
      if (onReset || reset) onReset(tmp ?? rowsEdited);
      dispatch({ type: 'init' });
      dispatch({ type: 'characterizer' });
    },
    [rowsEdited]
  );
  const handleModalClose = () => {
    setOpenModalSplit(false);
    setOpenModalAddCaptureRow(false);
    setSelection([]);
  };
  const handleSplit = () => {
    if (selection.length === 0) {
      setSnack('Please select the row', 'warning');
      return;
    }
    setOpenModalSplit(true);
  };

  const handleInsertCaptureRowSubmit = (formBody: any, file: any) => {
    const columnName = targetColumn ?? 'Text';
    const row_types = 'characterizer';
    const id = Number(rowsEdited.find((row, index) => selection.includes(index)).id);
    /* eslint no-continue: "error" */

    const formData = new FormData();
    if (file.file !== undefined && formBody.subtype === 'Image') {
      formData.append('file', file.file);
      const value = {
        Context: formBody?.context,
        comment: formBody?.comment,
        Hierarchy: formBody?.hierarchy,
        SubType: formBody.subtype,
        language_code: translationCode === '-' ? originalText : translationCode,
        capture: 'Image',
        Type: 'Text',
        i18n: { en: 'Image' },
        Original_Hierarchy: undefined,
        column_name: columnName,
      };
      insertCharacterizer(id, value, row_types, formData).then((response: any) => {
        const tmp = response.map((row) => {
          const { i18n } = row?.row_capture || {};
          if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
            if (row.row_capture[targetColumn]) {
              return {
                ...row.row_capture,
                [targetColumn]:
                  translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
              updated_at: row.updated_at,
              id: row.id,
            };
          }
          return {
            ...row.row_capture,
            updated_at: row.updated_at,
            id: row.id,
          };
        });
        setOpenModalAddCaptureRow(false);
        setTimeout(() => {
          handleRefesh(tmp, false);
          if (dispatch) {
            setSnack('Image uploaded successfully', 'success');
            dispatch({ type: 'init' });
            dispatch({ type: 'characterizer' });
          }
        }, 500);
      });
    } else {
      const tempObj1: any = {};
      if (languages?.length) {
        tempObj1[originalText] = formBody.capture;
        for (const i of languages) {
          tempObj1[i] = formBody.capture;
        }
      }
      const tempObj = {} as any;
      if (Object.keys(tempObj1).length === 0) {
        tempObj[originalText] = formBody.capture;
        tempObj.en = formBody.capture;
      }
      const body = {
        Context: formBody?.context,
        comment: formBody?.comment,
        Hierarchy: formBody?.hierarchy,
        SubType: formBody.subtype,
        capture: formBody.capture,
        language_code: translationCode === '-' ? originalText : translationCode,
        Type: 'Text',
        i18n: Object.keys(tempObj1).length > 0 ? tempObj1 : tempObj,
        Original_Hierarchy: undefined,
        column_name: columnName,
      } as PayloadInsertNewCapture;
      insertNewCapture(id, body, row_types).then((response: any) => {
        const tmp = response.map((row) => {
          const { i18n } = row?.row_capture || {};
          if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
            if (row.row_capture[targetColumn]) {
              return {
                ...row.row_capture,
                [targetColumn]:
                  translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
              updated_at: row.updated_at,
              id: row.id,
            };
          }
          return {
            ...row.row_capture,
            updated_at: row.updated_at,
            id: row.id,
          };
        });
        setOpenModalAddCaptureRow(false);
        setTimeout(() => {
          handleRefesh(tmp, false);
          if (dispatch) {
            setSnack('Row added successfully', 'success');
            dispatch({ type: 'init' });
            dispatch({ type: 'characterizer' });
          }
        }, 500);
      });
    }
  };

  // console.log('originalText', originalText);
  // console.log('translationCode', translationCode);

  const handleTextAlter = (concatStr: any) => {
    // sort selection
    // check sequential
    // send ids to API
    selection.sort((a, b) => (a < b ? -1 : 1));
    try {
      if (selection.length < 2) {
        if (concatStr === '\n') {
          setSnack('Please select at least two rows for merge', 'warning');
        } else {
          setSnack('Please select at least two rows for concat', 'warning');
        }
        return;
      }

      selection.forEach((element, index) => {
        if (index === 0) return;
        if (Number(element) !== selection[index - 1] + 1) {
          throw new Error('selection must be squential');
        }
      });

      const rowsSelection = rowsEdited
        .filter((row, index) => selection.includes(index))
        .map((r) => r.id);
      const body = {
        project_id: Number(projectId),
        workload_id: workloadId,
        ids: rowsSelection,
        column_name: targetColumn ?? 'Text',
        language_code: translationCode === '-' ? originalText : translationCode,
        concat_str: concatStr,
      };
      const row_types = 'characterizer';
      mergeCapture(body, row_types)
        .then((response: any) => {
          if (dispatch) {
            dispatch({ type: 'init' });
            dispatch({ type: 'characterizer' });
          }
          const tmp = response.map((row) => {
            const { i18n } = row?.row_capture || {};
            if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
              if (row.row_capture[targetColumn]) {
                return {
                  ...row.row_capture,
                  [targetColumn]:
                    translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                  updated_at: row.updated_at,
                  id: row.id,
                };
              }
              return {
                ...row.row_capture,
                Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              updated_at: row.updated_at,
              id: row.id,
            };
          });
          setTimeout(() => {
            handleRefesh(tmp, false);
          }, 500);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          if (concatStr === '\n') {
            setSnack('Merged successfully!', 'success');
          } else {
            setSnack('Concatenated successfully!', 'success');
          }
        });
    } catch (error: any) {
      setSnack(error.message, 'warning');
    }
  };

  const handleConcat = () => {
    const emptyStr = ' ';
    handleTextAlter(emptyStr);
  };

  const handleMerge = () => {
    handleTextAlter('\n');
  };

  const handleRepeatMerge = (concatStr = '\n') => {
    // sort selection
    // check sequential
    // send ids to API
    selection.sort((a, b) => (a < b ? -1 : 1));
    try {
      if (selection.length < 2) {
        setSnack('Please select at least two rows for repeat header', 'warning');
        return;
      }
      selection.forEach((element, index) => {
        if (index === 0) return;
        if (Number(element) !== selection[index - 1] + 1) {
          throw new Error('selection must be squential');
        }
      });

      const rowsSelection = rowsEdited
        .filter((row, index) => selection.includes(index))
        .map((r) => r.id);
      const body = {
        project_id: Number(projectId),
        workload_id: workloadId,
        ids: rowsSelection,
        column_name: targetColumn ?? 'Text',
        concat_str: concatStr,
        language_code: translationCode === '-' ? originalText : translationCode,
      };
      const row_types = 'characterizer';
      mergeRepeatCapture(body, row_types)
        .then((response: any) => {
          if (dispatch) {
            dispatch({ type: 'init' });
            dispatch({ type: 'characterizer' });
          }
          const tmp = response.map((row) => {
            const { i18n } = row?.row_capture || {};
            if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
              if (row.row_capture[targetColumn]) {
                return {
                  ...row.row_capture,
                  [targetColumn]:
                    translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                  updated_at: row.updated_at,
                  id: row.id,
                };
              }
              return {
                ...row.row_capture,
                Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              updated_at: row.updated_at,
              id: row.id,
            };
          });
          setTimeout(() => {
            handleRefesh(tmp, false);
          }, 500);
        })
        .finally(() => {
          setSnack('Merged successfully!', 'success');
        });
    } catch (error: any) {
      setSnack(error.message, 'warning');
    }
  };

  const handleOwning = () => {
    const selectedRows = rowsEdited.filter((row, index) => selection.includes(index));
    if (selectedRows.length === 1) {
      setOpenModalAddCaptureRow(true);
    } else {
      setSnack('Please select row', 'warning');
      setOpenModalAddCaptureRow(false);
    }
  };

  const handleSplitSubmit = (formBody) => {
    const body = {
      project_id: Number(projectId),
      workload_id: workloadId,
      id: rowsEdited.find((row, index) => selection.includes(index)).id,
      column_name: targetColumn ?? 'Text',
      language_code: translationCode === '-' ? originalText : translationCode,
      ...formBody,
    } as PayloadSplit;
    const row_types = 'characterizer';
    splitCapture(body, row_types)
      .then((response: any) => {
        const tmp = response.map((row) => {
          const { i18n } = row?.row_capture || {};
          if ('i18n' in row.row_capture && Object?.keys(i18n)?.length > 1) {
            if (row.row_capture[targetColumn]) {
              return {
                ...row.row_capture,
                [targetColumn]:
                  translationCode === '-' ? i18n[originalText] : i18n[translationCode],
                updated_at: row.updated_at,
                id: row.id,
              };
            }
            return {
              ...row.row_capture,
              Text: translationCode === '-' ? i18n[originalText] : i18n[translationCode],
              updated_at: row.updated_at,
              id: row.id,
            };
          }
          return {
            ...row.row_capture,
            updated_at: row.updated_at,
            id: row.id,
          };
        });
        setOpenModalSplit(false);
        setTimeout(() => {
          handleRefesh(tmp, false);
          if (dispatch) {
            dispatch({ type: 'init' });
            dispatch({ type: 'characterizer' });
          }
        }, 500);
      })
      .finally(() => {
        setSnack('Splited successfully', 'success');
      });
  };
  const autoSave = (myRows: any[], idsChanged: any) => {
    const rowsEditedFiltered = myRows.filter((row: any) => {
      return idsChanged.includes(row.id);
    });
    const checkLang = translationCode === '-' ? originalText : translationCode;
    if (rowsEditedFiltered.length > 0) {
      saveOwning(
        rowsEditedFiltered,
        'capture',
        projectId,
        Number(workloadId),
        checkLang,
        targetColumn || 'Text',
        filename
      )
        .then((responseSave) => {
          setSnack('characterization saved', 'success');
          setIdsChanged([]);
          setRowsEdited((prev) => {
            return prev.map((data) => {
              const rowWithTranslation = responseSave.find(
                (r2) => Number(r2.id) === Number(data.id)
              );
              if (rowWithTranslation?.id === data.id) {
                if (
                  'i18n' in rowWithTranslation &&
                  Object?.keys(rowWithTranslation?.i18n)?.length > 1
                ) {
                  const { i18n } = rowWithTranslation;
                  if (rowWithTranslation[targetColumn]) {
                    rowWithTranslation[targetColumn] =
                      translationCode === '-' ? i18n[originalText] : i18n[translationCode];
                  } else {
                    rowWithTranslation.Text =
                      translationCode === '-' ? i18n[originalText] : i18n[translationCode];
                  }
                }
                return rowWithTranslation;
              }
              return data;
            });
          });
          onChange(myRows);

          if (dispatch) {
            dispatch({ type: 'init' });
            dispatch({ type: 'characterizer' });
          }
        })
        .catch((reason) => {
          setSnack(reason, 'error');
        })
        .finally(() => {});
    } else if (rowsDeleted.length > 0) {
      deleteOwning(rowsDeleted, projectId, Number(workloadId), filename)
        .then(() => {
          const deleteIds = rowsDeleted.map((row) => row.id);
          onChange(myRows.filter((row) => !deleteIds.includes(row.id)));
          setSnack('delete ok', 'success');
          if (dispatch) {
            dispatch({ type: 'init' });
            dispatch({ type: 'characterizer' });
          }
        })
        .catch((reason) => {
          console.error(reason);
        });
    }
  };
  const handleEditDrop = () => {
    if (selection.length === 0) {
      setSnack('Please select the row', 'warning');
      return;
    }
    setEditingRowIds(selection);
  };
  const handleEdit = ({ changed }) => {
    let changedRows;
    if (changed) {
      if (translationCode === '-') {
        changedRows = rowsEdited.map((row, index) =>
          changed[index]
            ? {
                ...row,
                ...changed[index],
                i18n: {
                  ...row.i18n,
                  [originalText]: targetColumn ? changed[index][targetColumn] : changed[index].Text,
                },
                updated_at: dayjs.utc().format(),
              }
            : row
        );
      } else {
        changedRows = rowsEdited.map((row, index) =>
          changed[index]
            ? {
                ...row,
                ...changed[index],
                i18n: {
                  ...row.i18n,
                  [translationCode]: targetColumn
                    ? changed[index][targetColumn]
                    : changed[index].Text,
                },
                updated_at: dayjs.utc().format(),
              }
            : row
        );
      }

      const idsChangedNew = rowsEdited.filter((row, index) => changed[index]).map((row) => row.id);
      setIdsChanged([...new Set([...idsChanged, ...idsChangedNew])]);
      setRowsEdited(changedRows);
      if (dispatch) {
        dispatch({ type: 'init' });
        dispatch({ type: 'characterizer' });
      }
      autoSave(changedRows, [...new Set([...idsChanged, ...idsChangedNew])]);
    }
  };

  const handleSave = () => {
    autoSave(rowsEdited, idsChanged);
  };

  const handleCharacterization = (e, row: any, column: Column) => {
    setIdsChanged([...new Set([...idsChanged, row.id])]);
    const rowsEditedNew = rowsEdited.map((r) => {
      if (r.id === row.id) {
        return {
          ...r,
          [column.name]: e.target.value,
          updated_at: dayjs.utc().format(),
        };
      }
      return r;
    });
    setRowsEdited(rowsEditedNew);
  };

  const renderCharacterization = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    if (['D&IS', 'Services'].includes(project?.entity)) {
      return value === 'Requirement' || value === 'Information' ? (
        <Select defaultValue={value} onChange={(e) => handleCharacterization(e, row, column)}>
          <MenuItem value="Requirement">Requirement</MenuItem>
          <MenuItem value="Information">Information</MenuItem>
        </Select>
      ) : (
        <Select defaultValue={value} onChange={(e) => handleCharacterization(e, row, column)}>
          <MenuItem value={value}>{value}</MenuItem>
        </Select>
      );
    }
    return value === 'Requirement' || value === 'Information' ? (
      <Select defaultValue={value} onChange={(e) => handleCharacterization(e, row, column)}>
        <MenuItem value="Requirement">Requirement</MenuItem>
        <MenuItem value="Information">Information</MenuItem>
      </Select>
    ) : (
      <Select defaultValue={value} onChange={(e) => handleCharacterization(e, row, column)}>
        <MenuItem value={value}>{value}</MenuItem>
      </Select>
    );
  };

  // KPI
  const showDashboardHandler = () => {
    setOpenDashboard(true);
  };

  const modalImage = (row, rawType = 'images' as rawTypeEnum) => {
    if (rawType === 'images') {
      const imageIndex = imagesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...imagesList.slice(imageIndex),
        ...imagesList.slice(0, imageIndex).reverse(),
      ];
      setImagesList(imagesListSorted);
    }

    if (rawType === 'tables') {
      const itemIndex = tablesList.findIndex((r) => row?.id === r.id);
      const imagesListSorted = [
        ...tablesList.slice(itemIndex),
        ...tablesList.slice(0, itemIndex).reverse(),
      ];
      setTablesList(imagesListSorted);
    }

    setRawType(rawType);
    setToggleRawdata(true);
  };

  const handleDeleteRows = () => {
    if (selection.length === 0) {
      setSnack('Please select the row', 'warning');
    } else {
      setModalOpen(true);
    }
  };
  // const check = window.confirm('Do you want to delete selected row?');

  const handleDeleteConfirm = () => {
    const rowsNotDeleted = rowsEdited.filter((row, index) => !selection.includes(index));
    setRowsEdited(rowsNotDeleted);
    onChange(rowsNotDeleted);
    const rowsDeleted = rowsEdited.filter((row, index) => selection.includes(index));
    deleteOwning(rowsDeleted, projectId, Number(workloadId), filename)
      .then(() => {
        const deleteIds = rowsDeleted.map((row) => row.id);
        onChange(rowsEdited.filter((row) => !deleteIds.includes(row.id)));
        setSnack('Selected record(s) has been successfully deleted!', 'success');
        if (dispatch) {
          dispatch({ type: 'init' });
          dispatch({ type: 'characterizer' });
        }
      })
      .catch((reason) => {
        console.error(reason);
      })
      .finally(() => {
        setSelection([]);
      });
    setModalOpen(false);
  };

  const renderSubType = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.Characterization === 'Image') {
      return (
        <Button title="Image" onClick={() => modalImage(row, 'images')}>
          <ImageIcon />
          {value}
        </Button>
      );
    }
    if (row.Characterization === 'Table') {
      return (
        <Button title="Table" onClick={() => modalImage(row, 'tables')}>
          <TableChartIcon />
          {value}
        </Button>
      );
    }

    return <>{value}</>;
  };

  // table container
  const tableChildContainer: React.FunctionComponent<Table.CellProps> = (props) => {
    return (
      <Table.Container
        {...props}
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          border: '1px solid black',
        }}
      />
    );
  };

  const renderScore = ({ value }: DataTypeProvider.ValueFormatterProps) => (
    <Chip
      label={String(value).toLowerCase()}
      title={String(value).toLowerCase()}
      style={{
        backgroundColor: CHARACTERIZE_COLORS[String(value).toLowerCase()],
        color: 'white',
        width: '100px',
      }}
    />
  );

  const cond = ['SubType', 'Text', 'Context', 'comment'];
  if (targetColumn && targetColumn.length > 0) {
    cond.push(targetColumn);
  }
  const columnsEdit = columns.map((col: Column) => {
    if (cond.includes(col.name)) {
      return { columnName: col.name, editingEnabled: true };
    }
    return { columnName: col.name, editingEnabled: col.name === 'comment' };
  });
  const handleTest = (e: any, row: any, column: Column) => {
    console.log(e?.value);
  };
  // table Row Menu
  const SelectionCellComponent = ({ row, selected, onToggle, value, column }) => (
    <TableCell>
      {/* <CheckBoxOutlineBlankIcon onClick={abcd(row)} /> */}
      <div className={classes1.IconStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomWidth2Tooltip
            // open={true}
            placement="top-start"
            // disableTouchListener
            arrow
            title={
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    pointerEvents: !['characterizer'].includes(actionForFile) ? 'none' : 'initial',
                  }}
                >
                  <a
                    href="#text-buttons"
                    onClick={handleConcat}
                    style={stylingObject.ahref}
                    title="Concat, add a space between text"
                    label="1"
                  >
                    <Avatar className={classes1.avatar}>
                      <PlaylistAddIcon fontSize="small" />
                    </Avatar>
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleMerge}
                    style={stylingObject.ahref}
                    title="Merge, add a new line between text"
                    label="2"
                  >
                    <Avatar className={classes1.avatar}>
                      <MergeIcon fontSize="small" />
                    </Avatar>
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={(e) => handleRepeatMerge('\n')}
                    style={stylingObject.ahref}
                    title="Repeat header"
                  >
                    <Avatar className={classes1.avatar}>
                      <HorizontalSplitIcon fontSize="small" />
                    </Avatar>

                    {/* Repeat Header */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleSplit}
                    style={stylingObject.ahref}
                    title="Split the row"
                  >
                    <Avatar className={classes1.avatar}>
                      <CallSplit fontSize="small" />
                    </Avatar>

                    {/* Split */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleDeleteRows}
                    style={stylingObject.ahref}
                    title="Delete the row"
                  >
                    <Avatar className={classes1.avatar}>
                      <DeleteIcon fontSize="small" />
                    </Avatar>

                    {/* Delete */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleOwning}
                    style={stylingObject.ahref}
                    title="Insert new capture row"
                  >
                    <Avatar className={classes1.avatar}>
                      <ControlPointIcon fontSize="small" />
                    </Avatar>

                    {/* New Capture */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={openSimilarizer}
                    style={stylingObject.ahref}
                    title="Find Similar Items"
                  >
                    <Avatar className={classes1.avatar}>
                      <FileCopyOutlinedIcon fontSize="small" />
                    </Avatar>

                    {/* iSimilarizer */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleEditDrop}
                    style={stylingObject.ahref}
                    title="Edit"
                  >
                    <Avatar className={classes1.avatar}>
                      <EditIcon fontSize="small" />
                    </Avatar>

                    {/* Edit */}
                  </a>
                  <a
                    href="#text-buttons"
                    onClick={handleSave}
                    style={stylingObject.ahref}
                    title="Save"
                  >
                    <Avatar className={classes1.avatar}>
                      <SaveIcon fontSize="small" />
                    </Avatar>

                    {/* Edit */}
                  </a>
                </div>
              </>
            }
          >
            <DragIndicatorIcon style={{ color: '#002A45', fontWeight: 'bold' }} fontSize="small" />
          </CustomWidth2Tooltip>
          <Checkbox checked={selected} onChange={onToggle} className={classes1.tableCheck} />
        </div>
      </div>
    </TableCell>
  );
  // Table Edit css for Save and Cancel
  const cellComponentEdit = ({ ...restProps }: any) => {
    return (
      <TableEditColumn.Cell
        {...restProps}
        style={{ paddingRight: 50 }}
        className={classes1.PaginationButton}
      />
    );
  };
  if (!project) return null;
  // if (loading) return <Loading />;
  return (
    <>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{ imagesList, tablesList, rawType, rowsEdited }}
          collapsed={true}
          theme="monokai"
        />
      )}
      {showTable ? (
        <>
          {' '}
          <div style={{ padding: '0 10px' }}>
            <ExpressGrid rows={rowsEdited} columns={columns}>
              <DataTypeProvider
                for={['Text']}
                formatterComponent={(dataTable) =>
                  RenderCellText({
                    filename,
                    dataTable,
                    translationCode,
                    originalText,
                    toggleHeight,
                    onSearchText,
                  })
                }
              />
              {displayColumns && (
                <DataTypeProvider
                  for={displayColumns}
                  formatterComponent={(dataTable) =>
                    RenderCellExcel({
                      dataTable,
                      toggleHeight,
                    })
                  }
                />
              )}
              {targetColumn && (
                <DataTypeProvider
                  for={[targetColumn]}
                  formatterComponent={(dataTable) =>
                    RenderCellExcel({
                      dataTable,
                      translationCode,
                      toggleHeight,
                    })
                  }
                />
              )}
              <DataTypeProvider
                for={['Characterization']}
                formatterComponent={renderCharacterization}
              />
              <DataTypeProvider for={['Char_Score']} formatterComponent={renderScore} />

              <DataTypeProvider for={['SubType']} formatterComponent={renderSubType} />

              {/* filtering */}
              <FilteringState />
              <PagingState
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                defaultCurrentPage={0}
                defaultPageSize={pageSizes[pageSizes.length - 1]}
                currentPage={currentPage}
                onCurrentPageChange={(p) => {
                  setCurrentPage(p);
                }}
              />
              <SortingState />
              <IntegratedSorting />
              <IntegratedFiltering />
              <IntegratedPaging />
              {/* selection */}
              <SelectionState selection={selection} onSelectionChange={setSelection} />
              <IntegratedSelection />

              <Table
                columnExtensions={columns.map((c) => ({
                  columnName: c.name,
                  wordWrapEnabled: toggleHeight,
                }))}
                tableComponent={MultiAllocTableComp}
                containerComponent={tableAllocatorComponent}
                headComponent={tableHeaderComponent}
              />
              <TableColumnResizing
                defaultColumnWidths={[
                  ...columns.map((c) => {
                    // if (c.name === 'Menu') return { columnName: 'Menu', width: 80 };
                    if (c.name === 'Text') return { columnName: 'Text', width: textWidth };
                    if (c.name === targetColumn)
                      return { columnName: targetColumn, width: textWidth };
                    if (c.name === 'Context') return { columnName: 'Context', width: 300 };
                    if (c.name === 'id') return { columnName: 'id', width: 120 };
                    if (c.name === 'PageNo') return { columnName: 'PageNo', width: 100 };
                    if (c.name === 'Hierarchy') return { columnName: 'Hierarchy', width: 120 };
                    if (c.name === 'Original_Hierarchy') return { columnName: c.name, width: 200 };
                    if (c.name.includes('Heading_Level')) return { columnName: c.name, width: 200 };
                    if (c.name === 'Char_Score') return { columnName: 'Char_Score', width: 140 };
                    if (c.name === 'SubType') return { columnName: 'SubType', width: 120 };
                    return {
                      columnName: c.name,
                      width: Math.ceil(3000 / columns.length),
                    };
                  }),
                ]}
              />
              <TableHeaderRow showSortingControls />
              {/* filtering */}
              <TableFilterRow />
              <TableColumnVisibility
                defaultHiddenColumnNames={
                  JSON.parse(localStorage.getItem('storedColumnChar')) || columnSelected
                }
                onHiddenColumnNamesChange={(arr) => {
                  // console.log(arr);
                  localStorage.setItem('storedColumnChar', JSON.stringify(arr));
                  setColumnSelected(arr);
                }}
              />
              <Toolbar rootComponent={toolbarComponent} />
              {/* selection */}
              <TableSelection showSelectAll cellComponent={SelectionCellComponent} />
              <ColumnChooser />
              {languages && languages.length > 0 && (
                <ToolBarPlugin
                  languages={languages}
                  languageCode={translationCode}
                  languageOriginCode={languageOriginCode}
                  name="Translation"
                  title="Translation"
                  onTranslate={(lang) => {
                    if (lang) setTranslationCode(lang);
                  }}
                />
              )}
              <ToolBarPlugin name="Refresh" onClick={handleRefesh} />
              <ToolBarPlugin name="Dashboard" onClick={showDashboardHandler} title="KPIs" />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <ToolBarPlugin
                name="Download"
                hide={downloadLoad}
                loading={downloadLoad}
                onClick={() => {
                  exportAllData();
                }}
              />
              {/* edition */}
              <EditingState
                onCommitChanges={handleEdit}
                columnExtensions={columnsEdit}
                // these two lines added to Edit button shift to dropdown edit
                editingRowIds={editingRowIds}
                onEditingRowIdsChange={setEditingRowIds}
              />
              <PagingPanel
                pageSizes={pageSizes}
                containerComponent={(props) => (
                  <>
                    <PagingPanel.Container {...props} className={classes1.pagingPanelContainer} />
                  </>
                )}
              />
              <PopupEditing popupComponent={Popup} targetColumn={targetColumn} />
              <TableEditColumn width={5} cellComponent={cellComponentEdit} />
            </ExpressGrid>
          </div>
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={rowsEdited.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      ) : (
        <div
          style={{
            width: '100%',
            minHeight: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            flexDirection: 'column',
          }}
        >
          <Typography style={{ fontSize: '1.5rem', color: '#002A45' }}>
            {loadCAll
              ? 'Wait it will take time characterizer is in progress'
              : 'Characterizer is loading please wait'}
          </Typography>
          {loadCAll ? <Loading /> : <CircularPogressBar percentage={percentage} />}
          {/* <CircularProgressWithLabel value={percentage} /> */}
        </div>
      )}
      {openSim && rowsSim.length && <Similarizer inputSimList={rowsSim} />}
      {openModalSplit && selection.length === 1 && (
        <ModalSplit
          requirement={rowsEdited.find((row, index) => selection.includes(index))}
          onClose={handleModalClose}
          onSubmit={handleSplitSubmit}
          targetColumn={targetColumn}
        />
      )}
      {openModalAddCaptureRow && selection.length === 1 && (
        <ModalAddCharacterizerRow
          onClose={handleModalClose}
          onSubmit={handleInsertCaptureRowSubmit}
          targetColumn={targetColumn}
          rows={rowsEdited}
          setFiles={setFiles}
          selection={selection}
        />
      )}
      <GridExporter
        ref={exporterRef}
        rows={rowsExport}
        columns={displayColumnDown}
        onSave={onDownload}
      />
      {toggleRawdata && (
        <ModalRawdata
          imagesList={imagesList}
          tablesList={tablesList}
          rawType={rawType}
          onClose={() => {
            setToggleRawdata(false);
          }}
        />
      )}
      {openDashboard && (
        <ChararcterizerModalKpi
          project_id={projectId}
          workload_id={workloadId}
          file_name={filename}
          // user_id={userJWT.user_id}
          title={filename}
          onClose={() => {
            setOpenDashboard(false);
          }}
        />
      )}
      {modalOpen && (
        <ModalConfirm
          open={modalOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={() => {
            setModalOpen(false);
          }}
          label="isDeleteRow"
        />
      )}
    </>
  );
}

export default CharacterizerFlow;
