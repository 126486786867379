/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getResultsByWorkload, userWorkloadGetById } from '../../../../../../api/workloads';
import TStyles, { tUseStyles } from '../../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../../components/Datagrid/ToolBarPlugin';
import Loading from '../../../../../../components/Core/Loading';
import WorkSpaceContext from '../../../../WorkspceContext';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

type Props = {
  id?: number;
  id_service?: string;
};

function FindDuplicates({ id: workloadId, id_service }: Props) {
  const { setSnack } = useContext(WorkSpaceContext);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const exporterRef = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  // to control the loader and display data full Grid
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('');

  const [resourcesAre, setResourcesAre] = useState<any>([]);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [pageSizes] = useState([5, 10, 20, 50]);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [pageSize1, setPageSize1] = useState(5);
  const [columnWidths, setColumnWidths] = useState([] as any);

  const handleSetRowsColumns = async (myRows: {}[]) => {
    if (!Array.isArray(myRows) || myRows.length === 0) {
      console.error('Invalid myRows:', myRows);
      return;
    }
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    const resizeColumns = myCols.map((c) => {
      return {
        columnName: c.name,
        width: Math.ceil(10000 / myCols.length),
      };
    });
    setColumnWidths(resizeColumns);
    setColumnsAre(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };

  useEffect(() => {
    if (workloadId === undefined) {
      return;
    }
    setLoadingResult(true);
    getResultsByWorkload(workloadId, id_service)
      .then((payload) => {
        setLoadingResult(false);
        setResourcesAre(payload?.resources);
        handleSetRowsColumns(JSON.parse(payload?.results_data[0]?.data?.output));
        setDisplay(true);
      })
      .catch(() => {
        setDisplay(false);
        setSnack('Workload erroe get by id', 'error');
        setMessageToDisplay('Workload error get by id');
      });
  }, [workloadId]);
  const filedata = resourcesAre[0]?.output?.TargetColumn
    ? resourcesAre[0]?.scopeSettingOP
    : resourcesAre?.map((res) => res?.settingsModule);
  return (
    <>
      {loadingResult ? <Loading /> : ''}
      {display && (
        <>
          <Paper
            style={{
              borderTop: 'solid rgba(0, 0, 0, 0.87)',
              borderWidth: '0.6px',
              backgroundColor: 'rgb(238, 241, 249)',
            }}
          >
            <div style={{ marginLeft: '5px' }}>
              <h3>Resource details:</h3>
              <Grid container spacing={0}>
                {(filedata ?? []).map((data: any) => {
                  return (
                    <>
                      {data?.FileName && (
                        <>
                          <Grid item xs={3}>
                            File Name: {data?.FileName}
                          </Grid>
                          <Grid item xs={3}>
                            File Type: {data?.FileType}
                          </Grid>
                          {data?.FileType === 'Excel' && (
                            <Grid item xs={3}>
                              Sheet Name: {data?.SheetName != null ? data?.SheetName : 'NA'}
                            </Grid>
                          )}
                          {data?.FileType === 'Excel' && (
                            <Grid item xs={3}>
                              Target Column: {data?.TargetColumn}
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </Grid>
            </div>
          </Paper>
          <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
            <ExpressGrid rows={rows} columns={columnsAre}>
              <DataTypeProvider
                for={columnNamesAre}
                formatterComponent={({ value }) => (
                  <span title={value} style={{ whiteSpace: `${newLine}` }}>
                    {value}
                  </span>
                )}
              />
              <SortingState />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage1}
                onCurrentPageChange={setCurrentPage1}
                pageSize={pageSize1}
                onPageSizeChange={setPageSize1}
              />
              <IntegratedPaging />
              <Table
                tableComponent={TableComponent}
                columnExtensions={[
                  {
                    columnName: 'Text1',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text2',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text2.1',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text3',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  {
                    columnName: 'Text4',
                    width: '300px',
                    wordWrapEnabled: toggleHeight,
                  },
                  // Similarity Match
                  {
                    columnName: 'Similarity Match',
                    width: '200px',
                  },
                ]}
              />
              <TableColumnResizing
                onColumnWidthsChange={setColumnWidths}
                columnWidths={columnWidths}
                resizingMode="nextColumn"
              />
              <TableHeaderRow showSortingControls />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={setHiddenColumnNames}
              />
              <Toolbar />
              <ColumnChooser />
              <ToolBarPlugin name="Download" onClick={startExport} />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <ToolBarPlugin
                name="NewLine"
                onClick={() => (newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine(''))}
              />
              <PagingPanel pageSizes={pageSizes} />
            </ExpressGrid>
            <GridExporter ref={exporterRef} rows={rows} columns={columnsAre} onSave={onSave} />
          </Box>
        </>
      )}
      {!display && (
        <Box style={{ margin: 'auto auto' }}>
          <Typography
            variant="subtitle1"
            style={{ color: '#d9534f', fontWeight: 'bold', textAlign: 'center' }}
          >
            {messageToDisplay}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default FindDuplicates;
