/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
  TableGroupRow,
  SearchPanel,
  GroupingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import Highlighter from 'react-highlight-words';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Bar,
} from 'recharts';
import { Typography, Grid, Chip } from '@mui/material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import ExcelDoc from '../../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../../assets/pdf_ins.png';
import CsvDoc from '../../../../../../assets/csv_icons.png';
import PptDoc from '../../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../../assets/icons8-microsoft-word-2019-48.png';
// import Module1Context from '../../../Module2Context';

import ToolBarPlugin from '../../../../../../components/Datagrid/ToolBarPlugin';
import '../../../../../tabulationStyle.css';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../../../../components/Datagrid/TStyles';
import { getResultsByWorkload } from '../../../../../../api/workloads';
import { DOCTODATASET_COLORS } from '../../../../../../../src/const';
import { getCaptureName } from '../../../../../../api/breakdowns';
// import Loading from '../../../../../../components/Core/Loading';
import MultiAllocTableComp from '../../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../../components/Core/NavigationGotoNew';
import WorkSpaceContext from '../../../../WorkspceContext';
import useDownloadFile from '../../../../../Module1/components/RunStepper/components/Hooks/useDownloadFile';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

type Props = {
  id?: number;

  id_service?: string;
};

const COLORS = ['#FF875A', '#002A45', 'red', '#FFFF99', '#FFBB28', 'green'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize="16px"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function SimilarityAnalysisNew({ id: workloadId, id_service = 'none' }: Props) {
  const [selection, setSelection] = useState([]);
  const { loading: downloading, exportIsimilarity } = useDownloadFile({ service: 'iSimilarity' });
  // to control the loader and display data full Gidr
  // const { setSnack } = useContext(Module1Context);
  const { setSnack } = useContext(WorkSpaceContext);
  const [loading, setLoading] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const [display, setDisplay] = useState(false);
  const [sheetNames, setSheetNames] = useState<any[]>([]);
  const [columnName, setColumnName] = useState<any[]>([]);
  const [sheets, setSheets] = useState<any[]>([]);
  const [selectedSheetName, setSelectedSheetName] = useState<any>();
  const [selectedColumnName, setSelectedColumnName] = useState<any>();
  const [fileType, setFileType] = useState<any>();
  const [displayGrid, setDisplayGrid] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<any>();
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [rows, setRows] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [csvDataframe, setCsvDataframe] = useState<any>();
  const [columnWidths, setColumnWidths] = useState([] as any);
  const exporterRef = useRef(null);
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [currentCaptureName, setCurrentCaptureName] = useState<any>('');
  const startExport = () => {
    // exporterRef.current.exportGrid();

    exportIsimilarity({ workloadId, serviceFor: id_service });
  };
  // const onSave = (workbook: any) => {
  //   workbook.xlsx.writeBuffer().then((buffer: any) => {
  //     saveAs(
  //       new Blob([buffer], { type: 'application/octet-stream' }),
  //       `${currentCaptureName}.xlsx`
  //     );
  //   });
  // };
  const [scopeSettingExcel, setScopeSettingExcel] = useState<any>();
  const [scopeSettingCsv, setScopeSettingCsv] = useState<any>();
  const [excelbool, setExcelbool] = useState<boolean>(false);
  const [csvbool, setCsvbool] = useState<boolean>(false);
  const [graphOP, setGraphOP] = useState<any>();
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    // 'Project1',
    // 'Project2',
    // 'Project3',
    // 'Text1',
    // 'Text2',
    // 'Text3',
    // 'Score1',
    // 'Score2',
    // 'Score3',
    'RBS1',
    'RBS2',
    'RBS3',
    'Allocation1',
    'Allocation2',
    'Allocation3',
    'Compliance1',
    'Compliance2',
    'Compliance3',
    'Entity1',
    'Entity2',
    'Entity3',
    'DOCUMENT_TITLE',
    'Document Title1',
    'Document Title2',
    'Document Title3',
    'Document Title',
    'Document Profile',
    'Document Profile1',
    'Document Profile2',
    'Document Profile3',
    'Document Path',
    'Document Path1',
    'Document Path2',
    'Document Path3',
    'Document Version',
    'Document Version1',
    'Document Version2',
    'Document Version3',
    'Last Modified By',
    'Last Modified By1',
    'Last Modified By2',
    'Last Modified By3',
    'Last Modified On',
    'Last Modified On1',
    'Last Modified On2',
    'Last Modified On3',
    'URL',
    'URL1',
    'URL2',
    'URL3',
    // 'Combined Compliance Status',
    // 'Compliance status influenced by',
    'Cleaned_column',
  ]);
  const [hiddenColumnSelected, setHiddenColumnSelected] = useState([]);

  const [targetCol, setTargetCol] = useState<any>('');
  const [alstomEntitiesDefault, setAlstomEntitiesDefault] = useState<any>();
  const [filterPlatformsDefault, setFilterPlatformsDefault] = useState<any>();
  const [filterProjectsDefault, setFilterProjectsDefault] = useState<any>();
  const [filterDocumentProfilesDefault, setFilterDocumentProfilesDefault] = useState<any>();

  const [alstomEntities, setAlstomEntities] = useState<any>([]);
  const [filterProjects, setFilterProjects] = useState<any[]>([]);
  const [filterDocumentProfiles, setFilterDocumentProfiles] = useState<any[]>([]);
  const [filterPlatforms, setFilterPlatforms] = useState<any[]>([]);

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [payloadHere, setPayloadHere] = useState<any>();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedDocumentProfiles, setSelectedDocumentProfiles] = useState([]);

  const [multiFilterDisplay, setMultiFilterDisplay] = useState<any>(false);
  const [inputCaptureName, setInputCaptureName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [dis, setDis] = useState(false);
  const [tempPage, setTemPage] = useState(0);

  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);

  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSetRowsColumns = async (myRows: {}[]) => {
    // const myCols = Object.keys(myRows[0]).map((key) => ({
    //   name: key,
    //   title: key.toUpperCase(),
    // })) as Column[];
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    const resizeColumns = myCols.map((c) => {
      return {
        columnName: c.name,
        width: 300,
      };
    });
    setColumnWidths(resizeColumns);
    setColumnsAre(myCols);
    setRows(myRows);
  };

  useEffect(() => {
    if (workloadId === undefined) {
      return;
    }
    setDisplayGrid(false);
    setLoadingSecond(true);
    getResultsByWorkload(workloadId, id_service)
      .then((payload) => {
        // setDisplayGrid(true);
        // setLoadingSecond(false);
        setPayloadHere(payload);

        const { resources } = payload;
        setTargetCol(resources[0].selectedColumn);
        if (payload) {
          // const obj = {
          //   dataframe:
          //     payload?.resources[0]?.generateComplianceBoolean === true
          //       ? payload?.results_data[0]?.data?.output[0]
          //       : payload?.results_data[0]?.data?.output,

          //   targetColumns: resources[0].selectedColumn,
          // };

          // console.log('old payload for similarity iner', obj);

          try {
            setDisplayGrid(true);
            setLoadingSecond(false);
            const obj2 = [];
            // obj2.push(JSON?.parse(data)?.df);
            obj2.push(payload?.results_data[0]?.data?.output[1]);
            obj2.push(payload?.results_data[0]?.data?.output[2]);
            // console.log(payload);
            if (
              payload?.resources[0]?.generateComplianceBoolean === false ||
              payload?.resources[0]?.doctodatset?.generateComplianceStatus === false
            ) {
              handleSetRowsColumns(JSON.parse(payload?.results_data[0]?.data?.output));
              setCurrentCaptureName(payload?.resources[0]?.NameOfCapture);
            } else {
              handleSetRowsColumns(JSON.parse(payload?.results_data[0]?.data?.output[0]));
              setCurrentCaptureName(payload?.resources[0]?.NameOfCapture);
              const r = JSON.parse(payload?.results_data[0]?.data?.output[1]);
              // console.log('r', r);
              setGraphOP(
                r.map((rec: any) => {
                  return { name: rec['Compliance Status label'], value: rec.Count };
                })
              );
            }
          } catch (e) {
            console.log(e);
          }

          // runSimilarityDoc(obj).then((data) => {
          //   // console.log('old result for similarity iner', JSON?.parse(data));

          // });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [workloadId]);

  useEffect(() => {
    if (pageSize1 > rows.length) {
      if (dis1) {
        setDis1((prev) => {
          return !prev;
        });
      }
    } else if (pageSize1 < rows.length) {
      setDis1((prev) => {
        return !prev;
      });
    }
  }, [pageSize1]);

  // useEffect(() => {
  //   getCaptureName(projectId).then((resp) => {
  //     if (resp) {

  const renderHighlighter = ({ row, value }) => {
    const { Input_Entities } = row;
    const arry = Input_Entities?.split(',') ?? [];

    return (
      <Highlighter
        highlightClassName="highlight"
        searchWords={arry}
        autoEscape={true}
        textToHighlight={value}
      />
    );
  };
  function getUniqueWords(text1, text2) {
    // Tokenize by matching words, numbers with optional decimals, and any individual punctuation
    const tokenize = (text) => text.toLowerCase().match(/\d+\.\d+|\w+|[^\s\w]/g) || [];

    // Tokenize each text after converting to lowercase
    const tokens1 = new Set(tokenize(text1));
    const tokens2 = new Set(tokenize(text2));

    // Find tokens unique to text1
    const uniqueToText1 = [...tokens1].filter((token) => !tokens2.has(token));
    // Find tokens unique to text2
    const uniqueToText2 = [...tokens2].filter((token) => !tokens1.has(token));

    return {
      uniqueToText1,
      uniqueToText2,
    };
  }
  const renderHighlighter2 = ({ row, value }) => {
    // const { Output_Entities } = row;
    // console.log('out', Output_Entities);
    // const arry = Output_Entities?.split(',') ?? [];

    // return (
    //   <Highlighter
    //     highlightClassName="yellow"
    //     searchWords={arry}
    //     autoEscape={true}
    //     textToHighlight={value}
    //   />
    // );c
    if (!value) return null;
    // console.log(row, 'value');
    const mostSimilarText =
      row[
        payloadHere?.resources[0]?.selectedColumn
          ? payloadHere?.resources[0]?.selectedColumn
          : 'Text'
      ];
    // Find common words between Chapter and Most Similar Text
    const commonWords = getUniqueWords(`${value}`, `${mostSimilarText}`);
    return (
      <Highlighter
        searchWords={commonWords.uniqueToText1} // Array of common words to highlight
        textToHighlight={value} // The text in which to highlight the words
        autoEscape={true}
        highlightStyle={{ backgroundColor: 'yellow', fontWeight: 'bold' }} // Style for highlighted words
      />
    );
  };

  const renderSimilar2 = ({ row, value }: DataTypeProvider.ValueFormatterProps) => {
    // console.log(
    //   'selectedColumnName',
    //   payloadHere?.resources[0]?.selectedColumn ? payloadHere?.resources[0]?.selectedColumn : 'Text'
    // );
    if (!value) return null;
    // console.log(row, 'value');
    const mostSimilarText = row['Most Similar Text'];
    // Find common words between Chapter and Most Similar Text
    const commonWords = getUniqueWords(`${value}`, `${mostSimilarText}`);
    return (
      <Highlighter
        searchWords={commonWords.uniqueToText1} // Array of common words to highlight
        textToHighlight={value} // The text in which to highlight the words
        autoEscape={true}
        highlightStyle={{ backgroundColor: 'yellow', fontWeight: 'bold' }} // Style for highlighted words
      />
    );
  };

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pptx: PptDoc,
      docx: DocxDoc,
      pdf: PdfDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {loadingSecond ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          {/* <Loading /> */}
          Loading...
        </div>
      ) : (
        <></>
      )}
      {displayGrid && (
        <div>
          <br />
          {/* {console.log('payloadHere', payloadHere)} */}
          <span style={{ color: 'black' }}>
            <b>Document Name: </b>{' '}
            {payloadHere?.resources[0]?.FileName
              ? payloadHere?.resources[0]?.FileName
              : payloadHere?.resources[0]?.output?.FileName}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <b>Selected Column: </b>{' '}
            {payloadHere?.resources[0]?.selectedColumn
              ? payloadHere?.resources[0]?.selectedColumn
              : 'Text'}
          </span>
          <br />

          <Grid>
            <div style={{ marginTop: 5, backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
              <ExpressGrid rows={rows} columns={columnsAre}>
                <DataTypeProvider
                  for={columnName}
                  formatterComponent={({ value }) => <span title={value}>{value}</span>}
                />
                <FilteringState defaultFilters={[{ columnName: selectedColumnName, value: '' }]} />
                <IntegratedFiltering />
                <SortingState />
                <IntegratedSorting />
                <PagingState
                  currentPage={currentPage1}
                  onCurrentPageChange={setCurrentPage1}
                  pageSize={pageSize1}
                  onPageSizeChange={setPageSize1}
                />
                <IntegratedPaging />
                <Table
                  // tableComponent={TableComponent}
                  tableComponent={MultiAllocTableComp}
                  containerComponent={tableAllocatorComponent}
                  headComponent={tableHeaderComponent}
                  columnExtensions={columnsAre.map((c) => ({
                    columnName: c.name,
                    wordWrapEnabled: toggleHeight,
                  }))}
                />

                <TableColumnResizing
                  onColumnWidthsChange={setColumnWidths}
                  columnWidths={columnWidths}
                  resizingMode="nextColumn"
                />
                <DataTypeProvider for={[targetCol]} formatterComponent={renderHighlighter} />
                <DataTypeProvider
                  for={['Most Similar Text']}
                  formatterComponent={renderHighlighter2}
                />
                <DataTypeProvider
                  for={[
                    payloadHere?.resources[0]?.selectedColumn
                      ? payloadHere?.resources[0]?.selectedColumn
                      : 'Text',
                  ]}
                  formatterComponent={renderSimilar2}
                />
                <TableFilterRow rowComponent={rowComponent} rowHeight={32} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                  hiddenColumnNames={hiddenColumnNames}
                  onHiddenColumnNamesChange={setHiddenColumnNames}
                />
                {/* {console.log('hiddenhhh', columnsAre)}
                {console.log('hiddenColumnNames', hiddenColumnNames)}
                {console.log(
                  'hiddenhhh22',
                  columnsAre.filter((item) => !hiddenColumnNames.includes(item.name))
                )} */}
                <Toolbar rootComponent={toolbarComponent} />
                <ColumnChooser />
                <ToolBarPlugin name="Download" onClick={startExport} loading={downloading} />
                <ToolBarPlugin
                  name="Height"
                  title="Wrap text"
                  onClick={() => setToggleHeight(!toggleHeight)}
                />
                {/* <PagingPanel pageSizes={pageSizes} /> */}
                <PagingPanel
                  pageSizes={pageSizes}
                  // containerComponent={(props) => (
                  //   <>
                  //     <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                  //   </>
                  // )}
                />
              </ExpressGrid>
              {/* <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columnsAre.filter((item) => !hiddenColumnNames.includes(item.name))}
                onSave={onSave}
              /> */}
              {/* <NavigationGotoNew
                setPageSize={setPageSize1}
                fileLength={rows.length}
                pageSize={pageSize1}
                dis={dis1}
                setTemPage={setTemPage1}
                handlePageNum={handlePageNum1}
                currentPage={currentPage1}
                setCurrentPage={setCurrentPage1}
              /> */}
            </div>
          </Grid>
          <br />
          {
            // eslint-disable-next-line eqeqeq
            graphOP != null && (
              <Grid container>
                <Grid lg={6} sm={6} md={6}>
                  <PieChart width={500} height={400}>
                    <Pie
                      data={graphOP}
                      cy="center"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={140}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {graphOP.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={DOCTODATASET_COLORS[entry.name]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  <div style={{ textAlign: 'center' }}>
                    {graphOP.map((d: any, index: any) => (
                      <Chip
                        // label={`${DOCTODATASET_LABELS[d.name]} (${d.value})`}
                        label={`${d.name} (${d.value})`}
                        color="primary"
                        style={{
                          backgroundColor: DOCTODATASET_COLORS[d.name],
                          marginRight: '3px',
                          marginTop: '5px',
                        }}
                        key={`chip-${index}`}
                      />
                    ))}
                  </div>
                </Grid>
                <Grid xs={6} style={{ marginTop: 50 }}>
                  <BarChart
                    width={graphOP.length * 120}
                    height={400}
                    data={graphOP
                      .sort((a: any, b: any) => {
                        return a.value - b.value;
                      })
                      .reverse()}
                    layout="horizontal"
                  >
                    <XAxis dataKey="name" fontSize={10} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="value" fill="#DC3223" barSize={20}>
                      {graphOP.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={DOCTODATASET_COLORS[entry.name]} />
                      ))}
                    </Bar>
                  </BarChart>
                </Grid>
              </Grid>
            )
          }
        </div>
      )}
    </>
  );
}

export default memo(SimilarityAnalysisNew);
