import {
  CssBaseline,
  ListItemButton,
  ListItemText,
  Paper,
  AppBar,
  Grid,
  List,
  Typography,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import AttributeTable from '../../../AttributeTable';
import ProjectAttributeContainer from './styles/ProjectAttributeStyle';

const ProjectAttribute = ({ projectId }) => {
  const [attributes, setAttributes] = useState<any>('Safety');
  // selectedIndex in List for Navigation
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [cancelValue, setCancelValue] = React.useState(true);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setAttributes(event?.target?.textContent);
    setSelectedIndex(index);
    setCancelValue(true);
  };

  return (
    <>
      <ProjectAttributeContainer style={{ marginTop: 10 }}>
        <Grid container spacing={2} style={{ minHeight: '50vh' }}>
          <Grid item xs={3}>
            <div
              style={{
                minHeight: '80vh',
                border: '1px solid #002A45',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '5px 5px 0px 5px',
                position: 'sticky',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* <CssBaseline /> */}
                <AppBar position="relative" style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <center>
                    <Typography variant="h6" noWrap component="div">
                      Attributes
                    </Typography>
                  </center>
                </AppBar>
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  disablePadding
                  className="ListItemBg"
                >
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemText primary="Category" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemText primary="Contribution" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                  >
                    <ListItemText primary="Critical To Customer" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                  >
                    <ListItemText primary="Safety Integrity" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 4}
                    onClick={(event) => handleListItemClick(event, 4)}
                  >
                    <ListItemText primary="Stability" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 5}
                    onClick={(event) => handleListItemClick(event, 5)}
                  >
                    <ListItemText primary="Testable" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 6}
                    onClick={(event) => handleListItemClick(event, 6)}
                  >
                    <ListItemText primary="Type" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 7}
                    onClick={(event) => handleListItemClick(event, 7)}
                  >
                    <ListItemText primary="Validation Method" style={{ textAlign: 'center' }} />
                  </ListItemButton>
                </List>
              </Box>
            </div>
          </Grid>
          {cancelValue === true ? (
            <>
              <Grid item xs={8}>
                {attributes === 'Safety Integrity' && (
                  <AttributeTable projectId={projectId} name="Safety Integrity" />
                )}
                {attributes === 'Category' && (
                  <AttributeTable projectId={projectId} name="Category" />
                )}
                {attributes === 'Testable' && (
                  <AttributeTable projectId={projectId} name="Testable" />
                )}
                {attributes === 'Contribution' && (
                  <AttributeTable projectId={projectId} name="Contribution" />
                )}
                {attributes === 'Type' && <AttributeTable projectId={projectId} name="Type" />}
                {attributes === 'Critical To Customer' && (
                  <AttributeTable projectId={projectId} name="Critical To Customer" />
                )}
                {attributes === 'Stability' && (
                  <AttributeTable projectId={projectId} name="Stability" />
                )}
                {attributes === 'Validation Method' && (
                  <AttributeTable projectId={projectId} name="Validation Method" />
                )}
              </Grid>
            </>
          ) : null}
        </Grid>
      </ProjectAttributeContainer>
    </>
  );
};

export default ProjectAttribute;
