import React, { useEffect, useRef, useState } from 'react';
import useWebSocketClient from '../../Core/useWebSocketClient';
import { userWorkloadGetbyPageNumber, workloadDeleteById } from '../../../api/workloads';

const useHistory = (props) => {
  const { serviceName, workload, currentPage } = props;
  // const workloads = useRef([]);
  const [historyData, setHistoryData] = useState({
    loading: true,
    rows: [],
    totalCount: 0,
    workloads: [],
  });

  const { workloads } = historyData;

  const { rows, totalCount } = historyData;
  const { status, webSocketResponse } = useWebSocketClient({
    serviceName,
    serviceFor: workload.serviceFor,
    workload: workload.workload ?? 0,
    workloads,
  });

  const getWorkLoadAll = (currentPage: number, serivceName: string) => {
    setHistoryData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let action;
    switch (serivceName) {
      case 'Compare Versions':
        action = 'docdiffer';
        break;
      case 'Extract Topics':
        action = 'iner';
        break;
      case 'Similarity Analysis':
        action = 'DocToDataset';
        break;
      case 'Document Similarity':
        action = 'DocToDoc';
        break;
      case 'Find Duplicates':
        action = 'SelfDoc';
        break;
      case 'Document Translator':
        action = 'Translator';
        break;
      case 'Rex Book':
        action = 'RexBook';
        break;
      default:
        break;
    }
    const { name, id } = workload.serviceFor;
    userWorkloadGetbyPageNumber(id, currentPage, action, name, id)
      .then((activities) => {
        const { activity_counts, workload } = activities as any;
        const listIds = workload.filter((data) => data.status !== 'end').map((data) => data.id);
        setHistoryData((prev) => {
          return {
            loading: false,
            rows: workload,
            totalCount: activity_counts[0].total_num_activity,
            workloads: listIds,
          };
        });
        // return Promise.resolve({ activities, listIds: '7605' });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteWorkLoad = async (id, service) => {
    if (id.length === 0) {
      return;
    }
    await workloadDeleteById(id, service);
    getWorkLoadAll(currentPage, serviceName);
  };

  // console.log('serviceName', serviceName);
  useEffect(() => {
    getWorkLoadAll(currentPage, serviceName);
  }, [currentPage]);

  // console.log('rows', rows);
  // console.log('webSocketResponse', webSocketResponse);

  useEffect(() => {
    if (webSocketResponse.length > 0) {
      const temp = rows.map((data) => {
        const t = webSocketResponse.find((item) => item.id === data.id);
        // console.log('t', t);
        if (t) {
          return t;
        }
        return data;
      });
      setHistoryData((prev) => {
        return {
          ...prev,
          loading: false,
          rows: temp,
        };
      });
      //   getWorkLoadAll(currentPage, serviceName);
    }
  }, [status, webSocketResponse]);

  return { ...historyData, getWorkLoadAll, deleteWorkLoad };
};

export default useHistory;
