import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SelectMUI from '../../../HOC/core/SelectMUI';
import { deepDocSupportedCode, deepl_language } from '../../../../const';
import M2GlobalContext from '../../../ContextMager/M2GlobalContext';

const MainContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  //   display: 'grid',
  //   gridTemplateColumns: '2fr 3fr',
  width: '100%',
  height: '100%',
  //   backgroundColor: 'rgb(255, 255, 255)',
  //   border: '1px solid #ccd6f2',
  //   margin: '0 auto',
  //   [theme.breakpoints.up('lg')]: {
  //     height: '30rem',
  //   },
  //   [theme.breakpoints.up('xl')]: {
  //     height: '25rem',
  //   },
}));

const SelectorContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  // gridTemplateColumns: '1.78fr 0.2fr 2.7fr',
  //   backgroundColor: 'rgb(255, 255, 255)',
  position: 'relative',
  transition: 'all 0.3s ease-out',
  //   border: '1px solid #ccd6f2',
}));

const Header = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-block',
  height: '100%',
  //   width: 'calc(100% / 2 - 20px)',
  // cursor: 'pointer',
  // backgroundColor: '#fafafa',
}));

const Selector = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-block',
  border: '1px solid #ccd6f2',
  transition: 'all 0.3s ease-out',
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '8px',
  height: '100%',
  cursor: 'pointer',
  '&:hover': {
    color: '#EEF1F9',
    //   border: 'none',
    transform: 'scale(1.0)',
    zIndex: 4,
    transition: 'border 0.1s ease-out',
    border: '1px solid #002a45',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
}));

const Label = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  width: '100%',
  transition: 'all 0.3s ease-out',
  padding: '0.3rem 0.3rem 0.3rem 0.9rem',
  alignItems: 'center',
  color: '#002a45',
  fontWeight: 800,
}));

const DocTranslatorHeader = (props) => {
  const { docTranslation, setDocTranslation } = props;

  const { languageCode } = useContext(M2GlobalContext);
  // console.log('languageCode', languageCode);
  // console.log('docTranslation', docTranslation);

  const languageOptions = useMemo(() => {
    return docTranslation.model === 'DeepL'
      ? deepl_language
          .filter((item) => deepDocSupportedCode.includes(item.code))
          .map((item) => {
            return `${item.language}-${item.code}`;
          })
      : languageCode;
  }, [docTranslation, languageCode]);

  const HandleOnChangeOptions = useCallback(
    (language, type) => {
      if (type === 'Target') {
        setDocTranslation((prevState) => ({
          ...prevState,
          toLang: language,
        }));
      } else {
        setDocTranslation((prevState) => ({
          ...prevState,
          model: language,
        }));
      }
    },
    [docTranslation]
  );

  return (
    <MainContainer>
      <SelectorContainer>
        {/* <Header style={{ flex: 1 }}> */}
        {/* <Selector style={{ width: '100%' }}>
            <Label>
              Source <ArrowDropDownIcon fontSize="large" />
            </Label>
          </Selector> */}
        {/* </Header> */}
        {/* <Header style={{ flex: 1 }}> */}
        {/* <IconButton
          style={{
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            color: '#002a45',
          }}
        >
          <SwapHorizIcon />
        </IconButton> */}
        {/* </Header> */}
        <Header
          style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: '0.5rem' }}
        >
          <SelectMUI
            style={{ width: '60%', height: '100%' }}
            name="Target"
            selectedVal={docTranslation.toLang}
            optionStyle={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
            options={languageOptions}
            onChange={HandleOnChangeOptions}
          />
          <SelectMUI
            style={{ marginLeft: '0.5rem', height: '100%' }}
            name="Microsoft"
            selectedVal={docTranslation.model}
            optionStyle={{ gridTemplateColumns: 'repeat(1, 1fr)' }}
            options={['Microsoft', 'DeepL']}
            onChange={HandleOnChangeOptions}
          />
        </Header>
      </SelectorContainer>
    </MainContainer>
  );
};

export default DocTranslatorHeader;
