import {
  Column,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Button, Checkbox, CircularProgress, FormLabel, Tooltip } from '@material-ui/core';
import PublishIcon from '@mui/icons-material/Publish';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import XLSX from 'xlsx';
import {
  deleteTemplatesBulk,
  getTemplates,
  saveFunctionsBulk,
  saveRulesBulk,
  updateTemplate,
} from '../../../api/iverifier';
import useAppToken from '../../../app/useAppToken';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import { IS_DEBUG_MODE } from '../../../const';
import { handleColumns } from '../../../tools/datagrid';
import formatDate, { formatLocal } from '../../../tools/formatDate';
import AdminContext from '../../Admin/AdminContext';
import ModalFunctionViewer from '../ModalFunctionViewer';

import ModalRulesViewer from '../ModalRulesViewer';
import ModalTemplate from './ModalTemplate';

const columns = handleColumns([
  'id',
  'name',
  'version',
  'is_published',
  'rules',
  'rules_uploader',
  'functions',
  'functions_uploader',
  'created_at',
  'created_by_user',
  'updated_at',
  'updated_by_user',
]);

export const renderUser = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  if (!value) return null;
  return <>{value.name}</>;
};

const IverifierTemplatesAdmin = () => {
  const { appJWT } = useAppToken();
  const { setSnack } = useContext(AdminContext);
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState<any>([]);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [template, setTemplate] = useState<any>(null);
  const [uploadType, setUploadType] = useState<any>('rules');
  const [saving, setSaving] = useState<number>(0);

  // modal state
  const [toggleModalName, setToggleModalName] = useState<
    '' | 'modalTemplate' | 'modalRule' | 'modalFunction'
  >('');

  useEffect(() => {
    getTemplates().then((response) => {
      setRows(response);
      setLoading(false);
    });
  }, []);

  const handleNewRow = () => {
    setToggleModalName('modalTemplate');
  };

  const handleDelete = () => {
    if (selection.length === 0) return;
    const selectionIds = selection.map((e) => rows[e].id);
    deleteTemplatesBulk(selectionIds).then((res) => {
      setRows(rows.filter((e) => !selectionIds.includes(e.id)));
      setSelection([]);
    });
  };

  const validationRules = (body: any[]): boolean => {
    // pre: validation
    // - object_number: not null and number or dot or hyphen
    const regObjectNumber = /^[0-9][-|.|0-9]*[0-9]*/;
    const testObjectNumber = body.every((elem) => {
      return (
        elem.object_number !== undefined &&
        String(elem.object_number).search(regObjectNumber) !== -1
      );
    });
    // - rule = Script - Rule - Id is not null and object_number with hyphen
    const testScriptRuleId = body.every((elem) => {
      const scriptRuleId = elem.script_rule_id ?? '';
      if (scriptRuleId === '') return true;
      // console.log(elem, String(elem.object_number).search('-') !== -1);

      return String(elem.object_number).search('-') !== -1;
    });
    // - chapter = not a rule check if hyphen is not present ??
    // - Script - Rule - Id must be unique
    const testScriptRuleIdUnique = body.every((elem) => {
      const scriptRuleId = elem.script_rule_id ?? '';
      if (scriptRuleId === '') return true;
      // console.log(body.filter((e) => e.script_rule_id === scriptRuleId).length);
      const countRuleId = body.filter((e) => e.script_rule_id === scriptRuleId).length;
      if (countRuleId === 1) return true;
      return false;
    });

    if (!testObjectNumber) {
      setSnack('Object number not found', 'error');
    }

    if (!testScriptRuleId) {
      setSnack('Rule must have hyphen "-"', 'error');
    }

    if (!testScriptRuleIdUnique) {
      setSnack('Script Rule ID not unique', 'error');
    }

    return testObjectNumber && testScriptRuleId && testScriptRuleIdUnique;
  };

  const handleImport = async (e: React.ChangeEvent<any>) => {
    // fix undefined files

    if (!e.currentTarget.files || !e.currentTarget.files[0]) return;
    const myFile = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(myFile);
    reader.onload = () => {
      const data = new Uint8Array(reader.result as any);
      const wb = XLSX.read(data, { type: 'array' });
      const rowsImport = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      const body = rowsImport.map((r: any) => {
        return {
          object_number: r['Object Number'],
          module_profile: r['Module Profile'],
          script_rule_id: r['Script - Rule - ID'],
          rule: r.Rule,
          script_rule_condition: r['Script - Rule - Condition - Local'],
          script_rule_result: r['Script - Rule - Expected Result'],
          criticity: r.Criticity,
          comments: r.Comments,
          standard: true,
          created_by: Number(appJWT.user_id),
          template_id: template.id,
        };
      });
      // console.log(rowsImport);
      if (uploadType === 'rules') {
        // validation
        if (validationRules(body)) {
          setSaving(template.id);
          saveRulesBulk(template.id, body).then((response) => {
            setSaving(0);
            setRows(
              rows.map((r) => {
                if (r.id === template.id) {
                  return { ...r, rules: response, updated_at: dayjs.utc().format() };
                }
                return r;
              })
            );
          });
        }
      }

      if (uploadType === 'functions') {
        setSaving(template.id);
        saveFunctionsBulk(
          template.id,
          rowsImport.map((r: any) => {
            return {
              function: r.function,
              example: r.example,
              mapping: r.mapping,
              comments: r.Comments,
              standard: true,
              created_by: Number(appJWT.user_id),
              template_id: template.id,
            };
          })
        ).then((response) => {
          setSaving(0);
          setRows(
            rows.map((r) => {
              if (r.id === template.id) {
                return { ...r, functions: response, updated_at: dayjs.utc().format() };
              }
              return r;
            })
          );
        });
      }
    };
    e.currentTarget.value = '';
  };

  const handleIspublished = (e: React.ChangeEvent, row: any) => {
    const { name, id, version } = row;
    updateTemplate({
      id,
      name,
      version,
      is_published: e.target.checked,
    }).then((response) => {
      console.log(response);
    });
  };

  const renderId = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return <>{value}</>;
  };

  const renderRulesUploader = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <Tooltip
        title="Import rules (.xlsx file only)"
        onClick={() => {
          setTemplate(row);
          setUploadType('rules');
          document.getElementById('input-file-template')?.click();
        }}
      >
        <FormLabel style={{ marginTop: '3px' }}>
          <PublishIcon />
        </FormLabel>
      </Tooltip>
    );
  };
  const renderFunctions = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        {saving === row.id && uploadType === 'functions' && (
          <span>
            <CircularProgress size={26} color="secondary" />
          </span>
        )}
        {value && (
          <Button
            onClick={() => {
              setTemplate(row);
              setToggleModalName('modalFunction');
            }}
          >
            {value.length}
          </Button>
        )}
      </>
    );
  };

  const renderFunctionsUploader = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <Tooltip
        title="Import function (.xlsx file only)"
        onClick={() => {
          setTemplate(row);
          setUploadType('functions');
          document.getElementById('input-file-template')?.click();
        }}
      >
        <FormLabel style={{ marginTop: '3px' }}>
          <PublishIcon />
        </FormLabel>
      </Tooltip>
    );
  };

  const renderPublished = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <Checkbox defaultChecked={value} onChange={(e) => handleIspublished(e, row)} />
      </>
    );
  };

  const renderRules = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        {saving === row.id && uploadType === 'rules' && (
          <span>
            <CircularProgress size={26} color="secondary" />
          </span>
        )}
        {value && (
          <Button
            onClick={() => {
              setTemplate(row);
              setToggleModalName('modalRule');
            }}
          >
            {value.filter((r) => r.script_rule_id).length}
          </Button>
        )}
      </>
    );
  };

  const columnsEdit = columns.map((col: Column) => {
    if (['name', 'version'].includes(col.name)) {
      return { columnName: col.name, editingEnabled: true };
    }
    return { columnName: col.name, editingEnabled: false };
  });

  const handleEdit = ({ changed }) => {
    let changedRows;
    if (changed) {
      const key = Number(Object.keys(changed)[0]);
      const { name, id, version, is_published } = rows[key];
      updateTemplate({
        name,
        id,
        version,
        is_published,
        ...changed[key],
      }).then((response) => {
        changedRows = rows.map((row, index) =>
          changed[index] ? { ...row, ...changed[index], updated_at: dayjs.utc().format() } : row
        );
        setRows(changedRows);
      });
    }
  };

  if (loading) return <Loading />;
  return (
    <div>
      <div>
        {IS_DEBUG_MODE && (
          <ReactJson
            src={{
              template,
              rows,
            }}
            collapsed={true}
            theme="monokai"
          />
        )}
        {/* value = "" to disable cache */}
        <input id="input-file-template" value="" type="file" hidden onChange={handleImport} />
        <ExpressGrid rows={rows} columns={columns}>
          <DataTypeProvider for={['id']} formatterComponent={renderId} />
          <DataTypeProvider for={['is_published']} formatterComponent={renderPublished} />
          <DataTypeProvider for={['rules']} formatterComponent={renderRules} />
          <DataTypeProvider for={['rules_uploader']} formatterComponent={renderRulesUploader} />
          <DataTypeProvider for={['functions']} formatterComponent={renderFunctions} />
          <DataTypeProvider
            for={['created_by_user', 'updated_by_user']}
            formatterComponent={renderUser}
          />
          <DataTypeProvider
            for={['functions_uploader']}
            formatterComponent={renderFunctionsUploader}
          />
          <DataTypeProvider
            for={['created_at', 'updated_at']}
            formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
          />
          {/* selection */}
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <IntegratedSelection />
          {/* filtering */}
          <FilteringState />
          <SortingState />
          <IntegratedSorting />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          {/* edition */}
          <EditingState onCommitChanges={handleEdit} columnExtensions={columnsEdit} />
          <Table
            columnExtensions={columns.map((c) => ({
              columnName: c.name,
              wordWrapEnabled: toggleHeight,
            }))}
            tableComponent={TableComponent}
            containerComponent={tableContainerComponent}
            headComponent={tableHeaderComponent}
          />
          <TableColumnResizing
            defaultColumnWidths={[
              ...columns.map((c) => {
                if (
                  [
                    'name',
                    'version',
                    'created_at',
                    'created_by_user',
                    'updated_at',
                    'updated_by_user',
                  ].includes(c.name)
                )
                  return { columnName: c.name, width: 150 };

                return { columnName: c.name, width: 80 };
              }),
            ]}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow />
          <TableColumnVisibility defaultHiddenColumnNames={[]} />
          <TableEditRow />
          <TableEditColumn showEditCommand />
          <Toolbar rootComponent={toolbarComponent} />
          {/* selection */}
          <TableSelection showSelectAll />
          {/* <TableBandHeader columnBands={columnBands} /> */}
          <ColumnChooser />
          <ToolBarPlugin name="NewCapture" onClick={handleNewRow} title="Create new template" />
          <ToolBarPlugin name="Delete" onClick={handleDelete} />
          <ToolBarPlugin
            name="Height"
            title="Wrap Text"
            onClick={() => {
              setToggleHeight(!toggleHeight);
            }}
          />
          <ToolBarPlugin name="Download" onClick={null} />
          <PagingPanel pageSizes={[10, 20, 50]} />
        </ExpressGrid>
        {toggleModalName === 'modalTemplate' && (
          <ModalTemplate
            rows={rows.sort((a, b) => String(a.name).localeCompare(b.name))}
            onClose={(response) => {
              setToggleModalName('');
              if (response) setRows([response, ...rows]);
            }}
          />
        )}
        {toggleModalName === 'modalRule' && (
          <ModalRulesViewer
            rows={template.rules || []}
            template={{
              id: template.id,
              name: template.name,
              version: template.version,
            }}
            onClose={() => {
              setToggleModalName('');
            }}
          />
        )}
        {toggleModalName === 'modalFunction' && (
          <>
            <ModalFunctionViewer
              rows={template.functions || []}
              template={{
                name: template.name,
                version: template.version,
              }}
              onClose={() => {
                setToggleModalName('');
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default IverifierTemplatesAdmin;
