/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Workload3Create } from 'WorkloadType';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import ProjectFolder from '../../../Project/ProjectContainer/components/ProjectFolder/ProjectFolder';
import { workloadCreate, workloadRunPost } from '../../../../api/workloads';

const FileUploader = ({
  setDrawerOpen,
  setLoading,
  projectId,
  setSnack,
  selectedFile,
  handleRefresh,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleIdocApi = async (metadata) => {
    console.log('metadata', metadata);
    const indexedFilenamesSet = new Set(selectedFile); // Convert array to Set for faster lookup

    for (const file of metadata) {
      if (indexedFilenamesSet.has(file.filename)) {
        return setSnack(`Filename already under process: ${file.filename}`, 'warning');
      }
    }

    const targetOutput = metadata.reduce((acc, item) => {
      acc[item.filename] = item;
      return acc;
    }, {});
    setLoading(true);
    try {
      // call the middleware api
      // create workload for the file
      workloadCreate({
        project_id: projectId,
        app: 'module1',
        action: 'iDocExplorer',
        resources: metadata,
      }).then((workloadNew: Workload3Create) => {
        workloadRunPost(Number(workloadNew.id), targetOutput, 'idoc_explorer')
          .then((response) => {
            handleRefresh();
            setSnack(
              'Processing File takes time than anticipated. Please wait while the file finishes indexing.',
              'warning'
            );
          })
          .catch(() => {
            setSnack('Error while workload post.', 'error');
            setLoading(false);
          })
          .finally(() => {});
      });
    } catch (error) {
      console.error('Error processing file:', error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  return (
    <div>
      <IconButton style={{ color: 'white' }} onClick={handleOpen}>
        <UploadIcon />
      </IconButton>
      <Dialog
        onClose={handleClose}
        open={openDialog}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '5px',
            maxHeight: '600px',
            maxWidth: '900px',
            padding: '0',
          },
        }}
      >
        <DialogContent style={{ display: 'flex', width: '800px', height: '500px', padding: '0' }}>
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', top: 8, color: 'gray' }}
          >
            <CloseIcon />
          </IconButton> */}
          <ProjectFolder
            handleScopeSettings={handleIdocApi}
            numberOfFilesSupport={5}
            select="multiple"
            setDrawerOpen={setDrawerOpen}
            setOpenDialog={setOpenDialog}
            fileTypeSupport={[
              'pdf',
              'docx',
              'pptx',
              'ppt',
              'doc',
              'csv',
              'xlsx',
              'png',
              'jpg',
              'PNG',
              'JPG',
              'jpeg',
              'html',
              'adoc',
              'md',
              'json',
            ]}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FileUploader;
