import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useEffect, useReducer } from 'react';
import Tooltip from '@mui/material/Tooltip';
import CsvDoc from '../../../../assets/csv_icons.png';
import docIcon from '../../../../assets/docx_ico.png';
import { LeftBlock, MainDiv, RightBlock } from './Styled';
// import csvFileReducer, { csvInitialState } from '../../../../../Reducers/csvFileReducer';
// import csvFileReducer, { csvInitialState } from '../../../Reducers/csvFileReducer';
// import DocAnalysisContext from '../../../DocAnalysisContext';

const CSVFileSetting = (props) => {
  const { FileName, Sheet_names, Sheets, Blob, Container, setPayload, FileType, id } = props;
  const [csvdata, setCsvdata] = React.useState({
    ...props,
    columns: [],
  });
  const { ColumnNames } = csvdata ?? { ColumnNames: [] };

  useEffect(() => {
    setPayload((prev) => {
      return {
        ...prev,
        [id]: { columnName: '', Blob, Container, FileType, FileName, columnNames: ColumnNames },
      };
    });
  }, []);

  const HandleReg = (e) => {
    setCsvdata({ ...csvdata, columnName: e.target.value });
    setPayload((prev) => {
      return {
        ...prev,
        [id]: {
          columnName: e.target.value,
          Blob,
          Container,
          FileType,
          FileName,
          columnNames: ColumnNames,
        },
      };
    });
    // dispatch({ type: 'change_columnName', payload: obj });
  };

  return (
    <>
      <MainDiv>
        <LeftBlock>
          <FormControl variant="outlined" fullWidth>
            <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              {csvdata?.FileType === 'unsupported' ? (
                <img src={docIcon} alt="isens" height="30" />
              ) : (
                ''
              )}
              &nbsp;
              {csvdata?.FileType === 'CSV' ? (
                <img src={CsvDoc} alt="isens" height="30" width="30" />
              ) : (
                ''
              )}
              {csvdata?.FileType !== 'unsupported' ? (
                <span>
                  <span style={{ color: 'red' }}>{csvdata?.Error}</span>
                </span>
              ) : (
                ''
              )}
              <Tooltip title={csvdata?.Blob} placement="top" arrow>
                <b style={{ color: 'blue', wordWrap: 'break-word', wordBreak: 'break-all' }}>
                  {csvdata?.Blob.substring(0, 25)}
                </b>
              </Tooltip>
            </span>
          </FormControl>
        </LeftBlock>
        <RightBlock>
          {csvdata.FileType === 'CSV' && (
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                style={{ color: '#002A45', fontWeight: 'bold' }}
              >
                Column Name
              </InputLabel>
              <Select
                native
                label="Column Name"
                name="columnname"
                style={{ width: '100%' }}
                onChange={(e) => HandleReg(e)}
                defaultValue=""
              >
                <option aria-label="None" value="" disabled />
                {csvdata?.ColumnNames !== null
                  ? csvdata?.ColumnNames.map((cap: string, index: number) => (
                      <option key={`capture-type-${index}`} value={cap}>
                        &nbsp;&nbsp;{cap}
                      </option>
                    ))
                  : ''}
              </Select>
            </FormControl>
          )}
        </RightBlock>
      </MainDiv>
    </>
  );
};

export default CSVFileSetting;
