import { getBearerTokenSync } from '../../../app/AppToken';

/* eslint-disable no-param-reassign */
export const initialState: AppState = {
  userInfo: {},
  user: 'normal',
  jwtTokenDetails: {
    accessToken: localStorage.getItem('jwtTokenDetails') || getBearerTokenSync(),
    idToken: '',
    refreshToken: '',
  },
};

type ToggleUser = { type: 'TOOGLE_USER' };
type SetUser = { type: 'SET_USER'; payload: any };

interface IToggleUserPayload {
  user: 'admin' | 'normal';
}

export enum ACTION_TYPE {
  SET_USER = 'SET_USER',
  TOGGLE_USER = 'TOGGLE_USER',
  SET_JWT_TOKEN_DETAILS = 'SET_JWT_TOKEN_DETAILS',
}

type SetJwtTokenDetails = {
  type: 'SET_JWT_TOKEN_DETAILS';
  jwtTokenDetails: object;
};

export type AppAction = ToggleUser | SetJwtTokenDetails | SetUser;

export interface AppState extends IToggleUserPayload {
  jwtTokenDetails: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
  };
  userInfo: any;
}

export default function authReducer(state: AppState, action: AppAction) {
  switch (action.type) {
    case 'TOOGLE_USER':
      state.user = state.user === 'admin' ? 'normal' : 'admin';
      return state;

    case 'SET_USER':
      state.userInfo = action.payload;
      state.user = action?.payload?.userRole?.id === 1 ? 'admin' : 'normal';
      return state;
    case 'SET_JWT_TOKEN_DETAILS':
      state.jwtTokenDetails = action.jwtTokenDetails;
      return state;

    default:
      return state;
  }
}
