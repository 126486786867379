import React, { useContext, useEffect, useState } from 'react';
import {
  userWorkloadGetbyPageNumber,
  workloadDelete,
  workloadGetbyPageNumber,
} from '../../../api/workloads';
import useProjectWebSocketClient from '../../Core/useProjectWebSocketClient';
import ProjectContext from '../../../containers/Project/ProjectContext';

const useProjectHistory = (props) => {
  const { serviceName, workload, currentPage, filterColumns, selectedWorkload } = props;
  const { setSnack } = useContext(ProjectContext);
  const [historyData, setHistoryData] = useState({
    loading: true,
    rows: [],
    totalCount: 0,
    listIds: [],
  });

  const { listIds } = historyData;

  const handleDeleteWorkload = () => {
    // console.log('selectedWorkload', selectedWorkload.current);
    if (selectedWorkload.current.length > 0) {
      workloadDelete(selectedWorkload.current)
        .then(() => {
          setHistoryData((prev) => {
            return {
              ...prev,
              rows: prev.rows.filter((row) => !selectedWorkload.current.includes(row.id)),
              loading: false,
            };
          });
          selectedWorkload.current = [];
          setSnack('Deletion Successful', 'success');
        })
        .catch((err) => {
          console.error(err);
          setSnack('Deletion Unsuccessful', 'error');
        });
    } else if (selectedWorkload.current.length === 0) {
      setSnack('Please select items to delete', 'warning');
    }
    // console.log('selectedWorkload', selectedWorkload);
  };

  const getWorkLoadAll = (
    currentPage: number,
    reset = 'none',
    payload = {
      activityId: '',
      modules: [],
      action: [],
      fromDate: '',
      toDate: '',
    }
  ) => {
    // const payload =
    //   reset === 'reset'
    //     ? {
    //         activityId: '',
    //         modules: [],
    //         action: [],
    //         fromDate: '',
    //         toDate: '',
    //       }
    //     : {
    //         activityId: '',
    //         modules: [],
    //         action: [],
    //         fromDate: '',
    //         toDate: '',
    //       };
    setHistoryData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    return workloadGetbyPageNumber(workload.serviceFor.id, currentPage, payload)
      .then((activities) => {
        let listIds = [] as any[];
        const { activity_counts, workload } = activities;
        listIds = workload.filter((act) => act.status !== 'end').map((act) => act.id);
        setHistoryData((prev) => {
          return {
            ...prev,
            loading: false,
            rows: workload,
            totalCount: activity_counts[0].total_num_activity,
            listIds,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const { status, webSocketResponse } = useProjectWebSocketClient({
    serviceName,
    serviceFor: workload.serviceFor,
    workload: workload.workload ?? 0,
    listIds,
  });

  useEffect(() => {
    getWorkLoadAll(currentPage, serviceName, filterColumns);
  }, [currentPage, workload]);

  useEffect(() => {
    if (webSocketResponse.length > 0) {
      setHistoryData((prev) => {
        return {
          ...prev,
          loading: false,
          rows: prev.rows.map((eachRow) => {
            const rowFound = webSocketResponse.find((r) => r.id === eachRow.id);
            if (rowFound) {
              return rowFound;
            }
            return eachRow;
          }),
        };
      });
      //   getWorkLoadAll(currentPage, serviceName);
    }
  }, [status, webSocketResponse]);

  return { historyData, getWorkLoadAll, handleDeleteWorkload };
};

export default useProjectHistory;
