import React, { useContext, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import dayjs, { Dayjs } from 'dayjs';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
  backdropClasses,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useProjectHistory from './Hooks/useProjectHistory';
import ServiceItem, { RowItem } from './components/ServiceItem';
import { workloadDelete } from '../../api/workloads';
import ProjectContext from '../../containers/Project/ProjectContext';
import AlertSnack from '../Alert/AlertSnack';

// Define custom styles
const useStyles = makeStyles({
  customStack: {
    paddingTop: '0 !important',
  },
});

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  gap: '10px',
  alignItems: 'center',
}));

const FilterHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  // width: '100%',
  // padding: '0.5rem',
  fontSize: '0.89rem',
  gridTemplateColumns: '0.5fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr',
  justifyContent: 'end',
  gap: '0.5rem',
  borderBottom: '1px solid #e0e0e0',
  width: '100%',
  // [theme.breakpoints.down('lg')]: {
  //   width: '100%',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   width: '100%',
  // },
}));

const EachItem = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  // backgroundColor: 'rgb(23, 193, 232)',
  color: 'white',
  padding: '0.5rem',
  borderRadius: '5px',
  cursor: 'pointer',
}));
const action_service: any[] = [
  { value: 'capture', label: 'iCapturer' },
  { value: 'characterizer', label: 'iCharacterizer' },
  { value: 'allocator', label: 'iAllocator' },
  { value: 'iQualimeter', label: 'iQualimeter' },
  { value: 'DocToDataset', label: 'Similarity Analysis' },
  { value: 'DocToDoc', label: 'Document similiarity' },
  { value: 'docdiffer', label: 'Compare versions' },
  { value: 'SelfDoc', label: 'Find duplicates' },
  { value: 'ner', label: 'Extract topics' },
  { value: 'Translator', label: 'Translator' },
  { value: 'RexBook', label: 'RexBook' },
  { value: 'ScheduleAnalysis', label: 'Schedule Analysis' },
];

const ApplyBtn = ({ filterColumns, selectedWorkload, getWorkLoadAll, currentPage }) => {
  let updateSelectedWorkload = selectedWorkload;
  const [snack, setSnack] = React.useState({ msg: '', severity: '' });
  const handleApply = () => {
    if (
      filterColumns.fromDate === '' &&
      filterColumns.toDate === '' &&
      filterColumns.action.length === 0 &&
      filterColumns.activityId === '' &&
      filterColumns.modules.length === 0
    ) {
      updateSelectedWorkload = [];
      setSnack({ msg: 'Please select at least one filter', severity: 'warning' });
      return;
    }
    getWorkLoadAll(currentPage, 'none', filterColumns);
  };
  return (
    <>
      <Button
        style={{
          backgroundColor: '#002a45',
          color: 'rgb(255, 255, 255)',
          width: '100%',
        }}
        onClick={handleApply}
      >
        Apply
      </Button>
      {snack.msg !== '' && (
        <AlertSnack
          msg={snack.msg}
          severity={snack.severity}
          handleClose={(event?: React.SyntheticEvent, reason?: string) =>
            setSnack({ msg: '', severity: '' })
          }
          duration={3000}
        />
      )}
    </>
  );
};
const ResetBtn = ({
  filterColumns,
  selectedWorkload,
  setFilterColumns,
  getWorkLoadAll,
  currentPage,
}) => {
  let updateSelectedWorkload = selectedWorkload;
  const [snack, setSnack] = React.useState({ msg: '', severity: '' });
  const handleReset = () => {
    if (
      filterColumns.fromDate === '' &&
      filterColumns.toDate === '' &&
      filterColumns.action.length === 0 &&
      filterColumns.activityId === '' &&
      filterColumns.modules.length === 0 &&
      selectedWorkload.current.length === 0
    ) {
      setSnack({ msg: 'Please do any changes', severity: 'warning' });
      return;
    }
    updateSelectedWorkload = [];
    setFilterColumns({
      activityId: '',
      modules: [],
      action: [],
      fromDate: '',
      toDate: '',
    });
    getWorkLoadAll(currentPage, 'none');
  };
  return (
    <>
      <Button
        style={{
          backgroundColor: '#002a45',
          color: 'rgb(255, 255, 255)',
          width: '100%',
        }}
        onClick={handleReset}
      >
        Reset
      </Button>
      {snack.msg !== '' && (
        <AlertSnack
          msg={snack.msg}
          severity={snack.severity}
          handleClose={(event?: React.SyntheticEvent, reason?: string) =>
            setSnack({ msg: '', severity: '' })
          }
          duration={3000}
        />
      )}
    </>
  );
};
const ProjectTable = ({
  props,
  rows,
  totalCount,
  currentPage,
  handleChangePage,
  handleCheckboxChange,
  selectedWorkload,
  loading,
}) => {
  return (
    <>
      <RowItem style={{ fontWeight: 'bold' }}>
        <div>{}</div>
        <div>ID</div>
        <div>Resources</div>
        <div>Action</div>
        <div>Status</div>
        <div>Created By</div>
        <div>Created At</div>
      </RowItem>
      {loading && (
        <RowItem
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
          }}
        >
          <CircularProgress style={{ color: '#002a45' }} size={20} />
        </RowItem>
      )}

      {!loading &&
        (rows.length > 0 ? (
          rows.map((item) => {
            return (
              <>
                <ServiceItem
                  {...props}
                  selectedWorkload={selectedWorkload}
                  data={item}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </>
            );
          })
        ) : (
          <RowItem
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: '#d9534f', fontWeight: 'bold', textAlign: 'center' }}
            >
              Oops! No item to display :(
            </Typography>
          </RowItem>
        ))}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          padding: '0.3rem',
          width: '100%',
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / 10)}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

const ProjectHistory = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { modelService, workload } = props;
  const classes = useStyles();
  const { setSnack } = useContext(ProjectContext);
  const today = dayjs();
  const yesterday = dayjs().add(-1, 'day');
  // const [selectedWorkload, setSelectedWorkload] = React.useState([]);

  const selectedWorkload = React.useRef([]);
  // console.log('yesterday', yesterday.format('YYYY-MM-DD'));
  const ActionOptions = [''];
  const [filterColumns, setFilterColumns] = React.useState({
    activityId: '',
    modules: [],
    action: [],
    fromDate: '',
    toDate: '',
  });

  const { historyData, getWorkLoadAll, handleDeleteWorkload } = useProjectHistory({
    serviceName: modelService,
    workload,
    currentPage,
    filterColumns,
    selectedWorkload,
  });

  const { loading, rows, totalCount } = historyData;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };
  // const handleReset = () => {
  //   if (
  //     filterColumns.fromDate === '' &&
  //     filterColumns.toDate === '' &&
  //     filterColumns.action.length === 0 &&
  //     filterColumns.activityId === '' &&
  //     filterColumns.modules.length === 0 &&
  //     selectedWorkload.current.length === 0
  //   ) {
  //     setSnack('Please do any changes', 'warning');
  //     return;
  //   }
  //   selectedWorkload.current = [];
  //   setFilterColumns({
  //     activityId: '',
  //     modules: [],
  //     action: [],
  //     fromDate: '',
  //     toDate: '',
  //   });
  //   getWorkLoadAll(currentPage, 'none');
  // };

  const handleActivityIdChange = (e) => {
    setFilterColumns({ ...filterColumns, activityId: e.target.value });
  };
  const handleFromDateChange = (e) => {
    if (filterColumns.toDate !== '' && dayjs(e).isAfter(dayjs(filterColumns.toDate))) {
      setFilterColumns({ ...filterColumns, fromDate: '' });
      alert('Please select a date before To Date');
    } else if (e !== null) {
      setFilterColumns({ ...filterColumns, fromDate: e.format('YYYY-MM-DD') });
    }
  };

  const handleToDateChange = (e) => {
    if (filterColumns.fromDate !== '' && dayjs(filterColumns.fromDate).isAfter(dayjs(e))) {
      setFilterColumns({ ...filterColumns, toDate: '' });
      alert('Please select a date after From Date');
    } else if (e !== null) {
      setFilterColumns({ ...filterColumns, toDate: e.format('YYYY-MM-DD') });
    }
  };
  const handleChangeAction = (event) => {
    const {
      target: { value },
    } = event;
    const tempArr = action_service
      .filter((item) => value.includes(item.label))
      .map((item) => item.value);
    if (tempArr.length === 0) {
      setFilterColumns({ ...filterColumns, action: [] });
    } else {
      setFilterColumns((prev) => {
        let currentActions = [...prev.action];

        tempArr.forEach((item) => {
          if (!currentActions.includes(item)) {
            currentActions.push(item);
          }
          currentActions = currentActions.filter((item) => tempArr.includes(item));
        });
        return { ...prev, action: currentActions };
      });
    }
  };

  const handleCheckboxChange = (event, data) => {
    const { id = 0 } = data ?? { id: 0 };
    if (event.target.checked && id) {
      selectedWorkload.current.push(id);
    } else if (selectedWorkload.current.includes(id) && id) {
      selectedWorkload.current = selectedWorkload.current.filter((item) => item !== id);
    }
  };
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  // console.log('selectedWorkload', selectedWorkload);

  return (
    <Container>
      <FilterHeader>
        <EachItem>
          <TextField
            style={{ flex: 1, backgroundColor: 'white', color: '#002A45', height: '40px' }}
            size="small"
            type="number"
            id="outlined-basic"
            label="Activity Id"
            variant="outlined"
            value={filterColumns.activityId === '' ? '' : Number(filterColumns.activityId)}
            onChange={handleActivityIdChange}
            inputProps={{ min: 1, onKeyPress: handleKeyPress }}
          />
        </EachItem>

        {/* {[1].map((item) => {
          return <EachItem>{item}</EachItem>;
        })}{' '} */}
        <EachItem>
          <FormControl size="small" style={{ width: '100%', backgroundColor: 'white' }}>
            <InputLabel id="demo-multiple-checkbox-label">Action</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={action_service
                .filter((item) => filterColumns.action.includes(item.value))
                .map((item) => item.label)}
              onChange={handleChangeAction}
              size="small"
              input={<OutlinedInput label="Action" size="small" />}
              renderValue={(selected) => selected.join(', ').slice(0, 30)}
              style={{ width: '100%' }}
            >
              {action_service.map((item) => (
                <MenuItem key={item.label} value={item.label} style={{ height: '40px' }}>
                  <Checkbox checked={filterColumns.action.includes(item.value)} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </EachItem>
        <EachItem>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
              <DatePicker
                label="From date"
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    sx: {
                      paddingTop: 0,
                      backgroundColor: 'white',
                      color: '#002A45',
                    },
                  },
                }}
                value={dayjs(filterColumns.fromDate)}
                maxDate={filterColumns.toDate !== '' ? dayjs(filterColumns.toDate) : today}
                onChange={handleFromDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>
        </EachItem>
        <EachItem>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
              <DatePicker
                label="To Date"
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    sx: {
                      backgroundColor: 'white',
                      color: '#002A45',
                    },
                  },
                }}
                value={dayjs(filterColumns.toDate)}
                minDate={dayjs(filterColumns.fromDate)}
                maxDate={today}
                onChange={handleToDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>
        </EachItem>
        <EachItem>
          {' '}
          <ApplyBtn
            filterColumns={filterColumns}
            selectedWorkload={selectedWorkload}
            setFilterColumns={setFilterColumns}
            getWorkLoadAll={getWorkLoadAll}
            currentPage={currentPage}
          />
        </EachItem>
        <EachItem>
          {' '}
          <ResetBtn
            filterColumns={filterColumns}
            selectedWorkload={selectedWorkload}
            setFilterColumns={setFilterColumns}
            getWorkLoadAll={getWorkLoadAll}
            currentPage={currentPage}
          />
        </EachItem>
        <EachItem>
          {' '}
          <Button
            style={{
              backgroundColor: '#002a45',
              color: 'rgb(255, 255, 255)',
              width: '100%',
            }}
            onClick={handleDeleteWorkload}
          >
            Delete
          </Button>
        </EachItem>
      </FilterHeader>

      <ProjectTable
        props={props}
        rows={rows}
        totalCount={totalCount}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        handleCheckboxChange={handleCheckboxChange}
        selectedWorkload={selectedWorkload.current}
        loading={loading}
      />
    </Container>
  );
};

export default ProjectHistory;
