import React, { useEffect, useState } from 'react';
import getW3client from '../../api/wsWorkloads';
import { Artifact, CreatedByUser } from '../../react-app-env';

type wsMessageType = {
  id: number;
  project_id: number;
  app: string;
  action: string;
  created_by: CreatedByUser;
  created_at: string;
  file_number: any;
  progress_percent: number;
  updated_at: string;
  status: 'end' | 'start' | 'progress';
  errors: any[];
  resources: any[];
};

const useProjectWebSocketClient = (props) => {
  const { workload, serviceFor, listIds } = props;
  const [wsClientNew, setWsClientNew] = useState<WebSocket | null>(null);
  const [webSocketResponse, setWebSocketResponse] = useState<[]>([]);

  function handleMessage(message: { data: string }) {
    const msgData: wsMessageType[] = JSON.parse(String(message.data));
    if (msgData && msgData.length > 0) {
      const wsResponse = [] as any;

      const filteredMessages = msgData.filter((r) => listIds.includes(r.id));
      const rowWebsocket = filteredMessages.find((r) => r.status !== 'end');

      if (rowWebsocket && wsClientNew) {
        wsClientNew?.close();
      }
      setWebSocketResponse(filteredMessages);
    }
  }

  useEffect(() => {
    return () => {
      // console.log('removed wsClientNew');
      // console.log('wsClientNew', wsClientNew);
      if (wsClientNew) {
        console.log('websocket closed');
        wsClientNew.close();
      }
    };
  }, [wsClientNew]);

  useEffect(() => {
    if (listIds.length > 0) {
      if (wsClientNew) {
        wsClientNew.close();
      }
      const wsTemp = getW3client();
      setWsClientNew(wsTemp);
      wsTemp.onopen = () => {
        // const stingTemp = workload.toString();
        wsTemp.send(listIds);
      };
      wsTemp.onmessage = (message) => handleMessage(message);
    }
  }, [listIds]);

  return { webSocketResponse };
};

export default useProjectWebSocketClient;
