import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Paper, Select, MenuItem, Typography, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { activateNewUsers, searchUsers } from '../../../api/users';
import Loading from '../../../components/Core/Loading';
import formatDate, { formatLocal } from '../../../tools/formatDate';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import ModalAdminUser from './ModalAdminUser';
import TStyles, { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import NavigationGoto from '../../../components/Core/NavigationGoto';

export const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, padding: theme.spacing(2) },
  bloc: {
    minHeight: 250,
    borderRadius: 10,
    // border: 'solid 1px;',
    borderColor: theme.palette.primary.light,
    margin: 10,
  },
  error: {
    color: theme.palette.error.main,
  },
  heading: {
    backgroundColor: '#DC3223',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    textAlign: 'center',
    marginBottom: 10,
    color: 'white',
  },
  success: {
    background: 'lightgreen',
    border: 'solid 1px green',
    borderRadius: 5,
    padding: 5,
  },
  selectStyle: {
    maxWidth: '120px',
    minWidth: '120px',
    textAlign: 'center',
  },
}));
const columns = [
  { name: 'id', title: 'Id' },
  { name: 'name', title: 'User Name' },
  { name: 'email', title: 'Email Id' },
  { name: 'activated_at', title: 'Activated Time' },
  { name: 'profile', title: 'Profile' },
  { name: 'is_admin', title: 'Admin' },
  { name: 'total_project', title: 'Total Projects' },
  // { name: 'projects_details', title: 'Projects' },
];

const AdminUsers = () => {
  const classes = useStyles();
  const [newUsers, setNewUsers] = useState<any[]>([]);
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [dis, setDis] = useState(true);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };
  const [selection, setSelection] = useState<any>([]);
  const exporterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [selectedProfileIs, setSelectedProfileIs] = useState<any>([]);
  const [modalData, setModalData] = useState([]);
  // modal state
  const [toggleModalName, setToggleModalName] = useState<
    '' | 'modalConfirm' | 'modalRule' | 'modalFunction'
  >('');
  const handleNewConfig = () => {
    setToggleModalName('modalConfirm');
  };
  const handleButtonId = (id: number) => {
    setToggleModalName('modalConfig');
  };

  const getAllUsersList = () => {
    searchUsers().then((dbusers: any[]) => {
      const usersArray = [];
      dbusers.forEach(
        (item: {
          profile: any;
          activated_at: any;
          id: any;
          projects_details: any;
          email: any;
          is_admin: any;
          name: any;
        }) => {
          return usersArray.push({
            name: item.name,
            profile: item.profile,
            id: item.id,
            email: item.email,
            activated_at: item.activated_at,
            is_admin: item.is_admin,
            projects_details: item.projects_details,
            total_project: item.projects_details.length,
          });
        }
      );
      setNewUsers(usersArray);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAllUsersList();
    setSelection([]);
  }, []);
  useEffect(() => {
    if (pageSize > newUsers.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < newUsers.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const activateUser = () => {
    if (selection.length === 0) alert('Select atleast one user to activate!');
    else {
      const selectedRows = newUsers
        .filter((row, index) => selection.includes(index))
        .map((row) => {
          return row.id;
        });
      // console.log(selectedRows);
      const arrayToSend: any = [];
      for (const r of selectedRows) {
        const myObj = newUsers.find((obj) => obj.id === r);
        // myObj.is_admin = true;
        arrayToSend.push(myObj);
      }
      // console.log(arrayToSend);
      activateNewUsers(arrayToSend).then((resp: any) => {
        setShowMsg(true);
        setSelection([]);
        getAllUsersList();
        setTimeout(() => {
          setShowMsg(false);
        }, 2000);
      });
    }
  };

  const handleRoleChange = (e: any, row: any, column: Column) => {
    const rowsEditedNew = newUsers.map((r) => {
      if (r.id === row.id) {
        return { ...r, [column.name]: e.target.value };
      }
      return r;
    });
    // console.log(rowsEditedNew);
    setNewUsers(rowsEditedNew);
  };
  const renderProject = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <Button
          onClick={() => {
            setToggleModalName('modalFunction');
            setModalData(row.projects_details);
          }}
        >
          {value}
        </Button>
      </>
    );
  };

  const renderRoles = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    let result = value;
    if (value === null) {
      result = (
        <Select
          value="null"
          key={value}
          onChange={(e) => handleRoleChange(e, row, column)}
          className={classes.selectStyle}
        >
          <MenuItem value="null" selected disabled>
            select a profile
          </MenuItem>
          <MenuItem value="keyuser">KeyUser</MenuItem>
        </Select>
      );
    } else if (value === 'viewer') {
      result = (
        <Select
          value="viewer"
          key={value}
          onChange={(e) => handleRoleChange(e, row, column)}
          className={classes.selectStyle}
        >
          <MenuItem value="keyuser">KeyUser</MenuItem>
          <MenuItem value="viewer" selected>
            <b>Viewer</b>
          </MenuItem>
        </Select>
      );
    } else if (value === 'keyuser') {
      result = (
        <Select
          value="keyuser"
          key={value}
          onChange={(e) => handleRoleChange(e, row, column)}
          className={classes.selectStyle}
        >
          <MenuItem value="keyuser" selected>
            <b>KeyUser</b>
          </MenuItem>
          <MenuItem value="viewer">Viewer</MenuItem>
        </Select>
      );
    }

    return result;
  };
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onDownload = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  const renderStatusIcon = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (value === true) return <DoneAllIcon titleAccess="Admin" />;
    if (value === false) return <CloseIcon titleAccess="Not Admin" />;
    return <></>;
  };
  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );

  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);
  // console.log(newUsers, 'hi');

  return (
    <div className={classes.root}>
      <Paper>
        <Box className={classes.bloc}>
          <Typography variant="subtitle1" className={classes.heading}>
            Activate Users
          </Typography>
          {showMsg && <span className={classes.success}>Successfully activated new users</span>}
          {loading ? (
            <Loading />
          ) : (
            <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
              <ExpressGrid rows={newUsers} columns={columns}>
                <DataTypeProvider
                  for={['activated_at']}
                  formatterComponent={({ value }) => (
                    <span>{formatDate(value, formatLocal('en'))}</span>
                  )}
                />
                <DataTypeProvider for={['is_admin']} formatterComponent={renderStatusIcon} />
                <DataTypeProvider for={['profile']} formatterComponent={renderRoles} />
                <DataTypeProvider for={['total_project']} formatterComponent={renderProject} />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <FilteringState />
                <IntegratedFiltering />
                <SelectionState selection={selection} onSelectionChange={setSelection} />
                <IntegratedSelection />
                <SortingState defaultSorting={[{ columnName: 'id', direction: 'desc' }]} />
                <IntegratedSorting />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <Table
                  tableComponent={TableComponent}
                  columnExtensions={[
                    { columnName: 'id', width: '50px' },
                    { columnName: 'name', width: '250px' },
                    { columnName: 'email', width: '250px' },
                    { columnName: 'activated_at', width: '100px' },
                    { columnName: 'is_admin', width: '100px' },
                    { columnName: 'total_project', width: '50px' },
                    { columnName: 'profile', width: '100px' },
                  ]}
                />
                <TableColumnResizing
                  defaultColumnWidths={[
                    { columnName: 'id', width: '100px' },
                    { columnName: 'name', width: '200px' },
                    { columnName: 'email', width: '200px' },
                    {
                      columnName: 'activated_at',
                      width: '150px',
                    },
                    {
                      columnName: 'is_admin',
                      width: '150px',
                    },
                    {
                      columnName: 'profile',
                      width: '150px',
                    },
                    {
                      columnName: 'total_project',
                      width: '150px',
                    },
                  ]}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow rowComponent={rowComponent} />
                <Toolbar rootComponent={toolbarComponent} />
                <ToolBarPlugin name="Activate" onClick={activateUser} />
                <ToolBarPlugin name="Download" onClick={startExport} />
                <PagingPanel pageSizes={pageSizes} />
                <TableSelection
                  showSelectAll
                  selectByRowClick
                  highlightRow
                  showSelectionColumn={true}
                />
              </ExpressGrid>
              <GridExporter
                ref={exporterRef}
                rows={newUsers}
                columns={columns}
                onSave={onDownload}
              />
              <NavigationGoto
                setPageSize={setPageSize}
                fileLength={newUsers?.length}
                pageSize={pageSize}
                dis={dis}
                setTemPage={setTemPage}
                handlePageNum={handlePageNum}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Box>
          )}
        </Box>
        {toggleModalName === 'modalFunction' && (
          <>
            <ModalAdminUser
              rows={modalData}
              onClose={() => {
                setToggleModalName('');
              }}
            />
          </>
        )}
      </Paper>
    </div>
  );
};

export default AdminUsers;
