import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  CheckCircle,
  Delete as Error,
  Info,
  Add as AddIcon,
} from '@mui/icons-material';
// import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import Module1Context from '../../../../Module1/Module1Context';
import ProjectContext from '../../../../Project/ProjectContext';
import { schedulephasesTabFinal, scheduleStreamPhases } from '../../../../../api/workloads';

const API_URL = 'https://your-api-url.com/stations'; // Replace with your API endpoint

const CdaPhaseTabTableResult = ({ streamDates, FinalData }) => {
  const generateOutput = (response) => {
    let phaseId = 1;
    let stationId = 101;

    return response.map((phase) => {
      const stations = phase.stations.map((station) => ({
        // eslint-disable-next-line no-plusplus
        id: stationId++,
        name: station,
      }));

      return {
        // eslint-disable-next-line no-plusplus
        id: phaseId++,
        name: phase.phase,
        stations,
      };
    });
  };
  const output = generateOutput(streamDates);

  const [metroData, setMetroData] = useState(output);

  const [expanded, setExpanded] = useState({});
  const [finalCall, setFinalCall] = useState(1);
  const [addingStation, setAddingStation] = useState(null);
  const [newStation, setNewStation] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const [newRows, setNewRows] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const { setSnack } = useContext(ProjectContext);
  // Pagination State
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination Logic
  const totalPages = Math.ceil(metroData.length / rowsPerPage);
  const paginatedData = metroData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const [newRowName, setNewRowName] = useState('');
  const [isAddingNewRow, setIsAddingNewRow] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   scheduleStreamPhases(FinalStreamData[1]?.workload_id)
  //     .then((payload) => {
  //       // setFinalPayload(payload);
  //       setLoading(false);
  //     })
  //     .catch((e) => console.log(e));
  // }, [finalCall]);

  // Toggle Expand/Collapse
  const toggleExpand = (index) => {
    setExpanded({ ...expanded, [index]: !expanded[index] });
  };

  // Start Adding a Station
  const startAddingStation = (index) => {
    setAddingStation(index);
    setNewStation('');
  };

  // Add a New Station
  const addStation = (index) => {
    if (newStation.trim() === '') return;

    const updatedData = [...metroData];

    // Generate new ID
    const lastStation = updatedData[index].stations[updatedData[index].stations.length - 1];
    const newId = lastStation ? lastStation.id + 1 : 1;

    updatedData[index].stations.push({ id: newId, name: newStation, status: 'inactive' });

    setMetroData(updatedData);
    setAddingStation(null);
    setHasChanges(true); // Track change
  };
  const deleteStation = (metroIndex, stationId) => {
    setDeleting(stationId);

    const updatedData = [...metroData];
    updatedData[metroIndex].stations = updatedData[metroIndex].stations.filter(
      (station) => station.id !== stationId
    );

    setMetroData(updatedData);
    setDeleting(null);
    setHasChanges(true); // Track change
  };
  // const addStation = (parentIndex, isNewRow = false) => {
  //   if (newStation.trim() === '') return;

  //   if (isNewRow) {
  //     // Add station to new row
  //     const updatedNewRows = [...newRows];
  //     const lastStation = updatedNewRows[parentIndex].stations.slice(-1)[0];
  //     const newId = lastStation ? lastStation.id + 1 : 1;

  //     updatedNewRows[parentIndex].stations.push({
  //       id: newId,
  //       name: newStation,
  //       status: 'inactive',
  //     });
  //     setNewRows(updatedNewRows);
  //   } else {
  //     // Add station to existing row in metroData
  //     const updatedMetroData = [...metroData];
  //     const lastStation = updatedMetroData[parentIndex].stations.slice(-1)[0];
  //     const newId = lastStation ? lastStation.id + 1 : 1;

  //     updatedMetroData[parentIndex].stations.push({
  //       id: newId,
  //       name: newStation,
  //       status: 'inactive',
  //     });
  //     setMetroData(updatedMetroData);
  //   }

  //   setAddingStation(null);
  //   setHasChanges(true); // Enable Save button
  // };
  // const deleteStation = (parentIndex, stationId, isNewRow = false) => {
  //   setDeleting(stationId);

  //   if (isNewRow) {
  //     const updatedNewRows = [...newRows];
  //     updatedNewRows[parentIndex].stations = updatedNewRows[parentIndex].stations.filter(
  //       (station) => station.id !== stationId
  //     );
  //     setNewRows(updatedNewRows);
  //   } else {
  //     const updatedMetroData = [...metroData];
  //     updatedMetroData[parentIndex].stations = updatedMetroData[parentIndex].stations.filter(
  //       (station) => station.id !== stationId
  //     );
  //     setMetroData(updatedMetroData);
  //   }

  //   setDeleting(null);
  //   setHasChanges(true); // Enable Save button
  // };

  const removeNewRow = (index) => {
    const updatedRows = newRows.filter((_, i) => i !== index);
    setNewRows(updatedRows);
    setHasChanges(updatedRows.length > 0); // Track change
  };

  // const handleSave = async () => {
  //   if (!hasChanges) return; // Prevent saving if no changes

  //   setLoading(true);
  //   setSnack('Your data has been saved successfully!', 'success');
  //   try {
  //     // Combine newRows (new metro parents) and metroData (existing metros)
  //     const combinedData = [...metroData, ...newRows];

  //     const response = await axios.post('https://your-api-url.com/metros', combinedData);
  //     if (response.status === 201) {
  //       alert('Your data has been saved successfully!');
  //       setMetroData(response.data); // Update UI with saved data
  //       setNewRows([]); // Clear new parents after saving
  //       setHasChanges(false); // Reset change tracker
  //     }
  //   } catch (error) {
  //     console.error('Error saving data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleAddNewRow = () => {
    setIsAddingNewRow(true);
    setNewRowName('');
  };
  const confirmNewRow = () => {
    if (newRowName.trim() === '') return;

    // Get the last existing parent ID and increment
    const lastParentId = metroData.length > 0 ? metroData[metroData.length - 1].id : 0;
    const newParentId = lastParentId + 1;

    // Create new row structure
    const newRow = {
      id: newParentId,
      name: newRowName,
      stations: [], // No sub-parents initially
    };

    setNewRows([...newRows, newRow]); // Temporarily store new rows
    setMetroData([...metroData, newRow]); // Update UI with new row
    setIsAddingNewRow(false); // Hide text field
    setHasChanges(true); // Enable save button
  };
  const handleSave = async () => {
    if (!hasChanges) return; // Prevent saving if no changes

    setLoading(true);
    // setSnack('Your data has been saved successfully!', 'success');

    // Merge new rows with existing metroData
    const updatedMetroData = [...metroData];
    const transformedData = updatedMetroData.map((phase) => ({
      phase: phase.name,
      stations: phase.stations.map((station) => station.name),
    }));
    // setMetroData(updatedMetroData);
    // const updatedArray = FinalStreamData.map((item) => {
    //   if (item.stream_phases) {
    //     return {
    //       ...item,
    //       stream_phases: {
    //         ...item.stream_phases,
    //         response: transformedData,
    //       },
    //     };
    //   }
    //   return item;
    // })
    //   .filter((item) => item.id === FinalStreamData[1]?.id)
    //   .map(({ stream_dataes, created_at, workload_id, ...rest }) => rest)
    //   .map((item) => item.stream_phases);

    // const updatedArray = streamDates
    //   .map(({ stream_dataes, created_at, workload_id, ...rest }) => rest)
    //   .map((item) => item.stream_phases);

    // const response = await axios.post(API_URL, updatedMetroData);
    // if (response.status === 201) {
    //   alert('Your data has been saved successfully!');
    //   setMetroData(response.data); // Update UI with saved data
    //   setNewRows([]); // Clear new parents after saving
    //   setHasChanges(false); // Reset change tracker
    // }
    const updatedObject = {
      ...FinalData,
      stream_phases: {
        ...FinalData.stream_phases,
        response: transformedData,
      },
    };

    schedulephasesTabFinal(updatedObject?.stream_phases, FinalData?.id)
      .then((p) => {
        setSnack('updated successfully.');
        setFinalCall(0);
      })
      .catch((err) => {
        // setHelperText(err.detail);
        // setError(true);
      })
      .finally(() => {
        setLoading(false);
        setHasChanges(false);
      });
  };
  // useEffect(() => {
  //   schedulephasesTabFinal().then((response) => {
  //     console.log('response', response);
  //   });
  // }, []);

  return (
    <Box sx={{ padding: 2, background: '#f5f7fa', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, background: '#002A45' }}>
        <Typography variant="h6" sx={{ color: 'white' }}>
          Metro Table
        </Typography>
        <Box>
          <Button
            variant="contained"
            sx={{ mr: 1, backgroundColor: 'green' }}
            size="small"
            onClick={handleAddNewRow}
          >
            Add Phase
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'blue' }}
            onClick={handleSave}
            disabled={!hasChanges || loading} // Enable only if changes exist
          >
            {loading ? <CircularProgress size={20} /> : 'Save'}
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: 300, overflow: 'auto' }}>
        <Table stickyHeader sx={{ minWidth: 600 }}>
          <TableBody>
            {metroData.map((metro, index) => (
              <React.Fragment key={metro.id}>
                {/* Metro Row */}
                <TableRow>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                      {metro.name}
                      <IconButton
                        onClick={() => startAddingStation(index)}
                        sx={{
                          backgroundColor: 'green',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '5px',
                          '&:hover': { backgroundColor: '#006400' }, // Darker green on hover
                          marginLeft: 2,
                        }}
                      >
                        <AddIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => toggleExpand(index)}>
                      {expanded[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                </TableRow>

                {/* Expandable Row for Stations */}
                <TableRow>
                  <TableCell colSpan={2} sx={{ padding: 0, background: '#f9f9f9' }}>
                    <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                      <Box sx={{ marginLeft: 4, padding: 1 }}>
                        {metro.stations.map((station) => (
                          <Box
                            key={station.id}
                            sx={{ display: 'flex', alignItems: 'center', marginBottom: 0.5 }}
                          >
                            <Error
                              sx={{
                                fontSize: 16,
                                color: 'red',
                                cursor: 'pointer',
                                marginRight: 1,
                              }}
                              onClick={() => deleteStation(index, station.id)}
                            />
                            {station.name}
                            {/* <Info sx={{ fontSize: 16, color: 'blue', marginLeft: 1 }} /> */}
                            {deleting === station.id && (
                              <CircularProgress size={16} sx={{ marginLeft: 1 }} />
                            )}
                          </Box>
                        ))}

                        {/* Input Field for Adding New Station */}
                        {addingStation === index && (
                          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            <TextField
                              size="small"
                              label="New Station"
                              variant="outlined"
                              value={newStation}
                              onChange={(e) => setNewStation(e.target.value)}
                            />
                            <Button
                              variant="contained"
                              sx={{ ml: 1 }}
                              onClick={() => addStation(index)}
                              disabled={loading}
                            >
                              {loading ? <CircularProgress size={20} /> : 'Add'}
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
            {isAddingNewRow && (
              <TableRow>
                <TableCell>
                  <TextField
                    size="small"
                    label="Enter Metro Name"
                    variant="outlined"
                    value={newRowName}
                    onChange={(e) => setNewRowName(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={confirmNewRow}>
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Page Navigation */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{ mx: 1 }}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>

          <Typography>
            Page {currentPage} of {totalPages}
          </Typography>

          <Button
            variant="contained"
            sx={{ mx: 1 }}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Box>

        {/* Go To Page Input */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Go to:</Typography>
          <TextField
            size="small"
            sx={{ width: 50, mx: 1 }}
            type="number"
            value={currentPage}
            onChange={(e) => {
              const page = Number(e.target.value);
              if (page >= 1 && page <= totalPages) {
                setCurrentPage(page);
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default CdaPhaseTabTableResult;
