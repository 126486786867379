import React, { useState } from 'react';
import { Route, useLocation, useParams } from 'react-router-dom';
import ExpandContainer from '../../HOC/ExpandComponent';
import ProjectContext from '../../Project/ProjectContext';
import IsensChatNew from '../../Module2/NewModule/GPT2/ChatContainer';
import { M2GlobalContextProvider } from '../../ContextMager/M2GlobalContext';
import { GenAContextProvider } from '../../ContextMager/GenAHomeContext';
import { ENV } from '../../../const';

const GenAHome = () => {
  const [isChatExpand, setIsChatExpand] = useState(true);
  const location = useLocation();
  const { projectId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  const Input = queryParams.get('text') ? decodeURIComponent(queryParams.get('text')) : null;

  if (actionIs === 'chat') {
    return (
      <IsensChatNew
        action="rex"
        initPrompt={
          Input
            ? `
extract and summarize the content about  ${Input}`
            : undefined
        }
        setIsChatExpand={setIsChatExpand}
      />
    );
  }

  if (projectId && (ENV === 'production' || ENV === 'test')) {
    return <>Un-authorized</>;
  }

  return (
    <GenAContextProvider>
      {location.pathname === `/${projectId}/iDocExplorer` &&
      ENV !== 'production' &&
      ENV !== 'test' ? (
        <IsensChatNew
          action="idoc"
          isChatExpandable={isChatExpand}
          setIsChatExpand={setIsChatExpand}
        />
      ) : (
        <IsensChatNew
          action={['private', 'public']}
          isChatExpandable={isChatExpand}
          setIsChatExpand={setIsChatExpand}
        />
      )}
    </GenAContextProvider>
  );
};

export default GenAHome;
