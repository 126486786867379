import React from 'react';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TextDiv = styled((props: any) => {
  return <Box {...props} />;
})((theme) => ({
  maxHeight: '20rem',
  overflowY: 'auto',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  color: theme.theme.palette.info.main,
  fontSize: '1.2rem',
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'none',
    borderRadius: '3px',
    backgroundColor: 'white',
  },

  '&::-webkit-scrollbar-thumb': {
    outline: 'none',
    backgroundColor: '#ccc',
  },
}));

const NCandGapContainer = styled((props: any) => {
  return <Box {...props} />;
})((theme) => ({
  border: `1px solid grey`,
  borderRadius: '10px',
  padding: '10px',
}));

const FormContainer = styled((props: any) => {
  return <Box {...props} />;
})((theme) => {
  return { color: theme.theme.palette.info.main, fontWeight: 100, fontSize: 1, marginBottom: 5 };
});

const RequirementTitle = styled((props: any) => {
  return <Box {...props} />;
})((theme) => ({
  color: theme.theme.palette.info.main,
}));

export { ExpandMore, TextDiv, NCandGapContainer, FormContainer, RequirementTitle };
