import { Button, FormControl, Grid, Paper, Select, Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import WorkIcon from '@mui/icons-material/Work';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../../../src/components/Core/Loading';
import { getAllProjectCreationLanguages, getAllProjectDetailsById } from '../../../api/projects';
import ProjectContext from '../ProjectContext';
import { ProjectPropertiesHidden } from '../ProjectContainer/components/ProjectConfiguration/styles/ProjectPropertiesStyle';

const PropertieHide = ({ getHideProperty }: Props) => {
  const [languageCode, setLanguageCode] = useState([]);
  const dataRegion = ['APAC', 'AMERICAS', 'AMECA', 'EUROPE', 'FRANCE', 'DACH', 'CHINA'];
  const history = useHistory();
  const { setProject, setSnack, userJWT, getProject, setProjects, projects } = useContext(
    ProjectContext
  );
  const [projectDetails, setProjetDetails] = useState([]);
  const { projectId } = useParams() as any;
  const project = getProject(Number(projectId));
  const [loading, setLoading] = useState(false);
  // take
  // language and get all users data Api Hit
  useEffect(() => {
    setLoading(true);
    getAllProjectDetailsById(projectId).then((dbProjects) => {
      setProjetDetails(dbProjects);
    });
    getAllProjectCreationLanguages()
      .then((dbProjects) => {
        const db = dbProjects.map((item) => {
          return `${item.codes}-${item.language}`;
        });
        setLanguageCode(db);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const data = languageCode;

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ProjectPropertiesHidden className="projecthiden">
          {/* <ProjectBreadCrumbs projectId={projectId} /> */}
          {projectDetails.length > 0 ? (
            <>
              <div className="paper_projecthiden">
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <form>
                        <div className="projectNameStyle_projecthiden">
                          <div
                            style={{
                              display: 'flex',
                              alignContent: 'center',
                              // justifyContent: 'center',
                              paddingTop: 5,
                            }}
                          >
                            <WorkIcon />
                            <b style={{ paddingLeft: 5 }}> Project Name</b>
                          </div>
                          <TextField
                            size="small"
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            inputProps={{
                              style: { color: '#777676', fontWeight: 'bold', border: 'none' },
                            }}
                            disabled
                            className="projectInput_projecthiden"
                            defaultValue={projectDetails[0]?.name}
                            // size="small"
                            name="name"
                            // onChange={handleChangeName}
                          />
                        </div>
                      </form>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="projectNameStyle_projecthiden">
                        <Grid item className="entity_projecthiden">
                          <FormLabel component="legend" style={{ color: '#002A45' }}>
                            <b>Select Alstom Entity</b>
                          </FormLabel>
                          <RadioGroup
                            aria-label="entity"
                            name="entity"
                            // onChange={handleChangeEntity}
                            value={projectDetails[0]?.entity}
                            // value={projectDetails[0]?.entity}
                            style={{ fontWeight: 'bold', display: 'flex' }}
                          >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <FormControlLabel
                                disabled
                                value="RSC"
                                control={<Radio />}
                                label="Rolling Stock & Component (RSC)"
                              />
                              <FormControlLabel
                                disabled
                                value="D&IS"
                                control={<Radio />}
                                label="Digital & Integrated Systems (D&IS)"
                              />
                              <FormControlLabel
                                disabled
                                value="Services"
                                control={<Radio />}
                                label="Services"
                              />
                            </div>
                          </RadioGroup>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: 15 }} className="type_projecthiden">
                      <form>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <b>Description:</b>
                          </div>
                          <TextField
                            style={{ marginLeft: 15 }}
                            rows="2"
                            multiline
                            fullWidth
                            disabled
                            id="outlined-basic"
                            variant="outlined"
                            inputProps={{
                              maxLength: 100,
                              style: { color: '#002A45', fontWeight: 'bold' },
                            }}
                            // defaultValue={projectDetails[0]?.solution}
                            defaultValue={projectDetails[0]?.description}
                            // size="small"
                            name="Description"
                            // onChange={handleChangeDescription}
                          />
                        </div>
                      </form>
                    </Grid>
                  </Grid>
                  <div className="secondPart_projecthiden">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Grid>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <FormLabel component="legend">
                              <b style={{ color: '#002A45', marginLeft: 10 }}>Type:</b>
                            </FormLabel>
                            <RadioGroup
                              aria-label="type"
                              name="types"
                              // value={projectDetails[0]?.types}
                              defaultValue={projectDetails[0]?.types}
                              // value={typeValue}
                              // onChange={handleChangeType}
                              style={{ fontWeight: 'bold', display: 'flex', marginLeft: 35 }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  width: '200%',
                                }}
                              >
                                <FormControlLabel
                                  disabled
                                  value="Project"
                                  control={<Radio />}
                                  label="Project"
                                />
                                <FormControlLabel
                                  disabled
                                  value="Product"
                                  control={<Radio />}
                                  label="Product"
                                />
                                <FormControlLabel
                                  disabled
                                  value="Program"
                                  control={<Radio />}
                                  label="Program"
                                />
                                <FormControlLabel
                                  disabled
                                  value="Tender"
                                  control={<Radio />}
                                  label="Tender"
                                />
                                <FormControlLabel
                                  disabled
                                  value="Pre Tender"
                                  control={<Radio />}
                                  label="Pre Tender"
                                />
                              </div>
                            </RadioGroup>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <form>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: 10,
                            }}
                          >
                            <div>
                              <b>Solution:</b>
                            </div>
                            <TextField
                              style={{ marginLeft: 38 }}
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              size="small"
                              name="solution"
                              defaultValue={projectDetails[0]?.solution}
                              disabled
                              // value={projectDetails[0]?.solution}
                              inputProps={{
                                style: { color: '#002A45', fontWeight: 'bold' },
                              }}
                              // onChange={handleChangeSolution}
                            />
                          </div>
                        </form>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: 10,
                          }}
                        >
                          <div>
                            <b>Language:</b>
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{ marginLeft: 30 }}
                            className="formControlMulti_projecthiden"
                          >
                            <Select
                              native
                              // label="Language"
                              disabled
                              name="language"
                              className="inputField_projecthiden"
                              // style={{ width: 150 }}
                              defaultValue={projectDetails[0]?.language}
                              // onChange={handleChangeLanguage}
                            >
                              <option aria-label="None" value="" />
                              {data.map((cap: string, index: number) => (
                                <option key={`capture-type-${index}`} value={cap}>
                                  {cap}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <form>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ whiteSpace: 'nowrap' }}>
                              <b>PR Code:</b>
                            </div>
                            <TextField
                              disabled
                              style={{ marginLeft: 10 }}
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              size="small"
                              defaultValue={projectDetails[0]?.prcode}
                              inputProps={{
                                style: { color: '#002A45', fontWeight: 'bold' },
                              }}
                              // onChange={handleChangePrCode}
                            />
                          </div>
                        </form>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <form noValidate autoComplete="off">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: 10,
                            }}
                          >
                            <div style={{ whiteSpace: 'nowrap' }}>
                              <b>Leading Unit:</b>
                            </div>
                            <TextField
                              style={{ marginLeft: 15 }}
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: { color: '#002A45', fontWeight: 'bold' },
                              }}
                              disabled
                              defaultValue={projectDetails[0]?.leadingunit}
                              // onChange={handleChangeLeadingUnit}
                            />
                          </div>
                        </form>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <b>Region:</b>
                          </div>
                          <FormControl
                            variant="outlined"
                            style={{ marginLeft: 15 }}
                            className="formControlMulti_projecthiden"
                          >
                            <Select
                              native
                              name="region"
                              className="inputField_projecthiden"
                              disabled
                              // style={{ width: 150 }}
                              // defaultValue={data[0]}
                              defaultValue={projectDetails[0]?.region}
                              // onChange={handleChangeRegion}
                            >
                              <option aria-label="None" value="" />
                              {dataRegion.map((cap: string, index: number) => (
                                <option key={`capture-type-${index}`} value={cap}>
                                  {cap}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                  <Grid
                    // container
                    style={{ textAlign: 'right', marginTop: 10, marginRight: 10 }}
                  >
                    <Grid>
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        // onClick={handleSubmit}
                        // endIcon={<Loading size={20} displayIcon={true} />}
                        style={{
                          // color: 'white',
                          // backgroundColor: '#DC3223',
                          width: '100px',
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </>
              </div>
            </>
          ) : null}
        </ProjectPropertiesHidden>
      )}
    </>
  );
};

export default PropertieHide;
