import { Typography } from '@mui/material';
import React from 'react';
import { RephraserButton, RulesList } from '../../../Styles/Rephraser';

const useResultPage = (props) => {
  const { handleAnalyse, result } = props;

  const {
    orginalText,
    rephrasedText,
    originalWarning,
    rephrasedTextWarning,
    original_socre,
    rephrased_socre,
  } = result;

  console.log('result', result);

  const renderProps = {
    left: {
      renderHeader: () => {
        return (
          <>
            <div className="header">
              <div className="label">
                <Typography>Orignal Score: {original_socre}</Typography>
              </div>
              {/* <RephraserButton>Analyse</RephraserButton> */}
            </div>
          </>
        );
      },
      renderContent: () => {
        return (
          <>
            <div className="content">
              {' '}
              <Typography>{orginalText}</Typography>
            </div>
          </>
        );
      },
      enableFooter: true,
      renderFotter: () => {
        return (
          <>
            <div className="fotter">
              <Typography>{originalWarning}</Typography>
            </div>
          </>
        );
      },
    },
    right: {
      renderHeader: () => {
        return (
          <>
            <div className="header">
              <div className="label">
                <Typography>Rephrased Score: {rephrased_socre}</Typography>
              </div>
              <RephraserButton
                onClick={() => {
                  console.log('rephrasedText', rephrasedText);
                  handleAnalyse(rephrasedText);
                }}
              >
                Rephrase
              </RephraserButton>
            </div>
          </>
        );
      },
      renderContent: () => {
        return (
          <>
            <div className="content">
              <Typography>{rephrasedText}</Typography>
            </div>
          </>
        );
      },
      enableFooter: true,
      renderFotter: () => {
        return (
          <>
            <div className="fotter">
              <Typography>{rephrasedTextWarning}</Typography>
            </div>
          </>
        );
      },
    },
  };

  return { resultProps: renderProps };
};

export default useResultPage;
