import React from 'react';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { RenderDateAndTiem } from './ServiceItem';
import downloadTranslateFile from '../../../tools/downloadTranslateFile';
import downloadSourceFile from '../../../tools/downloadSourceFile';
import { getBearerTokenSync } from '../../../app/AppToken';

const DetailforTransalator = (props) => {
  const { source_file, translatedLang, created_at, id: workLoadId, workload } = props;
  const [loading, setLoading] = React.useState(false);
  const { id, name } = workload.serviceFor;

  const downloadSource = () => {
    setLoading(true);
    const token = getBearerTokenSync();
    if (name === 'user') {
      const querystring = `files=${'original_file'}`;
      downloadSourceFile(`/download/userresources/${workLoadId}`, querystring, token, source_file)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (name === 'project') {
      const querystring = `files=${'original_file'}`;
      downloadTranslateFile(`/download/resources/${workLoadId}`, querystring, token, source_file)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
      <div>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            width: 'max-content',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Source File:</span>
          <Typography style={{ fontSize: '0.89rem' }}>{source_file}</Typography>
          <IconButton
            size="small"
            disabled={loading}
            style={{ color: '#002A45' }}
            onClick={() => {
              downloadSource();
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: '#002A45' }} size={20} />
            ) : (
              <GetAppIcon fontSize="small" />
            )}
          </IconButton>
        </div>
        <Typography style={{ fontSize: '0.89rem' }}>
          {' '}
          <span style={{ fontWeight: 'bold' }}>Translated Language:</span> {translatedLang}
        </Typography>
      </div>
      <RenderDateAndTiem created_at={created_at} style={{ fontSize: '0.89rem' }} />
      {/* <h3>Date of Creation: {dataofcreation}</h3> */}
    </div>
  );
};

export default DetailforTransalator;
