import jwt_decode from 'jwt-decode';
import { getAzJWT } from './AzureToken';
import { refreshToken, registerUserWithAzToken } from '../api/users';

const isTokenExpired = (decodedToken) => {
  if (decodedToken.exp < Date.now() / 1000) {
    return true;
  }
  return false;
};

const getLocalAppJWTContent = () => {
  const appJWT = localStorage.getItem('appJWT');
  return appJWT === null ? null : JSON.parse(appJWT);
};

export const saveAppJWT = (jwt) => {
  localStorage.setItem('appJWT', JSON.stringify(jwt));
};

export const getAppJWT = async () => {
  // Check if token exists and is still valid
  const appJWT = getLocalAppJWTContent();

  if (appJWT !== null) {
    const bearerToken: string = appJWT.access_token;

    // always decode JWT
    const decodedToken = jwt_decode(bearerToken);
    if (!isTokenExpired(decodedToken)) {
      return appJWT;
    }

    // check if refresh token is expired
    const refresh = appJWT.refresh_token;
    const decodedRefreshToken = jwt_decode(refresh);
    if (!isTokenExpired(decodedRefreshToken)) {
      const refreshedMiddlewareToken = await refreshToken(refresh, bearerToken);
      saveAppJWT(refreshedMiddlewareToken);
      return refreshedMiddlewareToken;
    }
  }

  // azure login if both tokens are expired
  const azJWT = await getAzJWT();
  const newMiddlewareToken = await registerUserWithAzToken(
    {
      details: {
        site_code: azJWT.idTokenClaims['site code'] as string,
        job_function: azJWT.idTokenClaims.jobTitle,
        department: azJWT.idTokenClaims.Department,
      },
    },
    azJWT.idToken
  );
  saveAppJWT(newMiddlewareToken);
  return newMiddlewareToken;
};

export const getAppJWTSync = () => {
  const appJWT = getLocalAppJWTContent();
  return appJWT;
};

export const getBearerToken = async () => {
  const appJWT = getLocalAppJWTContent();
  const bearerToken = appJWT === null ? null : (await getAppJWT()).access_token;
  return bearerToken;
};

export const getBearerTokenSync = () => {
  const appJWT = getLocalAppJWTContent();
  const bearerToken = appJWT === null ? null : appJWT.access_token;
  return bearerToken;
};
