import React, { useEffect } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SliderContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  //   height: '2rem',
  //   alignItems: 'center',
  gap: '2px',
  margin: '0rem 0.5rem',
  transition: 'all 0.3s ease-out',
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
}));

const SliderComponent = ({
  dispatch,
  type,
  label = 'Max Results',
  min = 5,
  defaultValue = 20,
  max = 100,
  reset = 0,
}) => {
  // const { isearcherMaxResultsValue } = isearcherFilter;
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    if (reset) {
      setValue(defaultValue);
    }
  }, [reset]);

  const handleMaxResultsChange = (event, newValue) => {
    setValue(newValue);
    dispatch({ type, payload: newValue });
  };

  return (
    <SliderContainer>
      <Typography id="discrete-slider-always" gutterBottom>
        {label}: {value}
      </Typography>
      <Slider
        min={min}
        max={max}
        // style={{ maxWidth: '12rem' }}
        value={value}
        step={1}
        onChangeCommitted={handleMaxResultsChange}
        valueLabelDisplay="auto"
        aria-labelledby="discrete-slider-always"
        defaultValue={defaultValue}
      />
      {/* <Typography id="discrete-slider-always" gutterBottom>
        {value}
      </Typography> */}
    </SliderContainer>
  );
};

export default SliderComponent;
