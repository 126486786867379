import { styled } from '@material-ui/styles';
import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Box from '@mui/material/Box';

const DataBox = styled(Table.Container)(({ theme }) => ({
  '&.root': {},

  '& .hoverEff': {
    color: 'red',
    '& .MuiButton-root': {
      textAlign: 'center',
      marginLeft: 60,
      marginTop: 10,
      color: 'white',
    },
    '& :hover': {
      color: 'white !important',
      opacity: 0.8,
    },
  },
}));
export default DataBox;
