import React, { useCallback } from 'react';
import { Box, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import SelectMUI from '../../HOC/core/SelectMUI';
import { deeplDescription, microSoftDescription } from '../../../const';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  // margin: '0 auto',
  height: 'calc(100% - 4rem)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const RenderTranslation = (props) => {
  const { docTranslation, setDocTranslation, setList } = props;

  const HandleOnChangeOptions = useCallback(
    (language, type) => {
      if (type === 'Target') {
        setDocTranslation((prevState) => ({
          ...prevState,
          toLang: language,
        }));
      } else {
        setDocTranslation((prevState) => ({
          ...prevState,
          model: language,
        }));

        if (language === 'DeepL') {
          setList(deeplDescription);
        } else {
          setList(microSoftDescription);
        }
      }
    },
    [docTranslation]
  );

  return (
    <Box style={{ height: '2rem', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <SelectMUI
        style={{ marginLeft: '0.5rem', height: '100%' }}
        name="Microsoft"
        selectedVal={docTranslation.model}
        optionStyle={{ gridTemplateColumns: 'repeat(1, 1fr)' }}
        options={['Microsoft', 'DeepL']}
        onChange={HandleOnChangeOptions}
      />
    </Box>
  );
};

const Description = (props) => {
  const { list: ls, desciptionProps = {}, renderTranslation = false } = props;
  const [list, setList] = React.useState(ls);
  return (
    <Container>
      {renderTranslation && <RenderTranslation {...desciptionProps} setList={setList} />}
      <List>
        {list.map((data) => {
          return (
            <ListItem style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{data}</ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default Description;
